import { bulkQueryTeilforderungDetails, queryCSSDsById } from "services/cssdService";
import { getIdProxyAddr } from "services/web3Services/commons";
import {
  calculateGegenwert,
  formatNumberForCSV,
  getCountrybyCode,
  getSprache,
  getTrueFalse,
  removeDots,
} from "./convertor";
import { appIntl } from "components/i18n/intl";
import { messages } from "components/CssdRegister/Wizard/messages";
import {
  getTilgungsart,
  getVerzinsung,
  getMargeTyp,
  getZahlungsrhythmus,
  getZinsmethode,
  getFaelligkeitstag,
  getBerrechnungszeitraum,
  getStepUpDown,
  getFormattedMonths,
} from "components/Shared/FormatField/FormatField";
import {
  bssdExportHeaderKeys,
  cessionExportHeaderKeys,
  partialClaimExportHeaderKeys,
} from "util/fields";
import { messages as partialClaimHeaderMessages } from "components/CssdRegister/Wizard/messages";
import { CSSDCessionState, referenzzinssaetze } from "./constants";
import { bssdHeaderMessages, exportMessages } from "components/Shared/Export/messages";
import CssdPartialClaimStatus from "components/Shared/CssdPartialClaim/CssdPartialClaimStatus";
import { statusMessages as partialClaimStatusMessages } from "components/CssdTeilforderungen/Status/messages";
import { messages as cessionStatusMessages } from "components/CssdCession/messages";
import { getCessionsForCssd } from "services/cssdCessionService";
import CssdCessionStatus from "components/Shared/CssdCession/CssdCessionStatus";
import { downloadFileFromBinary, FileTypes } from "./fileReader";
import {
  getSsdLabelText,
  getSsdState,
} from "components/BssdOverview/SsdContentWrapper/SsdContent/StatusChips/SsdStatusChip";
import { getPaymentState } from "components/BssdOverview/SsdContentWrapper/SsdContent/StatusChips/SsdPaymentStatus";
import { getMaturityDate } from "./ssdMaturityDateFormat";
import {
  formatBildschirmseite,
  formatZinszahlungsrhythmus,
  formatAuslandsfaehigkeit,
  formatZinsFix,
  formatKuendigungsrecht,
  formatFaelligkeitstagDerZinszahlung,
  formatBerechnungszeitraumDerZinszahlung,
  formatJaNein, formatReferenzzinssatz,
} from "../components/Shared/FormatField/FormatFieldLegacy";

// ----------------------------------------------------------------------------
//#region General Methods
// ----------------------------------------------------------------------------

const arrayToCsvBytes = (content) => {
  const csv = content.map((e) => e.join(";")).join("\n");

  let bytes = new Uint8Array(csv.length);
  for (let i = 0; i < csv.length; i++) {
    bytes[i] = csv.charCodeAt(i);
  }

  return bytes;
};

const buildSettlementInformationArray = (data, idProxy, reduced) => {
  let konto;
  if (reduced) {
    konto = new Array(8);
  } else {
    konto = [
      data.bicAllgemein,
      data.ibanZinsEur,
      data.bicZinsEur,
      data.hinweisZahlungZins,
      data.ibanTilgungEur,
      data.bicTilgungEur,
      data.hinweisZahlungTilgung,
      getTrueFalse(data.belastungInternesKonto),
    ];
  }
  return [
    data.firmenname,
    data.digitsKennung,
    data.umsatzSteuerId,
    data.anschrift?.replaceAll("\n", " "),
    getCountrybyCode(data.sitzLand),
    getSprache(data.sprache),
    data.ansprechpartnerAbwicklung,
    data.abteilungAbwicklung,
    data.telefonnummer,
    data.emailVerteiler,
    ...konto,
    data.idProxyAdresse === idProxy ? data.interneKennung : "",
  ];
};

const buildHeader = (headerDefinition, messages) => {
  let header = [];

  headerDefinition.map((headRegion) => {
    const prefix = headRegion.prefix ? appIntl().formatMessage(messages[headRegion.prefix]) : "";
    headRegion.keys.map((key) => {
      header.push((prefix ? prefix + "-" : "") + appIntl().formatMessage(messages[key]));
      return key;
    });
    return headRegion;
  });

  return header;
};

//#endregion

// ----------------------------------------------------------------------------
//#region Public Methods
// ----------------------------------------------------------------------------

export const downloadByteArrayAsCSV = (fileName, bytes) => {
  downloadFileFromBinary(bytes, fileName, FileTypes.CSV);
};

export const generatePartialClaimCSV = async (partialClaims) => {
  const [cssdRefs, cessionRefs] = await Promise.all([
    loadAllCssd(collectAllCssd(partialClaims)),
    loadAllClaimDetails(partialClaims),
  ]);

  const matrix = buildPartialClaimMatrix(partialClaims, cssdRefs, cessionRefs);
  const header = buildHeader(partialClaimExportHeaderKeys, partialClaimHeaderMessages);
  return arrayToCsvBytes([header, ...matrix]);
};

export const generateCessionCSV = async (cssd) => {
  const res = await getCessionsForCssd(getIdProxyAddr(), cssd.cssdId);
  const cessions = res.data.abtretungResponses.filter(
    (cession) => cession.status !== CSSDCessionState.INVALID
  );

  const matrix = buildCessionMatrix(cessions, cssd);
  const header = buildHeader(cessionExportHeaderKeys, partialClaimHeaderMessages);

  return arrayToCsvBytes([header, ...matrix]);
};

export const generateBssdCSV = async (bssds) => {
  const matrix = buildBssdMatrix(bssds);
  const header = buildHeader(bssdExportHeaderKeys, bssdHeaderMessages);

  return arrayToCsvBytes([header, ...matrix]);
};

//#endregion

// ----------------------------------------------------------------------------
//#region Fetch Methods
// ----------------------------------------------------------------------------

const groupCssdsByIdProxy = (cssds) => {
  let requests = {};
  const keys = Object.keys(cssds);
  for (const cssdId of keys) {
    const idProxy = cssds[cssdId].idProxyAdresse;
    if (requests[idProxy] === undefined) {
      requests[idProxy] = [
        { cssdId: Number(cssdId), arranger: idProxy, fingerprint: cssds[cssdId].fingerprint },
      ];
    } else {
      requests[idProxy].push({
        cssdId: Number(cssdId),
        arranger: idProxy,
        fingerprint: cssds[cssdId].fingerprint,
      });
    }
  }
  return requests;
};

const loadAllCssd = async (cssds) => {
  const cssdsByArranger = groupCssdsByIdProxy(cssds);
  const responses = await Promise.all(
    Object.keys(cssdsByArranger).map(async (arranger) => {
      return await loadCssdsByArranger(arranger, cssdsByArranger[arranger]).catch((err) =>
        cssdsByArranger[arranger].map((cssd) => ({ ...cssd, error: true }))
      );
    })
  );
  return responses.reduce((a, b) => [...a, ...b], []);
};

const loadCssdsByArranger = async (arranger, cssds) => {
  const { data } = await queryCSSDsById(arranger, cssds, getIdProxyAddr());
  const result = [...data.cssdResponses];
  [...data.missingIds, ...data.unauthorizedIds].forEach((errorId) => {
    result.push({ cssdId: errorId, error: true });
  });
  //evtl auf fp prüfen: { ...cssd, verified }
  return result;
};

const groupClaimsByIdProxy = (claims) => {
  let requests = {};
  for (const claim of claims) {
    if (requests[claim.idProxyAdresseZahlstelle] === undefined) {
      requests[claim.idProxyAdresseZahlstelle] = [{ teilforderungId: claim.id }];
    } else {
      requests[claim.idProxyAdresseZahlstelle].push({ teilforderungId: claim.id });
    }
  }
  return requests;
};

const loadAllClaimDetails = async (claims) => {
  const claimsByArranger = groupClaimsByIdProxy(claims);
  const responses = await Promise.all(
    Object.keys(claimsByArranger).map(async (arranger) => {
      return await loadClaimDetailsByArranger(arranger, claimsByArranger[arranger]).catch((err) =>
        claimsByArranger[arranger].map((claim) => ({ ...claim, error: true }))
      );
    })
  );
  return responses.reduce((a, b) => [...a, ...b], []);
};

const loadClaimDetailsByArranger = async (arranger, claimIds) => {
  const { data } = await bulkQueryTeilforderungDetails(arranger, claimIds);
  const result = [...data.cssdTeilforderungDetailsResponses];
  [...data.missingIds, ...data.unauthorizedIds].forEach((errorId) => {
    result.push({ teilforderungId: errorId, error: true });
  });
  //evtl auf fp prüfen: { ...claim, verified }
  return result;
};
//#endregion

// ----------------------------------------------------------------------------
//#region Partial Claim Methods
// ----------------------------------------------------------------------------

const collectAllCssd = (partialClaims) => {
  let cssds = {};
  for (let index = 0; index < partialClaims.length; index++) {
    const element = partialClaims[index];
    if (!cssds[element.cssdId]) {
      cssds[element.cssdId] = {
        idProxyAdresse: element.idProxyAdresseZahlstelle,
        fingerprint: element.cssdFingerprint,
      };
    }
  }
  return cssds;
};

const buildPartialClaimRow = (claim, idProxy, cssdRef, claimDetails) => {
  const statusText = appIntl().formatMessage(
    partialClaimStatusMessages[
      CssdPartialClaimStatus({ status: claim.status, kuendigungen: claim.kuendigungen })
        .statusTextKey
    ] || partialClaimStatusMessages.status_unknown
  );
  const isDN = cssdRef.darlehensnehmer.idProxyAdresse === idProxy;
  return [
    cssdRef.urkundenRegisternummer,
    cssdRef.konsortialkennung,
    appIntl().formatMessage(messages[cssdRef.darlehensart.toLowerCase()]),
    cssdRef.waehrung,
    formatNumberForCSV(cssdRef.darlehensbetrag),
    cssdRef.laufzeitbeginn,
    formatNumberForCSV(cssdRef.mindestbetragBeiAbtretung),
    getSprache(cssdRef.sprache),
    getTrueFalse(cssdRef.hasZahlstellenGebuehr),
    getTrueFalse(cssdRef.hasArrangierProvision),
    claim.endfaelligkeitTeilforderung,
    getTilgungsart(cssdRef.zinsUndTilgungskonditionen.tilgungsart),
    getVerzinsung(cssdRef.zinsUndTilgungskonditionen.verzinsung),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.zinssatz),
    cssdRef.zinsUndTilgungskonditionen.referenzzinssatz ?
      appIntl().formatMessage(
        referenzzinssaetze[cssdRef.zinsUndTilgungskonditionen.referenzzinssatz]
      ) : "",
    getMargeTyp(cssdRef.zinsUndTilgungskonditionen.margeTyp),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.marge),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.minimumZinssatz),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.maximumZinssatz),
    getZahlungsrhythmus(cssdRef.zinsUndTilgungskonditionen.zahlungsrhythmus),
    getZinsmethode(cssdRef.zinsUndTilgungskonditionen.zinsmethode),
    getFaelligkeitstag(cssdRef.zinsUndTilgungskonditionen.faelligkeitstagDerZinszahlung),
    getBerrechnungszeitraum(cssdRef.zinsUndTilgungskonditionen.berechnungszeitraumDerZinszahlung),
    cssdRef.zinsUndTilgungskonditionen.zinsterminTag,
    getFormattedMonths(cssdRef.zinsUndTilgungskonditionen.zinsterminMonat),
    cssdRef.zinsUndTilgungskonditionen.ersterZinstermin,
    cssdRef.zinsUndTilgungskonditionen.vorletzterZinstermin,
    getStepUpDown(cssdRef.zinsUndTilgungskonditionen.stepupStepdownRegelung),
    ...buildSettlementInformationArray(
      cssdRef.darlehensnehmer,
      idProxy,
      !(
        idProxy === cssdRef.darlehensnehmer.idProxyAdresse ||
        idProxy === cssdRef.zahlstelle.idProxyAdresse
      )
    ),
    ...buildSettlementInformationArray(cssdRef.zahlstelle, idProxy),
    ...buildSettlementInformationArray(claimDetails.darlehensgeber),
    formatNumberForCSV(claim.nominal),
    isDN && claim.entstandenAusAbtretungId && !claim.isRueckkauf
      ? ""
      : formatNumberForCSV(claimDetails.kurs),
    isDN && claim.entstandenAusAbtretungId && !claim.isRueckkauf
      ? ""
      : formatNumberForCSV(calculateGegenwert(claim.nominal, claimDetails.kurs)),
    claimDetails.abtretungsdatum,
    claimDetails.valuta,
    claimDetails
      ? claimDetails.naechsterZinstermin
      : cssdRef.zinsUndTilgungskonditionen.ersterZinstermin,
    claim.geschaeftsnummer,
    statusText,
    formatNumberForCSV(claim.abgestimmterRestbestand),
    claimDetails.valutaStueckzinsen,
    //getTrueFalse(cssdRef.verified && claimDetails.verified),
  ];
};

const buildPartialClaimMatrix = (partialClaims, cssdRefs, claimDetails) => {
  const idProxy = getIdProxyAddr();
  let errors = 0;
  let claims = partialClaims.map((claim) => {
    const cssdRef = cssdRefs.find((detail) => claim.cssdId === detail.cssdId);
    const detailRef = claimDetails.find((detail) => claim.id === detail.teilforderungId);
    if (!cssdRef || cssdRef.error || !detailRef || detailRef.error) {
      errors += 1;
      return [];
    } else {
      return buildPartialClaimRow(claim, idProxy, cssdRef, detailRef);
    }
  });
  claims = claims.filter((row) => row.length !== 0);

  if (errors > 0) {
    return [...claims, [appIntl().formatMessage(exportMessages.data_incomplete)]];
  }
  return claims;
};

//#endregion

// ----------------------------------------------------------------------------
//#region Cession Methods
// ----------------------------------------------------------------------------
const buildCessionRow = (cession, idProxy, cssdRef) => {
  const statusText = appIntl().formatMessage(
    cessionStatusMessages["status_" + CssdCessionStatus(cession).statusTextKey]
  );
  return [
    formatNumberForCSV(cession.nominal),
    formatNumberForCSV(cession.kurs),
    formatNumberForCSV(calculateGegenwert(cession.nominal, cession.kurs)),
    cession.abtretungsdatum,
    cession.valuta,
    cession.valutaStueckzinsen,
    cession.naechsterZinstermin,
    cession.gerichtsstand,
    cession.geschaeftsnummer,
    statusText,
    cssdRef.urkundenRegisternummer,
    cssdRef.konsortialkennung,
    appIntl().formatMessage(messages[cssdRef.darlehensart.toLowerCase()]),
    cssdRef.waehrung,
    formatNumberForCSV(cssdRef.darlehensbetrag),
    cssdRef.laufzeitbeginn,
    formatNumberForCSV(cssdRef.mindestbetragBeiAbtretung),
    getSprache(cssdRef.sprache),
    getTrueFalse(cssdRef.hasZahlstellenGebuehr),
    getTrueFalse(cssdRef.hasArrangierProvision),
    cssdRef.zinsUndTilgungskonditionen.endfaelligkeitDurchKuendigung ||
      cssdRef.zinsUndTilgungskonditionen.endfaelligkeit,
    getTilgungsart(cssdRef.zinsUndTilgungskonditionen.tilgungsart),
    getVerzinsung(cssdRef.zinsUndTilgungskonditionen.verzinsung),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.zinssatz),
    cssdRef.zinsUndTilgungskonditionen.referenzzinssatz ?
      appIntl().formatMessage(
        referenzzinssaetze[cssdRef.zinsUndTilgungskonditionen.referenzzinssatz]
      ) : "",
    getMargeTyp(cssdRef.zinsUndTilgungskonditionen.margeTyp),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.marge),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.minimumZinssatz),
    formatNumberForCSV(cssdRef.zinsUndTilgungskonditionen.maximumZinssatz),
    getZahlungsrhythmus(cssdRef.zinsUndTilgungskonditionen.zahlungsrhythmus),
    getZinsmethode(cssdRef.zinsUndTilgungskonditionen.zinsmethode),
    getFaelligkeitstag(cssdRef.zinsUndTilgungskonditionen.faelligkeitstagDerZinszahlung),
    getBerrechnungszeitraum(cssdRef.zinsUndTilgungskonditionen.berechnungszeitraumDerZinszahlung),
    cssdRef.zinsUndTilgungskonditionen.zinsterminTag,
    getFormattedMonths(cssdRef.zinsUndTilgungskonditionen.zinsterminMonat),
    cssdRef.zinsUndTilgungskonditionen.ersterZinstermin,
    cssdRef.zinsUndTilgungskonditionen.vorletzterZinstermin,
    getStepUpDown(cssdRef.zinsUndTilgungskonditionen.stepupStepdownRegelung),
    ...buildSettlementInformationArray(cssdRef.darlehensnehmer, idProxy),
    ...buildSettlementInformationArray(cession.alterDarlehensgeber, idProxy),
    ...buildSettlementInformationArray(cession.neuerDarlehensgeber, idProxy),
  ];
};

const buildCessionMatrix = (cessions, cssdRef) => {
  const idProxy = getIdProxyAddr();
  const claims = cessions.map((cession) => buildCessionRow(cession, idProxy, cssdRef));
  return claims;
};
//#endregion

// ----------------------------------------------------------------------------
//#region BSSD Methods
// ----------------------------------------------------------------------------

const getZinsfields = (bssd) => {
  if (bssd.zins_fix === "variabel") {
    return [
      "",
      formatZinsFix(bssd.zins_fix),
      formatReferenzzinssatz(bssd.referenzzinssatz),
      formatNumberForCSV(removeDots(bssd.aufschlag)),
      formatNumberForCSV(removeDots(bssd.abschlag)),
      formatNumberForCSV(removeDots(bssd.minimumzinssatz)),
      formatNumberForCSV(removeDots(bssd.maximumzinssatz)),
      formatNumberForCSV(removeDots(bssd.faktor)),
    ];
  } else {
    return [formatNumberForCSV(removeDots(bssd.zinssatz)), formatZinsFix(bssd.zins_fix), "", "", "", "", "", ""];
  }
};
const buildBssdRow = (bssd) => {
  return [
    bssd.urkunden_registernummer,
    getSsdLabelText(getSsdState(bssd)),
    getPaymentState(
      bssd.paymentState,
      bssd.rebuyState,
      bssd.state,
      bssd.maturityDate,
      bssd.rebuyMaturityDate,
      bssd.terminationDate,
      bssd.terminationState
    ),
    bssd.handelstag,
    formatNumberForCSV(removeDots(bssd.nominal)),
    bssd.waehrung_nominal,
    formatNumberForCSV(removeDots(bssd.kurs)),
    bssd.notiz,
    formatNumberForCSV(removeDots(bssd.kaufpreis)),
    bssd.waehrung_kaufpreis,
    bssd.valuta,
    getMaturityDate(bssd),
    ...getZinsfields(bssd),
    formatZinszahlungsrhythmus(bssd.zinszahlungsrhythmus),
    bssd.bankarbeitstage,
    formatBildschirmseite(bssd.bildschirmseite),
    bssd.relevanteuhrzeit,
    bssd.relevanterort,
    bssd.fixingtyp,
    bssd.zinsmethode,
    formatFaelligkeitstagDerZinszahlung(bssd.faelligkeitstag_der_zinszahlung),
    formatBerechnungszeitraumDerZinszahlung(bssd.berechnungszeitraum_der_zinszahlung),
    bssd.zinstermin_tag,
    bssd.zinstermin_monat,
    bssd.erster_zinstermin,
    bssd.vorletzter_zinstermin,
    bssd.geschaeftstagekalender,
    formatKuendigungsrecht(bssd.kuendigungsrecht),
    bssd.kuendigungsfrist,
    bssd.abtretbarkeit,
    formatNumberForCSV(removeDots(bssd.mindestbetrag_bei_abtretung)),
    bssd.waehrung_mindestbetrag_bei_abtretung,
    formatAuslandsfaehigkeit(bssd.auslandsfaehigkeit),
    formatJaNein(bssd.vag_konform),
    formatJaNein(bssd.mrel_72b_crr),
    bssd.kwg_46f,
    formatJaNein(bssd.sag_49),
    formatJaNein(bssd.tlac),
    bssd.darlehensnehmer_name,
    bssd.umsatzsteuer_id_darlehensnehmer,
    bssd.anschrift_darlehensnehmer?.replaceAll("\n", " "),
    bssd.telefonnummer_darlehensnehmer,
    bssd.lei_darlehensnehmer,
    bssd.digits_kennung_darlehensnehmer,
    bssd.erfassender_mitarbeiter_darlehensnehmer,
    bssd.interne_kennung_darlehensnehmer,
    bssd.vertragsreferenz_nr_darlehensnehmer,
    bssd.unser_zeichen_darlehensnehmer,
    bssd.iban_darlehensnehmer,
    bssd.bic_code_darlehensnehmer,
    bssd.darlehensgeber_name,
    bssd.umsatzsteuer_id_darlehensgeber,
    bssd.anschrift_darlehensgeber?.replaceAll("\n", " "),
    bssd.telefonnummer_darlehensgeber,
    bssd.lei_darlehensgeber,
    bssd.digits_kennung_darlehensgeber,
    bssd.erfassender_mitarbeiter_darlehensgeber,
    bssd.interne_kennung_darlehensgeber,
    bssd.vertragsreferenz_nr_darlehensgeber,
    bssd.unser_zeichen_darlehensgeber,
    bssd.iban_darlehensgeber,
    bssd.iban_darlehensgeber_tilgung,
    bssd.bic_code_darlehensgeber,
    bssd.bic_code_darlehensgeber_tilgung,
  ];
};

const buildBssdMatrix = (bssds) => {
  const bssdMatrix = bssds.map(buildBssdRow);
  return bssdMatrix;
};

//#endregion
