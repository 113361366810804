// action types
export const SAVE_CSSDS_REQUEST = "SAVE_CSSDS_REQUEST";
export const SAVE_CSSDS_SUCCESS = "SAVE_CSSDS_SUCCESS";
export const SAVE_CSSDS_FAILURE = "SAVE_CSSDS_FAILURE";

export const SAVE_CONFIRM_CSSDS_REQUEST = "SAVE_CONFIRM_CSSDS_REQUEST";
export const SAVE_CONFIRM_CSSDS_SUCCESS = "SAVE_CONFIRM_CSSDS_SUCCESS";
export const SAVE_CONFIRM_CSSDS_FAILURE = "SAVE_CONFIRM_CSSDS_FAILURE";

export const CONFIRM_CSSD_REQUEST = "CONFIRM_CSSD_REQUEST";
export const CONFIRM_CSSD_SUCCESS = "CONFIRM_CSSD_SUCCESS";
export const CONFIRM_CSSD_FAILURE = "CONFIRM_CSSD_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const saveCssdActions = {
  request: (account, cssdData) => action(SAVE_CSSDS_REQUEST, { account, cssdData }),
  success: () => action(SAVE_CSSDS_SUCCESS, {}),
  failure: (error) => action(SAVE_CSSDS_FAILURE, { error }),
};

export const saveAndConfirmCssdActions = {
  request: (account, cssdData) => action(SAVE_CONFIRM_CSSDS_REQUEST, { account, cssdData }),
  success: () => action(SAVE_CONFIRM_CSSDS_SUCCESS, {}),
  failure: (error) => action(SAVE_CONFIRM_CSSDS_FAILURE, { error }),
};

export const confirmCssdActions = {
  request: (account, cssdData) => action(CONFIRM_CSSD_REQUEST, { account, cssdData }),
  success: () => action(CONFIRM_CSSD_SUCCESS),
  failure: (error) => action(CONFIRM_CSSD_FAILURE, { error }),
};

export const ACCEPT_CSSD_REQUEST = "ACCEPT_CSSD_REQUEST";
export const ACCEPT_CSSD_SUCCESS = "ACCEPT_CSSD_SUCCESS";
export const ACCEPT_CSSD_FAILURE = "ACCEPT_CSSD_FAILURE";

export const acceptCssdActions = {
  request: (account, cssdData) => action(ACCEPT_CSSD_REQUEST, { account, cssdData }),
  success: () => action(ACCEPT_CSSD_SUCCESS),
  failure: (error) => action(ACCEPT_CSSD_FAILURE, { error }),
};

export const UPDATE_CSSD_REQUEST = "UPDATE_CSSD_REQUEST";
export const UPDATE_CSSD_SUCCESS = "UPDATE_CSSD_SUCCESS";
export const UPDATE_CSSD_FAILURE = "UPDATE_CSSD_FAILURE";

export const updateCssdActions = {
  request: (account, cssdData) => action(UPDATE_CSSD_REQUEST, { account, cssdData }),
  success: () => action(UPDATE_CSSD_SUCCESS),
  failure: (error) => action(UPDATE_CSSD_FAILURE, { error }),
};

export const UPDATE_ACCEPT_CSSD_REQUEST = "UPDATE_ACCEPT_CSSD_REQUEST";
export const UPDATE_ACCEPT_CSSD_SUCCESS = "UPDATE_ACCEPT_CSSD_SUCCESS";
export const UPDATE_ACCEPT_CSSD_FAILURE = "UPDATE_ACCEPT_CSSD_FAILURE";

export const updateAcceptCssdActions = {
  request: (account, cssdData) => action(UPDATE_ACCEPT_CSSD_REQUEST, { account, cssdData }),
  success: () => action(UPDATE_ACCEPT_CSSD_SUCCESS),
  failure: (error) => action(UPDATE_ACCEPT_CSSD_FAILURE, { error }),
};

export const REJECT_CSSD_REQUEST = "REJECT_CSSD_REQUEST";
export const REJECT_CSSD_SUCCESS = "REJECT_CSSD_SUCCESS";
export const REJECT_CSSD_FAILURE = "REJECT_CSSD_FAILURE";

export const rejectCssdActions = {
  request: (account, cssdData, reason) =>
    action(REJECT_CSSD_REQUEST, {
      account,
      cssdData,
      reason,
    }),
  success: () => action(REJECT_CSSD_SUCCESS, {}),
  failure: (error) => action(REJECT_CSSD_FAILURE, { error }),
};

export const CANCEL_CSSD_REQUEST = "CANCEL_CSSD_REQUEST";
export const CANCEL_CSSD_SUCCESS = "CANCEL_CSSD_SUCCESS";
export const CANCEL_CSSD_FAILURE = "CANCEL_CSSD_FAILURE";

export const cancelCssdActions = {
  request: (account, cssdData) => action(CANCEL_CSSD_REQUEST, { account, cssdData }),
  success: () => action(CANCEL_CSSD_SUCCESS, {}),
  failure: (error) => action(CANCEL_CSSD_FAILURE, { error }),
};

export const UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST =
  "UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST";
export const UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS =
  "UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS";
export const UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE =
  "UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE";

export const UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_CLOSE_DIALOG =
  "UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_CLOSE_DIALOG";
export const UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_OPEN_DIALOG =
  "UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_OPEN_DIALOG";

export const ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST =
  "ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST";
export const ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS =
  "ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS";
export const ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE =
  "ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE";

export const REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST =
  "REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST";
export const REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS =
  "REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS";
export const REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE =
  "REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE";

export const signCssdDocumentsActions = {
  uploadRequest: (cssd, files) =>
    action(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, {
      cssd,
      files,
    }),
  uploadSuccess: () => action(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS),
  uploadFailure: (error) => action(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE, { error }),
  closeDialog: () => action(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_CLOSE_DIALOG),
  openDialog: (cssd) => action(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_OPEN_DIALOG, { cssd }),

  acceptRequest: (cssd) => action(ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, { cssd }),
  acceptSuccess: () => action(ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS, {}),
  acceptFailure: (error) => action(ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE, { error }),

  rejectRequest: (cssd, reason) =>
    action(REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, { cssd, reason }),
  rejectSuccess: () => action(REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS, {}),
  rejectFailure: (error) => action(REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE, { error }),
};

export const DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST =
  "DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST";
export const DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_SUCCESS =
  "DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_SUCCESS";
export const DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_FAILURE =
  "DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_FAILURE";

export const downloadUrkundeZahlstellvertragActions = {
  request: (cssd, documentType, account) =>
    action(DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST, {
      cssd,
      documentType,
      account,
    }),
  success: () => action(DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_SUCCESS, {}),
  failure: (error) => action(DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_FAILURE, { error }),
};

export const DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST = "DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST";
export const DOWNLOAD_CSSD_CONFIRMATION_PDF_SUCCESS = "DOWNLOAD_CSSD_CONFIRMATION_PDF_SUCCESS";
export const DOWNLOAD_CSSD_CONFIRMATION_PDF_FAILURE = "DOWNLOAD_CSSD_CONFIRMATION_PDF_FAILURE";

export const downloadCssdConfirmationPdfActions = {
  request: (cssd) => action(DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST, { cssd }),
  success: () => action(DOWNLOAD_CSSD_CONFIRMATION_PDF_SUCCESS, {}),
  failure: (error) => action(DOWNLOAD_CSSD_CONFIRMATION_PDF_FAILURE, { error }),
};

export const DOWNLOAD_URKUNDE_PDF_REQUEST = "DOWNLOAD_URKUNDE_PDF_REQUEST";
export const DOWNLOAD_URKUNDE_PDF_SUCCESS = "DOWNLOAD_URKUNDE_PDF_SUCCESS";
export const DOWNLOAD_URKUNDE_PDF_FAILURE = "DOWNLOAD_URKUNDE_PDF_FAILURE";

export const downloadCssdUrkundePdfActions = {
  request: (id_proxy_arranger, cssdId) =>
    action(DOWNLOAD_URKUNDE_PDF_REQUEST, {
      id_proxy_arranger,
      cssdId,
    }),
  success: () => action(DOWNLOAD_URKUNDE_PDF_SUCCESS, {}),
  failure: (error) => action(DOWNLOAD_URKUNDE_PDF_FAILURE, { error }),
};

export const CSSD_REPAYMENT_RECEIVED_REQUEST = "CSSD_REPAYMENT_RECEIVED_REQUEST";
export const CSSD_REPAYMENT_RECEIVED_SUCCESS = "CSSD_REPAYMENT_RECEIVED_SUCCESS";
export const CSSD_REPAYMENT_RECEIVED_FAILURE = "CSSD_REPAYMENT_RECEIVED_FAILURE";
export const cssdRepaymentReceivedActions = {
  request: () => action(CSSD_REPAYMENT_RECEIVED_REQUEST),
  success: () => action(CSSD_REPAYMENT_RECEIVED_SUCCESS),
  failure: (error) => action(CSSD_REPAYMENT_RECEIVED_FAILURE, { error }),
};

export const OPEN_CONFIRM_REPAYMENT_RECEIVED_DIALOG = "OPEN_CONFIRM_REPAYMENT_RECEIVED_DIALOG";
export const CLOSE_CONFIRM_REPAYMENT_RECEIVED_DIALOG = "CLOSE_CONFIRM_REPAYMENT_RECEIVED_DIALOG";
export const confirmRepaymentReceivedDialogActions = {
  open: (cssd) => action(OPEN_CONFIRM_REPAYMENT_RECEIVED_DIALOG, { cssd }),
  close: () => action(CLOSE_CONFIRM_REPAYMENT_RECEIVED_DIALOG),
};

export const EDIT_CSSD_SETTLEMENT_INFORMATION = "EDIT_CSSD_SETTLEMENT_INFORMATION";
export const SAVE_CSSD_SETTLEMENT_INFORMATION = "SAVE_CSSD_SETTLEMENT_INFORMATION";
export const SAVE_CSSD_SETTLEMENT_INFORMATION_FAILURE = "SAVE_CSSD_SETTLEMENT_INFORMATION_FAILURE";
export const SAVE_CSSD_SETTLEMENT_INFORMATION_SUCCESS = "SAVE_CSSD_SETTLEMENT_INFORMATION_SUCCESS";
export const CLOSE_CSSD_SETTLEMENT_INFORMATION = "CLOSE_CSSD_SETTLEMENT_INFORMATION";

export const editCssdSettlementInformationActions = {
  openModal: (cssd) => action(EDIT_CSSD_SETTLEMENT_INFORMATION, { cssd }),
  save: (cssd, isDN) => action(SAVE_CSSD_SETTLEMENT_INFORMATION, { cssd, isDN }),
  failure: () => action(SAVE_CSSD_SETTLEMENT_INFORMATION_FAILURE),
  success: () => action(SAVE_CSSD_SETTLEMENT_INFORMATION_SUCCESS),
  closeModal: () => action(CLOSE_CSSD_SETTLEMENT_INFORMATION),
};
