import React from "react";
import { CSSDCessionState, CSSDFourEyesState as FourEyesState, ZERO_HASH } from "util/constants";
import {
  ImportIcon,
  StateInWorkIcon,
  StateCancelledIcon,
  StateDisplayedIcon,
  StateSignedIcon,
} from "../Icons";

const CssdCessionStatus = ({ status, fourEyesState, reasonHash, imported }) => {
  let statusTextKey = "",
    icon = imported ? <ImportIcon /> : <StateInWorkIcon />,
    color = imported ? "success" : "default";

  reasonHash = reasonHash || ZERO_HASH;

  switch (true) {
    case status === CSSDCessionState.NEW:
    case status === CSSDCessionState.IN_WORK &&
      reasonHash === ZERO_HASH &&
      fourEyesState === FourEyesState.NONE:
      statusTextKey = "registered";
      break;
    case status === CSSDCessionState.IN_WORK && fourEyesState === FourEyesState.CESSION_OFFER_FIRST:
      statusTextKey = "offer_first";
      break;
    case status === CSSDCessionState.OPEN && fourEyesState === FourEyesState.NONE:
      statusTextKey = "open";
      break;
    case status === CSSDCessionState.OPEN && fourEyesState === FourEyesState.CESSION_ACCEPT_FIRST:
      statusTextKey = "accept_first";
      break;
    case status === CSSDCessionState.APPROVED:
      statusTextKey = "approved";
      break;
    case status === CSSDCessionState.IN_WORK &&
      reasonHash !== ZERO_HASH &&
      fourEyesState === FourEyesState.NONE:
      statusTextKey = "rejected";
      color = "error";
      icon = <StateCancelledIcon />;
      break;
    case status === CSSDCessionState.CANCELLED:
      statusTextKey = "cancelled";
      color = "error";
      icon = <StateCancelledIcon />;
      break;
    case status === CSSDCessionState.ABORTED:
      statusTextKey = "aborted";
      color = "error";
      icon = <StateCancelledIcon />;
      break;
    case status === CSSDCessionState.PAYMENT_RECEIVED && fourEyesState === FourEyesState.NONE:
      statusTextKey = "payment_received";
      break;
    case status === CSSDCessionState.PAYMENT_RECEIVED &&
      fourEyesState === FourEyesState.CESSION_CERTIFICATE_OFFER_FIRST:
      statusTextKey = "cert_offer_first";
      break;
    case status === CSSDCessionState.CERT_OPEN &&
      fourEyesState === FourEyesState.CESSION_CERTIFICATE_ACCEPT_FIRST:
      statusTextKey = "cert_accept_first";
      break;
    case status === CSSDCessionState.CERT_OPEN:
      statusTextKey = "cert_open";
      icon = <StateSignedIcon />;
      break;
    case status === CSSDCessionState.CERT_APPROVED:
      statusTextKey = "cert_approved";
      icon = <StateSignedIcon />;
      break;
    case status === CSSDCessionState.NOTIFIED:
      statusTextKey = "notified";
      icon = <StateDisplayedIcon />;
      break;
    default:
      return {};
  }

  return { statusTextKey, color, icon };
};

export default CssdCessionStatus;
