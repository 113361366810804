import { defineMessages } from "react-intl";

export const messages = defineMessages({
  shared_format_field_table_cell_text_kuendigungstermin: {
    id: "shared.format.field.table.cell.text.kuendigungstermin",
    defaultMessage: "Kündigungstermin",
  },
  shared_format_field_table_cell_text_valuta: {
    id: "shared.format.field.table.cell.text.valuta",
    defaultMessage: "-valuta",
  },
  shared_format_field_table_cell_text_kurs_in_prozent: {
    id: "shared.format.field.table.cell.text.kurs.in.prozent",
    defaultMessage: "-kurs (in %)",
  },
  januar: {
    id: "shared.format.field.januar",
    defaultMessage: "Januar",
  },
  februar: {
    id: "shared.format.field.februar",
    defaultMessage: "Februar",
  },
  maerz: {
    id: "shared.format.field.maerz",
    defaultMessage: "März",
  },
  april: {
    id: "shared.format.field.april",
    defaultMessage: "April",
  },
  mai: {
    id: "shared.format.field.mai",
    defaultMessage: "Mai",
  },
  juni: {
    id: "shared.format.field.juni",
    defaultMessage: "Juni",
  },
  juli: {
    id: "shared.format.field.juli",
    defaultMessage: "Juli",
  },
  august: {
    id: "shared.format.field.august",
    defaultMessage: "August",
  },
  september: {
    id: "shared.format.field.september",
    defaultMessage: "September",
  },
  oktober: {
    id: "shared.format.field.oktober",
    defaultMessage: "Oktober",
  },
  november: {
    id: "shared.format.field.november",
    defaultMessage: "November",
  },
  dezember: {
    id: "shared.format.field.dezember",
    defaultMessage: "Dezember",
  },
  zins_fix_fix: {
    id: "shared.format.field.zins_fix_fix",
    defaultMessage: "fix",
  },
  zins_fix_var: {
    id: "shared.format.field.zins_fix_var",
    defaultMessage: "variabel",
  },
  zins_fix_fix_vertrag: {
    id: "shared.format.field.zins_fix_fix_vertrag",
    defaultMessage: "fix gemäß Vertrag",
  },
  zins_fix_var_vertrag: {
    id: "shared.format.field.zins_fix_var_vertrag",
    defaultMessage: "variabel gemäß Vertrag",
  },
  keine_marge: {
    id: "shared.format.field.keine_marge",
    defaultMessage: "Keine Marge",
  },
  aufschlag: {
    id: "shared.format.field.aufschlag",
    defaultMessage: "Aufschlag",
  },
  abschlag: {
    id: "shared.format.field.abschlag",
    defaultMessage: "Abschlag",
  },
  zahlungsrhythmus_jaehrlich: {
    id: "shared.format.field.zahlungsrhythmus.jaehrlich",
    defaultMessage: "Jährlich",
  },
  zahlungsrhythmus_halbjaehrlich: {
    id: "shared.format.field.zahlungsrhythmus.halbjaehrlich",
    defaultMessage: "Halbjährlich",
  },
  zahlungsrhythmus_vierteljaehrlich: {
    id: "shared.format.field.zahlungsrhythmus.vierteljaehrlich",
    defaultMessage: "Vierteljährlich",
  },
  zahlungsrhythmus_monatlich: {
    id: "shared.format.field.zahlungsrhythmus.monatlich",
    defaultMessage: "Monatlich",
  },
  zinsmethode: {
    id: "shared.format.field.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  zinsmethode_act_act_icma: {
    id: "shared.format.field.zinsmethode.act_act_icma",
    defaultMessage: "act/act ICMA Rule 251",
  },
  zinsmethode_act_act_isda: {
    id: "shared.format.field.zinsmethode.act_act_isda",
    defaultMessage: "act/act ISDA",
  },
  zinsmethode_act_360: {
    id: "shared.format.field.zinsmethode.act_360",
    defaultMessage: "act/360 (Eurozinsmethode, französische Zinsmethode)",
  },
  zinsmethode_act_365: {
    id: "shared.format.field.zinsmethode.act_365",
    defaultMessage: "act/365 (englische Zinsmethode)",
  },
  zinsmethode_30_360: {
    id: "shared.format.field.zinsmethode.30_360",
    defaultMessage: "30/360 (deutsche Zinsmethode)",
  },
  zinsmethode_30e_360: {
    id: "shared.format.field.zinsmethode.30e_360",
    defaultMessage: "30E/360 (US-Zinsmethode)",
  },
  // Hier sind bewusst die beiden Sprachen enthalten!
  faelligkeitstagDerZinszahlung_following_nachfolgend: {
    id: "shared.format.field.faelligkeitstagDerZinszahlung.following_nachfolgend",
    defaultMessage: "following-nachfolgend",
  },
  faelligkeitstagDerZinszahlung_modified_following_nachfolgend_modifiziert: {
    id:
      "shared.format.field.faelligkeitstagDerZinszahlung.modified_following_nachfolgend_modifiziert",
    defaultMessage: "modified following-nachfolgend modifiziert",
  },
  faelligkeitstagDerZinszahlung_preceding_vorhergehend: {
    id: "shared.format.field.faelligkeitstagDerZinszahlung.preceding_vorhergehend",
    defaultMessage: "preceding-vorhergehend",
  },
  berechnungszeitraumDerZinszahlung_unadjusted: {
    id: "shared.format.field.berechnungszeitraumDerZinszahlung.unadjusted",
    defaultMessage: "unadjusted-nicht angepasst",
  },
  berechnungszeitraumDerZinszahlung_adjusted: {
    id: "shared.format.field.berechnungszeitraumDerZinszahlung.adjusted",
    defaultMessage: "adjusted-angepasst",
  },
  gesamttilgung: {
    id: "shared.format.field.gesamttilgung",
    defaultMessage: "Gesamttilgung am Laufzeitende",
  },
  stepupStepdownRegelung_keine_regelung: {
    id: "shared.format.field.stepupStepdownRegelung.keine_regelung",
    defaultMessage: "kein Stepup/-down",
  },
  stepupStepdownRegelung_vertragliche_regelung: {
    id: "shared.format.field.stepupStepdownRegelung.vertragliche_regelung",
    defaultMessage: "Stepup/-down siehe Vertragliche Regelung",
  },
  variableVerzinsung: {
    id: "shared.format.field.zinsfestlegungsart.variableVerzinsung",
    defaultMessage: "variable Verzinsung",
  },
  variableVerzinsungUpDown: {
    id: "shared.format.field.zinsfestlegungsart.variableVerzinsungUpDown",
    defaultMessage: "variable Verzinsung und Stepup/-down",
  },
  stepUpDown: {
    id: "shared.format.field.zinsfestlegungsart.stepUpDown",
    defaultMessage: "Stepup/-down",
  },
  bool_true: {
    id: "shared.format.field.bool_true",
    defaultMessage: "Ja",
  },
  bool_false: {
    id: "shared.format.field.bool_false",
    defaultMessage: "Nein",
  },
  en: {
    id: "shared.format.field.en",
    defaultMessage: "Englisch",
  },
  de: {
    id: "shared.format.field.de",
    defaultMessage: "Deutsch",
  },
});
