import web3 from "util/web3";
import Contracts from "services/smartContracts/contracts/contracts";

import {
  PRIVILEGE_CONTROLLER_COORDINATOR,
  PRIVILEGE_CONTROLLER_RECOVERY,
  PRODUCT_SSD,
  PRODUCT_CSSD,
} from "util/constants";

import { contractCache } from "services/smartContracts/smartContracts";
import { sendDeployTransaction, sendTransaction, toHex } from "./commons";
import { requestEtherForWallet } from "./faucet";
//
// ONBOARDING
//

async function createSimplePrivilegeManagerController(account, ukAddress, recovererAddr) {
  const SimplePrivilegeManager = new web3.eth.Contract(Contracts.simplePrivilegeManager.abi);
  const deploy = SimplePrivilegeManager.deploy({
    data: Contracts.simplePrivilegeManager.bytecode,
    arguments: [
      PRIVILEGE_CONTROLLER_COORDINATOR,
      PRIVILEGE_CONTROLLER_RECOVERY,
      ukAddress,
      recovererAddr,
    ],
  }).encodeABI();
  const { contractAddress } = await sendDeployTransaction(account, deploy);
  return contractAddress;
}

function link(bytecode, libName, libAddress) {
  let symbol = "__" + libName + "_".repeat(40 - libName.length - 2);
  return bytecode.split(symbol).join(libAddress.toLowerCase().substr(2));
}

async function createMasterController(
  privilegeManagerAddress,
  account,
  registry,
  vier_augen_bestaetigung
) {
  const MasterController = new web3.eth.Contract(Contracts.masterController.abi);
  const deploy = MasterController.deploy({
    data: link(Contracts.masterController.bytecode, "SharedLib", registry.SharedLib),
    arguments: [
      contractCache.identityProxy._address,
      contractCache.platformManagerContract._address,
      privilegeManagerAddress,
      vier_augen_bestaetigung,
    ],
  }).encodeABI();
  const { contractAddress } = await sendDeployTransaction(account, deploy);
  return contractAddress;
}

async function createBSSDController(account, controllerAddress, fourEyes) {
  const BSSDController = new web3.eth.Contract(Contracts.bssdController.abi);
  const deploy = BSSDController.deploy({
    data: Contracts.bssdController.bytecode,
    arguments: [controllerAddress, fourEyes],
  }).encodeABI();
  const { contractAddress } = await sendDeployTransaction(account, deploy);
  return contractAddress;
}

async function createCSSDController(account, controllerAddress, fourEyes, registry) {
  const withShared = link(Contracts.cssdController.bytecode, "SharedLib", registry.SharedLib);
  const withCessionLib = link(
    withShared,
    "CSSDControllerCessionLib",
    registry.CSSDControllerCessionLib
  );
  const withCssdLib = link(withCessionLib, "CSSDControllerCSSDLib", registry.CSSDControllerCSSDLib);
  const withInterestLib = link(
    withCssdLib,
    "CSSDControllerInterestLib",
    registry.CSSDControllerInterestLib
  );
  const withTermination = link(
    withInterestLib,
    "CSSDControllerTerminationLib",
    registry.CSSDControllerTerminationLib
  );
  const withPartialClaim = link(
    withTermination,
    "CSSDControllerPartialClaimLib",
    registry.CSSDControllerPartialClaimLib
  );

  const CSSDController = new web3.eth.Contract(Contracts.cssdController.abi);
  const deploy = CSSDController.deploy({
    data: withPartialClaim,
    arguments: [controllerAddress, fourEyes],
  }).encodeABI();
  const { contractAddress } = await sendDeployTransaction(account, deploy);
  return contractAddress;
}

async function getPlatformRegistry() {
  const platform = contractCache.platformManagerContract;
  const nameRegistryAddr = await platform.methods.registry().call();
  const nameRegistry = new web3.eth.Contract(Contracts.NameRegistry.abi, nameRegistryAddr);
  const addresses = await nameRegistry.methods
    .getArray([
      toHex("SharedLib"),
      toHex("CSSDControllerCessionLib"),
      toHex("CSSDControllerCSSDLib"),
      toHex("CSSDControllerInterestLib"),
      toHex("CSSDControllerPartialClaimLib"),
      toHex("CSSDControllerTerminationLib"),
    ])
    .call();
  return {
    SharedLib: addresses[0],
    CSSDControllerCessionLib: addresses[1],
    CSSDControllerCSSDLib: addresses[2],
    CSSDControllerInterestLib: addresses[3],
    CSSDControllerPartialClaimLib: addresses[4],
    CSSDControllerTerminationLib: addresses[5],
  };
}

export async function onboardMember(
  account,
  ukAddress,
  backend_url_id_proxy,
  vier_augen_bestaetigung,
  recovererAddr
) {
  const registry = await getPlatformRegistry();

  const controllerPm = await createSimplePrivilegeManagerController(
    account,
    ukAddress,
    recovererAddr
  );

  const controllerAddr = await createMasterController(
    controllerPm,
    account,
    registry,
    vier_augen_bestaetigung
  );

  const controller = new web3.eth.Contract(Contracts.masterController.abi, controllerAddr);

  const bssdControllerAddr = await createBSSDController(
    account,
    controllerAddr,
    vier_augen_bestaetigung
  );

  const cssdControllerAddr = await createCSSDController(
    account,
    controllerAddr,
    vier_augen_bestaetigung,
    registry
  );

  const data = contractCache.masterController.methods
    .onboardMember(controllerAddr, backend_url_id_proxy)
    .encodeABI();

  await sendTransaction(account, contractCache.masterController._address, data);

  //console.error("registerSubcontroller");

  const registerControllers = controller.methods
    .registerSubcontrollers([PRODUCT_SSD, PRODUCT_CSSD], [bssdControllerAddr, cssdControllerAddr])
    .encodeABI();
  await sendTransaction(account, controllerAddr, registerControllers);

  //console.error("register complete");

  const companyIdProxyAddr = await controller.methods.idProxy().call();
  //console.error(companyIdProxyAddr);
  await requestEtherForWallet(account, ukAddress, companyIdProxyAddr);
  return companyIdProxyAddr;
}
