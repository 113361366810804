import {
  FETCH_SSD_HISTORY_REQUEST,
  FETCH_SSD_HISTORY_SUCCESS,
  FETCH_SSD_HISTORY_FAILURE,
  OPEN_HISTORY_MODAL,
  CLOSE_HISTORY_MODAL,
  OPEN_INTEREST_HITORY_MODAL,
  CLOSE_INTEREST_HITORY_MODAL,
  FETCH_INTEREST_HISTORY_REQUEST,
  FETCH_INTEREST_HISTORY_SUCCESS,
  FETCH_INTEREST_HISTORY_FAILURE,
  OPEN_CESSION_HISTORY_MODAL,
  CLOSE_CESSION_HISTORY_MODAL,
  FETCH_CESSION_HISTORY_REQUEST,
  FETCH_CESSION_HISTORY_SUCCESS,
  FETCH_CESSION_HISTORY_FAILURE,
} from "./actions";

// reducer with initial state
const initialState = {
  ssdHistory: [],
  historyPending: false,
  historyModalOpen: false,

  interestHistory: [],
  interestHistoryPending: false,
  interestHistoryModalOpen: false,

  cessionHistory: [],
  cessionHistoryPending: false,
  cessionHistoryModalOpen: false,
};

function ssdHistoryRequestReducer(state) {
  return {
    ...state,
    historyPending: true,
  };
}

function ssdHistorySuccessReducer(state, action) {
  return {
    ...state,
    historyPending: false,
    ssdHistory: action.ssdHistory,
  };
}

function ssdHistoryFailureReducer(state) {
  return {
    ...state,
    historyPending: false,
  };
}

function openHistoryModalReducer(state) {
  return {
    ...state,
    historyModalOpen: true,
  };
}

function closeHistoryModalReducer(state) {
  return {
    ...state,
    historyModalOpen: false,
    ssdHistory: [],
  };
}

function interestHistoryRequestReducer(state) {
  return {
    ...state,
    interestHistoryPending: true,
  };
}

function interestHistoryFailureReducer(state) {
  return {
    ...state,
    interestHistoryPending: false,
  };
}

function interestHistorySuccessReducer(state, action) {
  return {
    ...state,
    interestHistoryPending: false,
    interestHistory: action.interestHistory,
  };
}

function openInterestHistoryModalReducer(state) {
  return {
    ...state,
    interestHistoryModalOpen: true,
  };
}

function closeInterestHistoryModalReducer(state) {
  return {
    ...state,
    interestHistoryModalOpen: false,
    interestHistory: [],
  };
}

function openCessionHistoryModalReducer(state) {
  return {
    ...state,
    cessionHistoryModalOpen: true,
  };
}

function closeCessionHistoryModalReducer(state) {
  return {
    ...state,
    cessionHistoryModalOpen: false,
    cessionHistory: [],
  };
}

function cessionHistoryRequestReducer(state) {
  return {
    ...state,
    cessionHistoryPending: true,
  };
}

function cessionHistoryFailureReducer(state) {
  return {
    ...state,
    cessionHistoryPending: false,
  };
}

function cessionHistorySuccessReducer(state, action) {
  return {
    ...state,
    cessionHistoryPending: false,
    cessionHistory: action.cessionHistory,
  };
}

export function auditReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SSD_HISTORY_REQUEST:
      return ssdHistoryRequestReducer(state, action);
    case FETCH_SSD_HISTORY_SUCCESS:
      return ssdHistorySuccessReducer(state, action);
    case FETCH_SSD_HISTORY_FAILURE:
      return ssdHistoryFailureReducer(state);

    case OPEN_HISTORY_MODAL:
      return openHistoryModalReducer(state, action);
    case CLOSE_HISTORY_MODAL:
      return closeHistoryModalReducer(state, action);

    case OPEN_INTEREST_HITORY_MODAL:
      return openInterestHistoryModalReducer(state, action);
    case CLOSE_INTEREST_HITORY_MODAL:
      return closeInterestHistoryModalReducer(state, action);

    case FETCH_INTEREST_HISTORY_REQUEST:
      return interestHistoryRequestReducer(state, action);
    case FETCH_INTEREST_HISTORY_SUCCESS:
      return interestHistorySuccessReducer(state, action);
    case FETCH_INTEREST_HISTORY_FAILURE:
      return interestHistoryFailureReducer(state);

    case OPEN_CESSION_HISTORY_MODAL:
      return openCessionHistoryModalReducer(state, action);
    case CLOSE_CESSION_HISTORY_MODAL:
      return closeCessionHistoryModalReducer(state, action);

    case FETCH_CESSION_HISTORY_REQUEST:
      return cessionHistoryRequestReducer(state, action);
    case FETCH_CESSION_HISTORY_SUCCESS:
      return cessionHistorySuccessReducer(state, action);
    case FETCH_CESSION_HISTORY_FAILURE:
      return cessionHistoryFailureReducer(state, action);

    default:
      return state;
  }
}
