import React from "react";
import Chip from "@material-ui/core/Chip";
import SuccessChip from "./SuccessChip";
import ErrorChip from "./ErrorChip";

const ColoredChip = (props) => {
  const { color, ...otherProps } = props;
  switch (color) {
    case "success":
      return <SuccessChip {...otherProps} />;
    case "error":
      return <ErrorChip {...otherProps} />;
    default:
      return <Chip color={color} {...otherProps} />;
  }
};

export default ColoredChip;
