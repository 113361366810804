import { call, put, select, takeLatest, fork, takeEvery, takeLeading } from "redux-saga/effects";

import {
  detailsTeilforderung,
  FETCH_CSSD_PARTIALCLAIM_SUM_REQUEST,
  FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST,
  fetchPartialClaimsBulkActions,
  fetchPartialClaimsForCssdActions,
  fetchSumOfAllPartialClaimsActions,
  FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE,
  filterPartialClaimForCssdTable,
  LOAD_CSSD_TEILFORDERUNG_DETAIL,
  LOAD_TEILFORDERUNGEN_REQUEST,
  SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE,
  sortPartialClaimForCssdTable,
  UPDATE_PARTIAL_CLAIM_FROM_EVENT,
  cssdPartialClaimRepaymentReceivedActions,
  CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST,
  LOAD_TEILFORDERUNGEN_RETRY,
  SAVE_SETTLEMENTINFORMATION,
  editSettlementInformation,
  CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING,
  cssdExportPartialClaim,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST,
  cssdPartialClaimActions,
  BULK_OFFER_PARTIAL_CLAIM,
  BULK_CANCEL_PARTIAL_CLAIM,
  bulkPartialClaimActions,
  CANCEL_PARTIAL_CLAIM_REQUEST,
  CONFIRM_PARTIAL_CLAIM_REQUEST,
} from "./actions";
import {
  fetchPartialClaimsForCssd as fetchPartialClaimsForCssdFromBackend,
  fetchSumOfAllPartialClaims as fetchSumOfAllPartialClaimsFromBackend,
  queryTeilforderungDetails,
  queryTeilforderungenById,
} from "services/cssdService";
import { getIdProxyAddr, getPartialClaimEventFromBc, toHex } from "services/web3Services/commons";
import { repaymentReceived } from "services/web3Services/cssdCession";
import {
  getAccount,
  getPrivileges,
  getCompanyInfo,
  isPartialClaimCached,
  isZahlstelleSelector,
  isPartialClaimReferencedByCession,
  hasAccessToCssd,
} from "redux/selectors";
import { toUtf8 } from "services/web3Services/commons";
import {
  generateCSSDCessionFingerprints,
  generateCssdSettlementInformationFingerprint,
  generateCssdTerminationFingerprint,
} from "util/fingerprint";
import {
  storeMigrierteAbtretungTeilforderung,
  updateNewDgInfoForCession,
  fetchConfirmedRestOfAllCessions,
} from "services/cssdCessionService";
import { appIntl } from "components/i18n/intl";
import { snackbarActions } from "redux/shared/actions";
import { messages } from "./messages";
import {
  bulkCancelMigratedPartialClaim,
  bulkOfferMigratedPartialClaim,
  updatePartialClaimSettlementInformation,
  modifyAndOfferMigratedPartialClaim,
  cancelMigratedPartialClaim,
  sendOfferMigratedPartialClaim,
} from "services/web3Services/cssd";
import { downloadByteArrayAsCSV, generatePartialClaimCSV } from "util/export";
import { dateToTimestamp, formatNumberWithEmptyData } from "util/convertor";
import {
  PartialClaimState,
  zeroAddress,
  PartialClaimAction,
  CSSDTerminationState,
} from "util/constants";
import { migrateTerminatedPartialClaim } from "services/web3Services/cssdTermination";
import {
  storeCssdTeilforderungKuendigung,
  updateCssdKuendigungStatus,
  updateCssdTeilforderungKuendigung,
} from "services/cssdTermination";
import { cssdEventActions } from "redux/cssdEvents/actions";
import { cessionActions } from "redux/cssdCession/actions";
import { isPartialClaimLoading } from "redux/selectors";
import { fetchCssdFromArranger } from "redux/cssdEvents/sagas";

export const cssdTeilforderungenSagas = [
  takeLeading(LOAD_TEILFORDERUNGEN_REQUEST, fetchAllTeilforderungenBulkSaga),
  takeLatest(LOAD_TEILFORDERUNGEN_RETRY, retryFetchTeilforderungenBulkSaga),
  takeLatest(FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST, fetchPartialClaimsForCssdSaga),
  takeLatest(LOAD_CSSD_TEILFORDERUNG_DETAIL, fetchTeilforderungDetails),
  takeLeading(FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE, filterPartialClaimForCssdTable),
  takeLatest(SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE, sortPartialClaimForCssdTable),
  takeLatest(FETCH_CSSD_PARTIALCLAIM_SUM_REQUEST, fetchSumOfAllPartialClaims),
  takeEvery(UPDATE_PARTIAL_CLAIM_FROM_EVENT, fetchPartialClaimForUpdate),
  takeLatest(CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST, cssdRepaymentReceivedSagas),
  takeLatest(SAVE_SETTLEMENTINFORMATION, updateSettlementInformation),
  takeLatest(CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING, exportClaims),
  takeLatest(SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST, saveAndConfirmPartialClaimSaga),
  takeLatest(BULK_OFFER_PARTIAL_CLAIM, bulkOfferMigratedPartialClaimSaga),
  takeLatest(BULK_CANCEL_PARTIAL_CLAIM, bulkCancelMigratedPartialClaimSaga),
  takeLatest(CANCEL_PARTIAL_CLAIM_REQUEST, cancelMigratedPartialClaimSaga),
  takeLatest(CONFIRM_PARTIAL_CLAIM_REQUEST, confirmMigratedPartialClaimSaga),
];

function* fetchAllTeilforderungenBulkSaga({ lastBlockNumber, loadedClaims = [] }) {
  const companyInfo = yield select(getCompanyInfo);
  const { privilegeCssdOffer: isZSM } = yield select(getPrivileges);
  const events = yield call(
    getPartialClaimEventFromBc,
    companyInfo.idProxyAddress,
    isZSM,
    lastBlockNumber > 0 ? lastBlockNumber + 1 : 0
  );

  const lastBlockFromChain = events
    .map((partialClaimEvent) => partialClaimEvent.blockNumber)
    .reduce((a, b) => (a > b ? a : b), 0);

  yield put(fetchPartialClaimsBulkActions.storeLastBlockNumber(lastBlockFromChain));

  const filteredEvents = events.filter(
    (event) =>
      !loadedClaims
        .map((item) => item.id)
        .includes(Number(toUtf8(event.returnValues.partialClaimId)))
  );

  // Unique array of arranger and partialClaimId
  const uniqueBlocks = [];
  const map = new Map();
  for (const item of filteredEvents) {
    if (!map.has(item.returnValues.partialClaimId)) {
      map.set(item.returnValues.partialClaimId, true);
      uniqueBlocks.push({
        arranger: item.returnValues.payingAgent,
        partialClaim: { teilforderungId: Number(toUtf8(item.returnValues.partialClaimId)) },
      });
    }
  }

  yield put(fetchPartialClaimsBulkActions.increaseTotal(uniqueBlocks.length));
  yield fetchTeilforderungenBulkSaga(uniqueBlocks);
}

const prepareBlocks = (loadErrors) => {
  const blocksForRetry = [];
  Object.keys(loadErrors).forEach((arranger) => {
    loadErrors[arranger].forEach((id) => {
      blocksForRetry.push({
        arranger: arranger,
        partialClaim: { teilforderungId: id },
      });
    });
  });
  return blocksForRetry;
};

function* retryFetchTeilforderungenBulkSaga({ loadErrors, arranger }) {
  const uniqueBlocks =
    arranger != null
      ? loadErrors[arranger].map((id) => ({
          arranger: arranger,
          partialClaim: { teilforderungId: id },
        }))
      : prepareBlocks(loadErrors);

  yield fetchTeilforderungenBulkSaga(uniqueBlocks);
}

function* fetchTeilforderungenBulkSaga(uniqueBlocks) {
  const blocksToRead = 200; //TODO: environment variable

  const blockGroups = new Array(Math.ceil(uniqueBlocks.length / blocksToRead))
    .fill()
    .map((value) => uniqueBlocks.splice(0, blocksToRead));

  for (let i = 0; i < blockGroups.length; i++) {
    // partialClaimIds grouped by arranger
    const partialClaimsByArranger = blockGroups[i].reduce((acc, curr) => {
      acc[curr.arranger] = acc[curr.arranger]
        ? [...acc[curr.arranger], curr.partialClaim]
        : [curr.partialClaim];
      return acc;
    }, {});

    // Array of partialClaimIds split to smaller arrays (chunks)
    const chunkSize = Number(process.env.REACT_APP_CHUNK_SIZE_PARTIAL_CLAIM) || 3;
    Object.keys(partialClaimsByArranger).forEach((arranger) => {
      partialClaimsByArranger[arranger] = new Array(
        Math.ceil(partialClaimsByArranger[arranger].length / chunkSize)
      )
        .fill()
        .map((value) => partialClaimsByArranger[arranger].splice(0, chunkSize));
    });

    yield fetchTeilforderungenFromAll(partialClaimsByArranger);
  }
}

function* fetchTeilforderungenFromAll(partialClaimsByArranger) {
  const arrangers = Object.keys(partialClaimsByArranger);
  for (let i = 0; i < arrangers.length; i++) {
    yield fork(
      fetchTeilforderungenFromArranger,
      arrangers[i],
      partialClaimsByArranger[arrangers[i]]
    );
  }
}

function* fetchTeilforderungenFromArranger(arranger, payloads) {
  for (let i = 0; i < payloads.length; i++) {
    try {
      const response = yield call(
        queryTeilforderungenById,
        arranger,
        payloads[i],
        getIdProxyAddr()
      );

      yield put(
        fetchPartialClaimsBulkActions.success(
          arranger,
          response.data.cssdTeilforderungResponses,
          response.data.missingIds,
          response.data.unauthorizedIds
        )
      );
    } catch (e) {
      const ids = payloads[i].map((partialClaim) => partialClaim.teilforderungId);
      yield put(fetchPartialClaimsBulkActions.failure(arranger, ids));
      console.error(e);
    }
  }
}

function* fetchTeilforderungUpdateFromArranger(arranger, teilforderungId) {
  try {
    const response = yield call(
      queryTeilforderungenById,
      arranger,
      [{ teilforderungId }],
      getIdProxyAddr()
    );

    yield put(
      fetchPartialClaimsBulkActions.success(
        arranger,
        response.data.cssdTeilforderungResponses,
        response.data.missingIds,
        response.data.unauthorizedIds
      )
    );

    // changes could also have an impact on first partial claim and the CSSD
    if (response.data.cssdTeilforderungResponses) {
      const partialClaim = response.data.cssdTeilforderungResponses[0];
      yield updateParentPartialClaims(arranger, partialClaim);
      yield fetchCssdFromArranger(arranger, {cssdId: partialClaim.cssdId, fingerprint: partialClaim.cssdFingerprint});
    }

    for (const partialClaim in response.data.cssdTeilforderungResponses) {
      const updatePartialClaimRefOnCession = yield select(
        isPartialClaimReferencedByCession,
        partialClaim.id
      );

      if (updatePartialClaimRefOnCession) {
        yield put(cessionActions.setPartialClaim(partialClaim));
      }
    }
  } catch (e) {
    yield put(fetchPartialClaimsBulkActions.failure(arranger, [teilforderungId]));
    console.error(e);
  }
}

function* updateParentPartialClaims(arranger, partialClaim) {
  const companyInfo = yield select(getCompanyInfo);
  const parentPartialClaimVisible =
    companyInfo.idProxyAddress === partialClaim?.idProxyAdresseZahlstelle ||
    companyInfo.idProxyAddress === partialClaim?.idProxyAdresseDarlehensnehmer;
  if (parentPartialClaimVisible) {
    const partialClaimId = partialClaim.parentTeilforderungId;
    if (partialClaimId !== partialClaim.id) {
      const partialClaimExists = yield select(isPartialClaimCached, partialClaimId);
      if (partialClaimExists) {
        yield fetchTeilforderungUpdateFromArranger(arranger, partialClaimId);
      }
    }
  }
}

function* fetchPartialClaimsForCssdSaga({ cssdRef }) {
  try {
    const isZS = yield select(isZahlstelleSelector);

    const { data } = yield call(
      fetchPartialClaimsForCssdFromBackend,
      cssdRef.zahlstelle.idProxyAdresse,
      cssdRef.cssdId,
      isZS
    );
    yield put(
      fetchPartialClaimsForCssdActions.success({
        ersteTeilforderung: data.ersteTeilforderung,
        partialClaims: data.teilforderungResponses,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(fetchPartialClaimsForCssdActions.failure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.partial_claim_for_cssd_error))
    );
  }
}

function* fetchPartialClaimForUpdate({ update, onlyCached, updateKuendigungId }) {
  const { action, payingAgent, partialClaimId, cssdId } = update;
  const teilforderungId = Number(toUtf8(partialClaimId));
  const hasAccessToCSSD = yield select(hasAccessToCssd, Number(toUtf8(cssdId)), getIdProxyAddr());

  if (Number(action) === PartialClaimAction.REPAYMENT_RECEIVED && hasAccessToCSSD) {
    yield put(cssdEventActions.handleCSSDEvent(cssdId, null, null, null, payingAgent));
  }

  const partialClaimExists = yield select(isPartialClaimCached, teilforderungId);
  const partialClaimLoading = yield select(isPartialClaimLoading, teilforderungId);

  if (!(onlyCached && !partialClaimExists)) {
    // only update in cache, do not fetch new partialClaim
    if (!partialClaimExists && !partialClaimLoading) {
      yield put(fetchPartialClaimsBulkActions.setPending(teilforderungId));
      yield put(fetchPartialClaimsBulkActions.increaseTotal(1));
    }

    // if the method is called by a CSSDPartialClaimTerminationEvent
    // the state of the termination should be updated to avoid race conditions
    if (updateKuendigungId) {
      yield call(
        updateCssdKuendigungStatus,
        Number(toUtf8(cssdId)),
        {
          kuendigungId: updateKuendigungId,
        },
        payingAgent
      );
    }
    yield call(fetchTeilforderungUpdateFromArranger, payingAgent, teilforderungId);
    yield put(fetchPartialClaimsBulkActions.finished(teilforderungId));
  }
}

function* fetchTeilforderungDetails({ arranger, teilforderung, edit }) {
  try {
    const { data } = yield call(queryTeilforderungDetails, arranger, teilforderung.id);
    if (edit) {
      yield put(
        detailsTeilforderung.openEdit({
          ...data,
          validMigration: teilforderung.validMigration,
          abtretungId: teilforderung.entstandenAusAbtretungId,
          idProxyAdresseZahlstelle: arranger,
          cssdId: teilforderung.cssdId,
          cssdFingerprint: teilforderung.cssdFingerprint,
          firstConfirmerTeilforderung: teilforderung.firstConfirmerTeilforderung,
        })
      );
    } else {
      yield put(detailsTeilforderung.openDeatils({ ...data, idProxyAdresseZahlstelle: arranger }));
    }
  } catch (e) {
    console.error(e);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.loading_failure)));
    yield put(detailsTeilforderung.failure());
  }
}

function* fetchSumOfAllPartialClaims({ cssd }) {
  try {
    const isZS = yield select(isZahlstelleSelector);

    const { data } = yield call(
      fetchSumOfAllPartialClaimsFromBackend,
      cssd.zahlstelle.idProxyAdresse,
      cssd.cssdId,
      isZS
    );

    yield put(fetchSumOfAllPartialClaimsActions.success(data));
  } catch (error) {
    console.error(error);
    yield put(fetchSumOfAllPartialClaimsActions.failure(error));
  }
}

function* cssdRepaymentReceivedSagas({ partialClaim }) {
  try {
    const account = yield select(getAccount);
    const isRedocumentedRebuy =
      partialClaim.teilforderungNachdokumentiert && partialClaim.isRueckkauf;

    yield call(
      repaymentReceived,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`)
    );

    if (isRedocumentedRebuy) {
      const { data } = yield call(
        fetchConfirmedRestOfAllCessions,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.parentTeilforderungId
      );

      const isRepaid = data.summe - partialClaim.nominal <= 0;

      if (isRepaid) {
        yield call(
          repaymentReceived,
          account,
          partialClaim.idProxyAdresseZahlstelle,
          toHex(`${partialClaim.cssdId}`),
          toHex(`${partialClaim.parentTeilforderungId}`)
        );
      }
    }

    yield put(cssdPartialClaimRepaymentReceivedActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.repayment_success)));
  } catch (error) {
    console.error(error);
    yield put(cssdPartialClaimRepaymentReceivedActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.repayment_failure)));
  }
}

function* updateSettlementInformation({ cession, partialClaim }) {
  try {
    const payingAgent = partialClaim.idProxyAdresseZahlstelle;
    const account = yield select(getAccount);

    const settlementInfos = yield generateCssdSettlementInformationFingerprint(
      cession.neuerDarlehensgeber
    );
    yield call(updateNewDgInfoForCession, settlementInfos, payingAgent, cession.abtretungId);
    yield call(
      updatePartialClaimSettlementInformation,
      account,
      payingAgent,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      partialClaim.fingerprint,
      settlementInfos.fingerprint
    );
    yield put(editSettlementInformation.success());
    yield put(editSettlementInformation.closeDialog());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.claim_settlement_information_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(editSettlementInformation.failure());
    yield put(editSettlementInformation.closeDialog());
    yield put(snackbarActions.openError());
  }
}

function* exportClaims({ partialClaims }) {
  try {
    const bytes = yield generatePartialClaimCSV(partialClaims);
    yield put(cssdExportPartialClaim.end());
    downloadByteArrayAsCSV("Export.csv", bytes);
  } catch (error) {
    console.error(error);
    yield put(cssdExportPartialClaim.end());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.export_failure)));
  }
}

const prepareFormData = (formData) => {
  return {
    ...formData,
    abtretung: {
      ...formData.abtretung,
      nominal: formatNumberWithEmptyData(formData.abtretung?.nominal),
      kurs: formatNumberWithEmptyData(formData.abtretung?.kurs),
    },
    abgestimmterRestbestandDurchMigration: formatNumberWithEmptyData(
      formData.abgestimmterRestbestandDurchMigration
    ),
  };
};

function* saveAndConfirmKaufPartialClaim(account, partialClaim, formData) {
  const payload = {
    ...formData,
    abtretung: null,
    abgestimmterRestbestandDurchMigration: formatNumberWithEmptyData(
      formData.abgestimmterRestbestandDurchMigration
    ),
  };
  yield call(
    storeMigrierteAbtretungTeilforderung,
    partialClaim.idProxyAdresseZahlstelle,
    partialClaim.teilforderungId,
    payload
  );

  yield call(
    modifyAndOfferMigratedPartialClaim,
    account,
    partialClaim.idProxyAdresseZahlstelle, // payingAgent
    toHex(`${partialClaim.cssdId}`), // cssdId
    partialClaim.cssdFingerprint, // cssdHash
    zeroAddress, // parentPartialClaimId
    toHex(`${partialClaim.teilforderungId}`), // newPartialClaimId,
    partialClaim.fingerprint, // newPartialClaimHash,
    zeroAddress, // cessionId,
    zeroAddress // cessionHash
  );
}
function* saveAndConfirmAbtretungPartialClaim(account, partialClaim, formData) {
  const preparedFormData = prepareFormData(formData);
  const cessionWithFP = yield call(generateCSSDCessionFingerprints, preparedFormData.abtretung);
  const payload = { ...preparedFormData, abtretung: cessionWithFP };

  yield call(
    storeMigrierteAbtretungTeilforderung,
    partialClaim.idProxyAdresseZahlstelle,
    partialClaim.teilforderungId,
    payload
  );

  yield call(
    modifyAndOfferMigratedPartialClaim,
    account,
    partialClaim.idProxyAdresseZahlstelle, // payingAgent
    toHex(`${partialClaim.cssdId}`), // cssdId
    partialClaim.cssdFingerprint, // cssdHash
    zeroAddress, // parentPartialClaimId
    toHex(`${partialClaim.teilforderungId}`), // newPartialClaimId,
    payload.abtretung.fingerprint, // newPartialClaimHash,
    toHex(`${payload.abtretung.abtretungId}`), // cessionId,
    payload.abtretung.fingerprint // cessionHash
  );
}
function* saveAndConfirmPartialClaimSaga({ account, partialClaim, formData }) {
  try {
    if (partialClaim.kaufTeilforderung) {
      yield call(saveAndConfirmKaufPartialClaim, account, partialClaim, formData);
    } else {
      yield call(saveAndConfirmAbtretungPartialClaim, account, partialClaim, formData);
    }

    yield put(cssdPartialClaimActions.saveAndConfirmSuccess());
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.save_and_confirm_partial_claim_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.save_and_confirm_partial_claim_failure)
      )
    );
    yield put(cssdPartialClaimActions.saveAndConfirmFailure(error));
  }
}

function* confirmMigratedPartialClaimSaga({ account, partialClaim }) {
  try {
    if (partialClaim.statusDurchMigration === PartialClaimState.TERMINATED) {
      const terminationWithFP = yield generateCssdTerminationFingerprint({
        valuta: partialClaim.endfaelligkeitDurchKuendigung,
      });
      const { data } = yield call(
        storeCssdTeilforderungKuendigung,
        partialClaim.cssdId,
        partialClaim.teilforderungId,
        partialClaim.idProxyAdresseZahlstelle,
        terminationWithFP
      );
      yield call(
        updateCssdTeilforderungKuendigung,
        partialClaim.cssdId,
        partialClaim.teilforderungId,
        partialClaim.idProxyAdresseZahlstelle,
        {
          ...terminationWithFP,
          kuendigungId: data.kuendigungId,
          status: CSSDTerminationState.APPROVED,
          firstConfirmer: zeroAddress,
          initiator: zeroAddress,
        }
      );
      yield call(
        migrateTerminatedPartialClaim,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.teilforderungId}`),
        toHex(`${data.kuendigungId}`),
        terminationWithFP.fingerprint,
        dateToTimestamp(terminationWithFP.valuta)
      );
    }

    yield call(
      sendOfferMigratedPartialClaim,
      account,
      partialClaim.firstConfirmerTeilforderung,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.teilforderungId}`),
      partialClaim.fingerprint,
      partialClaim.statusDurchMigration
    );

    yield put(cssdPartialClaimActions.confirmSuccess());
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.confirm_partial_claim_success))
    );
  } catch (error) {
    console.error(error);
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.confirm_partial_claim_failure))
    );
    yield put(cssdPartialClaimActions.confirmFailure());
  }
}

function* cancelMigratedPartialClaimSaga({ account, partialClaim }) {
  try {
    yield call(
      cancelMigratedPartialClaim,
      account, // account,
      partialClaim.idProxyAdresseZahlstelle, // payingAgent,
      toHex(`${partialClaim.cssdId}`), // cssdId,
      toHex(`${partialClaim.teilforderungId}`), // partialClaimId,
      toHex(`${partialClaim.entstandenAusAbtretungId}`) // parentCessionId
    );

    yield put(cssdPartialClaimActions.cancelSuccess());
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cancel_partial_claim_success))
    );
  } catch (error) {
    console.error(error);
    yield put(detailsTeilforderung.closeEdit());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cancel_partial_claim_failure))
    );
    yield put(cssdPartialClaimActions.cancelFailure());
  }
}

function* bulkOfferMigratedPartialClaimSaga({ partialClaims, reset }) {
  try {
    const cssdIds = partialClaims.map((claim) => toHex(`${claim.cssdId}`));
    const partialClaimIds = partialClaims.map((claim) => toHex(`${claim.id}`));
    const partialClaimHashes = partialClaims.map((claim) => claim.fingerprint);
    const firstConfirmers = partialClaims.map((claim) => claim.firstConfirmerTeilforderung);
    const targetStatus = partialClaims.map((claim) => claim.statusDurchMigration);
    const account = yield select(getAccount);

    const bulkActionArgs = {
      account,
      firstConfirmers,
      cssdIds,
      partialClaimIds,
      partialClaimHashes,
      targetStatus,
    };

    yield call(bulkOfferMigratedPartialClaim, bulkActionArgs);

    yield put(bulkPartialClaimActions.finishedBulkRequest());
    reset();
  } catch (error) {
    console.error(error);
    yield put(bulkPartialClaimActions.failedBulkRequest());
  }
}

function* bulkCancelMigratedPartialClaimSaga({ partialClaims, reset }) {
  try {
    const cssdIds = partialClaims.map((claim) => toHex(`${claim.cssdId}`));
    const partialClaimIds = partialClaims.map((claim) => toHex(`${claim.id}`));
    const parentCessionIds = partialClaims.map((claim) =>
      toHex(`${claim.entstandenAusAbtretungId}`)
    );
    const account = yield select(getAccount);

    const bulkActionArgs = {
      account,
      cssdIds,
      partialClaimIds,
      parentCessionIds,
    };

    yield call(bulkCancelMigratedPartialClaim, bulkActionArgs);

    yield put(bulkPartialClaimActions.finishedBulkRequest());
    reset();
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cancel_partial_claim_bulk_action_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(bulkPartialClaimActions.failedBulkRequest());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cancel_partial_claim_bulk_action_failure)
      )
    );
  }
}
