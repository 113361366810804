import {
  MODIFY_SSD_REBUY_REQUEST,
  MODIFY_SSD_REBUY_SUCCESS,
  MODIFY_SSD_REBUY_FAILURE,
  MODIYY_AND_OFFER_SSD_REBUY_REQUEST,
  MODIYY_AND_OFFER_SSD_REBUY_SUCCESS,
  MODIYY_AND_OFFER_SSD_REBUY_FAILURE,
  CANCEL_SSD_REBUY_REQUEST,
  CANCEL_SSD_REBUY_SUCCESS,
  CANCEL_SSD_REBUY_FAILURE,
  OFFER_SSD_REBUY_REQUEST,
  OFFER_SSD_REBUY_SUCCESS,
  OFFER_SSD_REBUY_FAILURE,
  ACCEPT_SSD_REBUY_REQUEST,
  ACCEPT_SSD_REBUY_SUCCESS,
  ACCEPT_SSD_REBUY_FAILURE,
  CANCEL_OFFER_SSD_REBUY_REQUEST,
  CANCEL_OFFER_SSD_REBUY_SUCCESS,
  CANCEL_OFFER_SSD_REBUY_FAILURE,
  CANCEL_ACCEPT_SSD_REBUY_REQUEST,
  CANCEL_ACCEPT_SSD_REBUY_SUCCESS,
  CANCEL_ACCEPT_SSD_REBUY_FAILURE,
  REJECT_SSD_REBUY_REQUEST,
  REJECT_SSD_REBUY_SUCCESS,
  REJECT_SSD_REBUY_FAILURE,
  PAYMENT_CORRECTION_REQUEST,
  PAYMENT_CORRECTION_SUCCESS,
  PAYMENT_CORRECTION_FAILURE,
  PAYMENT_RECEIVED_REQUEST,
  PAYMENT_RECEIVED_SUCCESS,
  PAYMENT_RECEIVED_FAILURE,
  REPAYMENT_RECEIVED_REQUEST,
  REPAYMENT_RECEIVED_SUCCESS,
  REPAYMENT_RECEIVED_FAILURE,
  OPEN_CONFIRM_REPAYMENT_DIALOG,
  CLOSE_CONFIRM_REPAYMENT_DIALOG,
} from "./actions";

const initialState = {
  ssdPaymentActionPending: false,
  confirmPending: false,
  savePending: false,
  saveAndConfirmPending: false,
  cancelPending: false,
  acceptPending: false,
  rejectPending: false,
  repaymentPending: false,
  ssdInfos: {},
  confirmRepaymentDialogOpen: false,
};

function ssdRePaymentRequestReducer(state) {
  return {
    ...state,
    repaymentPending: true,
  };
}

function ssdRePaymentSuccessReducer(state) {
  return {
    ...state,
    repaymentPending: false,
    confirmRepaymentDialogOpen: false,
  };
}

function ssdRePaymentFailureReducer(state) {
  return {
    ...state,
    repaymentPending: false,
    confirmRepaymentDialogOpen: false,
  };
}

function ssdPaymentActionRequestReducer(state) {
  return {
    ...state,
    ssdPaymentActionPending: true,
  };
}

function ssdPaymentActionSuccessReducer(state) {
  return {
    ...state,
    ssdPaymentActionPending: false,
  };
}

function ssdPaymentActionFailureReducer(state) {
  return {
    ...state,
    ssdPaymentActionPending: false,
  };
}

function modifySsdRebuyRequestReducer(state) {
  return {
    ...state,
    savePending: true,
  };
}

function modifySsdRebuySuccessReducer(state) {
  return {
    ...state,
    savePending: false,
  };
}

function modifySsdRebuyFailureReducer(state) {
  return {
    ...state,
    savePending: false,
  };
}

function modifyAndOfferSsdRebuyRequetReducer(state) {
  return {
    ...state,
    saveAndConfirmPending: true,
  };
}

function modifyAndOfferSsdRebuySuccessReducer(state) {
  return {
    ...state,
    saveAndConfirmPending: false,
  };
}

function modifyAndOfferSsdRebuyFailureReducer(state) {
  return {
    ...state,
    saveAndConfirmPending: false,
  };
}

function cancelSsdRebuyRequestReducer(state) {
  return {
    ...state,
    cancelPending: true,
  };
}

function cancelSsdRebuySuccessReducer(state) {
  return {
    ...state,
    cancelPending: false,
  };
}

function cancelSsdRebuyFailureReducer(state) {
  return {
    ...state,
    cancelPending: false,
  };
}

function offerSsdRebuyRequestReducer(state) {
  return {
    ...state,
    confirmPending: true,
  };
}

function offerSsdRebuySuccessReducer(state) {
  return {
    ...state,
    confirmPending: false,
  };
}

function offerSsdRebuyFailureReducer(state) {
  return {
    ...state,
    confirmPending: false,
  };
}

function acceptSsdRebuyRequestReducer(state) {
  return {
    ...state,
    acceptPending: true,
  };
}

function acceptSsdRebuySuccessReducer(state) {
  return {
    ...state,
    acceptPending: false,
  };
}

function acceptSsdRebuyFailureReducer(state) {
  return {
    ...state,
    acceptPending: false,
  };
}

function rejectSsdRebuyRequestReducer(state) {
  return {
    ...state,
    rejectPending: true,
  };
}

function rejectSsdRebuySuccessReducer(state) {
  return {
    ...state,
    rejectPending: false,
  };
}

function rejectSsdRebuyFailureReducer(state) {
  return {
    ...state,
    rejectPending: false,
  };
}

function openConfirmRepaymentDialogReducer(state, action) {
  return {
    ...state,
    ssdInfos: action.ssdInfos,
    confirmRepaymentDialogOpen: true,
  };
}

function closeConfirmRepaymentDialogReducer(state) {
  return {
    ...state,
    confirmRepaymentDialogOpen: false,
  };
}

export function ssdPaymentStatusReducer(state = initialState, action) {
  switch (action.type) {
    case MODIFY_SSD_REBUY_REQUEST:
      return modifySsdRebuyRequestReducer(state, action);
    case MODIFY_SSD_REBUY_SUCCESS:
      return modifySsdRebuySuccessReducer(state, action);
    case MODIFY_SSD_REBUY_FAILURE:
      return modifySsdRebuyFailureReducer(state, action);

    case MODIYY_AND_OFFER_SSD_REBUY_REQUEST:
      return modifyAndOfferSsdRebuyRequetReducer(state, action);
    case MODIYY_AND_OFFER_SSD_REBUY_SUCCESS:
      return modifyAndOfferSsdRebuySuccessReducer(state, action);
    case MODIYY_AND_OFFER_SSD_REBUY_FAILURE:
      return modifyAndOfferSsdRebuyFailureReducer(state, action);

    case CANCEL_SSD_REBUY_REQUEST:
      return cancelSsdRebuyRequestReducer(state, action);
    case CANCEL_SSD_REBUY_SUCCESS:
      return cancelSsdRebuySuccessReducer(state, action);
    case CANCEL_SSD_REBUY_FAILURE:
      return cancelSsdRebuyFailureReducer(state, action);

    case OFFER_SSD_REBUY_REQUEST:
      return offerSsdRebuyRequestReducer(state, action);
    case OFFER_SSD_REBUY_SUCCESS:
      return offerSsdRebuySuccessReducer(state, action);
    case OFFER_SSD_REBUY_FAILURE:
      return offerSsdRebuyFailureReducer(state, action);

    case ACCEPT_SSD_REBUY_REQUEST:
      return acceptSsdRebuyRequestReducer(state, action);
    case ACCEPT_SSD_REBUY_SUCCESS:
      return acceptSsdRebuySuccessReducer(state, action);
    case ACCEPT_SSD_REBUY_FAILURE:
      return acceptSsdRebuyFailureReducer(state, action);

    case CANCEL_OFFER_SSD_REBUY_REQUEST:
      return ssdPaymentActionRequestReducer(state, action);
    case CANCEL_OFFER_SSD_REBUY_SUCCESS:
      return ssdPaymentActionSuccessReducer(state, action);
    case CANCEL_OFFER_SSD_REBUY_FAILURE:
      return ssdPaymentActionFailureReducer(state, action);

    case CANCEL_ACCEPT_SSD_REBUY_REQUEST:
      return ssdPaymentActionRequestReducer(state, action);
    case CANCEL_ACCEPT_SSD_REBUY_SUCCESS:
      return ssdPaymentActionSuccessReducer(state, action);
    case CANCEL_ACCEPT_SSD_REBUY_FAILURE:
      return ssdPaymentActionFailureReducer(state, action);

    case REJECT_SSD_REBUY_REQUEST:
      return rejectSsdRebuyRequestReducer(state, action);
    case REJECT_SSD_REBUY_SUCCESS:
      return rejectSsdRebuySuccessReducer(state, action);
    case REJECT_SSD_REBUY_FAILURE:
      return rejectSsdRebuyFailureReducer(state, action);

    case PAYMENT_CORRECTION_REQUEST:
      return ssdPaymentActionRequestReducer(state, action);
    case PAYMENT_CORRECTION_SUCCESS:
      return ssdPaymentActionSuccessReducer(state, action);
    case PAYMENT_CORRECTION_FAILURE:
      return ssdPaymentActionFailureReducer(state, action);

    case PAYMENT_RECEIVED_REQUEST:
      return ssdPaymentActionRequestReducer(state, action);
    case PAYMENT_RECEIVED_SUCCESS:
      return ssdPaymentActionSuccessReducer(state, action);
    case PAYMENT_RECEIVED_FAILURE:
      return ssdPaymentActionFailureReducer(state, action);

    case REPAYMENT_RECEIVED_REQUEST:
      return ssdRePaymentRequestReducer(state, action);
    case REPAYMENT_RECEIVED_SUCCESS:
      return ssdRePaymentSuccessReducer(state, action);
    case REPAYMENT_RECEIVED_FAILURE:
      return ssdRePaymentFailureReducer(state, action);

    case OPEN_CONFIRM_REPAYMENT_DIALOG:
      return openConfirmRepaymentDialogReducer(state, action);
    case CLOSE_CONFIRM_REPAYMENT_DIALOG:
      return closeConfirmRepaymentDialogReducer(state, action);

    default:
      return state;
  }
}
