import { Chip, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    color: theme.palette.common.white,
  },
});

export default withStyles(styles)(Chip);
