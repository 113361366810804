import { sendSignedHttpRequest } from "util/requestSigning";
import { startPdfDownload } from "services/commonService";

export function downloadCessionCert(arranger, cessionId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/uebertragungszertifikat/${arranger}/${cessionId}`,
    arranger
  ).then((response) => {
    startPdfDownload(response.data.dokument, response.data.dateiName);
  });
}

export function fetchCessionCert(arranger, cessionId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/uebertragungszertifikat/${arranger}/${cessionId}`,
    arranger
  ).then((response) => {
    return response;
  });
}

export function uploadCessionCert(arranger, cessionId, certificate) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/upload_uebertragungszertifikat/${arranger}/${cessionId}`,
    arranger,
    { uebertragungszertifikat: certificate }
  );
}

export function downloadCessionConfirmation(arranger, cessionId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/geschaeftsbestaetigung/${arranger}/${cessionId}`,
    arranger
  ).then((response) => {
    startPdfDownload(response.data.dokument, response.data.dateiName);
  });
}

export function createCessionConfirmationPDF(arranger, cessionId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/geschaeftsbestaetigung/${arranger}/${cessionId}`,
    arranger
  ).then((response) => {
    return response.data;
  });
}

export async function createCertificateOnPaymentReceived(id_proxy_arranger, abtretungId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/uebertragungszertifikat/${id_proxy_arranger}/${abtretungId}`,
    id_proxy_arranger,
    null
  );
}

export async function fetchSumOfAllCessions(id_proxy_arranger, partialClaimId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/teilforderungen/summeNominale/${id_proxy_arranger}/${partialClaimId}`,
    id_proxy_arranger
  );
}

export async function fetchConfirmedRestOfAllCessions(id_proxy_arranger, partialClaimId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/teilforderung/abgestimmterRestbestand/${id_proxy_arranger}/${partialClaimId}`,
    id_proxy_arranger
  );
}

export async function fetchCessions(teilforderungId, arranger, filters, sortCriteria, pagingInfo) {
  delete filters.status;
  sortCriteria = {
    ...sortCriteria,
    column: sortCriteria.column === "STATUS" ? "GESCHAEFTSNUMMER" : sortCriteria.column,
  };

  const data = {
    sortBy: sortCriteria.column,
    descending: sortCriteria.isDescending,
    filters: filters,
    pageNumber: pagingInfo.pageNumber,
    pageSize: pagingInfo.pageSize,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/teilforderung/${teilforderungId}/abtretungen/${arranger}`,
    arranger,
    data
  );
}

export async function fetchCessionRequests(post, arranger) {
  const data = {
    cssdAbtretungRequests: [].concat(post),
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/anfragen/${arranger}`,
    arranger,
    data
  ).catch((error) => {
    console.error(error);
  });
}

export async function fetchCessionsForDashboard(post, arranger) {
  const data = {
    cssdAbtretungRequests: [].concat(post),
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/dashboard/${arranger}`,
    arranger,
    data
  ).catch((error) => {
    console.error(error);
  });
}

export async function postCession(id_proxy_arranger, teilforderungId, cession) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/${id_proxy_arranger}/${teilforderungId}`,
    id_proxy_arranger,
    cession
  );
}

export async function fetchCession(id_proxy_arranger, abtretungId, fingerprint) {
  const post = {
    cssdAbtretungRequests: [
      {
        abtretungId,
        fingerprint,
      },
    ],
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/abtretungen/${id_proxy_arranger}`,
    id_proxy_arranger,
    post
  );
}

export async function updateNewDgInfoForCession(post, id_proxy_arranger, cessionId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/${cessionId}/neuerDarlehensgeber/${id_proxy_arranger}`,
    id_proxy_arranger,
    post
  );
}

export function createPartialClaimFromCession(arranger, cssdId, cessionId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/${cssdId}/teilforderungFromAbtretung/${arranger}/${cessionId}`,
    arranger
  );
}

export async function createCertificateOnPaymentReceivedBulk(id_proxy_arranger, abtretungIds) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/bulkUebertragungszertifikat/${id_proxy_arranger}`,
    id_proxy_arranger,
    abtretungIds
  );
}

export async function ablehnen(id_proxy_arranger, abtretungId, fingerprint, cssdAblehnungRequest) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/ablehnung/${id_proxy_arranger}/${abtretungId}/${fingerprint}`,
    id_proxy_arranger,
    cssdAblehnungRequest
  );
}

export async function getCession(id_proxy_arranger, abtretungId, fingerprint) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/abtretung/${id_proxy_arranger}/${abtretungId}/${fingerprint}`,
    id_proxy_arranger
  );
}

export async function getCessionNotification(id_proxy_arranger, abtretungId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/abtretungsanzeige-extern/${id_proxy_arranger}/${abtretungId}`,
    id_proxy_arranger
  ).then((response) => {
    startPdfDownload(response.data.dokument, response.data.dateiName);
  });
}

export async function createCessionNotification(id_proxy_arranger, abtretungId, cssdId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/abtretungsanzeige-extern/${id_proxy_arranger}/${abtretungId}/${cssdId}`,
    id_proxy_arranger
  );
}

export async function createCessionNotificationBulk(id_proxy_arranger, cssdId, abtretungIds) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/bulk-abtretungsanzeige-extern/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger,
    { abtretungIds }
  );
}

export function fetchCssdCessionHistory(id_proxy_arranger, cssdId, cessionId, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/abtretung/${id_proxy_arranger}/${cssdId}/${cessionId}`,
    id_proxy_arranger,
    data
  );
}

export function fetchCssdInterestNotificationHistory(
  arranger,
  cssdId,
  interestNotificationId,
  data
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/zinsmitteilung/${arranger}/${cssdId}/${interestNotificationId}`,
    arranger,
    data
  );
}

export function createCssdGeschaeftsnummer(arranger, teilforderungId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/incrementedGeschaeftsnummer/${arranger}/${teilforderungId}`,
    arranger
  ).then(({ data }) => data.geschaeftsnummer);
}

export function getCessionsForCssd(idProxy, cssdId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/abtretungen/${idProxy}/${cssdId}`,
    idProxy
  );
}

export function storeMigrierteAbtretungTeilforderung(id_proxy_arranger, teilforderungId, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/migrierteTeilforderung/${id_proxy_arranger}/${teilforderungId}`,
    id_proxy_arranger,
    data
  );
}
