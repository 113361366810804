import { sendSignedHttpRequest } from "util/requestSigning";
import { startPdfDownload } from "services/commonService";
import { getIdProxyAddr } from "./web3Services/commons";

function prepareTerminationsForCheck(terminations = []) {
  const kuendigungStatusDTOs = [];
  terminations.forEach(({ kuendigungId, status, firstConfirmer, initiator }) => {
    kuendigungStatusDTOs.push({
      kuendigungId,
      status,
      firstConfirmer,
      initiator,
    });
  });
  return kuendigungStatusDTOs;
}

export function storeCssdKuendigung(
  cssdKuendigungDTO,
  cssdId,
  arranger,
  firstConfirmer,
  idProxy = getIdProxyAddr().toLowerCase()
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/${arranger}/${idProxy}/${firstConfirmer}/${cssdId}`,
    arranger,
    cssdKuendigungDTO
  );
}

export function storeNachdokumentierteCssdKuendigung(
  cssdKuendigungDTO,
  cssdId,
  id_proxy_arranger,
  accountAddress,
  idProxy = getIdProxyAddr().toLowerCase()
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/nachdokumentiert/${id_proxy_arranger}/${idProxy}/${accountAddress}/${cssdId}`,
    id_proxy_arranger,
    cssdKuendigungDTO
  );
}

export function updateCssdKuendigung(cssdKuendigungDTO, cssdId, arranger) {
  return sendSignedHttpRequest(
    "put",
    `/digits-server/cssd/kuendigung/${arranger}/${cssdId}`,
    arranger,
    cssdKuendigungDTO
  );
}

export function storeCssdTeilforderungKuendigung(
  cssdId,
  teilforderungId,
  arranger,
  cssdKuendigungDTO,
  firstConfirmer,
  idProxy = getIdProxyAddr().toLowerCase()
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/${arranger}/${idProxy}/${firstConfirmer}/${cssdId}/teilforderung/${teilforderungId}`,
    arranger,
    cssdKuendigungDTO
  );
}

export function updateCssdTeilforderungKuendigung(
  cssdId,
  teilforderungId,
  arranger,
  cssdKuendigungDTO
) {
  return sendSignedHttpRequest(
    "put",
    `/digits-server/cssd/kuendigung/${arranger}/${cssdId}/teilforderung/${teilforderungId}`,
    arranger,
    cssdKuendigungDTO
  );
}

export function pruefeKuendigungStatus(
  cssdId,
  { kuendigungId, status, firstConfirmer, initiator },
  idProxyArranger
) {
  const payload = {
    kuendigungId,
    status,
    firstConfirmer,
    initiator,
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/state/${idProxyArranger}/${cssdId}`,
    idProxyArranger,
    payload
  );
}

export function pruefeKuendigungStatusBulk(cssdId, kuendigungen, idProxyArranger) {
  const payload = {
    statusDTOList: prepareTerminationsForCheck(kuendigungen),
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/bulkstate/${idProxyArranger}/${cssdId}`,
    idProxyArranger,
    payload
  );
}

export function updateCssdKuendigungStatus(cssdId, statusDTO, idProxyArranger) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/state/update/${idProxyArranger}/${cssdId}`,
    idProxyArranger,
    statusDTO
  );
}

export function erstelleKuendigungsPDF(
  cssdId,
  kuendigungId,
  idProxyArranger,
  idProxy = getIdProxyAddr()
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/kuendigungsschreiben/${idProxyArranger}/${idProxy}/${cssdId}/${kuendigungId}`,
    idProxyArranger,
    null
  );
}

export function downloadKuendigungsPDF(
  cssdId,
  kuendigungId,
  idProxyArranger,
  idProxy = getIdProxyAddr()
) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/kuendigung/kuendigungsschreiben/${idProxyArranger}/${idProxy}/${cssdId}/${kuendigungId}`,
    idProxyArranger
  ).then((response) => {
    startPdfDownload(response.data.dokument, response.data.dateiName);
  });
}

export function deleteDocuments(arranger, cssdId, abtretungIds) {
  const data = { abtretungIds };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/abbruchabtretung/deletedokuments/${arranger}/${cssdId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}
