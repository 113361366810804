import { formatDate } from "util/convertor";
import { SSDRebuyState, SSDTerminationState } from "util/constants";

export function getMaturityDate({
  terminationState,
  terminationDate,
  rebuyState,
  rebuyMaturityDate,
  endfaelligkeit,
}) {
  if (rebuyState === SSDRebuyState.APPROVED && terminationState !== SSDTerminationState.APPROVED) {
    return formatDate(new Date(rebuyMaturityDate * 1000));
  }

  if (terminationState === SSDTerminationState.APPROVED && rebuyState !== SSDRebuyState.APPROVED) {
    return formatDate(new Date(terminationDate * 1000));
  }

  if (terminationState === SSDTerminationState.APPROVED && rebuyState === SSDRebuyState.APPROVED) {
    let date = Math.min(Number(rebuyMaturityDate), Number(terminationDate));
    return formatDate(new Date(date * 1000));
  }

  return endfaelligkeit;
}
