import history from "./history";
import {
  messagesCessionInfo,
  messagesCessionTitles,
  messagesDgTitles,
  messagesDnTitles,
  messagesEmployeeStatus,
  messagesInterestNotificationTitles,
  messagesRebuyTitles,
  messagesSvTitles,
} from "./fields_messages";

let testData = process.env.REACT_APP_IS_WITH_TEST_DATA.trim() === "true";
export function isWithTestData() {
  return testData;
}

export function switchTestData() {
  if (process.env.REACT_APP_IS_WITH_TEST_DATA.trim() === "true") {
    testData = !testData;
  }
  return testData;
}

let isCreateImported = false;
export function getCreateImported() {
  return isCreateImported;
}
export function createImported() {
  if (process.env.REACT_APP_IS_WITH_TEST_DATA.trim() === "true") {
    isCreateImported = !isCreateImported;
  }
  return isCreateImported;
}

export function goToMigrationOverview() {
  if (process.env.REACT_APP_IS_WITH_TEST_DATA.trim() === "true") {
    history.push("/migrate");
  }
}

export const getAbtretungTestData = (idProxyAddress) => {
  return isWithTestData()
    ? idProxyAddress === abtretungDekaInitValue.neuerDarlehensgeber.idProxyAdresse
      ? abtretungTheBankInitValue
      : abtretungDekaInitValue
    : {};
};

export const EMPLOYEE_STATUS_LABELS = {
  ACTIVE: messagesEmployeeStatus.fields_employee_status_active,
  SUSPENDED: messagesEmployeeStatus.fields_employee_status_suspended,
  PENDING: messagesEmployeeStatus.fields_employee_status_pending,
  INACTIVE: messagesEmployeeStatus.fields_employee_status_inactive,
};

//------------- This block is for fields variable name (lowercase and with underline)-------------
export const bssdDnFields = [
  "darlehensnehmer_name",
  "darlehensnehmer",
  "umsatzsteuer_id_darlehensnehmer",
  "anschrift_darlehensnehmer",
  "telefonnummer_darlehensnehmer",
  "lei_darlehensnehmer",
  "digits_kennung_darlehensnehmer",
  "erfassender_mitarbeiter_darlehensnehmer",
  "unser_zeichen_darlehensnehmer",
  "iban_darlehensnehmer",
  "bic_code_darlehensnehmer",
  "vertragsreferenz_nr_darlehensnehmer",
  "interne_kennung_darlehensnehmer",
  "finledger_urkunde_verwenden",
];
export const bssdDnRequiredFields = [
  "darlehensnehmer",
  "umsatzsteuer_id_darlehensnehmer",
  "anschrift_darlehensnehmer",
  "lei_darlehensnehmer",
  "digits_kennung_darlehensnehmer",
  "iban_darlehensnehmer",
  "bic_code_darlehensnehmer",
];

export const bssdDgFields = [
  "darlehensgeber_name",
  "darlehensgeber",
  "umsatzsteuer_id_darlehensgeber",
  "anschrift_darlehensgeber",
  "lei_darlehensgeber",
  "telefonnummer_darlehensgeber",
  "unser_zeichen_darlehensgeber",
  "digits_kennung_darlehensgeber",
  "iban_darlehensgeber",
  "bic_code_darlehensgeber",
  "iban_darlehensgeber_tilgung",
  "bic_code_darlehensgeber_tilgung",
  "vertragsreferenz_nr_darlehensgeber",
  "interne_kennung_darlehensgeber",
];
export const bssdDgRequiredFields = [
  "darlehensgeber",
  "umsatzsteuer_id_darlehensgeber",
  "anschrift_darlehensgeber",
  "lei_darlehensgeber",
  "digits_kennung_darlehensgeber",
];

export const bssdSvFields = [
  "urkunden_registernummer",
  "handelstag",
  "handelstag_uhrzeit",
  "nominal",
  "waehrung_nominal",
  "kurs",
  "notiz",
  "kaufpreis",
  "waehrung_kaufpreis",
  "valuta",
  "endfaelligkeit",
  "zins_fix",
  "referenzzinssatz",
  "aufschlag",
  "minimumzinssatz",
  "abschlag",
  "maximumzinssatz",
  "faktor",

  "bankarbeitstage",
  "bildschirmseite",
  "relevanteuhrzeit",
  "relevanterort",
  "fixingtyp",

  "zinssatz",
  "zinszahlungsrhythmus",
  "zinstermin_tag",
  "zinstermin_monat",
  "zinsmethode",
  "faelligkeitstag_der_zinszahlung",
  "berechnungszeitraum_der_zinszahlung",
  "erster_zinstermin",
  "vorletzter_zinstermin",
  "geschaeftstagekalender",

  "kuendigungsrecht",
  "kuendigungTermine",
  "kuendigungsfrist",

  "abtretbarkeit",
  "mindestbetrag_bei_abtretung",
  "waehrung_mindestbetrag_bei_abtretung",
  "auslandsfaehigkeit",
  "vag_konform",
  "mrel_72b_crr",
  "kwg_46f",
  "sag_49",
  "tlac",
];

export const bssdSvRequiredFields = [
  "handelstag",
  "nominal",
  "waehrung_nominal",
  "kurs",
  "notiz",
  "waehrung_kaufpreis",
  "valuta",
  "endfaelligkeit",
  "zinssatz",

  "zins_fix",
  "referenzzinssatz",

  "bankarbeitstage",
  "bildschirmseite",
  "relevanteuhrzeit",
  "relevanterort",
  "fixingtyp",
  "vorletzter_zinstermin",

  "kuendigungsrecht",
  "kuendigungTermine",
  "kuendigungsfrist",

  "zinszahlungsrhythmus",
  "zinstermin_tag",
  "zinstermin_monat",
  "zinsmethode",
  "faelligkeitstag_der_zinszahlung",
  "berechnungszeitraum_der_zinszahlung",
  "erster_zinstermin",
  "geschaeftstagekalender",
  "mindestbetrag_bei_abtretung",
  "waehrung_mindestbetrag_bei_abtretung",
  "urkunden_registernummer",
];

export const rebuyFields = [
  "handelstag_rueckkauf",
  "uhrzeit",
  "nominal",
  "waehrung_nominal",
  "urspruengliche_endfaelligkeit",
  "ruecknahmekurs",
  "notiz_ruecknahmekurs",
  "ruecknahmebetrag",
  "waehrung_ruecknahmebetrag",
  "valuta_ruecknahmebetrag",
  "aufgelaufene_stueckzinsen",
  "waehrung_aufgelaufene_stueckzinsen",
  "valuta_aufgelaufene_stueckzinsen",
  "urkunden_registernummer",
];
export const rebuyRequiredFields = [
  "handelstag_rueckkauf",
  "nominal",
  "waehrung_nominal",
  "urspruengliche_endfaelligkeit",
  "ruecknahmekurs",
  "notiz_ruecknahmekurs",
  "ruecknahmebetrag",
  "waehrung_ruecknahmebetrag",
  "valuta_ruecknahmebetrag",
  "aufgelaufene_stueckzinsen",
  "waehrung_aufgelaufene_stueckzinsen",
  "valuta_aufgelaufene_stueckzinsen",
  "urkunden_registernummer",
];

export const cessionFields = [
  "zessionar_name",
  "darlehensgeber",
  "umsatzsteuer_id_darlehensgeber",
  "anschrift_darlehensgeber",
  "telefonnummer_darlehensgeber",
  "lei_darlehensgeber",
  "digits_kennung_darlehensgeber",
  "interne_kennung_darlehensgeber",
  "vertragsreferenz_darlehensgeber",
  "unser_zeichen_darlehensgeber",
  "iban_darlehensgeber_zinsen",
  "bic_code_darlehensgeber_zinsen",
  "iban_darlehensgeber_tilgung",
  "bic_code_darlehensgeber_tilgung",

  "datum_abtretung",
  "uhrzeit",
  "abgetretenes_nominal",
  "waehrung_nominal",
  "valuta_abtretung",
  "abtretungskurs",
  "notiz_abtretungskurs",
  "gegenwert_abtretung",
  "waehrung_gegenwert_abtretung",
  "stueckzinsen",
  "waehrung_stueckzinsen",
  "valuta_stueckzinsen",
  "naechster_zinstermin",
  "urkunden_registernummer",
];

export const cessionSsdInfoFields = [
  "darlehensnehmer_name",
  "darlehensnehmer",
  "lei_darlehensnehmer",
  "digits_kennung_darlehensnehmer",
  "nominal",
  "waehrung_nominal",
  "valuta",
  "endfaelligkeit",
  "kuendigungsrecht",
  "kuendigungTermine",
  "kuendigungsfrist",
  "urkunden_registernummer",
  "zinssatz",
  "zinszahlungsrhythmus",
  "zinsmethode",
  "faelligkeitstag_der_zinszahlung",
  "berechnungszeitraum_der_zinszahlung",
  "zinstermin_tag",
  "zinstermin_monat",
  "zins_fix",
  "referenzzinssatz",
  "aufschlag",
  "minimumzinssatz",
  "abschlag",
  "maximumzinssatz",
  "faktor",
  "bankarbeitstage",
  "bildschirmseite",
  "relevanteuhrzeit",
  "relevanterort",
  "fixingtyp",
];

export const interestNotificationFields = [
  "datum",
  "referenzZinssatz",
  "feststellungsTag",
  "zinsSatz",
  "zinsperiodeVon",
  "zinsperiodeBis",
  "anzahlTage",
  "zinsBetrag",
  "zahltag",
  "naechsteFestsetzung",
];

export const interestNotificationRequiredFields = [
  "datum",
  "referenzZinssatz",
  "feststellungsTag",
  "zinsSatz",
  "zinsperiodeVon",
  "zinsperiodeBis",
  "anzahlTage",
  "zinsBetrag",
  "zahltag",
  "naechsteFestsetzung",
];

//------------- This block is for fields titles (Firts letter uppercase and with space)-------------

export const dnTitles = [
  messagesDnTitles.fields_dn_titles_darlehensnehmer,
  messagesDnTitles.fields_dn_titles_blockchain_adresse,
  messagesDnTitles.fields_dn_titles_ustid,
  messagesDnTitles.fields_dn_titles_anschrift,
  messagesDnTitles.fields_dn_titles_telefonnummer,
  messagesDnTitles.fields_dn_titles_lei,
  messagesDnTitles.fields_dn_titles_digits_kennung,
  messagesDnTitles.fields_dn_titles_mitarbeiter,
  messagesDnTitles.fields_dn_titles_zeichen,
  messagesDnTitles.fields_dn_titles_iban,
  messagesDnTitles.fields_dn_titles_bic,
  messagesDnTitles.fields_dn_titles_vertragsnr,
  messagesDnTitles.fields_dn_titles_interne_kennung,
  messagesDnTitles.fields_dn_titles_urkunde,
];

export const dgTitles = [
  messagesDgTitles.fields_dg_titles_darlehensgeber,
  messagesDgTitles.fields_dg_titles_blockchain_adresse,
  messagesDgTitles.fields_dg_titles_ustid,
  messagesDgTitles.fields_dg_titles_anschrift,
  messagesDgTitles.fields_dg_titles_lei,
  messagesDgTitles.fields_dg_titles_telefonnummer,
  messagesDgTitles.fields_dg_titles_zeichen,
  messagesDgTitles.fields_dg_titles_digits_kennung,
  messagesDgTitles.fields_dg_titles_iban,
  messagesDgTitles.fields_dg_titles_bic,
  messagesDgTitles.fields_dg_titles_iban_tilgung,
  messagesDgTitles.fields_dg_titles_bic_tilgung,
  messagesDgTitles.fields_dg_titles_vertragsnr,
  messagesDgTitles.fields_dg_titles_interne_kennung,
];

export const bssdSvTitles = [
  messagesSvTitles.fields_sv_titles_urkundennummer,
  messagesSvTitles.fields_sv_titles_handelstag,
  messagesSvTitles.fields_sv_titles_handelsuhrzeit,
  messagesSvTitles.fields_sv_titles_nominal,
  messagesSvTitles.fields_sv_titles_nominal_waehrung,
  messagesSvTitles.fields_sv_titles_kurs,
  messagesSvTitles.fields_sv_titles_notiz,
  messagesSvTitles.fields_sv_titles_kaufpreis,
  messagesSvTitles.fields_sv_titles_kaufpreis_waehrung,
  messagesSvTitles.fields_sv_titles_valuta,
  messagesSvTitles.fields_sv_titles_endfaelligkeit,

  messagesSvTitles.fields_sv_titles_verzinsung,
  messagesSvTitles.fields_sv_titles_referenzzinssatz,
  messagesSvTitles.fields_sv_titles_aufschlag,
  messagesSvTitles.fields_sv_titles_minimum,
  messagesSvTitles.fields_sv_titles_abschlag,
  messagesSvTitles.fields_sv_titles_maximum,
  messagesSvTitles.fields_sv_titles_faktor,

  messagesSvTitles.fields_sv_titles_bankarbeitstage,
  messagesSvTitles.fields_sv_titles_bildschirmseite,
  messagesSvTitles.fields_sv_titles_relevante_uhrzeit,
  messagesSvTitles.fields_sv_titles_relevanter_ort,
  messagesSvTitles.fields_sv_titles_fixingtyp,

  messagesSvTitles.fields_sv_titles_zinssatz,
  messagesSvTitles.fields_sv_titles_zinsrhythmus,
  messagesSvTitles.fields_sv_titles_zinstermin_tag,
  messagesSvTitles.fields_sv_titles_zinstermin_monat,
  messagesSvTitles.fields_sv_titles_zinsmethode,
  messagesSvTitles.fields_sv_titles_tag_zinszahlung,
  messagesSvTitles.fields_sv_titles_zeitraum_zinszahlung,
  messagesSvTitles.fields_sv_titles_zinstermin_erster,
  messagesSvTitles.fields_sv_titles_zinstermin_vorletzter,
  messagesSvTitles.fields_sv_titles_geschaeftstagekalender,

  messagesSvTitles.fields_sv_titles_kuendbar,
  messagesSvTitles.fields_sv_titles_kuendigungstermine,
  messagesSvTitles.fields_sv_titles_kuendigungsfrist,

  messagesSvTitles.fields_sv_titles_abtretbarkeit,
  messagesSvTitles.fields_sv_titles_mindestbetrag_abtretung,
  messagesSvTitles.fields_sv_titles_mindestbetrag_abtretung_waehrung,
  messagesSvTitles.fields_sv_titles_auslandsfaehig,
  messagesSvTitles.fields_sv_titles_vag,
  messagesSvTitles.fields_sv_titles_mrel,
  messagesSvTitles.fields_sv_titles_kwg,
  messagesSvTitles.fields_sv_titles_sag,
  messagesSvTitles.fields_sv_titles_tlac,
];

export const rebuyTitles = [
  messagesRebuyTitles.fields_rebuy_titles_handelstag,
  messagesRebuyTitles.fields_rebuy_titles_uhrzeit,
  messagesRebuyTitles.fields_rebuy_titles_nominal,
  messagesRebuyTitles.fields_rebuy_titles_nominal_waehrung,
  messagesRebuyTitles.fields_rebuy_titles_ursp_endfaelligkeit,
  messagesRebuyTitles.fields_rebuy_titles_ruecknamekurs,
  messagesRebuyTitles.fields_rebuy_titles_ruecknamekurs_notiz,
  messagesRebuyTitles.fields_rebuy_titles_ruecknahmebetrag,
  messagesRebuyTitles.fields_rebuy_titles_ruecknahmebetrag_waehrung,
  messagesRebuyTitles.fields_rebuy_titles_ruecknahmebetrag_valuta,
  messagesRebuyTitles.fields_rebuy_titles_stueckzinsen,
  messagesRebuyTitles.fields_rebuy_titles_stueckzinsen_waehrung,
  messagesRebuyTitles.fields_rebuy_titles_stueckzinsen_valuta,
  messagesRebuyTitles.fields_rebuy_titles_urkundennummer,
];

export const cessionTitles = [
  messagesCessionTitles.fields_cession_titles_zessionar,
  messagesCessionTitles.fields_cession_titles_dg_blockchain,
  messagesCessionTitles.fields_cession_titles_dg_ustid,
  messagesCessionTitles.fields_cession_titles_dg_anschrift,
  messagesCessionTitles.fields_cession_titles_dg_telefonnummer,
  messagesCessionTitles.fields_cession_titles_dg_lei,
  messagesCessionTitles.fields_cession_titles_dg_digits,
  messagesCessionTitles.fields_cession_titles_dg_kennung,
  messagesCessionTitles.fields_cession_titles_dg_vertragsnr,
  messagesCessionTitles.fields_cession_titles_dg_zeichen,
  messagesCessionTitles.fields_cession_titles_iban_zinsen,
  messagesCessionTitles.fields_cession_titles_bic_zinsen,
  messagesCessionTitles.fields_cession_titles_iban_tilgung,
  messagesCessionTitles.fields_cession_titles_bic_tilgung,

  messagesCessionTitles.fields_cession_titles_datum,
  messagesCessionTitles.fields_cession_titles_uhrzeit,
  messagesCessionTitles.fields_cession_titles_nominal,
  messagesCessionTitles.fields_cession_titles_nominal_waehrung,
  messagesCessionTitles.fields_cession_titles_valuta,
  messagesCessionTitles.fields_cession_titles_kurs,
  messagesCessionTitles.fields_cession_titles_kurs_notiz,
  messagesCessionTitles.fields_cession_titles_gegenwert,
  messagesCessionTitles.fields_cession_titles_gegenwert_waehrung,
  messagesCessionTitles.fields_cession_titles_stueckzinsen,
  messagesCessionTitles.fields_cession_titles_stueckzinsen_waehrung,
  messagesCessionTitles.fields_cession_titles_stueckzinsen_valuta,
  messagesCessionTitles.fields_cession_titles_zinstermin_naechster,
  messagesCessionTitles.fields_cession_titles_urkundennummer,
];

export const cessionSsdInfoTitles = [
  messagesCessionInfo.fields_cession_info_darlehensnehmer,
  messagesCessionInfo.fields_cession_info_blockchain,
  messagesCessionInfo.fields_cession_info_lei,
  messagesCessionInfo.fields_cession_info_digits,
  messagesCessionInfo.fields_cession_info_nominal,
  messagesCessionInfo.fields_cession_info_nominal_waehrung,
  messagesCessionInfo.fields_cession_info_valuta,
  messagesCessionInfo.fields_cession_info_endfaelligkeit,
  messagesCessionInfo.fields_cession_info_kuendbar,
  messagesCessionInfo.fields_cession_info_kuendigungstermine,
  messagesCessionInfo.fields_cession_info_kuendigungsfrist,
  messagesCessionInfo.fields_cession_info_urkundennummer,
  messagesCessionInfo.fields_cession_info_zinssatz,
  messagesCessionInfo.fields_cession_info_zinszahlungsrythmus,
  messagesCessionInfo.fields_cession_info_zinsmethode,
  messagesCessionInfo.fields_cession_info_tag_zinszahlung,
  messagesCessionInfo.fields_cession_info_zeitraum_zinszahlung,
  messagesCessionInfo.fields_cession_info_zinstermin_tag,
  messagesCessionInfo.fields_cession_info_zinstermin_monat,
  messagesCessionInfo.fields_cession_info_verzinsung,
  messagesCessionInfo.fields_cession_info_referenzzinssatz,
  messagesCessionInfo.fields_cession_info_aufschlag,
  messagesCessionInfo.fields_cession_info_minimum,
  messagesCessionInfo.fields_cession_info_abschlag,
  messagesCessionInfo.fields_cession_info_maximum,
  messagesCessionInfo.fields_cession_info_faktor,
  messagesCessionInfo.fields_cession_info_bankarbeitstage,
  messagesCessionInfo.fields_cession_info_bildschirmseite,
  messagesCessionInfo.fields_cession_info_relevante_uhrzeit,
  messagesCessionInfo.fields_cession_info_relevanter_ort,
  messagesCessionInfo.fields_cession_info_fixingtyp,
];

export const interestNotificationTitles = [
  messagesInterestNotificationTitles.fields_interest_notification_titles_datum,
  messagesInterestNotificationTitles.fields_interest_notification_titles_referenzzinssatz,
  messagesInterestNotificationTitles.fields_interest_notification_titles_referenzzinssatz_tag,
  messagesInterestNotificationTitles.fields_interest_notification_titles_zinssatz,
  messagesInterestNotificationTitles.fields_interest_notification_titles_periode_von,
  messagesInterestNotificationTitles.fields_interest_notification_titles_periode_bis,
  messagesInterestNotificationTitles.fields_interest_notification_titles_anzahl_tage,
  messagesInterestNotificationTitles.fields_interest_notification_titles_zinsbetrag,
  messagesInterestNotificationTitles.fields_interest_notification_titles_zahltag,
  messagesInterestNotificationTitles.fields_interest_notification_titles_zinsfestsetzung,
];

//------------- This block is sample data for test  --------------------------

export const bssdSampleDataForTest = {
  fingerprint: "",
  darlehensnehmer: "",
  umsatzsteuer_id_darlehensnehmer: "",
  anschrift_darlehensnehmer: "",
  telefonnummer_darlehensnehmer: "",
  lei_darlehensnehmer: "",
  digits_kennung_darlehensnehmer: "",
  erfassender_mitarbeiter_darlehensnehmer: "",
  unser_zeichen_darlehensnehmer: "",
  iban_darlehensnehmer: "DE84500105176619333197",
  bic_code_darlehensnehmer: "DNbic",
  vertragsreferenz_nr_darlehensnehmer: "DN123456",
  interne_kennung_darlehensnehmer: "DN099877",
  finledger_urkunde_verwenden: true,

  darlehensgeber: "",
  umsatzsteuer_id_darlehensgeber: "",
  anschrift_darlehensgeber: "",
  lei_darlehensgeber: "",
  telefonnummer_darlehensgeber: "",
  digits_kennung_darlehensgeber: "",
  unser_zeichen_darlehensgeber: "",
  iban_darlehensgeber: "",
  bic_code_darlehensgeber: "",
  iban_darlehensgeber_tilgung: "",
  bic_code_darlehensgeber_tilgung: "",
  vertragsreferenz_nr_darlehensgeber: "DG176556",
  interne_kennung_darlehensgeber: "DG2343477",

  handelstag: "23.04.2017",
  handelstag_uhrzeit: "20:45",
  nominal: "10.749.000,12",
  waehrung_nominal: "EUR",
  kurs: "123.100,23",
  notiz: "%",
  kaufpreis: "13.232.043.870,42",
  waehrung_kaufpreis: "EUR",
  valuta: "30.09.2020",
  endfaelligkeit: "30.09.2021",

  zins_fix: "fix",
  referenzzinssatz: "1-Monats-EURIBOR",
  aufschlag: "0",
  minimumzinssatz: "1,4",
  abschlag: "0",
  maximumzinssatz: "3,2",
  faktor: "1",

  bankarbeitstage: 3,
  bildschirmseite: "REUTERS Seite EURIBOR01",
  relevanteuhrzeit: "20:45",
  relevanterort: "London",
  fixingtyp: "In-Advance",

  zinssatz: "123.220,285",
  zinszahlungsrhythmus: "monatlich",
  zinstermin_tag: 30,
  zinstermin_monat: [], // ["April", "Mai", "September"],
  zinsmethode: "act/act ICMA Rule 251",
  faelligkeitstag_der_zinszahlung: "following - nachfolgend",
  berechnungszeitraum_der_zinszahlung: "unadjusted - nicht angepasst",
  erster_zinstermin: "30.09.2017",
  vorletzter_zinstermin: "26.08.2017",
  geschaeftstagekalender: ["London", "Paris"],

  kuendigungsrecht: "kündbar",
  kuendigungTermine: [
    {
      kuendigungstermin: "30.04.2020",
      kuendigungsvaluta: "30.04.2020",
      kuendigungskurs: "1.234,56234",
    },
    {
      kuendigungstermin: "30.04.2020",
      kuendigungsvaluta: "30.04.2020",
      kuendigungskurs: "1.234,56234",
    },
  ],
  kuendigungsfrist: 3,

  abtretbarkeit: "abtretbar",
  mindestbetrag_bei_abtretung: "10",
  waehrung_mindestbetrag_bei_abtretung: "EUR",
  auslandsfaehigkeit: "nicht auslandsfähig",
  vag_konform: "Ja",
  mrel_72b_crr: "Nein",
  kwg_46f: "preferred",
  sag_49: "Ja",
  tlac: "Nein",
  urkunden_registernummer: "123adb",
  blockchain_anwendung: "Finledger App",
  gerichtsstand: "Frankfurt am Main",
};

export const cssdSampleDataForTest = {
  urkundenRegisternummer: "Urkunde 1001",
  konsortialkennung: "Konsortium 42",
  darlehensbetrag: "105.000.000,00",
  laufzeitbeginn: "30.12.2020",
  mindestbetragBeiAbtretung: "500.000,00",
  spaetesteAbtretungsanzeige: "15",
  sprache: "de",
  darlehensart: "SCHULDSCHEINDARLEHEN",
  waehrung: "EUR",
  hasZahlstellenGebuehr: false,
  hasArrangierProvision: false,
  zinsUndTilgungskonditionen: {
    zinssatz: "2,000",
    marge: "",
    minimumZinssatz: "1",
    maximumZinssatz: "8",
    ersterZinstermin: "31.12.2020",
    vorletzterZinstermin: "30.04.2030",
    endfaelligkeit: "31.05.2030",
    verzinsung: "FIX_GEMAESS_VERTRAG",
    referenzzinssatz: "RZ_1_Monats_EURIBOR",
    margeTyp: "KEINE_MARGE",
    zahlungsrhythmus: "HALBJAEHRLICH",
    zinsmethode: "ZM_ACT_ACT_ICMA_RULE_251",
    faelligkeitstagDerZinszahlung: "FOLLOWING",
    berechnungszeitraumDerZinszahlung: "UNADJUSTED",
    zinsterminTag: 30,
    zinsterminMonat: ["maerz", "juni"],
    stepupStepdownRegelung: "SIEHE_VERTRAGLICHE_REGELUNG",
    tilgungsart: "GESAMTFAELLIG_AM_LAUFZEITENDE",
  },
  darlehensnehmer: {
    idProxyAdresse: "0x0bFa1E216F4DEA74e02B4D14fc211AF188ed038C",
    firmenname: "Helaba",
    digitsKennung: "HELABA",
    umsatzSteuerId: "DE82341234",
    anschrift: "Helaba_Anschrift",
    sitzLand: "DE",
    sprache: "de",
    ansprechpartnerAbwicklung: "Max Nehmermann",
    abteilungAbwicklung: "CSSD ABW Wiesbaden 2",
    telefonnummer: "+49 30 12345",
    emailVerteiler: "helaba.mail@helaba.de",
    bicAllgemein: "HELADEFF123",
    ibanTilgungEur: "DE84500105176619333197",
    bicTilgungEur: "HELADEFF123",
    lei: "HE_123",
    hinweisZahlungTilgung: "Bitte immer Urkundennr. als Verwendungszweck angeben!",
    belastungInternesKonto: true,
  },
  kauf: {
    geschaeftsnummer: "G-20200001",
    nominal: "85.000.000,00",
    kurs: "100,00000000",
    kaufpreis: "15.000.000,00",
    handelstag: "29.05.2020",
    valuta: "30.12.2020",
  },
  zahlstelle: {
    idProxyAdresse: "0xDA2E40B859a99e2F9C1F24cD92300E4C6007bA8f",
    firmenname: "DZ BANK",
    digitsKennung: "DZ",
    anschrift: "DZ_Anschrift",
    sitzLand: "DE",
    sprache: "de",
    ansprechpartnerAbwicklung: "Max Zahlmann",
    abteilungAbwicklung: "CSSD ABW FRA 9",
    telefonnummer: "+49 30 12345",
    emailVerteiler: "dz.mail@test.de",
    bicAllgemein: "HELADEFF123",
    lei: "DZ_1234",
    erfassenderMitarbeiter: "DZ_Mueller",
    umsatzSteuerId: "1234",
    hinweisZahlungZins: "Hinweis Zins",
    ibanZinsEur: "DE84500105176619333197",
    bicZinsEur: "HELADEFF123",
    ibanTilgungEur: "DE84500105176619333197",
    bicTilgungEur: "HELADEFF123",
    hinweisZahlungTilgung: "Hinweis Tilgung",
  },
};

//------------- This block is sample data for test  --------------------------

export const rebuySampleDataForTest = {
  handelstag_rueckkauf: "23.09.2019",
  uhrzeit: "14:05",
  nominal: 100000.66,
  waehrung_nominal: "EUR",
  urspruengliche_endfaelligkeit: "30.09.2020",
  ruecknahmekurs: 98.12345678,
  notiz_ruecknahmekurs: "%",
  ruecknahmebetrag: 100000,
  waehrung_ruecknahmebetrag: "EUR",
  valuta_ruecknahmebetrag: "17.08.2017",
  aufgelaufene_stueckzinsen: 7688000,
  waehrung_aufgelaufene_stueckzinsen: "EUR",
  valuta_aufgelaufene_stueckzinsen: "18.08.2019",
  urkunden_registernummer: "123adbcdef",
};

//------------- This block is initial and emty value for SSD fields  --------------------------

export const bssdInitialValue = {
  fingerprint: "",
  darlehensnehmer: "",
  umsatzsteuer_id_darlehensnehmer: "",
  anschrift_darlehensnehmer: "",
  telefonnummer_darlehensnehmer: "",
  lei_darlehensnehmer: "",
  digits_kennung_darlehensnehmer: "",
  erfassender_mitarbeiter_darlehensnehmer: "",
  unser_zeichen_darlehensnehmer: "",
  iban_darlehensnehmer: "",
  bic_code_darlehensnehmer: "",
  vertragsreferenz_nr_darlehensnehmer: "",
  interne_kennung_darlehensnehmer: "",
  finledger_urkunde_verwenden: true,

  darlehensgeber: "",
  umsatzsteuer_id_darlehensgeber: "",
  anschrift_darlehensgeber: "",
  lei_darlehensgeber: "",
  telefonnummer_darlehensgeber: "",
  unser_zeichen_darlehensgeber: "",
  digits_kennung_darlehensgeber: "",
  iban_darlehensgeber: "",
  bic_code_darlehensgeber: "",
  iban_darlehensgeber_tilgung: "",
  bic_code_darlehensgeber_tilgung: "",
  vertragsreferenz_nr_darlehensgeber: "",
  interne_kennung_darlehensgeber: "",

  handelstag: null,
  handelstag_uhrzeit: "",
  nominal: "0",
  waehrung_nominal: "",
  kurs: "0",
  notiz: "",
  kaufpreis: "0",
  waehrung_kaufpreis: "",
  valuta: null,
  endfaelligkeit: null,

  zins_fix: "fix",
  referenzzinssatz: "1-Monats-EURIBOR",
  aufschlag: "0",
  minimumzinssatz: "",
  abschlag: "0",
  maximumzinssatz: "",
  faktor: "",

  bankarbeitstage: 0,
  bildschirmseite: "",
  relevanteuhrzeit: "",
  relevanterort: "",
  fixingtyp: "In-Advance",

  zinssatz: "0",
  zinszahlungsrhythmus: "",
  zinstermin_tag: 0,
  zinstermin_monat: [],
  zinsmethode: "",
  faelligkeitstag_der_zinszahlung: "",
  berechnungszeitraum_der_zinszahlung: "",
  erster_zinstermin: null,
  vorletzter_zinstermin: null,
  geschaeftstagekalender: [],

  kuendigungsrecht: "nicht kündbar",
  kuendigungTermine: [],
  kuendigungsfrist: 0,

  abtretbarkeit: "nicht abtretbar",
  mindestbetrag_bei_abtretung: "0",
  waehrung_mindestbetrag_bei_abtretung: "",
  auslandsfaehigkeit: "nicht auslandsfähig",
  vag_konform: "Ja",
  mrel_72b_crr: "Nein",
  kwg_46f: "preferred",
  sag_49: "Ja",
  tlac: "Nein",
  urkunden_registernummer: "",
  blockchain_anwendung: "",
  gerichtsstand: "",
};

export const rebuyInitialValue = {
  fingerprint_rebuy: "",
  handelstag_rueckkauf: null,
  uhrzeit: null,
  nominal: "",
  waehrung_nominal: "",
  urspruengliche_endfaelligkeit: "",
  ruecknahmekurs: "0",
  notiz_ruecknahmekurs: "",
  ruecknahmebetrag: "",
  waehrung_ruecknahmebetrag: "",
  valuta_ruecknahmebetrag: null,
  aufgelaufene_stueckzinsen: "0",
  waehrung_aufgelaufene_stueckzinsen: "",
  valuta_aufgelaufene_stueckzinsen: null,
  urkunden_registernummer: "",
};

export const cessionInitialValue = {
  darlehensgeber: "",
  umsatzsteuer_id_darlehensgeber: "",
  anschrift_darlehensgeber: "",
  telefonnummer_darlehensgeber: "",
  lei_darlehensgeber: "",
  digits_kennung_darlehensgeber: "",
  interne_kennung_darlehensgeber: "",
  vertragsreferenz_darlehensgeber: "",
  unser_zeichen_darlehensgeber: "",
  iban_darlehensgeber_zinsen: "",
  bic_code_darlehensgeber_zinsen: "",
  iban_darlehensgeber_tilgung: "",
  bic_code_darlehensgeber_tilgung: "",

  datum_abtretung: null,
  uhrzeit: null,
  abgetretenes_nominal: "",
  waehrung_nominal: "",
  valuta_abtretung: null,
  abtretungskurs: "",
  notiz_abtretungskurs: "",
  gegenwert_abtretung: "",
  waehrung_gegenwert_abtretung: "",
  stueckzinsen: "",
  waehrung_stueckzinsen: "",
  valuta_stueckzinsen: null,
  naechster_zinstermin: null,
  urkunden_registernummer: "",
};

export const abtretungDekaInitValue = {
  geschaeftsnummer: "",
  neuerDarlehensgeber: {
    idProxyAdresse: "0xf71d7B6bD8536D45ABcc4d66d9D84f41694B763E",
    firmenname: "DekaBank",
    lei: "Deka_1234",
    anschrift: "Deka_Anschrift",
    digitsKennung: "DEKA",
    umsatzSteuerId: "1234",
    sitzLand: "DE",
    sprache: "de",
    ansprechpartnerAbwicklung: "Ansprechpartner",
    abteilungAbwicklung: "Abteilung",
    telefonnummer: "+49 30 12345",
    emailVerteiler: "deka.mail@deka.de",
    bicAllgemein: "HELADEFF123",
    ibanZinsEur: "DE84 5001 0517 6619 3331 97",
    bicZinsEur: "HELADEFF123",
    hinweisZahlungZins: "Hinweis",
    ibanTilgungEur: "DE84 5001 0517 6619 3331 97",
    bicTilgungEur: "HELADEFF123",
    hinweisZahlungTilgung: "Hinweis",
    belastungInternesKonto: true,
  },
  abtretungsdatum: "29.06.2021",
  nominal: 500000,
  valuta: "29.06.2025",
  valutaStueckzinsen: "31.07.2021",
  kurs: 100,
  naechsterZinstermin: "01.07.2021",
  gerichtsstand: "Essen",
};

export const abtretungTheBankInitValue = {
  geschaeftsnummer: "",
  neuerDarlehensgeber: {
    idProxyAdresse: "0x2a2c2d042992B50580F3604d67063E4711dD4E59",
    firmenname: "TheBank",
    lei: "TB_1234",
    anschrift: "TheBank_Anschrift",
    digitsKennung: "THEBANK",
    umsatzSteuerId: "1234",
    sitzLand: "DE",
    sprache: "de",
    ansprechpartnerAbwicklung: "Ansprechpartner",
    abteilungAbwicklung: "Abteilung",
    telefonnummer: "+49 30 12345",
    emailVerteiler: "thebank.mail@thebank.de",
    bicAllgemein: "HELADEFF123",
    ibanZinsEur: "DE84 5001 0517 6619 3331 97",
    bicZinsEur: "HELADEFF123",
    hinweisZahlungZins: "Hinweis",
    ibanTilgungEur: "DE84 5001 0517 6619 3331 97",
    bicTilgungEur: "HELADEFF123",
    hinweisZahlungTilgung: "Hinweis",
    belastungInternesKonto: true,
  },
  abtretungsdatum: "29.06.2021",
  nominal: 500000,
  valuta: "29.06.2025",
  valutaStueckzinsen: "31.07.2021",
  kurs: 100,
  naechsterZinstermin: "01.07.2021",
  gerichtsstand: "Essen",
};

export const terminationInitValue = {
  kuendigungsgrund: "Künd Grund",
  kuendigungsgrundZweiteSprache: "Term Reason",
  kuendigungstermin: "24.12.2020",
  kurs: 100,
  valuta: "31.12.2020",
};

export function formatTitleField(index, key, data) {
  if (key === "iban_darlehensgeber_zinsen" && !data.iban_darlehensgeber_tilgung) {
    return messagesCessionTitles.fields_cession_titles_iban_zinsen_tilgung;
  } else if (key === "bic_code_darlehensgeber_zinsen" && !data.bic_code_darlehensgeber_tilgung) {
    return messagesCessionTitles.fields_cession_titles_bic_zinsen_tilgung;
  } else {
    return cessionTitles[index];
  }
}

export const partialClaimExportHeaderKeys = [
  {
    prefix: "cssd_pre",
    keys: [
      "urkundenRegisternummer",
      "konsortialkennung",
      "darlehensart",
      "waehrung",
      "darlehensbetrag",
      "laufzeitbeginn",
      "mindestbetragBeiAbtretung",
      "sprache",
      "zahlstellengebuehr",
      "arrangierungsprovision",
      "endfaelligkeit",
      "tilgungsart",
      "verzinsung",
      "zinssatz",
      "referenzzinssatz",
      "margeTyp",
      "marge_aufschlag_abschlag",
      "minimumZinssatz",
      "maximumZinssatz",
      "zahlungsrhythmus",
      "zinsmethode",
      "faelligkeitstagDerZinszahlung",
      "berechnungszeitraumDerZinszahlung",
      "zinsterminTag",
      "zinsterminMonat",
      "ersterZinstermin",
      "vorletzterZinstermin",
      "stepupStepdownRegelung",
    ],
  },
  {
    prefix: "dn_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
  {
    prefix: "zs_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
  {
    prefix: "dg_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
  {
    prefix: "tf_pre",
    keys: [
      "nominal",
      "kurs",
      "gegenwert",
      "datum",
      "valuta",
      "naechsterZinstermin",
      "geschaeftsnummer",
      "status",
      "partialClaimCurrentAmount",
    ],
  },
  {
    prefix: "abtretung_pre",
    keys: ["valutaStueckzins"],
  },
  /*
  {
    keys: ["checked"],
  },
  */
];

export const cessionExportHeaderKeys = [
  {
    prefix: "abtretung_pre",
    keys: [
      "nominal",
      "kurs",
      "gegenwert",
      "datum",
      "valuta",
      "valutaStueckzins",
      "naechsterZinstermin",
      "gerichtsstand",
      "geschaeftsnummer",
      "status",
    ],
  },
  {
    prefix: "cssd_pre",
    keys: [
      "urkundenRegisternummer",
      "konsortialkennung",
      "darlehensart",
      "waehrung",
      "darlehensbetrag",
      "laufzeitbeginn",
      "mindestbetragBeiAbtretung",
      "sprache",
      "zahlstellengebuehr",
      "arrangierungsprovision",
      "endfaelligkeit",
      "tilgungsart",
      "verzinsung",
      "zinssatz",
      "referenzzinssatz",
      "margeTyp",
      "marge_aufschlag_abschlag",
      "minimumZinssatz",
      "maximumZinssatz",
      "zahlungsrhythmus",
      "zinsmethode",
      "faelligkeitstagDerZinszahlung",
      "berechnungszeitraumDerZinszahlung",
      "zinsterminTag",
      "zinsterminMonat",
      "ersterZinstermin",
      "vorletzterZinstermin",
      "stepupStepdownRegelung",
    ],
  },
  {
    prefix: "dn_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
  {
    prefix: "dg_bisher_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
  {
    prefix: "dg_neu_pre",
    keys: [
      "firmenname",
      "finledger_kennung",
      "umsatzsteuer_id",
      "anschrift",
      "sitzland",
      "sprache",
      "ansprechpartner",
      "abteilung",
      "telefonnummer",
      "emailVerteiler",
      "bic",
      "konto_zins",
      "bic_zins",
      "hinweis_zahlung_zins",
      "konto_tilgung",
      "bic_tilgung",
      "hinweis_zahlung_tilgung",
      "belastung_internes_konto",
      "interneKennung",
    ],
  },
];

export const bssdExportHeaderKeys = [
  {
    prefix: "ssd_pre",
    keys: [
      "urkundenRegisternummer",
      "status",
      "status_payment",
      "handelstag",
      "nominal",
      "waehrung_nominal",
      "kurs",
      "notiz",
      "kaufpreis",
      "waehrung_preis",
      "valuta",
      "endfaelligkeit",
      "zinssatz",
      "verzinsung",
      "referenzzinssatz",
      "aufschlag",
      "abschlag",
      "minimumZinssatz",
      "maximumZinssatz",
      "faktor",
      "zinszahlungsrhythmus",
      "bankarbeitstage",
      "bildschirmseite",
      "uhrzeit",
      "ort",
      "fixing",
      "zinsmethode",
      "faelligkeitstag",
      "berechnungszeitraum",
      "zinsterminTag",
      "zinsterminMonat",
      "ersterZinstermin",
      "vorletzterZinstermin",
      "geschaeftstagekalender",
      "kuendigungsrecht",
      "kuendigungsfrist",
      "abtretbar",
      "mindestbetragBeiAbtretung",
      "waehrung_mindestbetrag",
      "auslandsfaehig",
      "vag",
      "mrel",
      "kwg",
      "sag",
      "tlac",
    ],
  },
  {
    prefix: "dn_pre",
    keys: [
      "firmenname",
      "umsatzsteuer_id",
      "anschrift",
      "telefonnummer",
      "lei",
      "finledger_kennung",
      "erfasser",
      "interneKennung",
      "vertragsrefNr",
      "unserZeichen",
      "iban",
      "bic",
    ],
  },
  {
    prefix: "dg_pre",
    keys: [
      "firmenname",
      "umsatzsteuer_id",
      "anschrift",
      "telefonnummer",
      "lei",
      "finledger_kennung",
      "erfasser",
      "interneKennung",
      "vertragsrefNr",
      "unserZeichen",
      "iban",
      "iban_tilgung",
      "bic",
      "bic_tilgung",
    ],
  },
];
