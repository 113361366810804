import { defineMessages } from "react-intl";

export const messages = defineMessages({
  currentBusiness: {
    id: "cssd.zinsmitteilungen.aktuellesGeschaeft",
    defaultMessage: "Aktuelles Geschäft",
  },
  darlehensnehmer: {
    id: "cssd.zinsmitteilungen.darlehensnehmer",
    defaultMessage: "Darlehensnehmer",
  },
  urkundenRegisternummer: {
    id: "cssd.zinsmitteilungen.urkundenRegisternummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  interneKennung: {
    id: "cssd.zinsmitteilungen.interneKennung",
    defaultMessage: "Interne Kennung",
  },
  laufzeitbeginn: {
    id: "cssd.zinsmitteilungen.laufzeitbeginn",
    defaultMessage: "Laufzeitbeginn",
  },
  sprache: {
    id: "cssd.zinsmitteilungen.sprache",
    defaultMessage: "Sprache CSSD",
  },
  referenzzinssatz: {
    id: "cssd.zinsmitteilungen.referenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  minimumzins: {
    id: "cssd.zinsmitteilungen.minimumzinssatz",
    defaultMessage: "Minimumzinssatz",
  },
  stepupdown: {
    id: "cssd.zinsmitteilungen.stepup_stepdown",
    defaultMessage: "Step-Up/ Step-Down",
  },
  zahlstelle: {
    id: "cssd.zinsmitteilungen.zahlstelle",
    defaultMessage: "Zahlstelle",
  },
  darlehensbetrag: {
    id: "cssd.zinsmitteilungen.darlehensbetrag",
    defaultMessage: "Darlehensbetrag",
  },
  waehrung: {
    id: "cssd.zinsmitteilungen.waehrung",
    defaultMessage: "Währung Nominal",
  },
  mindestbetrag: {
    id: "cssd.zinsmitteilungen.mindestbetragBeiAbtretung",
    defaultMessage: "Mindestbetrag bei Übertragung",
  },
  endfaelligkeit: {
    id: "cssd.zinsmitteilungen.endfaelligkeit",
    defaultMessage: "Endfälligkeit",
  },
  aufabschlag: {
    id: "cssd.zinsmitteilungen.auf_abschlag",
    defaultMessage: "Auf-/Abschlag",
  },
  maximumzins: {
    id: "cssd.zinsmitteilungen.maximumzinssatz",
    defaultMessage: "Maximumzinssatz",
  },
  interestNotifications: {
    id: "cssd.zinsmitteilungen.zinsmitteilungen",
    defaultMessage: "Zinsmitteilungen",
  },
  status: {
    id: "cssd.zinsmitteilungen.status",
    defaultMessage: "Status",
  },
  actions: {
    id: "cssd.zinsmitteilungen.actions",
    defaultMessage: "Aktion",
  },
  date: {
    id: "cssd.zinsmitteilungen.datum",
    defaultMessage: "Datum",
  },
  zinsperiodeVon: {
    id: "cssd.zinsmitteilungen.zinsperiondeVon",
    defaultMessage: "Zinsperiode von",
  },
  zinsperiodeBis: {
    id: "cssd.zinsmitteilungen.zinsperiodeBis",
    defaultMessage: "Zinsperiode bis",
  },
  zinssatz: {
    id: "cssd.zinsmitteilungen.zinssatz",
    defaultMessage: "Zinssatz",
  },
  margenanpassung: {
    id: "cssd.zinsmitteilungen.margenanpassung",
    defaultMessage: "Margenanpassung",
  },
  abort: {
    id: "cssd.zinsmitteilungen.abbrechen",
    defaultMessage: "Abbrechen",
  },
  save: {
    id: "cssd.zinsmitteilungen.save",
    defaultMessage: "Speichern",
  },
  saveAndConfirm: {
    id: "cssd.zinsmitteilungen.saveAndConfirm",
    defaultMessage: "Speichern und Erstbestätigen",
  },
  register: {
    id: "cssd.zinsmitteilungen.zinsmitteilungErfassen",
    defaultMessage: "Zinsmitteilung erfassen",
  },
  feststellungstagDesReferenzzinses: {
    id: "cssd.zinsmitteilungen.feststellungstagDesReferenzzinses",
    defaultMessage: "Feststellungstag d. Referenzzinses",
  },
  zahltag: {
    id: "cssd.zinsmitteilungen.zahltag",
    defaultMessage: "Zahltag",
  },
  naechsteZinsfestsetzung: {
    id: "cssd.zinsmitteilungen.naechsteZinsfestsetzung",
    defaultMessage: "Nächste Zinsfestsetzung",
  },
  zinsfestlegungsart: {
    id: "cssd.zinsmitteilungen.artDerZinsfestsetzung",
    defaultMessage: "Art der Zinsfestsetzung",
  },
  anzahlTage: {
    id: "cssd.zinsmitteilungen.anzahlTage",
    defaultMessage: "Anzahl Tage",
  },
  festgestellterReferenzzinssatz: {
    id: "cssd.zinsmitteilungen.festgestellterReferenzzinssatz",
    defaultMessage: "Festgestellter Referenzzinssatz",
  },
  variableVerzinsung: {
    id: "cssd.zinsmitteilungen.variableVerzinsung",
    defaultMessage: "variable Verzinsung",
  },
  variableVerzinsungUpDown: {
    id: "cssd.zinsmitteilungen.variableVerzinsungUpDown",
    defaultMessage: "variable Verzinsung und Stepup/-down",
  },
  stepUpDown: {
    id: "cssd.zinsmitteilung.stepUpDown",
    defaultMessage: "Stepup/-down",
  },
  status_erfasst: {
    id: "cssd.zinsmitteilungen.status.erfasst",
    defaultMessage: "Erfasst",
  },
  status_erstbestaetigt: {
    id: "cssd.zinsmitteilungen.status.erstbestaetigt",
    defaultMessage: "Erstbestätigt",
  },
  status_abgestimmt: {
    id: "cssd.zinsmitteilungen.status.abgestimmt",
    defaultMessage: "Abgestimmt",
  },
  status_storniert: {
    id: "cssd.zinsmitteilungen.status.storniert",
    defaultMessage: "Storniert",
  },
  status_geloescht: {
    id: "cssd.zinsmitteilungen.status.geloescht",
    defaultMessage: "Gelöscht",
  },
  status_invalid: {
    id: "cssd.zinsmitteilungen.status.invalid",
    defaultMessage: "Invalid",
  },
  delete: {
    id: "cssd.zinsmitteilungen.loeschen",
    defaultMessage: "Löschen",
  },
  edit: {
    id: "cssd.zinsmitteilungen.bearbeiten",
    defaultMessage: "Bearbeiten",
  },
  firstConfirm: {
    id: "cssd.zinsmitteilungen.erstbestaetigen",
    defaultMessage: "Erstbestätigen",
  },
  secondConfirm: {
    id: "cssd.zinsmitteilungen.zweitbestaetigen",
    defaultMessage: "Zweitbestätigen",
  },
  cancel: {
    id: "cssd.zinsmitteilungen.stornieren",
    defaultMessage: "Stornieren",
  },
  yes: {
    id: "cssd.zinsmitteilungen.yes",
    defaultMessage: "Bestätigen",
  },
  no: {
    id: "cssd.zinsmitteilungen.no",
    defaultMessage: "Abbrechen",
  },
  cancel_title: {
    id: "cssd.zinsmitteilungen.stornieren_titel",
    defaultMessage: "Zinsmitteilung stornieren",
  },
  remove_title: {
    id: "cssd.zinsmitteilungen.loeschen_titel",
    defaultMessage: "Zinsmitteilung löschen",
  },
  cancel_question: {
    id: "cssd.zinsmitteilungen.stornieren_abfrage",
    defaultMessage: "Sind Sie sicher, dass Sie die Zinsmitteilung stornieren möchten?",
  },
  deletion_question: {
    id: "cssd.zinsmitteilungen.loeschen_abfrage",
    defaultMessage: "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
  },
  error: {
    id: "cssd.zinsmitteilungen.speichern.fehler",
    defaultMessage: "Fehler beim Speichern",
  },
  success: {
    id: "cssd.zinsmitteilungen.speichern.erfolg",
    defaultMessage: "Speichern erfolgreich",
  },
  error_delete: {
    id: "cssd.zinsmitteilungen.loeschen.fehler",
    defaultMessage: "Fehler beim Löschen",
  },
  success_delete: {
    id: "cssd.zinsmitteilungen.loeschen.erfolg",
    defaultMessage: "Löschen erfolgreich",
  },
  zinsmitteilung: {
    id: "cssd.zinsmitteilungen",
    defaultMessage: "Zinsmitteilung",
  },
});
