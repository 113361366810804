import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveWalletActions } from "redux/walletRegistration/actions";
import { wallet_styles } from "./wallet_styles";
import history from "util/history";
import WalletSuccessModal from "./WalletSuccessModal/WalletSuccessModal";
import jwt_decode from "jwt-decode";
import {
  checkBic,
  checkEmail,
  checkIban,
  checkLEI,
  checkSecondIban,
  checkTel,
  checkUmsatzsteuerId,
  checkUrl,
  ibansNotMatching,
} from "util/validation.js";
import {
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { formatIban, getSprache } from "util/convertor";
import { appIntl } from "components/i18n/intl";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import SitzlandAutocomplete from "components/Shared/SitzlandAutocomplete";
import Select from "@material-ui/core/Select";
import { supportedLanguages } from "util/constants";
import BackButton from "components/Shared/Button/BackButton";

class WalletRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletData: {
        uk_wallet_adresse: props.account.address,
        firmenname: "",
        umsatzsteuer_id: "",
        anschrift: "",
        sitzLand: "",
        sprache: "de",
        telefonnummer: "",
        lei: "",
        erfassender_mitarbeiter: props.mitarbeiterName,
        email_erfassender_mitarbeiter: "",
        alternativer_ansprechpartner_fuer_mitteilungen: "",
        telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen: "",
        email_verteiler: "",
        backend_url_id_proxy: process.env.REACT_APP_ONBOARDING_DEFAULT_BACKEND_URL
          ? process.env.REACT_APP_ONBOARDING_DEFAULT_BACKEND_URL
          : "",
        iban: "",
        bic: "",
        iban_tilgung: "",
        bic_tilgung: "",
        bic_allgemein: "",
        second_bank_account: false,
        vier_augen_bestaetigung: true,
        idproxy_adresse: "",
      },
      emptyFields: [],
      fieldIsValid: {
        lei: true,
        umsatzsteuer_id: true,
        email_verteiler: true,
        backend_url_id_proxy: true,
        telefonnummer: true,
        telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen: true,
        iban: true,
        bic: true,
        iban_tilgung: true,
        bic_tilgung: true,
        bic_allgemein: true,
      },
    };
  }
  //---------------------------------------------------------------------------
  componentDidMount() {
    this.checkValidity();
  }
  //---------------------------------------------------------------------------
  handleChange = (key) => (event) => {
    this.setState(
      {
        walletData: {
          ...this.state.walletData,
          [key]: event.target.value,
        },
      },
      () => {
        this.checkValidity();
      }
    );
  };
  //---------------------------------------------------------------------------
  handleCheckboxChange = (key) => (event) => {
    let walletData = { ...this.state.walletData };

    this.setState(
      {
        walletData: {
          ...walletData,
          [key]: event.target.checked,
        },
      },
      () => this.checkValidity()
    );
  };
  //---------------------------------------------------------------------------
  checkValidity = () => {
    const { walletData } = this.state;
    let isValid = true,
      validatedFields = [
        "telefonnummer",
        "telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen",
        "email_verteiler",
        "umsatzsteuer_id",
        "lei",
        "backend_url_id_proxy",
        "iban",
        "bic",
        "bic_allgemein",
      ];

    if (walletData.second_bank_account) {
      validatedFields.push("iban_tilgung");
      validatedFields.push("bic_tilgung");
    }

    let tempObj = {};
    for (let field of validatedFields) {
      let validity = document.getElementById(field).getAttribute("isvalid") === "true";
      tempObj[field] = validity;
      if (!validity) isValid = false;
    }
    this.setState({
      fieldIsValid: tempObj,
    });

    return isValid;
  };

  //---------------------------------------------------------------------------
  checkEmptyFields = (fieldsList, data) => {
    let emptyFieldsArray = [];
    for (let field of fieldsList) {
      let value = data[field];
      if (value == null || value.toString() === "" || Number(value) === 0)
        emptyFieldsArray.push(field);
    }
    this.setState({ emptyFields: emptyFieldsArray });
    return emptyFieldsArray;
  };
  //---------------------------------------------------------------------------
  handleConfirmClick = (event) => {
    event.preventDefault();
    let { walletData } = this.state;

    let requiredFields = [
      "firmenname",
      "umsatzsteuer_id",
      "anschrift",
      "sitzLand",
      "sprache",
      "lei",
      "email_verteiler",
      "backend_url_id_proxy",
    ];

    //clear 2nd iban&bic
    if (!walletData.second_bank_account) {
      walletData = { ...walletData, iban_tilgung: "", bic_tilgung: "" };
    }

    if (this.checkEmptyFields(requiredFields, walletData).length === 0 && this.checkValidity())
      this.setState(
        {
          walletData: {
            ...walletData,
            email_erfassender_mitarbeiter: this.props.platformMemberEmail,
            idproxy_adresse: jwt_decode(this.props.token).idproxyaddress,
          },
        },
        () => this.props.onSaveWallet(this.state.walletData, this.props.onBoardingUrl)
      );
  };
  //---------------------------------------------------------------------------
  handleCancelClick = (event) => {
    event.preventDefault();
    history.push("/login");
  };
  //---------------------------------------------------------------------------
  render() {
    const { classes } = this.props;
    const { walletData, emptyFields, fieldIsValid } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h5" align={"center"}>
              <FormattedMessage
                {...messages.wallet_registration_paper_unternehmen_registrieren_stammdaten}
              />
            </Typography>
            <br />
            <Typography variant="subtitle2" align={"left"} className={classes.warning}>
              <FormattedMessage
                {...messages.wallet_registration_paper_folgende_pflichtfelder_unveraenderbar}
              />
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="firmenname"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_firmenname
                    )}
                    inputProps={{ maxLength: 300 }}
                    className={classes.textField}
                    onChange={this.handleChange("firmenname")}
                    error={emptyFields.includes("firmenname")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="umsatzsteuer_id"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_umsatzsteuer_id
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("umsatzsteuer_id")}
                    error={emptyFields.includes("umsatzsteuer_id")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkUmsatzsteuerId(walletData.umsatzsteuer_id),
                    }}
                  />
                  {!fieldIsValid["umsatzsteuer_id"] && (
                    <FormHelperText error={!fieldIsValid["umsatzsteuer_id"]}>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_umsatzsteuer_id_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="anschrift"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_anschrift
                    )}
                    multiline
                    rows="3"
                    inputProps={{ maxLength: 450 }}
                    className={classes.textField}
                    onChange={this.handleChange("anschrift")}
                    error={emptyFields.includes("anschrift")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SitzlandAutocomplete
                    required
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_sitzland
                    )}
                    onBlur={(value) => this.handleChange("sitzLand")({ target: { value } })}
                    error={emptyFields.includes("sitzLand")}
                    defaultValue="DE"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.textField} required>
                    <InputLabel>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_text_field_label_sprache}
                      />
                    </InputLabel>
                    <Select
                      error={emptyFields.includes("sprache")}
                      inputProps={{
                        name: "sprache",
                        id: "sprache",
                        value: walletData.sprache,
                      }}
                      onChange={this.handleChange("sprache")}
                    >
                      {supportedLanguages.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          {getSprache(lang)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="lei"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_lei
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("lei")}
                    error={emptyFields.includes("lei")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkLEI(walletData.lei),
                    }}
                  />
                  {!fieldIsValid["lei"] && (
                    <FormHelperText error={!fieldIsValid["lei"]}>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_lei_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="email_verteiler"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_email_verteiler
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("email_verteiler")}
                    error={emptyFields.includes("email_verteiler")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkEmail(walletData.email_verteiler),
                    }}
                  />
                  {!fieldIsValid["email_verteiler"] && (
                    <FormHelperText error={!fieldIsValid["email_verteiler"]}>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_email_verteiler_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="backend_url_id_proxy"
                    value={this.state.walletData.backend_url_id_proxy}
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_backend_url
                    )}
                    className={classes.textField}
                    /*                     aria-describedby="component-error-text"
                     */ onChange={this.handleChange("backend_url_id_proxy")}
                    error={emptyFields.includes("backend_url_id_proxy")}
                    inputProps={{
                      maxLength: 500,
                      isvalid: checkUrl(walletData.backend_url_id_proxy),
                    }}
                  />
                  {!fieldIsValid["backend_url_id_proxy"] && (
                    <FormHelperText
                      error={!fieldIsValid["backend_url_id_proxy"]}
                      /*                       id="component-error-text"
                       */
                    >
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_backend_url_muss_mit_http_s_beginnen}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    align={"left"}
                    className={classes.warningOptional}
                  >
                    <FormattedMessage
                      {...messages.wallet_registration_paper_folgende_optionale_felder_als_vorbelegung}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="telefonnummer"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_telefonnummer
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("telefonnummer")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkTel(walletData.telefonnummer),
                    }}
                  />
                  {!fieldIsValid["telefonnummer"] && (
                    <FormHelperText error={!fieldIsValid["telefonnummer"]}>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_telefonnummer_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="erfassender_mitarbeiter"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_erfassender_mitarbeiter
                    )}
                    inputProps={{ maxLength: 100 }}
                    className={classes.textField}
                    value={walletData.erfassender_mitarbeiter}
                    onChange={this.handleChange("erfassender_mitarbeiter")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="alternativer_ansprechpartner_fuer_mitteilungen"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_alternativer_ansprechpartner_fuer_mitteilungen
                    )}
                    inputProps={{ maxLength: 100 }}
                    className={classes.textField}
                    onChange={this.handleChange("alternativer_ansprechpartner_fuer_mitteilungen")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen
                    )}
                    className={classes.textField}
                    onChange={this.handleChange(
                      "telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen"
                    )}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkTel(
                        walletData.telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen
                      ),
                    }}
                  />
                  {!fieldIsValid[
                    "telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen"
                  ] && (
                    <FormHelperText
                      error={
                        !fieldIsValid[
                          "telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen"
                        ]
                      }
                    >
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_telefonnummer_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="bic_allgemein"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_bic_allgemein
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("bic_allgemein")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkBic(walletData.bic_allgemein),
                    }}
                  />
                  {!fieldIsValid["bic_allgemein"] && (
                    <FormHelperText error>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_bic_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    align={"left"}
                    className={classes.warningOptional}
                  >
                    {walletData.second_bank_account
                      ? appIntl().formatMessage(
                          messages.wallet_registration_paper_bankverbindung_fuer_zinsen
                        )
                      : appIntl().formatMessage(
                          messages.wallet_registration_paper_bankverbindung_fuer_zinsen_und_tilgung
                        )}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="iban"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_iban
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("iban")}
                    value={walletData.iban}
                    onBlur={(e) => {
                      this.handleChange("iban")({
                        target: { value: formatIban(e?.target?.value) },
                      });
                    }}
                    inputProps={{
                      isvalid: checkIban(walletData.iban),
                    }}
                  />
                  {!fieldIsValid["iban"] && (
                    <FormHelperText error>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_iban_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="bic"
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_text_field_label_bic
                    )}
                    className={classes.textField}
                    onChange={this.handleChange("bic")}
                    inputProps={{
                      maxLength: 100,
                      isvalid: checkBic(walletData.bic),
                    }}
                  />
                  {!fieldIsValid["bic"] && (
                    <FormHelperText error>
                      <FormattedMessage
                        {...messages.wallet_registration_paper_form_helper_text_fehler_bic_beispiel}
                      />
                    </FormHelperText>
                  )}
                </Grid>

                {walletData.second_bank_account && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        align={"left"}
                        className={classes.warningOptional}
                      >
                        <FormattedMessage
                          {...messages.wallet_registration_paper_bankverbindung_fuer_tilgung}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="iban_tilgung"
                        label={appIntl().formatMessage(
                          messages.wallet_registration_paper_text_field_label_iban_tilgung
                        )}
                        className={classes.textField}
                        onChange={this.handleChange("iban_tilgung")}
                        value={walletData.iban_tilgung}
                        onBlur={(e) => {
                          this.handleChange("iban_tilgung")({
                            target: { value: formatIban(e?.target?.value) },
                          });
                        }}
                        inputProps={{
                          isvalid: checkSecondIban(walletData.iban, walletData.iban_tilgung),
                        }}
                      />
                      {!fieldIsValid["iban_tilgung"] && (
                        <FormHelperText error>
                          {ibansNotMatching(walletData.iban, walletData.iban_tilgung)
                            ? appIntl().formatMessage(
                                messages.wallet_registration_paper_form_helper_text_fehler_iban_tilgung_beispiel
                              )
                            : appIntl().formatMessage(
                                messages.wallet_registration_paper_form_helper_text_muss_sich_von_iban_zinsen_unterscheiden
                              )}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="bic_tilgung"
                        label={appIntl().formatMessage(
                          messages.wallet_registration_paper_text_field_label_bic_tilgung
                        )}
                        className={classes.textField}
                        onChange={this.handleChange("bic_tilgung")}
                        value={walletData.bic_tilgung}
                        inputProps={{
                          maxLength: 100,
                          isvalid: checkBic(walletData.bic_tilgung),
                        }}
                      />
                      {!fieldIsValid["bic_tilgung"] && (
                        <FormHelperText error>
                          <FormattedMessage
                            {...messages.wallet_registration_paper_form_helper_text_fehler_bic_beispiel}
                          />
                        </FormHelperText>
                      )}
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={walletData.second_bank_account}
                        onChange={this.handleCheckboxChange("second_bank_account")}
                        color="primary"
                        value="second_bank_account"
                      />
                    }
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_checkbox_label_zweite_bankverbindung_erfassen
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={walletData.vier_augen_bestaetigung}
                        onChange={this.handleCheckboxChange("vier_augen_bestaetigung")}
                        color="primary"
                        value="vier_augen_bestaetigung"
                      />
                    }
                    label={appIntl().formatMessage(
                      messages.wallet_registration_paper_checkbox_label_vier_augen_bestaetigung_erforderlich
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.buttonsRow}>
                <div className={classes.wrapper}>
                  <BackButton
                    disabled={this.props.saveWalletPending}
                    onClick={this.handleCancelClick}
                  >
                    <FormattedMessage {...messages.wallet_registration_paper_button_text_zurueck} />
                  </BackButton>
                </div>
                <div className={classes.wrapper}>
                  <Button
                    disabled={this.props.saveWalletPending}
                    variant="contained"
                    color="primary"
                    onClick={this.handleConfirmClick}
                  >
                    <FormattedMessage
                      {...messages.wallet_registration_paper_button_text_erfassen}
                    />
                  </Button>
                  {this.props.saveWalletPending && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
              </Grid>
            </form>
          </Paper>
        </main>
        <WalletSuccessModal />
      </React.Fragment>
    );
  }
}

WalletRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    account: state.signInReducer.account,
    saveWalletPending: state.walletRegistrationReducer.saveWalletPending,
    platformMemberEmail: state.signInReducer.platformMemberEmail,
    token: state.signInReducer.token,
    onBoardingUrl: state.signInReducer.onBoardingUrl,
    mitarbeiterName: state.walletRegistrationReducer.mitarbeiterName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveWallet: (walletData, backendUrl) =>
      dispatch(saveWalletActions.request(walletData, backendUrl)),
  };
};

export default withStyles(wallet_styles)(
  connect(mapStateToProps, mapDispatchToProps)(WalletRegistration)
);
