import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { ab2str } from "services/web3Services/commons";
import { FormattedMessage } from "react-intl";
import { defineMessages } from "react-intl";
import { SSD_TYPE } from "util/constants";

export const messages = defineMessages({
  bssd: {
    id: "shared.signin.authenticity.check.button.bssd",
    defaultMessage: "BSSD Echtheitsprüfung",
  },
  cssd: {
    id: "shared.signin.authenticity.check.button.cssd",
    defaultMessage: "CSSD Echtheitsprüfung",
  },
  uploadCertificateButton: {
    id: "shared.signin.upload.certificate.button",
    defaultMessage: "urkunde hochladen",
  },
});

const options = [SSD_TYPE.BSSD, SSD_TYPE.CSSD];

function AuthenticityCheckSplitButton({ setDocument }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedDocumentType, setselectedDocumentType] = React.useState(SSD_TYPE.CSSD);

  const handleMenuItemClick = (event, index, documentType) => {
    setSelectedIndex(index);
    setselectedDocumentType(documentType);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const readFileAsync = (file) => {
    // the FileReader API only supports callbacks.
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(ab2str(reader.result));
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const onPdfUpload = async (event) => {
    try {
      let file = event.target.files[0];
      let contentBuffer = await readFileAsync(file);
      setDocument(contentBuffer, selectedDocumentType);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
        <Button style={{ width: "100%", paddingLeft: "40px" }} component="label">
          <input
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={onPdfUpload}
          />
          {selectedDocumentType === SSD_TYPE.BSSD ? "BSSD " : "CSSD "}
          <FormattedMessage {...messages.uploadCertificateButton} />
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index, option)}
                    >
                      <FormattedMessage {...messages[option]} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default AuthenticityCheckSplitButton;
