import { sendSignedHttpRequest } from "../util/requestSigning";
import { getIdProxyAddr } from "./web3Services/commons";

export async function storeAdressbucheintrag(
  adressbuchRequest,
  id_proxy_arranger = getIdProxyAddr()
) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/adressbuch/${id_proxy_arranger}`,
    id_proxy_arranger,
    adressbuchRequest
  );
}

export async function getAdressbuchEintrag(adressbuch_id, id_proxy_arranger = getIdProxyAddr()) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/adressbuch/${id_proxy_arranger}/${adressbuch_id}`,
    id_proxy_arranger
  );
}

export async function getAdressbuchEintraege(id_proxy_arranger = getIdProxyAddr()) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/adressbuch/${id_proxy_arranger}`,
    id_proxy_arranger
  );
}

export async function deleteAdressbuchEintrag(adressbuch_id, id_proxy_arranger = getIdProxyAddr()) {
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/adressbuch/${id_proxy_arranger}/${adressbuch_id}`,
    id_proxy_arranger
  );
}
