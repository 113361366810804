import {
  OPEN_TERMINATAION_DIALOG,
  CLOSE_TERMINATION_DIALOG,
  OPEN_CONFIRM_TERMINATION_DIALOG,
  CLOSE_CONFIRM_TERMINATION_DIALOG,
  OPEN_SECOND_CONFIRM_TERMINATION_DIALOG,
  CLOSE_SECOND_CONFIRM_TERMINATION_DIALOG,
  FETCH_KUENDIGUNG_SUCCESS,
  CREATE_TERMINATION_REQUEST,
  CREATE_TERMINATION_SUCCESS,
  CREATE_TERMINATION_FAILURE,
  CONFIRM_TERMINATION_REQUEST,
  CONFIRM_TERMINATION_SUCCESS,
  CONFIRM_TERMINATION_FAILURE,
  REJECT_TERMINIATION_REQUEST,
  REJECT_TERMINATION_SUCCESS,
  REJECT_TERMINATION_FAILURE,
} from "./actions";

const initialState = {
  terminateSsdDialogIsOpen: false,
  confirmTerminationDialogIsOpen: false,
  secondConfirmTerminationDialogIsOpen: false,
  ssdToTerminate: {},
  kuendigung: {},
  createTerminationIsPending: false,
  confirmTerminationIsPending: false,
  rejectTerminationIsPending: false,
};

function openTerminationDialogReducer(state, action) {
  return {
    ...state,
    ssdToTerminate: action.ssdInfo,
    terminateSsdDialogIsOpen: true,
  };
}

function closeTerminationDialogReducer(state) {
  return {
    ...state,
    terminateSsdDialogIsOpen: false,
    ssdToTerminate: {},
  };
}

function openSecondConfirmTerminationReducer(state, action) {
  return {
    ...state,
    secondConfirmTerminationDialogIsOpen: true,
    kuendigung: action.kuendigung,
    ssdToTerminate: action.ssdInfo,
  };
}

function closeSecondConfirmTerminationReducer(state) {
  return {
    ...state,
    secondConfirmTerminationDialogIsOpen: false,
    kuendigung: {},
    ssdToTerminate: {},
  };
}

function openConfirmTerminationReducer(state, action) {
  return {
    ...state,
    confirmTerminationDialogIsOpen: true,
    terminateSsdDialogIsOpen: false,
    kuendigung: action.kuendigung,
  };
}

function closeConfirmTerminationReducer(state) {
  return {
    ...state,
    confirmTerminationDialogIsOpen: false,
    terminateSsdDialogIsOpen: true,
    kuendigung: {},
  };
}

function fetchKuendigungSuccessReducer(state, action) {
  return {
    ...state,
    kuendigung: action.kuendigung,
    ssdToTerminate: action.ssdInfo,
  };
}

function createTerminationRequestReducer(state) {
  return {
    ...state,
    createTerminationIsPending: true,
  };
}

function createTerminationFailureReducer(state) {
  return {
    ...state,
    createTerminationIsPending: false,
  };
}

function createTerminationSuccessReducer(state) {
  return {
    ...state,
    createTerminationIsPending: false,
    terminateSsdDialogIsOpen: false,
    confirmTerminationDialogIsOpen: false,
  };
}

function confirmTerminationRequestReducer(state) {
  return {
    ...state,
    confirmTerminationIsPending: true,
  };
}

function confirmTerminationFailureReducer(state) {
  return {
    ...state,
    confirmTerminationIsPending: false,
    secondConfirmTerminationDialogIsOpen: false,
  };
}

function confirmTerminationSuccessReducer(state) {
  return {
    ...state,
    confirmTerminationIsPending: false,
    secondConfirmTerminationDialogIsOpen: false,
  };
}

function rejectTerminationRequestReducer(state) {
  return {
    ...state,
    rejectTerminationIsPending: true,
  };
}

function rejectTerminationFailureReducer(state) {
  return {
    ...state,
    rejectTerminationIsPending: false,
    secondConfirmTerminationDialogIsOpen: false,
  };
}

function rejectTerminationSuccessReducer(state) {
  return {
    ...state,
    rejectTerminationIsPending: false,
    secondConfirmTerminationDialogIsOpen: false,
  };
}

export function terminationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_TERMINATAION_DIALOG:
      return openTerminationDialogReducer(state, action);
    case CLOSE_TERMINATION_DIALOG:
      return closeTerminationDialogReducer(state);
    case OPEN_CONFIRM_TERMINATION_DIALOG:
      return openConfirmTerminationReducer(state, action);
    case CLOSE_CONFIRM_TERMINATION_DIALOG:
      return closeConfirmTerminationReducer(state);
    case OPEN_SECOND_CONFIRM_TERMINATION_DIALOG:
      return openSecondConfirmTerminationReducer(state, action);
    case CLOSE_SECOND_CONFIRM_TERMINATION_DIALOG:
      return closeSecondConfirmTerminationReducer(state);
    case FETCH_KUENDIGUNG_SUCCESS:
      return fetchKuendigungSuccessReducer(state, action);

    case CREATE_TERMINATION_REQUEST:
      return createTerminationRequestReducer(state);
    case CREATE_TERMINATION_FAILURE:
      return createTerminationFailureReducer(state);
    case CREATE_TERMINATION_SUCCESS:
      return createTerminationSuccessReducer(state);

    case CONFIRM_TERMINATION_REQUEST:
      return confirmTerminationRequestReducer(state);
    case CONFIRM_TERMINATION_FAILURE:
      return confirmTerminationFailureReducer(state);
    case CONFIRM_TERMINATION_SUCCESS:
      return confirmTerminationSuccessReducer(state);

    case REJECT_TERMINIATION_REQUEST:
      return rejectTerminationRequestReducer(state);
    case REJECT_TERMINATION_FAILURE:
      return rejectTerminationFailureReducer(state);
    case REJECT_TERMINATION_SUCCESS:
      return rejectTerminationSuccessReducer(state);

    default:
      return state;
  }
}
