import {
  FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST,
  OPEN_CSSD_TEILFORDERUNG_DETAIL,
  CLOSE_CSSD_TEILFORDERUNG_DETAIL,
  FETCH_CSSD_PARTIALCLAIM_SUM_SUCCESS,
  FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE,
  SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE,
  FETCH_PARTIAL_CLAIMS_FOR_CSSD_SUCCESS,
  OPEN_PARTIAL_CLAIMS_FOR_CSSD,
  UPDATE_PARTIAL_CLAIM_FROM_EVENT,
  FETCH_PARTIAL_CLAIMS_FOR_CSSD_FAILURE,
  CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST,
  CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_SUCCESS,
  CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_FAILURE,
  LOAD_TEILFORDERUNGEN_SUCCESS,
  LOAD_TEILFORDERUNGEN_REFRESH_SORT,
  STORE_LAST_BLOCK_NUMBER_PARTIAL_CLAIM,
  LOAD_TEILFORDERUNGEN_INCREASE_TOTAL,
  LOAD_TEILFORDERUNGEN_FAILURE,
  LOAD_TEILFORDERUNGEN_RETRY,
  OPEN_SETTLEMENTINFORMATION,
  CLOSE_SETTLEMENTINFORMAION,
  SAVE_SETTLEMENTINFORMATION,
  SUCCESS_SAVE_SETTLEMENTINFORMATION,
  FAILURE_SAVE_SETTLEMENTINFORMATION,
  UPDATE_PARTIAL_CLAIM_TERMINATIONS,
  CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING,
  CSSD_EXPORT_PARTIAL_CLAIM_PROCESSED,
  OPEN_CSSD_TEILFORDERUNG_EDIT,
  CLOSE_CSSD_TEILFORDERUNG_EDIT,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_SUCCESS,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_FAILURE,
  BULK_ACTION_PARTIAL_CLAIM_FAILURE,
  BULK_ACTION_PARTIAL_CLAIM_SUCCESS,
  BULK_CANCEL_PARTIAL_CLAIM,
  BULK_OFFER_PARTIAL_CLAIM,
  CANCEL_PARTIAL_CLAIM_SUCCESS,
  CANCEL_PARTIAL_CLAIM_FAILURE,
  CANCEL_PARTIAL_CLAIM_REQUEST,
  CONFIRM_PARTIAL_CLAIM_REQUEST,
  CONFIRM_PARTIAL_CLAIM_SUCCESS,
  CONFIRM_PARTIAL_CLAIM_FAILURE,
  LOAD_CSSD_TEILFORDERUNG_DETAIL,
  LOAD_CSSD_TEILFORDERUNG_DETAIL_FAILURE,
  PARTIAL_CLAIM_SET_CSSD,
  LOAD_TEILFORDERUNGEN_SET_PENDING,
  LOAD_TEILFORDERUNGEN_FINISHED,
} from "./actions";
import { toUtf8 } from "services/web3Services/commons";
import { onlyUnique } from "../../util/convertor";
import { updateTerminations } from "util/cssdUtils";
import { SortType } from "util/filters";
import { UPDATE_CSSD_PARTIAL_CLAIM_FOUREYES_EVENT } from "redux/cssdEvents/actions";

const initialState = {
  teilforderungen: { sortable: [], newData: [], idsForUpdate: [] },
  cssdRef: null,
  teilforderungDetail: null,
  migratedTeilforderungDetail: null,
  isLoadingDetails: false,
  lastBlockNumber: 0,
  ersteTeilforderung: null,
  partialClaimsForCssd: [],
  sortCriteria: {
    column: "geschaeftsnummer",
    isDescending: true,
    sortType: SortType.TEXT,
  },
  filters: {},
  sumOfAllPartialClaims: null,
  partialClaimsForCssdRequestPending: false,
  partialClaimsForCssdRequestFailed: false,
  loadErrors: {},
  numTotal: 0,
  editSettlementDialog: null,
  settlementInformationPending: false,
  exporting: false,
  saveAndConfirmPending: false,
  cancePending: false,
  confirmPending: false,
};

function loadTeilforderungenSuccessReducer(state, action) {
  return {
    ...state,
    teilforderungen: {
      ...state.teilforderungen,
      sortable: updatePartialClaimDetails(state.teilforderungen.sortable, action.teilforderungen),
      newData: [
        ...updatePartialClaimDetails(state.teilforderungen.newData, action.teilforderungen),
        ...action.teilforderungen
          .filter(
            (newPartialClaim) =>
              !state.teilforderungen.newData
                .map((storedPartialClaim) => storedPartialClaim.id)
                .includes(newPartialClaim.id)
          )
          .filter(
            (newPartialClaim) =>
              !state.teilforderungen.sortable
                .map((storedPartialClaim) => storedPartialClaim.id)
                .includes(newPartialClaim.id)
          ),
      ],
    },
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? [...state.loadErrors[action.payingAgent], ...action.missingIds].filter(onlyUnique)
          : [...action.missingIds],
    },
    numTotal: state.numTotal - action.unauthorizedIds.length,
    ersteTeilforderung: updateSinglePartialClaim(state.ersteTeilforderung, action.teilforderungen),
    partialClaimsForCssd: updatePartialClaimDetails(
      state.partialClaimsForCssd,
      action.teilforderungen
    ),
  };
}

function loadTeilforderungenFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? [...state.loadErrors[action.payingAgent], ...action.ids].filter(onlyUnique)
          : [...action.ids],
    },
  };
}

function loadTeilforderungenRetryReducer(state, action) {
  return {
    ...state,
    loadErrors: action.arranger ? { ...state.loadErrors, [action.arranger]: [] } : {},
  };
}

function loadTeilforderungenRefreshSort(state) {
  return {
    ...state,
    teilforderungen: {
      ...state.teilforderungen,
      sortable: [...state.teilforderungen.sortable, ...state.teilforderungen.newData],
      newData: [],
    },
  };
}

function storeLastBlockFromChainReducer(state, action) {
  return {
    ...state,
    lastBlockNumber: action.lastBlockFromChain,
  };
}

function loadTeilforderungenIncreaseTotalReducer(state, action) {
  return {
    ...state,
    numTotal: state.numTotal + action.numPartialClaims,
  };
}

function loadTeilforderungenSetPendingReducer(state, action) {
  return {
    ...state,
    teilforderungen: {
      ...state.teilforderungen,
      idsForUpdate: [...state.teilforderungen.idsForUpdate, action.partialClaimId].filter(
        onlyUnique
      ),
    },
  };
}

function loadTeilforderungenFinishedReducer(state, action) {
  return {
    ...state,
    teilforderungen: {
      ...state.teilforderungen,
      idsForUpdate: state.teilforderungen.idsForUpdate.filter((id) => id !== action.partialClaimId),
    },
  };
}

const loadTeilforderungDetailSuccess = (state, action) => ({
  ...state,
  isLoadingDetails: false,
  teilforderungDetail: action.teilforderungDetail,
});

const closeDetailModal = (state) => ({
  ...state,
  teilforderungDetail: null,
});

const openEditMigratedTeilforderungModal = (state, action) => ({
  ...state,
  isLoadingDetails: false,
  migratedTeilforderungDetail: action.teilforderungDetail,
});

const closeEditMigratedTeilforderungModal = (state) => ({
  ...state,
  migratedTeilforderungDetail: null,
});

const openPartialClaimsForCssd = (state, action) => ({
  ...state,
  cssdRef: action.cssdRef,
  ersteTeilforderung: initialState.ersteTeilforderung,
  partialClaimsForCssd: initialState.partialClaimsForCssd,
  sumOfAllPartialClaims: initialState.sumOfAllPartialClaims,
  partialClaimsForCssdRequestFailed: false,
});

function fetchPartialClaimForCssdRequestReducer(state) {
  return {
    ...state,
    partialClaimsForCssdRequestPending: true,
    partialClaimsForCssdRequestFailed: false,
  };
}

function fetchPartialClaimForCssdSuccessReducer(state, action) {
  return {
    ...state,
    ersteTeilforderung: action.ersteTeilforderung,
    partialClaimsForCssd: action.partialClaims,
    partialClaimsForCssdRequestPending: false,
    partialClaimsForCssdRequestFailed: false,
  };
}

const fetchPartialClaimForCssdFailureReducer = (state) => ({
  ...state,
  partialClaimsForCssdRequestPending: false,
  partialClaimsForCssdRequestFailed: true,
});

const sortPartialClaimForCssdTableReducer = (state, action) => {
  const { column } = action;
  const { sortCriteria } = state;
  return {
    ...state,
    sortCriteria: {
      column,
      isDescending: column === sortCriteria.column ? !sortCriteria.isDescending : true,
    },
  };
};

const filterPartialClaimForCssdTableReducer = (state, action) => ({
  ...state,
  filters: action.filters,
});

const sumOfAllPartialClaimsSuccess = (state, action) => ({
  ...state,
  sumOfAllPartialClaims: action.summe,
});

const updatePartialClaimState = (partialClaimArr, update) => {
  return partialClaimArr.map((entry) => {
    if (entry.id === Number(toUtf8(update.partialClaimId)) && update.state)
      return {
        ...entry,
        status: Number(update.state),
      };
    return entry;
  });
};

const updatePartialClaimFourEyeState = (partialClaimArr, update) => {
  return partialClaimArr.map((entry) => {
    if (entry.id === Number(update.subid) && update.firstConfirmer)
      return {
        ...entry,
        firstConfirmerTeilforderung: update.firstConfirmer,
      };
    return entry;
  });
};

const updateSinglePartialClaim = (entry, newPartialClaims) => {
  const update = newPartialClaims.find((claim) => claim.id === entry?.id);
  if (update) {
    return {
      ...entry,
      ...update,
    };
  }
  return entry;
};

const updatePartialClaimDetails = (partialClaimArr, newPartialClaims) => {
  return partialClaimArr.map((entry) => updateSinglePartialClaim(entry, newPartialClaims));
};

const updatePartialClaimFromEvent = (state, action) => {
  const { teilforderungen, partialClaimsForCssd } = state;
  const { update } = action;
  return {
    ...state,
    teilforderungen: {
      ...teilforderungen,
      sortable: updatePartialClaimState(teilforderungen.sortable, update),
      newData: updatePartialClaimState(teilforderungen.newData, update),
    },
    partialClaimsForCssd: updatePartialClaimState(partialClaimsForCssd, update),
  };
};
const cssdPartialClaimRepaymentReceivedRequestReducer = (state) => ({
  ...state,
  partialClaimsForCssdRequestPending: true,
});

const cssdPartialClaimRepaymentReceivedSuccessReducer = (state) => ({
  ...state,
  partialClaimsForCssdRequestPending: false,
});

const cssdPartialClaimRepaymentReceivedFailureReducer = (state) => ({
  ...state,
  partialClaimsForCssdRequestPending: false,
});

const editSettlementDialog = (state, action) => ({
  ...state,
  editSettlementDialog: action.partialClaim,
});

const closeEditSettlementDialog = (state) => ({
  ...state,
  editSettlementDialog: null,
});

const setSettlementInformationPending = (state) => ({
  ...state,
  settlementInformationPending: true,
});

const resetSettlementInformationPending = (state) => ({
  ...state,
  settlementInformationPending: false,
});

const updatePartialClaimTerminations = (state, action) => {
  return {
    ...state,
    teilforderungen: {
      ...state.teilforderungen,
      sortable: updateTerminations(
        state.teilforderungen.sortable,
        action.partialClaimId,
        "id",
        action.terminations
      ),
      newData: updateTerminations(
        state.teilforderungen.newData,
        action.partialClaimId,
        "id",
        action.terminations
      ),
    },
  };
};

const setExporting = (state) => ({
  ...state,
  exporting: true,
});

const resetExporting = (state) => ({
  ...state,
  exporting: false,
});

const saveAndConfirmPartilClaimRequestReducer = (state) => ({
  ...state,
  saveAndConfirmPending: true,
});
const saveAndConfirmPartilClaimSuccessReducer = (state) => ({
  ...state,
  saveAndConfirmPending: false,
});
const saveAndConfirmPartilClaimFailureReducer = (state) => ({
  ...state,
  saveAndConfirmPending: false,
});

const cancelPartilClaimRequestReducer = (state) => ({
  ...state,
  cancelPending: true,
});
const cancelPartilClaimSuccessReducer = (state) => ({
  ...state,
  cancelPending: false,
});
const cancelPartilClaimFailureReducer = (state) => ({
  ...state,
  cancelPending: false,
});

const confirmPartilClaimRequestReducer = (state) => ({
  ...state,
  confirmPending: true,
});
const confirmPartilClaimSuccessReducer = (state) => ({
  ...state,
  confirmPending: false,
});
const confirmPartilClaimFailureReducer = (state) => ({
  ...state,
  confirmPending: false,
});

const bulkActionPending = (state, pending) => ({
  ...state,
  bulkActionPending: pending,
});

function updateCssdPartialClaimFoureyesEventReducer(state, action) {
  const { teilforderungen, partialClaimsForCssd } = state;
  return {
    ...state,
    teilforderungen: {
      ...teilforderungen,
      sortable: updatePartialClaimFourEyeState(teilforderungen.sortable, action),
      newData: updatePartialClaimFourEyeState(teilforderungen.newData, action),
    },
    partialClaimsForCssd: updatePartialClaimFourEyeState(partialClaimsForCssd, action),
  };
}

function loadingDetails(state, action) {
  return {
    ...state,
    isLoadingDetails: true,
  };
}

function setCssd(state, action) {
  return {
    ...state,
    cssdRef: action.cssd,
  };
}

export function cssdTeilforderungenReducer(state = initialState, action) {
  switch (action.type) {
    case PARTIAL_CLAIM_SET_CSSD:
      return setCssd(state, action);
    case LOAD_TEILFORDERUNGEN_INCREASE_TOTAL:
      return loadTeilforderungenIncreaseTotalReducer(state, action);
    case LOAD_TEILFORDERUNGEN_SUCCESS:
      return loadTeilforderungenSuccessReducer(state, action);
    case LOAD_TEILFORDERUNGEN_FAILURE:
      return loadTeilforderungenFailureReducer(state, action);
    case LOAD_TEILFORDERUNGEN_RETRY:
      return loadTeilforderungenRetryReducer(state, action);
    case LOAD_TEILFORDERUNGEN_REFRESH_SORT:
      return loadTeilforderungenRefreshSort(state);
    case STORE_LAST_BLOCK_NUMBER_PARTIAL_CLAIM:
      return storeLastBlockFromChainReducer(state, action);
    case LOAD_TEILFORDERUNGEN_SET_PENDING:
      return loadTeilforderungenSetPendingReducer(state, action);
    case LOAD_TEILFORDERUNGEN_FINISHED:
      return loadTeilforderungenFinishedReducer(state, action);
    case OPEN_PARTIAL_CLAIMS_FOR_CSSD:
      return openPartialClaimsForCssd(state, action);
    case FETCH_PARTIAL_CLAIMS_FOR_CSSD_SUCCESS:
      return fetchPartialClaimForCssdSuccessReducer(state, action);
    case FETCH_PARTIAL_CLAIMS_FOR_CSSD_FAILURE:
      return fetchPartialClaimForCssdFailureReducer(state);
    case FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST:
      return fetchPartialClaimForCssdRequestReducer(state);
    case OPEN_CSSD_TEILFORDERUNG_DETAIL:
      return loadTeilforderungDetailSuccess(state, action);
    case CLOSE_CSSD_TEILFORDERUNG_DETAIL:
      return closeDetailModal(state);
    case FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE:
      return filterPartialClaimForCssdTableReducer(state, action);
    case SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE:
      return sortPartialClaimForCssdTableReducer(state, action);
    case FETCH_CSSD_PARTIALCLAIM_SUM_SUCCESS:
      return sumOfAllPartialClaimsSuccess(state, action);
    case UPDATE_PARTIAL_CLAIM_FROM_EVENT:
      return updatePartialClaimFromEvent(state, action);
    case CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST:
      return cssdPartialClaimRepaymentReceivedRequestReducer(state);
    case CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_SUCCESS:
      return cssdPartialClaimRepaymentReceivedSuccessReducer(state);
    case CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_FAILURE:
      return cssdPartialClaimRepaymentReceivedFailureReducer(state);

    case OPEN_SETTLEMENTINFORMATION:
      return editSettlementDialog(state, action);
    case CLOSE_SETTLEMENTINFORMAION:
      return closeEditSettlementDialog(state, action);
    case SAVE_SETTLEMENTINFORMATION:
      return setSettlementInformationPending(state, action);
    case SUCCESS_SAVE_SETTLEMENTINFORMATION:
    case FAILURE_SAVE_SETTLEMENTINFORMATION:
      return resetSettlementInformationPending(state, action);

    case UPDATE_PARTIAL_CLAIM_TERMINATIONS:
      return updatePartialClaimTerminations(state, action);

    case CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING:
      return setExporting(state, action);
    case CSSD_EXPORT_PARTIAL_CLAIM_PROCESSED:
      return resetExporting(state, action);

    case LOAD_CSSD_TEILFORDERUNG_DETAIL:
      return loadingDetails(state, action);
    case LOAD_CSSD_TEILFORDERUNG_DETAIL_FAILURE:
    case OPEN_CSSD_TEILFORDERUNG_EDIT:
      return openEditMigratedTeilforderungModal(state, action);
    case CLOSE_CSSD_TEILFORDERUNG_EDIT:
      return closeEditMigratedTeilforderungModal(state);

    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST:
      return saveAndConfirmPartilClaimRequestReducer(state);
    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_SUCCESS:
      return saveAndConfirmPartilClaimSuccessReducer(state);
    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_FAILURE:
      return saveAndConfirmPartilClaimFailureReducer(state);

    case CANCEL_PARTIAL_CLAIM_REQUEST:
      return cancelPartilClaimRequestReducer(state);
    case CANCEL_PARTIAL_CLAIM_SUCCESS:
      return cancelPartilClaimSuccessReducer(state);
    case CANCEL_PARTIAL_CLAIM_FAILURE:
      return cancelPartilClaimFailureReducer(state);

    case CONFIRM_PARTIAL_CLAIM_REQUEST:
      return confirmPartilClaimRequestReducer(state);
    case CONFIRM_PARTIAL_CLAIM_SUCCESS:
      return confirmPartilClaimSuccessReducer(state);
    case CONFIRM_PARTIAL_CLAIM_FAILURE:
      return confirmPartilClaimFailureReducer(state);

    case BULK_OFFER_PARTIAL_CLAIM:
    case BULK_CANCEL_PARTIAL_CLAIM:
      return bulkActionPending(state, true);
    case BULK_ACTION_PARTIAL_CLAIM_SUCCESS:
    case BULK_ACTION_PARTIAL_CLAIM_FAILURE:
      return bulkActionPending(state, false);

    case UPDATE_CSSD_PARTIAL_CLAIM_FOUREYES_EVENT:
      return updateCssdPartialClaimFoureyesEventReducer(state, action);

    default:
      return state;
  }
}
