import { takeLatest, takeEvery, put, call, all, fork, spawn, select } from "redux-saga/effects";
import {
  OPEN_OVERVIEW_CSSD_CESSION,
  cessionActions,
  FETCH_CSSD_CESSION_REQUEST,
  fetchCessionActions,
  FETCH_CSSD_CESSIONREQUEST_REQUEST,
  fetchCessionRequestActions,
  CREATE_CSSD_CESSION_REQUEST,
  createCessionActions,
  CONFIRM_CSSD_CESSION_REQUEST,
  ACCEPT_CSSD_CESSION_REQUEST,
  confirmCessionActions,
  UPDATE_CSSD_CESSION_REQUEST,
  updateCessionActions,
  UPDATE_NEW_DG_DETAILS_REQUEST,
  updateNewDgDetailsAction,
  BULK_CSSD_CESSION_REQUEST,
  bulkActions,
  cessionModals,
  acceptCssdCessionActions,
  SAVE_AND_CONFIRM_CESSION_REQUEST,
  saveAndConfirmCessionActions,
  CSSD_PAYMENT_CONFIRMED_REQUEST,
  cssdPaymentConfirmedActions,
  UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST,
  updateAndAcceptNewDgDetailsAction,
  cessionDialogs,
  DOWNLOAD_CSSD_CESSION_CONFIRMATION_REQUEST,
  cessionConfirmation,
  FETCH_CSSD_CESSION_SUM_REQUEST,
  fetchSumOfAllCessionsActions,
  CANCEL_CSSD_CESSION_REQUEST,
  cancelCessionActions,
  REJECT_CSSD_CESSION_REQUEST,
  rejectCessionActions,
  OFFER_CSSD_CESSION_CERTIFICATE_REQUEST,
  offerCertificateActions,
  ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST,
  NOTIFY_CSSD_CESSION_REQUEST,
  notifyCssdCessionActions,
  DOWNLOAD_CESSION_CERT,
  ACCEPT_EXT_CESSION_REQUEST,
  acceptExtCessionActions,
  REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST,
  requestChangesForExternalCessionActions,
  UPLOAD_CESSION_CERT_REQUEST,
  uploadCessionCertActions,
  BULK_CSSD_CESSION_NOTIFY_REQUEST,
  bulkNotifyCessionActions,
  acceptCertificateActions,
  OPEN_REBUY_CSSD,
  DOWNLOAD_CSSD_CESSION_NOTIFICATION_REQUEST,
  PAYMENT_RECEIVED_FOR_REBUY_REQUEST,
  paymentReceivedForRebuyActions,
  REDOCUMENT_CESSION_REQUEST,
  redocumentCessionActions,
  CONFIRM_REDOCUMENT_CESSION_REQUEST,
  TRY_OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG,
  redocumentedRebuyActions,
  fetchCssdCessionsForWorkActions,
  FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST,
  openCessionOverviewFromHomeActions,
  OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST,
  filterCessionTable,
  FETCH_CSSD_CESSIONS_FOR_WORK_RETRY,
  overviewCssdCession,
  updateCssdCessionsForWorkActions,
} from "./actions";
import history from "util/history";
import {
  postCession as postCessionToBackend,
  fetchCession,
  fetchSumOfAllCessions,
  uploadCessionCert,
  createCessionNotificationBulk,
  createCessionNotification,
  fetchCessionCert,
} from "services/cssdCessionService";
import { snackbarActions } from "redux/shared/actions";
import { appIntl } from "components/i18n/intl";
import {
  createCession as createCessionOnBlockchain,
  offerCession as offerCessionOnBlockchain,
  acceptCession as acceptCessionOnBlockchain,
  bulkActionCession as bulkActionCessionOnBlockchain,
  bulkNotifyCession as bulkNotifyCessionOnBlockchain,
  updateCession as updateCessionOnBlockchain,
  confirmPayment as confirmPaymentOnBlockchain,
  updateAndAcceptCession as updateAndAcceptCessionOnBlockchain,
  modifyAndOfferCession as modifyAndOfferCessionOnBlockchain,
  offerCertificate as offerCertificateOnBlockchain,
  acceptCert as acceptCertOnBlockchain,
  acceptCessionExtern as acceptCessionExternOnBlockchain,
  bulkActionReceivePayment as bulkReceivePaymentOnBlockchain,
  modifyAndOfferReDocumentedCession as modifyAndOfferReDocumentedCessionOnBlockchain,
  offerReDocumentedCession as offerReDocumentedCessionOnBlockchain,
  newPartialClaimForReDocumentedCession as newPartialClaimForReDocumentedCessionOnBlockchain,
} from "services/web3Services/cssdCession";
import { messages } from "components/CssdCession/messages";
import {
  generateCSSDCessionFingerprints,
  generateCssdSettlementInformationFingerprint,
  generateCssdRejectionFingerprint,
} from "util/fingerprint";
import {
  queryCSSD,
  queryCSSDNewDg,
  getRestAmountFromPartialClaimForRebuy,
  queryTeilforderungenById,
} from "services/cssdService";
import {
  createCessionConfirmationPDF as createCessionConfirmationPDFOnBackend,
  updateNewDgInfoForCession as updateNewDgInfosOnBackend,
  fetchCessions as fetchCessionsFromBackend,
  downloadCessionCert as downloadCessionCertFromBackend,
  createCertificateOnPaymentReceived,
  fetchConfirmedRestOfAllCessions,
  createCertificateOnPaymentReceivedBulk,
  createPartialClaimFromCession,
  fetchCessionRequests as fetchCessionRequestsFromBackend,
  downloadCessionConfirmation as downloadCessionConfirmationFromBackend,
  ablehnen as rejectCessionOnBackend,
  getCessionNotification,
} from "services/cssdCessionService";

import {
  getCssdCessionEvent as getCssdCessionEventFromBlockchain,
  getAllCssdCessionEvents as getAllCssdCessionEventFromBlockchain,
  toHex,
  toUtf8,
  getIdProxyAddr,
} from "services/web3Services/commons";
import {
  UPDATE_CSSD_CESSION_EVENT,
  UPDATE_CSSD_CESSION_AND_REQUEST_EVENT,
  UPDATE_CSSD_CESSION_FOR_WORK_EVENT,
} from "../cssdEvents/actions";
import { formatNumberWithEmptyData } from "util/convertor";
import {
  zeroAddress,
  mailTemplates,
  CSSDCessionBulkAction,
  GeschaeftsvorfallTyp,
  ZERO_HASH,
} from "util/constants";
import {
  sendCssdMail,
  sendCssdCessionMail,
  sendBulkCssdCessionMail,
  sendBulkMailForNewGp,
} from "services/mailService";
import { contractCache } from "services/smartContracts/smartContracts";
import {
  cancelCession as cancelCessionOnBlockchain,
  rejectCession as rejectCessionOnBlockchain,
  notify as notifyCessionOnBlockchain,
} from "services/web3Services/cssdCession";
import {
  getTerminationsForPartialClaim as getTerminationsForPartialClaimOnBlockchain,
  repaymentReceivedPartialClaimZS as repaymentReceivedPartialClaimZSOnBlockchain,
  sendNewPartialClaim as sendNewPartialClaimOnBlockchain,
} from "services/web3Services/cssd";
import { getGpFromPartners } from "../../services/partnerService";
import { connectGp, connectGpsInBulk } from "../../services/businessPartnerService";
import { acceptCessionCertificateExtern } from "../../services/web3Services/cssdCession";
import CssdCessionStatus from "components/Shared/CssdCession/CssdCessionStatus";
import { storeAdressbucheintrag as storeAddressBookEntry } from "services/addressBookService";
import { toSha3 } from "services/web3Services/commons";
import { _base64ToArrayBuffer } from "util/convertor";
import { ab2str } from "services/web3Services/commons";
import { isZahlstelleSelector, getAccount, isCessionCached } from "redux/selectors";
import { CSSDCessionState } from "util/constants";
import { fetchCessionsForDashboard } from "services/cssdCessionService";
import { saveLogs } from "util/savefile";

export const cssdCessionSagas = [
  takeLatest(OPEN_OVERVIEW_CSSD_CESSION, openOverviewCession),
  takeLatest(OPEN_REBUY_CSSD, rebuyCession),
  takeLatest(CREATE_CSSD_CESSION_REQUEST, saveCession),
  takeLatest(FETCH_CSSD_CESSION_REQUEST, fetchCessions),
  takeLatest(FETCH_CSSD_CESSIONREQUEST_REQUEST, fetchCessionRequests),
  takeLatest(FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST, fetchAllCessions),
  takeLatest(UPDATE_CSSD_CESSION_FOR_WORK_EVENT, fetchUpdatedCessionForWork),
  takeLatest(FETCH_CSSD_CESSIONS_FOR_WORK_RETRY, retryFetchAllCessions),
  takeLatest(OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST, navigateToCessionOverview),
  takeLatest(CONFIRM_CSSD_CESSION_REQUEST, confirmCession),
  takeLatest(UPDATE_CSSD_CESSION_REQUEST, updateCession),
  takeLatest(ACCEPT_CSSD_CESSION_REQUEST, acceptCssdCession),
  takeEvery(UPDATE_CSSD_CESSION_EVENT, fetchCSSDCessionForUpdate),
  takeEvery(UPDATE_CSSD_CESSION_AND_REQUEST_EVENT, updateCessionsAndRequests),
  takeLatest(BULK_CSSD_CESSION_REQUEST, bulkActionSaga),
  takeLatest(FETCH_CSSD_CESSION_SUM_REQUEST, fetchSumOfAllCessionsSaga),
  takeLatest(SAVE_AND_CONFIRM_CESSION_REQUEST, saveAndConfirmCession),
  takeLatest(UPDATE_NEW_DG_DETAILS_REQUEST, updateNewDgDetails),
  takeLatest(CSSD_PAYMENT_CONFIRMED_REQUEST, confirmPayment),
  takeLatest(UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST, updateAndAcceptNewDgDetails),
  takeLatest(DOWNLOAD_CSSD_CESSION_CONFIRMATION_REQUEST, downloadConfirmation),
  takeLatest(CANCEL_CSSD_CESSION_REQUEST, cancelCession),
  takeLatest(REJECT_CSSD_CESSION_REQUEST, rejectCession),
  takeLatest(OFFER_CSSD_CESSION_CERTIFICATE_REQUEST, confirmCertificate),
  takeLatest(ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST, acceptCertificate),
  takeLatest(NOTIFY_CSSD_CESSION_REQUEST, notifyCession),
  takeLatest(DOWNLOAD_CESSION_CERT, downloadCessionCertSaga),
  takeLatest(ACCEPT_EXT_CESSION_REQUEST, acceptExtCessionSaga),
  takeLatest(REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST, requestChangesForExternalCession),
  takeLatest(UPLOAD_CESSION_CERT_REQUEST, acceptExtCessionCertificateSaga),
  takeLatest(BULK_CSSD_CESSION_NOTIFY_REQUEST, bulkNotifyCession),
  takeLatest(DOWNLOAD_CSSD_CESSION_NOTIFICATION_REQUEST, downloadCessionNotificationSaga),
  takeLatest(PAYMENT_RECEIVED_FOR_REBUY_REQUEST, confirmPaymentReceivedForRebuy),
  takeLatest(REDOCUMENT_CESSION_REQUEST, redocumentCessionSagas),
  takeLatest(CONFIRM_REDOCUMENT_CESSION_REQUEST, confirmRedocumentCessionSagas),
  takeLatest(TRY_OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG, rebuyCession),
];

export function* updateExternalPartialClaimDG(partialClaim, newGp, throwEvents = true) {
  const account = yield select(getAccount);
  const {
    entstandenAusAbtretungId,
    idProxyAdresseZahlstelle,
    entstandenAusAbtretungFingerprint,
  } = partialClaim;
  const { data } = yield call(
    fetchCession,
    idProxyAdresseZahlstelle,
    entstandenAusAbtretungId,
    entstandenAusAbtretungFingerprint
  );
  const cession = data.abtretungResponses[0];
  const updateCession = {
    ...cession,
    neuerDarlehensgeber: {
      ...cession.neuerDarlehensgeber,
      idProxyAdresse: newGp,
    },
  };
  const settlementInformation = yield generateCssdSettlementInformationFingerprint(
    buildPostCssdCessionPayload(updateCession).neuerDarlehensgeber
  );

  yield updateNewDgInfosOnBackend(
    settlementInformation,
    cession.idProxyAdresseZahlstelle,
    cession.abtretungId
  );

  yield call(
    updateCessionOnBlockchain,
    account,
    idProxyAdresseZahlstelle ? idProxyAdresseZahlstelle : cession.zahlstelle.idProxyAdresse,
    toHex(`${cession.cssdId}`),
    toHex(`${cession.entstandenAusTeilforderungId}`),
    toHex(`${cession.abtretungId}`),
    settlementInformation.fingerprint,
    throwEvents
  );
}

export function* createExternalPartialClaimSaga(account, cessionRequest, cssd) {
  try {
    const { data } = yield call(
      createPartialClaimFromCession,
      cessionRequest.idProxyAdresseZahlstelle,
      cessionRequest.cssdId,
      cessionRequest.abtretungId
    );

    yield call(
      newPartialClaimForReDocumentedCessionOnBlockchain,
      account,
      cessionRequest.idProxyAdresseZahlstelle,
      toHex(`${cssd.cssdId}`),
      toHex(`${cessionRequest.entstandenAusTeilforderungId}`),
      toHex(`${data.id}`),
      cessionRequest.fingerprint,
      toHex(`${cessionRequest.abtretungId}`),
      cessionRequest.fingerprint
    );
  } catch (error) {
    console.error(error);
  }
}

function* confirmRedocumentCessionSagas({ account, cssd, partialClaim, cession }) {
  try {
    // a redocumented rebuy is never 'repaid' on confirming
    let isRepaid = !cession.isRueckkauf;

    if (isRepaid) {
      const { data } = yield call(
        fetchConfirmedRestOfAllCessions,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.id
      );
      isRepaid = data.summe - cession.nominal <= 0;
    }

    yield call(
      offerReDocumentedCessionOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint,
      isRepaid
    );

    yield call(createExternalPartialClaimSaga, account, cession, cssd);

    yield put(confirmCessionActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_offer_success)));
    navigateToOverview();
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_offer_failure)));
    yield put(confirmCessionActions.failure(error));
  }
}

function* redocumentCessionSagas({ account, cession, partialClaim }) {
  try {
    const saveInAddressBook = cession.neuerDarlehensgeber.saveInAddressBook;
    const cessionWithFp = yield generateCSSDCessionFingerprints(
      buildPostCssdCessionPayload({ ...cession, nachdokumentiert: true })
    );

    if (saveInAddressBook) {
      yield call(storeAddressBookEntry, cessionWithFp.neuerDarlehensgeber);
    }

    const { data } = yield postCessionToBackend(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id,
      cessionWithFp
    );

    yield call(
      modifyAndOfferReDocumentedCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.idProxyAdresseDarlehensgeber,
      cessionWithFp.neuerDarlehensgeber.idProxyAdresse,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${data.abtretungId}`),
      cessionWithFp.fingerprint,
      cessionWithFp.neuerDarlehensgeber.fingerprint,
      cessionWithFp.valuta,
      cessionWithFp.extern
    );

    yield put(redocumentCessionActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_save_success)));
    if (cession.isRueckkauf) {
      yield put(redocumentedRebuyActions.closeDialog());
      yield put(overviewCssdCession.openOverview(partialClaim));
    } else {
      navigateToOverview();
    }
  } catch (error) {
    console.error(error);
    yield put(redocumentCessionActions.failure());
    if (error.response?.status === 400) {
      yield put(snackbarActions.openError(error.response?.data?.constraintViolations[0].message));
    } else {
      yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_save_failure)));
      if (cession.isRueckkauf) {
        yield put(redocumentedRebuyActions.closeDialog());
      } else {
        navigateToOverview();
      }
    }
  }
}

const navigateToOverview = (subpath = "/cessionoverview", fromPath = null) => {
  const path = fromPath ? fromPath : history.location.pathname;
  if (path.includes(subpath)) {
    const pos = path.indexOf(subpath);
    history.push(path.substring(0, pos + subpath.length));
  } else {
    history.push(path + subpath);
  }
};

export function* rejectCession({ account, cession, reason }) {
  try {
    const toPost = {
      id: cession.abtretungId,
      typ: GeschaeftsvorfallTyp.ABTRETUNG,
      geschaeftsvorfallFingerprint: cession.fingerprint,
      geschaeftsvorfallFingerprintTimestamp: cession.fingerprintTimestamp,
      geschaeftsvorfallFingerprintVersion: cession.fingerprintVersion,
      ablehnungsgrund: reason,
    };

    const reasonWithFP = yield generateCssdRejectionFingerprint(toPost);

    const reasonHash = reasonWithFP.ablehnungsgrundFingerprint;

    yield call(
      rejectCessionOnBackend,
      cession.zahlstelle.idProxyAdresse,
      cession.abtretungId,
      cession.fingerprint,
      reasonWithFP
    );

    yield call(
      rejectCessionOnBlockchain,
      account,
      cession.zahlstelle.idProxyAdresse,
      toHex(`${cession.cssdId}`),
      toHex(`${cession.entstandenAusTeilforderungId}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint,
      reasonHash
    );

    yield call(sendCssdCessionMail, {
      template: mailTemplates.cssdabtretung_ablehnung,
      arranger: cession.zahlstelle.idProxyAdresse,
      cessionId: cession.abtretungId,
      cessionHash: cession.fingerprint,
      begruendung: reason,
    });

    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_reject_success))
    );
    yield put(rejectCessionActions.success(cession));
    yield put(cessionDialogs.closeConfirmCancelDialog());
    yield put(cessionModals.close());
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_reject_failure)));
    yield put(rejectCessionActions.failure(error));
    yield put(cessionModals.close());
  }
}

export function* cancelCession({ account, partialClaim, cession }) {
  try {
    yield call(
      cancelCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint
    );
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.status_cancelled)));
    yield put(cancelCessionActions.success());
    yield put(cessionModals.close());
    yield put(cessionDialogs.closeConfirmCancelDialog());
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_cancel_failure)));
    yield put(cancelCessionActions.failure(error));
  }
}

export function* fetchCssd(partialClaim) {
  const isZS = yield select(isZahlstelleSelector);
  let response;
  if (isZS || !partialClaim.entstandenAusAbtretungId) {
    response = yield queryCSSD(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.cssdId,
      partialClaim.cssdFingerprint
    );
  } else {
    response = yield queryCSSDNewDg(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.cssdId,
      partialClaim.cssdFingerprint,
      partialClaim.entstandenAusAbtretungId
    );
  }
  return response.data;
}

export function* openOverviewCession({ partialClaim, cssd, fromPath }) {
  try {
    if (!cssd) {
      cssd = yield fetchCssd(partialClaim);
    }
    yield put(filterCessionTable({}));
    yield put(cessionActions.openOverview(cssd));
    yield put(cessionActions.setPartialClaim(partialClaim));
    navigateToOverview(undefined, fromPath);
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cession_open_view_failure))
    );
  }
}

export function* rebuyCession({ partialClaim, extern }) {
  try {
    const { data } = yield queryCSSD(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.cssdId,
      partialClaim.cssdFingerprint
    );

    if (extern) {
      yield call(fetchSumOfAllCessionsSaga, { partialClaim });
      yield put(redocumentedRebuyActions.openDialog({ partialClaim, cssd: data }));
    } else {
      yield put(cessionActions.openOverview(data));
      yield put(cessionActions.setPartialClaim(partialClaim));
      yield call(fetchSumOfAllCessionsSaga, { partialClaim });
      navigateToOverview("/cessionoverview/rebuy");
    }
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cession_open_view_failure))
    );
  }
}

function buildPostCssdCessionPayload(cession) {
  let payload = {
    ...cession,
    nominal: formatNumberWithEmptyData(cession?.nominal),
    kurs: formatNumberWithEmptyData(cession?.kurs),
    extern: cession.neuerDarlehensgeber.extern || cession.extern,
  };
  delete payload.neuerDarlehensgeber.extern;
  delete payload.saveInAddressBook;
  return payload;
}

export function* saveCession({ account, cessionData, partialClaim }) {
  try {
    const saveInAddressBook = cessionData.neuerDarlehensgeber.saveInAddressBook;
    const cessionPayload = buildPostCssdCessionPayload(cessionData);
    const cession = yield generateCSSDCessionFingerprints(cessionPayload);

    if (saveInAddressBook) {
      yield call(storeAddressBookEntry, cessionPayload.neuerDarlehensgeber);
    }

    const { data } = yield postCessionToBackend(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id,
      cession
    );

    yield call(
      createCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.idProxyAdresseDarlehensgeber,
      cession.neuerDarlehensgeber.idProxyAdresse,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${data.abtretungId}`),
      cession.fingerprint,
      cession.neuerDarlehensgeber.fingerprint,
      cession.valuta,
      cession.extern
    );

    yield put(createCessionActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_save_success)));
    navigateToOverview();
  } catch (error) {
    console.error(error);
    yield put(createCessionActions.failure());
    if (error.response.status === 400) {
      yield put(snackbarActions.openError(error.response?.data?.constraintViolations[0].message));
    } else {
      yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_save_failure)));
      navigateToOverview();
    }
  }
}

export function* updateNewDgDetails({ account, cession }) {
  try {
    const settlementInformation = yield generateCssdSettlementInformationFingerprint(
      buildPostCssdCessionPayload(cession).neuerDarlehensgeber //TODO use cession.neuerDg directly when language is implemented
    );

    yield updateNewDgInfosOnBackend(
      settlementInformation,
      cession.zahlstelle.idProxyAdresse,
      cession.abtretungId
    );

    yield call(
      updateCessionOnBlockchain,
      account,
      cession.zahlstelle.idProxyAdresse,
      toHex(`${cession.cssdId}`),
      toHex(`${cession.entstandenAusTeilforderungId}`),
      toHex(`${cession.abtretungId}`),
      settlementInformation.fingerprint
    );

    yield call(sendCssdCessionMail, {
      template: mailTemplates.cssdabtretung_abwicklungsinformationen_geaendert,
      arranger: cession.zahlstelle.idProxyAdresse,
      cessionId: cession.abtretungId,
      cessionHash: cession.fingerprint,
    });

    yield put(updateNewDgDetailsAction.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_update_success))
    );
    yield put(cessionModals.close());
  } catch (error) {
    console.error(error);
    yield put(updateNewDgDetailsAction.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_update_failure)));
    yield put(cessionModals.close());
  }
}

export function* updateAndAcceptNewDgDetails({ account, cession }) {
  try {
    const payload = yield generateCssdSettlementInformationFingerprint(
      buildPostCssdCessionPayload(cession).neuerDarlehensgeber
    );

    yield updateNewDgInfosOnBackend(
      payload,
      cession.zahlstelle.idProxyAdresse,
      cession.abtretungId
    );

    yield call(
      updateAndAcceptCessionOnBlockchain,
      account,
      cession.zahlstelle.idProxyAdresse,
      toHex(`${cession.cssdId}`),
      toHex(`${cession.entstandenAusTeilforderungId}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint,
      payload.fingerprint
    );

    yield call(sendCssdCessionMail, {
      template: mailTemplates.cssdabtretung_abwicklungsinformationen_geaendert,
      arranger: cession.zahlstelle.idProxyAdresse,
      cessionId: cession.abtretungId,
      cessionHash: cession.fingerprint,
    });

    yield put(updateAndAcceptNewDgDetailsAction.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_update_success))
    );
    yield put(cessionModals.close());
  } catch (error) {
    console.error(error);
    yield put(updateAndAcceptNewDgDetailsAction.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_update_failure)));
    yield put(cessionModals.close());
  }
}

export function* updateCession({ account, cessionData, teilforderung }) {
  try {
    const cession = yield generateCSSDCessionFingerprints(buildPostCssdCessionPayload(cessionData));

    const { data } = yield postCessionToBackend(
      teilforderung.idProxyAdresseZahlstelle,
      teilforderung.id,
      cession
    );

    yield call(
      createCessionOnBlockchain,
      account,
      teilforderung.idProxyAdresseZahlstelle,
      teilforderung.idProxyAdresseDarlehensgeber,
      cession.neuerDarlehensgeber.idProxyAdresse,
      toHex(`${teilforderung.cssdId}`),
      toHex(`${teilforderung.id}`),
      toHex(`${data.abtretungId}`),
      cession.fingerprint,
      cession.neuerDarlehensgeber.fingerprint,
      cession.valuta,
      cession.extern
    );

    yield put(updateCessionActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_update_success))
    );
    yield put(cessionModals.close());
  } catch (error) {
    console.error(error);
    yield put(updateCessionActions.failure());
    if (error.response.status === 400) {
      yield put(snackbarActions.openError(error.response?.data?.constraintViolations[0].message));
    } else {
      yield put(
        snackbarActions.openError(appIntl().formatMessage(messages.cession_update_failure))
      );
    }
  }
}

export function* fetchCessions({ partialClaim, filters, sortCriteria, pagingData }) {
  try {
    const { data } = yield call(
      fetchCessionsFromBackend,
      partialClaim.id,
      partialClaim.idProxyAdresseZahlstelle,
      { ...filters },
      sortCriteria,
      pagingData
    );
    if (filters.status) {
      data.abtretungResponses = data.abtretungResponses.filter((cession) => {
        const { statusTextKey } = CssdCessionStatus(cession);
        if (filters.status === "status_imported" && cession.imported) {
          return true;
        }
        return "status_" + statusTextKey === filters.status;
      });
    }

    yield put(fetchCessionActions.success(data));
  } catch (error) {
    console.error(error);
    yield put(fetchCessionActions.failure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cessions_for_partial_claim_error))
    );
  }
}

const buildCessionRequestBundles = (events) => {
  let bundles = {};
  let requestedIds = [];

  // latest first
  events = events.sort((a, b) => b.blockNumber - a.blockNumber).map((event) => event.returnValues);

  for (let event of events) {
    const id = Number(toUtf8(event.cessionId));
    if (requestedIds.includes(id)) {
      continue;
    }
    if (!bundles[event.payingAgent]) {
      bundles = {
        ...bundles,
        [event.payingAgent]: [
          {
            abtretungId: Number(toUtf8(event.cessionId)),
            fingerprint: event.cessionHash,
          },
        ],
      };
    } else {
      bundles = {
        ...bundles,
        [event.payingAgent]: [
          ...bundles[event.payingAgent],
          {
            abtretungId: Number(toUtf8(event.cessionId)),
            fingerprint: event.cessionHash,
          },
        ],
      };
    }
    requestedIds.push(id);
  }
  return bundles;
};

const eventFilterForDashboard = (event, callerAddr) => {
  const state = Number(event.state);
  if (event.buyer === callerAddr) {
    if (state === CSSDCessionState.OPEN || state === CSSDCessionState.CERT_OPEN) {
      return true;
    }
  } else if (event.seller === callerAddr) {
    const validStates = [
      CSSDCessionState.NEW,
      CSSDCessionState.IN_WORK,
      CSSDCessionState.APPROVED,
      CSSDCessionState.PAYMENT_RECEIVED,
    ];
    if (validStates.includes(state)) {
      return true;
    }
  }
  return false;
};

const buildCessionsForDashboardRequestBundles = (events, callerAddr) => {
  let bundles = {};

  // latest first
  events = events.sort((a, b) => b.blockNumber - a.blockNumber).map((event) => event.returnValues);

  const lastEvents = [];
  events.forEach((event) => {
    const cessionIds = lastEvents.map((item) => item.cessionId);
    if (!cessionIds.includes(event.cessionId)) {
      lastEvents.push(event);
    }
  });

  const filteredEvents = lastEvents.filter((event) => eventFilterForDashboard(event, callerAddr));

  const numTotal = filteredEvents.length;

  for (let event of filteredEvents) {
    if (!bundles[event.payingAgent]) {
      bundles = {
        ...bundles,
        [event.payingAgent]: [
          {
            abtretungId: Number(toUtf8(event.cessionId)),
            fingerprint: event.cessionHash,
            isSeller: event.seller === callerAddr,
          },
        ],
      };
    } else {
      bundles = {
        ...bundles,
        [event.payingAgent]: [
          ...bundles[event.payingAgent],
          {
            abtretungId: Number(toUtf8(event.cessionId)),
            fingerprint: event.cessionHash,
            isSeller: event.seller === callerAddr,
          },
        ],
      };
    }
  }

  return { bundles, numTotal };
};

export function* fetchSumOfAllCessionsSaga({ partialClaim }) {
  try {
    const { data } = yield call(
      fetchSumOfAllCessions,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id
    );
    yield put(fetchSumOfAllCessionsActions.success(data));
  } catch (error) {
    console.error(error);
    yield put(fetchSumOfAllCessionsActions.failure(error));
  }
}

export function* fetchCessionRequests({ callerAddr }) {
  try {
    let cessionRequests = [];
    const events = yield call(getCssdCessionEventFromBlockchain, callerAddr);
    let bundles = buildCessionRequestBundles(events);

    const responses = yield all(
      Object.keys(bundles).map((payingAgent) => {
        return call(fetchCessionRequestsFromBackend, bundles[payingAgent], payingAgent);
      })
    );

    responses.forEach((response) => {
      cessionRequests = [...cessionRequests, ...response.data.abtretungsAnfrageResponses];
    });
    yield put(fetchCessionRequestActions.success(cessionRequests));
  } catch (error) {
    console.error(error);
    yield put(fetchCessionRequestActions.failure(error));
  }
}

function* fetchCessionsFromAll(cessionsByArranger) {
  const arrangers = Object.keys(cessionsByArranger);
  for (let i = 0; i < arrangers.length; i++) {
    yield fork(fetchCessionsFromArranger, arrangers[i], cessionsByArranger[arrangers[i]]);
  }
}

function* fetchCessionsFromArranger(arranger, payload) {
  try {
    const response = yield call(fetchCessionsForDashboard, payload, arranger);
    yield put(fetchCssdCessionsForWorkActions.success(response.data.abtretungResponses));
    yield put(fetchCssdCessionsForWorkActions.decreaseTotal(response.data.numFiltered));
  } catch (e) {
    const errors = payload.map((cession) => ({
      id: cession.abtretungId,
      ...cession,
    }));
    yield put(fetchCssdCessionsForWorkActions.failure(arranger, errors));
    console.error(e);
  }
}

function* retryFetchAllCessions({ loadErrors, arranger }) {
  const errors = arranger ? { [arranger]: loadErrors[arranger] } : loadErrors;
  const bundle = {};
  Object.keys(errors).forEach((arranger) => {
    bundle[arranger] = errors[arranger].map((err) => {
      const { id, ...cession } = err;
      return cession;
    });
  });
  yield fetchCessionsFromAll(bundle);
}

export function* fetchAllCessions({ callerAddr }) {
  try {
    const events = yield call(getAllCssdCessionEventFromBlockchain, callerAddr);
    const { bundles, numTotal } = buildCessionsForDashboardRequestBundles(events, callerAddr);
    yield put(fetchCssdCessionsForWorkActions.setTotal(numTotal));
    yield fetchCessionsFromAll(bundles);
  } catch (error) {
    console.error(error);
  }
}

export function* fetchUpdatedCessionForWork({ callerAddr, event }) {
  try {
    const cessionId = Number(toUtf8(event.returnValues.cessionId));
    const { payingAgent } = event.returnValues;
    const isCached = yield select(isCessionCached, cessionId);
    const shouldBeDisplayed = eventFilterForDashboard(event.returnValues, callerAddr);

    if (shouldBeDisplayed && !isCached) {
      const payload = [
        {
          abtretungId: cessionId,
          fingerprint: event.returnValues.cessionHash,
          isSeller: event.returnValues.seller === callerAddr,
        },
      ];
      try {
        const response = yield call(fetchCessionsForDashboard, payload, payingAgent);
        if (!response.data.numFiltered) {
          yield put(
            updateCssdCessionsForWorkActions.success(
              payingAgent,
              response.data.abtretungResponses[0]
            )
          );
        }
      } catch (e) {
        const errors = payload.map((cession) => ({
          id: cession.abtretungId,
          ...cession,
        }));
        yield put(updateCssdCessionsForWorkActions.failure(payingAgent, errors));
        console.error(e);
      }
    } else if (shouldBeDisplayed) {
      yield put(
        updateCssdCessionsForWorkActions.updateStatus(cessionId, Number(event.returnValues.state))
      );
    } else if (isCached) {
      yield put(updateCssdCessionsForWorkActions.remove(payingAgent, cessionId));
    } else {
      // ignore the event
    }
  } catch (error) {
    console.error(error);
  }
}

export function* navigateToCessionOverview({ arranger, partialClaimId, geschaeftsnummer }) {
  try {
    const payload = [{ teilforderungId: partialClaimId }];
    const response = yield call(queryTeilforderungenById, arranger, payload, getIdProxyAddr());
    const partialClaim = response.data.cssdTeilforderungResponses[0];
    yield openOverviewCession({
      partialClaim,
      fromPath: "/cssdpartialclaimoverview/cessionoverview",
    });
    yield put(filterCessionTable({ GESCHAEFTSNUMMER: geschaeftsnummer }));
    yield put(openCessionOverviewFromHomeActions.success());
  } catch (e) {
    console.error(e);
    yield put(openCessionOverviewFromHomeActions.failure(e));
  }
}

export function* confirmCession({ account, cssd, partialClaim, cession }) {
  try {
    if (cession.extern && cession.firstConfirmer !== zeroAddress) {
      yield call(
        createCessionConfirmationPDFOnBackend,
        cssd.zahlstelle.idProxyAdresse,
        cession.abtretungId
      );
    }

    yield call(
      offerCessionOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      cession.firstConfirmer,
      toHex(`${cssd.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint
    );

    if (cession.firstConfirmer !== zeroAddress) {
      yield call(sendCssdCessionMail, {
        template: mailTemplates.cssdabtretung_bestaetigung_zwei,
        arranger: cssd.zahlstelle.idProxyAdresse,
        cessionId: cession.abtretungId,
        cessionHash: cession.fingerprint,
      });
    }

    yield put(confirmCessionActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_offer_success)));
    navigateToOverview();
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_offer_failure)));
    yield put(confirmCessionActions.failure(error));
  }
}

export function* confirmCertificate({ account, partialClaim, cessionToConfirm }) {
  try {
    yield call(
      offerCertificateOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      cessionToConfirm.firstConfirmer,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cessionToConfirm.abtretungId}`),
      cessionToConfirm.fingerprint
    );
    if (cessionToConfirm.firstConfirmer !== zeroAddress) {
      yield call(sendCssdCessionMail, {
        template: mailTemplates.cssdabtretung_uebertragungszertifikat_bestaetigung_zwei,
        arranger: partialClaim.idProxyAdresseZahlstelle,
        cessionId: cessionToConfirm.abtretungId,
        cessionHash: cessionToConfirm.fingerprint,
      });
    }
    yield put(offerCertificateActions.success());
    yield put(cessionDialogs.closeCertificateConfirmationDialog());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.bulk_action_success)));
  } catch (error) {
    console.error(error);
    yield put(offerCertificateActions.failure(error));
    yield put(cessionDialogs.closeCertificateConfirmationDialog());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.bulk_action_failure)));
  }
}

export function* acceptCertificate({ account, cessionToAccept }) {
  try {
    const { data: cessionCert } = yield call(
      fetchCessionCert,
      cessionToAccept.zahlstelle.idProxyAdresse,
      cessionToAccept.abtretungId
    );

    const certBuffer = _base64ToArrayBuffer(cessionCert.dokument);
    const certString = ab2str(certBuffer);
    const certSignedHash = toSha3(certString);

    yield call(
      acceptCertOnBlockchain,
      account,
      cessionToAccept.zahlstelle.idProxyAdresse,
      cessionToAccept.firstConfirmer,
      toHex(`${cessionToAccept.cssdId}`),
      toHex(`${cessionToAccept.entstandenAusTeilforderungId}`),
      toHex(`${cessionToAccept.abtretungId}`),
      cessionToAccept.fingerprint,
      certSignedHash
    );
    if (cessionToAccept.firstConfirmer !== zeroAddress) {
      yield call(sendCssdCessionMail, {
        template: mailTemplates.cssdabtretung_uebertragungszertifikat_gegenbestaetigung_zwei,
        arranger: cessionToAccept.zahlstelle.idProxyAdresse,
        cessionId: cessionToAccept.abtretungId,
        cessionHash: cessionToAccept.fingerprint,
      });
    }
    yield put(acceptCertificateActions.success());
    yield put(cessionDialogs.closeCertificateAcceptDialog());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.bulk_action_success)));
  } catch (error) {
    console.error(error);
    yield put(acceptCertificateActions.failure(error));
    yield put(cessionDialogs.closeCertificateAcceptDialog());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.bulk_action_failure)));
  }
}

export function* saveAndConfirmCession({ account, cessionData, partialClaim, onCreating }) {
  try {
    const saveInAddressBook = cessionData.neuerDarlehensgeber.saveInAddressBook;
    const cessionPayload = buildPostCssdCessionPayload(cessionData);
    const cession = yield generateCSSDCessionFingerprints(cessionPayload);

    if (saveInAddressBook) {
      yield call(storeAddressBookEntry, cessionPayload.neuerDarlehensgeber);
    }

    const { data } = yield postCessionToBackend(
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id,
      cession
    );

    yield call(
      modifyAndOfferCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.idProxyAdresseDarlehensgeber,
      cession.neuerDarlehensgeber.idProxyAdresse,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${data.abtretungId}`),
      cession.fingerprint,
      cession.neuerDarlehensgeber.fingerprint,
      cession.valuta,
      cession.extern
    );

    yield put(saveAndConfirmCessionActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cession_save_and_confirm_success)
      )
    );
    if (onCreating) {
      navigateToOverview();
    } else {
      yield put(cessionModals.close());
    }
  } catch (error) {
    console.error(error);
    yield put(saveAndConfirmCessionActions.failure());
    if (error?.response?.status === 400) {
      yield put(snackbarActions.openError(error.response?.data?.constraintViolations[0].message));
    } else {
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.cession_save_and_confirm_failure)
        )
      );
      if (onCreating) {
        navigateToOverview();
      } else {
        yield put(cessionModals.close());
      }
    }
  }
}

function* confirmPaymentOnBlockchainAndCreateCertificate({
  account,
  cssd,
  partialClaim,
  cession,
  hasRest,
}) {
  yield call(
    createCertificateOnPaymentReceived,
    partialClaim.idProxyAdresseZahlstelle,
    cession.abtretungId
  );

  yield call(
    confirmPaymentOnBlockchain,
    account,
    partialClaim.idProxyAdresseZahlstelle,
    toHex(`${cssd.cssdId}`),
    toHex(`${partialClaim.id}`),
    toHex(`${cession.abtretungId}`),
    cession.fingerprint,
    hasRest
  );
}

export function* confirmPayment({ account, cssd, partialClaim, cession }) {
  try {
    yield call(confirmPaymentOnBlockchainAndCreateCertificate, {
      account,
      cssd,
      partialClaim,
      cession,
      hasRest: true,
    });

    yield put(cssdPaymentConfirmedActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_paid_success)));
    yield put(cessionDialogs.closePaymentConfirmedDialog());
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_paid_failure)));
    yield put(cessionDialogs.closePaymentConfirmedDialog());
    yield put(cssdPaymentConfirmedActions.failure(error));
  }
}

export function* confirmPaymentReceivedForRebuy({ account, partialClaim }) {
  try {
    const { data } = yield call(
      getRestAmountFromPartialClaimForRebuy,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id
    );

    yield call(
      createCertificateOnPaymentReceived,
      partialClaim.idProxyAdresseZahlstelle,
      data.entstandenAusAbtretungId
    );

    yield call(
      confirmPaymentOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${data.entstandenAusTeilforderungId}`),
      toHex(`${data.entstandenAusAbtretungId}`),
      data.entstandenAusAbtretungFingerprint,
      data.summe > 0
    );

    yield put(paymentReceivedForRebuyActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.rebuy_paid_success)));
    yield put(cessionDialogs.closeConfirmPaymentForRebuyDialog());
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.rebuy_paid_failure)));
    yield put(paymentReceivedForRebuyActions.failure(error));
    yield put(cessionDialogs.closeConfirmPaymentForRebuyDialog());
  }
}

export function* fetchCSSDCessionForUpdate({ arranger, id, fingerprint, payingAgent }) {
  try {
    if (
      contractCache.identityProxy._address === arranger ||
      contractCache.identityProxy._address === payingAgent
    ) {
      const { data } = yield call(fetchCession, payingAgent, id, fingerprint);
      if (data.abtretungResponses[0]) {
        yield put(cessionActions.update(data.abtretungResponses[0]));
      } else {
        yield put(cessionActions.remove(id));
      }
    } else {
      const { data } = yield call(
        fetchCessionRequestsFromBackend,
        {
          abtretungId: id,
          fingerprint,
        },
        payingAgent
      );

      if (data.abtretungResponses[0]) {
        yield put(cessionActions.update(data.abtretungsAnfrageResponses[0]));
      } else {
        yield put(cessionActions.removeRequest(id));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateCessionsAndRequests({ arranger, id, fingerprint, payingAgent }) {
  try {
    if (
      contractCache.identityProxy._address === arranger ||
      contractCache.identityProxy._address === payingAgent
    ) {
      const { data } = yield call(fetchCession, payingAgent, id, fingerprint);
      yield put(cessionActions.update(data.abtretungResponses[0]));
    } else {
      yield put(cessionActions.remove(id));
      yield put(cessionActions.removeRequest(id));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createPartialClaimSaga(account, cessionRequest) {
  try {
    const { data } = yield call(
      createPartialClaimFromCession,
      cessionRequest.zahlstelle.idProxyAdresse,
      cessionRequest.cssdId,
      cessionRequest.abtretungId
    );

    yield call(
      sendNewPartialClaimOnBlockchain,
      account,
      cessionRequest.zahlstelle.idProxyAdresse,
      toHex(`${cessionRequest.cssdId}`),
      toHex(`${cessionRequest.entstandenAusTeilforderungId}`),
      toHex(`${data.id}`),
      cessionRequest.fingerprint,
      toHex(`${cessionRequest.abtretungId}`),
      cessionRequest.fingerprint
    );
  } catch (error) {
    console.error(error);
    saveLogs(cessionRequest);
  }
}

export function* acceptCssdCession({ account, cessionRequest }) {
  try {
    if (cessionRequest.firstConfirmer !== zeroAddress) {
      yield call(
        createCessionConfirmationPDFOnBackend,
        cessionRequest.zahlstelle.idProxyAdresse,
        cessionRequest.abtretungId
      );
    }

    yield call(
      acceptCessionOnBlockchain,
      account,
      cessionRequest.zahlstelle.idProxyAdresse,
      cessionRequest.firstConfirmer,
      toHex(`${cessionRequest.cssdId}`),
      toHex(`${cessionRequest.entstandenAusTeilforderungId}`),
      toHex(`${cessionRequest.abtretungId}`),
      cessionRequest.fingerprint
    );

    if (cessionRequest.firstConfirmer !== zeroAddress) {
      yield spawn(sendCssdCessionMail, {
        template: mailTemplates.cssdabtretung_gegenbestaetigung_zwei,
        arranger: cessionRequest.zahlstelle.idProxyAdresse,
        cessionId: cessionRequest.abtretungId,
        cessionHash: cessionRequest.fingerprint,
      });

      const response = yield call(
        getTerminationsForPartialClaimOnBlockchain,
        cessionRequest.zahlstelle.idProxyAdresse,
        toHex(`${cessionRequest.cssdId}`),
        toHex(`${cessionRequest.entstandenAusTeilforderungId}`)
      );
      if (response.approvedTerminationId !== ZERO_HASH) {
        yield spawn(sendCssdCessionMail, {
          template: mailTemplates.cssd_abtretung_kuendigung_hinweis,
          arranger: cessionRequest.zahlstelle.idProxyAdresse,
          cessionId: cessionRequest.abtretungId,
          cessionHash: cessionRequest.fingerprint,
        });
      }

      if (cessionRequest.isRueckkauf) {
        yield spawn(sendCssdCessionMail, {
          template: mailTemplates.cssdabtretung_rueckkauf_gegenbestaetigung,
          arranger: cessionRequest.zahlstelle.idProxyAdresse,
          cessionId: cessionRequest.abtretungId,
          cessionHash: cessionRequest.fingerprint,
        });
      }

      yield call(createPartialClaimSaga, account, cessionRequest);
    }

    yield put(acceptCssdCessionActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cession_accept_success))
    );
    yield put(cessionModals.close());
  } catch (error) {
    console.error(error);
    yield put(acceptCssdCessionActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cession_accept_failure)));
    yield put(cessionModals.close());
  }
}

export function* downloadConfirmation({ partialClaim, cession }) {
  if (partialClaim === "0") {
    partialClaim = null;
  } // when loaded by eventListener it is '0' in that case.
  const idProxyZahlstelle = partialClaim
    ? partialClaim.idProxyAdresseZahlstelle
    : cession.zahlstelle.idProxyAdresse;

  try {
    yield call(downloadCessionConfirmationFromBackend, idProxyZahlstelle, cession.abtretungId);
    yield put(cessionConfirmation.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cession_confirmation_download_failure)
      )
    );
    yield put(cessionConfirmation.failure(error));
  }
}

export function* bulkActionSaga({ bulkAction, account, cssd, cessions, partialClaim }) {
  try {
    const cessionIdsHex = cessions.map((cession) => toHex(`${cession.abtretungId}`));
    const cessionHashes = cessions.map((cession) => cession.fingerprint);
    const firstConfirmers = cessions.map((cession) => cession.firstConfirmer);
    const bulkActionArgs = {
      account,
      cssd,
      cessions,
      partialClaim,
      cessionIdsHex,
      cessionHashes,
      firstConfirmers,
    };

    switch (bulkAction) {
      case CSSDCessionBulkAction.RECEIVE_PAYMENT:
        yield call(bulkReceivePaymentSaga, bulkActionArgs);
        break;
      case CSSDCessionBulkAction.OFFER_CESSION:
        yield call(bulkOffer, bulkActionArgs);
        break;
      case CSSDCessionBulkAction.OFFER_CERT:
        yield call(bulkOfferCert, bulkActionArgs);
        break;
      default:
        throw new Error(`Invalid argument bulkAction ${bulkAction}`);
    }

    yield put(bulkActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.bulk_action_success)));
  } catch (error) {
    console.error(error);
    yield put(bulkActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.bulk_action_failure)));
  }
}

export function* bulkReceivePaymentSaga({
  account,
  cssd,
  cessions,
  partialClaim,
  cessionIdsHex,
  cessionHashes,
}) {
  yield call(createCertificateOnPaymentReceivedBulk, cssd.zahlstelle.idProxyAdresse, {
    abtretungIds: cessions.map((cession) => cession.abtretungId),
  });

  yield call(
    bulkReceivePaymentOnBlockchain,
    account,
    cssd.zahlstelle.idProxyAdresse,
    toHex(`${cssd.cssdId}`),
    toHex(`${partialClaim.id}`),
    true,
    cessionIdsHex,
    cessionHashes
  );
}

function* bulkOffer({
  account,
  cssd,
  cessions,
  partialClaim,
  cessionIdsHex,
  cessionHashes,
  firstConfirmers,
}) {
  const offeredCessions = cessions.filter((cession) => {
    return cession.firstConfirmer !== zeroAddress;
  });
  let mailServiceRequests = {};
  let createCessionConfirmationPDFOnBackendRequests = [];
  offeredCessions.forEach((cession) => {
    mailServiceRequests[cession.abtretungId] = {
      template: cession.extern
        ? mailTemplates.cssdabtretung_gegenbestaetigung_zwei
        : mailTemplates.cssdabtretung_bestaetigung_zwei,
    };
    if (cession.extern) {
      createCessionConfirmationPDFOnBackendRequests.push(
        call(
          createCessionConfirmationPDFOnBackend,
          cssd.zahlstelle.idProxyAdresse,
          cession.abtretungId
        )
      );
    }
  });
  yield all(createCessionConfirmationPDFOnBackendRequests);

  yield call(
    bulkActionCessionOnBlockchain,
    CSSDCessionBulkAction.OFFER_CESSION,
    account,
    cssd.zahlstelle.idProxyAdresse,
    firstConfirmers,
    toHex(`${cssd.cssdId}`),
    toHex(`${partialClaim.id}`),
    cessionIdsHex,
    cessionHashes
  );

  yield call(sendBulkCssdCessionMail, {
    arranger: cssd.zahlstelle.idProxyAdresse,
    mailServiceRequests,
  });
}

function* bulkOfferCert({
  account,
  cssd,
  cessions,
  partialClaim,
  cessionIdsHex,
  cessionHashes,
  firstConfirmers,
}) {
  yield call(
    bulkActionCessionOnBlockchain,
    CSSDCessionBulkAction.OFFER_CERT,
    account,
    cssd.zahlstelle.idProxyAdresse,
    firstConfirmers,
    toHex(`${cssd.cssdId}`),
    toHex(`${partialClaim.id}`),
    cessionIdsHex,
    cessionHashes
  );

  const offeredCessions = cessions.filter((cession) => {
    return cession.firstConfirmer !== zeroAddress;
  });
  let mailServiceRequests = {};
  offeredCessions.forEach((cession) => {
    mailServiceRequests[cession.abtretungId] = {
      template: mailTemplates.cssdabtretung_uebertragungszertifikat_bestaetigung_zwei,
    };
  });
  yield call(sendBulkCssdCessionMail, {
    arranger: cssd.zahlstelle.idProxyAdresse,
    mailServiceRequests,
  });
}

export function* bulkNotifyCession({ account, cssd, partialClaims }) {
  try {
    const partialClaimIds = partialClaims.map((partialClaim) => toHex(`${partialClaim.id}`));

    yield call(
      bulkNotifyCessionOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      partialClaimIds
    );

    const restBestandResponse = yield call(
      fetchConfirmedRestOfAllCessions,
      partialClaims[0].idProxyAdresseZahlstelle,
      partialClaims[0].parentTeilforderungId,
    );

    if (restBestandResponse.data.summe === 0) {
      yield call(
        repaymentReceivedPartialClaimZSOnBlockchain,
        account,
        partialClaims[0].idProxyAdresseZahlstelle,
        toHex(`${partialClaims[0].cssdId}`),
        toHex(`${partialClaims[0].parentTeilforderungId}`),
      );
    }

    const cessionId = partialClaims
      .filter((partialClaim) => partialClaim.extern)
      .map((partialClaim) => partialClaim.entstandenAusAbtretungId);

    yield call(createCessionNotificationBulk, getIdProxyAddr(), cssd.cssdId, cessionId);

    yield connectCessionPartners(partialClaims);

    let mailServiceRequests = {};
    let notifiedBusinessIdentifiers = "";
    partialClaims.forEach((partialClaim) => {
      mailServiceRequests[partialClaim.entstandenAusAbtretungId] = {
        template: mailTemplates.cssdabtretung_anzeige_alter_und_neuer_darlehensgeber,
      };
      notifiedBusinessIdentifiers = notifiedBusinessIdentifiers.concat(
        partialClaim.geschaeftsnummer + ","
      );
    });

    yield call(sendBulkCssdCessionMail, {
      arranger: cssd.zahlstelle.idProxyAdresse,
      mailServiceRequests,
    });
    yield call(sendCssdMail, {
      template: mailTemplates.cssdabtretung_anzeige_darlehensnehmer,
      arranger: partialClaims[0].idProxyAdresseZahlstelle,
      cssdId: partialClaims[0].cssdId,
      fingerprint: partialClaims[0].cssdFingerprint,
      angezeigteGeschaeftsnummern: notifiedBusinessIdentifiers,
    });

    yield put(bulkNotifyCessionActions.success());

    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.bulk_action_success)));
  } catch (error) {
    console.error(error);
    yield put(bulkNotifyCessionActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.bulk_action_failure)));
  }
}

export function* downloadCessionCertSaga({ idProxyAddress, cessionId }) {
  try {
    yield call(downloadCessionCertFromBackend, idProxyAddress, cessionId);
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cession_cert_download_failure))
    );
  }
}

export function* notifyCession({ account, partialClaim }) {
  try {
    if (partialClaim.extern) {
      yield call(
        createCessionNotification,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.entstandenAusAbtretungId,
        partialClaim.cssdId
      );
    }

    yield call(
      notifyCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`)
    );

    const restBestandResponse = yield call(
      fetchConfirmedRestOfAllCessions,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.parentTeilforderungId
    );

    if (restBestandResponse.data.summe === 0) {
      yield call(
        repaymentReceivedPartialClaimZSOnBlockchain,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.parentTeilforderungId}`)
      );
    }

    yield call(sendCssdCessionMail, {
      template: mailTemplates.cssdabtretung_anzeige_alter_und_neuer_darlehensgeber,
      arranger: partialClaim.idProxyAdresseZahlstelle,
      cessionId: partialClaim.entstandenAusAbtretungId,
      cessionHash: partialClaim.entstandenAusAbtretungFingerprint,
    });

    yield call(sendCssdMail, {
      template: mailTemplates.cssdabtretung_anzeige_darlehensnehmer,
      arranger: partialClaim.idProxyAdresseZahlstelle,
      cssdId: partialClaim.cssdId,
      fingerprint: partialClaim.cssdFingerprint,
      angezeigteGeschaeftsnummern: partialClaim.geschaeftsnummer,
    });

    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.bulk_action_success)));
    yield connectCessionPartners([partialClaim]);
    yield put(notifyCssdCessionActions.success());
    yield put(cessionDialogs.closeConfirmNotifyCessionDialog());
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.bulk_action_failure)));
    yield put(notifyCssdCessionActions.failure(error));
    yield put(cessionDialogs.closeConfirmNotifyCessionDialog());
  }
}
//---------------------------------------------------------------------------
export function* acceptExtCessionSaga({ account, cssd, partialClaim, cession }) {
  try {
    yield call(
      acceptCessionExternOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint
    );

    const { cssdId, darlehensbetrag, zahlstelle } = cssd;
    const cessionRequest = {
      ...cession,
      cssdId,
      darlehensbetrag,
      zahlstelle,
      entstandenAusTeilforderungId: partialClaim.id,
    };

    yield call(createPartialClaimSaga, account, cessionRequest);

    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_cession_accept_ext_snackbar_success)
      )
    );
    yield put(acceptExtCessionActions.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_cession_accept_ext_snackbar_failure)
      )
    );
    yield put(acceptExtCessionActions.failure(error));
  }
}

export function* acceptExtCessionCertificateSaga({
  account,
  cssd,
  partialClaim,
  cession,
  certificate,
}) {
  try {
    yield call(uploadCessionCert, cssd.zahlstelle.idProxyAdresse, cession.abtretungId, certificate);

    const certBuffer = _base64ToArrayBuffer(certificate);
    const certString = ab2str(certBuffer);
    const certSignedHash = toSha3(certString);

    yield call(
      acceptCessionCertificateExtern,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint,
      certSignedHash
    );
    yield call(sendCssdCessionMail, {
      template: mailTemplates.cssdabtretung_uebertragungszertifikat_gegenbestaetigung_zwei,
      arranger: partialClaim.idProxyAdresseZahlstelle,
      cessionId: cession.abtretungId,
      cessionHash: cession.fingerprint,
    });
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_cession_upload_cert_snackbar_success)
      )
    );
    yield put(uploadCessionCertActions.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_cession_upload_cert_snackbar_failure)
      )
    );
    yield put(uploadCessionCertActions.failure(error));
  }
}

export function* requestChangesForExternalCession({ account, cssd, partialClaim, cession }) {
  try {
    const toPost = {
      id: cession.abtretungId,
      typ: GeschaeftsvorfallTyp.ABTRETUNG,
      geschaeftsvorfallFingerprint: cession.fingerprint,
      geschaeftsvorfallFingerprintTimestamp: cession.fingerprintTimestamp,
      geschaeftsvorfallFingerprintVersion: cession.fingerprintVersion,
      ablehnungsgrund: "",
    };

    const reasonWithFP = yield generateCssdRejectionFingerprint(toPost);

    const reasonHash = reasonWithFP.ablehnungsgrundFingerprint;

    yield call(
      rejectCessionOnBlockchain,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${cssd.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${cession.abtretungId}`),
      cession.fingerprint,
      reasonHash
    );
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_cession_request_change_external_snackbar_success)
      )
    );

    yield put(requestChangesForExternalCessionActions.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_cession_request_change_external_snackbar_failure)
      )
    );
    yield put(requestChangesForExternalCessionActions.failure(error));
  }
}

export function* connectGpsInBulkSagas(dnAddress, businessPartners, claim) {
  const { data } = yield call(connectGpsInBulk, dnAddress, businessPartners);
  const connectedGps = businessPartners.filter((partner) =>
    data.newConnections.includes(partner.idProxyAddress)
  );
  if (connectedGps.length) {
    const newGps = connectedGps.map((gp) => {
      return {
        firmenname: gp.name,
        lei: gp.lei,
        digitsKennung: gp.digitsKennung,
      };
    });
    yield fork(sendBulkMailForNewGp, {
      arranger: claim.idProxyAdresseZahlstelle,
      cssdId: claim.cssdId,
      newGps,
    });
  }
}

export function* connectGpSagas(dnAddress, dgAddresses, partialClaims) {
  const dn = yield call(getGpFromPartners, dnAddress);
  for (let address of dgAddresses) {
    const connected = yield call(connectGp, address, dn);
    if (connected) {
      const claim = partialClaims.find((claim) => claim.idProxyAdresseDarlehensgeber === address);
      yield fork(sendCssdCessionMail, {
        template: mailTemplates.cssdabtretung_neuer_geschaeftspartner_dg,
        arranger: claim.idProxyAdresseZahlstelle,
        cessionId: claim.entstandenAusAbtretungId,
        cessionHash: claim.entstandenAusAbtretungFingerprint,
        firmenname: dn.name,
        lei: dn.lei,
        digitsKennung: dn.digitsKennung,
      });
    }
  }
}

export function* connectCessionPartners(partialClaims) {
  try {
    let dnAddress = partialClaims[0].idProxyAdresseDarlehensnehmer;
    let dgAddresses = [
      ...new Set(
        partialClaims
          .filter((claim) => !claim.extern)
          .map((claim) => claim.idProxyAdresseDarlehensgeber)
      ),
    ];

    const businessPartners = yield all(
      dgAddresses.map((address) => call(getGpFromPartners, address))
    );

    yield call(connectGpsInBulkSagas, dnAddress, businessPartners, partialClaims[0]);

    yield call(connectGpSagas, dnAddress, dgAddresses, partialClaims);
  } catch (error) {
    console.error(error);
  }
}

export function* downloadCessionNotificationSaga({ partialClaim }) {
  try {
    yield call(
      getCessionNotification,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.entstandenAusAbtretungId
    );
  } catch (error) {
    console.error(error);
  }
}
