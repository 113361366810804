import { getIdProxyAddr } from "./web3Services/commons";
import { sendSignedHttpRequest } from "util/requestSigning";

export const createFingerprint = async (type, data) => {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/fingerprint/${getIdProxyAddr()}/${type}/create`,
    getIdProxyAddr(),
    data
  );
};

export const validateFingerprint = async (type, data) => {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/fingerprint/${getIdProxyAddr()}/${type}/validate`,
    getIdProxyAddr(),
    data
  );
};
