import React from "react";
import { Breadcrumbs, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { defineMessages, FormattedMessage } from "react-intl";
import { breadcrumb_styles } from "./dashboard_styles";

const messages = defineMessages({
  infomaterialoverview: {
    id: "breadcrumbs.infomaterialoverview",
    defaultMessage: "Informationsmaterial Übersicht",
  },
  upload: {
    id: "breadcrumbs.upload",
    defaultMessage: "Hochladen",
  },
  cessionRequest: {
    id: "breadcrumbs.cession.request",
    defaultMessage: "Übertragungsanfragen",
  },
  cssd: {
    id: "breadcrumbs.cssdoverview",
    defaultMessage: "CSSD Übersicht",
  },
  partialclaim_overview: {
    id: "breadcrumbs.partialclaimoverview",
    defaultMessage: "Teilforderung Übersicht",
  },
  partialclaim_cssd: {
    id: "breadcrumbs.partialclaimforcssd",
    defaultMessage: "Teilforderungen zu einem CSSD",
  },
  cession: {
    id: "breadcrumbs.cession",
    defaultMessage: "Übertragung Übersicht",
  },
  cession_register: {
    id: "breadcrumbs.cession_register",
    defaultMessage: "Erfassen",
  },
  cession_redokument: {
    id: "breadcrumbs.cession_redokument",
    defaultMessage: "Nachdokumentieren",
  },
  register_rebuy: {
    id: "breadcrumbs.register_rebuy",
    defaultMessage: "Rückkauf erfassen",
  },
  interestnotification: {
    id: "breadcrumbs.interestnotification",
    defaultMessage: "Zinsmitteilungen",
  },
  audit: {
    id: "breadcrumbs.audit",
    defaultMessage: "Historie",
  },
});

function Breadcrumb({ classes, component, ...props }) {
  const paths = convertPathToSubpaths(props.match.path);
  const Component = component;

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs}>
        {paths.map((path, index) => {
          return (
            <Link to={path} aria-current="page" key={index} className={classes.link}>
              {matchPathToMessage(path)}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Component {...props} />
    </>
  );
}

export default withStyles(breadcrumb_styles)(Breadcrumb);

const convertPathToSubpaths = (path) => {
  const count = (path.match(/\//g) || []).length;
  const subpaths = new Array(count - 1);
  for (let i = count; i > 0; i--) {
    subpaths[i] = path;
    path = path.replace(path.substring(path.lastIndexOf("/"), path.length), "");
  }
  return subpaths;
};

const matchPathToMessage = (path) => {
  switch (path) {
    case "/cssdpartialclaimoverview/informationmaterial/upload":
    case "/cssdoverview/informationmaterial/upload":
    case "/cssdoverview/partialclaimforcssdoverview/informationsmaterial/upload":
      return <FormattedMessage {...messages.upload} />;
    case "/cssdcessionrequest":
      return <FormattedMessage {...messages.cessionRequest} />;
    case "/cssdpartialclaimoverview/informationmaterial":
    case "/cssdoverview/informationmaterial":
    case "/cssdoverview/partialclaimforcssdoverview/informationmaterial":
      return <FormattedMessage {...messages.infomaterialoverview} />;
    case "/cssdoverview":
      return <FormattedMessage {...messages.cssd} />;
    case "/cssdoverview/audit":
    case "/cssdcessionrequest/audit":
    case "/cssdoverview/interestnotification/audit":
    case "/cssdoverview/partialclaimforcssdoverview/audit":
    case "/cssdpartialclaimoverview/audit":
    case "/cssdpartialclaimoverview/cessionoverview/audit":
    case "/cssdoverview/partialclaimforcssdoverview/cessionoverview/audit":
      return <FormattedMessage {...messages.audit} />;
    case "/cssdoverview/interestnotification":
      return <FormattedMessage {...messages.interestnotification} />;
    case "/cssdoverview/partialclaimforcssdoverview":
      return <FormattedMessage {...messages.partialclaim_cssd} />;
    case "/cssdpartialclaimoverview":
      return <FormattedMessage {...messages.partialclaim_overview} />;
    case "/cssdpartialclaimoverview/cessionoverview":
    case "/cssdoverview/partialclaimforcssdoverview/cessionoverview":
      return <FormattedMessage {...messages.cession} />;
    case "/cssdpartialclaimoverview/cessionoverview/add":
    case "/cssdoverview/partialclaimforcssdoverview/cessionoverview/add":
      return <FormattedMessage {...messages.cession_register} />;
    case "/cssdpartialclaimoverview/cessionoverview/nachdokumentieren":
    case "/cssdoverview/partialclaimforcssdoverview/cessionoverview/nachdokumentieren":
      return <FormattedMessage {...messages.cession_redokument} />;
    case "/cssdpartialclaimoverview/cessionoverview/rebuy":
    case "/cssdoverview/partialclaimforcssdoverview/cessionoverview/rebuy":
      return <FormattedMessage {...messages.register_rebuy} />;
    default:
      return "";
  }
};
