import { takeLatest, call, put, fork, take, all, cancel } from "redux-saga/effects";
import {
  SAVE_CESSION_REQUEST,
  SUBMIT_CESSION_REQUEST,
  MODIFY_CESSION_REQUEST,
  MODIFY_AND_OFFER_CESSION_REQUEST,
  FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST,
  FETCH_CESSION_FOR_SSD_REQUEST,
  FETCH_CESSION_REQUESTS_REQUEST,
  FETCH_CESSION_NOTIFY_FOR_SSD_UPDATE_AND_REQUEST,
  FETCH_CESSION_NOTIFY_FOR_SSD_REQUEST,
  STOP_CESSION_LISTENER,
  OFFER_CESSION_REQUEST,
  ACCEPT_CESSION_REQUEST,
  ADD_CESSION_EVENT_DG,
  ADD_CESSION_EVENT_NEW_DG,
  REJECT_CESSION_REQUEST,
  NOTIFY_CESSION_REQUEST,
  CESSION_PROCESS_NOTIFICATION_REQUEST,
  DOWNLOAD_CESSION_ANZEIGE_REQUEST,
  DOWNLOAD_CESSION_CONFIRMATION_REQUEST,
  CANCEL_CESSION_REQUEST,
  OPEN_CESSION_CONFIRM_MODAL,
} from "./actions";
import {
  saveCessionActions,
  submitCessionActions,
  modifyCessionActions,
  modifyAndOfferCessionActions,
  offerCessionActions,
  acceptCessionActions,
  rejectCessionActions,
  cessionRegisterModalActions,
  cessionConfirmModalActions,
  cessionPreviewModalActions,
  fetchCessionDetailsForSsdActions,
  fetchCessionEventsForSsdActions,
  fetchCessionRequestActions,
  fetchCessionRequestDetailsActions,
  cessionNotifyActions,
  cessionProcessNotificationActions,
  downloadCessionAnzeigePdfActions,
  downloadCessionConfirmationPdfActions,
  cancelCessionActions,
  cessionFetchRejectReasonActions,
} from "./actions";
import {
  prepareSsdForCession,
  postCession,
  updateCession,
  fetchCession,
  rejectCessionPostRejectReason,
} from "services/cessionService";
import {
  fetchSSDDetailsForNewDg,
  fetchSSDDetails,
  fetchSSDDetailsZedent,
  updateSSD,
} from "services/ssdService";
import { getCessionCount } from "services/web3Services/cession";
import { snackbarActions } from "redux/shared/actions";
import { dateToTimestamp, removeDots, addDotsComma } from "util/convertor";
import {
  cessionNew,
  cessionNewAndOffer,
  getCessionsForSsdId,
  getCessionEventsFromBcForCompany,
  getCessionMovedEventsFromBcForCompany,
  cessionOffer,
  cessionAccept,
  cessionReject,
  cessionCancel,
  cessionModify,
  cessionModifyAndOffer,
  cessionNotify,
  cessionProcessNotification,
  getSSDCessionData,
} from "services/web3Services/cession";
import {
  toHex,
  toUtf8,
  getBlockNumber,
  getFourEyesState,
  getCessionState,
  getHashFromData,
  checkIfisAllowedToSeeCession,
  getSsdState,
  getTerminationState,
} from "services/web3Services/commons";
import { contractCache } from "services/smartContracts/smartContracts";
import {
  listenForCessionEvent,
  listenForFourEyesEvents,
} from "services/web3Services/eventListener";
import {
  FourEyesState,
  mailTemplates,
  zeroAddress,
  ZERO_HASH,
  CessionState,
  SSDTerminationState,
} from "util/constants";
import { formatDate, formatTime } from "util/convertor";
import {
  cessionAcceptOfferMail,
  cessionNotifyMail,
  createCessionAnzeige,
  getCessionAnzeige,
  getCessionConfirmation,
  fetchRejectReason,
} from "services/cessionService";
import history from "util/history";
import { getKuendigung, postKuendigung, postKuendigungZedent } from "services/terminationService";
import { sendMail, sendGenericMail } from "services/mailService";
import { getGpFromPartners, getAllPartnerInfo } from "services/partnerService";
import { connectGp } from "services/businessPartnerService";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generateSsdCessionFingerprint, generateSsdFingerprint } from "util/fingerprint";
import { checkIfCessionWasApproved } from "services/web3Services/cession";
import { getPlatformMemberDetails } from "services/platformMemberService";
import { updateSsdDetails } from "redux/ssdEvents/sagas";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const cessionSagas = [
  takeLatest(SAVE_CESSION_REQUEST, saveCession),
  takeLatest(SUBMIT_CESSION_REQUEST, saveAndOfferCession),
  takeLatest(MODIFY_CESSION_REQUEST, modifyCession),
  takeLatest(MODIFY_AND_OFFER_CESSION_REQUEST, modifyAndOfferCession),
  takeLatest(FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST, fetchCessionEventsAndUpdate),
  takeLatest(FETCH_CESSION_FOR_SSD_REQUEST, fetchCessionEvents),
  takeLatest(FETCH_CESSION_NOTIFY_FOR_SSD_UPDATE_AND_REQUEST, fetchCessionEventsAndUpdate),
  takeLatest(FETCH_CESSION_NOTIFY_FOR_SSD_REQUEST, fetchCessionEvents),
  takeLatest(FETCH_CESSION_REQUESTS_REQUEST, fetchCessionRequests),
  takeLatest(OFFER_CESSION_REQUEST, offerCession),
  takeLatest(ACCEPT_CESSION_REQUEST, acceptCession),
  takeLatest(ADD_CESSION_EVENT_DG, addCessionFromEvent),
  takeLatest(ADD_CESSION_EVENT_NEW_DG, addCessionFromEvent),
  takeLatest(REJECT_CESSION_REQUEST, rejectCession),
  takeLatest(NOTIFY_CESSION_REQUEST, notifyCession),
  takeLatest(CESSION_PROCESS_NOTIFICATION_REQUEST, processNotification),
  takeLatest(DOWNLOAD_CESSION_ANZEIGE_REQUEST, downloadCessionAnzeige),
  takeLatest(DOWNLOAD_CESSION_CONFIRMATION_REQUEST, downloadCessionConfirmation),
  takeLatest(CANCEL_CESSION_REQUEST, cancelCession),
  takeLatest(OPEN_CESSION_CONFIRM_MODAL, fetchRejectReasonForCession),
];

export function* fetchRejectReasonForCession({
  darlehensnehmer,
  ssdId,
  cessionId,
  cessionInitiator,
  cessionHash,
}) {
  try {
    const { data } = yield call(
      fetchRejectReason,
      darlehensnehmer,
      cessionInitiator,
      ssdId,
      cessionId,
      cessionHash
    );
    if (data) {
      yield put(cessionFetchRejectReasonActions.success(data.kommentar));
    } else {
      yield put(cessionFetchRejectReasonActions.empty());
    }
  } catch (error) {
    console.error(error);
  }
}

export function* addCessionFromEvent({ ssdId, cessionId, cessionHash, arranger, seller }) {
  yield call(
    fetchCessionDetailsForSsd,
    toUtf8(ssdId),
    toUtf8(cessionId),
    cessionHash,
    arranger,
    seller
  );
}
//---------------------------------------------------------------------------

export function* startEventListener() {
  const currentBlock = yield call(getBlockNumber);
  const listener = [];
  listener.push(yield fork(fourEyesEventListener, currentBlock));
  listener.push(yield fork(cessionEventListener, currentBlock));
  yield take(STOP_CESSION_LISTENER);
  yield all(listener.map((channel) => cancel(channel)));
}
//---------------------------------------------------------------------------

export function* updateCessionFromFourEyesEvent(arranger, ssdId, cessionId) {
  const cessionState = yield call(getCessionState, arranger, toUtf8(ssdId), toUtf8(cessionId));
  const ssd = yield call(getSsdState, arranger, toUtf8(ssdId));
  yield fork(
    fetchCessionDetailsForSsd,
    toUtf8(ssdId),
    toUtf8(cessionState.cessionId),
    cessionState.hash,
    arranger,
    ssd.buyer
  );
}
//---------------------------------------------------------------------------

export function* fourEyesEventListener(currentBlock) {
  try {
    const chan = yield call(listenForFourEyesEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield fork(updateCessionFromFourEyesEvent, result.arranger, result.ssdId, result.cessionId);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}
//---------------------------------------------------------------------------

export function* cessionEventListener(currentBlock) {
  try {
    const chan = yield call(listenForCessionEvent, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield fork(
        fetchCessionRequestDetails,
        toUtf8(result.cessionId),
        toUtf8(result.ssdId),
        result.arranger,
        result.seller
      );
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}
//---------------------------------------------------------------------------

function* fetchCessionDetailsForSsd(ssdId, cessionId, cessionHash, arranger, seller) {
  try {
    const { data } = yield call(fetchCession, arranger, seller, ssdId, cessionId, cessionHash);
    const cessionState = yield call(getCessionState, data.idproxy_arranger, ssdId, cessionId);
    if (
      data.idproxy_arranger !== contractCache.identityProxy._address ||
      cessionState.state === CessionState.CESSION_NOTIFIED ||
      cessionState.state === CessionState.CESSION_PROCESSED
    ) {
      const fourEyesState = yield call(getFourEyesState, data.idproxy_arranger, ssdId, cessionId);

      let newDgName;
      if (data.abtretung_extern) {
        newDgName = `${data.darlehensgeber} / (extern)`;
      } else {
        const { response } = yield call(getPlatformMemberDetails, data.darlehensgeber);
        newDgName = `${response.data.name} / ${response.data.digitsKennung}`;
      }

      const result = {
        ...data,
        confirmationState: fourEyesState.state,
        firstConfirmer: fourEyesState.firstConfirmer,
        state: cessionState.state,
        newDgName,
      };

      yield put(fetchCessionDetailsForSsdActions.success(result));
    } else {
      yield put(fetchCessionDetailsForSsdActions.notAllowed(cessionId));
    }
  } catch (error) {
    console.error(error);
    yield put(fetchCessionDetailsForSsdActions.failure(error));
  }
}
//---------------------------------------------------------------------------
export function* fetchCessionEventsAndUpdate({ arranger, ssdId }) {
  try {
    yield call(updateSsdDetails, arranger, ssdId, null, true);

    const result = yield call(getCessionsForSsdId, arranger, ssdId);

    yield put(fetchCessionEventsForSsdActions.success([...result]));

    const ssd = yield call(getSsdState, arranger, ssdId);

    yield all(
      [...result].map((cession) =>
        fork(
          fetchCessionDetailsForSsd,
          ssdId,
          toUtf8(cession.cessionId),
          cession.hash,
          arranger,
          ssd.buyer
        )
      )
    );
    //start listener for updates
    yield call(startEventListener);
  } catch (error) {
    console.error(error);
    yield put(fetchCessionEventsForSsdActions.failure());
  }
}

export function* fetchCessionEvents({ arranger, ssdId }) {
  try {
    const result = yield call(getCessionsForSsdId, arranger, ssdId);

    yield put(fetchCessionEventsForSsdActions.success([...result]));

    const ssd = yield call(getSsdState, arranger, ssdId);

    yield all(
      [...result].map((cession) =>
        fork(
          fetchCessionDetailsForSsd,
          ssdId,
          toUtf8(cession.cessionId),
          cession.hash,
          arranger,
          ssd.buyer
        )
      )
    );
    //start listener for updates
    yield call(startEventListener);
  } catch (error) {
    console.error(error);
    yield put(fetchCessionEventsForSsdActions.failure());
  }
}
//---------------------------------------------------------------------------
function* fetchCessionRequestDetails(cessionId, ssdIdOld, arranger, cessionInitiator) {
  try {
    const cessionState = yield call(getCessionState, arranger, ssdIdOld, cessionId);
    const wasApproved = yield call(checkIfCessionWasApproved, toHex(cessionId));
    const oldSsd = yield call(getSsdState, arranger, ssdIdOld);

    if (checkIfisAllowedToSeeCession(cessionState.state, wasApproved)) {
      const { data } = yield call(
        fetchCession,
        arranger,
        cessionInitiator,
        ssdIdOld,
        cessionId,
        cessionState.hash
      );
      const fourEyesState = yield call(getFourEyesState, arranger, ssdIdOld, cessionId);
      const ssdInfo = yield call(
        fetchSSDDetailsForNewDg,
        data.ssdid_alt,
        oldSsd.hash,
        oldSsd.rebuyHash,
        data.idproxy_arranger
      );
      const terminationState = yield call(getTerminationState, arranger, ssdIdOld);

      const result = {
        ...data,
        ssdInfo: ssdInfo.data,
        termination: terminationState,
        confirmationState: fourEyesState.state,
        firstConfirmer: fourEyesState.firstConfirmer,
        state: cessionState.state,
        actionInitiator: cessionInitiator,
        arranger,
      };
      yield put(fetchCessionRequestDetailsActions.success(result));
    } else {
      yield put(fetchCessionRequestDetailsActions.notAllowed(cessionId));
    }
  } catch (error) {
    console.error(error);
    yield put(fetchCessionRequestDetailsActions.failure(cessionId, error));
  }
}
//---------------------------------------------------------------------------

export function* fetchCessionRequests({ companyIdProxy }) {
  try {
    const cessionEvents = yield call(getCessionEventsFromBcForCompany, companyIdProxy);
    const cessionMovedEvents = yield call(getCessionMovedEventsFromBcForCompany);
    const updatedCessions = cessionEvents.map((cession) => {
      for (let moved of cessionMovedEvents) {
        if (moved.cessionId === cession.cessionId) {
          return {
            ...cession,
            ssdId: moved.newSsdId,
          };
        }
      }
      return cession;
    });
    yield put(fetchCessionRequestActions.success(updatedCessions));
    yield all(
      [...updatedCessions].map((cession) =>
        fork(
          fetchCessionRequestDetails,
          toUtf8(cession.cessionId),
          toUtf8(cession.ssdId),
          cession.arranger,
          cession.seller
        )
      )
    );
    //start listener for updates
    yield call(startEventListener);
  } catch (error) {
    console.error(error);
    yield put(fetchCessionRequestActions.failure(error));
  }
}
//---------------------------------------------------------------------------
const updateSsdDnInfo = async function (ssdInfo) {
  const companyInfo = await getGpFromPartners(ssdInfo.darlehensnehmer);
  const update = {
    ...ssdInfo,
    anschrift_darlehensnehmer: companyInfo.anschrift,
    bic_code_darlehensnehmer:
      companyInfo.bic === "" ? ssdInfo.bic_code_darlehensnehmer : companyInfo.bic,
    darlehensnehmer_name: companyInfo.name,
    digits_kennung_darlehensnehmer: companyInfo.digitsKennung,
    erfassender_mitarbeiter_darlehensnehmer: companyInfo.erfassender_mitarbeiter,
    iban_darlehensnehmer: companyInfo.iban === "" ? ssdInfo.iban_darlehensnehmer : companyInfo.iban,
    interne_kennung_darlehensnehmer: companyInfo.interne_kennung,
    lei_darlehensnehmer: companyInfo.lei,
    telefonnummer_darlehensnehmer: companyInfo.telefonnummer,
    umsatzsteuer_id_darlehensnehmer: companyInfo.umsatzsteuer_id,
  };
  return update;
};
//---------------------------------------------------------------------------
export function* prepareNewSsd(oldSsdInfo, cessionData) {
  const {
    ssdid,
    fingerprint: oldFingerprint,
    nominal,
    urkunden_registernummer,
    darlehensgeber,
    darlehensgeber_name,
    umsatzsteuer_id_darlehensgeber,
    anschrift_darlehensgeber,
    lei_darlehensgeber,
    telefonnummer_darlehensgeber,
    digits_kennung_darlehensgeber,
    created_by_cession,
    valuta,
    ...newSsdInfo // new object without previous properties
  } = oldSsdInfo;

  let cessionCount = yield call(getCessionCount, oldSsdInfo.arranger, oldSsdInfo.ssdid);
  let newUrkundeNr =
    oldSsdInfo.urkunden_registernummer +
    "-" +
    (Number(cessionCount) + 2).toString().padStart(3, "0");

  newSsdInfo.fingerprint_rebuy = ZERO_HASH;
  newSsdInfo.nominal = cessionData.abgetretenes_nominal;
  newSsdInfo.valuta = cessionData.valuta_abtretung;
  newSsdInfo.urkunden_registernummer = newUrkundeNr;
  newSsdInfo.darlehensgeber = cessionData.darlehensgeber;
  newSsdInfo.darlehensgeber_name = cessionData.zessionar_name;
  newSsdInfo.umsatzsteuer_id_darlehensgeber = cessionData.umsatzsteuer_id_darlehensgeber;
  newSsdInfo.anschrift_darlehensgeber = cessionData.anschrift_darlehensgeber;
  newSsdInfo.lei_darlehensgeber = cessionData.lei_darlehensgeber;
  newSsdInfo.telefonnummer_darlehensgeber = cessionData.telefonnummer_darlehensgeber;
  newSsdInfo.digits_kennung_darlehensgeber = cessionData.digits_kennung_darlehensgeber;
  newSsdInfo.created_by_cession = true;
  newSsdInfo.handelstag = formatDate(new Date());
  newSsdInfo.handelstag_uhrzeit = formatTime(new Date());
  newSsdInfo.iban_darlehensgeber = cessionData.iban_darlehensgeber_zinsen;
  newSsdInfo.bic_code_darlehensgeber = cessionData.bic_code_darlehensgeber_zinsen;
  newSsdInfo.iban_darlehensgeber_tilgung = cessionData.iban_darlehensgeber_tilgung;
  newSsdInfo.bic_code_darlehensgeber_tilgung = cessionData.bic_code_darlehensgeber_tilgung;

  const updatedSsdInfo = yield call(updateSsdDnInfo, newSsdInfo);

  const { fingerprint, fingerprintTimestamp, fingerprintVersion } = yield generateSsdFingerprint(
    updatedSsdInfo
  );

  let newSsdwithFingerprint = {
    ...updatedSsdInfo,
    fingerprint,
    fingerprintTimestamp,
    fingerprintVersion,
  };

  const { data } = yield call(prepareSsdForCession, newSsdwithFingerprint);
  return { ...newSsdwithFingerprint, ...data };
}
//---------------------------------------------------------------------------
export function* saveCession({ account, oldSsdInfo, cessionData, cessionToExt }) {
  try {
    //------- Post a new SSD with updated fields (fingerprint, darlehensgeber, nominal)

    const newSsdInfo = yield call(prepareNewSsd, oldSsdInfo, cessionData);

    //------- Post a new Cession with required new fields

    cessionData.ssdid_alt = oldSsdInfo.ssdid;
    cessionData.ssd_fingerprint_alt = oldSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_alt = oldSsdInfo.fingerprint_rebuy;

    cessionData.ssdid_neu = newSsdInfo.ssdId.toString();
    cessionData.ssd_fingerprint_neu = newSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_neu = newSsdInfo.fingerprint_rebuy;
    cessionData.idproxy_arranger = newSsdInfo.arranger;
    cessionData.darlehensgeber_alt = oldSsdInfo.darlehensgeber;
    cessionData.abtretung_extern = cessionToExt;

    const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(cessionData);

    const cession = yield call(postCession, cessionDataWithFingerprint, oldSsdInfo.ssdid);

    const buyerAddr = cessionToExt ? zeroAddress : cessionData.darlehensgeber;

    //------- Save the new Cession in Blockchain
    yield call(
      cessionNew,
      account,
      newSsdInfo.arranger,
      toHex(`${oldSsdInfo.ssdid}`),
      toHex(`${cession.data.abtretungId}`),
      cession.data.abtretungFingerprint,
      dateToTimestamp(cessionData.valuta_abtretung),
      buyerAddr,
      toHex(`${newSsdInfo.ssdId}`),
      newSsdInfo.fingerprint,
      cessionToExt
    );

    //------- Update application state

    yield put(saveCessionActions.success());

    yield put(
      fetchCessionEventsForSsdActions.request(account, newSsdInfo.arranger, `${oldSsdInfo.ssdid}`)
    );

    yield put(cessionRegisterModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_gespeichert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(saveCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_gespeichert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* saveAndOfferCession({
  account,
  oldSsdInfo,
  cessionData,
  cessionToExt,
  fourEyesFlag,
}) {
  try {
    //------- Post a new SSD with updated fields (fingerprint, darlehensgeber, nominal)
    const newSsdInfo = yield call(prepareNewSsd, oldSsdInfo, cessionData);

    //------- Post a new Cession with required new fields
    cessionData.ssdid_alt = oldSsdInfo.ssdid;
    cessionData.ssd_fingerprint_alt = oldSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_alt = oldSsdInfo.fingerprint_rebuy;

    cessionData.ssdid_neu = newSsdInfo.ssdId.toString();
    cessionData.ssd_fingerprint_neu = newSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_neu = newSsdInfo.fingerprint_rebuy;
    cessionData.idproxy_arranger = newSsdInfo.arranger;
    cessionData.darlehensgeber_alt = oldSsdInfo.darlehensgeber;

    cessionData.abtretung_extern = cessionToExt;

    const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(cessionData);

    let cession = yield call(postCession, cessionDataWithFingerprint, oldSsdInfo.ssdid);

    const buyerAddr = cessionToExt ? zeroAddress : cessionData.darlehensgeber;

    //------- Save the new Cession in Blockchain
    yield call(
      cessionNewAndOffer,
      account,
      newSsdInfo.arranger,
      toHex(`${oldSsdInfo.ssdid}`),
      toHex(`${cession.data.abtretungId}`),
      cession.data.abtretungFingerprint,
      dateToTimestamp(cessionData.valuta_abtretung),
      buyerAddr,
      toHex(`${newSsdInfo.ssdId}`),
      newSsdInfo.fingerprint,
      oldSsdInfo.fingerprint,
      cessionToExt
    );

    //------- Update application state

    yield put(saveCessionActions.success());

    yield put(
      fetchCessionEventsForSsdActions.request(account, newSsdInfo.arranger, `${oldSsdInfo.ssdid}`)
    );

    //------- Send mail if Company has 2 eyes

    if (!fourEyesFlag && !cessionToExt) {
      yield call(sendGenericMail, {
        template: mailTemplates.abtretung_bestaetigung_zwei,
        sender: contractCache.identityProxy._address,
        geschaeft: oldSsdInfo.ssdid,
        abtretungFingerprint: cession.data.abtretungFingerprint,
        abtretungId: cession.data.abtretungId,
      });
    }

    yield put(submitCessionActions.success());
    yield put(cessionRegisterModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_gespeichert_und_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(submitCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_cession_abtretung_konnte_nicht_gespeichert_und_bestaetigt_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* updateNewSsd(cessionData) {
  const ssdDetails = yield call(
    fetchSSDDetailsZedent,
    cessionData.ssdid_neu,
    cessionData.ssd_fingerprint_neu,
    cessionData.ssd_fingerprint_rebuy_neu,
    cessionData.ssdid_alt,
    cessionData.idproxy_arranger
  );

  const {
    darlehensgeber,
    nominal,
    ...newSsdInfo // new object without previous properties
  } = ssdDetails.data;

  newSsdInfo.darlehensgeber = cessionData.darlehensgeber;
  newSsdInfo.arranger = cessionData.idproxy_arranger;
  newSsdInfo.nominal = cessionData.abgetretenes_nominal;

  const { fingerprint, fingerprintTimestamp, fingerprintVersion } = yield generateSsdFingerprint(
    newSsdInfo
  );
  let newSsdwithFingerprint = {
    ...newSsdInfo,
    fingerprint,
    fingerprintTimestamp,
    fingerprintVersion,
  };

  const { data } = yield call(prepareSsdForCession, newSsdwithFingerprint);
  return { ...newSsdwithFingerprint, ...data };
}
//---------------------------------------------------------------------------
export function* modifyCession({ account, oldSsdInfo, cessionData }) {
  try {
    //------- Post a new SSD with updated fields (fingerprint, darlehensgeber, nominal) but keep id
    const newSsdInfo = yield call(updateNewSsd, cessionData);

    //------- Post a new Cession with required new fields
    cessionData.ssdid_alt = oldSsdInfo.ssdid;
    cessionData.ssd_fingerprint_alt = oldSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_alt = oldSsdInfo.fingerprint_rebuy;

    cessionData.ssdid_neu = newSsdInfo.ssdId.toString();
    cessionData.ssd_fingerprint_neu = newSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_neu = newSsdInfo.fingerprint_rebuy;
    cessionData.idproxy_arranger = newSsdInfo.arranger;
    cessionData.darlehensgeber_alt = oldSsdInfo.darlehensgeber;

    const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(cessionData);

    let cession = yield call(postCession, cessionDataWithFingerprint, oldSsdInfo.ssdid);

    //------- Save the new Cession in Blockchain
    yield call(
      cessionModify,
      account,
      newSsdInfo.arranger,
      toHex(`${oldSsdInfo.ssdid}`),
      toHex(`${cession.data.abtretungId}`),
      dateToTimestamp(cessionData.valuta_abtretung),
      cession.data.abtretungFingerprint,
      newSsdInfo.fingerprint
    );

    //------- Update application state

    yield put(modifyCessionActions.success());

    yield put(
      fetchCessionEventsForSsdActions.request(account, newSsdInfo.arranger, `${oldSsdInfo.ssdid}`)
    );

    yield put(cessionConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wurde_gespeichert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(modifyCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_gespeichert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
function* modifyAndOfferCession({ account, oldSsdInfo, cessionData, fourEyesFlag }) {
  try {
    //------- Post a new SSD with updated fields (fingerprint, darlehensgeber, nominal)

    const newSsdInfo = yield call(updateNewSsd, cessionData);

    cessionData.ssdid_alt = oldSsdInfo.ssdid;
    cessionData.ssd_fingerprint_alt = oldSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_alt = oldSsdInfo.fingerprint_rebuy;

    cessionData.ssdid_neu = newSsdInfo.ssdId.toString();
    cessionData.ssd_fingerprint_neu = newSsdInfo.fingerprint;
    cessionData.ssd_fingerprint_rebuy_neu = newSsdInfo.fingerprint_rebuy;
    cessionData.idproxy_arranger = newSsdInfo.arranger;
    cessionData.darlehensgeber_alt = oldSsdInfo.darlehensgeber;

    const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(cessionData);
    //------- Post a new Cession with required new fields

    let cession = yield call(postCession, cessionDataWithFingerprint, oldSsdInfo.ssdid);

    //------- Save the new Cession in Blockchain
    yield call(
      cessionModifyAndOffer,
      account,
      newSsdInfo.arranger,
      toHex(`${oldSsdInfo.ssdid}`),
      toHex(`${cession.data.abtretungId}`),
      dateToTimestamp(cessionData.valuta_abtretung),
      cession.data.abtretungFingerprint,
      newSsdInfo.fingerprint,
      oldSsdInfo.fingerprint
    );

    //------- Update application state

    yield put(modifyAndOfferCessionActions.success());

    yield put(
      fetchCessionEventsForSsdActions.request(account, newSsdInfo.arranger, `${oldSsdInfo.ssdid}`)
    );

    yield put(cessionConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_gespeichert_und_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(modifyAndOfferCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_cession_abtretung_konnte_nicht_gespeichert_und_bestaetigt_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------

export function* offerCession({
  account,
  arranger,
  ssdId,
  cessionId,
  ssdHash,
  cessionHash,
  confirmationState,
  firstConfirmer,
  externallySettled,
  fourEyesFlag,
}) {
  try {
    if (
      (confirmationState === FourEyesState.CESSION_OFFER_FIRST || !fourEyesFlag) &&
      !externallySettled
    ) {
      yield call(sendGenericMail, {
        template: mailTemplates.abtretung_bestaetigung_zwei,
        sender: contractCache.identityProxy._address,
        geschaeft: ssdId,
        abtretungFingerprint: cessionHash,
        abtretungId: toUtf8(cessionId),
      });
    }
    yield call(
      cessionOffer,
      account,
      arranger,
      firstConfirmer,
      toHex(`${ssdId}`),
      cessionId,
      ssdHash,
      cessionHash
    );
    yield put(offerCessionActions.success());
    yield put(cessionConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(offerCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_bestaetigt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* acceptCession({ account, currentCession, isDataModified, fourEyesFlag }) {
  const {
    cessionId,
    idproxy_arranger,
    ssdid_alt,
    ssdid_neu,
    ssd_fingerprint_alt,
    ssd_fingerprint_neu,
    abtretung_fingerprint,
    ssd_fingerprint_rebuy_neu,
    confirmationState,
    firstConfirmer,
  } = currentCession;
  try {
    if (
      !currentCession.iban_darlehensgeber_zinsen &&
      !currentCession.bic_code_darlehensgeber_zinsen
    ) {
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.redux_cession_bitte_ergaenzen_sie_iban_und_bic)
        )
      );
      yield put(
        acceptCessionActions.failure(
          appIntl().formatMessage(messages.redux_cession_bitte_ergaenzen_sie_iban_und_bic)
        )
      );
      return;
    }

    if (isDataModified) {
      const ssd = yield call(
        fetchSSDDetails,
        ssdid_neu,
        ssd_fingerprint_neu,
        ssd_fingerprint_rebuy_neu,
        idproxy_arranger
      );

      const newSsdData = {
        ...ssd.data,
        anschrift_darlehensgeber: currentCession.anschrift_darlehensgeber,
        telefonnummer_darlehensgeber: currentCession.telefonnummer_darlehensgeber,
        unser_zeichen_darlehensgeber: currentCession.unser_zeichen_darlehensgeber,
        iban_darlehensgeber: currentCession.iban_darlehensgeber_zinsen,
        bic_code_darlehensgeber: currentCession.bic_code_darlehensgeber_zinsen,
        iban_darlehensgeber_tilgung: currentCession.iban_darlehensgeber_tilgung,
        bic_code_darlehensgeber_tilgung: currentCession.bic_code_darlehensgeber_tilgung,
        vertragsreferenz_nr_darlehensgeber: currentCession.vertragsreferenz_darlehensgeber,
        interne_kennung_darlehensgeber: currentCession.interne_kennung_darlehensgeber,
      };

      const newCession = {
        ...currentCession,
      };

      yield call(updateSSD, newSsdData);
      const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(newCession);
      yield call(updateCession, cessionDataWithFingerprint, ssdid_alt);
    }

    if (confirmationState === FourEyesState.CESSION_ACCEPT_FIRST || !fourEyesFlag) {
      yield call(
        cessionAcceptOfferMail,
        idproxy_arranger,
        mailTemplates.abtretung_gegenbestaetigung,
        currentCession.actionInitiator,
        ssdid_alt,
        toUtf8(cessionId),
        abtretung_fingerprint
      );
    }

    yield call(
      cessionAccept,
      account,
      idproxy_arranger,
      firstConfirmer,
      toHex(`${ssdid_alt}`),
      cessionId,
      ssd_fingerprint_alt,
      abtretung_fingerprint
    );
    yield put(acceptCessionActions.success());
    yield put(cessionPreviewModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(acceptCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_bestaetigt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* rejectCession({
  account,
  arranger,
  ssdId,
  cessionId,
  ssdHash,
  cessionHash,
  reason,
  actionInitiator,
}) {
  try {
    const reasonHash = yield getHashFromData(reason);
    yield call(
      rejectCessionPostRejectReason,
      arranger,
      actionInitiator,
      ssdId,
      toUtf8(cessionId),
      cessionHash,
      reason
    );
    yield call(
      cessionReject,
      account,
      arranger,
      toHex(`${ssdId}`),
      cessionId,
      ssdHash,
      cessionHash,
      reasonHash
    );
    yield put(rejectCessionActions.success());
    yield put(cessionPreviewModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wurde_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_abgelehnt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* cancelCession({
  account,
  arranger,
  ssdId,
  cessionId,
  ssdHash,
  cessionHash,
  reason,
}) {
  try {
    const reasonHash = yield getHashFromData(reason);

    yield call(
      cessionCancel,
      account,
      arranger,
      toHex(`${ssdId}`),
      cessionId,
      ssdHash,
      cessionHash,
      reasonHash
    );

    yield put(cancelCessionActions.success());
    yield put(cessionConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wurde_storniert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cancelCessionActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_storniert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* prepareRestSsd(oldSsdInfo, cessions) {
  let cessionsNominalSum = 0;

  for (let cession of cessions) {
    cessionsNominalSum = cessionsNominalSum + cession.abgetretenes_nominal;
  }

  const restNominal = parseFloat(removeDots(oldSsdInfo.nominal)) - cessionsNominalSum;

  const {
    ssdid,
    fingerprint: oldFingerprint,
    nominal,
    urkunden_registernummer,
    created_by_cession,
    ...restSsdInfo // new object without previous properties
  } = oldSsdInfo;

  //set new nominal and Urkundenr and cession flag
  restSsdInfo.nominal = addDotsComma(restNominal);
  restSsdInfo.urkunden_registernummer = oldSsdInfo.urkunden_registernummer + "-001";
  restSsdInfo.created_by_cession = true;
  restSsdInfo.fingerprint_rebuy = ZERO_HASH;

  const { fingerprint, fingerprintTimestamp, fingerprintVersion } = yield generateSsdFingerprint(
    restSsdInfo
  );
  let newRestSsdwithFingerprint = {
    ...restSsdInfo,
    fingerprint,
    fingerprintTimestamp,
    fingerprintVersion,
  };

  let { data } = yield call(prepareSsdForCession, newRestSsdwithFingerprint);

  return { ...newRestSsdwithFingerprint, ...data };
}
//---------------------------------------------------------------------------
export function* sendTerminationMail(arranger, ssdId, ssdHash, recipient, kuendigung, parentId) {
  yield call(sendMail, {
    template: mailTemplates.abtretunganzeige_bei_kuendigung,
    arranger: arranger,
    ssdid: ssdId,
    fingerprint: ssdHash,
    fingerprint_rebuy: ZERO_HASH,
    recipient: recipient,
    kuendigungFingerprint: kuendigung.fingerprint,
    parentId: parentId,
  });
}

export function* sendTerminationCancelledMail(arranger, ssdId, ssdHash, recipient, kuendigung) {
  yield call(sendMail, {
    template: mailTemplates.kuendigung_abgebrochen,
    arranger: arranger,
    ssdid: ssdId,
    fingerprint: ssdHash,
    fingerprint_rebuy: ZERO_HASH,
    recipient: recipient,
    kuendigungFingerprint: kuendigung.fingerprint,
  });
}

//---------------------------------------------------------------------------
export function* checkIfParentTerminationAborted(ssdDetails) {
  const termination = yield call(getTerminationState, ssdDetails.arranger, ssdDetails.ssdid);
  return termination.state === SSDTerminationState.ABORTED;
}
//---------------------------------------------------------------------------
export function* getTerminationTransaction(ssdDetails) {
  try {
    const termination = yield call(getTerminationState, ssdDetails.arranger, ssdDetails.ssdid);
    return termination;
  } catch (err) {
    console.error("no termination transaction found", err);
    return null;
  }
}
//---------------------------------------------------------------------------
export function checkIfParentTerminated(terminationTrx) {
  try {
    if (terminationTrx.hash === ZERO_HASH) {
      return false;
    }
    if (terminationTrx.state === SSDTerminationState.APPROVED) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error("error when checking termination transaction", err);
    return false;
  }
}
//---------------------------------------------------------------------------
export function* getParentTermination(terminationHash, ssdDetails) {
  try {
    if (terminationHash === ZERO_HASH) {
      return null;
    }
    const kuendigung = yield call(
      getKuendigung,
      ssdDetails.arranger,
      ssdDetails.ssdid,
      terminationHash
    );
    return kuendigung;
  } catch (err) {
    console.error("no termination found for hash " + terminationHash, err);
    return null;
  }
}
//---------------------------------------------------------------------------
export function* postTerminationForSsd(
  arranger,
  ssdId,
  parentTermination,
  parentId,
  zedentPermission
) {
  let termination = {
    ...parentTermination,
    rueckzahlung: addDotsComma(parentTermination.rueckzahlung),
    rueckzahlungsbetrag: addDotsComma(parentTermination.rueckzahlungsbetrag),
    zinsbetrag: addDotsComma(parentTermination.zinsbetrag),
    ssdId: ssdId,
  };

  if (zedentPermission) {
    yield call(postKuendigungZedent, arranger, ssdId, parentId, termination);
  } else {
    yield call(postKuendigung, arranger, ssdId, termination);
  }
}
//---------------------------------------------------------------------------

export function* connectCessionPartner({ cession }) {
  if (!cession.abtretung_extern) {
    const dn = yield call(getGpFromPartners, cession.idproxy_arranger);
    const zessionar = yield call(getGpFromPartners, cession.darlehensgeber);
    //dn -> zesionar
    const connectedZessionar = yield call(connectGp, cession.idproxy_arranger, zessionar);
    if (connectedZessionar !== false) {
      yield call(sendGenericMail, {
        sender: cession.darlehensgeber_alt,
        recipient: cession.idproxy_arranger,
        template: mailTemplates.gp_zuordnung_bei_abtretung,
        firmenname: zessionar.name,
        lei: zessionar.lei,
        digitsKennung: zessionar.digitsKennung,
      });
    }
    //zesionar -> dn
    const connectedtDN = yield call(connectGp, cession.darlehensgeber, dn);
    if (connectedtDN !== false) {
      yield call(sendGenericMail, {
        sender: cession.darlehensgeber_alt,
        recipient: cession.darlehensgeber,
        template: mailTemplates.gp_zuordnung_bei_abtretung,
        firmenname: dn.name,
        lei: dn.lei,
        digitsKennung: dn.digitsKennung,
      });
    }
  }
}

//---------------------------------------------------------------------------
export function* notifyCession({
  account,
  arranger,
  ssdId,
  ssdHash,
  cessionsToNotify,
  cessionToMove,
  ssdDetails,
}) {
  try {
    //try to connect cession partner
    yield all(
      cessionsToNotify.map((cession) => {
        return call(connectCessionPartner, { cession });
      })
    );

    // fetching termination transaction and entity
    // check if parent is terminated
    const terminationTrx = yield call(getTerminationTransaction, ssdDetails);
    const isParentTerminated = yield call(checkIfParentTerminated, terminationTrx);
    const parentTermination = yield call(getParentTermination, terminationTrx.hash, ssdDetails);

    let cessionIds = [];
    let cessionHashes = [];
    for (const cession of cessionsToNotify) {
      if (isParentTerminated) {
        yield call(
          postTerminationForSsd,
          cession.idproxy_arranger,
          cession.ssdid_neu,
          parentTermination,
          ssdDetails.ssdid,
          true
        );
        yield call(
          sendTerminationMail,
          arranger,
          cession.ssdid_neu,
          cession.ssd_fingerprint_neu,
          cession.darlehensgeber,
          parentTermination,
          ssdDetails.ssdid
        );
      }

      //------- create Cession Anzeige PDF
      yield call(
        createCessionAnzeige,
        cession.darlehensgeber_alt,
        cession.abtretung_id,
        cession.abtretung_fingerprint,
        ssdDetails
      );

      cessionIds.push(toHex(`${cession.abtretung_id}`));
      cessionHashes.push(cession.abtretung_fingerprint);
    }
    let count = 0;
    for (const cessionId of cessionIds) {
      yield call(
        cessionNotifyMail,
        mailTemplates.abtretung_anzeige,
        arranger,
        toUtf8(ssdId),
        toUtf8(cessionId),
        cessionHashes[count],
        contractCache.identityProxy._address
      );
      count++;
    }

    const restSsd = yield call(prepareRestSsd, ssdDetails, cessionsToNotify);

    for (const cession of cessionToMove) {
      let cessionData = { ...cession };

      cessionData.abgetretenes_nominal = addDotsComma(cessionData.abgetretenes_nominal);
      cessionData.abtretungskurs = addDotsComma(cessionData.abtretungskurs);
      cessionData.gegenwert_abtretung = addDotsComma(cessionData.gegenwert_abtretung);
      cessionData.stueckzinsen = addDotsComma(cessionData.stueckzinsen);
      cessionData.ssdid_alt = restSsd.ssdId;
      cessionData.ssd_fingerprint_alt = restSsd.fingerprint;

      const cessionDataWithFingerprint = yield generateSsdCessionFingerprint(cessionData);
      yield call(postCession, cessionDataWithFingerprint, toUtf8(ssdId));
    }

    yield call(
      cessionNotify,
      account,
      arranger,
      ssdId,
      ssdHash,
      toHex(`${restSsd.ssdId}`),
      restSsd.fingerprint,
      cessionIds,
      cessionHashes
    );

    //post Termination for Rest-SSD
    if (isParentTerminated) {
      yield call(
        postTerminationForSsd,
        restSsd.arranger,
        restSsd.ssdId,
        parentTermination,
        ssdDetails.ssdid,
        false
      );
    }

    const terminationWasCancelled = yield call(checkIfParentTerminationAborted, ssdDetails);
    if (terminationWasCancelled) {
      yield call(
        sendTerminationCancelledMail,
        arranger,
        ssdDetails.ssdid,
        ssdDetails.fingerprint,
        arranger,
        parentTermination
      );
    }

    yield put(cessionNotifyActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretung_wird_angezeigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cessionNotifyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_cession_abtretung_konnte_nicht_angezeigt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* processNotification({
  account,
  arranger,
  ssdId,
  cessionIds,
  cessionExpectedHashes,
  newSsdIds,
  newSsdHashes,
  urkunden_registernummer,
  cessions,
}) {
  try {
    const { restId, restHash, restValueDate } = yield call(
      getSSDCessionData,
      arranger,
      toUtf8(ssdId)
    );

    yield call(
      cessionProcessNotification,
      account,
      arranger,
      ssdId,
      restId,
      restHash,
      restValueDate,
      cessionIds,
      cessionExpectedHashes,
      newSsdIds,
      newSsdHashes
    );

    yield call(getAllPartnerInfo, arranger);

    yield put(cessionProcessNotificationActions.success());

    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_cession_abtretungszertifikate_wurden_erstellt)
      )
    );

    history.push(`/bssdoverview?filter=${urkunden_registernummer}`);
  } catch (error) {
    console.error(error);
    yield put(cessionProcessNotificationActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_cession_abtretungszertifikate_konnten_nicht_erstellt_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* downloadCessionAnzeige({
  darlehensnehmer,
  cessionInitiator,
  ssdId,
  cessionId,
  cessionHash,
  oldSsd,
}) {
  try {
    yield call(getCessionAnzeige, darlehensnehmer, cessionInitiator, ssdId, cessionId);
    yield put(downloadCessionAnzeigePdfActions.success());
  } catch (error) {
    console.error(error);
    yield put(downloadCessionAnzeigePdfActions.failure(error));
  }
}
//---------------------------------------------------------------------------
export function* downloadCessionConfirmation({
  cessionInitiator,
  ssdId,
  cessionId,
  cessionHash,
  cessionInfo,
  ssdDetails,
}) {
  try {
    yield call(
      getCessionConfirmation,
      cessionInitiator,
      ssdId,
      cessionId,
      cessionHash,
      cessionInfo,
      ssdDetails
    );
    yield put(downloadCessionConfirmationPdfActions.success());
  } catch (error) {
    console.error(error);
    yield put(downloadCessionConfirmationPdfActions.failure(error));
  }
}
