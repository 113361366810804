import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  FETCH_CSSD_HISTORY_REQUEST,
  FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST,
  FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST,
} from "./actions";
import {
  fetchCssdHistory as fetchCssdHistoryFromBE,
  fetchCssdSettlementHistory as fetchCssdSettlementHistoryFromBE,
  fetchRejectReason,
  fetchDocumentRejectReason,
  queryTeilforderungDetails,
  fetchTerminationHistory,
  fetchCssdKaufHistory,
} from "services/cssdService";
import {
  fetchCssdCessionHistory as fetchCssdCessionHistoryFromBE,
  getCession,
  fetchCssdInterestNotificationHistory,
} from "services/cssdCessionService";
import {
  getCssdAuditEvents,
  getCssdFourEyesEvents,
  getCssdCessionAuditEventByCessionId,
  getCssdCessionAuditEventByPartialClaimId,
  getCSSDInterestNotificationAuditEvent,
  getConnectGPEvents,
} from "services/web3Services/cssd";
import {
  toHex,
  getIdProxyAddr,
  getTimestampFromBlock,
  toUtf8,
} from "services/web3Services/commons";
import { getAccountInfo } from "services/accountService";
import { convertTimestampToDate, convertTimestampToTime } from "util/convertor";
import {
  FourEyesState,
  CSSDState,
  CSSDAction,
  CSSDCessionState,
  CSSDCessionAction,
  CSSDInterestNotificationState,
  CSSDInterestNotificationAction,
  CSSDTerminationState,
  CSSDTerminationAction,
  PartialClaimState,
  zeroAddress,
  PartialClaimAction,
} from "util/constants";
import {
  messages,
  fourEyesStateDescriptions,
  cssdStateDescriptions,
  cssdActionDescriptions,
  cssdCessionActionDescriptions,
  cssdCessionStateDescriptions,
  cssdPartialClaimStateDescriptions,
  cssdPartialClaimActionDescriptions,
  cssdInterestNotificationStateDescriptions,
  cssdInterestNotificationActionDescriptions,
  cssdTerminationStateDescriptions,
  cssdTerminationActionDescriptions,
} from "./messages";
import { appIntl } from "components/i18n/intl";
import {
  fetchCssdHistoryActions,
  fetchPartialClaimCessionHistoryActions,
  fetchInterestNotificationHistoryActions,
} from "./actions";
import { snackbarActions } from "redux/shared/actions";
import { partnerCache } from "services/partnerService";
import { ZERO_HASH } from "util/constants";
import { GeschaeftsvorfallTyp } from "util/constants";
import { getGpFromPartners } from "services/partnerService";

const CSSDAuditEventTypes = {
  CESSION: "0",
  PARTIAL_CLAIM: "1",
  CSSD: "2",
  TERMINATION: "3",
};

const eventType = {
  FOUREYES: "FOUREYES",
  CSSD_AUDIT: "CSSD_AUDIT",
  CSSD_CESSION_AUDIT: "CSSD_CESSION_AUDIT",
  CSSD_INTEREST_NOTIFICATION_AUDIT: "CSSD_INTEREST_NOTIFICATION_AUDIT",
  CSSD_TERMINATION_AUDIT: "CSSD_TERMINATION_AUDIT",
};

const eventPriority = {
  HIGHEST: -1,
  HIGH: 0,
  MID: 1,
  LOW: 2,
  LOWEST: 3,
};

const auditHashes = {
  OLD_HASH: 0,
  NEW_HASH: 1,
  OLD_BUY_HASH: 2,
  NEW_BUY_HASH: 3,
  OLD_DN_HASH: 4,
  NEW_DN_HASH: 5,
  OLD_PAYING_AGENT_HASH: 6,
  NEW_PAYING_AGENT_HASH: 7,
  OLD_DG_HASH: 8,
  NEW_DG_HASH: 9,
  REASON_HASH: 10,
  OLD_TERMINATION_HASH: 11,
  NEW_TERMINATION_HASH: 12,
};

const auditPartialClaimCessionHashes = {
  OLD_CESSION_HASH: 0,
  NEW_CESSION_HASH: 1,
  OLD_SETTLEMENT_HASH: 2,
  NEW_SETTLEMENT_HASH: 3,
  REASON_HASH: 4,
  OLD_TERMINATION_HASH: 5,
  NEW_TERMINATION_HASH: 6,
};

const auditHashKeyNames = {
  OLD_HASH: "oldFingerprint",
  NEW_HASH: "newFingerprint",
  OLD_BUY_HASH: "oldBuyFingerprint",
  NEW_BUY_HASH: "newBuyFingerprint",
  OLD_DN_HASH: "oldSellerFingerprint",
  NEW_DN_HASH: "newSellerFingerprint",
  OLD_PAYING_AGENT_HASH: "oldPayingAgentFingerprint",
  NEW_PAYING_AGENT_HASH: "newPayingAgentFingerprint",
  OLD_DG_HASH: "oldBuyerFingerprint",
  NEW_DG_HASH: "newBuyerFingerprint",
  REASON_HASH: "reasonHash",

  OLD_CESSION_HASH: "oldCessionHash",
  NEW_CESSION_HASH: "cessionHash",
  OLD_CESSION_SETTLEMENT_HASH: "oldSettlementDataHash",
  NEW_CESSION_SETTLEMENT_HASH: "settlementDataHash",

  OLD_INTEREST_NOTIFICATION_HASH: "oldHash",
  NEW_INTEREST_NOTIFICATION_HASH: "newHash",

  OLD_TERMINATION_HASH: "oldTerminationHash",
  NEW_TERMINATION_HASH: "newTerminationHash",
};

const auditChangeTypes = {
  CSSD: "CSSD",
  CSSD_BUY: "CSSD_BUY",
  SETTLEMENT_DN: "SETTLEMENT_DN",
  SETTLEMENT_DG: "SETTLEMENT_DG",
  SETTLEMENT_PAYING_AGENT: "SETTLEMENT_PAYING_AGENT",
  CSSD_CESSION: "CSSD_CESSION",
  CSSD_CESSION_NEW_DG: "CSSD_CESSION_NEW_DG",
  CSSD_INTEREST_NOTIFICATION: "CSSD_INTEREST_NOTIFICATION",
  CSSD_TERMINATION: "CSSD_TERMINATION",
};

const NAME_CACHE = [];

export const cssdAuditSagas = [
  takeLatest(FETCH_CSSD_HISTORY_REQUEST, fetchCssdHistory),
  takeLatest(FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST, fetchPartialClaimCessionHistory),
  takeLatest(FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST, fetchInterestNotificationCessionHistory),
];

const getFourEyesEventPrio = ({ state }) => {
  if (
    state === FourEyesState.OFFER_CANCELLED ||
    state === FourEyesState.OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.ACCEPT_CANCELLED ||
    state === FourEyesState.ACCEPT_CANCELLED_ON_REJECT ||
    state === FourEyesState.CESSION_OFFER_CANCELLED ||
    state === FourEyesState.CESSION_OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED_ON_REJECT ||
    state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED ||
    state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.TERMINATION_APPROVE_CANCELLED ||
    state === FourEyesState.TERMINATION_APPROVE_CANCELLED_ON_MODIFY ||
    state === FourEyesState.TERMINATION_APPROVE_CANCELLED_ON_DELETE ||
    state === FourEyesState.CONNECT_GP_FIRST ||
    state === FourEyesState.CONNECT_GP_SECOND
  )
    return eventPriority.HIGHEST;
  return eventPriority.MID;
};

const getAuditEventPrio = (type, { state, action, auditType }) => {
  if (type === eventType.CSSD_AUDIT) {
    if (Number(state) === CSSDState.NEW) return eventPriority.HIGH;
    if (Number(action) === CSSDAction.MODIFY) return eventPriority.HIGH;
  }
  if (type === eventType.CSSD_CESSION_AUDIT) {
    if (auditType === CSSDAuditEventTypes.PARTIAL_CLAIM) {
      if (Number(state) === PartialClaimState.NEW) return eventPriority.HIGH;
      if (Number(state) === PartialClaimState.IN_WORK) return eventPriority.MID;
    }
    if (Number(state) === CSSDCessionState.NEW) return eventPriority.HIGH;
    if (Number(action) === CSSDCessionAction.MODIFY) return eventPriority.HIGH;
  }
  if (type === eventType.CSSD_INTEREST_NOTIFICATION_AUDIT) {
    if (Number(state) === CSSDInterestNotificationState.NEW) return eventPriority.HIGH;
    if (Number(action) === CSSDInterestNotificationAction.MODIFY) return eventPriority.HIGH;
  }
  if (type === eventType.CSSD_TERMINATION_AUDIT) {
    if (Number(state) === CSSDTerminationState.NEW) return eventPriority.HIGH;
    if (Number(action) === CSSDTerminationAction.MODIFY) return eventPriority.HIGH;
  }
  return eventPriority.LOWEST;
};

const sortAuditEvents = (a, b) => {
  if (a.blockNumber === b.blockNumber) return b.prio - a.prio;
  return b.blockNumber - a.blockNumber;
};

const formatCssdAuditEvent = (blockNumber, type, returnValues) => {
  return {
    ...returnValues,
    blockNumber,
    type,
    [auditHashKeyNames.OLD_HASH]: returnValues.hashes[auditHashes.OLD_HASH],
    [auditHashKeyNames.NEW_HASH]: returnValues.hashes[auditHashes.NEW_HASH],
    [auditHashKeyNames.OLD_BUY_HASH]: returnValues.hashes[auditHashes.OLD_BUY_HASH],
    [auditHashKeyNames.NEW_BUY_HASH]: returnValues.hashes[auditHashes.NEW_BUY_HASH],
    [auditHashKeyNames.OLD_DN_HASH]: returnValues.hashes[auditHashes.OLD_DN_HASH],
    [auditHashKeyNames.NEW_DN_HASH]: returnValues.hashes[auditHashes.NEW_DN_HASH],
    [auditHashKeyNames.OLD_PAYING_AGENT_HASH]:
      returnValues.hashes[auditHashes.OLD_PAYING_AGENT_HASH],
    [auditHashKeyNames.NEW_PAYING_AGENT_HASH]:
      returnValues.hashes[auditHashes.NEW_PAYING_AGENT_HASH],
    [auditHashKeyNames.OLD_DG_HASH]: returnValues.hashes[auditHashes.OLD_DG_HASH],
    [auditHashKeyNames.NEW_DG_HASH]: returnValues.hashes[auditHashes.NEW_DG_HASH],
    [auditHashKeyNames.REASON_HASH]: returnValues.hashes[auditHashes.REASON_HASH],
    [auditHashKeyNames.OLD_TERMINATION_HASH]: returnValues.hashes[auditHashes.OLD_TERMINATION_HASH],
    [auditHashKeyNames.NEW_TERMINATION_HASH]: returnValues.hashes[auditHashes.NEW_TERMINATION_HASH],
    state: returnValues.state,
    action: returnValues.action,
    triggeredBy: returnValues.triggeredBy,
    triggeredByIdProxy: returnValues.triggeredByIdProxy,
    prio: getAuditEventPrio(type, returnValues),
    payingAgent: returnValues.payingAgent,
    cssdId: returnValues.cssdId,
  };
};

const formatCssdCessionAuditEvent = (blockNumber, type, returnValues) => {
  return {
    ...returnValues,
    [auditHashKeyNames.OLD_CESSION_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.OLD_CESSION_HASH],
    [auditHashKeyNames.NEW_CESSION_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.NEW_CESSION_HASH],
    [auditHashKeyNames.OLD_CESSION_SETTLEMENT_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.OLD_SETTLEMENT_HASH],
    [auditHashKeyNames.NEW_CESSION_SETTLEMENT_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.NEW_SETTLEMENT_HASH],
    [auditHashKeyNames.OLD_TERMINATION_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.OLD_TERMINATION_HASH],
    [auditHashKeyNames.NEW_TERMINATION_HASH]:
      returnValues.hashes[auditPartialClaimCessionHashes.NEW_TERMINATION_HASH],
    blockNumber,
    type,
    prio: getAuditEventPrio(type, returnValues),
  };
};

const formatCssdInterestNotificationAuditEvent = (blockNumber, type, returnValues) => {
  if (returnValues.hashes) {
    return {
      ...returnValues,
      [auditHashKeyNames.OLD_CESSION_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.OLD_CESSION_HASH],
      [auditHashKeyNames.NEW_CESSION_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.NEW_CESSION_HASH],
      [auditHashKeyNames.OLD_CESSION_SETTLEMENT_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.OLD_SETTLEMENT_HASH],
      [auditHashKeyNames.NEW_CESSION_SETTLEMENT_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.NEW_SETTLEMENT_HASH],
      [auditHashKeyNames.REASON_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.REASON_HASH],
      [auditHashKeyNames.OLD_TERMINATION_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.OLD_TERMINATION_HASH],
      [auditHashKeyNames.NEW_TERMINATION_HASH]:
        returnValues.hashes[auditPartialClaimCessionHashes.NEW_TERMINATION_HASH],
      blockNumber,
      type,
      prio: getAuditEventPrio(type, returnValues),
    };
  } else {
    return {
      ...returnValues,
      blockNumber,
      type,
      prio: getAuditEventPrio(type, returnValues),
    };
  }
};

const mergeAuditLogFromEvents = (auditEvents, type, fourEyesEvents) => {
  const auditChanges = auditEvents.map(({ returnValues, blockNumber }) => {
    switch (type) {
      case eventType.CSSD_AUDIT:
        return formatCssdAuditEvent(blockNumber, type, returnValues);
      case eventType.CSSD_CESSION_AUDIT:
        return formatCssdCessionAuditEvent(blockNumber, type, returnValues);
      case eventType.CSSD_INTEREST_NOTIFICATION_AUDIT:
        return formatCssdInterestNotificationAuditEvent(blockNumber, type, returnValues);
      default:
        return {};
    }
  });

  const fourEyesChanges = fourEyesEvents.map(({ returnValues, blockNumber }) => {
    return {
      blockNumber,
      type: eventType.FOUREYES,
      state: returnValues.state,
      triggeredBy: returnValues.account,
      prio: getFourEyesEventPrio(returnValues),
    };
  });
  return [...fourEyesChanges, ...auditChanges].sort(sortAuditEvents);
};

function* getCssdAuditVersionDiffs(
  payingAgentAddr,
  id,
  subdId = null,
  auditEvents,
  oldHash,
  newHash,
  callback
) {
  const transactionChanges = auditEvents.map(({ returnValues }) => {
    return {
      oldFingerprint: returnValues.hashes[oldHash],
      newFingerprint: returnValues.hashes[newHash],
    };
  });
  if (transactionChanges.length === 0) return { transactionChanges: [] };
  const post = {
    transactionChanges,
  };
  const { data } = yield call(callback, payingAgentAddr, id, subdId, post);
  return data;
}

function* getAuditVersionDiffs(
  payingAgentAddr,
  cssdId,
  id,
  auditEvents,
  oldHash,
  newHash,
  callback
) {
  const transactionChanges = auditEvents.map(({ returnValues }) => {
    return {
      oldFingerprint: returnValues[oldHash],
      newFingerprint: returnValues[newHash],
    };
  });
  if (transactionChanges.length === 0) return { transactionChanges: [] };
  const post = {
    transactionChanges,
  };
  const { data } = yield call(callback, payingAgentAddr, cssdId, id, post);
  return data;
}

const getAuditChangesForLogEntry = (
  serverChange,
  transactionChanges,
  oldFingerprintKey,
  newFingerprintKey
) => {
  if (serverChange.type === eventType.FOUREYES) return null;
  return transactionChanges.find(
    (txChange) =>
      txChange.oldFingerprint === serverChange[oldFingerprintKey] &&
      txChange.newFingerprint === serverChange[newFingerprintKey] &&
      txChange.changes?.length > 0
  )?.changes;
};

const getActorName = (audit) => {
  const cachedName = NAME_CACHE.find((entry) => entry.account === audit.triggeredBy);
  if (cachedName) {
    return cachedName.name;
  }
  const partner = partnerCache.find(
    (partner) => partner.idProxyAddress === audit.triggeredByIdProxy
  );
  if (partner) {
    return partner.name;
  }
  return appIntl().formatMessage(messages.no_name_found);
};

function* getRejectReason({ payingAgent, cssdId, hashes, type, action }) {
  let reasonHash;
  if (hashes) {
    reasonHash =
      type === eventType.CSSD_CESSION_AUDIT
        ? hashes[auditPartialClaimCessionHashes.REASON_HASH]
        : hashes[auditHashes.REASON_HASH];
  }
  if (
    reasonHash &&
    reasonHash !== ZERO_HASH &&
    (type === eventType.CSSD_AUDIT || type === eventType.CSSD_CESSION_AUDIT) &&
    Number(action) === CSSDAction.REJECT_DOCUMENTS
  ) {
    const { data } = yield call(
      fetchDocumentRejectReason,
      payingAgent,
      Number(toUtf8(cssdId)),
      reasonHash
    );
    return data.ablehnungsgrund;
  }
  if (
    reasonHash &&
    reasonHash !== ZERO_HASH &&
    (type === eventType.CSSD_AUDIT || type === eventType.CSSD_CESSION_AUDIT) &&
    Number(action) === CSSDAction.REJECT_OFFER
  ) {
    try {
      const { data } = yield call(
        fetchRejectReason,
        payingAgent,
        Number(toUtf8(cssdId)),
        reasonHash,
        type === eventType.CSSD_AUDIT ? GeschaeftsvorfallTyp.KAUF : GeschaeftsvorfallTyp.ABTRETUNG
      );
      return data.ablehnungsgrund;
    } catch (error) {
      return null;
    }
  }
  return null;
}

function* getConnectedGPInfo({ connectedGPAddr }) {
  if (connectedGPAddr && connectedGPAddr !== zeroAddress) {
    try {
      const partner = yield call(getGpFromPartners, connectedGPAddr);
      return partner;
    } catch (error) {
      return null;
    }
  }
  return null;
}

const getAuditDescription = ({ type, state, action, auditType }) => {
  try {
    if (type === eventType.FOUREYES) {
      return `– ${appIntl().formatMessage(fourEyesStateDescriptions[state])}`;
    }
    if (type === eventType.CSSD_AUDIT) {
      if (auditType === CSSDAuditEventTypes.TERMINATION) {
        if (Number(state) === CSSDTerminationState.NEW) {
          return appIntl().formatMessage(cssdTerminationStateDescriptions[state]);
        }
        return appIntl().formatMessage(cssdTerminationActionDescriptions[action]);
      }
      if (Number(state) === CSSDState.NEW) {
        return appIntl().formatMessage(cssdStateDescriptions[state]);
      }
      return appIntl().formatMessage(cssdActionDescriptions[action]);
    }
    if (type === eventType.CSSD_CESSION_AUDIT) {
      if (auditType === CSSDAuditEventTypes.TERMINATION) {
        if (Number(state) === CSSDTerminationState.NEW) {
          return appIntl().formatMessage(cssdTerminationStateDescriptions[state]);
        }
        return appIntl().formatMessage(cssdTerminationActionDescriptions[action]);
      }
      if (auditType === CSSDAuditEventTypes.PARTIAL_CLAIM) {
        if (Number(state) === PartialClaimState.CANCELLED) {
          return appIntl().formatMessage(cssdPartialClaimActionDescriptions[action]);
        }
        if (
          Number(action) === PartialClaimAction.CONNECT ||
          Number(action) === PartialClaimAction.CANCEL_CONNECT
        ) {
          return appIntl().formatMessage(cssdPartialClaimActionDescriptions[action]);
        }
        if (Number(action) === PartialClaimAction.UPDATE) {
          return appIntl().formatMessage(cssdPartialClaimActionDescriptions[action]);
        }
        return appIntl().formatMessage(cssdPartialClaimStateDescriptions[state]);
      }
      if (Number(state) === CSSDCessionState.NEW) {
        return appIntl().formatMessage(cssdCessionStateDescriptions[state]);
      }
      return appIntl().formatMessage(cssdCessionActionDescriptions[action]);
    }
    if (type === eventType.CSSD_INTEREST_NOTIFICATION_AUDIT) {
      if (Number(state) === CSSDInterestNotificationState.NEW) {
        return appIntl().formatMessage(cssdInterestNotificationStateDescriptions[state]);
      }
      return appIntl().formatMessage(cssdInterestNotificationActionDescriptions[action]);
    }
  } catch {
    return `${appIntl().formatMessage(
      messages.no_description_found
    )} (type: ${type}, state: ${state}, action: ${action})`;
  }
};

const mergeChangeArrays = (...arrays) => {
  let combined = [].concat(...arrays.filter(Array.isArray));
  return combined.length === 0 ? null : combined;
};

function* buildCssdAuditEntry(auditChange, transactionChanges) {
  const [
    description,
    changes,
    changesBuy,
    changesDN,
    changesZS,
    changesTermination,
    timestamp,
    name,
    rejectReason,
    connectedGPInfo,
  ] = yield all([
    call(getAuditDescription, auditChange),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD],
      auditHashKeyNames.OLD_HASH,
      auditHashKeyNames.NEW_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_BUY],
      auditHashKeyNames.OLD_BUY_HASH,
      auditHashKeyNames.NEW_BUY_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.SETTLEMENT_DN],
      auditHashKeyNames.OLD_DN_HASH,
      auditHashKeyNames.NEW_DN_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.SETTLEMENT_PAYING_AGENT],
      auditHashKeyNames.OLD_PAYING_AGENT_HASH,
      auditHashKeyNames.NEW_PAYING_AGENT_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_TERMINATION],
      auditHashKeyNames.OLD_TERMINATION_HASH,
      auditHashKeyNames.NEW_TERMINATION_HASH
    ),
    call(getTimestampFromBlock, auditChange.blockNumber),
    call(getActorName, auditChange),
    call(getRejectReason, auditChange),
    call(getConnectedGPInfo, auditChange),
  ]);

  return {
    ...auditChange,
    changes: mergeChangeArrays(changes, changesBuy),
    changesDN,
    changesZS,
    changesTermination,
    date: convertTimestampToDate(timestamp),
    time: convertTimestampToTime(timestamp),
    name,
    description,
    rejectReason,
    connectedGPInfo,
  };
}

const filterCessionChanges = (isDn, cessionChanges) => {
  if (cessionChanges && isDn) {
    return cessionChanges.map((entry) => {
      if (entry.field === "kurs") {
        return {
          ...entry,
          field: "",
          newValue: appIntl().formatMessage(messages.notShown),
          oldValue: "",
        };
      }
      return entry;
    });
  }
  return cessionChanges;
};

function* buildCssdCessionAuditEntry(auditChange, transactionChanges, isDn) {
  const [
    description,
    changesCession,
    changesNewDg,
    changesTermination,
    timestamp,
    name,
    rejectReason,
    connectedGPInfo,
  ] = yield all([
    call(getAuditDescription, auditChange),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_CESSION],
      auditHashKeyNames.OLD_CESSION_HASH,
      auditHashKeyNames.NEW_CESSION_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_CESSION_NEW_DG],
      auditHashKeyNames.OLD_CESSION_SETTLEMENT_HASH,
      auditHashKeyNames.NEW_CESSION_SETTLEMENT_HASH
    ),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_TERMINATION],
      auditHashKeyNames.OLD_TERMINATION_HASH,
      auditHashKeyNames.NEW_TERMINATION_HASH
    ),
    call(getTimestampFromBlock, auditChange.blockNumber),
    call(getActorName, auditChange),
    call(getRejectReason, auditChange),
    call(getConnectedGPInfo, auditChange),
  ]);
  return {
    ...auditChange,
    changesCession: filterCessionChanges(isDn, changesCession),
    changesNewDg,
    changesTermination,
    date: convertTimestampToDate(timestamp),
    time: convertTimestampToTime(timestamp),
    name,
    description,
    rejectReason,
    connectedGPInfo,
  };
}

function* buildCssdInterestNotificationAuditEntry(auditChange, transactionChanges) {
  const [description, changesInterestNotification, timestamp, name, rejectReason] = yield all([
    call(getAuditDescription, auditChange),
    call(
      getAuditChangesForLogEntry,
      auditChange,
      transactionChanges[auditChangeTypes.CSSD_INTEREST_NOTIFICATION],
      auditHashKeyNames.OLD_INTEREST_NOTIFICATION_HASH,
      auditHashKeyNames.NEW_INTEREST_NOTIFICATION_HASH
    ),
    call(getTimestampFromBlock, auditChange.blockNumber),
    call(getActorName, auditChange),
    call(getRejectReason, auditChange),
  ]);
  return {
    ...auditChange,
    changesInterestNotification,
    date: convertTimestampToDate(timestamp),
    time: convertTimestampToTime(timestamp),
    name,
    description,
    rejectReason,
  };
}

function* fetchNameUpdateCache(triggeredBy) {
  try {
    const myIdProxyAddr = getIdProxyAddr();
    const { data } = yield call(getAccountInfo, triggeredBy, myIdProxyAddr);
    NAME_CACHE.push({ account: triggeredBy, name: data.benutzerkennung });
    return data.benutzerkennung;
  } catch {
    return appIntl().formatMessage(messages.no_name_found);
  }
}

function* buildNameCache(auditChanges) {
  const myIdProxyAddr = getIdProxyAddr();
  const maList = [
    ...new Set(
      auditChanges
        .filter(
          (change) =>
            change.triggeredByIdProxy === undefined || change.triggeredByIdProxy === myIdProxyAddr
        )
        .map((change) => change.triggeredBy)
    ),
  ];
  yield all(maList.map((ma) => call(fetchNameUpdateCache, ma)));
}

function* buildAuditLog(auditChanges, transactionChanges, type, isDn = false) {
  yield call(buildNameCache, auditChanges);
  switch (type) {
    case eventType.CSSD_AUDIT:
      return yield all(
        auditChanges.map((auditChange) =>
          call(buildCssdAuditEntry, auditChange, transactionChanges)
        )
      );
    case eventType.CSSD_CESSION_AUDIT:
      return yield all(
        auditChanges.map((auditChange) =>
          call(buildCssdCessionAuditEntry, auditChange, transactionChanges, isDn)
        )
      );
    case eventType.CSSD_INTEREST_NOTIFICATION_AUDIT:
      return yield all(
        auditChanges.map((auditChange) =>
          call(buildCssdInterestNotificationAuditEntry, auditChange, transactionChanges)
        )
      );
    default:
      return [];
  }
}

function* getCssdAuditChanges(cssd, cssdAuditEvents) {
  const { transactionChanges: cssdDiff } = yield call(
    getCssdAuditVersionDiffs,
    cssd.zahlstelle.idProxyAdresse,
    cssd.cssdId,
    null,
    cssdAuditEvents,
    auditHashes.OLD_HASH,
    auditHashes.NEW_HASH,
    fetchCssdHistoryFromBE
  );

  const { transactionChanges: cssdKaufDiff } = yield call(
    getCssdAuditVersionDiffs,
    cssd.zahlstelle.idProxyAdresse,
    cssd.cssdId,
    cssd.kauf.kaufId,
    cssdAuditEvents,
    auditHashes.OLD_BUY_HASH,
    auditHashes.NEW_BUY_HASH,
    fetchCssdKaufHistory
  );

  const { transactionChanges: dgDiff } = yield call(
    getCssdAuditVersionDiffs,
    cssd.zahlstelle.idProxyAdresse,
    cssd.cssdId,
    cssd.darlehensgeber.cssdAbwicklungsinformationenId,
    cssdAuditEvents,
    auditHashes.OLD_DG_HASH,
    auditHashes.NEW_DG_HASH,
    fetchCssdSettlementHistoryFromBE
  );

  const { transactionChanges: dnDiff } = yield call(
    getCssdAuditVersionDiffs,
    cssd.zahlstelle.idProxyAdresse,
    cssd.cssdId,
    cssd.darlehensnehmer.cssdAbwicklungsinformationenId,
    cssdAuditEvents,
    auditHashes.OLD_DN_HASH,
    auditHashes.NEW_DN_HASH,
    fetchCssdSettlementHistoryFromBE
  );

  const { transactionChanges: payingAgentDiff } = yield call(
    getCssdAuditVersionDiffs,
    cssd.zahlstelle.idProxyAdresse,
    cssd.cssdId,
    cssd.zahlstelle.cssdAbwicklungsinformationenId,
    cssdAuditEvents,
    auditHashes.OLD_PAYING_AGENT_HASH,
    auditHashes.NEW_PAYING_AGENT_HASH,
    fetchCssdSettlementHistoryFromBE
  );

  let terminationDiffs = [];
  if (cssd.kuendigungen?.length >= 1) {
    terminationDiffs = yield all(
      cssd.kuendigungen.map((termination) =>
        call(
          getCssdAuditVersionDiffs,
          cssd.zahlstelle.idProxyAdresse,
          termination.cssdId,
          termination.kuendigungId,
          cssdAuditEvents,
          auditHashes.OLD_TERMINATION_HASH,
          auditHashes.NEW_TERMINATION_HASH,
          fetchTerminationHistory
        )
      )
    );
  }
  return {
    [auditChangeTypes.CSSD]: cssdDiff,
    [auditChangeTypes.CSSD_BUY]: cssdKaufDiff,
    [auditChangeTypes.SETTLEMENT_DG]: dgDiff,
    [auditChangeTypes.SETTLEMENT_DN]: dnDiff,
    [auditChangeTypes.SETTLEMENT_PAYING_AGENT]: payingAgentDiff,
    [auditChangeTypes.CSSD_TERMINATION]: terminationDiffs
      .map((entry) => entry.transactionChanges)
      .flat(),
  };
}

function* getCssdCessionAuditChanges(partialClaim, cession, cssdCessionAuditEvents) {
  let cssdCessionDiff = null;
  let cssdCessionSettlementDiff = null;

  if (cession.abtretungId) {
    cssdCessionDiff = yield call(
      getCssdAuditVersionDiffs,
      cession.idProxyAdresseZahlstelle,
      cession.cssdId,
      cession.abtretungId,
      cssdCessionAuditEvents,
      auditPartialClaimCessionHashes.OLD_CESSION_HASH,
      auditPartialClaimCessionHashes.NEW_CESSION_HASH,
      fetchCssdCessionHistoryFromBE
    );
    cssdCessionSettlementDiff = yield call(
      getCssdAuditVersionDiffs,
      cession.idProxyAdresseZahlstelle,
      cession.cssdId,
      cession.neuerDarlehensgeber.cssdAbwicklungsinformationenId,
      cssdCessionAuditEvents,
      auditPartialClaimCessionHashes.OLD_SETTLEMENT_HASH,
      auditPartialClaimCessionHashes.NEW_SETTLEMENT_HASH,
      fetchCssdSettlementHistoryFromBE
    );
  }

  let terminationDiffs = [];
  if (partialClaim.kuendigungen?.length >= 1) {
    terminationDiffs = yield all(
      partialClaim.kuendigungen.map((termination) =>
        call(
          getCssdAuditVersionDiffs,
          partialClaim.idProxyAdresseZahlstelle,
          termination.cssdId || partialClaim.cssdId,
          termination.kuendigungId,
          cssdCessionAuditEvents,
          auditPartialClaimCessionHashes.OLD_TERMINATION_HASH,
          auditPartialClaimCessionHashes.NEW_TERMINATION_HASH,
          fetchTerminationHistory
        )
      )
    );
  }

  return {
    [auditChangeTypes.CSSD_CESSION]: cssdCessionDiff ? cssdCessionDiff.transactionChanges : [],
    [auditChangeTypes.CSSD_CESSION_NEW_DG]: cssdCessionSettlementDiff
      ? cssdCessionSettlementDiff.transactionChanges
      : [],
    [auditChangeTypes.CSSD_TERMINATION]: terminationDiffs
      .map((entry) => entry.transactionChanges)
      .flat(),
  };
}

function* getCssdInterestNotificationAuditChanges(
  interestNotification,
  cssdInterestNotificationAuditEvents
) {
  const { transactionChanges: cssdInterestNotificationDiff } = yield call(
    getAuditVersionDiffs,
    interestNotification.idProxyAdresseZahlstelle,
    interestNotification.cssdId,
    interestNotification.zinsmitteilungId,
    cssdInterestNotificationAuditEvents,
    auditHashKeyNames.OLD_INTEREST_NOTIFICATION_HASH,
    auditHashKeyNames.NEW_INTEREST_NOTIFICATION_HASH,
    fetchCssdInterestNotificationHistory
  );
  return {
    [auditChangeTypes.CSSD_INTEREST_NOTIFICATION]: cssdInterestNotificationDiff,
  };
}

export function* fetchGPConnectFourEyesEvents(cssdId, patrialClaimId) {
  const connectGpEvents = yield call(
    getConnectGPEvents,
    toHex(`${cssdId}`),
    patrialClaimId ? toHex(`${patrialClaimId}`) : ZERO_HASH
  );
  const gpHashes = [
    ...new Set(
      connectGpEvents.map((event) => {
        return event.returnValues.gpHash;
      })
    ),
  ];

  const [events] = yield all(
    gpHashes.map((gpHash) =>
      call(getCssdFourEyesEvents, toHex(`${patrialClaimId ? patrialClaimId : cssdId}`), gpHash)
    )
  );
  return events ? events : [];
}

export function* fetchCssdHistory({ cssd }) {
  try {
    const cssdAuditEvents = yield call(getCssdAuditEvents, toHex(`${cssd.cssdId}`));
    let fourEyesEvents = yield call(getCssdFourEyesEvents, toHex(`${cssd.cssdId}`));
    if (cssd.kuendigungen) {
      const cessionFourEyes = yield all(
        cssd.kuendigungen.map((termination) =>
          call(getCssdFourEyesEvents, toHex(`${cssd.cssdId}`), toHex(`${termination.kuendigungId}`))
        )
      );
      fourEyesEvents = [...fourEyesEvents, ...cessionFourEyes.flat()];
    }

    const gpConnectFourEyesEvents = yield call(fetchGPConnectFourEyesEvents, cssd.cssdId, null);

    fourEyesEvents = [...fourEyesEvents, ...gpConnectFourEyesEvents];

    const auditChanges = yield call(
      mergeAuditLogFromEvents,
      cssdAuditEvents,
      eventType.CSSD_AUDIT,
      fourEyesEvents
    );
    const diff = yield call(getCssdAuditChanges, cssd, cssdAuditEvents);
    const auditLog = yield call(buildAuditLog, auditChanges, diff, eventType.CSSD_AUDIT);
    yield put(fetchCssdHistoryActions.success(auditLog));
  } catch (error) {
    console.error(error);
    yield put(fetchCssdHistoryActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.fetch_history_error)));
  }
}

export function* fetchPartialClaimCessionHistory({ partialClaim, cession }) {
  let auditCession = cession ? cession : {};
  let auditPartialClaim = partialClaim ? partialClaim : {};

  try {
    if (!partialClaim && cession.zugehoerigeTeilforderungId) {
      const { data: claim } = yield call(
        queryTeilforderungDetails,
        cession.idProxyAdresseZahlstelle,
        cession.zugehoerigeTeilforderungId
      );
      auditPartialClaim = claim;
    }

    if (!cession && partialClaim.entstandenAusAbtretungId) {
      const { data: ces } = yield call(
        getCession,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.entstandenAusAbtretungId,
        partialClaim.entstandenAusAbtretungFingerprint
      );
      auditCession = ces;
    }

    const { abtretungId, entstandenAusTeilforderungId } = auditCession;
    const { id: partialClaimId, cssdId } = auditPartialClaim;

    const cessionAuditEvents = abtretungId
      ? yield call(getCssdCessionAuditEventByCessionId, toHex(`${abtretungId}`))
      : [];
    const partialClaimAuditEvents = partialClaimId
      ? yield call(getCssdCessionAuditEventByPartialClaimId, toHex(`${partialClaimId}`))
      : [];
    const auditEvents = cessionAuditEvents.concat(partialClaimAuditEvents);

    let fourEyesEvents =
      abtretungId && entstandenAusTeilforderungId
        ? yield call(
            getCssdFourEyesEvents,
            toHex(`${entstandenAusTeilforderungId}`),
            toHex(`${abtretungId}`)
          )
        : [];

    if (partialClaimId && partialClaim.kuendigungen) {
      const cessionFourEyes = yield all(
        partialClaim.kuendigungen.map((termination) =>
          call(
            getCssdFourEyesEvents,
            toHex(`${partialClaimId}`),
            toHex(`${termination.kuendigungId}`)
          )
        )
      );
      fourEyesEvents = [...fourEyesEvents, ...cessionFourEyes.flat()];
    }

    const gpConnectFourEyesEvents = yield call(
      fetchGPConnectFourEyesEvents,
      cssdId,
      partialClaimId
    );
    fourEyesEvents = [...fourEyesEvents, ...gpConnectFourEyesEvents];

    const auditChanges = yield call(
      mergeAuditLogFromEvents,
      auditEvents,
      eventType.CSSD_CESSION_AUDIT,
      fourEyesEvents
    );

    const diff = yield call(
      getCssdCessionAuditChanges,
      auditPartialClaim,
      auditCession,
      auditEvents
    );

    const idProx = getIdProxyAddr();
    const isDN =
      idProx === auditCession.idProxyAdresseDarlehensnehmer &&
      idProx !== auditCession.neuerDarlehensgeber?.idProxyAdresse &&
      idProx !== auditCession.alterDarlehensgeber?.idProxyAdresse;

    let auditLog = yield call(
      buildAuditLog,
      auditChanges,
      diff,
      eventType.CSSD_CESSION_AUDIT,
      isDN
    );

    auditLog = auditPartialClaim
      ? buildAuditLogWithNachdokumentiertDescription(auditLog, auditPartialClaim)
      : auditLog;

    yield put(fetchPartialClaimCessionHistoryActions.success(auditLog));
  } catch (error) {
    console.error(error);
    yield put(fetchPartialClaimCessionHistoryActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.fetch_history_error)));
  }
}

const buildAuditLogWithNachdokumentiertDescription = (auditLog, partialClaim) => {
  let nachdokumentierteTeilforderungen = [];
  if (partialClaim.kuendigungen && partialClaim.kuendigungen.length > 0) {
    partialClaim.kuendigungen.map((termination) => {
      let hexId = toHex(`${termination.kuendigungId}`);
      if (termination.nachdokumentiert) nachdokumentierteTeilforderungen.push(hexId);
      return termination;
    });
  }
  auditLog.map((entry) => {
    for (let nachdokumentierteId of nachdokumentierteTeilforderungen) {
      if (entry.terminationId && entry.terminationId.includes(nachdokumentierteId)) {
        entry.description =
          appIntl().formatMessage(messages.reDocumented) + " " + entry.description;
        return entry;
      }
    }
    return entry;
  });
  return auditLog;
};

export function* fetchInterestNotificationCessionHistory({ interestNotification }) {
  try {
    const interestNotificationAuditEvents = yield call(
      getCSSDInterestNotificationAuditEvent,
      toHex(`${interestNotification.zinsmitteilungId}`)
    );
    const fourEyesEvents = yield call(
      getCssdFourEyesEvents,
      toHex(`${interestNotification.cssdId}`),
      toHex(`${interestNotification.zinsmitteilungId}`)
    );
    const auditChanges = yield call(
      mergeAuditLogFromEvents,
      interestNotificationAuditEvents,
      eventType.CSSD_INTEREST_NOTIFICATION_AUDIT,
      fourEyesEvents
    );
    const diff = yield call(
      getCssdInterestNotificationAuditChanges,
      interestNotification,
      interestNotificationAuditEvents
    );
    const auditLog = yield call(
      buildAuditLog,
      auditChanges,
      diff,
      eventType.CSSD_INTEREST_NOTIFICATION_AUDIT
    );
    yield put(fetchInterestNotificationHistoryActions.success(auditLog));
  } catch (error) {
    console.error(error);
    yield put(fetchInterestNotificationHistoryActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.fetch_history_error)));
  }
}
