import React from "react";
import { CircularProgress, Fab, Typography, withStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { messages } from "../messages";
import LaunchIcon from "@material-ui/icons/Launch";
import { signin_styles } from "../signin_styles";
import { connect } from "react-redux";

const WalletInformation = ({ signInPending, handleNextClick, classes }) => {
  return (
    <form className={classes.form}>
      <Typography
        variant="subtitle2"
        align="center"
        className={classes.walletInformation}
        gutterBottom
      >
        <FormattedMessage {...messages.walletInfoMessage} />
      </Typography>
      <Typography
        variant="subtitle2"
        align="center"
        className={classes.walletInformation}
        gutterBottom
      >
        <u>
          <FormattedMessage {...messages.secureYourWalletMessage} />
        </u>
      </Typography>
      <Fab
        variant="extended"
        size="large"
        color="primary"
        disabled={signInPending}
        className={classes.nextButton}
        onClick={handleNextClick}
      >
        {signInPending ? (
          <CircularProgress size={24} className={classes.buttonProgress} />
        ) : (
          <div className={classes.nextButtonContent}>
            <FormattedMessage {...messages.nextButton} />
            <LaunchIcon className={classes.rightIcon} />
          </div>
        )}
      </Fab>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    signInPending: state.signInReducer.signInPending,
  };
};

export default withStyles(signin_styles)(connect(mapStateToProps, null)(WalletInformation));
