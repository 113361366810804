/* eslint-disable no-console */
import {
  FETCH_SSD_EVENT_INFO_SUCCESS,
  FETCH_SSD_EVENT_INFO_NO_PERMISSIONS,
  FETCH_SSD_EVENT_INFO_FAILURE,
  FETCH_SSD_EVENT_INFO_BULK_FAILURE,
  OPEN_DETAIL_MODAL,
  CLOSE_DETAIL_MODAL,
  OPEN_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL,
  OPEN_EDIT_MODAL,
  CLOSE_EDIT_MODAL,
  UPDATE_SSD_STATUS,
  UPDATE_PAYMENT_STATUS,
  UPDATE_FOUR_EYES_STATUS,
  UPDATE_ID_PROXY_ADDR,
  ADD_SSD_FROM_EVENT,
  UPDATE_SSD_PREVIEW,
  OPEN_REBUY_MODAL,
  CLOSE_REBUY_MODAL,
  UPDATE_REBUY_STATUS,
  OPEN_REBUY_PREVIEW_MODAL,
  CLOSE_REBUY_PREVIEW_MODAL,
  OPEN_SIGN_DOCUMENT_MODAL,
  CLOSE_SIGN_DOCUMENT_MODAL,
  FILTER_SSD_INFOS,
  RESET_SSD_INFOS_FILTER,
  UPDATE_TERMINATION_STATUS,
  FETCH_SSD_EVENTS_REQUEST,
  FETCH_SSD_EVENT_INFO_BULK_RETRY,
  FETCH_SSD_EVENT_INFO_SET_TOTAL,
  EXPORT_BSSD_CSV,
  EXPORT_BSSD_CSV_FAILURE,
  EXPORT_BSSD_CSV_SUCCESS,
  SET_IS_LOADING,
  SET_BSSDS,
} from "./actions";
import { toUtf8 } from "services/web3Services/commons";
import {
  SSDState,
  SSDPaymentState,
  FourEyesState,
  zeroAddress,
  SSDActions,
  SSDRebuyAction,
} from "util/constants";
import { isDn } from "util/ssdUtils";
import { dateToTimestamp, onlyUnique } from "../../util/convertor";
import { getSsdState } from "components/BssdOverview/SsdContentWrapper/SsdContent/StatusChips/SsdStatusChip";

const initialState = {
  bssds: [],
  isLoading: false,
  //--------------------------
  ssdInfos: [],
  filteredSsdInfos: [],
  ssdDetails: {},
  rejectReason: {},
  ssdStatus: {},
  detailModalOpen: false,
  confirmModalOpen: false,
  editModalOpen: false,
  rebuyModalOpen: false,
  rebuyPreviewModalOpen: false,
  signDocumentModalOpen: false,
  idProxyAddr: "",
  filter: {
    interne_kennung: "",
    vertrags_ref_num: "",
    urkunden_registernummer: "",
    roleInSSD: "all",
    idProxyAddr: "",
    status: "all",
    darlehensnehmer: "",
    darlehensgeber: "",
    handelstagVon: null,
    handelstagBis: null,
    endfaelligkeitVon: null,
    endfaelligkeitBis: null,
  },
  loadErrors: {},
  numTotal: 0,
  isExporting: false,
};

function addSsdFromEventReducer(state, action) {
  let ssdInfos = [...state.ssdInfos];
  const id = state.ssdInfos.length + 1;
  const ssdData = {
    id: id,
    ssdid: toUtf8(action.id),
    vertragsreferenz_nr_darlehensgeber: "",
    interne_kennung_darlehensgeber: "",
    vertragsreferenz_nr_darlehensnehmer: "",
    interne_kennung_darlehensnehmer: "",
    darlehensnehmer: "",
    darlehensgeber: "",
    nominal: "",
    zinssatz: "",
    handelstag: "",
    faelligkeitstag_der_zinszahlung: "",
    valuta: "",
    state: "",
    paymentState: "",
    fingerprint: action.fingerprint,
    confirmationState: "",
    firstConfirmer: "",
    arranger: action.arranger,
    buyer: "",
    maturityDate: "",
    rebuyMaturityDate: "",
    rebuyState: "",
    valueDate: "",
    rebuyHash: "",
    urkunden_registernummer: "",
    waehrung_nominal: "",
    endfaelligkeit: "",
    ssdRejected: false,
    rebuyRejected: false,
  };
  const hitsInArr = [...state.ssdInfos].filter((ssdInfo) => ssdInfo.ssdid === ssdData.ssdid);
  if (hitsInArr.length === 0) {
    ssdInfos.push(ssdData);
  }
  return {
    ...state,
    ssdInfos,
  };
}

function updateSsdInfos(ssdInfo, ssdInfosArr) {
  const { ssdDetails, ...infoWithoutDetails } = ssdInfo;
  const isNew = ssdInfosArr.filter((entry) => entry.ssdid === ssdInfo.ssdid).length === 0;
  if (isNew) {
    return [...ssdInfosArr, { ...ssdDetails, ...infoWithoutDetails }];
  } else {
    return [...ssdInfosArr].map((entry) => {
      if (entry.ssdid === ssdInfo.ssdid) {
        return {
          ...entry,
          ...ssdDetails,
          ...infoWithoutDetails,
        };
      }
      return entry;
    });
  }
}

function removeEventFromSsdInfos(ssdid, ssdInfosArr) {
  return [...ssdInfosArr].filter((entry) => ssdid !== entry.ssdid);
}

function fetchSsdEventsRequestReducer(state, action) {
  return {
    ...state,
    loadErrors: {},
    ssdInfos: initialState.ssdInfos,
    filterSsdInfos: initialState.filteredSsdInfos,
    numTotal: initialState.numTotal,
  };
}

function fetchSsdEventInfoSuccessReducer(state, action) {
  const ssdInfos = updateSsdInfos(action.ssdInfo, state.ssdInfos);
  return {
    ...state,
    ssdInfos,
  };
}

function fetchSsdEventInfoFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.arranger]:
        state.loadErrors[action.arranger] != null
          ? [...state.loadErrors[action.arranger], action.ssdid].filter(onlyUnique)
          : [action.ssdid],
    },
  };
}

function fetchSsdEventInfoBulkFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.arranger]:
        state.loadErrors[action.arranger] != null
          ? [...state.loadErrors[action.arranger], ...action.ssdids].filter(onlyUnique)
          : [...action.ssdids],
    },
  };
}

function fetchSsdEventInfoBulkRetryReducer(state, action) {
  return {
    ...state,
    loadErrors: action.arranger ? { ...state.loadErrors, [action.arranger]: [] } : {},
  };
}

function fetchSsdEventInfoSetTotalReducer(state, action) {
  return {
    ...state,
    numTotal: action.numTotal,
  };
}

function fetchSsdEventInfoNoPermissionsReducer(state, action) {
  const ssdInfos = removeEventFromSsdInfos(action.ssdid, state.ssdInfos);
  const filteredSsdInfos = removeEventFromSsdInfos(action.ssdid, state.filteredSsdInfos);
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
    numTotal: state.numTotal - 1,
  };
}

function updateSsdStateInArr(ssdInfos, id, state, ssdAction, fingerprint) {
  return [...ssdInfos].map((ssdInfo) => {
    if (ssdInfo.ssdid.localeCompare(id) === 0) {
      //remove first confirmer if ssd was rejected from DG side, so we can start again on DN side
      let firstConfirmer = ssdInfo.firstConfirmer;
      let ssdRejected = false;
      if (ssdAction === SSDActions.REJECT_OFFER) {
        firstConfirmer = zeroAddress;
        ssdRejected = true;
      }
      //update payment state if ssd transitions from IN_WORK to OPEN, since there is no extra SSDPaymentEvent :(
      let paymentState = ssdInfo.paymentState;
      if (ssdInfo.state === SSDState.IN_WORK && state === SSDState.OPEN) {
        paymentState = SSDPaymentState.AWAITING_PAYMENT;
      }
      return {
        ...ssdInfo,
        state,
        paymentState,
        firstConfirmer,
        fingerprint,
        ssdRejected,
      };
    }
    return ssdInfo;
  });
}

function updatePaymentStateInArr(ssdInfos, id, paymentState) {
  return [...ssdInfos].map((ssdInfo) => {
    if (ssdInfo.ssdid.localeCompare(id) === 0) {
      return { ...ssdInfo, paymentState };
    }
    return ssdInfo;
  });
}

function updateSsdStatusReducer(state, action) {
  const ssdInfos = updateSsdStateInArr(
    state.ssdInfos,
    toUtf8(action.id),
    action.state,
    action.ssdAction,
    action.fingerprint
  );
  const filteredSsdInfos = updateSsdStateInArr(
    state.filteredSsdInfos,
    toUtf8(action.id),
    action.state,
    action.ssdAction,
    action.fingerprint
  );
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
  };
}

function updatePaymentStatus(state, action) {
  const ssdInfos = updatePaymentStateInArr(state.ssdInfos, toUtf8(action.id), action.state);
  const filteredSsdInfos = updatePaymentStateInArr(
    state.filteredSsdInfos,
    toUtf8(action.id),
    action.state
  );
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
  };
}

function updateFourEyesStateInArr(ssdInfos, id, state, firstConfirmer) {
  let confirmer = firstConfirmer;
  //remove firstconfirmer if foureyes event state is "CANCELLED"
  if (
    state === FourEyesState.ACCEPT_CANCELLED ||
    state === FourEyesState.ACCEPT_CANCELLED_ON_REJECT ||
    state === FourEyesState.OFFER_CANCELLED ||
    state === FourEyesState.OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.REBUY_OFFER_CANCELLED ||
    state === FourEyesState.REBUY_OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.REBUY_ACCEPT_CANCELLED ||
    state === FourEyesState.REBUY_ACCEPT_CANCELLED_ON_REJECT ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED_ON_REJECT ||
    state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED ||
    state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY
  ) {
    confirmer = zeroAddress;
  }
  return [...ssdInfos].map((ssdInfo) => {
    if (ssdInfo.ssdid.localeCompare(id) === 0) {
      return {
        ...ssdInfo,
        confirmationState: state,
        firstConfirmer: confirmer,
      };
    }
    return ssdInfo;
  });
}

function updateFourEyesStatus(state, action) {
  const ssdInfos = updateFourEyesStateInArr(
    state.ssdInfos,
    toUtf8(action.id),
    action.state,
    action.firstConfirmer
  );
  const filteredSsdInfos = updateFourEyesStateInArr(
    state.filteredSsdInfos,
    toUtf8(action.id),
    action.state,
    action.firstConfirmer
  );
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
  };
}

function updateRebuyStateInArr(ssdInfos, id, rebuyState, rebuyHash, ssdAction) {
  return [...ssdInfos].map((ssdInfo) => {
    if (ssdInfo.ssdid.localeCompare(id) === 0) {
      //remove first confirmer if ssd was rejected from DG side, so we can start again on DN side
      let firstConfirmer = ssdInfo.firstConfirmer;
      let rebuyRejected = false;
      if (ssdAction === SSDRebuyAction.REJECT_OFFER) {
        firstConfirmer = zeroAddress;
        rebuyRejected = true;
      }
      return {
        ...ssdInfo,
        rebuyState,
        rebuyHash,
        firstConfirmer,
        rebuyRejected,
      };
    }
    return ssdInfo;
  });
}

function updateRebuyStatus(state, action) {
  const ssdInfos = updateRebuyStateInArr(
    state.ssdInfos,
    toUtf8(action.id),
    action.state,
    action.hash,
    action.ssdAction
  );
  const filteredSsdInfos = updateRebuyStateInArr(
    state.filteredSsdInfos,
    toUtf8(action.id),
    action.state,
    action.hash,
    action.ssdAction
  );
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
  };
}

function updateIdProxyAddrReducer(state, action) {
  return {
    ...state,
    idProxyAddr: action.idProxyAddr,
  };
}

function updateSsdPreviewReducer(state, action) {
  const ssdDetails = {
    ...action.ssdDetails,
    ssdid: `${action.ssdDetails.ssdid}`,
  };
  const rejectReason = action.rejectReason;
  const ssdStatus = {
    ...action.ssdStatus,
    ssdid: `${action.ssdStatus.ssdid}`,
  };
  return {
    ...state,
    ssdDetails,
    rejectReason,
    ssdStatus,
  };
}

function updateTerminationStateInArr(
  ssdInfos,
  id,
  state,
  terminationInitiator,
  terminationHash,
  terminationDate,
  terminationType
) {
  return [...ssdInfos].map((ssdInfo) => {
    if (ssdInfo.ssdid.localeCompare(id) === 0) {
      return {
        ...ssdInfo,
        terminationState: state,
        terminationInitiator: terminationInitiator,
        terminationHash: terminationHash,
        terminationDate: terminationDate,
        terminationType: terminationType,
      };
    }
    return ssdInfo;
  });
}

function updateTerminationStatusReducer(state, action) {
  const ssdInfos = updateTerminationStateInArr(
    state.ssdInfos,
    toUtf8(action.ssdId),
    action.state,
    action.terminationInitiator,
    action.terminationHash,
    action.terminationDate,
    action.terminationType
  );
  const filteredSsdInfos = updateTerminationStateInArr(
    state.filteredSsdInfos,
    toUtf8(action.ssdId),
    action.state,
    action.terminationInitiator,
    action.terminationHash,
    action.terminationDate,
    action.terminationType
  );
  return {
    ...state,
    ssdInfos,
    filteredSsdInfos,
  };
}

function openDetailModalReducer(state) {
  return {
    ...state,
    detailModalOpen: true,
  };
}

function closeDetailModalReducer(state) {
  return {
    ...state,
    detailModalOpen: false,
  };
}

function openConfirmModalReducer(state) {
  return {
    ...state,
    confirmModalOpen: true,
  };
}

function closeConfirmModalReducer(state) {
  return {
    ...state,
    confirmModalOpen: false,
  };
}

function openEditModalReducer(state) {
  return {
    ...state,
    editModalOpen: true,
  };
}

function closeEditModalReducer(state) {
  return {
    ...state,
    editModalOpen: false,
  };
}

function openRebuyModalReducer(state) {
  return {
    ...state,
    rebuyModalOpen: true,
  };
}

function closeRebuyModalReducer(state) {
  return {
    ...state,
    rebuyModalOpen: false,
  };
}

function openRebuyPreviewModalReducer(state) {
  return {
    ...state,
    rebuyPreviewModalOpen: true,
  };
}

function closeRebuyPreviewModalReducer(state) {
  return {
    ...state,
    rebuyPreviewModalOpen: false,
  };
}

function openSignDocumentModalReducer(state) {
  return {
    ...state,
    signDocumentModalOpen: true,
  };
}

function closeSignDocumentModalReducer(state) {
  return {
    ...state,
    signDocumentModalOpen: false,
  };
}

function startExport(state) {
  return {
    ...state,
    isExporting: true,
  };
}

function endExport(state) {
  return {
    ...state,
    isExporting: false,
  };
}

const filterText = (actualValue, filterValue) =>
  actualValue && actualValue.toUpperCase().includes(filterValue.toUpperCase());

const filterDate = (actualValue, filterFromValue, filterToValue) => {
  let retVal = true;
  if (filterFromValue) {
    retVal = retVal && dateToTimestamp(actualValue) >= filterFromValue / 1000;
  }
  if (filterToValue) {
    retVal = retVal && dateToTimestamp(actualValue) <= filterToValue / 1000;
  }
  return retVal;
};

export const filterSsdInfos = (ssdInfos, filter) => {
  let resultArr = [...ssdInfos];
  if (filter.roleInSSD === "dn") {
    resultArr = resultArr.filter(
      (ssd) => ssd.darlehensnehmer && ssd.darlehensnehmer === filter.idProxyAddr
    );
  }
  if (filter.roleInSSD === "dg") {
    resultArr = resultArr.filter(
      (ssd) => ssd.darlehensgeber && ssd.darlehensgeber === filter.idProxyAddr
    );
  }
  if (filter.status && filter.status !== "all") {
    resultArr = resultArr.filter((ssd) => {
      return getSsdState(ssd) === filter.status;
    });
  }
  if (filter.urkunden_registernummer !== "") {
    resultArr = resultArr.filter((ssd) =>
      filterText(ssd.urkunden_registernummer, filter.urkunden_registernummer)
    );
  }
  if (filter.interne_kennung !== "") {
    resultArr = resultArr.filter((ssd) =>
      isDn(ssd.darlehensnehmer)
        ? filterText(ssd.interne_kennung_darlehensnehmer, filter.interne_kennung)
        : filterText(ssd.interne_kennung_darlehensgeber, filter.interne_kennung)
    );
  }
  if (filter.vertrags_ref_num !== "") {
    resultArr = resultArr.filter((ssd) =>
      isDn(ssd.darlehensnehmer)
        ? filterText(ssd.vertragsreferenz_nr_darlehensnehmer, filter.vertrags_ref_num)
        : filterText(ssd.vertragsreferenz_nr_darlehensgeber, filter.vertrags_ref_num)
    );
  }
  if (filter.darlehensnehmer !== "") {
    resultArr = resultArr.filter((ssd) =>
      filterText(ssd.darlehensnehmer_name, filter.darlehensnehmer)
    );
  }
  if (filter.darlehensgeber !== "") {
    resultArr = resultArr.filter((ssd) =>
      filterText(ssd.darlehensgeber_name, filter.darlehensgeber)
    );
  }

  if (filter.handelstagVon || filter.handelstagBis) {
    resultArr = resultArr.filter((ssd) =>
      filterDate(ssd.handelstag, filter.handelstagVon, filter.handelstagBis)
    );
  }

  if (filter.endfaelligkeitVon || filter.endfaelligkeitBis) {
    resultArr = resultArr.filter((ssd) =>
      filterDate(ssd.endfaelligkeit, filter.endfaelligkeitVon, filter.endfaelligkeitBis)
    );
  }

  return resultArr;
};

function filterSsdInfosReducer(state, action) {
  const filteredSsdInfos = filterSsdInfos(state.ssdInfos, action.filter);
  return {
    ...state,
    filteredSsdInfos,
    filter: action.filter,
  };
}

function resetSsdInfosFilterReducer(state) {
  return {
    ...state,
    filteredSsdInfos: state.ssdInfos,
    filter: {
      interne_kennung: "",
      vertrags_ref_num: "",
      urkunden_registernummer: "",
      roleInSSD: "all",
      idProxyAddr: "",
      status: "all",
      darlehensnehmer: "",
      darlehensgeber: "",
      handelstagVon: null,
      handelstagBis: null,
      endfaelligkeitVon: null,
      endfaelligkeitBis: null,
    },
  };
}

export const ssdEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SSD_EVENTS_REQUEST:
      return fetchSsdEventsRequestReducer(state, action);
    case FETCH_SSD_EVENT_INFO_SUCCESS:
      return fetchSsdEventInfoSuccessReducer(state, action);
    case FETCH_SSD_EVENT_INFO_FAILURE:
      return fetchSsdEventInfoFailureReducer(state, action);
    case FETCH_SSD_EVENT_INFO_BULK_FAILURE:
      return fetchSsdEventInfoBulkFailureReducer(state, action);
    case FETCH_SSD_EVENT_INFO_BULK_RETRY:
      return fetchSsdEventInfoBulkRetryReducer(state, action);
    case FETCH_SSD_EVENT_INFO_SET_TOTAL:
      return fetchSsdEventInfoSetTotalReducer(state, action);
    case FETCH_SSD_EVENT_INFO_NO_PERMISSIONS:
      return fetchSsdEventInfoNoPermissionsReducer(state, action);
    case OPEN_DETAIL_MODAL:
      return openDetailModalReducer(state, action);
    case CLOSE_DETAIL_MODAL:
      return closeDetailModalReducer(state, action);
    case OPEN_CONFIRM_MODAL:
      return openConfirmModalReducer(state, action);
    case CLOSE_CONFIRM_MODAL:
      return closeConfirmModalReducer(state, action);
    case OPEN_EDIT_MODAL:
      return openEditModalReducer(state, action);
    case CLOSE_EDIT_MODAL:
      return closeEditModalReducer(state, action);
    case ADD_SSD_FROM_EVENT:
      return addSsdFromEventReducer(state, action);
    case UPDATE_SSD_PREVIEW:
      return updateSsdPreviewReducer(state, action);
    case UPDATE_SSD_STATUS:
      return updateSsdStatusReducer(state, action);
    case UPDATE_PAYMENT_STATUS:
      return updatePaymentStatus(state, action);
    case UPDATE_FOUR_EYES_STATUS:
      return updateFourEyesStatus(state, action);
    case UPDATE_REBUY_STATUS:
      return updateRebuyStatus(state, action);
    case UPDATE_ID_PROXY_ADDR:
      return updateIdProxyAddrReducer(state, action);
    case OPEN_REBUY_MODAL:
      return openRebuyModalReducer(state, action);
    case CLOSE_REBUY_MODAL:
      return closeRebuyModalReducer(state, action);
    case OPEN_REBUY_PREVIEW_MODAL:
      return openRebuyPreviewModalReducer(state, action);
    case CLOSE_REBUY_PREVIEW_MODAL:
      return closeRebuyPreviewModalReducer(state, action);
    case OPEN_SIGN_DOCUMENT_MODAL:
      return openSignDocumentModalReducer(state, action);
    case CLOSE_SIGN_DOCUMENT_MODAL:
      return closeSignDocumentModalReducer(state, action);
    case FILTER_SSD_INFOS:
      return filterSsdInfosReducer(state, action);
    case RESET_SSD_INFOS_FILTER:
      return resetSsdInfosFilterReducer(state, action);
    case UPDATE_TERMINATION_STATUS:
      return updateTerminationStatusReducer(state, action);
    case EXPORT_BSSD_CSV:
      return startExport(state);
    case EXPORT_BSSD_CSV_FAILURE:
    case EXPORT_BSSD_CSV_SUCCESS:
      return endExport(state);
    case SET_IS_LOADING:
      return setIsLoading(state, action);
    case SET_BSSDS:
      return setBssds(state, action);
    default:
      return state;
  }
};

const setIsLoading = (state, action) => ({
  ...state,
  isLoading: action.isLoading,
});

const setBssds = (state, action) => ({
  ...state,
  bssds: [...state.bssds, ...action.bssds],
  ssdInfos: [...state.bssds, ...action.bssds],
});
