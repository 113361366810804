import {
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR_SUCCESS,
  OPEN_SNACKBAR_ERROR,
  OPEN_SNACKBAR_WARNING,
  OPEN_SNACKBAR,
  OPEN_MESSAGE_DIALOG,
  CLOSE_MESSAGE_DIALOG,
  OPEN_ALERT_DIALOG,
  CLOSE_ALERT_DIALOG,
} from "./actions";

// reducer with initial state
const initialState = {
  snackbars: [],
  messageDialog: {
    open: false,
    icon: null,
    title: "",
    body: "",
    buttonText: "OK",
  },
  alertDialog: {
    open: false,
    callback: () => {},
  },
};

function openMessageDialog(state, action) {
  return {
    ...state,
    messageDialog: {
      icon: action.icon,
      title: action.title,
      body: action.body,
      buttonText: action.buttonText,
      open: true,
    },
  };
}

function closeMessageDialog(state) {
  return {
    ...state,
    messageDialog: { ...initialState.messageDialog },
  };
}

function openSnackbarReducer(state, action, variant) {
  return {
    ...state,
    snackbars: state.snackbars.concat({
      id: Date.now(),
      text: action.text,
      open: true,
      variant: variant,
    }),
  };
}

function closeSnackbarReducer(state, action) {
  return {
    ...state,
    snackbars: state.snackbars.filter((sb) => sb.id !== action.id),
  };
}

function openAlertDialog(state, action) {
  return {
    ...state,
    alertDialog: {
      open: true,
      callback: action.callback,
    },
  };
}

function closeAlertDialog(state, action) {
  return {
    ...state,
    alertDialog: {
      open: false,
      callback: () => {},
    },
  };
}

export function sharedReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return openSnackbarReducer(state, action, "info");
    case OPEN_SNACKBAR_SUCCESS:
      return openSnackbarReducer(state, action, "success");
    case OPEN_SNACKBAR_ERROR:
      return openSnackbarReducer(state, action, "error");
    case OPEN_SNACKBAR_WARNING:
      return openSnackbarReducer(state, action, "warning");

    case CLOSE_SNACKBAR:
      return closeSnackbarReducer(state, action);

    case OPEN_MESSAGE_DIALOG:
      return openMessageDialog(state, action);
    case CLOSE_MESSAGE_DIALOG:
      return closeMessageDialog(state);

    case OPEN_ALERT_DIALOG:
      return openAlertDialog(state, action);
    case CLOSE_ALERT_DIALOG:
      return closeAlertDialog(state, action);

    default:
      return state;
  }
}
