import axios from "axios";
import jwt_decode from "jwt-decode";
import { sendSignedHttpRequest } from "util/requestSigning";
import { getDetailsUrl } from "services/web3Services/commons";
import { formatSafeUrl } from "util/convertor";

export async function checkToken(token, walletAddress) {
  const data = { token, mitarbeiter_wallet_adresse: walletAddress };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/invitation/accept`,
    jwt_decode(token).idproxyaddress,
    data
  ).catch(function (error) {
    return error.response;
  });
}

export async function invite(invitationData) {
  const idProxyAddr = invitationData.idProxyAddr;
  const backendUrl = await getDetailsUrl(idProxyAddr);
  const data = {
    ...invitationData,
    backendUrl,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter/invite`,
    idProxyAddr,
    data
  ).catch(function (error) {
    return error;
  });
}

export async function revokeInvite(email, idProxyAddr) {
  const data = {
    email,
  };
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter/invite/delete`,
    idProxyAddr,
    data
  );
}

export async function getColleagues(idProxyAddr) {
  const requestHeader = {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
  };

  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter`,
    idProxyAddr,
    null,
    requestHeader
  ).catch(function (error) {
    return error;
  });
}

export async function activateColleague(maWalletAddress, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter/${maWalletAddress}/activate`,
    idProxyAddr
  ).catch(function (error) {
    return error;
  });
}

export async function deactivateColleague(maWalletAddress, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter/${maWalletAddress}/deactivate`,
    idProxyAddr
  ).catch(function (error) {
    return error;
  });
}

export async function deleteColleague(maWalletAddress, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/mitarbeiter/${maWalletAddress}/delete`,
    idProxyAddr
  );
}

export async function validateToken(token, backendUrl) {
  return axios
    .get(formatSafeUrl(backendUrl + `/digits-server/invitation/${token}/validate`))
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function saveTechnicalUser(idProxyAddr, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/technischerUser`,
    idProxyAddr,
    data
  ).catch(function (error) {
    return error;
  });
}
