import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_termination_kuendigung_wurde_bestaetigt: {
    id: "redux.termination.kuendigung.wurde.bestaetigt",
    defaultMessage: "Kündigung wurde bestätigt.",
  },
  redux_termination_kuendigung_konnte_nicht_bestaetigt_werden: {
    id: "redux.termination.kuendigung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Kündigung konnte nicht bestätigt werden.",
  },
  redux_termination_kuendigung_wurde_abgelehnt: {
    id: "redux.termination.kuendigung.wurde.abgelehnt",
    defaultMessage: "Kündigung wurde abgelehnt.",
  },
  redux_termination_kuendigung_konnte_nicht_abgelehnt_werden: {
    id: "redux.termination.kuendigung.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Kündigung konnte nicht abgelehnt werden.",
  },
  redux_termination_kuendigung_wurde_erfasst: {
    id: "redux.termination.kuendigung.wurde.erfasst",
    defaultMessage: "Kündigung wurde erfasst.",
  },
  redux_termination_kuendigungsvaluta_muss_kleiner_als_die_valuta_des_ssds_sein: {
    id: "redux.termination.kuendigungsvaluta.muss.kleiner.als.die.valuta.des.ssds.sein",
    defaultMessage: "Kündigungsvaluta muss kleiner als die Endfälligkeit des SSDs sein.",
  },
  redux_termination_kuendigung_konnte_nicht_erfasst_werden: {
    id: "redux.termination.kuendigung.konnte.nicht.erfasst.werden",
    defaultMessage: "Kündigung konnte nicht erfasst werden.",
  },
});
