import {
  PDF_VERIFICATION_REQUEST,
  PDF_VERIFICATION_SUCCESS,
  PDF_VERIFICATION_FAILURE,
} from "./actions";

// reducer with initial state
const initialState = {
  verificationPending: false,
  pdfStatus: {
    isSigned: false,
    isMatured: false,
    isRootSsd: false,
    isCeded: false,
    isRebought: false,
    isRepaid: false,
    rebuyDate: 0,
    isTerminated: false,
    terminationDate: 0,
  },
};

function pdfVerificationRequestReducer(state) {
  return {
    ...state,
    verificationPending: true,
  };
}

function pdfVerificationSuccessReducer(state, action) {
  return {
    ...state,
    verificationPending: false,
    pdfStatus: action.pdfStatus,
  };
}

function pdfVerificationFailureReducer(state) {
  return {
    ...state,
    verificationPending: false,
  };
}

export function pdfVerificationReducer(state = initialState, action) {
  switch (action.type) {
    case PDF_VERIFICATION_REQUEST:
      return pdfVerificationRequestReducer(state, action);
    case PDF_VERIFICATION_SUCCESS:
      return pdfVerificationSuccessReducer(state, action);
    case PDF_VERIFICATION_FAILURE:
      return pdfVerificationFailureReducer(state);

    default:
      return state;
  }
}
