import { defineMessages } from "react-intl";

export const messages = defineMessages({
  shared_bssd_form_add_kuendigung_modal_dialog_title_text_kuendigungs_daten: {
    id: "shared.bssd.form.add.kuendigung.modal.dialog.title.text.kuendigungs.daten",
    defaultMessage: "Kündigungs Daten",
  },
  shared_bssd_form_add_kuendigung_modal_date_picker_label_termin_vorauss: {
    id: "shared.bssd.form.add.kuendigung.modal.date.picker.label.termin.vorauss",
    defaultMessage: "Termin (vorauss.)",
  },
  shared_bssd_form_add_kuendigung_modal_date_picker_label_valuta: {
    id: "shared.bssd.form.add.kuendigung.modal.date.picker.label.valuta",
    defaultMessage: "Valuta",
  },
  shared_bssd_form_add_kuendigung_modal_date_picker_label_kurs_in_prozent: {
    id: "shared.bssd.form.add.kuendigung.modal.date.picker.label.kurs.in.prozent",
    defaultMessage: "Kurs in %",
  },
  shared_bssd_form_add_kuendigung_modal_button_text_abbrechen: {
    id: "shared.bssd.form.add.kuendigung.modal.button.text.abbrechen",
    defaultMessage: "abbrechen",
  },
  shared_bssd_form_add_kuendigung_modal_button_text_hinzufuegen: {
    id: "shared.bssd.form.add.kuendigung.modal.button.text.hinzufuegen",
    defaultMessage: "hinzufügen",
  },
  shared_bssd_form_dg_details_custom_table_cell_text_bankverbindung_fuer_zinsen: {
    id: "shared.bssd.form.dg.details.custom.table.cell.text.bankverbindung.fuer.zinsen",
    defaultMessage: "Bankverbindung für Zinsen:",
  },
  shared_bssd_form_dg_details_custom_table_cell_text_bankverbindung_fuer_zinsen_und_tilgung: {
    id: "shared.bssd.form.dg.details.custom.table.cell.text.bankverbindung.fuer.zinsen.und.tilgung",
    defaultMessage: "Bankverbindung für Zinsen und Tilgung:",
  },
  shared_bssd_form_dg_details_custom_table_cell_text_iban: {
    id: "shared.bssd.form.dg.details.custom.table.cell.text.iban",
    defaultMessage: "IBAN:",
  },
  shared_bssd_form_dg_details_custom_table_cell_text_bic: {
    id: "shared.bssd.form.dg.details.custom.table.cell.text.bic",
    defaultMessage: "BIC:",
  },
  shared_bssd_form_dg_details_custom_table_cell_text_bankverbindung_fuer_tilgung: {
    id: "shared.bssd.form.dg.details.custom.table.cell.text.bankverbindung.fuer.tilgung",
    defaultMessage: "Bankverbindung für Tilgung:",
  },
  shared_bssd_form_fixing_sentence_das_fixing_erfolgt: {
    id: "shared.bssd.form.fixing.sentence.das.fixing.erfolgt",
    defaultMessage: "Das Fixing erfolgt",
  },
  shared_bssd_form_fixing_sentence_bankarbeitstage: {
    id: "shared.bssd.form.fixing.sentence.bankarbeitstage",
    defaultMessage: "Bankarbeitstag(e)",
  },
  shared_bssd_form_fixing_sentence_vor_periodenbeginn: {
    id: "shared.bssd.form.fixing.sentence.vor.periodenbeginn",
    defaultMessage: "vor Periodenbeginn",
  },
  shared_bssd_form_fixing_sentence_vor_periodenende: {
    id: "shared.bssd.form.fixing.sentence.vor.periodenende",
    defaultMessage: "vor Periodenende",
  },
  shared_bssd_form_fixing_sentence_gemaess: {
    id: "shared.bssd.form.fixing.sentence.gemaess",
    defaultMessage: "gemäß",
  },
  shared_bssd_form_fixing_sentence_uhr: {
    id: "shared.bssd.form.fixing.sentence.uhr",
    defaultMessage: "Uhr,",
  },
  shared_bssd_form_ssd_form_card_content_text_geschaeft: {
    id: "shared.bssd.form.ssd.form.card.content.text.geschaeft",
    defaultMessage: "Geschäft",
  },
  shared_bssd_form_ssd_form_text_field_label_urkunden_registernummer: {
    id: "shared.bssd.form.ssd.form.text.field.label.urkunden.registernummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  shared_bssd_form_ssd_form_date_picker_label_handelstag: {
    id: "shared.bssd.form.ssd.form.date_picker.label.handelstag",
    defaultMessage: "Handelstag *",
  },
  shared_bssd_form_ssd_form_time_picker_label_handeluhrzeit: {
    id: "shared.bssd.form.ssd.form.time_picker.label.handeluhrzeit",
    defaultMessage: "Handelsuhrzeit",
  },
  shared_bssd_form_ssd_form_text_field_label_nominal: {
    id: "shared.bssd.form.ssd.form.text.field.label.nominal",
    defaultMessage: "Nominal",
  },
  shared_bssd_form_ssd_form_input_label_text_waehrung_nominal: {
    id: "shared.bssd.form.ssd.form.input.label.text.waehrung.nominal",
    defaultMessage: "Währung Nominal",
  },
  shared_bssd_form_ssd_form_menu_item_text_eur: {
    id: "shared.bssd.form.ssd.form.menu.item.text.eur",
    defaultMessage: "EUR",
  },
  shared_bssd_form_ssd_form_menu_item_text_usd: {
    id: "shared.bssd.form.ssd.form.menu.item.text.usd",
    defaultMessage: "USD",
  },
  shared_bssd_form_ssd_form_menu_item_text_gbp: {
    id: "shared.bssd.form.ssd.form.menu.item.text.gbp",
    defaultMessage: "GBP",
  },
  shared_bssd_form_ssd_form_menu_item_text_chf: {
    id: "shared.bssd.form.ssd.form.menu.item.text.chf",
    defaultMessage: "CHF",
  },
  shared_bssd_form_ssd_form_text_field_label_kurs_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.kurs.in.prozent",
    defaultMessage: "Kurs in %",
  },
  shared_bssd_form_ssd_form_input_label_text_notiz: {
    id: "shared.bssd.form.ssd.form.input.label.text.notiz",
    defaultMessage: "Notiz",
  },
  shared_bssd_form_ssd_form_menu_item_text_prozent_symbol: {
    id: "shared.bssd.form.ssd.form.menu.item.text.prozent.symbol",
    defaultMessage: "%",
  },
  shared_bssd_form_ssd_form_text_field_label_kaufpreis: {
    id: "shared.bssd.form.ssd.form.text.field.label.kaufpreis",
    defaultMessage: "Kaufpreis",
  },
  shared_bssd_form_ssd_form_text_field_label_waehrung_kaufpreis: {
    id: "shared.bssd.form.ssd.form.text.field.label.waehrung.kaufpreis",
    defaultMessage: "Währung Kaufpreis",
  },
  shared_bssd_form_ssd_form_date_picker_label_valuta: {
    id: "shared.bssd.form.ssd.form.date.picker.label.valuta",
    defaultMessage: "Valuta *",
  },
  shared_bssd_form_ssd_form_date_picker_label_endfaelligkeit: {
    id: "shared.bssd.form.ssd.form.date.picker.label.endfaelligkeit",
    defaultMessage: "Endfälligkeit *",
  },
  shared_bssd_form_ssd_form_input_label_text_geschaeftstagekalender: {
    id: "shared.bssd.form.ssd.form.input.label.text.geschaeftstagekalender",
    defaultMessage: "Geschäftstagekalender",
  },
  shared_bssd_form_ssd_form_form_control_label_label_mrel_72b_crr: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.mrel_72b_crr",
    defaultMessage: "MREL / Art. 72b CRR",
  },
  shared_bssd_form_ssd_form_form_control_label_label_kwg_46f_preferred: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.kwg_46f_preferred",
    defaultMessage: "§ 46f KWG preferred",
  },
  shared_bssd_form_ssd_form_form_control_label_label_sag_49: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.sag_49",
    defaultMessage: "§ 49 SAG",
  },
  shared_bssd_form_ssd_form_form_control_label_label_tlac: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.tlac",
    defaultMessage: "TLAC",
  },
  shared_bssd_form_ssd_form_form_control_label_label_auslandsfaehig: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.auslandsfaehig",
    defaultMessage: "Auslandsfähig",
  },
  shared_bssd_form_ssd_form_form_control_label_label_nicht_auslandsfaehig: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.nicht.auslandsfaehig",
    defaultMessage: "Nicht auslandsfähig",
  },
  shared_bssd_form_ssd_form_form_control_label_label_vag_konform: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.vag.konform",
    defaultMessage: "VAG-konform",
  },
  shared_bssd_form_ssd_form_card_content_text_verzinsung: {
    id: "shared.bssd.form.ssd.form.card_content.text.verzinsung",
    defaultMessage: "Verzinsung",
  },
  shared_bssd_form_ssd_form_input_label_text_verzinsung: {
    id: "shared.bssd.form.ssd.form.input.label.text.verzinsung",
    defaultMessage: "Verzinsung",
  },
  shared_bssd_form_ssd_form_menu_item_text_fix: {
    id: "shared.bssd.form.ssd.form.menu.item.text.fix",
    defaultMessage: "fix",
  },
  shared_bssd_form_ssd_form_menu_item_text_variabel: {
    id: "shared.bssd.form.ssd.form.menu.item.text.variabel",
    defaultMessage: "variabel",
  },
  shared_bssd_form_ssd_form_input_label_text_zinszahlungsrhytmus: {
    id: "shared.bssd.form.ssd.form.input.label.text.zinszahlungsrhytmus",
    defaultMessage: "Zinszahlungsrhythmus",
  },
  shared_bssd_form_ssd_form_menu_item_text_jaehrlich: {
    id: "shared.bssd.form.ssd.form.menu.item.text.jaehrlich",
    defaultMessage: "jährlich",
  },
  shared_bssd_form_ssd_form_menu_item_text_halbjaehrlich: {
    id: "shared.bssd.form.ssd.form.menu.item.text.halbjaehrlich",
    defaultMessage: "halbjährlich",
  },
  shared_bssd_form_ssd_form_menu_item_text_vierteljaehrlich: {
    id: "shared.bssd.form.ssd.form.menu.item.text.vierteljaehrlich",
    defaultMessage: "vierteljährlich",
  },
  shared_bssd_form_ssd_form_menu_item_text_monatlich: {
    id: "shared.bssd.form.ssd.form.menu.item.text.monatlich",
    defaultMessage: "monatlich",
  },
  shared_bssd_form_ssd_form_input_label_text_zinstermin_tag: {
    id: "shared.bssd.form.ssd.form.input.label.text.zinstermin.tag",
    defaultMessage: "Zinstermin Tag",
  },
  shared_bssd_form_ssd_form_input_label_text_zinstermin_monat: {
    id: "shared.bssd.form.ssd.form.input.label.text.zinstermin.monat",
    defaultMessage: "Zinstermin Monat",
  },
  shared_bssd_form_ssd_form_form_helper_text_bitte_alle_monate_zum_zinszahlungsrhythmus_angeben: {
    id:
      "shared.bssd.form.ssd.form.form_helper.text.bitte.alle.monate.zum.zinszahlungsrhythmus.angeben",
    defaultMessage: "Bitte alle Monate zum Zinszahlungsrhythmus angeben",
  },
  shared_bssd_form_ssd_form_date_picker_label_erster_zinstermin: {
    id: "shared.bssd.form.ssd.form.date_picker.label.erster.zinstermin",
    defaultMessage: "erster Zinstermin *",
  },
  shared_bssd_form_ssd_form_date_picker_label_vorletzter_zinstermin: {
    id: "shared.bssd.form.ssd.form.date_picker.label.vorletzter.zinstermin",
    defaultMessage: "vorletzter Zinstermin *",
  },
  shared_bssd_form_ssd_form_input_label_text_faelligkeitstag_der_zinszahlung: {
    id: "shared.bssd.form.ssd.form.input.label.text.faelligkeitstag.der.zinszahlung",
    defaultMessage: "Fälligkeitstag der Zinszahlung",
  },
  shared_bssd_form_ssd_form_menu_item_text_following_nachfolgend: {
    id: "shared.bssd.form.ssd.form.menu.item.text.following.nachfolgend",
    defaultMessage: "following - nachfolgend",
  },
  shared_bssd_form_ssd_form_menu_item_text_modified_following_modifiziert_nachfolgend: {
    id: "shared.bssd.form.ssd.form.menu.item.text.modified.following.modifiziert.nachfolgend",
    defaultMessage: "modified following - modifiziert nachfolgend",
  },
  shared_bssd_form_ssd_form_input_label_text_berechnungszeitraum_der_zinszahlung: {
    id: "shared.bssd.form.ssd.form.input.label.text.berechnungszeitraum.der.zinszahlung",
    defaultMessage: "Berechnungszeitraum der Zinszahlung",
  },
  shared_bssd_form_ssd_form_menu_item_text_unadjusted_nicht_angepasst: {
    id: "shared.bssd.form.ssd.form.menu.item.text.unadjusted.nicht.angepasst",
    defaultMessage: "unadjusted - nicht angepasst",
  },
  shared_bssd_form_ssd_form_menu_item_text_adjusted_angepasst: {
    id: "shared.bssd.form.ssd.form.menu.item.text.adjusted.angepasst",
    defaultMessage: "adjusted - angepasst",
  },
  shared_bssd_form_ssd_form_input_label_text_zinsmethode: {
    id: "shared.bssd.form.ssd.form.input.label.text.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  shared_bssd_form_ssd_form_menu_item_text_act_act_icma_rule_251: {
    id: "shared.bssd.form.ssd.form.menu.item.text.act.act.icma.rule.251",
    defaultMessage: "act/act ICMA Rule 251",
  },
  shared_bssd_form_ssd_form_menu_item_text_act_act_isda: {
    id: "shared.bssd.form.ssd.form.menu.item.text.act.act.isda",
    defaultMessage: "act/act ISDA",
  },
  shared_bssd_form_ssd_form_menu_item_text_act_360_eurozinsmethode_franzoesische_zinsmethode: {
    id:
      "shared.bssd.form.ssd.form.menu.item.text.act.360.eurozinsmethode.franzoesische.zinsmethode",
    defaultMessage: "act/360 (Eurozinsmethode, französische Zinsmethode)",
  },
  shared_bssd_form_ssd_form_menu_item_text_act_360_englische_zinsmethode: {
    id: "shared.bssd.form.ssd.form.menu.item.text.act.360.englische.zinsmethode",
    defaultMessage: "act/365 (englische Zinsmethode)",
  },
  shared_bssd_form_ssd_form_menu_item_text_30_360_deutsche_zinsmethode: {
    id: "shared.bssd.form.ssd.form.menu.item.text.30.360.deutsche.zinsmethode",
    defaultMessage: "30/360 (deutsche Zinsmethode)",
  },
  shared_bssd_form_ssd_form_menu_item_text_30e_360_us_zinsmethode: {
    id: "shared.bssd.form.ssd.form.menu.item.text.30e.360.us.zinsmethode",
    defaultMessage: "30E/360 (US-Zinsmethode)",
  },
  shared_bssd_form_ssd_form_text_field_label_zinssatz_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.zinssatz.in.prozent",
    defaultMessage: "Zinssatz in %",
  },
  shared_bssd_form_ssd_form_input_label_text_referenzzinssatz: {
    id: "shared.bssd.form.ssd.form.input.label.text.referenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  shared_bssd_form_ssd_form_menu_item_text_1_monats_euribor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.1.monats.euribor",
    defaultMessage: "1-Monats-EURIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_3_monats_euribor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.3.monats.euribor",
    defaultMessage: "3-Monats-EURIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_6_monats_euribor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.6.monats.euribor",
    defaultMessage: "6-Monats-EURIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_12_monats_euribor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.12.monats.euribor",
    defaultMessage: "12-Monats-EURIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_1_monats_gbp_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.1.monats.gbp.libor",
    defaultMessage: "1-Monats-GBP-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_3_monats_gbp_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.3.monats.gbp.libor",
    defaultMessage: "3-Monats-GBP-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_6_monats_gbp_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.6.monats.gbp.libor",
    defaultMessage: "6-Monats-GBP-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_12_monats_gbp_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.12.monats.gbp.libor",
    defaultMessage: "12-Monats-GBP-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_1_monats_usd_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.1.monats.usd.libor",
    defaultMessage: "1-Monats-USD-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_3_monats_usd_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.3.monats.usd.libor",
    defaultMessage: "3-Monats-USD-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_6_monats_usd_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.6.monats.usd.libor",
    defaultMessage: "6-Monats-USD-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_12_monats_usd_libor: {
    id: "shared.bssd.form.ssd.form.menu.item.text.12.monats.usd.libor",
    defaultMessage: "12-Monats-USD-LIBOR",
  },
  shared_bssd_form_ssd_form_menu_item_text_2y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.2y.eur.cms.satz",
    defaultMessage: "2Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_3y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.3y.eur.cms.satz",
    defaultMessage: "3Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_5y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.5y.eur.cms.satz",
    defaultMessage: "5Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_10y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.10y.eur.cms.satz",
    defaultMessage: "10Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_15y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.15y.eur.cms.satz",
    defaultMessage: "15Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_20y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.20y.eur.cms.satz",
    defaultMessage: "20Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_30y_10y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.30y.10y.eur.cms.satz",
    defaultMessage: "30Y-10Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_10y_2y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.10y.2y.eur.cms.satz",
    defaultMessage: "10Y-2Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_menu_item_text_30y_2y_eur_cms_satz: {
    id: "shared.bssd.form.ssd.form.menu.item.text.30y.2y.eur.cms.satz",
    defaultMessage: "30Y-2Y-EUR-CMS-Satz",
  },
  shared_bssd_form_ssd_form_input_label_text_aufschlag_abschlag: {
    id: "shared.bssd.form.ssd.form.input.label.text.aufschlag.abschlag",
    defaultMessage: "Aufschlag/Abschlag",
  },
  shared_bssd_form_ssd_form_menu_item_aufschlag: {
    id: "shared.bssd.form.ssd.form.menu.item.aufschlag",
    defaultMessage: "Aufschlag",
  },
  shared_bssd_form_ssd_form_menu_item_abschlag: {
    id: "shared.bssd.form.ssd.form.menu.item.abschlag",
    defaultMessage: "Abschlag",
  },
  shared_bssd_form_ssd_form_text_field_label_abschlag_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.abschlag.in.prozent",
    defaultMessage: "Abschlag in %",
  },
  shared_bssd_form_ssd_form_text_field_label_aufschlag_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.aufschlag.in.prozent",
    defaultMessage: "Aufschlag in %",
  },
  shared_bssd_form_ssd_form_text_field_label_aufschlag_abschlag: {
    id: "shared.bssd.form.ssd.form.text.field.label.aufschlag.abschlag",
    defaultMessage: "Aufschlag/Abschlag",
  },
  shared_bssd_form_ssd_form_text_field_label_minimumzinssatz_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.minimumzinssatz.in.prozent",
    defaultMessage: "Minimumzinssatz in %",
  },
  shared_bssd_form_ssd_form_form_helper_text_muss_kleiner_sein_als_maximumzinssatz: {
    id: "shared.bssd.form.ssd.form.form.helper.text.muss.kleiner.sein.als.maximumzinssatz",
    defaultMessage: "Muss kleiner sein als Maximumzinssatz",
  },
  shared_bssd_form_ssd_form_text_field_label_maximumzinssatz_in_prozent: {
    id: "shared.bssd.form.ssd.form.text.field.label.maximumzinssatz.in.prozent",
    defaultMessage: "Maximumzinssatz in %",
  },
  shared_bssd_form_ssd_form_form_helper_text_muss_groesser_sein_als_minimumzinssatz: {
    id: "shared.bssd.form.ssd.form.form.helper.text.muss.groesser.sein.als.minimumzinssatz",
    defaultMessage: "Muss größer sein als Minimumzinssatz",
  },
  shared_bssd_form_ssd_form_text_field_label_faktor: {
    id: "shared.bssd.form.ssd.form.text.field.label.faktor",
    defaultMessage: "Faktor",
  },
  shared_bssd_form_ssd_form_card_content_heading_fixing: {
    id: "shared.bssd.form.ssd.form.card.content.heading.fixing",
    defaultMessage: "Fixing",
  },
  shared_bssd_form_ssd_form_input_label_text_fixingtyp: {
    id: "shared.bssd.form.ssd.form.input.label.text.fixingtyp",
    defaultMessage: "Fixingtyp",
  },
  shared_bssd_form_ssd_form_menu_item_in_advance: {
    id: "shared.bssd.form.ssd.form.menu.item.in.advance",
    defaultMessage: "In-Advance",
  },
  shared_bssd_form_ssd_form_menu_item_in_arrears: {
    id: "shared.bssd.form.ssd.form.menu.item.in.arrears",
    defaultMessage: "In-Arrears",
  },
  shared_bssd_form_ssd_form_text_field_label_anzahl_der_bankarbeitstage: {
    id: "shared.bssd.form.ssd.form.text.field.label.anzahl.der.bankarbeitstage",
    defaultMessage: "Anzahl der Bankarbeitstage",
  },
  shared_bssd_form_ssd_form_input_label_text_bildschirmseite: {
    id: "shared.bssd.form.ssd.form.input.label.text.bildschirmseite",
    defaultMessage: "Bildschirmseite",
  },
  shared_bssd_form_ssd_form_menu_item_reuters_seite_euribor01: {
    id: "shared.bssd.form.ssd.form.menu.item.reuters.seite.euribor01",
    defaultMessage: "REUTERS Seite EURIBOR01",
  },
  shared_bssd_form_ssd_form_menu_item_reuters_seite_libibor01: {
    id: "shared.bssd.form.ssd.form.menu.item.reuters.seite.libibor01",
    defaultMessage: "REUTERS Seite LIBOR01",
  },
  shared_bssd_form_ssd_form_menu_item_reuters_seite_eursfixa: {
    id: "shared.bssd.form.ssd.form.menu.item.reuters.seite.eursfixa",
    defaultMessage: "REUTERS Seite EURSFIXA=",
  },
  shared_bssd_form_ssd_form_time_picker_label_relevante_uhrzeit: {
    id: "shared.bssd.form.ssd.form.time.picker.label.relevante.uhrzeit",
    defaultMessage: "Relevante Uhrzeit *",
  },
  shared_bssd_form_ssd_form_input_label_text_relevanter_ort: {
    id: "shared.bssd.form.ssd.form.input.label.text.relevanter.ort",
    defaultMessage: "Relevanter Ort",
  },
  shared_bssd_form_ssd_form_menu_item_bruessel: {
    id: "shared.bssd.form.ssd.form.menu.item.bruessel",
    defaultMessage: "Brüssel",
  },
  shared_bssd_form_ssd_form_menu_item_london: {
    id: "shared.bssd.form.ssd.form.menu.item.london",
    defaultMessage: "London",
  },
  shared_bssd_form_ssd_form_card_content_heading_abtretung: {
    id: "shared.bssd.form.ssd.form.card.content.heading.abtretung",
    defaultMessage: "Abtretung",
  },
  shared_bssd_form_ssd_form_form_control_label_label_abtretbar: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.abtretbar",
    defaultMessage: "Abtretbar",
  },
  shared_bssd_form_ssd_form_form_control_label_label_nicht_abtretbar: {
    id: "shared.bssd.form.ssd.form.form.control.label.label.nicht.abtretbar",
    defaultMessage: "Nicht abtretbar",
  },
  shared_bssd_form_ssd_form_text_field_label_mindestbetrag_bei_abtretung: {
    id: "shared.bssd.form.ssd.form.text.field.label.mindestbetrag.bei.abtretung",
    defaultMessage: "Mindestbetrag bei Abtretung",
  },
  shared_bssd_form_ssd_form_input_label_text_waehrung_mindestbetrag_bei_abtretung: {
    id: "shared.bssd.form.ssd.form.input.label.text.waehrung.mindestbetrag.bei.abtretung",
    defaultMessage: "Währung Mindestbetrag bei Abtretung",
  },
  shared_bssd_form_ssd_form_card_content_heading_kuendigungsrecht: {
    id: "shared.bssd.form.ssd.form.card.content.heading.kuendigungsrecht",
    defaultMessage: "Kündigungsrecht",
  },
  shared_bssd_form_ssd_form_input_label_text_kuendbar_durch_dn: {
    id: "shared.bssd.form.ssd.form.input.label.text.kuendbar.durch.dn",
    defaultMessage: "Kündbar durch DN",
  },
  shared_bssd_form_ssd_form_menu_item_kuendbar: {
    id: "shared.bssd.form.ssd.form.menu.item.kuendbar",
    defaultMessage: "kündbar",
  },
  shared_bssd_form_ssd_form_menu_item_nicht_kuendbar: {
    id: "shared.bssd.form.ssd.form.menu.item.nicht.kuendbar",
    defaultMessage: "nicht kündbar",
  },
  shared_bssd_form_ssd_form_text_field_label_kuendigungsfrist_in_tagen: {
    id: "shared.bssd.form.ssd.form.text.field.label.kuendigungsfrist.in.tagen",
    defaultMessage: "Kündigungsfrist in Tagen",
  },
  shared_bssd_form_ssd_form_table_cell_text_termin_vorraus: {
    id: "shared.bssd.form.ssd.form.table.cell.text.termin.vorraus",
    defaultMessage: "Termin (vorauss.)",
  },
  shared_bssd_form_ssd_form_table_cell_text_valuta: {
    id: "shared.bssd.form.ssd.form.table.cell.text.valuta",
    defaultMessage: "Valuta",
  },
  shared_bssd_form_ssd_form_table_cell_text_kurs_in_prozent: {
    id: "shared.bssd.form.ssd.form.table.cell.text.kurs.in.prozent",
    defaultMessage: "Kurs in %",
  },
  shared_bssd_form_ssd_form_fab_aria_label_remove: {
    id: "shared.bssd.form.ssd.form.fab.aria.label.remove",
    defaultMessage: "remove",
  },
  shared_bssd_form_ssd_form_fab_aria_label_add: {
    id: "shared.bssd.form.ssd.form.fab.aria.label.add",
    defaultMessage: "add",
  },
  shared_bssd_form_ssd_form_table_cell_text_bitte_mindestens_einen_kuendigungstermin_angeben: {
    id:
      "shared.bssd.form.ssd.form.table.cell.text.bitte.mindestens.einen.kuendigungstermin.angeben",
    defaultMessage: "Bitte mindestens einen Kündigungstermin angeben.",
  },
  shared_bssd_form_ssd_kuendigungs_preview_table_cell_text_termin_vorraus: {
    id: "shared.bssd.form.ssd.kuendigungs.preview.table.cell.text.termin.vorraus",
    defaultMessage: "Termin (vorauss.)",
  },
  shared_bssd_form_ssd_kuendigungs_preview_table_cell_text_valuta: {
    id: "shared.bssd.form.ssd.kuendigungs.preview.table.cell.text.valuta",
    defaultMessage: "Valuta",
  },
  shared_bssd_form_ssd_kuendigungs_preview_table_cell_text_kurs_in_prozent: {
    id: "shared.bssd.form.ssd.kuendigungs.preview.table.cell.text.kurs.in.prozent",
    defaultMessage: "Kurs in %",
  },
  januar: {
    id: "shared.bssd.form.januar",
    defaultMessage: "Januar",
  },
  februar: {
    id: "shared.bssd.form.februar",
    defaultMessage: "Februar",
  },
  maerz: {
    id: "shared.bssd.form.maerz",
    defaultMessage: "März",
  },
  april: {
    id: "shared.bssd.form.april",
    defaultMessage: "April",
  },
  mai: {
    id: "shared.bssd.form.mai",
    defaultMessage: "Mai",
  },
  juni: {
    id: "shared.bssd.form.juni",
    defaultMessage: "Juni",
  },
  juli: {
    id: "shared.bssd.form.juli",
    defaultMessage: "Juli",
  },
  august: {
    id: "shared.bssd.form.august",
    defaultMessage: "August",
  },
  september: {
    id: "shared.bssd.form.september",
    defaultMessage: "September",
  },
  oktober: {
    id: "shared.bssd.form.oktober",
    defaultMessage: "Oktober",
  },
  november: {
    id: "shared.bssd.form.november",
    defaultMessage: "November",
  },
  dezember: {
    id: "shared.bssd.form.dezember",
    defaultMessage: "Dezember",
  },
});
