import { sendSignedHttpRequest } from "../util/requestSigning";

export async function getBusinessPartners(idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/${idProxyAddr}/geschaeftspartner/all`,
    idProxyAddr
  );
}

export async function getPendingInvites(idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/${idProxyAddr}/gponboardinginvite/all`,
    idProxyAddr
  );
}

// ===================== SEND INVITE =====================

export async function getIdentity(idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddr}/who-am-i`,
    idProxyAddr
  );
}

export async function getPublicPlatformMemberInfo(partnerIdProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${partnerIdProxyAddr}/public`,
    partnerIdProxyAddr
  );
}

export async function addPartner(idProxyAddr, partnerPublicInfo) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${idProxyAddr}/geschaeftspartner`,
    idProxyAddr,
    partnerPublicInfo
  );
}

export async function updatePartnerId(pmIdProxyAddr, gpIdProxyAddr, newPartnerId) {
  const data = {
    idProxyAddress: gpIdProxyAddr,
    partnerId: newPartnerId,
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/${pmIdProxyAddr}/geschaeftspartner/update_partner_id`,
    pmIdProxyAddr,
    data
  );
}

export async function sendPartnerInvite(partnerIdProxyAddr, myInfo) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${partnerIdProxyAddr}/gponboardinginvite`,
    partnerIdProxyAddr,
    myInfo
  );
}

// ===================== ACCEPT INVITE =====================

export async function acceptInvite(partnerIdProxyAddr, myInfo) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${partnerIdProxyAddr}/gponboardinginvite/accept`,
    partnerIdProxyAddr,
    myInfo
  );
}

export async function getCompletePartnerInfo(idProxyAddr, partnerIdProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/${idProxyAddr}/gponboardinginvite/${partnerIdProxyAddr}`,
    idProxyAddr
  );
}

export async function finalizeAccept(idProxyAddr, completePartnerInfo) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${idProxyAddr}/gponboardinginvite/finalize`,
    idProxyAddr,
    completePartnerInfo
  );
}

export async function rejectInvite(idProxyAddr, partnerIdProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${partnerIdProxyAddr}/gponboardinginvite/reject/${idProxyAddr}`,
    partnerIdProxyAddr
  );
}

export async function cleanUpRejectedInvites(
  idProxyAddr,
  partnerIdProxyAddr,
  rejector,
  rejectReason
) {
  const data = { ablehnungsgrund: rejectReason, ablehnender: rejector };
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/${idProxyAddr}/gponboardinginvite/${partnerIdProxyAddr}`,
    idProxyAddr,
    data
  );
}

// ===================== CESSION CONNECT GP =====================

export async function connectGp(platformmember_idProxyAddr, gp) {
  const data = {
    ...gp,
    firmenname: gp.name,
  };
  let response;
  try {
    response = await sendSignedHttpRequest(
      "POST",
      `/digits-server/${platformmember_idProxyAddr}/active_geschaeftspartner`,
      platformmember_idProxyAddr,
      data
    );
  } catch (error) {
    // 409 meaning already connected
    response = error.response.status === 409;
  }
  return response;
}

export async function connectGpsInBulk(platformmember_idProxyAddr, gps) {
  const data = {
    geschaeftspartners: gps.map((gp) => ({ ...gp, firmenname: gp.name })),
  };

  const response = await sendSignedHttpRequest(
    "POST",
    `/digits-server/${platformmember_idProxyAddr}/active_geschaeftspartners`,
    platformmember_idProxyAddr,
    data
  );
  return response;
}
