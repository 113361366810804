import { sendTransaction, getCSSDControllerContractMethods } from "./commons";
import { contractCache } from "services/smartContracts/smartContracts";
import { dateToTimestamp } from "util/convertor";
import { CSSDCessionBulkAction, zeroAddress } from "util/constants";

export async function offerMigratedCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerMigratedCession(payingAgent, zeroAddress, cssdId, partialClaimId, cessionId, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndOfferCession(
  account,
  payingAgent,
  seller,
  buyer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  settlementDataHash,
  valueDate,
  externallySettled
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferCession(
      payingAgent,
      seller,
      buyer,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      settlementDataHash,
      dateToTimestamp(valueDate),
      externallySettled
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function offerCession(
  account,
  payingAgent,
  previousConfirmer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerCession(payingAgent, previousConfirmer, cssdId, partialClaimId, cessionId, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function acceptCession(
  account,
  payingAgent,
  previousConfirmer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .acceptCession(payingAgent, previousConfirmer, cssdId, partialClaimId, cessionId, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function bulkActionCession(
  bulkAction,
  account,
  payingAgent,
  firstConfirmers,
  cssdId,
  partialClaimId,
  cessionIds,
  cessionHashes
) {
  if (!Object.values(CSSDCessionBulkAction).includes(bulkAction)) {
    throw new Error(`Invalid argument bulkAction ${bulkAction}`);
  }
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .bulkActionCession(
      payingAgent,
      firstConfirmers,
      cssdId,
      partialClaimId,
      cessionIds,
      cessionHashes,
      bulkAction
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function bulkActionReceivePayment(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  hasRest,
  cessionIds,
  cessionHashes
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .bulkActionReceivePayment(
      payingAgent,
      cssdId,
      partialClaimId,
      hasRest,
      cessionIds,
      cessionHashes
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function bulkNotifyCession(account, payingAgent, cssdId, partialClaimIds) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .bulkActionPartialClaim(payingAgent, cssdId, partialClaimIds)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function updateCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  settlementDataHash,
  throwEvents = true
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updateCession(payingAgent, cssdId, partialClaimId, cessionId, settlementDataHash, throwEvents)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function updateAndAcceptCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  settlementDataHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updateAndAcceptCession(
      payingAgent,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      settlementDataHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function createCession(
  account,
  payingAgent,
  seller,
  buyer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  settlementDataHash,
  cessionValueDate,
  externallySettled
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyCession(
      payingAgent,
      seller,
      buyer,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      settlementDataHash,
      dateToTimestamp(cessionValueDate),
      externallySettled
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndOfferReDocumentedCession(
  account,
  payingAgent,
  seller,
  buyer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  settlementDataHash,
  cessionValueDate,
  externallySettled
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferReDocumentedCession(
      payingAgent,
      seller,
      buyer,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      settlementDataHash,
      dateToTimestamp(cessionValueDate),
      externallySettled
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function offerReDocumentedCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  isRepaid
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerReDocumentedCession(payingAgent, cssdId, partialClaimId, cessionId, cessionHash, isRepaid)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function newPartialClaimForReDocumentedCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  newPartialClaimId,
  newPartialClaimHash,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .newPartialClaimForReDocumentedCession(
      payingAgent,
      cssdId,
      partialClaimId,
      newPartialClaimId,
      newPartialClaimHash,
      cessionId,
      cessionHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function getCessions(payingAgent, cssdId, partialClaimId) {
  const cessions = await contractCache.cssdManagerContract.methods
    .getCessions(payingAgent, cssdId, partialClaimId)
    .call();
  return cessions;
}

export async function getCession(payingAgent, cssdId, partialClaimId, cessionId) {
  const cession = await contractCache.cssdManagerContract.methods
    .getCession(payingAgent, cssdId, partialClaimId, cessionId)
    .call();
  return cession;
}

export async function getParentCessionState(payingAgent, cssdId, partialClaimId) {
  const cession = await contractCache.cssdManagerContract.methods
    .getParentCessionState(payingAgent, cssdId, partialClaimId)
    .call();
  return cession;
}

export async function cancelCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelCession(payingAgent, cssdId, partialClaimId, cessionId, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function rejectCession(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  reasonHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .rejectCession(payingAgent, cssdId, partialClaimId, cessionId, cessionHash, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function confirmPayment(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  hasRest
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .receivePayment(payingAgent, cssdId, partialClaimId, cessionId, cessionHash, hasRest)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function offerCertificate(
  account,
  payingAgent,
  firstConfirmer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerCessionCertificate(
      payingAgent,
      firstConfirmer,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function acceptCert(
  account,
  payingAgent,
  firstConfirmer,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  documentHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .acceptCessionCertificate(
      payingAgent,
      firstConfirmer,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      documentHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function notify(account, payingAgent, cssdId, partialClaimId) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods.notifyCession(payingAgent, cssdId, partialClaimId).encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function acceptCessionExtern(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .acceptCessionExtern(payingAgent, cssdId, partialClaimId, cessionId, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function acceptCessionCertificateExtern(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  cessionId,
  cessionHash,
  documentHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .acceptCessionCertificateExtern(
      payingAgent,
      cssdId,
      partialClaimId,
      cessionId,
      cessionHash,
      documentHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function repaymentReceived(account, payingAgent, cssdId, partialClaimId) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods.repaymentReceived(payingAgent, cssdId, partialClaimId).encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}
