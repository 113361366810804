import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_ssd_status_ungueltiges_dokument: {
    id: "redux.ssd.status.ungueltiges.dokument",
    defaultMessage: "Ungültiges Dokument",
  },
  redux_ssd_status_urkunde_nicht_angenommen: {
    id: "redux.ssd.status.urkunde.nicht.angenommen",
    defaultMessage: "Urkunde nicht angenommen.",
  },
  redux_ssd_status_das_hochgeladene_dokument_entspricht_nicht_dem_bereitgestellten_dokument: {
    id: "redux.ssd.status.das.hochgeladene.dokument.entspricht.nicht.dem.bereitgestellten.dokument",
    defaultMessage: "Das hochgeladene Dokument entspricht nicht dem bereitgestellten Dokument.",
  },
  redux_ssd_status_das_dokument_wurde_nicht_angenommen: {
    id: "redux.ssd.status.das.dokument.wurde.nicht.angenommen",
    defaultMessage: "Das Dokument wurde nicht angenommen,",
  },
  redux_ssd_status_weil_es_mindestens_zwei_digitale_signaturen_beinhalten_muss: {
    id: "redux.ssd.status.weil.es.mindestens.zwei.digitale.signaturen.beinhalten.muss",
    defaultMessage: "weil es mindestens zwei digitale Signaturen beinhalten muss.",
  },
  redux_ssd_status_signieren_erfolgreich: {
    id: "redux.ssd.status.signieren.erfolgreich",
    defaultMessage: "Signieren erfolgreich.",
  },
  redux_ssd_status_signieren_fehlgeschlagen: {
    id: "redux.ssd.status.signieren.fehlgeschlagen",
    defaultMessage: "Signieren fehlgeschlagen.",
  },
  redux_ssd_status_signatur_wurde_angefragt_sie_werden_per_email_benachrichtigt: {
    id: "redux.ssd.status.signatur.wurde.angefragt.sie.werden.per.email.benachrichtigt",
    defaultMessage:
      "Signatur wurde angefragt. Sie werden per Email benachrichtigt, sobald das Dokument signiert wurde.",
  },
  redux_ssd_status_docusign_signieren_fehlgeschlagen: {
    id: "redux.ssd.status.docusign.signieren.fehlgeschlagen",
    defaultMessage: "DocuSign Signieren fehlgeschlagen.",
  },
  redux_ssd_status_bestaetigung_wurde_zurueckgenommen: {
    id: "redux.ssd.status.bestaetigung.wurde.zurueckgenommen",
    defaultMessage: "Bestätigung wurde zurückgenommen.",
  },
  redux_ssd_status_bestaetigung_konnte_nicht_zurueckgenommen_werden: {
    id: "redux.ssd.status.bestaetigung.konnte.nicht.zurueckgenommen.werden",
    defaultMessage: "Bestätigung konnte nicht zurück genommen werden.",
  },
  redux_ssd_status_ssd_wurde_bestaetigt: {
    id: "redux.ssd.status.ssd.wurde.bestaetigt",
    defaultMessage: "SSD wurde bestätigt.",
  },
  redux_ssd_status_ssd_konnte_nicht_bestaetigt_werden: {
    id: "redux.ssd.status.ssd.konnte.nicht.bestaetigt.werden",
    defaultMessage: "SSD konnte nicht bestätigt werden.",
  },
  redux_ssd_status_ssd_wurde_aktualisiert: {
    id: "redux.ssd.status.ssd.wurde.aktualisiert",
    defaultMessage: "SSD wurde aktualisiert.",
  },
  redux_ssd_status_ssd_konnte_nicht_aktualisiert_werden: {
    id: "redux.ssd.status.ssd.konnte.nicht.aktualisiert.werden",
    defaultMessage: "SSD konnte nicht aktualisiert werden.",
  },
  redux_ssd_status_ssd_wurde_aktualisiert_und_bestaetigt: {
    id: "redux.ssd.status.ssd.wurde.aktualisiert.und.bestaetigt",
    defaultMessage: "SSD wurde aktualisiert und bestätigt.",
  },
  redux_ssd_status_ssd_konnte_nicht_aktualisiert_oder_bestaetigt_werden: {
    id: "redux.ssd.status.ssd.konnte.nicht.aktualisiert.oder.bestaetigt.werden",
    defaultMessage: "SSD konnte nicht aktualisiert oder bestätigt werden.",
  },
  redux_ssd_status_ssd_gespeichert: {
    id: "redux.ssd.status.ssd.gespeichert",
    defaultMessage: "SSD gespeichert.",
  },
  redux_ssd_status_ssd_konnten_nicht_gespeichert_werden: {
    id: "redux.ssd.status.ssd.konnten.nicht.gespeichert.werden",
    defaultMessage: "SSD konnten nicht gespeichert werden.",
  },
  redux_ssd_status_ssd_gespeichert_und_bestaetigt: {
    id: "redux.ssd.status.ssd.gespeichert_und_bestaetigt",
    defaultMessage: "SSD gespeichert und bestätigt.",
  },
  redux_ssd_status_ssd_abgelehnt: {
    id: "redux.ssd.status.ssd.abgelehnt",
    defaultMessage: "SSD abgelehnt.",
  },
  redux_ssd_status_ssd_konnte_nicht_abgelehnt_werden: {
    id: "redux.ssd.status.ssd.konnte.nicht.abgelehnt.werden",
    defaultMessage: "SSD konnte nicht abgelehnt werden.",
  },
  redux_ssd_status_ssd_wurde_storniert: {
    id: "redux.ssd.status.ssd.wurde.storniert",
    defaultMessage: "SSD wurde storniert.",
  },
  redux_ssd_status_ssd_konnte_nicht_storniert_werden: {
    id: "redux.ssd.status.ssd.konnte.nicht.storniert.werden",
    defaultMessage: "SSD konnte nicht storniert werden.",
  },
});
