import {
  WALLET_SAVE_SUCCESS,
  WALLET_SAVE_FAILURE,
  WALLET_SAVE_REQUEST,
  CLOSE_SUCCESS_MODAL,
  FETCH_PENDING_WALLET_SUCCESS,
  OPEN_REGISTRATION_CONFIRM_MODAL,
  CLOSE_REGISTRATION_CONFIRM_MODAL,
  ACCEPT_PENDING_WALLET_REQUEST,
  ACCEPT_PENDING_WALLET_SUCCESS,
  ACCEPT_PENDING_WALLET_FAILURE,
  REJECT_PENDING_WALLET_REQUEST,
  REJECT_PENDING_WALLET_SUCCESS,
  REJECT_PENDING_WALLET_FAILURE,
  SET_NAME_FROM_URL,
} from "./actions";

// reducer with initial state
const initialState = {
  saveWalletResponse: {},
  pendingWallets: [],
  modalSaveWalletSuccessOpen: false,
  registrationConfirmModalOpen: false,
  registrationDetailData: {},
  acceptWalletPending: false,
  rejectWalletPending: false,
  saveWalletPending: false,
  mitarbeiterName: "",
};

function walletSaveRequestReducer(state) {
  return {
    ...state,
    saveWalletPending: true,
  };
}

function walletSaveSuccessReducer(state, action) {
  return {
    ...state,
    saveWalletResponse: action.response,
    modalSaveWalletSuccessOpen: true,
    saveWalletPending: false,
  };
}

function walletSaveFailureReducer(state) {
  return {
    ...state,
    saveWalletPending: false,
  };
}

function closeWalletSaveSuccessModalReducer(state) {
  return {
    ...state,
    modalSaveWalletSuccessOpen: false,
  };
}

function fetchPendingWalletSuccessReducer(state, action) {
  return {
    ...state,
    pendingWallets: action.pendingWallets,
  };
}

function openRegistrationConfirmModalReducer(state, action) {
  return {
    ...state,
    registrationConfirmModalOpen: true,
    registrationDetailData: action.detailData,
  };
}

function closeRegistrationConfirmModalReducer(state) {
  return {
    ...state,
    registrationConfirmModalOpen: false,
  };
}

function acceptPendingWalletRequestReducer(state) {
  return {
    ...state,
    acceptWalletPending: true,
  };
}

function acceptPendingWalletFailureReducer(state) {
  return {
    ...state,
    acceptWalletPending: false,
  };
}

function acceptPendingWalletSuccessReducer(state) {
  return {
    ...state,
    acceptWalletPending: false,
  };
}

function rejectPendingWalletRequestReducer(state) {
  return {
    ...state,
    rejectWalletPending: true,
  };
}

function rejectPendingWalletFailureReducer(state) {
  return {
    ...state,
    rejectWalletPending: false,
  };
}

function rejectPendingWalletSuccessReducer(state) {
  return {
    ...state,
    rejectWalletPending: false,
  };
}

function setMitarbeiterNameFromUrlReducer(state, action) {
  return {
    ...state,
    mitarbeiterName: action.name,
  };
}

export function walletRegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PENDING_WALLET_SUCCESS:
      return fetchPendingWalletSuccessReducer(state, action);
    case WALLET_SAVE_REQUEST:
      return walletSaveRequestReducer(state, action);
    case WALLET_SAVE_SUCCESS:
      return walletSaveSuccessReducer(state, action);
    case WALLET_SAVE_FAILURE:
      return walletSaveFailureReducer(state);
    case CLOSE_SUCCESS_MODAL:
      return closeWalletSaveSuccessModalReducer(state, action);
    case OPEN_REGISTRATION_CONFIRM_MODAL:
      return openRegistrationConfirmModalReducer(state, action);
    case CLOSE_REGISTRATION_CONFIRM_MODAL:
      return closeRegistrationConfirmModalReducer(state, action);
    case ACCEPT_PENDING_WALLET_REQUEST:
      return acceptPendingWalletRequestReducer(state, action);
    case ACCEPT_PENDING_WALLET_FAILURE:
      return acceptPendingWalletFailureReducer(state, action);
    case ACCEPT_PENDING_WALLET_SUCCESS:
      return acceptPendingWalletSuccessReducer(state, action);
    case REJECT_PENDING_WALLET_REQUEST:
      return rejectPendingWalletRequestReducer(state, action);
    case REJECT_PENDING_WALLET_FAILURE:
      return rejectPendingWalletFailureReducer(state, action);
    case REJECT_PENDING_WALLET_SUCCESS:
      return rejectPendingWalletSuccessReducer(state, action);
    case SET_NAME_FROM_URL:
      return setMitarbeiterNameFromUrlReducer(state, action);
    default:
      return state;
  }
}
