function action(type, payload = {}) {
  return { type, ...payload };
}

export const TRY_OPEN_CSSD_TERMINATION_DIALOG = "TRY_OPEN_CSSD_TERMINATION_DIALOG";
export const OPEN_CSSD_TERMINATION_DIALOG = "OPEN_CSSD_TERMINATION_DIALOG";
export const OPEN_CSSD_TERMINATION_DIALOG_SUCCESS = "OPEN_CSSD_TERMINATION_DIALOG_SUCCESS";
export const OPEN_CSSD_TERMINATION_DIALOG_FAILURE = "OPEN_CSSD_TERMINATION_DIALOG_FAILURE";
export const CLOSE_CSSD_TERMINATION_DIALOG = "CLOSE_CSSD_TERMINATION_DIALOG";

export const cssdTerminationDialogActions = {
  tryOpen: (cssd, termination, partialClaim) =>
    action(TRY_OPEN_CSSD_TERMINATION_DIALOG, { cssd, termination, partialClaim }),
  open: (cssd, termination, partialClaim) =>
    action(OPEN_CSSD_TERMINATION_DIALOG, { cssd, termination, partialClaim }),
  open_success: (cssd, termination, partialClaim, partialClaimDetail = null) =>
    action(OPEN_CSSD_TERMINATION_DIALOG_SUCCESS, {
      cssd,
      termination,
      partialClaim,
      partialClaimDetail,
    }),
  open_failure: () => action(OPEN_CSSD_TERMINATION_DIALOG_FAILURE),
  close: () => action(CLOSE_CSSD_TERMINATION_DIALOG, {}),
};

export const SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST =
  "SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST";
export const SAVE_AND_CONFIRM_CSSD_TERMINATION_SUCCESS =
  "SAVE_AND_CONFIRM_CSSD_TERMINATION_SUCCESS";
export const SAVE_AND_CONFIRM_CSSD_TERMINATION_FAILURE =
  "SAVE_AND_CONFIRM_CSSD_TERMINATION_FAILURE";

export const CONFIRM_CSSD_TERMINATION_REQUEST = "CONFIRM_CSSD_TERMINATION_REQUEST";
export const CONFIRM_CSSD_TERMINATION_SUCCESS = "CONFIRM_CSSD_TERMINATION_SUCCESS";
export const CONFIRM_CSSD_TERMINATION_FAILURE = "CONFIRM_CSSD_TERMINATION_FAILURE";

export const CANCEL_CSSD_TERMINATION_REQUEST = "CANCEL_CSSD_TERMINATION_REQUEST";
export const CANCEL_CSSD_TERMINATION_SUCCESS = "CANCEL_CSSD_TERMINATION_SUCCESS";
export const CANCEL_CSSD_TERMINATION_FAILURE = "CANCEL_CSSD_TERMINATION_FAILURE";

export const cssdTerminationActions = {
  saveAndConfirmRequest: (account, cssd, termination) =>
    action(SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST, { account, cssd, termination }),
  saveAndConfirmSuccess: () => action(SAVE_AND_CONFIRM_CSSD_TERMINATION_SUCCESS, {}),
  saveAndConfirmFailure: (error) => action(SAVE_AND_CONFIRM_CSSD_TERMINATION_FAILURE, { error }),

  confirmRequest: (account, cssd, termination) =>
    action(CONFIRM_CSSD_TERMINATION_REQUEST, { account, cssd, termination }),
  confirmSuccess: () => action(CONFIRM_CSSD_TERMINATION_SUCCESS, {}),
  confirmFailure: (error) => action(CONFIRM_CSSD_TERMINATION_FAILURE, { error }),

  cancelRequest: (account, cssd, termination) =>
    action(CANCEL_CSSD_TERMINATION_REQUEST, { account, cssd, termination }),
  cancelSuccess: () => action(CANCEL_CSSD_TERMINATION_SUCCESS, {}),
  cancelFailure: (error) => action(CANCEL_CSSD_TERMINATION_FAILURE, { error }),
};

export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST =
  "SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST";
export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS =
  "SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS";
export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE =
  "SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE";

export const CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST =
  "CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST";
export const CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS =
  "CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS";
export const CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE =
  "CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE";

export const CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST = "CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST";
export const CANCEL_PARTIAL_CLAIM_TERMINATION_SUCCESS = "CANCEL_PARTIAL_CLAIM_TERMINATION_SUCCESS";
export const CANCEL_PARTIAL_CLAIM_TERMINATION_FAILURE = "CANCEL_PARTIAL_CLAIM_TERMINATION_FAILURE";

export const partialClaimTerminationActions = {
  saveAndConfirmRequest: (account, partialClaim, termination) =>
    action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST, {
      account,
      partialClaim,
      termination,
    }),
  saveAndConfirmSuccess: () => action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS, {}),
  saveAndConfirmFailure: (error) =>
    action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE, { error }),

  confirmRequest: (account, partialClaim, termination) =>
    action(CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST, { account, partialClaim, termination }),
  confirmSuccess: () => action(CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS, {}),
  confirmFailure: (error) => action(CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE, { error }),

  cancelRequest: (account, partialClaim, termination) =>
    action(CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST, { account, partialClaim, termination }),
  cancelSuccess: () => action(CANCEL_PARTIAL_CLAIM_TERMINATION_SUCCESS, {}),
  cancelFailure: (error) => action(CANCEL_PARTIAL_CLAIM_TERMINATION_FAILURE, { error }),
};

export const CHECK_TERMINATIONS = "CHECK_TERMINATIONS";

export const terminationsCheckActions = {
  check: (terminations, callback, cssd, partialClaim) =>
    action(CHECK_TERMINATIONS, { terminations, callback, cssd, partialClaim }),
};

export const TRY_OPEN_CONFIRM_REVOKE_DIALOG = "TRY_OPEN_CONFIRM_REVOKE_DIALOG";
export const OPEN_CONFIRM_REVOKE_DIALOG = "OPEN_CONFIRM_REVOKE_DIALOG";
export const CLOSE_CONFIRM_REVOKE_DIALOG = "CLOSE_CONFIRM_REVOKE_DIALOG";

export const OFFER_REVOKE_REQUEST = "OFFER_REVOKE_REQUEST";
export const OFFER_REVOKE_SUCCESS = "OFFER_REVOKE_SUCCESS";
export const OFFER_REVOKE_FAILURE = "OFFER_REVOKE_FAILURE";

export const CANCEL_REVOKE_REQUEST = "CANCEL_REVOKE_REQUEST";
export const CANCEL_REVOKE_SUCCESS = "CANCEL_REVOKE_SUCCESS";
export const CANCEL_REVOKE_FAILURE = "CANCEL_REVOKE_FAILURE";

export const ACCEPT_REVOKE_REQUEST = "ACCEPT_REVOKE_REQUEST";
export const ACCEPT_REVOKE_SUCCESS = "ACCEPT_REVOKE_SUCCESS";
export const ACCEPT_REVOKE_FAILURE = "ACCEPT_REVOKE_FAILURE";

export const revokeTerminationActions = {
  tryOpen: (partialClaim, termination) =>
    action(TRY_OPEN_CONFIRM_REVOKE_DIALOG, { partialClaim, termination, onRevoke: true }),
  openConfirmDialog: (partialClaim, termination) =>
    action(OPEN_CONFIRM_REVOKE_DIALOG, { partialClaim, termination }),
  closeConfirmDialog: () => action(CLOSE_CONFIRM_REVOKE_DIALOG),

  offerRequest: (account, partialClaim, termination) =>
    action(OFFER_REVOKE_REQUEST, { account, partialClaim, termination }),
  offerSuccess: () => action(OFFER_REVOKE_SUCCESS),
  offerFailure: (error) => action(OFFER_REVOKE_FAILURE, error),

  cancelRequest: (account, partialClaim, termination) =>
    action(CANCEL_REVOKE_REQUEST, { account, partialClaim, termination }),
  cancelSuccess: () => action(CANCEL_REVOKE_SUCCESS),
  cancelFailure: (error) => action(CANCEL_REVOKE_FAILURE, error),

  acceptRequest: (account, partialClaim, termination) =>
    action(ACCEPT_REVOKE_REQUEST, { account, partialClaim, termination }),
  acceptSuccess: () => action(ACCEPT_REVOKE_SUCCESS),
  acceptFailure: (error) => action(ACCEPT_REVOKE_FAILURE, error),
};

export const DOWNLOAD_TERMINATION = "DOWNLOAD_TERMINATION";
export const downloadTerminationActions = {
  download: (partialClaim, termination) =>
    action(DOWNLOAD_TERMINATION, { partialClaim, termination }),
};
