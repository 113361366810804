import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_cession_abtretung_gespeichert: {
    id: "redux.cession.abtretung.gespeichert",
    defaultMessage: "Abtretung gespeichert.",
  },
  redux_cession_abtretung_konnte_nicht_gespeichert_werden: {
    id: "redux.cession.abtretung.konnte.nicht.gespeichert.werden",
    defaultMessage: "Abtretung konnte nicht gespeichert werden.",
  },
  redux_cession_abtretung_gespeichert_und_bestaetigt: {
    id: "redux.cession.abtretung.gespeichert.und.bestaetigt",
    defaultMessage: "Abtretung gespeichert und bestätigt.",
  },
  redux_cession_abtretung_konnte_nicht_gespeichert_und_bestaetigt_werden: {
    id: "redux.cession.abtretung.konnte.nicht.gespeichert.und.bestaetigt.werden",
    defaultMessage: "Abtretung konnte nicht gespeichert und bestätigt werden.",
  },
  redux_cession_abtretung_wurde_gespeichert: {
    id: "redux.cession.abtretung.wurde.gespeichert",
    defaultMessage: "Abtretung wurde gespeichert.",
  },
  redux_cession_abtretung_wurde_bestaetigt: {
    id: "redux.cession.abtretung.wurde.bestaetigt",
    defaultMessage: "Abtretung wurde bestätigt.",
  },
  redux_cession_abtretung_konnte_nicht_bestaetigt_werden: {
    id: "redux.cession.abtretung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Abtretung konnte nicht bestätigt werden.",
  },
  redux_cession_bitte_ergaenzen_sie_iban_und_bic: {
    id: "redux.cession.bitte.ergaenzen.sie.iban.und.bic",
    defaultMessage: "Bitte ergänzen Sie IBAN und BIC.",
  },
  redux_cession_abtretung_wurde_abgelehnt: {
    id: "redux.cession.abtretung.wurde.abgelehnt",
    defaultMessage: "Abtretung wurde abgelehnt.",
  },
  redux_cession_abtretung_konnte_nicht_abgelehnt_werden: {
    id: "redux.cession.abtretung.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Abtretung konnte nicht abgelehnt werden.",
  },
  redux_cession_abtretung_wurde_storniert: {
    id: "redux.cession.abtretung.wurde.storniert",
    defaultMessage: "Abtretung wurde storniert.",
  },
  redux_cession_abtretung_konnte_nicht_storniert_werden: {
    id: "redux.cession.abtretung.konnte.nicht.storniert.werden",
    defaultMessage: "Abtretung konnte nicht storniert werden.",
  },
  redux_cession_abtretung_wird_angezeigt: {
    id: "redux.cession.abtretung.wird.angezeigt",
    defaultMessage: "Abtretung wird angezeigt.",
  },
  redux_cession_abtretung_konnte_nicht_angezeigt_werden: {
    id: "redux.cession.abtretung.konnte.nicht.angezeigt.werden",
    defaultMessage: "Abtretung konnte nicht angezeigt werden.",
  },
  redux_cession_abtretungszertifikate_wurden_erstellt: {
    id: "redux.cession.abtretungszertifikate.wurden.erstellt",
    defaultMessage: "Abtretungszertifikate wurden erstellt",
  },
  redux_cession_abtretungszertifikate_konnten_nicht_erstellt_werden: {
    id: "redux.cession.abtretungszertifikate.konnten.nicht.erstellt.werden",
    defaultMessage: "Abtretungszertifikate konnten nicht erstellt werden.",
  },
});
