import { contractCache } from "services/smartContracts/smartContracts";
import { sendTransaction, getCSSDControllerContractMethods, toHex } from "./commons";
import { dateToTimestamp } from "util/convertor";
import { CSSDCessionState, zeroAddress, ZERO_HASH } from "util/constants";

export async function bulkCancelMigratedPartialClaim({
  account,
  cssdIds,
  partialClaimIds,
  parentCessionIds,
}) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .bulkCancelMigratedPartialClaims(
      contractCache.identityProxy._address,
      cssdIds,
      partialClaimIds,
      parentCessionIds
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function bulkOfferMigratedPartialClaim({
  account,
  firstConfirmers,
  cssdIds,
  partialClaimIds,
  partialClaimHashes,
  targetStatus,
}) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .bulkOfferMigratedPartialClaim(
      contractCache.identityProxy._address,
      firstConfirmers,
      cssdIds,
      partialClaimIds,
      partialClaimHashes,
      targetStatus
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendModifyMigratedCSSD(
  account,
  seller,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash,
  valueDate,
  maturityDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyMigratedCSSD(
      contractCache.identityProxy._address,
      seller,
      cssdId,
      cssdHash,
      cssdBuyHash,
      dgHash,
      dnHash,
      zsHash,
      valueDate ? dateToTimestamp(valueDate) : 0,
      maturityDate ? dateToTimestamp(maturityDate) : 0
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendModifyAndOfferMigratedCSSD(
  account,
  seller,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash,
  valueDate,
  maturityDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferMigratedCSSD(
      contractCache.identityProxy._address,
      seller,
      cssdId,
      cssdHash,
      cssdBuyHash,
      dgHash,
      dnHash,
      zsHash,
      valueDate ? dateToTimestamp(valueDate) : 0,
      maturityDate ? dateToTimestamp(maturityDate) : 0
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendOfferMigratedCSSD(account, firstConfirmer, cssdId, cssdHash) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerMigratedCSSD(contractCache.identityProxy._address, firstConfirmer, cssdId, cssdHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendModifyCSSD(
  account,
  seller,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash,
  valueDate,
  maturityDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyCSSD(
      contractCache.identityProxy._address,
      seller,
      cssdId,
      cssdHash,
      cssdBuyHash,
      dgHash,
      dnHash,
      zsHash,
      valueDate ? dateToTimestamp(valueDate) : 0,
      maturityDate ? dateToTimestamp(maturityDate) : 0
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendModifyAndOfferCSSD(
  account,
  seller,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash,
  valueDate,
  maturityDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferCSSD(
      contractCache.identityProxy._address,
      seller,
      cssdId,
      cssdHash,
      cssdBuyHash,
      dgHash,
      dnHash,
      zsHash,
      dateToTimestamp(valueDate),
      dateToTimestamp(maturityDate)
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendOfferCSSD(account, firstConfirmer, cssdId, hash) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerCSSD(contractCache.identityProxy._address, firstConfirmer, cssdId, hash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendAcceptCSSD(account, payingAgent, firstConfirmer, cssdId, hash) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods.acceptCSSD(payingAgent, firstConfirmer, cssdId, hash).encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendUpdateCSSD(
  account,
  arranger,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash,
  throwEvents = true
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updateCSSD(arranger, cssdId, cssdHash, cssdBuyHash, dgHash, dnHash, zsHash, throwEvents)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendUpdateAndAcceptCSSD(
  account,
  arranger,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updateAndAcceptCSSD(arranger, cssdId, cssdHash, cssdBuyHash, dgHash, dnHash, zsHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendRejectCSSD(account, arranger, id, fingerprint, reasonFingerprint) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods.rejectCSSD(arranger, id, fingerprint, reasonFingerprint).encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendCancelCSSD(account, id, fingerprint) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelCSSD(contractCache.identityProxy._address, id, fingerprint)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendOfferMigratedPartialClaim(
  account,
  firstConfirmer,
  cssdId,
  partialClaimId,
  partialClaimHash,
  targetStatus = CSSDCessionState.NOTIFIED
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerMigratedPartialClaim(
      contractCache.identityProxy._address,
      firstConfirmer,
      cssdId,
      partialClaimId,
      partialClaimHash,
      targetStatus
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyMigratedPartialClaim(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  parentPartialClaimId,
  newPartialClaimId,
  newPartialClaimHash,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyMigratedPartialClaim(
      payingAgent,
      cssdId,
      cssdHash,
      parentPartialClaimId,
      newPartialClaimId,
      newPartialClaimHash,
      cessionId,
      cessionHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndOfferMigratedPartialClaim(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  parentPartialClaimId,
  newPartialClaimId,
  newPartialClaimHash,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferMigratedPartialClaim(
      payingAgent,
      cssdId,
      cssdHash,
      parentPartialClaimId,
      newPartialClaimId,
      newPartialClaimHash,
      cessionId,
      cessionHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function cancelMigratedPartialClaim(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  parentCessionId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelMigratedPartialClaim(payingAgent, cssdId, partialClaimId, parentCessionId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendFirstPartialClaim(
  account,
  arranger,
  cssdId,
  cssdHash,
  claimId,
  claimHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .firstPartialClaim(arranger, cssdId, cssdHash, claimId, claimHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendNewPartialClaim(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  newPartialClaimId,
  newPartialClaimHash,
  cessionId,
  cessionHash
) {
  const contractMethods = getCSSDControllerContractMethods();

  const data = contractMethods
    .newPartialClaim(
      payingAgent,
      cssdId,
      partialClaimId,
      newPartialClaimId,
      newPartialClaimHash,
      cessionId,
      cessionHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendSignDocumentsBuyer(
  account,
  arranger,
  cssdId,
  cssdHash,
  signedUrkundeHash,
  signedZahlstellenvertragHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .signDocumentsBuyer(arranger, cssdId, cssdHash, signedUrkundeHash, signedZahlstellenvertragHash)
    .encodeABI();

  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendSignDocumentsSeller(
  account,
  payingAgent,
  firstConfirmer,
  cssdId,
  cssdHash,
  signedUrkundeHash,
  signedZahlstellenvertragHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .signDocumentsSeller(
      payingAgent,
      firstConfirmer,
      cssdId,
      cssdHash,
      signedUrkundeHash,
      signedZahlstellenvertragHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function sendRejectDocumentsSeller(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  reasonHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .rejectDocumentsSeller(payingAgent, cssdId, cssdHash, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}
export async function getCSSDInterestNotificationAuditEvent(interestNotificationId) {
  return await contractCache.cssdManagerContract.getPastEvents(
    "CSSDInterestNotificationAuditEvent",
    {
      fromBlock: 0,
      toBlock: "latest",
      filter: { interestNotificationId: interestNotificationId },
    }
  );
}

export async function getCssdAuditEvents(cssdId) {
  return await contractCache.cssdManagerContract.getPastEvents("CSSDAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { cssdId: cssdId },
  });
}

export async function getCssdCessionAuditEventByCessionId(cessionId) {
  return await contractCache.cssdManagerContract.getPastEvents("CSSDCessionAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { cessionId: cessionId },
  });
}

export async function getCssdCessionAuditEventByPartialClaimId(partialClaimId) {
  return await contractCache.cssdManagerContract.getPastEvents("CSSDCessionAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { partialClaimId: partialClaimId, cessionId: "0x0" },
  });
}
export async function getCssdPartialClaimAuditEvent(partialClaimId) {
  return await contractCache.cssdManagerContract.getPastEvents("PartialClaimAuditEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { partialClaimId: partialClaimId },
  });
}

export async function getCssdFourEyesEvents(id, subId = "0x0") {
  const fourEyesEvents = await contractCache.cssdController.getPastEvents("FourEyesEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { id, subid: subId },
  });
  return fourEyesEvents;
}

export async function modifyAndOfferInterestNotification(
  account,
  idProxyAddress,
  cssdId,
  interestNotificationId,
  interestNotificationFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferInterestNotification(
      idProxyAddress,
      cssdId,
      interestNotificationId,
      interestNotificationFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyInterestNotification(
  account,
  idProxyAddress,
  cssdId,
  interestNotificationId,
  interestNotificationFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyInterestNotification(
      idProxyAddress,
      cssdId,
      interestNotificationId,
      interestNotificationFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function offerInterestNotification(
  account,
  idProxyAddress,
  cssdId,
  interestNotificationId,
  interestNotificationFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerInterestNotification(
      idProxyAddress,
      cssdId,
      interestNotificationId,
      interestNotificationFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function repaymentReceived(account, idProxyAddress, cssdId, cssdFingerprint) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .repaymentReceivedCSSD(idProxyAddress, cssdId, cssdFingerprint)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function cancelInterestNotification(
  account,
  idProxyAddress,
  cssdId,
  interestNotificationId,
  interestNotificationFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelInterestNotification(
      idProxyAddress,
      cssdId,
      interestNotificationId,
      interestNotificationFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function closeInterestNotification(
  account,
  idProxyAddress,
  cssdId,
  interestNotificationId,
  interestNotificationFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .closeInterestNotification(
      idProxyAddress,
      cssdId,
      interestNotificationId,
      interestNotificationFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function updateCSSDSettlementInformation(
  account,
  arranger,
  cssdId,
  cssdHash,
  cssdBuyHash,
  dgHash,
  dnHash,
  zsHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updateCSSDSettlementInformation(
      arranger,
      cssdId,
      cssdHash,
      cssdBuyHash,
      dgHash,
      dnHash,
      zsHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function updatePartialClaimSettlementInformation(
  account,
  arranger,
  cssdId,
  partialClaimId,
  partialClaimFingerprint,
  dgFingerprint
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .updatePartialClaimSettlementInformation(
      arranger,
      cssdId,
      partialClaimId,
      partialClaimFingerprint,
      dgFingerprint
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function getTerminationsForPartialClaim(payingAgent, cssdId, partialClaimId) {
  const cession = await contractCache.cssdManagerContract.methods
    .getPartialClaim(payingAgent, cssdId, partialClaimId)
    .call();
  return cession;
}

export async function connectGP(
  account,
  payingAgent,
  firstConfirmer,
  gpAddress,
  gpHash,
  cssdId,
  partialClaimId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .connectGP(payingAgent, firstConfirmer, gpAddress, gpHash, cssdId, partialClaimId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function cancelConnectGP(account, payingAgent, gpHash, cssdId, partialClaimId) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelConnectGP(payingAgent, gpHash, cssdId, partialClaimId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function getConnectGPEvents(cssdId, partialClaimId) {
  return await contractCache.cssdController.getPastEvents("ConnectGPEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: {
      cssdId: cssdId,
      partialClaimId: partialClaimId,
    },
  });
}

export async function checkIfGpConnectionIsPending(
  cssdId,
  partialClaimId,
  idProxyAdresseZahlstelle
) {
  const gpEvents = await getConnectGPEvents(
    toHex(`${cssdId}`),
    partialClaimId ? toHex(`${partialClaimId}`) : ZERO_HASH
  );
  if (gpEvents.length === 0) return false;
  const { gpHash } = gpEvents[gpEvents.length - 1]?.returnValues;
  const connectFourEyes = await getConnectGPFourEyes(
    idProxyAdresseZahlstelle,
    partialClaimId ? toHex(`${partialClaimId}`) : toHex(`${cssdId}`),
    gpHash
  );
  return connectFourEyes.firstConfirmer !== zeroAddress;
}

export async function getConnectGPFourEyes(arranger, cssdId, subid) {
  const fourEyesState = await contractCache.cssdController.methods
    .getFourEyesState(arranger, cssdId, subid)
    .call();
  return fourEyesState;
}

export async function cancelCessions({ account, payingAgent, cssdId, partialClaimId }) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods.cancelCessions(payingAgent, cssdId, partialClaimId).encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function getCancelledIds({ payingAgent, cssdId, partialClaimId }) {
  const ids = await contractCache.cssdManagerContract.methods
    .getCanceledIds(payingAgent, cssdId, partialClaimId)
    .call();
  return ids;
}

export async function repaymentReceivedPartialClaimZS(
  account,
  payingAgent,
  cssdId,
  partialClaimId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .repaymentReceivedPartialClaim(payingAgent, cssdId, partialClaimId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}
