import web3 from "util/web3";
import { appIntl } from "components/i18n/intl";
import { defineMessages } from "react-intl";
import { eventChannel } from "redux-saga";
import { store } from "redux/store";
import { snackbarActions } from "redux/shared/actions";

import {
  ssdEventActions,
  ssdPaymentEventActions,
  fourEyesEventActions,
  providerConnectionStatus,
  ssdRebuyEventActions,
  ssdTerminationEventActions,
} from "redux/ssdEvents/actions";
import {
  SSDState,
  CessionState,
  InterestNotificationState,
  CSSDState,
  PartialClaimState,
  CSSDTerminationState,
  ZERO_HASH,
  CSSDCessionState,
  FourEyesStateType
} from "util/constants";
import { contractCache } from "services/smartContracts/smartContracts";
import {
  getSsdManagerEventListener,
  getCssdManagerEventListener,
  getPlatformManagerEventListener,
  getBSSDControllerEventListener,
  getCSSDControllerEventListener,
  toSha3,
} from "./commons";
import { getSignatureString } from "util/requestSigning";
import { sendPing } from "util/wsPingHelper";
import { cessionEventActions, cessionFourEyesEventActions } from "redux/cession/actions";
import { interestNotificationEventListenerActions } from "redux/interestNotification/actions";
import { memberDataEventListenerActions } from "redux/account/actions";
import { cssdEventActions } from "redux/cssdEvents/actions";
import { toUtf8 } from "services/web3Services/commons";
import {
  partialClaimTerminationActions,
  updatePartialClaimFromEventActions,
} from "redux/cssdTeilforderungen/actions";
import { interestNotificationEventActions } from "redux/cssdInformationsmaterial/actions";

const messages = defineMessages({
  connection_lost: {
    id: "util.wspinghelper.connection.lost",
    defaultMessage: "Verbindung zur Node verloren",
  },
  connection_recovered: {
    id: "util.wspinghelper.connection.recovered",
    defaultMessage: "Verbindung zur Node erholt",
  }
});

//
// LISTENER
//

export function listenForSSDEvents(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.SSDEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (
          event.returnValues.state === SSDState.PREPARED ||
          event.returnValues.state === SSDState.PREPARED_REST
        ) {
          return;
        } else if (
          (event.returnValues.state === SSDState.NEW &&
            contractCache.identityProxy._address === event.returnValues.arranger) ||
          (event.returnValues.state === SSDState.OPEN &&
            contractCache.identityProxy._address === event.returnValues.buyer)
        ) {
          emit(
            ssdEventActions.addSsd(
              event.returnValues.arranger,
              event.returnValues.buyer,
              event.returnValues.id
            )
          );
        } else {
          emit(
            ssdEventActions.updateStatus(
              event.returnValues.id,
              event.returnValues.state,
              event.returnValues.action,
              event.returnValues.hash,
              event.returnValues.arranger
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForTerminationEvents(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.TerminationEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(
          ssdTerminationEventActions.updateStatus(
            event.returnValues.arranger,
            event.returnValues.ssdId,
            event.returnValues.terminationHash,
            event.returnValues.state,
            event.returnValues.action,
            event.returnValues.terminationInitiator,
            event.returnValues.actionInitiator,
            event.returnValues.terminationDate,
            event.returnValues.terminationType
          )
        );
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForSSDPaymentEvents(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.SSDPaymentEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(ssdPaymentEventActions.updateStatus(event.returnValues.id, event.returnValues.state));
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForFourEyesEvents(currentBlock) {
  const contractMethods = getBSSDControllerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.FourEyesEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        //before cession subid equals ZERO_HASH
        if (event.returnValues.subid === ZERO_HASH) {
          emit(
            fourEyesEventActions.updateStatus(
              event.returnValues.id,
              event.returnValues.state,
              event.returnValues.account,
              event.returnValues.arranger
            )
          );
        } else {
          emit(
            cessionFourEyesEventActions.updateCessionFourEyesState(
              event.returnValues.id,
              event.returnValues.subid,
              event.returnValues.state,
              event.returnValues.arranger,
              event.returnValues.account
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForSSDRebuyEvents(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.SSDRebuyEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(
          ssdRebuyEventActions.updateStatus(
            event.returnValues.id,
            event.returnValues.state,
            event.returnValues.hash,
            event.returnValues.action,
            event.returnValues.arranger
          )
        );
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForInterestNotificationEvent(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.InterestNotificationEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (event.returnValues.state === InterestNotificationState.NEW) {
          emit(
            interestNotificationEventListenerActions.addNewInterestNotification(
              event.returnValues.ssdId,
              event.returnValues.arranger,
              event.returnValues.interestNotificationHash,
              event.returnValues.interestNotificationId
            )
          );
        } else {
          emit(
            interestNotificationEventListenerActions.updateState(
              event.returnValues.ssdId,
              event.returnValues.interestNotificationId,
              event.returnValues.interestNotificationHash,
              event.returnValues.state,
              event.returnValues.arranger
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForInterestNotificationFourEyesEvents(currentBlock) {
  const contractMethods = getBSSDControllerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.FourEyesEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(
          interestNotificationEventListenerActions.updateFourEyes(
            event.returnValues.id,
            event.returnValues.subid,
            event.returnValues.state,
            event.returnValues.account
          )
        );
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCessionEvent(currentBlock) {
  const contractMethods = getSsdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.CessionEvent({
      fromBlock: currentBlock,
    });
    web3Emitter.on("data", function (event) {
      if (
        event.returnValues.actionInitiator === contractCache.identityProxy._address &&
        event.returnValues.state === CessionState.NEW
      ) {
        emit(
          cessionEventActions.addNewCessionDG(
            event.returnValues.cessionId,
            event.returnValues.cessionHash,
            event.returnValues.actionInitiator,
            event.returnValues.ssdId,
            event.returnValues.arranger,
            event.returnValues.state,
            event.returnValues.seller
          )
        );
      } else if (
        event.returnValues.buyer === contractCache.identityProxy._address &&
        event.returnValues.state === CessionState.OPEN
      ) {
        emit(
          cessionEventActions.addNewCessionNewDG(
            event.returnValues.cessionId,
            event.returnValues.cessionHash,
            event.returnValues.actionInitiator,
            event.returnValues.ssdId,
            event.returnValues.arranger,
            event.returnValues.state,
            event.returnValues.seller
          )
        );
      } else {
        emit(
          cessionEventActions.updateCession(
            event.returnValues.cessionId,
            event.returnValues.cessionHash,
            event.returnValues.actionInitiator,
            event.returnValues.ssdId,
            event.returnValues.arranger,
            event.returnValues.state,
            event.returnValues.seller
          )
        );
      }
    });
    return () => {
      web3Emitter.off();
    };
  });
}

const updateProvider = async  () => {
  try{
    const urlParam = await getSignatureString(
      "get",
      process.env.REACT_APP_NODE_URL,
      process.env.REACT_APP_SERVER_URL_PREFIX + process.env.REACT_APP_SERVER_URL
    );
    const provider = new web3.providers.WebsocketProvider(
      process.env.REACT_APP_NODE_URL + "?token=" + urlParam, {
        clientConfig: {
          maxReceivedFrameSize: 100000000,
          maxReceivedMessageSize: 100000000,
        }
      }
    );
    return await waitForProvider(provider);
  } catch (e) {
    console.error(e);
    return false;
  }
}

const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const reconnect = async ()=> {
  const connected = await updateProvider();
  if(!connected) {
    await timeout(5000);
    await reconnect();
  }
}

const waitForProvider = (provider) => {
  return new Promise((resolve) => {
    if (provider.connection.readyState === 1 || provider.connection.readyState === 0) {
      provider.on("connect", function () {
        web3.setProvider(provider);
        sendPing();
        resolve(true);
      });
    } else {
      resolve(false);
    }
  })
}

export function listenForProviderDisconnect() {
  return eventChannel((emit) => {
    const web3Emitter = web3;
    const providerSocket = web3Emitter.currentProvider.connection;
    providerSocket.onclose = async () => {
      store.dispatch(snackbarActions.openError(appIntl().formatMessage(messages.connection_lost)));
      await reconnect();
      store.dispatch(snackbarActions.openSuccess(appIntl().formatMessage(messages.connection_recovered)));
      emit(providerConnectionStatus.connected());
    }
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForMemberDataModifiedEvent(currentBlock) {
  const contractMethods = getPlatformManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.MemberDataModifiedEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(
          memberDataEventListenerActions.update(
            event.returnValues.companyAddress,
            event.returnValues.firstConfirmer
          )
        );
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForMemberDataUpdatedEvent(currentBlock) {
  const contractMethods = getPlatformManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.MemberDataUpdatedEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(memberDataEventListenerActions.accept(event.returnValues.companyAddress));
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForMemberDataRejectedEvent(currentBlock) {
  const contractMethods = getPlatformManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.MemberDataRejectedEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        emit(memberDataEventListenerActions.reject(event.returnValues.companyAddress));
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

// CSSD

const isActiveParticipant = (addr, event) =>
  addr === event.returnValues.payingAgent ||
  addr === event.returnValues.seller ||
  addr === event.returnValues.buyer;

const isDN = (addr, event) => {
  return addr === event.returnValues.seller && addr !== event.returnValues.buyer;
};

export function listenForCSSDEvents(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.CSSDEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (
          Number(event.returnValues.state) !== CSSDState.INVALID &&
          Number(event.returnValues.state) !== CSSDState.NEW &&
          isActiveParticipant(contractCache.identityProxy._address, event)
        ) {
          emit(
            cssdEventActions.handleCSSDEvent(
              event.returnValues.id,
              Number(event.returnValues.state),
              event.returnValues.action,
              event.returnValues.hash,
              event.returnValues.payingAgent,
              toSha3(`${event.returnValues.reasonHash}`)
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDFourEyesEvents(currentBlock) {
  const contractMethods = getCSSDControllerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.FourEyesEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (event.returnValues.stateType === FourEyesStateType.CSSD) {
          emit(
            cssdEventActions.handleFourEyesEvent(
              Number(toUtf8(event.returnValues.id)),
              Number(event.returnValues.state),
              event.returnValues.account.toLowerCase()
            )
          );
        }
        if (event.returnValues.stateType === FourEyesStateType.CESSION) {
          emit(
            cssdEventActions.handleCSSDCessionFourEyesEvent(
              Number(toUtf8(event.returnValues.id)),
              Number(toUtf8(event.returnValues.subid)),
              Number(event.returnValues.state),
              event.returnValues.account.toLowerCase()
            )
          );
        }
        if (event.returnValues.stateType === FourEyesStateType.PARTIAL_CLAIM) {
          emit(
            cssdEventActions.handleCSSDPartialClaimFourEyesEvent(
              Number(toUtf8(event.returnValues.id)),
              Number(toUtf8(event.returnValues.subid)),
              Number(event.returnValues.state),
              event.returnValues.account.toLowerCase()
            )
          );
        }
        if (event.returnValues.stateType === FourEyesStateType.CONNECT_GP) {
          emit(
            cssdEventActions.handleFourEyesEvent(
              Number(toUtf8(event.returnValues.id)),
              Number(event.returnValues.state),
              event.returnValues.account.toLowerCase()
            )
          );
          emit(
            cssdEventActions.handleCSSDPartialClaimFourEyesEvent(
              Number(toUtf8(event.returnValues.id)),
              Number(toUtf8(event.returnValues.id)),
              Number(event.returnValues.state),
              event.returnValues.account.toLowerCase()
            )
          );
        }
        if (event.returnValues.stateType === FourEyesStateType.INTEREST_NOTIFICATION) {
          emit(interestNotificationEventActions.fourEyesUpdate(event.returnValues));
        }
        if (event.returnValues.stateType === FourEyesStateType.REVOKE_TERMINATION) {
          emit(
            partialClaimTerminationActions.update(Number(toUtf8(event.returnValues.id)), [
              {
                kuendigungId: Number(toUtf8(event.returnValues.subid)),
                firstConfirmer: event.returnValues.account.toLowerCase(),
              },
            ])
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDCessionEvents(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.CSSDCessionEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (
          Number(event.returnValues.state) > CSSDCessionState.NEW &&
          isActiveParticipant(contractCache.identityProxy._address, event)
        ) {
          if (
            Number(event.returnValues.state) === CSSDCessionState.IN_WORK ||
            Number(event.returnValues.state) === CSSDCessionState.NOTIFIED
          ) {
            emit(
              cssdEventActions.handleCSSDCessionAndRequestEvent(
                Number(toUtf8(event.returnValues.cessionId)),
                Number(event.returnValues.state),
                Number(event.returnValues.action),
                event.returnValues.cessionHash,
                event.returnValues.seller,
                event.returnValues.payingAgent
              )
            );
          } else {
            emit(
              cssdEventActions.handleCSSDCessionEvent(
                Number(toUtf8(event.returnValues.cessionId)),
                Number(event.returnValues.state),
                Number(event.returnValues.action),
                event.returnValues.cessionHash,
                event.returnValues.seller,
                event.returnValues.payingAgent
              )
            );
          }
          emit(
            cssdEventActions.handleCSSDCessionForWorkEvent(
              contractCache.identityProxy._address,
              event
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDPartialClaimEvent(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.PartialClaimEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (
          isActiveParticipant(contractCache.identityProxy._address, event) &&
          Number(event.returnValues.state) >=
            (isDN(contractCache.identityProxy._address, event)
              ? PartialClaimState.NOTIFIED
              : PartialClaimState.NEW)
        ) {
          emit(updatePartialClaimFromEventActions.update(event.returnValues));
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDPartialClaimTerminationEvent(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.PartialClaimTerminationEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (Number(event.returnValues.state) >= CSSDTerminationState.NEW) {
          emit(
            updatePartialClaimFromEventActions.update(
              {
                ...event.returnValues,
                payingAgent: event.returnValues.arranger,
                state: null,
              },
              true,
              Number(toUtf8(event.returnValues.terminationId))
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDInterestNotificationEvent(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.CSSDInterestNotificationEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (isActiveParticipant(contractCache.identityProxy._address, event)) {
          emit(interestNotificationEventActions.event(event.returnValues));
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}

export function listenForCSSDTerminationEvent(currentBlock) {
  const contractMethods = getCssdManagerEventListener();
  return eventChannel((emit) => {
    const web3Emitter = contractMethods.CSSDTerminationEvent({
      fromBlock: currentBlock,
    });
    web3Emitter
      .on("data", function (event) {
        if (
          contractCache.identityProxy._address === event.returnValues.arranger ||
          contractCache.identityProxy._address === event.returnValues.initiator
        ) {
          emit(
            cssdEventActions.handleCSSDEvent(
              event.returnValues.cssdId,
              null,
              null,
              event.returnValues.cssdHash,
              event.returnValues.arranger
            )
          );
        }
      })
      .on("error", (error) => emit(new Error(error)));
    return () => {
      web3Emitter.off();
    };
  });
}
