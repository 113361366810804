import web3 from "./web3";

let timerId = 0;

export function sendPing() {
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(async () => {
    try {
      const result = await web3.eth.getBlockNumber();
      if (typeof result !== "undefined") {
        sendPing();
      }
    } catch (err) {
      console.error("WebSocket disconnected");
    }
  }, process.env.REACT_APP_WS_PING_INTERVALL);
}
