import { call, takeLatest, put, all } from "redux-saga/effects";
import {
  FETCH_RECOVERY_PLATFORM_MEMBER_REQUEST,
  SELECT_COMPANY_FOR_RECOVERY,
  REQUEST_RECOVERY_FOR_WALLET_REQUEST,
  RECOVERY_GRANT_UK_REQUEST,
  INVITE_WALLET_FOR_RECOVERY_REQUEST,
} from "./actions";
import { fetchRecoveryPlatformMemberActions } from "./actions";
import {
  getPlatformMemberForRecovery,
  getPmforIdProx,
  checkIfIsUkOfCompany,
  revokeAllPrivileges,
  checkPrivilegeForCompany,
  grantUkPrivilegeForRecovery,
} from "services/web3Services/recovery";
import { getPlatformMemberDetails } from "services/platformMemberService";
import { activateColleague } from "services/colleagueService";
import {
  fetchEmployeesCompanyRecoveryActions,
  requestRecoveryForWalletActions,
  recoveryGrantUkActions,
  inviteWalletForRecoveryActions,
} from "./actions";
import { deleteColleague, getColleagues, invite } from "services/colleagueService";

import { permissions, EMPLOYEE_STATUS } from "util/constants";
import { snackbarActions } from "redux/shared/actions";
import { requestEtherForWallet } from "services/web3Services/faucet";

import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";

export const recoverySagas = [
  takeLatest(FETCH_RECOVERY_PLATFORM_MEMBER_REQUEST, fetchRecoveryPLatformMember),
  takeLatest(SELECT_COMPANY_FOR_RECOVERY, fetchRecoveryMAsForCompany),
  takeLatest(REQUEST_RECOVERY_FOR_WALLET_REQUEST, employeeWalletRecovery),
  takeLatest(RECOVERY_GRANT_UK_REQUEST, grantUk),
  takeLatest(INVITE_WALLET_FOR_RECOVERY_REQUEST, sendInviteAgain),
];

export function* sendInviteAgain({ companyIdProxy, invitationData }) {
  try {
    yield call(inviteService, {
      companyIdProxy,
      invitationData,
    });
    yield put(inviteWalletForRecoveryActions.success());
  } catch (error) {
    console.error(error);
    yield put(inviteWalletForRecoveryActions.failure(error));
  }
}

export function* grantUk({ account, employeeInfo, activeCompany }) {
  try {
    yield call(
      grantUkPrivilegeForRecovery,
      account,
      employeeInfo.wallet_adresse,
      activeCompany.privilegeManager
    );

    yield call(activateColleague, employeeInfo.wallet_adresse, activeCompany.idProxy._address);

    yield call(fetchRecoveryMAsForCompany, {
      companyIdProxy: activeCompany.idProxy._address,
    });

    yield call(
      requestEtherForWallet,
      account,
      employeeInfo.wallet_adresse,
      activeCompany.idProxy._address
    );

    yield put(recoveryGrantUkActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_recovery_wallet_wurde_als_uk_berechtigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(recoveryGrantUkActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_recovery_recovery_fehlgeschlagen)
      )
    );
  }
}

export function* inviteService({ companyIdProxy, invitationData }) {
  try {
    const response = yield call(invite, invitationData);

    if (response.status >= 200 && response.status < 300) {
      yield call(fetchRecoveryMAsForCompany, { companyIdProxy });
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_recovery_einladung_wurde_versendet)
        )
      );
    } else {
      throw response;
    }
  } catch (err) {
    if (err.response.status === 403)
      yield put(
        snackbarActions.open(
          appIntl().formatMessage(
            messages.redux_recovery_diese_email_wurde_bereits_erfolgreich_eingeladen
          )
        )
      );
    else
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.redux_recovery_einladung_fehlgeschlagen)
        )
      );
  }
}

export function* employeeWalletRecovery({ account, employeeInfo, activeCompany }) {
  try {
    const currentPermissions = yield all(
      permissions.map((permission) => {
        return call(
          checkPrivilegeForCompany,
          permission.id,
          employeeInfo.wallet_adresse,
          activeCompany.privilegeManager
        );
      })
    );

    yield call(
      revokeAllPrivileges,
      account,
      currentPermissions,
      employeeInfo.wallet_adresse,
      activeCompany.privilegeManager
    );

    yield call(deleteColleague, employeeInfo.wallet_adresse, activeCompany.info.idProxyAddress);

    const inviteInfo = {
      ...activeCompany.info,
      ...employeeInfo,
      idProxyAddr: activeCompany.info.idProxyAddress,
    };

    yield call(inviteService, {
      companyIdProxy: activeCompany.info.idProxyAddress,
      invitationData: inviteInfo,
    });

    yield put(requestRecoveryForWalletActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_recovery_nutzer_wurde_zur_recovery_aufgefordert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(requestRecoveryForWalletActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_recovery_recovery_fehlgeschlagen)
      )
    );
  }
}

export function* fetchRecoveryPLatformMember({ recovererAddr }) {
  try {
    const pmRaw = yield call(getPlatformMemberForRecovery, recovererAddr);
    const pmDetails = yield all(
      pmRaw.map((pm) => call(getPlatformMemberDetails, pm.idProxy._address))
    );

    const pmCombined = pmDetails
      .map(({ response }, i) => {
        if (response) {
          return { ...pmRaw[i], info: response.data };
        }
        return null;
      })
      .filter((pm) => pm !== null);

    yield put(fetchRecoveryPlatformMemberActions.success(pmCombined));
  } catch (error) {
    console.error(error);
    yield put(fetchRecoveryPlatformMemberActions.failure(error));
  }
}

export function* fetchRecoveryMAsForCompany({ companyIdProxy }) {
  try {
    const { data } = yield call(getColleagues, companyIdProxy);
    const pm = yield call(getPmforIdProx, companyIdProxy);
    const result = [];
    for (let employee of data) {
      let isUk = false;
      if (employee.wallet_adresse) {
        isUk = yield call(checkIfIsUkOfCompany, pm, employee.wallet_adresse);
      }
      if (
        isUk ||
        employee.status_email === EMPLOYEE_STATUS.INACTIVE ||
        employee.status_email === EMPLOYEE_STATUS.PENDING
      ) {
        result.push(employee);
      }
    }
    yield put(fetchEmployeesCompanyRecoveryActions.success(result));
  } catch (error) {
    console.error(error);
    yield put(fetchEmployeesCompanyRecoveryActions.failure(error));
  }
}
