import React from "react";
import { addDotsComma, formatDate, getSprache, removeDots } from "util/convertor";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { referenzzinssaetze } from "util/constants";
import { appIntl } from "components/i18n/intl";

export default function FormatField({ keyName, value }) {
  /*
  -- In case we do i18n for numbers --
   const formatNumber = (value, decimalplaces) => {
       return appIntl().formatNumber( Number(removeDots(value)), {maximumFractionDigits: decimalplaces});
  }
*/
  const formatNumber = (value, decimalplaces) =>
    addDotsComma(Number(removeDots(value)).toFixed(decimalplaces));

  const formatKurs = (value) => {
    const numberValue = Number(removeDots(value));
    if (numberValue % 1 === 0 || numberValue.toString().split(".")[1].length === 1) {
      return addDotsComma(Number(removeDots(value)).toFixed(2));
    }

    return addDotsComma(numberValue);
  };

  const formatPercent = (value) => {
    const numberValue = Number(removeDots(value));
    const numberWithDecimals = numberValue % 1 === 0 ? numberValue.toFixed(2) : numberValue;
    return addDotsComma(numberWithDecimals) + " %";
  };

  const formatField = (key, value) => {
    if (value == null || value === "") {
      return " -/- ";
    } else {
      const underscoreKeyName = key.replace(/([A-Z])/g, "_$1").toLowerCase();

      switch (underscoreKeyName) {
        case "geschaeftstagekalender":
          return value.toString().replace(/,/g, ", ");
        case "kurs":
        case "kuendigungskurs":
        case "aufschlag":
        case "abschlag":
        case "faktor":
        case "rueckzahlung":
        case "abtretungskurs":
        case "darlehensbetrag":
          return formatKurs(value);
        case "nominal":
        case "kaufpreis":
        case "mindestbetrag_bei_abtretung":
        case "rueckzahlungsbetrag":
        case "zinsbetrag":
        case "zins_betrag":
        case "stueckzinsen":
        case "abgetretenes_nominal":
        case "gegenwert_abtretung":
        case "currency":
          return formatNumber(value, 2);
        case "percent":
        case "marge":
        case "margenanpassung":
        case "festgestellter_referenzzinssatz":
        case "zinssatz":
        case "minimum_zinssatz":
        case "maximum_zinssatz":
          return formatPercent(value);
        case "zinstermin_monat":
          return getFormattedMonths(value);
        case "verzinsung":
          return getVerzinsung(value);
        case "marge_typ":
          return getMargeTyp(value);
        case "zahlungsrhythmus":
          return getZahlungsrhythmus(value);
        case "referenzzinssatz":
          return value in referenzzinssaetze
            ? appIntl().formatMessage(referenzzinssaetze[value])
            : "";
        case "zinsmethode":
          return getZinsmethode(value);
        case "faelligkeitstag_der_zinszahlung":
          return getFaelligkeitstag(value);
        case "berechnungszeitraum_der_zinszahlung":
          return getBerrechnungszeitraum(value);
        case "tilgungsart":
          return getTilgungsart(value);
        case "stepup_stepdown_regelung":
          return getStepUpDown(value);
        case "sprache":
          return getSprache(value);
        case "endfaelligkeit":
        case "erster_zinstermin":
        case "vorletzter_zinstermin":
        case "handelstag":
        case "datum":
        case "datum_abtretung":
        case "valuta_abtretung":
        case "laufzeitbeginn":
        case "abtretungsdatum":
        case "valuta_stueckzinsen":
        case "naechster_zinstermin":
        case "feststellungstag_des_referenzzinses":
        case "zinsperiode_von":
        case "zinsperiode_bis":
        case "zahltag":
        case "naechste_zinsfestsetzung":
        case "valuta":
          if (Number(value)) {
            return formatDate(new Date(Number(value)));
          }
          return value;
        case "zinsfestlegungsart":
          return getZinsfestlegungsart(value);
        default:
          if (Array.isArray(value)) {
            return value.join(", ");
          } else {
            switch (String(value)) {
              case "true":
                return <FormattedMessage {...messages.bool_true} />;
              case "false":
                return <FormattedMessage {...messages.bool_false} />;
              default:
                return value;
            }
          }
      }
    }
  };

  return formatField(keyName, value);
}

export const getFormattedMonths = (idArr) => {
  let arr = idArr;
  if (typeof idArr === "string") {
    arr = idArr.split(",");
  }
  let response = "";
  if (!arr || arr.length < 1) return response;
  response += getMonth(arr[0]);
  for (let i = 1; i < arr.length; i++) {
    response += ", " + getMonth(arr[i]);
  }
  return response;
};

const getMonth = (id) => {
  switch (id) {
    case "januar":
      return appIntl().formatMessage(messages.januar);
    case "februar":
      return appIntl().formatMessage(messages.februar);
    case "maerz":
      return appIntl().formatMessage(messages.maerz);
    case "april":
      return appIntl().formatMessage(messages.april);
    case "mai":
      return appIntl().formatMessage(messages.mai);
    case "juni":
      return appIntl().formatMessage(messages.juni);
    case "juli":
      return appIntl().formatMessage(messages.juli);
    case "august":
      return appIntl().formatMessage(messages.august);
    case "september":
      return appIntl().formatMessage(messages.september);
    case "oktober":
      return appIntl().formatMessage(messages.oktober);
    case "november":
      return appIntl().formatMessage(messages.november);
    case "dezember":
      return appIntl().formatMessage(messages.dezember);
    default:
      return null;
  }
};

export const getMargeTyp = (id) => {
  switch (id) {
    case "KEINE_MARGE":
      return appIntl().formatMessage(messages.keine_marge);
    case "AUFSCHLAG":
      return appIntl().formatMessage(messages.aufschlag);
    case "ABSCHLAG":
      return appIntl().formatMessage(messages.abschlag);
    default:
      return null;
  }
};

export const getZahlungsrhythmus = (id) => {
  switch (id) {
    case "JAEHRLICH":
      return appIntl().formatMessage(messages.zahlungsrhythmus_jaehrlich);
    case "HALBJAEHRLICH":
      return appIntl().formatMessage(messages.zahlungsrhythmus_halbjaehrlich);
    case "VIERTELJAEHRLICH":
      return appIntl().formatMessage(messages.zahlungsrhythmus_vierteljaehrlich);
    case "MONATLICH":
      return appIntl().formatMessage(messages.zahlungsrhythmus_monatlich);
    default:
      return null;
  }
};

export const getZinsmethode = (id) => {
  switch (id) {
    case "ZM_ACT_ACT_ICMA_RULE_251":
      return appIntl().formatMessage(messages.zinsmethode_act_act_icma);
    case "ZM_ACT_ACT_ISDA":
      return appIntl().formatMessage(messages.zinsmethode_act_act_isda);
    case "ZM_ACT_360":
      return appIntl().formatMessage(messages.zinsmethode_act_360);
    case "ZM_ACT_365":
      return appIntl().formatMessage(messages.zinsmethode_act_365);
    case "ZM_30_360":
      return appIntl().formatMessage(messages.zinsmethode_30_360);
    case "ZM_30E_360":
      return appIntl().formatMessage(messages.zinsmethode_30e_360);
    default:
      return null;
  }
};

export const getFaelligkeitstag = (id) => {
  switch (id) {
    case "FOLLOWING":
      return appIntl().formatMessage(messages.faelligkeitstagDerZinszahlung_following_nachfolgend);
    case "MODIFIED_FOLLOWING":
      return appIntl().formatMessage(
        messages.faelligkeitstagDerZinszahlung_modified_following_nachfolgend_modifiziert
      );
    case "PRECEDING":
      return appIntl().formatMessage(messages.faelligkeitstagDerZinszahlung_preceding_vorhergehend);
    default:
      return null;
  }
};

export const getBerrechnungszeitraum = (id) => {
  switch (id) {
    case "UNADJUSTED":
      return appIntl().formatMessage(messages.berechnungszeitraumDerZinszahlung_unadjusted);
    case "ADJUSTED":
      return appIntl().formatMessage(messages.berechnungszeitraumDerZinszahlung_adjusted);
    default:
      return null;
  }
};

export const getStepUpDown = (id) => {
  switch (id) {
    case "KEIN_STEPUP_STEPDOWN":
      return appIntl().formatMessage(messages.stepupStepdownRegelung_keine_regelung);
    case "SIEHE_VERTRAGLICHE_REGELUNG":
      return appIntl().formatMessage(messages.stepupStepdownRegelung_vertragliche_regelung);
    default:
      return null;
  }
};

export const getVerzinsung = (id) => {
  switch (id) {
    case "FIX":
      return appIntl().formatMessage(messages.zins_fix_fix);
    case "VARIABEL":
      return appIntl().formatMessage(messages.zins_fix_var);
    case "FIX_GEMAESS_VERTRAG":
      return appIntl().formatMessage(messages.zins_fix_fix_vertrag);
    case "VARIABEL_GEMAESS_VERTRAG":
      return appIntl().formatMessage(messages.zins_fix_var_vertrag);
    default:
      return null;
  }
};

export const getTilgungsart = (id) => {
  switch (id) {
    case "GESAMTFAELLIG_AM_LAUFZEITENDE":
      return appIntl().formatMessage(messages.gesamttilgung);
    default:
      return null;
  }
};

export const getZinsfestlegungsart = (id) => {
  switch (id) {
    case "VARIABLE_VERZINSUNG":
      return appIntl().formatMessage(messages.variableVerzinsung);
    case "VARIABLE_VERZINSUNG_UND_STEPUP_STEPDOWN":
      return appIntl().formatMessage(messages.variableVerzinsungUpDown);
    case "STEPUP_STEPDOWN":
      return appIntl().formatMessage(messages.stepUpDown);
    default:
      return null;
  }
};
