import { call, put, select, all, takeLeading, spawn } from "redux-saga/effects";
import { snackbarActions } from "redux/shared/actions";
import {
  CONFIRM_CSSD_REQUEST,
  SAVE_CSSDS_REQUEST,
  SAVE_CONFIRM_CSSDS_REQUEST,
  ACCEPT_CSSD_REQUEST,
  UPDATE_CSSD_REQUEST,
  UPDATE_ACCEPT_CSSD_REQUEST,
  REJECT_CSSD_REQUEST,
  CANCEL_CSSD_REQUEST,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
  DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST,
  DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST,
  DOWNLOAD_URKUNDE_PDF_REQUEST,
  saveCssdActions,
  saveAndConfirmCssdActions,
  confirmCssdActions,
  acceptCssdActions,
  updateCssdActions,
  updateAcceptCssdActions,
  rejectCssdActions,
  cancelCssdActions,
  signCssdDocumentsActions,
  downloadCssdConfirmationPdfActions,
  downloadUrkundeZahlstellvertragActions,
  downloadCssdUrkundePdfActions,
  CSSD_REPAYMENT_RECEIVED_REQUEST,
  cssdRepaymentReceivedActions,
  confirmRepaymentReceivedDialogActions,
  SAVE_CSSD_SETTLEMENT_INFORMATION,
  editCssdSettlementInformationActions,
  ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
  REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
} from "./actions";
import {
  sendModifyCSSD as sendModifyCSSDOnBlockchain,
  sendModifyAndOfferCSSD as sendModifyAndOfferCSSDOnBlockchain,
  sendOfferCSSD as sendOfferCSSDOnBlockchain,
  sendAcceptCSSD as sendAcceptCSSDOnBlockchain,
  sendRejectCSSD as sendRejectCSSDOnBlockchain,
  sendCancelCSSD as sendCancelCSSDOnBlockchain,
  sendUpdateCSSD as sendUpdateCSSDOnBlockchain,
  sendUpdateAndAcceptCSSD as sendUpdateAndAcceptCSSDOnBlockchain,
  sendFirstPartialClaim as sendFirstPartialClaimOnBlockchain,
  sendSignDocumentsBuyer as sendSignDocumentsBuyerOnBlockchain,
  sendSignDocumentsSeller as sendSignDocumentsSellerOnBlockchain,
  repaymentReceived as repaymentReceivedOnBlockchain,
  updateCSSDSettlementInformation as updateCSSDSettlementInformationOnBlockchain,
  sendModifyMigratedCSSD as sendModifyMigratedCSSDOnBlockchain,
  sendModifyAndOfferMigratedCSSD as sendModifyAndOfferMigratedCSSDOnBlockchain,
  sendOfferMigratedCSSD as sendOfferMigratedCSSDOnBlockchain,
  cancelCessions as cancelCessionsOnBlockchain,
  getCancelledIds as getCancelledIdsOnBlockchain,
  sendRejectDocumentsSeller as sendRejectDocumentsSellerOnBlockchain,
} from "services/web3Services/cssd";

import {
  postCSSD,
  cssdAblehnen,
  uploadDocumentsCSSD,
  zweitgegenbestaetigung,
  fetchPDF,
  getGeschaeftsbestaetigung,
  createErsteTeilforderung,
  getCssdUrkunde,
  storeSettlementInformation,
  checkIfCancelCessionRequired,
  deleteAllCssdDocuments,
  rejectCssdDocuments,
  updateUrkundeUndZahlstellenvertragSignatureTimestamp,
} from "services/cssdService";
import {
  generateCSSDFingerprints,
  generateCssdSettlementInformationFingerprint,
  generateCssdRejectionFingerprint,
} from "util/fingerprint";
import { toHex, toSha3, getIdProxyAddr } from "services/web3Services/commons";
import { buildPostCssdPayload, hashArrayToNumberArray } from "util/convertor";
import { cssdConfirmModal } from "redux/cssdEvents/actions";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import history from "util/history";
import { sendAbortCessionMail, sendCssdMail } from "services/mailService";
import { zeroAddress, mailTemplates, GeschaeftsvorfallTyp, ZERO_HASH } from "util/constants";
import { _base64ToArrayBuffer } from "util/convertor";
import { ab2str } from "services/web3Services/commons";
import { getAccount } from "redux/selectors";
import { getCreateImported } from "util/fields";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const cssdActionSagas = [
  takeLeading(SAVE_CSSDS_REQUEST, saveCssd),
  takeLeading(SAVE_CONFIRM_CSSDS_REQUEST, saveAndConfirmCssd),
  takeLeading(CONFIRM_CSSD_REQUEST, confirmCssd),
  takeLeading(ACCEPT_CSSD_REQUEST, acceptCssd),
  takeLeading(UPDATE_CSSD_REQUEST, updateCssd),
  takeLeading(UPDATE_ACCEPT_CSSD_REQUEST, updateAndAcceptCssd),
  takeLeading(REJECT_CSSD_REQUEST, rejectCssd),
  takeLeading(CANCEL_CSSD_REQUEST, cancelCssd),
  takeLeading(UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, uploadPDFCssd),
  takeLeading(DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST, downloadCssdConfirmationPdf),
  takeLeading(DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST, downloadUrkundeZahlstellenvertragPDF),
  takeLeading(DOWNLOAD_URKUNDE_PDF_REQUEST, downloadUrkundePDF),
  takeLeading(CSSD_REPAYMENT_RECEIVED_REQUEST, repaymentReceivedCssd),
  takeLeading(SAVE_CSSD_SETTLEMENT_INFORMATION, saveSettlementInformation),
  takeLeading(ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, acceptCssdDocuemntsSaga),
  takeLeading(REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST, rejectCssdDocuemntsSaga),
];

export function* updateExternalCssdData(cssdData, newGp, keyToOverride, throwEvents = true) {
  try {
    const account = yield select(getAccount);
    const updatedSettlementInfos = {
      ...cssdData[keyToOverride],
      idProxyAdresse: newGp,
    };
    const settlementInfosWithFp = yield generateCssdSettlementInformationFingerprint(
      updatedSettlementInfos
    );

    const cssdWithFP = {
      ...cssdData,
      [keyToOverride]: settlementInfosWithFp,
    };

    yield call(
      storeSettlementInformation,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.cssdId,
      settlementInfosWithFp.fingerprint,
      settlementInfosWithFp
    );

    yield call(
      sendUpdateCSSDOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      toHex(`${cssdData.cssdId}`),
      cssdWithFP.fingerprint,
      cssdWithFP.kauf.fingerprint,
      cssdWithFP.darlehensgeber.fingerprint,
      cssdWithFP.darlehensnehmer.fingerprint,
      cssdWithFP.zahlstelle.fingerprint,
      throwEvents
    );
  } catch (error) {
    console.error(error);
  }
}

export function* saveCssd({ account, cssdData }) {
  try {
    let payload = {
      ...buildPostCssdPayload(cssdData),
      imported: getCreateImported(),
    };
    const cssdWithFP = yield generateCSSDFingerprints(payload);
    const isMigrated = cssdWithFP.migriert;

    const { data } = yield call(postCSSD, cssdWithFP.zahlstelle.idProxyAdresse, cssdWithFP);

    yield call(
      isMigrated ? sendModifyMigratedCSSDOnBlockchain : sendModifyCSSDOnBlockchain,
      account,
      cssdWithFP.darlehensnehmer.idProxyAdresse,
      toHex(`${data.cssdId}`),
      cssdWithFP.fingerprint,
      cssdWithFP.kauf.fingerprint,
      cssdWithFP.darlehensgeber.fingerprint,
      cssdWithFP.darlehensnehmer.fingerprint,
      cssdWithFP.zahlstelle.fingerprint,
      cssdWithFP.kauf.valuta,
      cssdWithFP.zinsUndTilgungskonditionen.endfaelligkeit
    );

    yield put(saveCssdActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_save_success)));
    history.push("/cssdoverview");
  } catch (error) {
    console.error(error);
    yield put(saveCssdActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cssd_save_failure)));
  }
}

export function* saveAndConfirmCssd({ account, cssdData }) {
  try {
    let payload = {
      ...buildPostCssdPayload(cssdData),
      imported: getCreateImported(),
    };
    const cssdWithFP = yield generateCSSDFingerprints(payload);
    const isMigrated = cssdWithFP.migriert;

    const { data } = yield call(postCSSD, cssdWithFP.zahlstelle.idProxyAdresse, cssdWithFP);

    if (data.constraintViolations && data.constraintViolations.length > 0 && !isMigrated) {
      throw new Error(
        data.constraintViolations[0].propertyPath + " " + data.constraintViolations[0].message
      );
    }

    yield call(
      isMigrated ? sendModifyAndOfferMigratedCSSDOnBlockchain : sendModifyAndOfferCSSDOnBlockchain,
      account,
      cssdWithFP.darlehensnehmer.idProxyAdresse,
      toHex(`${data.cssdId}`),
      cssdWithFP.fingerprint,
      cssdWithFP.kauf.fingerprint,
      cssdWithFP.darlehensgeber.fingerprint,
      cssdWithFP.darlehensnehmer.fingerprint,
      cssdWithFP.zahlstelle.fingerprint,
      cssdWithFP.kauf.valuta,
      cssdWithFP.zinsUndTilgungskonditionen.endfaelligkeit
    );

    yield put(saveAndConfirmCssdActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_save_and_confirm_success))
    );
    yield put(cssdConfirmModal.close());
    history.push("/cssdoverview");
  } catch (error) {
    console.error(error);
    yield put(saveAndConfirmCssdActions.failure());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_save_and_confirm_failure))
    );
  }
}

export function* createTeilforderung(account, cssdData) {
  try {
    const { data } = yield call(
      createErsteTeilforderung,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.cssdId,
      cssdData.fingerprint
    );

    yield call(
      sendFirstPartialClaimOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      toHex(`${cssdData.cssdId}`),
      cssdData.fingerprint,
      toHex(`${data.id}`),
      cssdData.kauf.fingerprint
    );
  } catch (error) {
    console.error(error);
  }
}

export function* confirmCssd({ account, cssdData }) {
  try {
    const { data: cancelRequired } = yield call(
      checkIfCancelCessionRequired,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.cssdId
    );

    if (cancelRequired) {
      yield call(cancelCssd, { account, cssdData }, false);
    }

    const isMigrated = cssdData.migriert;

    yield call(
      isMigrated ? sendOfferMigratedCSSDOnBlockchain : sendOfferCSSDOnBlockchain,
      account,
      cssdData.firstConfirmer,
      toHex(`${cssdData.cssdId}`),
      cssdData.fingerprint
    );

    if (cssdData.firstConfirmer !== zeroAddress) {
      if (!isMigrated) {
        yield call(createTeilforderung, account, cssdData);
        yield spawn(sendCssdMail, {
          template: mailTemplates.cssd_bestaetigung_zwei,
          arranger: cssdData.zahlstelle.idProxyAdresse,
          cssdId: cssdData.cssdId,
          fingerprint: cssdData.fingerprint,
        });
      }
    }

    yield put(confirmCssdActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.css_accept_cssd_success))
    );
    yield put(cssdConfirmModal.close());
  } catch (error) {
    console.error(error);
    yield put(confirmCssdActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.css_accept_cssd_failure)));
  }
}

export function* acceptCssd({ account, cssdData }) {
  try {
    if (cssdData.firstConfirmer !== zeroAddress) {
      yield call(
        zweitgegenbestaetigung,
        cssdData.zahlstelle.idProxyAdresse,
        cssdData.cssdId,
        cssdData.fingerprint
      );
    }

    yield call(
      sendAcceptCSSDOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.firstConfirmer,
      toHex(`${cssdData.cssdId}`),
      cssdData.fingerprint
    );

    if (cssdData.firstConfirmer !== zeroAddress) {
      yield spawn(sendCssdMail, {
        template: mailTemplates.cssd_gegenbestaetigung_zwei,
        arranger: cssdData.zahlstelle.idProxyAdresse,
        cssdId: cssdData.cssdId,
        fingerprint: cssdData.fingerprint,
      });
    }

    yield put(acceptCssdActions.success());

    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.css_accept_cssd_success))
    );
    yield put(cssdConfirmModal.close());
  } catch (error) {
    yield put(acceptCssdActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.css_accept_cssd_failure)));
  }
}

export function* updateCssd({ account, cssdData }) {
  try {
    const oldFingerprint = cssdData.fingerprint;
    const cssdWithFP = yield generateCSSDFingerprints(buildPostCssdPayload(cssdData));

    const { data } = yield call(postCSSD, cssdWithFP.zahlstelle.idProxyAdresse, cssdWithFP);

    yield call(
      sendUpdateCSSDOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      toHex(`${data.cssdId}`),
      cssdWithFP.fingerprint,
      cssdWithFP.kauf.fingerprint,
      cssdWithFP.darlehensgeber.fingerprint,
      cssdWithFP.darlehensnehmer.fingerprint,
      cssdWithFP.zahlstelle.fingerprint
    );

    yield spawn(sendCssdMail, {
      template: mailTemplates.cssd_abwicklungsinformationen_geaendert,
      arranger: cssdData.zahlstelle.idProxyAdresse,
      cssdId: cssdData.cssdId,
      fingerprint: cssdWithFP.fingerprint,
      oldHash: oldFingerprint,
    });

    yield put(updateCssdActions.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_save_success)));
    yield put(cssdConfirmModal.close());
  } catch (error) {
    console.error(error);
    yield put(updateCssdActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.cssd_save_failure)));
  }
}

export function* updateAndAcceptCssd({ account, cssdData }) {
  try {
    const oldFingerprint = cssdData.fingerprint;
    const cssdWithFP = yield generateCSSDFingerprints(buildPostCssdPayload(cssdData));

    const { data } = yield call(postCSSD, cssdData.zahlstelle.idProxyAdresse, cssdWithFP);

    yield call(
      sendUpdateAndAcceptCSSDOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      toHex(`${data.cssdId}`),
      cssdWithFP.fingerprint,
      cssdWithFP.kauf.fingerprint,
      cssdWithFP.darlehensgeber.fingerprint,
      cssdWithFP.darlehensnehmer.fingerprint,
      cssdWithFP.zahlstelle.fingerprint
    );

    yield spawn(sendCssdMail, {
      template: mailTemplates.cssd_abwicklungsinformationen_geaendert,
      arranger: cssdData.zahlstelle.idProxyAdresse,
      cssdId: cssdData.cssdId,
      fingerprint: cssdWithFP.fingerprint,
      oldHash: oldFingerprint,
    });

    yield put(updateAcceptCssdActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_save_and_confirm_success))
    );
    yield put(cssdConfirmModal.close());
  } catch (error) {
    console.error(error);
    yield put(updateAcceptCssdActions.failure());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_save_and_confirm_failure))
    );
  }
}

export function* rejectCssd({ account, cssdData, reason }) {
  try {
    const toPost = {
      id: cssdData.kauf.kaufId,
      typ: GeschaeftsvorfallTyp.KAUF,
      geschaeftsvorfallFingerprint: cssdData.kauf.fingerprint,
      geschaeftsvorfallFingerprintVersion: cssdData.kauf.fingerprintVersion,
      geschaeftsvorfallFingerprintTimestamp: cssdData.kauf.fingerprintTimestamp,
      ablehnungsgrund: reason,
    };

    const reasonWithFP = yield generateCssdRejectionFingerprint(toPost);

    yield call(
      cssdAblehnen,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.cssdId,
      cssdData.fingerprint,
      reasonWithFP
    );

    yield call(
      sendRejectCSSDOnBlockchain,
      account,
      cssdData.zahlstelle.idProxyAdresse,
      toHex(`${cssdData.cssdId}`),
      cssdData.fingerprint,
      reasonWithFP.ablehnungsgrundFingerprint
    );

    yield spawn(sendCssdMail, {
      template: mailTemplates.cssd_gegenbestaetigung_abgelehnt,
      arranger: cssdData.zahlstelle.idProxyAdresse,
      cssdId: cssdData.cssdId,
      fingerprint: cssdData.fingerprint,
      begruendung: reason,
    });

    yield put(rejectCssdActions.success());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.css_reject_cssd_success))
    );
    yield put(cssdConfirmModal.close());
  } catch (error) {
    console.error(error);
    yield put(rejectCssdActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.css_reject_cssd_failure)));
  }
}

/**
 *
 * @param {account, payingAgent, cssdId, partialClaimId} metadata
 */
function* cancelCessionsForPartialClaim(metadata) {
  yield call(cancelCessionsOnBlockchain, metadata);

  const response = yield call(getCancelledIdsOnBlockchain, metadata);
  const zessionarCessionIds = [];
  const zedentCessionIds = [];

  if (response.partialClaimIds.length > 0) {
    for (let index = 0; index < response.partialClaimIds.length; index++) {
      const result = yield call(cancelCessionsForPartialClaim, {
        ...metadata,
        partialClaimId: response.partialClaimIds[index],
      });
      zessionarCessionIds.push(...result.zessionarCessionIds);
      zedentCessionIds.push(...result.zedentCessionIds);
    }
  }

  return {
    zedentCessionIds: [...zedentCessionIds, ...response.zedentCessionIds],
    zessionarCessionIds: [...zessionarCessionIds, ...response.zessionarCessionIds],
  };
}

export function* cancelCssdProcess(account, cssdData) {
  const { zedentCessionIds, zessionarCessionIds } = yield call(cancelCessionsForPartialClaim, {
    account,
    payingAgent: cssdData.zahlstelle.idProxyAdresse,
    cssdId: toHex(`${cssdData.cssdId}`),
    partialClaimId: ZERO_HASH,
  });

  const zedentCessionIdsNumeric = hashArrayToNumberArray(zedentCessionIds);
  const zessionarCessionIdsNumeric = hashArrayToNumberArray(zessionarCessionIds);
  if (!cssdData.migriert) {
    yield spawn(
      sendAbortCessionMail,
      cssdData.zahlstelle.idProxyAdresse,
      cssdData.cssdId,
      "cssd_storno_uebertragung",
      zessionarCessionIdsNumeric,
      zedentCessionIdsNumeric
    );
  }
}

export function* cancelCssd({ account, cssdData }, cancelCssd = true) {
  try {
    if (cancelCssd) {
      yield call(
        sendCancelCSSDOnBlockchain,
        account,
        toHex(`${cssdData.cssdId}`),
        cssdData.fingerprint
      );
    }

    yield call(cancelCssdProcess, account, cssdData);

    yield call(deleteAllCssdDocuments, cssdData.zahlstelle.idProxyAdresse, cssdData.cssdId);

    yield put(cancelCssdActions.success());
    if (cancelCssd) {
      yield put(
        snackbarActions.openSuccess(appIntl().formatMessage(messages.css_cancel_cssd_success))
      );
      yield put(cssdConfirmModal.close());
      history.push("/cssdoverview");
    }
  } catch (error) {
    console.error(error);
    yield put(cancelCssdActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.css_cancel_cssd_failure)));
  }
}

function getDocumentHash(file) {
  const fileBuffer = _base64ToArrayBuffer(file);
  const fileString = ab2str(fileBuffer);
  const hash = toSha3(fileString);
  return hash;
}

function* uploadPDFCssd({ cssd, files }) {
  try {
    const account = yield select(getAccount);
    files.geschaeftspartnerTyp = "ZAHLSTELLE";

    yield call(
      uploadDocumentsCSSD,
      cssd.zahlstelle.idProxyAdresse,
      cssd.cssdId,
      cssd.fingerprint,
      files
    );

    const urkundeSignedHash = getDocumentHash(files.urkunde);
    const zahlstellenvertragSignedHash = getDocumentHash(files.zahlstellenvertrag);

    yield call(
      sendSignDocumentsBuyerOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      cssd.fingerprint,
      urkundeSignedHash,
      zahlstellenvertragSignedHash
    );

    const mailTemplate = "cssd_urkunde_und_zahlstellenvertrag_hochgeladen";

    yield spawn(sendCssdMail, {
      template: mailTemplates[mailTemplate],
      arranger: cssd.zahlstelle.idProxyAdresse,
      cssdId: cssd.cssdId,
      fingerprint: cssd.fingerprint,
    });

    yield put(signCssdDocumentsActions.uploadSuccess());
    yield put(signCssdDocumentsActions.closeDialog());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_save_success)));
  } catch (error) {
    yield put(signCssdDocumentsActions.uploadFailure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.css_upload_document_failure))
    );
  }
}

export function* acceptCssdDocuemntsSaga({ cssd }) {
  try {
    const account = yield select(getAccount);

    yield call(
      sendSignDocumentsSellerOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      cssd.firstConfirmer,
      toHex(`${cssd.cssdId}`),
      cssd.fingerprint,
      ZERO_HASH,
      ZERO_HASH
    );

    let messageForSuccess;
    if (cssd.firstConfirmer === zeroAddress) {
      messageForSuccess = messages.cssd_documents_first_accept_success;
    } else {
      messageForSuccess = messages.cssd_documents_second_accept_success;
      yield call(
        updateUrkundeUndZahlstellenvertragSignatureTimestamp,
        cssd.zahlstelle.idProxyAdresse,
        cssd.cssdId,
        cssd.fingerprint
      );
    }

    yield put(signCssdDocumentsActions.acceptSuccess());
    yield put(signCssdDocumentsActions.closeDialog());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messageForSuccess)));
  } catch (error) {
    console.error(error);
    yield put(signCssdDocumentsActions.closeDialog());
    yield put(signCssdDocumentsActions.acceptFailure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_documents_accept_failure))
    );
  }
}

export function* rejectCssdDocuemntsSaga({ cssd, reason }) {
  try {
    const account = yield select(getAccount);

    const toPost = {
      id: cssd.kauf.kaufId,
      typ: GeschaeftsvorfallTyp.KAUF,
      geschaeftsvorfallFingerprint: cssd.kauf.fingerprint,
      geschaeftsvorfallFingerprintVersion: cssd.kauf.fingerprintVersion,
      geschaeftsvorfallFingerprintTimestamp: cssd.kauf.fingerprintTimestamp,
      ablehnungsgrund: reason,
    };

    const reasonWithFP = yield generateCssdRejectionFingerprint(toPost);

    yield call(
      rejectCssdDocuments,
      cssd.zahlstelle?.idProxyAdresse,
      cssd.cssdId,
      cssd.fingerprint,
      reasonWithFP
    );

    yield call(
      sendRejectDocumentsSellerOnBlockchain,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      cssd.fingerprint,
      reasonWithFP.ablehnungsgrundFingerprint
    );

    yield spawn(sendCssdMail, {
      template: mailTemplates.cssd_urkunde_und_zahlstellenvertrag_abgelehnt,
      arranger: cssd.zahlstelle.idProxyAdresse,
      cssdId: cssd.cssdId,
      fingerprint: cssd.fingerprint,
      ablehnungsgrund: reason,
    });

    yield put(signCssdDocumentsActions.rejectSuccess());
    yield put(signCssdDocumentsActions.closeDialog());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_documents_reject_success))
    );
  } catch (error) {
    yield put(signCssdDocumentsActions.rejectFailure(error));
    yield put(signCssdDocumentsActions.closeDialog());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_documents_reject_failure))
    );
  }
}

export function* downloadUrkundeZahlstellenvertragPDF({ cssd, documentType }) {
  try {
    yield call(
      fetchPDF,
      cssd.zahlstelle.idProxyAdresse,
      cssd.cssdId,
      cssd.fingerprint,
      documentType
    );
    yield put(downloadUrkundeZahlstellvertragActions.success());
  } catch (error) {
    yield put(downloadUrkundeZahlstellvertragActions.failure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.css_download_document_failure))
    );
  }
}

export function* downloadCssdConfirmationPdf({ cssd }) {
  try {
    yield call(
      getGeschaeftsbestaetigung,
      cssd.zahlstelle.idProxyAdresse,
      cssd.cssdId,
      cssd.fingerprint,
      cssd.kauf.geschaeftsnummer
    );
    yield put(downloadCssdConfirmationPdfActions.success());
  } catch (error) {
    console.error(error);
    yield put(downloadCssdConfirmationPdfActions.failure(error));
  }
}

export function* downloadUrkundePDF({ id_proxy_arranger, cssdId }) {
  try {
    yield call(getCssdUrkunde, id_proxy_arranger, cssdId);
    yield put(downloadCssdUrkundePdfActions.success());
  } catch (error) {
    console.error(error);
    yield put(downloadCssdUrkundePdfActions.failure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.css_download_document_failure))
    );
  }
}

export function* repaymentReceivedCssd() {
  try {
    const state = yield select();
    const account = state.signInReducer.account;
    const cssd = state.cssdActionReducer.confirmRepaymentReceivedDialog;
    yield call(
      repaymentReceivedOnBlockchain,
      account,
      getIdProxyAddr(),
      toHex(`${cssd.cssdId}`),
      cssd.fingerprint
    );
    yield put(cssdRepaymentReceivedActions.success());
    yield put(confirmRepaymentReceivedDialogActions.close());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_repayment_received_success))
    );
  } catch (error) {
    console.error(error);
    yield put(cssdRepaymentReceivedActions.failure());
    yield put(confirmRepaymentReceivedDialogActions.close());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_repayment_received_failure))
    );
  }
}

export function* saveSettlementInformation({ cssd, isDN }) {
  try {
    const payingAgent = cssd.zahlstelle.idProxyAdresse;
    const account = yield select(getAccount);

    if (isDN) {
      const settlementInfos = yield generateCssdSettlementInformationFingerprint(
        cssd.darlehensnehmer
      );
      yield all([
        call(
          storeSettlementInformation,
          payingAgent,
          cssd.cssdId,
          settlementInfos.fingerprint,
          settlementInfos
        ),
        call(
          updateCSSDSettlementInformationOnBlockchain,
          account,
          payingAgent,
          toHex(`${cssd.cssdId}`),
          cssd.fingerprint,
          cssd.kauf.fingerprint,
          cssd.darlehensgeber.fingerprint,
          settlementInfos.fingerprint,
          cssd.zahlstelle.fingerprint
        ),
      ]);
    } else {
      const settlementInfos = yield generateCssdSettlementInformationFingerprint(cssd.zahlstelle);
      const settlementInfosDg = {
        ...settlementInfos,
        cssdAbwicklungsinformationenId: cssd.darlehensgeber.cssdAbwicklungsinformationenId,
      };
      yield all([
        call(
          storeSettlementInformation,
          payingAgent,
          cssd.cssdId,
          settlementInfos.fingerprint,
          settlementInfos
        ),
        call(
          storeSettlementInformation,
          payingAgent,
          cssd.cssdId,
          settlementInfosDg.fingerprint,
          settlementInfosDg
        ),
        call(
          updateCSSDSettlementInformationOnBlockchain,
          account,
          payingAgent,
          toHex(`${cssd.cssdId}`),
          cssd.fingerprint,
          cssd.kauf.fingerprint,
          settlementInfosDg.fingerprint,
          cssd.darlehensnehmer.fingerprint,
          settlementInfos.fingerprint
        ),
      ]);
    }
    yield put(editCssdSettlementInformationActions.success());
    yield put(editCssdSettlementInformationActions.closeModal());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_settlement_information_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(editCssdSettlementInformationActions.failure());
    yield put(editCssdSettlementInformationActions.closeModal());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_settlement_information_failure)
      )
    );
  }
}
