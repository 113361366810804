function action(type, payload = {}) {
  return { type, ...payload };
}

export const OPEN_OVERVIEW_CSSD_CESSION = "OPEN_OVERVIEW_CSSD_CESSION";

export const overviewCssdCession = {
  openOverview: (partialClaim, cssd) => action(OPEN_OVERVIEW_CSSD_CESSION, { partialClaim, cssd }),
};

export const OPEN_REBUY_CSSD = "OPEN_REBUY_CSSD";

export const rebuyCssdActions = {
  register: (partialClaim) => action(OPEN_REBUY_CSSD, { partialClaim }),
};

export const TRY_OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG =
  "TRY_OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG";
export const OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG =
  "OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG";
export const CLOSE_REDOCUMENTED_REBUY_REGISTRATION_DIALOG =
  "CLOSE_REDOCUMENTED_REBUY_REGISTRATION_DIALOG";
export const redocumentedRebuyActions = {
  tryOpenDialog: (partialClaim) =>
    action(TRY_OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG, { partialClaim, extern: true }),
  openDialog: (data) => action(OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG, { data }),
  closeDialog: () => action(CLOSE_REDOCUMENTED_REBUY_REGISTRATION_DIALOG),
};

export const CESSION_SET_CSSD_PARTIAL_CLAIM = "CESSION_SET_CSSD_PARTIAL_CLAIM";
export const OPEN_CSSD_CESSION_OVERVIEW = "OPEN_CSSD_CESSION_OVERVIEW";
export const CESSION_SET_CSSD = "CESSION_SET_CSSD";
export const UPDATE_CSSD_CESSION = "UPDATE_CSSD_CESSION";
export const REMOVE_CSSD_CESSION = "REMOVE_CSSD_CESSION";
export const REMOVE_CSSD_CESSION_REQUEST = "REMOVE_CSSD_CESSION_REQUEST";

export const cessionActions = {
  setPartialClaim: (partialClaim) => action(CESSION_SET_CSSD_PARTIAL_CLAIM, { partialClaim }),
  setCssd: (cssd) => action(CESSION_SET_CSSD, { cssd }),
  openOverview: (cssd) => action(OPEN_CSSD_CESSION_OVERVIEW, { cssd }),
  update: (cession) => action(UPDATE_CSSD_CESSION, { cession }),
  remove: (id) => action(REMOVE_CSSD_CESSION, { id }),
  removeRequest: (id) => action(REMOVE_CSSD_CESSION_REQUEST, { id }),
};

export const EDIT_CSSD_CESSION = "EDIT_CSSD_CESSION";
export const DETAIL_CSSD_CESSION = "DETAIL_CSSD_CESSION";
export const CLOSE_CSSD_CESSION_MODALS = "CLOSE_CSSD_CESSION_MODALS";
export const REVIEW_CSSD_CESSION_REQUEST = "REVIEW_CSSD_CESSION_REQUEST";

export const cessionModals = {
  editCession: (cession) => action(EDIT_CSSD_CESSION, { cession }),
  detailCession: (cession) => action(DETAIL_CSSD_CESSION, { cession }),
  reviewCessionRequest: (cession) => action(REVIEW_CSSD_CESSION_REQUEST, { cession }),
  close: () => action(CLOSE_CSSD_CESSION_MODALS),
};

export const REDOCUMENT_CESSION_REQUEST = "REDOCUMENT_CESSION_REQUEST";
export const REDOCUMENT_CESSION_FAILURE = "REDOCUMENT_CESSION_FAILURE";
export const REDOCUMENT_CESSION_SUCCESS = "REDOCUMENT_CESSION_SUCCESS";

export const redocumentCessionActions = {
  request: (account, cession, partialClaim) =>
    action(REDOCUMENT_CESSION_REQUEST, { account, cession, partialClaim }),
  failure: () => action(REDOCUMENT_CESSION_FAILURE, {}),
  success: () => action(REDOCUMENT_CESSION_SUCCESS, {}),
};

export const CONFIRM_REDOCUMENT_CESSION_REQUEST = "CONFIRM_REDOCUMENT_CESSION_REQUEST";
export const CONFIRM_REDOCUMENT_CESSION_FAILURE = "CONFIRM_REDOCUMENT_CESSION_FAILURE";
export const CONFIRM_REDOCUMENT_CESSION_SUCCESS = "CONFIRM_REDOCUMENT_CESSION_SUCCESS";

export const confirmRedocumentCessionActions = {
  request: (account, cssd, partialClaim, cession) =>
    action(CONFIRM_REDOCUMENT_CESSION_REQUEST, { account, cssd, partialClaim, cession }),
  failure: () => action(CONFIRM_REDOCUMENT_CESSION_FAILURE, {}),
  success: () => action(CONFIRM_REDOCUMENT_CESSION_SUCCESS, {}),
};

export const OPEN_PAYMENT_CONFIRMED_DIALOG = "OPEN_PAYMENT_CONFIRMED_DIALOG";
export const CLOSE_PAYMENT_CONFIRMED_DIALOG = "CLOSE_PAYMENT_CONFIRMED_DIALOG";
export const OPEN_CERTIFICATE_CONFIRMATION_DIALOG = "OPEN_CERTIFICATE_CONFIRMATION_DIALOG";
export const CLOSE_CERTIFICATE_CONFIRMATION_DIALOG = "CLOSE_CERTIFICATE_CONFIRMATION_DIALOG";
export const OPEN_CERTIFICATE_ACCEPT_DIALOG = "OPEN_CERTIFICATE_ACCEPT_DIALOG";
export const CLOSE_CERTIFICATE_ACCEPT_DIALOG = "CLOSE_CERTIFICATE_ACCEPT_DIALOG";

export const OPEN_CONFIRM_CANCEL_CESSION_DIALOG = "OPEN_CONFIRM_CANCEL_CESSION_DIALOG";
export const CLOSE_CONFIRM_CANCEL_CESSION_DIALOG = "CLOSE_CONFIRM_CANCEL_CESSION_DIALOG";

export const OPEN_CONFIRM_NOTIFY_CESSION_DIALOG = "OPEN_CONFIRM_NOTIFY_CESSION_DIALOG";
export const CLOSE_CONFIRM_NOTIFY_CESSION_DIALOG = "CLOSE_CONFIRM_NOTIFY_CESSION_DIALOG";

export const OPEN_ACCEPT_FOR_EXT_DIALOG = "OPEN_ACCEPT_FOR_EXT_DIALOG";
export const CLOSE_ACCEPT_FOR_EXT_DIALOG = "CLOSE_ACCEPT_FOR_EXT_DIALOG";

export const OPEN_CONFIRM_PAYMENT_FOR_REBUY_DIALOG = "OPEN_CONFIRM_PAYMENT_FOR_REBUY_DIALOG";
export const CLOSE_CONFIRM_PAYMENT_FOR_REBUY_DIALOG = "CLOSE_CONFIRM_PAYMENT_FOR_REBUY_DIALOG";

export const cessionDialogs = {
  openPaymentConfirmedDialog: (cession) => action(OPEN_PAYMENT_CONFIRMED_DIALOG, { cession }),
  closePaymentConfirmedDialog: () => action(CLOSE_PAYMENT_CONFIRMED_DIALOG, {}),

  openCertificateConfirmationDialog: (cession) =>
    action(OPEN_CERTIFICATE_CONFIRMATION_DIALOG, { cession }),
  closeCertificateConfirmationDialog: () => action(CLOSE_CERTIFICATE_CONFIRMATION_DIALOG, {}),

  openCertificateAcceptDialog: (cession) => action(OPEN_CERTIFICATE_ACCEPT_DIALOG, { cession }),
  closeCertificateAcceptDialog: () => action(CLOSE_CERTIFICATE_ACCEPT_DIALOG, {}),

  openConfirmCancelDialog: (cession) => action(OPEN_CONFIRM_CANCEL_CESSION_DIALOG, { cession }),
  closeConfirmCancelDialog: () => action(CLOSE_CONFIRM_CANCEL_CESSION_DIALOG, {}),

  openConfirmNotifyCessionDialog: (partialClaim) =>
    action(OPEN_CONFIRM_NOTIFY_CESSION_DIALOG, { partialClaim }),
  closeConfirmNotifyCessionDialog: () => action(CLOSE_CONFIRM_NOTIFY_CESSION_DIALOG, {}),

  openAcceptForExtDialog: (cession) => action(OPEN_ACCEPT_FOR_EXT_DIALOG, { cession }),
  closeAcceptForExtDialog: () => action(CLOSE_ACCEPT_FOR_EXT_DIALOG, {}),

  openConfirmPaymentForRebuyDialog: (partialClaim) =>
    action(OPEN_CONFIRM_PAYMENT_FOR_REBUY_DIALOG, { partialClaim }),
  closeConfirmPaymentForRebuyDialog: () => action(CLOSE_CONFIRM_PAYMENT_FOR_REBUY_DIALOG, {}),
};

export const OFFER_CSSD_CESSION_CERTIFICATE_REQUEST = "OFFER_CSSD_CESSION_CERTIFICATE_REQUEST";
export const OFFER_CSSD_CESSION_CERTIFICATE_SUCCESS = "OFFER_CSSD_CESSION_CERTIFICATE_SUCCESS";
export const OFFER_CSSD_CESSION_CERTIFICATE_FAILURE = "OFFER_CSSD_CESSION_CERTIFICATE_FAILURE";

export const offerCertificateActions = {
  request: (account, partialClaim, cessionToConfirm) =>
    action(OFFER_CSSD_CESSION_CERTIFICATE_REQUEST, {
      account,
      partialClaim,
      cessionToConfirm,
    }),
  success: () => action(OFFER_CSSD_CESSION_CERTIFICATE_SUCCESS, {}),
  failure: (error) => action(OFFER_CSSD_CESSION_CERTIFICATE_FAILURE, { error }),
};

export const ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST = "ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST";
export const ACCEPT_CSSD_CESSION_CERTIFICATE_SUCCESS = "ACCEPT_CSSD_CESSION_CERTIFICATE_SUCCESS";
export const ACCEPT_CSSD_CESSION_CERTIFICATE_FAILURE = "ACCEPT_CSSD_CESSION_CERTIFICATE_FAILURE";

export const acceptCertificateActions = {
  request: (account, cessionToAccept) =>
    action(ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST, {
      account,
      cessionToAccept,
    }),
  success: () => action(ACCEPT_CSSD_CESSION_CERTIFICATE_SUCCESS, {}),
  failure: (error) => action(ACCEPT_CSSD_CESSION_CERTIFICATE_FAILURE, { error }),
};

export const DOWNLOAD_CSSD_CESSION_CONFIRMATION_REQUEST =
  "DOWNLOAD_CSSD_CESSION_CONFIRMATION_REQUEST";
export const DOWNLOAD_CSSD_CESSION_CONFIRMATION_SUCCESS =
  "DOWNLOAD_CSSD_CESSION_CONFIRMATION_SUCCESS";
export const DOWNLOAD_CSSD_CESSION_CONFIRMATION_FAILURE =
  "DOWNLOAD_CSSD_CESSION_CONFIRMATION_FAILURE";

export const cessionConfirmation = {
  request: (partialClaim, cession) =>
    action(DOWNLOAD_CSSD_CESSION_CONFIRMATION_REQUEST, {
      partialClaim,
      cession,
    }),
  success: () => action(DOWNLOAD_CSSD_CESSION_CONFIRMATION_SUCCESS, {}),
  failure: () => action(DOWNLOAD_CSSD_CESSION_CONFIRMATION_FAILURE, {}),
};

export const DOWNLOAD_CSSD_CESSION_NOTIFICATION_REQUEST =
  "DOWNLOAD_CSSD_CESSION_NOTIFICATION_REQUEST";
export const DOWNLOAD_CSSD_CESSION_NOTIFICATION_SUCCESS =
  "DOWNLOAD_CSSD_CESSION_NOTIFICATION_SUCCESS";
export const DOWNLOAD_CSSD_CESSION_NOTIFICATION_FAILURE =
  "DOWNLOAD_CSSD_CESSION_NOTIFICATION_FAILURE";

export const cessionNotificationActions = {
  download: (partialClaim) => action(DOWNLOAD_CSSD_CESSION_NOTIFICATION_REQUEST, { partialClaim }),
  success: () => action(DOWNLOAD_CSSD_CESSION_NOTIFICATION_SUCCESS),
  failure: () => action(DOWNLOAD_CSSD_CESSION_NOTIFICATION_FAILURE),
};

export const CSSD_PAYMENT_CONFIRMED_REQUEST = "CSSD_PAYMENT_CONFIRMED_REQUEST";
export const CSSD_PAYMENT_CONFIRMED_SUCCESS = "CSSD_PAYMENT_CONFIRMED_SUCCESS";
export const CSSD_PAYMENT_CONFIRMED_FAILURE = "CSSD_PAYMENT_CONFIRMED_FAILURE";

export const cssdPaymentConfirmedActions = {
  request: (account, cssd, partialClaim, cession) =>
    action(CSSD_PAYMENT_CONFIRMED_REQUEST, { account, cssd, partialClaim, cession }),
  success: () => action(CSSD_PAYMENT_CONFIRMED_SUCCESS, {}),
  failure: (error) => action(CSSD_PAYMENT_CONFIRMED_FAILURE, { error }),
};

export const CREATE_CSSD_CESSION_REQUEST = "CREATE_CSSD_CESSION_REQUEST";
export const CREATE_CSSD_CESSION_SUCCESS = "CREATE_CSSD_CESSION_SUCCESS";
export const CREATE_CSSD_CESSION_FAILURE = "CREATE_CSSD_CESSION_FAILURE";

export const createCessionActions = {
  request: (account, cessionData, partialClaim) =>
    action(CREATE_CSSD_CESSION_REQUEST, {
      account,
      cessionData,
      partialClaim,
    }),
  success: () => action(CREATE_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(CREATE_CSSD_CESSION_FAILURE, { error }),
};

export const UPDATE_CSSD_CESSION_REQUEST = "UPDATE_CSSD_CESSION_REQUEST";
export const UPDATE_CSSD_CESSION_SUCCESS = "UPDATE_CSSD_CESSION_SUCCESS";
export const UPDATE_CSSD_CESSION_FAILURE = "UPDATE_CSSD_CESSION_FAILURE";

export const updateCessionActions = {
  request: (account, cessionData, teilforderung) =>
    action(UPDATE_CSSD_CESSION_REQUEST, {
      account,
      cessionData,
      teilforderung,
    }),
  success: () => action(UPDATE_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(UPDATE_CSSD_CESSION_FAILURE, { error }),
};

export const UPDATE_NEW_DG_DETAILS_REQUEST = "UPDATE_NEW_DG_DETAILS_REQUEST";
export const UPDATE_NEW_DG_DETAILS_SUCCESS = "UPDATE_NEW_DG_DETAILS_SUCCESS";
export const UPDATE_NEW_DG_DETAILS_FAILURE = "UPDATE_NEW_DG_DETAILS_FAILURE";

export const updateNewDgDetailsAction = {
  request: (account, cession) => action(UPDATE_NEW_DG_DETAILS_REQUEST, { account, cession }),
  success: () => action(UPDATE_NEW_DG_DETAILS_SUCCESS, {}),
  failure: (error) => action(UPDATE_NEW_DG_DETAILS_FAILURE, { error }),
};

export const UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST = "UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST";
export const UPDATE_AND_ACCEPT_NEW_DG_DETAILS_SUCCESS = "UPDATE_AND_ACCEPT_NEW_DG_DETAILS_SUCCESS";
export const UPDATE_AND_ACCEPT_NEW_DG_DETAILS_FAILURE = "UPDATE_AND_ACCEPT_NEW_DG_DETAILS_FAILURE";

export const updateAndAcceptNewDgDetailsAction = {
  request: (account, cession) =>
    action(UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST, { account, cession }),
  success: () => action(UPDATE_AND_ACCEPT_NEW_DG_DETAILS_SUCCESS, {}),
  failure: (error) => action(UPDATE_AND_ACCEPT_NEW_DG_DETAILS_FAILURE, { error }),
};

export const FETCH_CSSD_CESSION_REQUEST = "FETCH_CSSD_CESSION_REQUEST";
export const FETCH_CSSD_CESSION_SUCCESS = "FETCH_CSSD_CESSION_SUCCESS";
export const FETCH_CSSD_CESSION_FAILURE = "FETCH_CSSD_CESSION_FAILURE";

export const fetchCessionActions = {
  request: (queryData) => action(FETCH_CSSD_CESSION_REQUEST, queryData),
  success: (data) => action(FETCH_CSSD_CESSION_SUCCESS, data),
  failure: (error) => action(FETCH_CSSD_CESSION_FAILURE, { error }),
};

export const FETCH_CSSD_CESSION_SUM_REQUEST = "FETCH_CSSD_CESSION_SUM_REQUEST";
export const FETCH_CSSD_CESSION_SUM_SUCCESS = "FETCH_CSSD_CESSION_SUM_SUCCESS";
export const FETCH_CSSD_CESSION_SUM_FAILURE = "FETCH_CSSD_CESSION_SUM_FAILURE";

export const fetchSumOfAllCessionsActions = {
  request: (partialClaim) => action(FETCH_CSSD_CESSION_SUM_REQUEST, partialClaim),
  success: (data) => action(FETCH_CSSD_CESSION_SUM_SUCCESS, data),
  failure: (error) => action(FETCH_CSSD_CESSION_SUM_FAILURE, { error }),
};

export const SET_PAGE_CSSD_CESSION_TABLE = "SET_PAGE_CSSD_CESSION_TABLE";
export const SET_PAGE_SIZE_CSSD_CESSION_TABLE = "SET_PAGE_SIZE_CSSD_CESSION_TABLE";

export const cessionTableActions = {
  setPage: (pageNumber) => action(SET_PAGE_CSSD_CESSION_TABLE, { pageNumber }),
  setPageSize: (pageSize) => action(SET_PAGE_SIZE_CSSD_CESSION_TABLE, { pageSize }),
};

export const FETCH_CSSD_CESSIONREQUEST_REQUEST = "FETCH_CSSD_CESSIONREQUEST_REQUEST";
export const FETCH_CSSD_CESSIONREQUEST_SUCCESS = "FETCH_CSSD_CESSIONREQUEST_SUCCESS";
export const FETCH_CSSD_CESSIONREQUEST_FAILURE = "FETCH_CSSD_CESSIONREQUEST_FAILURE";

export const fetchCessionRequestActions = {
  request: (callerAddr) => action(FETCH_CSSD_CESSIONREQUEST_REQUEST, { callerAddr }),
  success: (cessionRequests) => action(FETCH_CSSD_CESSIONREQUEST_SUCCESS, { cessionRequests }),
  failure: (error) => action(FETCH_CSSD_CESSIONREQUEST_FAILURE, { error }),
};

export const FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST = "FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST";
export const FETCH_CSSD_CESSIONS_FOR_WORK_SUCCESS = "FETCH_CSSD_CESSIONS_FOR_WORK_SUCCESS";
export const FETCH_CSSD_CESSIONS_FOR_WORK_FAILURE = "FETCH_CSSD_CESSIONS_FOR_WORK_FAILURE";
export const FETCH_CSSD_CESSIONS_FOR_WORK_SET_TOTAL = "FETCH_CSSD_CESSIONS_FOR_WORK_SET_TOTAL";
export const FETCH_CSSD_CESSIONS_FOR_WORK_DESCREASE_TOTAL =
  "FETCH_CSSD_CESSIONS_FOR_WORK_DESCREASE_TOTAL";
export const FETCH_CSSD_CESSIONS_FOR_WORK_RETRY = "FETCH_CSSD_CESSIONS_FOR_WORK_RETRY";

export const fetchCssdCessionsForWorkActions = {
  request: (callerAddr) => action(FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST, { callerAddr }),
  success: (cessions) => action(FETCH_CSSD_CESSIONS_FOR_WORK_SUCCESS, { cessions }),
  failure: (payingAgent, cessions) =>
    action(FETCH_CSSD_CESSIONS_FOR_WORK_FAILURE, { payingAgent, cessions }),
  setTotal: (numTotal) => action(FETCH_CSSD_CESSIONS_FOR_WORK_SET_TOTAL, { numTotal }),
  decreaseTotal: (numFiltered) =>
    action(FETCH_CSSD_CESSIONS_FOR_WORK_DESCREASE_TOTAL, { numFiltered }),
  retry: (loadErrors, arranger) =>
    action(FETCH_CSSD_CESSIONS_FOR_WORK_RETRY, { loadErrors, arranger }),
};

export const UPDATE_CSSD_CESSIONS_FOR_WORK_SUCCESS = "UPDATE_CSSD_CESSIONS_FOR_WORK_SUCCESS";
export const UPDATE_CSSD_CESSIONS_FOR_WORK_FAILURE = "UPDATE_CSSD_CESSIONS_FOR_WORK_FAILURE";
export const UPDATE_CSSD_CESSIONS_FOR_WORK_REMOVE = "UPDATE_CSSD_CESSIONS_FOR_WORK_REMOVE";
export const UPDATE_CSSD_CESSIONS_FOR_WORK_UPDATE_STATUS =
  "UPDATE_CSSD_CESSIONS_FOR_WORK_UPDATE_STATUS";

export const updateCssdCessionsForWorkActions = {
  success: (payingAgent, cession) =>
    action(UPDATE_CSSD_CESSIONS_FOR_WORK_SUCCESS, { payingAgent, cession }),
  failure: (payingAgent, cessions) =>
    action(UPDATE_CSSD_CESSIONS_FOR_WORK_FAILURE, { payingAgent, cessions }),
  updateStatus: (cessionId, status) =>
    action(UPDATE_CSSD_CESSIONS_FOR_WORK_UPDATE_STATUS, {
      cessionId,
      status,
    }),
  remove: (payingAgent, cessionId) =>
    action(UPDATE_CSSD_CESSIONS_FOR_WORK_REMOVE, { payingAgent, cessionId }),
};

export const OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST = "OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST";
export const OPEN_CESSION_OVERVIEW_FROM_HOME_SUCCESS = "OPEN_CESSION_OVERVIEW_FROM_HOME_SUCCESS";
export const OPEN_CESSION_OVERVIEW_FROM_HOME_FAILURE = "OPEN_CESSION_OVERVIEW_FROM_HOME_FAILURE";

export const openCessionOverviewFromHomeActions = {
  request: (arranger, partialClaimId, geschaeftsnummer) =>
    action(OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST, { arranger, partialClaimId, geschaeftsnummer }),
  success: () => action(OPEN_CESSION_OVERVIEW_FROM_HOME_SUCCESS),
  failure: (error) => action(OPEN_CESSION_OVERVIEW_FROM_HOME_FAILURE, { error }),
};

export const CONFIRM_CSSD_CESSION_REQUEST = "CONFIRM_CSSD_CESSION_REQUEST";
export const CONFIRM_CSSD_CESSION_SUCCESS = "CONFIRM_CSSD_CESSION_SUCCESS";
export const CONFIRM_CSSD_CESSION_FAILURE = "CONFIRM_CSSD_CESSION_FAILURE";

export const confirmCessionActions = {
  request: (account, cssd, partialClaim, cession) => {
    return action(CONFIRM_CSSD_CESSION_REQUEST, {
      account,
      cssd,
      partialClaim,
      cession,
    });
  },
  success: () => action(CONFIRM_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(CONFIRM_CSSD_CESSION_FAILURE, { error }),
};

export const ACCEPT_CSSD_CESSION_REQUEST = "ACCEPT_CSSD_CESSION_REQUEST";
export const ACCEPT_CSSD_CESSION_SUCCESS = "ACCEPT_CSSD_CESSION_SUCCESS";
export const ACCEPT_CSSD_CESSION_FAILURE = "ACCEPT_CSSD_CESSION_FAILURE";

export const acceptCssdCessionActions = {
  request: (account, cessionRequest) =>
    action(ACCEPT_CSSD_CESSION_REQUEST, { account, cessionRequest }),
  success: () => action(ACCEPT_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(ACCEPT_CSSD_CESSION_FAILURE, { error }),
};

export const BULK_CSSD_CESSION_REQUEST = "BULK_CSSD_CESSION_REQUEST";
export const BULK_CSSD_CESSION_SUCCESS = "BULK_CSSD_CESSION_SUCCESS";
export const BULK_CSSD_CESSION_FAILURE = "BULK_CSSD_CESSION_FAILURE";

export const bulkActions = {
  request: (bulkAction, account, cssd, cessions, partialClaim) =>
    action(BULK_CSSD_CESSION_REQUEST, {
      bulkAction,
      account,
      cssd,
      cessions,
      partialClaim,
    }),
  success: () => action(BULK_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(BULK_CSSD_CESSION_FAILURE, { error }),
};

export const BULK_CSSD_CESSION_NOTIFY_REQUEST = "BULK_CSSD_CESSION_NOTIFY_REQUEST";
export const BULK_CSSD_CESSION_NOTIFY_SUCCESS = "BULK_CSSD_CESSION_NOTIFY_SUCCESS";
export const BULK_CSSD_CESSION_NOTIFY_FAILURE = "BULK_CSSD_CESSION_NOTIFY_FAILURE";

export const bulkNotifyCessionActions = {
  request: (account, cssd, partialClaims) =>
    action(BULK_CSSD_CESSION_NOTIFY_REQUEST, {
      account,
      cssd,
      partialClaims,
    }),
  success: () => action(BULK_CSSD_CESSION_NOTIFY_SUCCESS, {}),
  failure: (error) => action(BULK_CSSD_CESSION_NOTIFY_FAILURE, { error }),
};

export const FILTER_CSSD_CESSION_TABLE = "FILTER_CSSD_CESSION_TABLE";

export const filterCessionTable = (filters) => action(FILTER_CSSD_CESSION_TABLE, { filters });

export const SORT_CSSD_CESSION_TABLE = "SORT_CSSD_CESSION_TABLE";

export const sortCessionTable = (column) => action(SORT_CSSD_CESSION_TABLE, { column });

export const SAVE_AND_CONFIRM_CESSION_REQUEST = "SAVE_AND_CONFIRM_CESSION_REQUEST";
export const SAVE_AND_CONFIRM_CESSION_SUCCESS = "SAVE_AND_CONFIRM_CESSION_SUCCESS";
export const SAVE_AND_CONFIRM_CESSION_FAILURE = "SAVE_AND_CONFIRM_CESSION_FAILURE";

export const saveAndConfirmCessionActions = {
  request: (account, cessionData, partialClaim, onCreating) =>
    action(SAVE_AND_CONFIRM_CESSION_REQUEST, {
      account,
      cessionData,
      partialClaim,
      onCreating,
    }),
  success: () => action(SAVE_AND_CONFIRM_CESSION_SUCCESS, {}),
  failure: (error) => action(SAVE_AND_CONFIRM_CESSION_FAILURE, { error }),
};

export const CANCEL_CSSD_CESSION_REQUEST = "CANCEL_CSSD_CESSION_REQUEST";
export const CANCEL_CSSD_CESSION_REQUEST_SUCCESS = "CANCEL_CSSD_CESSION_REQUEST_SUCCESS";
export const CANCEL_CSSD_CESSION_REQUEST_FAILURE = "CANCEL_CSSD_CESSION_REQUEST_FAILURE";

export const cancelCessionActions = {
  request: (account, cssd, partialClaim, cession) =>
    action(CANCEL_CSSD_CESSION_REQUEST, {
      account,
      cssd,
      partialClaim,
      cession,
    }),
  success: () => action(CANCEL_CSSD_CESSION_REQUEST_SUCCESS, {}),
  failure: (error) => action(CANCEL_CSSD_CESSION_REQUEST_FAILURE, { error }),
};

export const REJECT_CSSD_CESSION_REQUEST = "REJECT_CSSD_CESSION_REQUEST";
export const REJECT_CSSD_CESSION_REQUEST_SUCCESS = "REJECT_CSSD_CESSION_REQUEST_SUCCESS";
export const REJECT_CSSD_CESSION_REQUEST_FAILURE = "REJECT_CSSD_CESSION_REQUEST_FAILURE";

export const rejectCessionActions = {
  request: (account, cession, reason) =>
    action(REJECT_CSSD_CESSION_REQUEST, {
      account,
      cession,
      reason,
    }),
  success: (rejectedCession) => action(REJECT_CSSD_CESSION_REQUEST_SUCCESS, { rejectedCession }),
  failure: (error) => action(REJECT_CSSD_CESSION_REQUEST_FAILURE, { error }),
};

export const NOTIFY_CSSD_CESSION_REQUEST = "NOTIFY_CSSD_CESSION_REQUEST";
export const NOTIFY_CSSD_CESSION_SUCCESS = "NOTIFY_CSSD_CESSION_SUCCESS";
export const NOTIFY_CSSD_CESSION_FAILURE = "NOTIFY_CSSD_CESSION_FAILURE";

export const notifyCssdCessionActions = {
  request: (account, partialClaim) =>
    action(NOTIFY_CSSD_CESSION_REQUEST, {
      account,
      partialClaim,
    }),
  success: () => action(NOTIFY_CSSD_CESSION_SUCCESS, {}),
  failure: (error) => action(NOTIFY_CSSD_CESSION_FAILURE, { error }),
};

export const DOWNLOAD_CESSION_CERT = "DOWNLOAD_CESSION_CERT";
export const downloadCessionCertActions = {
  download: (idProxyAddress, cessionId) =>
    action(DOWNLOAD_CESSION_CERT, { idProxyAddress, cessionId }),
};

export const ACCEPT_EXT_CESSION_REQUEST = "ACCEPT_EXT_CESSION_REQUEST";
export const ACCEPT_EXT_CESSION_SUCCESS = "ACCEPT_EXT_CESSION_SUCCESS";
export const ACCEPT_EXT_CESSION_FAILURE = "ACCEPT_EXT_CESSION_FAILURE";

export const acceptExtCessionActions = {
  request: (account, cssd, partialClaim, cession) =>
    action(ACCEPT_EXT_CESSION_REQUEST, {
      account,
      cssd,
      partialClaim,
      cession,
    }),
  success: () => action(ACCEPT_EXT_CESSION_SUCCESS, {}),
  failure: (error) => action(ACCEPT_EXT_CESSION_FAILURE, { error }),
};

export const UPLOAD_CESSION_CERT_OPEN = "UPLOAD_CESSION_CERT_OPEN";
export const UPLOAD_CESSION_CERT_CLOSE = "UPLOAD_CESSION_CERT_CLOSE";
export const UPLOAD_CESSION_CERT_REQUEST = "UPLOAD_CESSION_CERT_REQUEST";
export const UPLOAD_CESSION_CERT_SUCCESS = "UPLOAD_CESSION_CERT_SUCCESS";
export const UPLOAD_CESSION_CERT_FAILURE = "UPLOAD_CESSION_CERT_FAILURE";

export const uploadCessionCertActions = {
  open: (cession) => action(UPLOAD_CESSION_CERT_OPEN, { cession }),
  close: () => action(UPLOAD_CESSION_CERT_CLOSE),
  upload: (account, cssd, partialClaim, cession, certificate) =>
    action(UPLOAD_CESSION_CERT_REQUEST, {
      account,
      cssd,
      partialClaim,
      cession,
      certificate,
    }),
  success: () => action(UPLOAD_CESSION_CERT_SUCCESS, {}),
  failure: (error) => action(UPLOAD_CESSION_CERT_FAILURE, { error }),
};

export const REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST = "REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST";
export const REQUEST_CHANGE_EXTERNAL_CESSION_SUCCESS = "REQUEST_CHANGE_EXTERNAL_CESSION_SUCCESS";
export const REQUEST_CHANGE_EXTERNAL_CESSION_FAILURE = "REQUEST_CHANGE_EXTERNAL_CESSION_FAILURE";

export const requestChangesForExternalCessionActions = {
  request: (account, cssd, partialClaim, cession) =>
    action(REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST, {
      account,
      cssd,
      partialClaim,
      cession,
    }),
  success: () => action(REQUEST_CHANGE_EXTERNAL_CESSION_SUCCESS, {}),
  failure: (error) => action(REQUEST_CHANGE_EXTERNAL_CESSION_FAILURE, { error }),
};

export const PAYMENT_RECEIVED_FOR_REBUY_REQUEST = "PAYMENT_RECEIVED_FOR_REBUY_REQUEST";
export const PAYMENT_RECEIVED_FOR_REBUY_SUCCESS = "PAYMENT_RECEIVED_FOR_REBUY_SUCCESS";
export const PAYMENT_RECEIVED_FOR_REBUY_FAILURE = "PAYMENT_RECEIVED_FOR_REBUY_FAILURE";

export const paymentReceivedForRebuyActions = {
  request: (account, partialClaim) =>
    action(PAYMENT_RECEIVED_FOR_REBUY_REQUEST, { account, partialClaim }),
  success: () => action(PAYMENT_RECEIVED_FOR_REBUY_SUCCESS, {}),
  failure: (error) => action(PAYMENT_RECEIVED_FOR_REBUY_FAILURE, { error }),
};
