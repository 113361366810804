import {
  INVITE_PLATFORM_MEMBER_SEND,
  INVITE_PLATFORM_MEMBER_SUCCESS,
  INVITE_PLATFORM_MEMBER_FAILURE,
  INVITE_PLATFORM_MEMBER_AGAIN_SEND,
  INVITE_PLATFORM_MEMBER_AGAIN_SUCCESS,
  INVITE_PLATFORM_MEMBER_AGAIN_FAILURE,
  REVOKE_INVITE_PLATFORM_MEMBER_REQUEST,
  REVOKE_INVITE_PLATFORM_MEMBER_SUCCESS,
  REVOKE_INVITE_PLATFORM_MEMBER_FAILURE,
  GET_PLATFORM_MEMBER_INVITES_SUCCESS,
  GET_PLATFORM_MEMBERS_SUCCESS,
  GET_PLATFORM_MEMBER_PERMISSIONS_SUCCESS,
  OPEN_PLATFORM_MEMBERS_ROLE_MODAL,
  CLOSE_PLATFORM_MEMBERS_ROLE_MODAL,
  GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST,
  GRANT_REVOKE_PLATFORM_PRIVILEGES_SUCCESS,
  GRANT_REVOKE_PLATFORM_PRIVILEGES_FAILURE,
  FILTER_PLATFORM_MEMBERS,
  RESET_FILTER_PLATFORM_MEMBERS,
  GET_PLATFORM_MEMBER_DETAILS_SUCCESS,
  GET_PLATFORM_MEMBER_DETAILS_FAILURE,
  GET_PLATFORM_MEMBER_DETAILS_RETRY,
} from "./actions";
import { onlyUnique } from "../../util/convertor";

// reducer with initial state
const initialState = {
  numPlatformMembers: 0,
  platformMembers: [],
  filteredPlatformMembers: [],
  platformMemberInvites: [],
  platformMemberData: {},
  invitePending: false,
  inviteAgainPending: false,
  platformMemberRoleModalOpen: false,
  savePermissionsPending: false,
  revokePlatformMemberPending: false,
  idProxyAddress: "",
  permissions: {
    PRIVILEGE_PLATFORM_OFFER_SSD: false,
    PRIVILEGE_PLATFORM_ZAHLSTELLE: false,
    PRIVILEGE_PLATFORM_READ_ONLY: false,
  },
  loadErrors: {},
};

function updatePlatformemberDetail(platformMembers, details) {
  const isNew =
    platformMembers.filter((entry) => entry.idProxyAddress === details.idProxyAddress).length === 0;
  if (isNew) {
    return [...platformMembers, { ...details }];
  } else {
    return [...platformMembers].map((member) => {
      if (member.idProxyAddress === details.idProxyAddress) {
        return { ...details };
      }
      return member;
    });
  }
}

function getPlatformMemberDetailsSuccessReducer(state, action) {
  const platformMembers = updatePlatformemberDetail(state.platformMembers, action.details);
  return {
    ...state,
    platformMembers,
    filteredPlatformMembers: platformMembers,
  };
}

function getPlatformMemberDetailsFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.idProxyAddr]:
        state.loadErrors[action.idProxyAddr] != null
          ? [...state.loadErrors[action.idProxyAddr], action.idProxyAddr].filter(onlyUnique)
          : [action.idProxyAddr],
    },
  };
}

function getPlatformMemberDetailsRetryReducer(state, action) {
  return {
    ...state,
    loadErrors: action.idProxyAddr ? { ...state.loadErrors, [action.idProxyAddr]: [] } : {},
  };
}

function platformMemberInviteSendReducer(state) {
  return {
    ...state,
    invitePending: true,
  };
}

function platformMemberInviteSuccessReducer(state) {
  return {
    ...state,
    invitePending: false,
  };
}

function platformMemberInviteFailureReducer(state) {
  return {
    ...state,
    invitePending: false,
  };
}

function platformMemberInviteAgainSendReducer(state) {
  return {
    ...state,
    inviteAgainPending: true,
  };
}

function platformMemberInviteAgainSuccessReducer(state) {
  return {
    ...state,
    inviteAgainPending: false,
  };
}

function platformMemberInviteAgainFailureReducer(state) {
  return {
    ...state,
    inviteAgainPending: false,
  };
}

function fetchPlatformMemberInvitesSuccessReducer(state, action) {
  return {
    ...state,
    platformMemberInvites: action.platformMemberInvites,
  };
}

function getPlatformMembersSuccessReducer(state, action) {
  return {
    ...state,
    numPlatformMembers: action.platformMembers.length,
    loadErrors: initialState.loadErrors,
    platformMembers: initialState.platformMembers,
    filteredPlatformMembers: initialState.platformMembers,
  };
}

function fetchPlatformMembersPermissionsSuccessReducer(state, action) {
  return {
    ...state,
    idProxyAddress: action.idProxyAddr,
    permissions: action.permissions,
  };
}

function openPlatformMemberRoleModalReducer(state, action) {
  return {
    ...state,
    platformMemberRoleModalOpen: true,
    platformMemberData: action.platformMember,
  };
}

function closePlatformMemberRoleModalReducer(state) {
  return {
    ...state,
    platformMemberRoleModalOpen: false,
  };
}

function grantRevokePrivilegesRequestReducer(state) {
  return {
    ...state,
    savePermissionsPending: true,
  };
}

function grantRevokePrivilegesSuccessReducer(state) {
  return {
    ...state,
    savePermissionsPending: false,
    platformMemberRoleModalOpen: false,
  };
}

function grantRevokePrivilegesFailureReducer(state) {
  return {
    ...state,
    savePermissionsPending: false,
    platformMemberRoleModalOpen: false,
  };
}

function filterPlatformmembers(platformMembers, filter) {
  let result = [...platformMembers];
  if (filter.name !== "") {
    result = result.filter(
      (member) => member.name && member.name.toUpperCase().includes(filter.name.toUpperCase())
    );
  }
  if (filter.digitsKennung !== "") {
    result = result.filter(
      (member) =>
        member.digitsKennung &&
        member.digitsKennung.toUpperCase().includes(filter.digitsKennung.toUpperCase())
    );
  }
  return result;
}

function filterPlatformmembersReducer(state, action) {
  const filteredPlatformMembers = filterPlatformmembers(state.platformMembers, action.filter);
  return {
    ...state,
    filteredPlatformMembers,
  };
}

function resetFilterPlatformMembersReducer(state) {
  return {
    ...state,
    filteredPlatformMembers: state.platformMembers,
  };
}

function revokePlatformMemberInviteRequestReducer(state) {
  return {
    ...state,
    revokePlatformMemberPending: true,
  };
}

function revokePlatformMemberInviteSuccessReducer(state) {
  return {
    ...state,
    revokePlatformMemberPending: false,
  };
}

function revokePlatformMemberInviteFailureReducer(state) {
  return {
    ...state,
    revokePlatformMemberPending: false,
  };
}

export function platformMemberReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_PLATFORM_MEMBER_SEND:
      return platformMemberInviteSendReducer(state, action);
    case INVITE_PLATFORM_MEMBER_SUCCESS:
      return platformMemberInviteSuccessReducer(state, action);
    case INVITE_PLATFORM_MEMBER_FAILURE:
      return platformMemberInviteFailureReducer(state);

    case INVITE_PLATFORM_MEMBER_AGAIN_SEND:
      return platformMemberInviteAgainSendReducer(state, action);
    case INVITE_PLATFORM_MEMBER_AGAIN_SUCCESS:
      return platformMemberInviteAgainSuccessReducer(state, action);
    case INVITE_PLATFORM_MEMBER_AGAIN_FAILURE:
      return platformMemberInviteAgainFailureReducer(state);

    case REVOKE_INVITE_PLATFORM_MEMBER_REQUEST:
      return revokePlatformMemberInviteRequestReducer(state);
    case REVOKE_INVITE_PLATFORM_MEMBER_SUCCESS:
      return revokePlatformMemberInviteSuccessReducer(state);
    case REVOKE_INVITE_PLATFORM_MEMBER_FAILURE:
      return revokePlatformMemberInviteFailureReducer(state);

    case GET_PLATFORM_MEMBER_INVITES_SUCCESS:
      return fetchPlatformMemberInvitesSuccessReducer(state, action);

    case GET_PLATFORM_MEMBER_PERMISSIONS_SUCCESS:
      return fetchPlatformMembersPermissionsSuccessReducer(state, action);

    case GET_PLATFORM_MEMBERS_SUCCESS:
      return getPlatformMembersSuccessReducer(state, action);

    case GET_PLATFORM_MEMBER_DETAILS_SUCCESS:
      return getPlatformMemberDetailsSuccessReducer(state, action);

    case GET_PLATFORM_MEMBER_DETAILS_FAILURE:
      return getPlatformMemberDetailsFailureReducer(state, action);

    case GET_PLATFORM_MEMBER_DETAILS_RETRY:
      return getPlatformMemberDetailsRetryReducer(state, action);

    case OPEN_PLATFORM_MEMBERS_ROLE_MODAL:
      return openPlatformMemberRoleModalReducer(state, action);
    case CLOSE_PLATFORM_MEMBERS_ROLE_MODAL:
      return closePlatformMemberRoleModalReducer(state, action);

    case GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST:
      return grantRevokePrivilegesRequestReducer(state, action);
    case GRANT_REVOKE_PLATFORM_PRIVILEGES_SUCCESS:
      return grantRevokePrivilegesSuccessReducer(state, action);
    case GRANT_REVOKE_PLATFORM_PRIVILEGES_FAILURE:
      return grantRevokePrivilegesFailureReducer(state, action);

    case FILTER_PLATFORM_MEMBERS:
      return filterPlatformmembersReducer(state, action);
    case RESET_FILTER_PLATFORM_MEMBERS:
      return resetFilterPlatformMembersReducer(state, action);
    default:
      return state;
  }
}
