import React, { useState } from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";

const getCountryCodeAndName = (i18n) => {
  const countries = require("i18n-iso-countries");
  countries.registerLocale(require(`i18n-iso-countries/langs/${i18n.locale}.json`));
  const unformatted = countries.getNames(i18n.locale);
  const formatted = Object.keys(unformatted).map((key) => {
    return { key, value: unformatted[key] };
  });
  return formatted;
};

const messages = defineMessages({
  noOptions: {
    id: "sitzland_keineErgebnisse",
    defaultMessage: "Keine Ergebnisse",
  },
});

const buildDisplay = (option) => {
  if (typeof option === "string") return option;
  return option ? `${option.key}, ${option.value}` : "";
};

const SitzlandAutocomplete = ({
  label,
  register,
  onBlur,
  required = false,
  disabled = false,
  error,
  defaultValue,
  updateValue = null,
}) => {
  const i18n = useIntl();
  const [codes] = useState(getCountryCodeAndName(i18n));
  const [code, setCode] = useState(defaultValue);
  const [input, setInput] = useState("");

  const handleBlur = typeof onBlur === "function" ? onBlur : () => {};
  const updateData = (key) => {
    const ref = codes.find((obj) => obj.key === key);
    if (ref) {
      setCode(key);
      handleBlur(key);
      setInput(buildDisplay(ref));
    }
  };

  useEffect(() => {
    if (defaultValue) {
      updateData(defaultValue.toUpperCase());
    }
  }, []);

  useEffect(() => {
    if (updateValue) {
      updateData(updateValue.toUpperCase());
    } else if (updateValue === "") {
      setCode("");
      setInput("");
    }
  }, [updateValue]);

  const compareOption = (option, string) => {
    return string === "" ? option.key === "DE" : buildDisplay(option) === string;
  };

  return (
    <>
      <input
        type="hidden"
        ref={register ? register({ required }) : null}
        name="sitzLand"
        value={code}
      />
      <Autocomplete
        disabled={disabled}
        options={codes}
        getOptionLabel={buildDisplay}
        renderOption={buildDisplay}
        getOptionSelected={compareOption}
        onInputChange={(e, v) => {
          if (v) {
            v = v[0] + v[1];
            updateData(v);
            return;
          }
          handleBlur("");
          setCode("");
          setInput("");
        }}
        value={input}
        filterOptions={createFilterOptions({
          stringify: buildDisplay,
          ignoreCase: true,
          matchFrom: "any",
        })}
        renderInput={(params) => (
          <TextField {...params} label={label} required={required} error={error} />
        )}
        noOptionsText={i18n.formatMessage(messages.noOptions)}
      />
    </>
  );
};

export default SitzlandAutocomplete;
