export const OPEN_TERMINATAION_DIALOG = "OPEN_TERMINATAION_DIALOG";
export const OPEN_TERMINATAION_DIALOG_UPDATE = "OPEN_TERMINATAION_DIALOG_UPDATE";
export const CLOSE_TERMINATION_DIALOG = "CLOSE_TERMINATION_DIALOG";

export const OPEN_CONFIRM_TERMINATION_DIALOG = "OPEN_CONFIRM_TERMINATION_DIALOG";
export const CLOSE_CONFIRM_TERMINATION_DIALOG = "CLOSE_CONFIRM_TERMINATION_DIALOG";

export const OPEN_SECOND_CONFIRM_TERMINATION_DIALOG = "OPEN_SECOND_CONFIRM_TERMINATION_DIALOG";
export const CLOSE_SECOND_CONFIRM_TERMINATION_DIALOG = "CLOSE_SECOND_CONFIRM_TERMINATION_DIALOG";

export const CREATE_TERMINATION_REQUEST = "CREATE_TERMINATION_REQUEST";
export const CREATE_TERMINATION_SUCCESS = "CREATE_TERMINATION_SUCCESS";
export const CREATE_TERMINATION_FAILURE = "CREATE_TERMINATION_FAILURE";

export const CONFIRM_TERMINATION_REQUEST = "CONFIRM_TERMINATION_REQUEST";
export const CONFIRM_TERMINATION_SUCCESS = "CONFIRM_TERMINATION_SUCCESS";
export const CONFIRM_TERMINATION_FAILURE = "CONFIRM_TERMINATION_FAILURE";

export const FETCH_KUENDIGUNG_REQUEST = "FETCH_KUENDIGUNG_REQUEST";
export const FETCH_KUENDIGUNG_SUCCESS = "FETCH_KUENDIGUNG_SUCCESS";
export const FETCH_KUENDIGUNG_FAILURE = "FETCH_KUENDIGUNG_FAILURE";

export const REJECT_TERMINIATION_REQUEST = "REJECT_TERMINIATION_REQUEST";
export const REJECT_TERMINATION_SUCCESS = "REJECT_TERMINATION_SUCCESS";
export const REJECT_TERMINATION_FAILURE = "REJECT_TERMINATION_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const fetchKuendigungActions = {
  request: (ssdInfo) => action(FETCH_KUENDIGUNG_REQUEST, { ssdInfo }),
  success: (ssdInfo, kuendigung) => action(FETCH_KUENDIGUNG_SUCCESS, { ssdInfo, kuendigung }),
  failure: (error) => action(FETCH_KUENDIGUNG_FAILURE, { error }),
};

export const secondConfirmDialogActions = {
  open: (ssdInfo, kuendigung) =>
    action(OPEN_SECOND_CONFIRM_TERMINATION_DIALOG, { ssdInfo, kuendigung }),
  close: () => action(CLOSE_SECOND_CONFIRM_TERMINATION_DIALOG, {}),
};

export const terminateSsdDialogActions = {
  update: (ssdInfo) => action(OPEN_TERMINATAION_DIALOG_UPDATE, { ssdInfo }),
  open: (ssdInfo) => action(OPEN_TERMINATAION_DIALOG, { ssdInfo }),
  close: () => action(CLOSE_TERMINATION_DIALOG, {}),
};

export const confirmTerminationDialogActions = {
  open: (kuendigung) => action(OPEN_CONFIRM_TERMINATION_DIALOG, { kuendigung }),
  close: () => action(CLOSE_CONFIRM_TERMINATION_DIALOG, {}),
};

export const createTerminationActions = {
  request: (account, arranger, ssdid, ssdHash, kuendigung, ssdToTerminate, recipient, fourEyes) =>
    action(CREATE_TERMINATION_REQUEST, {
      account,
      arranger,
      ssdid,
      ssdHash,
      kuendigung,
      ssdToTerminate,
      recipient,
      fourEyes,
    }),
  success: () => action(CREATE_TERMINATION_SUCCESS, {}),
  failure: (error) => action(CREATE_TERMINATION_FAILURE, { error }),
};

export const confirmTerminationActions = {
  request: (account, arranger, ssdId, ssdHash, kuendigung, ssdToTerminate, recipient) =>
    action(CONFIRM_TERMINATION_REQUEST, {
      account,
      arranger,
      ssdId,
      ssdHash,
      kuendigung,
      ssdToTerminate,
      recipient,
    }),
  success: () => action(CONFIRM_TERMINATION_SUCCESS, {}),
  failure: (error) => action(CONFIRM_TERMINATION_FAILURE, { error }),
};

export const rejectTerminationActions = {
  request: (account, arranger, ssdId, ssdHash, kuendigung) =>
    action(REJECT_TERMINIATION_REQUEST, {
      account,
      arranger,
      ssdId,
      ssdHash,
      kuendigung,
    }),
  success: () => action(REJECT_TERMINATION_SUCCESS, {}),
  failure: (error) => action(REJECT_TERMINATION_FAILURE, { error }),
};
