// action types
export const OPEN_CREATE_INTEREST_NOTIFICATION_MODAL = "OPEN_CREATE_INTEREST_NOTIFICATION_MODAL";
export const CLOSE_CREATE_INTEREST_NOTIFICATION_MODAL = "CLOSE_CREATE_INTEREST_NOTIFICATION_MODAL";

export const CREATE_INTEREST_NOTIFICATION_REQUEST = "CREATE_INTEREST_NOTIFICATION_REQUEST";
export const CREATE_INTEREST_NOTIFICATION_SUCCESS = "CREATE_INTEREST_NOTIFICATION_SUCCESS";
export const CREATE_INTEREST_NOTIFICATION_FAILURE = "CREATE_INTEREST_NOTIFICATION_FAILURE";

export const CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST =
  "CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST";
export const CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_SUCCESS =
  "CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_SUCCESS";
export const CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_FAILURE =
  "CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_FAILURE";

export const CLOSE_INTEREST_NOTIFICATION_REQUEST = "CLOSE_INTEREST_NOTIFICATION_REQUEST";
export const CLOSE_INTEREST_NOTIFICATION_SUCCESS = "CLOSE_INTEREST_NOTIFICATION_SUCCESS";
export const CLOSE_INTEREST_NOTIFICATION_FAILURE = "CLOSE_INTEREST_NOTIFICATION_FAILURE";

export const CONFIRM_INTEREST_NOTIFICATION_REQUEST = "CONFIRM_INTEREST_NOTIFICATION_REQUEST";
export const CONFIRM_INTEREST_NOTIFICATION_SUCCESS = "CONFIRM_INTEREST_NOTIFICATION_SUCCESS";
export const CONFIRM_INTEREST_NOTIFICATION_FAILURE = "CONFIRM_INTEREST_NOTIFICATION_FAILURE";

export const CLOSE_INTEREST_NOTIFICATION_MODAL_OPEN = "CLOSE_INTEREST_NOTIFICATION_MODAL_OPEN";
export const CLOSE_INTEREST_NOTIFICATION_MODAL_CLOSE = "CLOSE_INTEREST_NOTIFICATION_MODAL_CLOSE";

export const CANCEL_INTEREST_NOTIFICATION_MODAL_OPEN = "CANCEL_INTEREST_NOTIFICATION_MODAL_OPEN";
export const CANCEL_INTEREST_NOTIFICATION_MODAL_CLOSE = "CANCEL_INTEREST_NOTIFICATION_MODAL_CLOSE";

export const FETCH_INTEREST_NOTIFICATIONS_REQUEST = "FETCH_INTEREST_NOTIFICATIONS_REQUEST";
export const FETCH_INTEREST_NOTIFICATIONS_SUCCESS = "FETCH_INTEREST_NOTIFICATIONS_SUCCESS";
export const FETCH_INTEREST_NOTIFICATIONS_FAILURE = "FETCH_INTEREST_NOTIFICATIONS_FAILURE";

export const FETCH_INTEREST_NOTIFICATION_DETAILS_SUCCESS =
  "FETCH_INTEREST_NOTIFICATION_DETAILS_SUCCESS";
export const FETCH_INTEREST_NOTIFICATION_DETAILS_FAILURE =
  "FETCH_INTEREST_NOTIFICATION_DETAILS_FAILURE";

export const SET_SSD_INFO_FOR_INTEREST = "SET_SSD_INFO_FOR_INTEREST";

export const UPDATE_INTEREST_NOTIFICAION_FOUR_EYES = "UPDATE_INTEREST_NOTIFICAION_FOUR_EYES";
export const UPDATE_INTEREST_NOTIFICAION_STATE = "UPDATE_INTEREST_NOTIFICAION_STATE";
export const ADD_INTEREST_NOTIFICATION_FROM_EVENT = "ADD_INTEREST_NOTIFICATION_FROM_EVENT";

export const START_INTEREST_NOTIFICATION_EVENT_LISTENER =
  "START_INTEREST_NOTIFICATION_EVENT_LISTENER";
export const STOP_INTEREST_NOTIFICATION_EVENT_LISTENER =
  "STOP_INTEREST_NOTIFICATION_EVENT_LISTENER";

export const INTEREST_NOTIFICATION_EDIT_MODAL_OPEN = "INTEREST_NOTIFICATION_EDIT_MODAL_OPEN";
export const INTEREST_NOTIFICATION_EDIT_MODAL_CLOSE = "INTEREST_NOTIFICATION_EDIT_MODAL_CLOSE";

export const MODIFY_INTEREST_NOTIFICATION_REQUEST = "MODIFY_INTEREST_NOTIFICATION_REQUEST";
export const MODIFY_INTEREST_NOTIFICATION_SUCCESS = "MODIFY_INTEREST_NOTIFICATION_SUCCESS";
export const MODIFY_INTEREST_NOTIFICATION_FAILURE = "MODIFY_INTEREST_NOTIFICATION_FAILURE";

export const MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST =
  "MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST";
export const MODIFY_AND_SAVE_INTEREST_NOTIFICATION_SUCCESS =
  "MODIFY_AND_SAVE_INTEREST_NOTIFICATION_SUCCESS";
export const MODIFY_AND_SAVE_INTEREST_NOTIFICATION_FAILURE =
  "MODIFY_AND_SAVE_INTEREST_NOTIFICATION_FAILURE";

export const REJECT_INTEREST_NOTIFICATION_REQUEST = "REJECT_INTEREST_NOTIFICATION_REQUEST";
export const REJECT_INTEREST_NOTIFICATION_SUCCESS = "REJECT_INTEREST_NOTIFICATION_SUCCESS";
export const REJECT_INTEREST_NOTIFICATION_FAILURE = "REJECT_INTEREST_NOTIFICATION_FAILURE";

export const CANCEL_INTEREST_NOTIFICATION_REQUEST = "CANCEL_INTEREST_NOTIFICATION_REQUEST";
export const CANCEL_INTEREST_NOTIFICATION_SUCCESS = "CANCEL_INTEREST_NOTIFICATION_SUCCESS";
export const CANCEL_INTEREST_NOTIFICATION_FAILURE = "CANCEL_INTEREST_NOTIFICATION_FAILURE";

export const DOWNLOAD_INTEREST_NOTIFICATION_REQUEST = "DOWNLOAD_INTEREST_NOTIFICATION_REQUEST";
export const DOWNLOAD_INTEREST_NOTIFICATION_FAILURE = "DOWNLOAD_INTEREST_NOTIFICATION_FAILURE";
export const DOWNLOAD_INTEREST_NOTIFICATION_SUCCESS = "DOWNLOAD_INTEREST_NOTIFICATION_SUCCESS";

export const UPDATE_BSSD_INTEREST_NOTIFICAION = "UPDATE_BSSD_INTEREST_NOTIFICAION";

export const updateInterestNotificationActions = {
  updateBSSDInterestNotification: (arranger, ssdId) =>
    action(UPDATE_BSSD_INTEREST_NOTIFICAION, { arranger, ssdId }),
};

export const downloadInterestNotificationRequestReducer = {
  request: (arranger, ssdId, interestId, fingerprint, fingerprintRebuy, datum) =>
    action(DOWNLOAD_INTEREST_NOTIFICATION_REQUEST, {
      arranger,
      ssdId,
      interestId,
      fingerprint,
      fingerprintRebuy,
      datum,
    }),
  success: () => action(DOWNLOAD_INTEREST_NOTIFICATION_FAILURE, {}),
  failure: (error) => action(DOWNLOAD_INTEREST_NOTIFICATION_SUCCESS, { error }),
};

function action(type, payload = {}) {
  return { type, ...payload };
}

export const modifyInterestNotificationActions = {
  request: (account, arranger, ssdId, ssdHash, interestNotificationData) =>
    action(MODIFY_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      ssdHash,
      interestNotificationData,
    }),
  success: () => action(MODIFY_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(MODIFY_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const modifyAndSaveInterestNotificationActions = {
  request: (account, arranger, ssdId, ssdHash, interestNotificationData) =>
    action(MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      ssdHash,
      interestNotificationData,
    }),
  success: () => action(MODIFY_AND_SAVE_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(MODIFY_AND_SAVE_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const closeInterestNotificationModalActions = {
  open: (interestData) => action(CLOSE_INTEREST_NOTIFICATION_MODAL_OPEN, { interestData }),
  close: () => action(CLOSE_INTEREST_NOTIFICATION_MODAL_CLOSE),
};

export const cancelInterestNotificationModalActions = {
  open: (interestData) => action(CANCEL_INTEREST_NOTIFICATION_MODAL_OPEN, { interestData }),
  close: () => action(CANCEL_INTEREST_NOTIFICATION_MODAL_CLOSE),
};

export const closeInterestNotificationActions = {
  request: (account, arranger, ssdId, interestNotificationId, ssdHash, interestNotificationHash) =>
    action(CLOSE_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash,
    }),
  success: () => action(CLOSE_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CLOSE_INTEREST_NOTIFICATION_FAILURE, { error }),
};
export const confirmInterestNotificationActions = {
  request: (account, interestNotificationData, ssdInfoForInterest, fourEyesFlag) =>
    action(CONFIRM_INTEREST_NOTIFICATION_REQUEST, {
      account,
      interestNotificationData,
      ssdInfoForInterest,
      fourEyesFlag,
    }),
  success: () => action(CONFIRM_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CONFIRM_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const interestNotificationEventListenerActions = {
  start: () => action(START_INTEREST_NOTIFICATION_EVENT_LISTENER),
  stop: () => action(STOP_INTEREST_NOTIFICATION_EVENT_LISTENER),
  updateFourEyes: (ssdId, id, state, firstConfirmer) =>
    action(UPDATE_INTEREST_NOTIFICAION_FOUR_EYES, {
      ssdId,
      id,
      state,
      firstConfirmer,
    }),
  updateState: (ssdId, id, hash, state, arranger) =>
    action(UPDATE_INTEREST_NOTIFICAION_STATE, {
      ssdId,
      id,
      hash,
      state,
      arranger,
    }),
  addNewInterestNotification: (ssdId, arranger, interestNotificationHash, interestNotificationId) =>
    action(ADD_INTEREST_NOTIFICATION_FROM_EVENT, {
      ssdId,
      arranger,
      interestNotificationHash,
      interestNotificationId,
    }),
};

export const setSsdInfoForInterest = {
  set: (ssdInfo) => action(SET_SSD_INFO_FOR_INTEREST, { ssdInfo }),
};

export const interestNotificationModalActions = {
  open: () => action(OPEN_CREATE_INTEREST_NOTIFICATION_MODAL),
  close: () => action(CLOSE_CREATE_INTEREST_NOTIFICATION_MODAL),
};

export const createInterestNotificationActions = {
  request: (account, arranger, ssdId, interestData) =>
    action(CREATE_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      interestData,
    }),
  success: () => action(CREATE_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CREATE_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const createAndConfirmInterestNotifications = {
  request: (account, ssdInfoForInterest, interestData, fourEyesFlag) =>
    action(CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST, {
      account,
      ssdInfoForInterest,
      interestData,
      fourEyesFlag,
    }),
  success: () => action(CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const fetchInterestNotificationActions = {
  request: (arranger, ssdId) => action(FETCH_INTEREST_NOTIFICATIONS_REQUEST, { arranger, ssdId }),
  success: (interestNotifications) =>
    action(FETCH_INTEREST_NOTIFICATIONS_SUCCESS, { interestNotifications }),
  failure: (error) => action(FETCH_INTEREST_NOTIFICATIONS_FAILURE, { error }),
};

export const fetchInterestNotificationDetailsActions = {
  success: (interestNotificationDetails) =>
    action(FETCH_INTEREST_NOTIFICATION_DETAILS_SUCCESS, {
      interestNotificationDetails,
    }),
  failure: (error) => action(FETCH_INTEREST_NOTIFICATION_DETAILS_FAILURE, { error }),
};

export const interestNotificationEditModalActions = {
  open: (interestData) => action(INTEREST_NOTIFICATION_EDIT_MODAL_OPEN, { interestData }),
  close: () => action(INTEREST_NOTIFICATION_EDIT_MODAL_CLOSE, {}),
};

export const rejectInterestNotificationActions = {
  request: (account, arranger, ssdId, interestNotificationId, ssdHash, interestNotificationHash) =>
    action(REJECT_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash,
    }),
  success: () => action(REJECT_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(REJECT_INTEREST_NOTIFICATION_FAILURE, { error }),
};

export const cancelInterestNotificationActions = {
  request: (account, arranger, ssdId, interestNotificationId, ssdHash, interestNotificationHash) =>
    action(CANCEL_INTEREST_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash,
    }),
  success: () => action(CANCEL_INTEREST_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CANCEL_INTEREST_NOTIFICATION_FAILURE, { error }),
};
