import { defineMessages } from "react-intl";

export const messagesEmployeeStatus = defineMessages({
  fields_employee_status_active: {
    id: "fields.employee.status.active",
    defaultMessage: "Aktiv",
  },
  fields_employee_status_suspended: {
    id: "fields.employee.status.suspended",
    defaultMessage: "Gesperrt",
  },
  fields_employee_status_pending: {
    id: "fields.employee.status.pending",
    defaultMessage: "Warte auf Bestätigung",
  },
  fields_employee_status_inactive: {
    id: "fields.employee.status.inactive",
    defaultMessage: "ohne Berechtigung",
  },
});

export const messagesDnTitles = defineMessages({
  fields_dn_titles_darlehensnehmer: {
    id: "fields.dn.titles.darlehensnehmer",
    defaultMessage: "Darlehensnehmer",
  },
  fields_dn_titles_blockchain_adresse: {
    id: "fields.dn.titles.blockchain_adresse",
    defaultMessage: "Blockchain Adresse",
  },
  fields_dn_titles_ustid: {
    id: "fields.dn.titles.ustid",
    defaultMessage: "Umsatzsteuer ID Darlehensnehmer",
  },
  fields_dn_titles_anschrift: {
    id: "fields.dn.titles.anschrift",
    defaultMessage: "Anschrift Darlehensnehmer",
  },
  fields_dn_titles_telefonnummer: {
    id: "fields.dn.titles.telefonnummer",
    defaultMessage: "Telefonnummer Darlehensnehmer",
  },
  fields_dn_titles_lei: {
    id: "fields.dn.titles.lei",
    defaultMessage: "LEI Darlehensnehmer",
  },
  fields_dn_titles_digits_kennung: {
    id: "fields.dn.titles.digits",
    defaultMessage: "Finledger Kennung Darlehensnehmer",
  },
  fields_dn_titles_mitarbeiter: {
    id: "fields.dn.titles.mitarbeiter",
    defaultMessage: "Erfassender Mitarbeiter Darlehensnehmer",
  },
  fields_dn_titles_zeichen: {
    id: "fields.dn.titles.zeichen",
    defaultMessage: "Unser Zeichen Darlehensnehmer",
  },
  fields_dn_titles_iban: {
    id: "fields.dn.titles.iban",
    defaultMessage: "IBAN Darlehensnehmer",
  },
  fields_dn_titles_bic: {
    id: "fields.dn.titles.bic",
    defaultMessage: "bic Darlehensnehmer",
  },
  fields_dn_titles_vertragsnr: {
    id: "fields.dn.titles.vertragsnr",
    defaultMessage: "Vertragsreferenz Nr. Darlehensnehmer",
  },
  fields_dn_titles_interne_kennung: {
    id: "fields.dn.titles.interne.kennung",
    defaultMessage: "Interne Kennung Darlehensnehmer",
  },
  fields_dn_titles_urkunde: {
    id: "fields.dn.titles.urkunde",
    defaultMessage: "Finledger-Urkunde verwenden",
  },
});

export const messagesDgTitles = defineMessages({
  fields_dg_titles_darlehensgeber: {
    id: "fields.dg.titles.darlehensgeber",
    defaultMessage: "Darlehensgeber",
  },
  fields_dg_titles_blockchain_adresse: {
    id: "fields.dg.titles.blockchain_adresse",
    defaultMessage: "Blockchain Adresse",
  },
  fields_dg_titles_ustid: {
    id: "fields.dg.titles.ustid",
    defaultMessage: "Umsatzsteuer ID Darlehensgeber",
  },
  fields_dg_titles_anschrift: {
    id: "fields.dg.titles.anschrift",
    defaultMessage: "Anschrift Darlehensgeber",
  },
  fields_dg_titles_lei: {
    id: "fields.dg.titles.lei",
    defaultMessage: "LEI Darlehensgeber",
  },
  fields_dg_titles_telefonnummer: {
    id: "fields.dg.titles.telefonnummer",
    defaultMessage: "Telefonnummer Darlehensgeber",
  },
  fields_dg_titles_zeichen: {
    id: "fields.dg.titles.zeichen",
    defaultMessage: "Unser Zeichen Darlehensgeber",
  },
  fields_dg_titles_digits_kennung: {
    id: "fields.dg.titles.digits",
    defaultMessage: "Finledger Kennung Darlehensgeber",
  },
  fields_dg_titles_iban: {
    id: "fields.dg.titles.iban",
    defaultMessage: "IBAN Darlehensgeber",
  },
  fields_dg_titles_bic: {
    id: "fields.dg.titles.bic",
    defaultMessage: "BIC Darlehensgeber",
  },
  fields_dg_titles_iban_tilgung: {
    id: "fields.dg.titles.iban.tilgung",
    defaultMessage: "IBAN Darlehensgeber Tilgung",
  },
  fields_dg_titles_bic_tilgung: {
    id: "fields.dg.titles.bic.tilgung",
    defaultMessage: "BIC Darlehensgeber Tilgung",
  },
  fields_dg_titles_vertragsnr: {
    id: "fields.dg.titles.vertragsnr",
    defaultMessage: "Vertragsreferenz Nr. Darlehensgeber",
  },
  fields_dg_titles_interne_kennung: {
    id: "fields.dg.titles.interne.kennung",
    defaultMessage: "Interne Kennung Darlehensgeber",
  },
});

export const messagesSvTitles = defineMessages({
  fields_sv_titles_urkundennummer: {
    id: "fields.sv.titles.urkundennummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  fields_sv_titles_handelstag: {
    id: "fields.sv.titles.handelstag",
    defaultMessage: "Handelstag",
  },
  fields_sv_titles_handelsuhrzeit: {
    id: "fields.sv.titles.handelsuhrzeit",
    defaultMessage: "Handelsuhrzeit",
  },
  fields_sv_titles_nominal: {
    id: "fields.sv.titles.nominal",
    defaultMessage: "Nominal",
  },
  fields_sv_titles_nominal_waehrung: {
    id: "fields.sv.titles.nominal.waehrung",
    defaultMessage: "Währung Nominal",
  },
  fields_sv_titles_kurs: {
    id: "fields.sv.titles.kurs",
    defaultMessage: "Kurs",
  },
  fields_sv_titles_notiz: {
    id: "fields.sv.titles.notiz",
    defaultMessage: "Notiz",
  },
  fields_sv_titles_kaufpreis: {
    id: "fields.sv.titles.kaufpreis",
    defaultMessage: "Kaufpreis",
  },
  fields_sv_titles_kaufpreis_waehrung: {
    id: "fields.sv.titles.kaufpreis.waehrung",
    defaultMessage: "Währung Kaufpreis",
  },
  fields_sv_titles_valuta: {
    id: "fields.sv.titles.valuta",
    defaultMessage: "Valuta",
  },
  fields_sv_titles_endfaelligkeit: {
    id: "fields.sv.titles.endfaelligkeit",
    defaultMessage: "Endfälligkeit",
  },
  fields_sv_titles_verzinsung: {
    id: "fields.sv.titles.verzinsung",
    defaultMessage: "Verzinsung",
  },
  fields_sv_titles_referenzzinssatz: {
    id: "fields.sv.titles.referrenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  fields_sv_titles_aufschlag: {
    id: "fields.sv.titles.aufschlag",
    defaultMessage: "Aufschlag in %",
  },
  fields_sv_titles_minimum: {
    id: "fields.sv.titles.minimum",
    defaultMessage: "Minimumzinssatz in %",
  },
  fields_sv_titles_abschlag: {
    id: "fields.sv.titles.abschlag",
    defaultMessage: "Abschlag in %",
  },
  fields_sv_titles_maximum: {
    id: "fields.sv.titles.maximum",
    defaultMessage: "Maximumzinssatz in %",
  },
  fields_sv_titles_faktor: {
    id: "fields.sv.titles.faktor",
    defaultMessage: "Faktor",
  },
  fields_sv_titles_bankarbeitstage: {
    id: "fields.sv.titles.bankarbeitstage",
    defaultMessage: "Anzahl der Bankarbeitstage ",
  },
  fields_sv_titles_bildschirmseite: {
    id: "fields.sv.titles.bildschirmseite",
    defaultMessage: "Bildschirmseite",
  },
  fields_sv_titles_relevante_uhrzeit: {
    id: "fields.sv.titles.relevante.uhrzeit",
    defaultMessage: "Relevante Uhrzeit",
  },
  fields_sv_titles_relevanter_ort: {
    id: "fields.sv.titles.relevanter.ort",
    defaultMessage: "Relevanter Ort",
  },
  fields_sv_titles_fixingtyp: {
    id: "fields.sv.titles.fixingtyp",
    defaultMessage: "Fixingtyp",
  },
  fields_sv_titles_zinssatz: {
    id: "fields.sv.titles.zinssatz",
    defaultMessage: "Zinssatz in %",
  },
  fields_sv_titles_zinsrhythmus: {
    id: "fields.sv.titles.zinsrhythmus",
    defaultMessage: "Zinszahlungsrhythmus",
  },
  fields_sv_titles_zinstermin_tag: {
    id: "fields.sv.titles.zinstermin.tag",
    defaultMessage: "Zinstermin Tag",
  },
  fields_sv_titles_zinstermin_monat: {
    id: "fields.sv.titles.zinstermin.monat",
    defaultMessage: "Zinstermin Monat",
  },
  fields_sv_titles_zinsmethode: {
    id: "fields.sv.titles.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  fields_sv_titles_tag_zinszahlung: {
    id: "fields.sv.titles.tag.zinszahlung",
    defaultMessage: "Fälligkeitstag der Zinszahlung",
  },
  fields_sv_titles_zeitraum_zinszahlung: {
    id: "fields.sv.titles.zeitraum.zinszahlung",
    defaultMessage: "Berechnungszeitraum der Zinszahlung",
  },
  fields_sv_titles_zinstermin_erster: {
    id: "fields.sv.titles.zinstermin.erster",
    defaultMessage: "erster Zinstermin",
  },
  fields_sv_titles_zinstermin_vorletzter: {
    id: "fields.sv.titles.zinstermin.vorletzter",
    defaultMessage: "vorletzter Zinstermin",
  },
  fields_sv_titles_geschaeftstagekalender: {
    id: "fields.sv.titles.geschaeftstagekalender",
    defaultMessage: "Geschäftstagekalender",
  },
  fields_sv_titles_kuendbar: {
    id: "fields.sv.titles.kuendbar",
    defaultMessage: "Kündbar durch DN",
  },
  fields_sv_titles_kuendigungstermine: {
    id: "fields.sv.titles.kuendigungstermine",
    defaultMessage: "Kündigungstermine",
  },
  fields_sv_titles_kuendigungsfrist: {
    id: "fields.sv.titles.kuendigungsfrist",
    defaultMessage: "Kündigungsfrist (in Tagen)",
  },
  fields_sv_titles_abtretbarkeit: {
    id: "fields.sv.titles.abtretbarkeit",
    defaultMessage: "Abtretbarkeit",
  },
  fields_sv_titles_mindestbetrag_abtretung: {
    id: "fields.sv.titles.mindestbetrag.abtretung",
    defaultMessage: "Mindestbetrag bei Abtretung",
  },
  fields_sv_titles_mindestbetrag_abtretung_waehrung: {
    id: "fields.sv.titles.mindestbetrag.abtretung.waehrung",
    defaultMessage: "Währung Mindestbetrag bei Abtretung",
  },
  fields_sv_titles_auslandsfaehig: {
    id: "fields.sv.titles.auslandsfaehigkeit",
    defaultMessage: "Auslandsfähigkeit",
  },
  fields_sv_titles_vag: {
    id: "fields.sv.titles.vag",
    defaultMessage: "VAG-konform",
  },
  fields_sv_titles_mrel: {
    id: "fields.sv.titles.mrel",
    defaultMessage: "MREL / Art. 72b CRR",
  },
  fields_sv_titles_kwg: {
    id: "fields.sv.titles.kwg",
    defaultMessage: "§ 46f KWG preferred",
  },
  fields_sv_titles_sag: {
    id: "fields.sv.titles.sag",
    defaultMessage: "§ 49 SAG",
  },
  fields_sv_titles_tlac: {
    id: "fields.sv.titles.tlac",
    defaultMessage: "TLAC",
  },
});

export const messagesRebuyTitles = defineMessages({
  fields_rebuy_titles_handelstag: {
    id: "fields.rebuy.titles.handelstag",
    defaultMessage: "Handelstag Rückkauf",
  },
  fields_rebuy_titles_uhrzeit: {
    id: "fields.rebuy.titles.uhrzeit",
    defaultMessage: "Uhrzeit",
  },
  fields_rebuy_titles_nominal: {
    id: "fields.rebuy.titles.nominal",
    defaultMessage: "Nominal",
  },
  fields_rebuy_titles_nominal_waehrung: {
    id: "fields.rebuy.titles.nominal.waehrung",
    defaultMessage: "Nominal Währung",
  },
  fields_rebuy_titles_ursp_endfaelligkeit: {
    id: "fields.rebuy.titles.endfaelligkeit.ursp",
    defaultMessage: "Ursprüngliche Endfälligkeit",
  },
  fields_rebuy_titles_ruecknamekurs: {
    id: "fields.rebuy.titles.ruecknahmekurs",
    defaultMessage: "Rücknahmekurs",
  },
  fields_rebuy_titles_ruecknamekurs_notiz: {
    id: "fields.rebuy.titles.ruecknahmekurs.notiz",
    defaultMessage: "Notiz Rücknahmekurs",
  },
  fields_rebuy_titles_ruecknahmebetrag: {
    id: "fields.rebuy.titles.ruecknamebetrag",
    defaultMessage: "Rücknahmebetrag",
  },
  fields_rebuy_titles_ruecknahmebetrag_waehrung: {
    id: "fields.rebuy.titles.ruecknahmebetrag.waehrung",
    defaultMessage: "Währung Rücknahmebetrag",
  },
  fields_rebuy_titles_ruecknahmebetrag_valuta: {
    id: "fields.rebuy.titles.ruecknahmebetrag.valuta",
    defaultMessage: "Valuta Rücknahmebetrag",
  },
  fields_rebuy_titles_stueckzinsen: {
    id: "fields.rebuy.titles.stueckzinsen",
    defaultMessage: "Aufgelaufene Stückzinsen",
  },
  fields_rebuy_titles_stueckzinsen_waehrung: {
    id: "fields.rebuy.titles.stueckzinsen.waehrung",
    defaultMessage: "Währung Aufgelaufene Stückzinsen",
  },
  fields_rebuy_titles_stueckzinsen_valuta: {
    id: "fields.rebuy.titles.stueckzinsen.valuta",
    defaultMessage: "Valuta Aufgelaufene Stückzinsen",
  },
  fields_rebuy_titles_urkundennummer: {
    id: "fields.rebuy.titles.urkundennummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
});

export const messagesCessionTitles = defineMessages({
  fields_cession_titles_zessionar: {
    id: "fields.cession.titles.zessionar",
    defaultMessage: "Darlehensgeber (Zessionar)",
  },
  fields_cession_titles_dg_blockchain: {
    id: "fields.cession.titles.dg.blockchain",
    defaultMessage: "Blockchain Adresse",
  },
  fields_cession_titles_dg_ustid: {
    id: "fields.cession.titles.dg.ustid",
    defaultMessage: "Umsatzsteuer ID Darlehensgeber",
  },
  fields_cession_titles_dg_anschrift: {
    id: "fields.cession.titles.dg.anschrift",
    defaultMessage: "Anschrift Darlehensgeber",
  },
  fields_cession_titles_dg_telefonnummer: {
    id: "fields.cession.titles.dg.telefonnummer",
    defaultMessage: "Telefonnummer Darlehensgeber",
  },
  fields_cession_titles_dg_lei: {
    id: "fields.cession.titles.dg.lei",
    defaultMessage: "LEI Darlehensgeber",
  },
  fields_cession_titles_dg_digits: {
    id: "fields.cession.titles.dg.digits",
    defaultMessage: "Finledger Kennung Darlehensgeber",
  },
  fields_cession_titles_dg_kennung: {
    id: "fields.cession.titles.dg.kennung",
    defaultMessage: "Interne Kennung",
  },
  fields_cession_titles_dg_vertragsnr: {
    id: "fields.cession.titles.dg.vertragsnr",
    defaultMessage: "Vertragsreferenz Nr",
  },
  fields_cession_titles_dg_zeichen: {
    id: "fields.cession.titles.dg.zeichen",
    defaultMessage: "Unser Zeichen",
  },
  fields_cession_titles_iban_zinsen: {
    id: "fields.cession.titles.iban.zinsen",
    defaultMessage: "IBAN Zinsen",
  },
  fields_cession_titles_bic_zinsen: {
    id: "fields.cession.titles.bic.zinsen",
    defaultMessage: "BIC Zinsen",
  },
  fields_cession_titles_iban_tilgung: {
    id: "fields.cession.titles.iban.tilgung",
    defaultMessage: "IBAN Tilgung",
  },
  fields_cession_titles_bic_tilgung: {
    id: "fields.cession.titles.bic.tilgung",
    defaultMessage: "BIC Tilgung",
  },
  fields_cession_titles_iban_zinsen_tilgung: {
    id: "fields.cession.titles.iban.zinsen.tilgung",
    defaultMessage: "IBAN Zinsen und Tilgung",
  },
  fields_cession_titles_bic_zinsen_tilgung: {
    id: "fields.cession.titles.bic.zinsen.tilgung",
    defaultMessage: "BIC Zinsen und Tilgung",
  },
  fields_cession_titles_datum: {
    id: "fields.cession.titles.datum",
    defaultMessage: "Datum der Abtretung",
  },
  fields_cession_titles_uhrzeit: {
    id: "fields.cession.titles.uhrzeit",
    defaultMessage: "Uhrzeit",
  },
  fields_cession_titles_nominal: {
    id: "fields.cession.titles.nominal",
    defaultMessage: "Nominal der Abtretung",
  },
  fields_cession_titles_nominal_waehrung: {
    id: "fields.cession.titles.nominal.waehrung",
    defaultMessage: "Währung Nominal",
  },
  fields_cession_titles_valuta: {
    id: "fields.cession.titles.valuta",
    defaultMessage: "Valuta Abtretung",
  },
  fields_cession_titles_kurs: {
    id: "fields.cession.titles.kurs",
    defaultMessage: "Abtretungskurs",
  },
  fields_cession_titles_kurs_notiz: {
    id: "fields.cession.titles.kurs.notiz",
    defaultMessage: "Notiz Abtretungskurs",
  },
  fields_cession_titles_gegenwert: {
    id: "fields.cession.titles.gegenwert",
    defaultMessage: "Gegenwert Abtretung",
  },
  fields_cession_titles_gegenwert_waehrung: {
    id: "fields.cession.titles.gegenwert.waehrung",
    defaultMessage: "Währung Gegenwert Abtretung",
  },
  fields_cession_titles_stueckzinsen: {
    id: "fields.cession.titles.stueckzinsen",
    defaultMessage: "Stückzinsen",
  },
  fields_cession_titles_stueckzinsen_waehrung: {
    id: "fields.cession.titles.stueckzinsen.waehrung",
    defaultMessage: "Währung Stückzinsen",
  },
  fields_cession_titles_stueckzinsen_valuta: {
    id: "fields.cession.titles.stueckzinsen.valuta",
    defaultMessage: "Valuta Stückzinsen",
  },
  fields_cession_titles_zinstermin_naechster: {
    id: "fields.cession.titles.zinstermin.naechster",
    defaultMessage: "Nächster Zinstermin",
  },
  fields_cession_titles_urkundennummer: {
    id: "fields.cession.titles.urkundennummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
});

export const messagesCessionInfo = defineMessages({
  fields_cession_info_darlehensnehmer: {
    id: "fields.cession.info.darlehensnehmer",
    defaultMessage: "Darlehensnehmer",
  },
  fields_cession_info_blockchain: {
    id: "fields.cession.info.blockchain",
    defaultMessage: "Blockchain Adresse",
  },
  fields_cession_info_lei: {
    id: "fields.cession.info.lei",
    defaultMessage: "LEI",
  },
  fields_cession_info_digits: {
    id: "fields.cession.info.digits",
    defaultMessage: "Finledger-Kennung",
  },
  fields_cession_info_nominal: {
    id: "fields.cession.info.nominal",
    defaultMessage: "Nominal",
  },
  fields_cession_info_nominal_waehrung: {
    id: "fields.cession.info.nominal.waehrung",
    defaultMessage: "Währung Nominal",
  },
  fields_cession_info_valuta: {
    id: "fields.cession.info.valuta",
    defaultMessage: "Valuta",
  },
  fields_cession_info_endfaelligkeit: {
    id: "fields.cession.info.endfaelligkeit",
    defaultMessage: "Endfälligkeit",
  },
  fields_cession_info_kuendbar: {
    id: "fields.cession.info.kuendbar",
    defaultMessage: "Kündbar durch DN",
  },
  fields_cession_info_kuendigungstermine: {
    id: "fields.cession.info.kuendigungstermine",
    defaultMessage: "Kündigungstermine",
  },
  fields_cession_info_kuendigungsfrist: {
    id: "fields.cession.info.kuendigungsfrist",
    defaultMessage: "Kündigungsfrist (in Tagen)",
  },
  fields_cession_info_urkundennummer: {
    id: "fields.cession.info.urkundennummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  fields_cession_info_zinssatz: {
    id: "fields.cession.info.zinssatz",
    defaultMessage: "Zinssatz in %",
  },
  fields_cession_info_zinszahlungsrythmus: {
    id: "fields.cession.info.zinszahlungsrythmus",
    defaultMessage: "Zinszahlungsrythmus",
  },
  fields_cession_info_zinsmethode: {
    id: "fields.cession.info.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  fields_cession_info_tag_zinszahlung: {
    id: "fields.cession.info.tag.zinszahlung",
    defaultMessage: "Fälligkeitstag der Zinszahlung",
  },
  fields_cession_info_zeitraum_zinszahlung: {
    id: "fields.cession.info.zeitraum.zinszahlung",
    defaultMessage: "Berechnungszeitraum der Zinszahlung",
  },
  fields_cession_info_zinstermin_tag: {
    id: "fields.cession.info.zinstermin.tag",
    defaultMessage: "Zinstermin Tag",
  },
  fields_cession_info_zinstermin_monat: {
    id: "fields.cession.info.zinstermin.monat",
    defaultMessage: "Zinstermin Monat",
  },
  fields_cession_info_verzinsung: {
    id: "fields.cession.info.verzinsung",
    defaultMessage: "Verzinsung",
  },
  fields_cession_info_referenzzinssatz: {
    id: "fields.cession.info.referenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  fields_cession_info_aufschlag: {
    id: "fields.cession.info.aufschlag",
    defaultMessage: "Aufschlag in %",
  },
  fields_cession_info_minimum: {
    id: "fields.cession.info.minimum",
    defaultMessage: "Minimumzinssatz in %",
  },
  fields_cession_info_abschlag: {
    id: "fields.cession.info.abschlag",
    defaultMessage: "Abschlag in %",
  },
  fields_cession_info_maximum: {
    id: "fields.cession.info.maximum",
    defaultMessage: "Maximumzinssatz in %",
  },
  fields_cession_info_faktor: {
    id: "fields.cession.info.faktor",
    defaultMessage: "Faktor",
  },
  fields_cession_info_bankarbeitstage: {
    id: "fields.cession.info.bankarbeitstage",
    defaultMessage: "Anzahl der Bankarbeitstage ",
  },
  fields_cession_info_bildschirmseite: {
    id: "fields.cession.info.bildschirmseite",
    defaultMessage: "Bildschirmseite ",
  },
  fields_cession_info_relevante_uhrzeit: {
    id: "fields.cession.info.relevante.uhrzeit",
    defaultMessage: "Relevante Uhrzeit",
  },
  fields_cession_info_relevanter_ort: {
    id: "fields.cession.info.relevanter.ort",
    defaultMessage: "Relevanter Ort",
  },
  fields_cession_info_fixingtyp: {
    id: "fields.cession.info.fixingtyp",
    defaultMessage: "Fixingtyp",
  },
});

export const messagesInterestNotificationTitles = defineMessages({
  fields_interest_notification_titles_datum: {
    id: "fields.interest.notification.titles.datum",
    defaultMessage: "Datum",
  },
  fields_interest_notification_titles_referenzzinssatz: {
    id: "fields.interest.notification.titles.referenzzinssatz",
    defaultMessage: "Festgestellter Referenzzinssatz",
  },
  fields_interest_notification_titles_referenzzinssatz_tag: {
    id: "fields.interest.notification.titles.referenzzinssatz.tag",
    defaultMessage: "Feststellungstag d. Referenzzinses",
  },
  fields_interest_notification_titles_zinssatz: {
    id: "fields.interest.notification.titles.zinssatz",
    defaultMessage: "Zinssatz",
  },
  fields_interest_notification_titles_periode_von: {
    id: "fields.interest.notification.titles.periode.von",
    defaultMessage: "Zinsperiode von (inklusive)",
  },
  fields_interest_notification_titles_periode_bis: {
    id: "fields.interest.notification.titles.periode.bis",
    defaultMessage: "Zinsperiode bis (exklusive)",
  },
  fields_interest_notification_titles_anzahl_tage: {
    id: "fields.interest.notification.titles.anzahl.tage",
    defaultMessage: "Anzahl Tage",
  },
  fields_interest_notification_titles_zinsbetrag: {
    id: "fields.interest.notification.titles.zinsbetrag",
    defaultMessage: "Zinsbetrag",
  },
  fields_interest_notification_titles_zahltag: {
    id: "fields.interest.notification.titles.zahltag",
    defaultMessage: "Zahltag",
  },
  fields_interest_notification_titles_zinsfestsetzung: {
    id: "fields.interest.notification.titles.zinsfestsetzung",
    defaultMessage: "Nächste Zinsfestsetzung",
  },
});
