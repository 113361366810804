import React from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";

export const verificationHeader = {
  valid: () => <FormattedMessage {...messages.valid} />,
  notValid: () => <FormattedMessage {...messages.notValid} />,
};

export const verificationText = {
  A: () => <FormattedMessage {...messages.a} />,
  B: () => <FormattedMessage {...messages.b} />,
  C: () => <FormattedMessage {...messages.c} />,
  D: () => <FormattedMessage {...messages.d} />,
  E: () => <FormattedMessage {...messages.e} />,
  F: () => <FormattedMessage {...messages.f} />,
  G: () => <FormattedMessage {...messages.g} />,
  H: (terminationDate) => (
    <FormattedMessage {...messages.h} values={{ terminationDate: `${terminationDate}` }} />
  ),
  I: (terminationDate) => (
    <FormattedMessage {...messages.i} values={{ terminationDate: `${terminationDate}` }} />
  ),
  J: () => <FormattedMessage {...messages.j} />,
  K: () => <FormattedMessage {...messages.k} />,
  L: () => <FormattedMessage {...messages.l} />,
  M: () => <FormattedMessage {...messages.m} />,
};
