export const SAVE_SSD_REQUEST = "SAVE_SSD_REQUEST";
export const SAVE_SSD_SUCCESS = "SAVE_SSD_SUCCESS";
export const SAVE_SSD_FAILURE = "SAVE_SSD_FAILURE";

export const SUBMIT_SSD_REQUEST = "SUBMIT_SSD_REQUEST";
export const SUBMIT_SSD_SUCCESS = "SUBMIT_SSD_SUCCESS";
export const SUBMIT_SSD_FAILURE = "SUBMIT_SSD_FAILURE";

//
// CONFIRMATION STATE MACHINE
//

export const OFFER_SSD_REQUEST = "OFFER_SSD_REQUEST";
export const OFFER_SSD_SUCCESS = "OFFER_SSD_SUCCESS";
export const OFFER_SSD_FAILURE = "OFFER_SSD_FAILURE";

export const REJECT_SSD_REQUEST = "REJECT_SSD_REQUEST";
export const REJECT_SSD_SUCCESS = "REJECT_SSD_SUCCESS";
export const REJECT_SSD_FAILURE = "REJECT_SSD_FAILURE";

export const OFFER_SSD_CANCEL_CONFIRMATION_REQUEST = "OFFER_SSD_CANCEL_CONFIRMATION_REQUEST";
export const OFFER_SSD_CANCEL_CONFIRMATION_SUCCESS = "OFFER_SSD_CANCEL_CONFIRMATION_SUCCESS";
export const OFFER_SSD_CANCEL_CONFIRMATION_FAILURE = "OFFER_SSD_CANCEL_CONFIRMATION_FAILURE";

export const ACCEPT_SSD_REQUEST = "ACCEPT_SSD_REQUEST";
export const ACCEPT_SSD_SUCCESS = "ACCEPT_SSD_SUCCESS";
export const ACCEPT_SSD_FAILURE = "ACCEPT_SSD_FAILURE";

export const UPDATE_SSD_REQUEST = "UPDATE_SSD_REQUEST";
export const UPDATE_SSD_SUCCESS = "UPDATE_SSD_SUCCESS";
export const UPDATE_SSD_FAILURE = "UPDATE_SSD_FAILURE";

export const UPDATE_ACCEPT_SSD_REQUEST = "UPDATE_ACCEPT_SSD_REQUEST";
export const UPDATE_ACCEPT_SSD_SUCCESS = "UPDATE_ACCEPT_SSD_SUCCESS";
export const UPDATE_ACCEPT_SSD_FAILURE = "UPDATE_ACCEPT_SSD_FAILURE";

export const ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST = "ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST";
export const ACCEPT_SSD_CANCEL_CONFIRMATION_SUCCESS = "ACCEPT_SSD_CANCEL_CONFIRMATION_SUCCESS";
export const ACCEPT_SSD_CANCEL_CONFIRMATION_FAILURE = "ACCEPT_SSD_CANCEL_CONFIRMATION_FAILURE";

export const CANCEL_SSD_REQUEST = "CANCEL_SSD_REQUEST";
export const CANCEL_SSD_SUCCESS = "CANCEL_SSD_SUCCESS";
export const CANCEL_SSD_FAILURE = "CANCEL_SSD_FAILURE";

export const SIGN_DOCUMENT_REQUEST = "SIGN_DOCUMENT_REQUEST";
export const SIGN_DOCUMENT_SUCCESS = "SIGN_DOCUMENT_SUCCESS";
export const SIGN_DOCUMENT_FAILURE = "SIGN_DOCUMENT_FAILURE";

export const DOCUSIGN_DOCUMENT_REQUEST = "DOCUSIGN_DOCUMENT_REQUEST";
export const DOCUSIGN_DOCUMENT_SUCCESS = "DOCUSIGN_DOCUMENT_SUCCESS";
export const DOCUSIGN_DOCUMENT_FAILURE = "DOCUSIGN_DOCUMENT_FAILURE";

export const UPLOAD_SIGNED_DOCUMENT_REQUEST = "UPLOAD_SIGNED_DOCUMENT_REQUEST";
export const UPLOAD_SIGNED_DOCUMENT_SUCCESS = "UPLOAD_SIGNED_DOCUMENT_SUCCESS";
export const UPLOAD_SIGNED_DOCUMENT_FAILURE = "UPLOAD_SIGNED_DOCUMENT_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const signDocumentActions = {
  request: (account, arranger, id, fingerprint) =>
    action(SIGN_DOCUMENT_REQUEST, {
      account,
      arranger,
      id,
      fingerprint,
    }),
  success: () => action(SIGN_DOCUMENT_SUCCESS, {}),
  failure: (error) => action(SIGN_DOCUMENT_FAILURE, { error }),
};

export const docuSignDocumentActions = {
  request: (account, arranger, id, fingerprint) =>
    action(DOCUSIGN_DOCUMENT_REQUEST, {
      account,
      arranger,
      id,
      fingerprint,
    }),
  success: () => action(DOCUSIGN_DOCUMENT_SUCCESS, {}),
  failure: (error) => action(DOCUSIGN_DOCUMENT_FAILURE, { error }),
};

export const saveSsdActions = {
  request: (account, ssdData) => action(SAVE_SSD_REQUEST, { account, ssdData }),
  success: () => action(SAVE_SSD_SUCCESS, {}),
  failure: (error) => action(SAVE_SSD_FAILURE, { error }),
};

export const submitSsdActions = {
  request: (account, ssdData) => action(SUBMIT_SSD_REQUEST, { account, ssdData }),
  success: () => action(SUBMIT_SSD_SUCCESS, {}),
  failure: (error) => action(SUBMIT_SSD_FAILURE, { error }),
};

export const offerSsdActions = {
  request: (account, ssdData, fingerprint, arranger, isSecondConfirm, firstConfirmer) =>
    action(OFFER_SSD_REQUEST, {
      account,
      ssdData,
      fingerprint,
      arranger,
      isSecondConfirm,
      firstConfirmer,
    }),
  success: () => action(OFFER_SSD_SUCCESS, {}),
  failure: (error) => action(OFFER_SSD_FAILURE, { error }),
};

export const rejectSsdActions = {
  request: (account, arranger, id, fingerprint, reason) =>
    action(REJECT_SSD_REQUEST, {
      account,
      arranger,
      id,
      fingerprint,
      reason,
    }),
  success: () => action(REJECT_SSD_SUCCESS, {}),
  failure: (error) => action(REJECT_SSD_FAILURE, { error }),
};

export const acceptSsdActions = {
  request: (account, arranger, ssdData, fingerprint, isSecondConfirm, firstConfirmer) =>
    action(ACCEPT_SSD_REQUEST, {
      account,
      arranger,
      ssdData,
      fingerprint,
      isSecondConfirm,
      firstConfirmer
    }),
  success: () => action(ACCEPT_SSD_SUCCESS, {}),
  failure: (error) => action(ACCEPT_SSD_FAILURE, { error }),
};

export const updateSsdActions = {
  request: (ssdData, bicOrIbanChanged) =>
    action(UPDATE_SSD_REQUEST, {
      ssdData,
      bicOrIbanChanged,
    }),
  success: () => action(UPDATE_SSD_SUCCESS, {}),
  failure: (error) => action(UPDATE_SSD_FAILURE, { error }),
};

export const updateAndAcceptSsdActions = {
  request: (account, arranger, id, fingerprint, isSecondConfirm, firstConfirmer, ssdData, bicOrIbanChanged) =>
    action(UPDATE_ACCEPT_SSD_REQUEST, {
      account,
      arranger,
      id,
      fingerprint,
      isSecondConfirm,
      firstConfirmer,
      ssdData,
      bicOrIbanChanged,
    }),
  success: () => action(UPDATE_ACCEPT_SSD_SUCCESS, {}),
  failure: (error) => action(UPDATE_ACCEPT_SSD_FAILURE, { error }),
};

export const cancelSsdActions = {
  request: (account, id, fingerprint, reason, arranger) =>
    action(CANCEL_SSD_REQUEST, { account, id, fingerprint, reason, arranger }),
  success: () => action(CANCEL_SSD_SUCCESS, {}),
  failure: (error) => action(CANCEL_SSD_FAILURE, { error }),
};

export const offerSsdCancelConfirmationActions = {
  request: (account, id) => action(OFFER_SSD_CANCEL_CONFIRMATION_REQUEST, { account, id }),
  success: () => action(OFFER_SSD_CANCEL_CONFIRMATION_SUCCESS, {}),
  failure: (error) => action(OFFER_SSD_CANCEL_CONFIRMATION_FAILURE, { error }),
};

export const acceptSsdCancelConfirmationActions = {
  request: (account, arranger, id) =>
    action(ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST, { account, arranger, id }),
  success: () => action(ACCEPT_SSD_CANCEL_CONFIRMATION_SUCCESS, {}),
  failure: (error) => action(ACCEPT_SSD_CANCEL_CONFIRMATION_FAILURE, { error }),
};

export const uploadSignedDocumentActions = {
  request: (account, pdfFile, ssdId, skipMetadataCheck, fingerprint, arranger) =>
    action(UPLOAD_SIGNED_DOCUMENT_REQUEST, {
      account,
      pdfFile,
      ssdId,
      skipMetadataCheck,
      fingerprint,
      arranger,
    }),
  success: () => action(UPLOAD_SIGNED_DOCUMENT_SUCCESS, {}),
  failure: (error) => action(UPLOAD_SIGNED_DOCUMENT_FAILURE, { error }),
};
