import { _base64ToArrayBuffer } from "./convertor";

export const FileTypes = {
  PDF: "application/pdf",
  JSON: "text/json;charset=utf-8",
  CSV: "text/csv;charset=utf-8",
};

export function readSingleFile(e, callback) {
  let file = e.target.files[0];
  if (!file) {
    return;
  }
  let reader = new FileReader();
  reader.onload = function (e) {
    let contents = e.target.result;
    callback(contents);
  };
  reader.readAsText(file);
}

export const readFileAsync = (file) => {
  // the FileReader API only supports callbacks.
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const downloadFileFromBinary = (binary, fileName, fileType) => {
  const blob = new Blob([binary], {
    type: fileType,
  });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display :none";
    a.setAttribute("href", URL.createObjectURL(blob));
    a.setAttribute("download", fileName);
    a.click();
  }
};

export const downloadFileFromBase64 = (base64, fileName, fileType) => {
  const binary = _base64ToArrayBuffer(base64);
  downloadFileFromBinary(binary, fileName, fileType);
};

export const bytesToMegaBytes = (bytes, digits) =>
  digits ? (bytes / (1024 * 1024)).toFixed(digits) : bytes / (1024 * 1024);
