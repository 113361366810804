import { createFingerprint, validateFingerprint } from "services/fingerprintService";
import {
  clearCessionNumberFormat,
  clearInterestNumberFormat,
  clearRebuyNumberFormat,
  clearSsdNumberFormat,
  clearTerminationNumberFormat,
} from "./convertor";

export const FP_TYPE = {
  CSSD: "cssd", // CssdDTO (auch für Subfingerprints)
  CSSDABWICKLUNGSINFORMATIONEN: "cssdAbwicklungsinformationen", //CssdAbwicklungsinformationenDTO
  CSSDKAUF: "cssdKauf", //CssdKaufDTO
  CSSDABTRETUNG: "cssdAbtretung", //CssdAbtretungDTO (auch für Subfingerprints)
  CSSDZINSMITTEILUNG: "cssdZinsmitteilung", //CssdZinsmitteilungDTO
  CSSDKUENDIGUNG: "cssdKuendigung", // CssdKuendigungDTO
  CSSDABLEHNUNGSGRUND: "cssdAblehnungsgrund",
  PLATFORMMEMBER: "platformmember",
  SSD: "ssd",
  SSDRUECKKAUF: "ssdRueckkauf",
  SSDABTRETUNG: "ssdAbtretung",
  SSDZINSMITTEILUNG: "ssdZinsmitteilung",
  SSDKUENDIGUNG: "ssdKuendigung",
  MIGRIERTETEILFORDERUNG: "migrierteTeilforderung", // CssdTeilforderungRequest
};

export async function generateCSSDFingerprints(cssd) {
  const response = await createFingerprint(FP_TYPE.CSSD, cssd);
  return response.data;
}

export async function generateCSSDCessionFingerprints(cession) {
  const response = await createFingerprint(FP_TYPE.CSSDABTRETUNG, cession);
  return response.data;
}

export async function generateCssdSettlementInformationFingerprint(settlementInformation) {
  const response = await createFingerprint(
    FP_TYPE.CSSDABWICKLUNGSINFORMATIONEN,
    settlementInformation
  );
  return response.data;
}

export async function generateCssdInterestNotificationFingerprint(interestNotification) {
  const response = await createFingerprint(FP_TYPE.CSSDZINSMITTEILUNG, interestNotification);
  return response.data;
}

export async function generateCssdTerminationFingerprint(termination) {
  const response = await createFingerprint(FP_TYPE.CSSDKUENDIGUNG, termination);
  return response.data;
}

export async function generateCssdRejectionFingerprint(rejection) {
  const response = await createFingerprint(FP_TYPE.CSSDABLEHNUNGSGRUND, rejection);
  return response.data;
}

export async function generatePlatformmemberFingerprint(platformmember) {
  const response = await createFingerprint(FP_TYPE.PLATFORMMEMBER, platformmember);
  return response.data;
}

export async function generateSsdFingerprint(ssd) {
  const response = await createFingerprint(FP_TYPE.SSD, clearSsdNumberFormat(ssd));
  return response.data;
}

export async function generateSsdRebuyFingerprint(buyback) {
  const response = await createFingerprint(FP_TYPE.SSDRUECKKAUF, clearRebuyNumberFormat(buyback));
  return response.data;
}

export async function generateSsdCessionFingerprint(cession) {
  const response = await createFingerprint(FP_TYPE.SSDABTRETUNG, clearCessionNumberFormat(cession));
  return response.data;
}

export async function generateSsdInterestNotificationFingerprint(interestNotification) {
  const response = await createFingerprint(
    FP_TYPE.SSDZINSMITTEILUNG,
    clearInterestNumberFormat(interestNotification)
  );
  return response.data;
}

export async function generateSsdTerminationFingerprint(rejection) {
  const response = await createFingerprint(
    FP_TYPE.SSDKUENDIGUNG,
    clearTerminationNumberFormat(rejection)
  );
  return response.data;
}

export async function generateMigratedPartialClaimFingerprints(partialClaim) {
  const response = await createFingerprint(FP_TYPE.MIGRIERTETEILFORDERUNG, partialClaim);
  return response.data;
}

export async function validateCssdFingerprint(cssd) {
  try {
    const response = await validateFingerprint(FP_TYPE.CSSD, cssd);
    return response.status === 200 ? response.data : false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function validateCssdCessionFingerprint(cession) {
  try {
    const response = await validateFingerprint(FP_TYPE.CSSDABTRETUNG, cession);
    return response.status === 200 ? response.data : false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function validateSsdFingerprint(ssd) {
  try {
    const response = await validateFingerprint(FP_TYPE.SSD, clearSsdNumberFormat(ssd));
    return response.status === 200 ? response.data : false;
  } catch (e) {
    console.error(e);
    return false;
  }
}
