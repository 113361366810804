import {
  OPEN_CSSD_CESSION_OVERVIEW,
  CESSION_SET_CSSD_PARTIAL_CLAIM,
  EDIT_CSSD_CESSION,
  CLOSE_CSSD_CESSION_MODALS,
  DETAIL_CSSD_CESSION,
  CREATE_CSSD_CESSION_REQUEST,
  CREATE_CSSD_CESSION_SUCCESS,
  CREATE_CSSD_CESSION_FAILURE,
  CONFIRM_CSSD_CESSION_REQUEST,
  CONFIRM_CSSD_CESSION_SUCCESS,
  CONFIRM_CSSD_CESSION_FAILURE,
  UPDATE_CSSD_CESSION_REQUEST,
  UPDATE_CSSD_CESSION_SUCCESS,
  UPDATE_CSSD_CESSION_FAILURE,
  ACCEPT_CSSD_CESSION_REQUEST,
  ACCEPT_CSSD_CESSION_SUCCESS,
  ACCEPT_CSSD_CESSION_FAILURE,
  UPDATE_NEW_DG_DETAILS_REQUEST,
  UPDATE_NEW_DG_DETAILS_SUCCESS,
  UPDATE_NEW_DG_DETAILS_FAILURE,
  BULK_CSSD_CESSION_REQUEST,
  BULK_CSSD_CESSION_SUCCESS,
  BULK_CSSD_CESSION_FAILURE,
  BULK_CSSD_CESSION_NOTIFY_REQUEST,
  BULK_CSSD_CESSION_NOTIFY_SUCCESS,
  BULK_CSSD_CESSION_NOTIFY_FAILURE,
  SET_PAGE_CSSD_CESSION_TABLE,
  SET_PAGE_SIZE_CSSD_CESSION_TABLE,
  FETCH_CSSD_CESSION_SUCCESS,
  FETCH_CSSD_CESSIONREQUEST_SUCCESS,
  FILTER_CSSD_CESSION_TABLE,
  SORT_CSSD_CESSION_TABLE,
  UPDATE_CSSD_CESSION,
  REMOVE_CSSD_CESSION,
  REMOVE_CSSD_CESSION_REQUEST,
  REVIEW_CSSD_CESSION_REQUEST,
  SAVE_AND_CONFIRM_CESSION_REQUEST,
  SAVE_AND_CONFIRM_CESSION_SUCCESS,
  SAVE_AND_CONFIRM_CESSION_FAILURE,
  OPEN_PAYMENT_CONFIRMED_DIALOG,
  CLOSE_PAYMENT_CONFIRMED_DIALOG,
  CSSD_PAYMENT_CONFIRMED_REQUEST,
  CSSD_PAYMENT_CONFIRMED_SUCCESS,
  CSSD_PAYMENT_CONFIRMED_FAILURE,
  UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST,
  UPDATE_AND_ACCEPT_NEW_DG_DETAILS_SUCCESS,
  UPDATE_AND_ACCEPT_NEW_DG_DETAILS_FAILURE,
  FETCH_CSSD_CESSION_SUM_SUCCESS,
  OPEN_CERTIFICATE_CONFIRMATION_DIALOG,
  CLOSE_CERTIFICATE_CONFIRMATION_DIALOG,
  OFFER_CSSD_CESSION_CERTIFICATE_REQUEST,
  OFFER_CSSD_CESSION_CERTIFICATE_SUCCESS,
  OFFER_CSSD_CESSION_CERTIFICATE_FAILURE,
  OPEN_CERTIFICATE_ACCEPT_DIALOG,
  CLOSE_CERTIFICATE_ACCEPT_DIALOG,
  ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST,
  ACCEPT_CSSD_CESSION_CERTIFICATE_SUCCESS,
  ACCEPT_CSSD_CESSION_CERTIFICATE_FAILURE,
  OPEN_CONFIRM_CANCEL_CESSION_DIALOG,
  CLOSE_CONFIRM_CANCEL_CESSION_DIALOG,
  CANCEL_CSSD_CESSION_REQUEST,
  CANCEL_CSSD_CESSION_REQUEST_SUCCESS,
  CANCEL_CSSD_CESSION_REQUEST_FAILURE,
  REJECT_CSSD_CESSION_REQUEST,
  REJECT_CSSD_CESSION_REQUEST_SUCCESS,
  REJECT_CSSD_CESSION_REQUEST_FAILURE,
  NOTIFY_CSSD_CESSION_REQUEST,
  NOTIFY_CSSD_CESSION_SUCCESS,
  NOTIFY_CSSD_CESSION_FAILURE,
  OPEN_CONFIRM_NOTIFY_CESSION_DIALOG,
  CLOSE_CONFIRM_NOTIFY_CESSION_DIALOG,
  OPEN_ACCEPT_FOR_EXT_DIALOG,
  CLOSE_ACCEPT_FOR_EXT_DIALOG,
  ACCEPT_EXT_CESSION_REQUEST,
  ACCEPT_EXT_CESSION_SUCCESS,
  ACCEPT_EXT_CESSION_FAILURE,
  REQUEST_CHANGE_EXTERNAL_CESSION_FAILURE,
  REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST,
  REQUEST_CHANGE_EXTERNAL_CESSION_SUCCESS,
  UPLOAD_CESSION_CERT_OPEN,
  UPLOAD_CESSION_CERT_SUCCESS,
  UPLOAD_CESSION_CERT_FAILURE,
  UPLOAD_CESSION_CERT_REQUEST,
  UPLOAD_CESSION_CERT_CLOSE,
  FETCH_CSSD_CESSION_REQUEST,
  FETCH_CSSD_CESSION_FAILURE,
  FETCH_CSSD_CESSIONREQUEST_FAILURE,
  FETCH_CSSD_CESSIONREQUEST_REQUEST,
  OPEN_CONFIRM_PAYMENT_FOR_REBUY_DIALOG,
  CLOSE_CONFIRM_PAYMENT_FOR_REBUY_DIALOG,
  PAYMENT_RECEIVED_FOR_REBUY_REQUEST,
  PAYMENT_RECEIVED_FOR_REBUY_SUCCESS,
  PAYMENT_RECEIVED_FOR_REBUY_FAILURE,
  REDOCUMENT_CESSION_REQUEST,
  REDOCUMENT_CESSION_FAILURE,
  REDOCUMENT_CESSION_SUCCESS,
  CONFIRM_REDOCUMENT_CESSION_REQUEST,
  CONFIRM_REDOCUMENT_CESSION_FAILURE,
  CONFIRM_REDOCUMENT_CESSION_SUCCESS,
  OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG,
  CLOSE_REDOCUMENTED_REBUY_REGISTRATION_DIALOG,
  FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST,
  FETCH_CSSD_CESSIONS_FOR_WORK_SUCCESS,
  FETCH_CSSD_CESSIONS_FOR_WORK_FAILURE,
  OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST,
  OPEN_CESSION_OVERVIEW_FROM_HOME_SUCCESS,
  OPEN_CESSION_OVERVIEW_FROM_HOME_FAILURE,
  FETCH_CSSD_CESSIONS_FOR_WORK_SET_TOTAL,
  FETCH_CSSD_CESSIONS_FOR_WORK_RETRY,
  FETCH_CSSD_CESSIONS_FOR_WORK_DESCREASE_TOTAL,
  CESSION_SET_CSSD,
  UPDATE_CSSD_CESSIONS_FOR_WORK_SUCCESS,
  UPDATE_CSSD_CESSIONS_FOR_WORK_FAILURE,
  UPDATE_CSSD_CESSIONS_FOR_WORK_REMOVE,
  UPDATE_CSSD_CESSIONS_FOR_WORK_UPDATE_STATUS,
} from "./actions";

import { UPDATE_CSSD_CESSION_FOUREYES_EVENT } from "redux/cssdEvents/actions";
import { getIdProxyAddr } from "services/web3Services/commons";
import { onlyUnique } from "util/convertor";

const initialState = {
  partialClaim: null,
  cessions: [],
  sortCriteria: {
    column: "GESCHAEFTSNUMMER",
    isDescending: true,
  },
  filters: {},
  pagingData: {
    pageSize: 10,
    pageNumber: 0,
    amount: 0,
  },
  cssdRef: null,
  loadCessionsPending: false,
  loadCessionsFailed: false,
  loadCessionsRequestsPending: false,
  confirmCessionPending: false,
  editCession: null,
  paymentConfirmedCession: null,
  paymentConfirmedPending: false,
  certificateConfirmation: false,
  offerCertificatePending: false,
  acceptCertificatePending: false,
  certificateAccept: null,
  detailAbtretung: null,
  detailCession: null,
  reviewCessionRequestModal: null,
  createCessionPending: false,
  updateCessionPending: false,
  acceptCessionPending: false,
  cancelCessionPending: false,
  saveAndConfirmCessionPending: false,
  sumNominal: 0,
  cessionRequests: [],
  cessionsForWork: [],
  numTotal: 0,
  loadErrors: {},
  loadingCessionOverview: false,
  confirmCancelCessionDialog: null,
  partialClaimRef: null,
  notifyCessionPending: false,
  extCessionForAccept: null,
  acceptCessionExtPending: false,
  requestChangesCessionExternalPending: false,
  extCessionForCertUpload: null,
  certUploadPending: false,
  showUploadDialog: false,
  partialClaimForConfirmingRebuyPayment: null,
  confirmPaymentForRebuyPending: false,
  externRebuyRegistrationDialogRef: null,
};

function setCssdRef(state, action) {
  return {
    ...state,
    cssdRef: action.cssd,
  };
}

function openCessionOverview(state, action) {
  return {
    ...state,
    cssdRef: action.cssd,
    cessions: initialState.cessions,
    sumNominal: initialState.sumNominal,
  };
}

function openCessionOverviewFromHomeRequestReducer(state) {
  return {
    ...state,
    loadingCessionOverview: true,
  };
}

function openCessionOverviewFromHomeSuccessReducer(state) {
  return {
    ...state,
    loadingCessionOverview: false,
  };
}

function openCessionOverviewFromHomeFailureReducer(state) {
  return {
    ...state,
    loadingCessionOverview: false,
  };
}

function setPartialClaim(state, action) {
  return {
    ...state,
    partialClaim: action.partialClaim,
  };
}

function editCessionReducer(state, action) {
  return {
    ...state,
    editCession: action.cession,
  };
}

function detailCessionReducer(state, action) {
  return {
    ...state,
    detailCession: action.cession,
  };
}

function reviewCessionRequestModal(state, action) {
  return {
    ...state,
    reviewCessionRequestModal: action.cession,
  };
}

function closeModals(state, action) {
  return {
    ...state,
    editCession: null,
    detailCession: null,
    reviewCessionRequestModal: null,
  };
}

function createCessionRequestReducer(state) {
  return {
    ...state,
    createCessionPending: true,
  };
}

function createCessionSuccessReducer(state) {
  return {
    ...state,
    createCessionPending: false,
    editCession: null,
  };
}
function createCessionFailureReducer(state) {
  return {
    ...state,
    createCessionPending: false,
  };
}

function acceptCssdCessionRequestReducer(state) {
  return {
    ...state,
    acceptCessionPending: true,
  };
}

function acceptCssdCessionSuccessReducer(state) {
  return {
    ...state,
    acceptCessionPending: false,
  };
}
function acceptCssdCessionFailureReducer(state) {
  return {
    ...state,
    acceptCessionPending: false,
  };
}

function openPaymentConfirmed(state, action) {
  return {
    ...state,
    paymentConfirmedCession: action.cession,
  };
}

function closePaymentConfirmed(state) {
  return {
    ...state,
    paymentConfirmedCession: null,
  };
}

function openConfirmPaymentForRebuyDialog(state, action) {
  return {
    ...state,
    partialClaimForConfirmingRebuyPayment: action.partialClaim,
  };
}

function closeConfirmPaymentForRebuyDialog(state) {
  return {
    ...state,
    partialClaimForConfirmingRebuyPayment: null,
  };
}

function confirmPaymentForRebuyRequestReducer(state) {
  return {
    ...state,
    confirmPaymentForRebuyPending: true,
  };
}

function confirmPaymentForRebuySuccessReducer(state) {
  return {
    ...state,
    confirmPaymentForRebuyPending: false,
  };
}

function confirmPaymentForRebuyFailureReducer(state) {
  return {
    ...state,
    confirmPaymentForRebuyPending: false,
  };
}

function paymentConfirmedRequest(state) {
  return {
    ...state,
    paymentConfirmedPending: true,
  };
}

function paymentConfirmedSuccess(state) {
  return {
    ...state,
    paymentConfirmedPending: false,
  };
}
function paymentConfirmedFailure(state) {
  return {
    ...state,
    paymentConfirmedPending: false,
  };
}

function openCertificateConfirmationReducer(state, action) {
  return {
    ...state,
    certificateConfirmation: action.cession,
  };
}

function closeCertificateConfirmationReducer(state) {
  return {
    ...state,
    certificateConfirmation: null,
  };
}

function openCertificateAcceptReducer(state, action) {
  return {
    ...state,
    certificateAccept: action.cession,
  };
}

function closeCertificateAcceptReducer(state) {
  return {
    ...state,
    certificateAccept: null,
  };
}

function offerCertificateRequestReducer(state) {
  return {
    ...state,
    offerCertificatePending: true,
  };
}

function offerCertificateSuccessReducer(state) {
  return {
    ...state,
    offerCertificatePending: false,
  };
}

function offerCertificateFailureReducer(state) {
  return {
    ...state,
    offerCertificatePending: false,
  };
}

function acceptCertificateRequestReducer(state) {
  return {
    ...state,
    acceptCertificatePending: true,
  };
}

function acceptCertificateSuccessReducer(state) {
  return {
    ...state,
    acceptCertificatePending: false,
  };
}

function acceptCertificateFailureReducer(state) {
  return {
    ...state,
    acceptCertificatePending: false,
  };
}

function fetchCessionsReducer(state) {
  return {
    ...state,
    loadCessionsPending: true,
    loadCessionsFailed: false,
  };
}

function fetchCessionsSuccessReducer(state, action) {
  return {
    ...state,
    cessions: action.abtretungResponses,
    pagingData: {
      ...state.pagingData,
      amount: action.amount,
    },
    loadCessionsPending: false,
    loadCessionsFailed: false,
  };
}
function fetchCessionsFailureReducer(state) {
  return {
    ...state,
    loadCessionsPending: false,
    loadCessionsFailed: true,
  };
}

function updateCessionRequestReducer(state) {
  return {
    ...state,
    updateCessionPending: true,
  };
}

function updateCessionSuccessReducer(state) {
  return {
    ...state,
    updateCessionPending: false,
  };
}
function updateCessionFailureReducer(state) {
  return {
    ...state,
    updateCessionPending: false,
  };
}

function updateNewDgDetailsRequest(state) {
  return {
    ...state,
    updateCessionPending: true,
  };
}

function updateNewDgDetailsSuccess(state) {
  return {
    ...state,
    updateCessionPending: false,
  };
}
function updateNewDgDetailsFailure(state) {
  return {
    ...state,
    updateCessionPending: false,
  };
}

function saveAndConfirmCessionRequestReducer(state) {
  return {
    ...state,
    saveAndConfirmCessionPending: true,
  };
}

function saveAndConfirmCessionSuccessReducer(state) {
  return {
    ...state,
    saveAndConfirmCessionPending: false,
  };
}
function saveAndConfirmCessionFailureReducer(state) {
  return {
    ...state,
    saveAndConfirmCessionPending: false,
  };
}

function bulkCessionRequestReducer(state) {
  return {
    ...state,
    bulkActionPending: true,
  };
}

function bulkCessionSuccessReducer(state) {
  return {
    ...state,
    bulkActionPending: false,
  };
}

function bulkCessionFailureReducer(state) {
  return {
    ...state,
    bulkActionPending: false,
  };
}

function fetchCssdCessionRequestRequestReducer(state) {
  return {
    ...state,
    loadCessionsRequestsPending: true,
  };
}

function fetchCssdCessionRequestSuccessReducer(state, action) {
  return {
    ...state,
    cessionRequests: action.cessionRequests,
    loadCessionsRequestsPending: false,
  };
}

function fetchCssdCessionRequestFailureReducer(state, action) {
  return {
    ...state,
    loadCessionsRequestsPending: false,
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? [...state.loadErrors[action.payingAgent], ...action.ids].filter(onlyUnique)
          : [...action.ids],
    },
  };
}

function fetchCssdCessionsForWorkRequestReducer(state) {
  return {
    ...state,
    cessionsForWork: [],
    numTotal: 0,
    loadErrors: {},
  };
}

function fetchCssdCessionsForWorkSuccessReducer(state, action) {
  return {
    ...state,
    cessionsForWork: action.cessions.filter(
      (newCession) =>
        !state.cessionsForWork
          .map((storedCession) => storedCession.abtretungId)
          .includes(newCession.abtretungId)
    ),
  };
}

function fetchCssdCessionsForWorkFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? [...state.loadErrors[action.payingAgent], ...action.cessions].filter(onlyUnique)
          : [...action.cessions],
    },
  };
}

function fetchCssdCessionsForWorkSetTotalReducer(state, action) {
  return {
    ...state,
    numTotal: action.numTotal,
  };
}

function fetchCssdCessionsForWorkDecreaseTotalReducer(state, action) {
  return {
    ...state,
    numTotal: state.numTotal - action.numFiltered,
  };
}

function fetchCssdCessionsForWorkRetryReducer(state, action) {
  return {
    ...state,
    loadErrors: action.arranger ? { ...state.loadErrors, [action.arranger]: [] } : {},
  };
}

function updateCssdCessionsForWorkSuccessReducer(state, action) {
  return {
    ...state,
    cessionsForWork: [...state.cessionsForWork, action.cession],
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? state.loadErrors[action.payingAgent].filter(
              (cession) => cession.abtretungId !== action.cession.abtretungId
            )
          : [],
    },
    numTotal: state.numTotal + 1,
  };
}

function updateCssdCessionsForWorkFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? [...state.loadErrors[action.payingAgent], ...action.cessions].filter(onlyUnique)
          : [...action.cessions],
    },
    numTotal: state.numTotal + 1,
  };
}

function updateCssdCessionsForWorkUpdateStatusReducer(state, action) {
  return {
    ...state,
    cessionsForWork: [...state.cessionsForWork].map((entry) => {
      if (entry.abtretungId === action.cessionId)
        return {
          ...entry,
          status: action.status,
        };
      return entry;
    }),
  };
}

function updateCssdCessionsForWorkRemoveReducer(state, action) {
  return {
    ...state,
    cessionsForWork: state.cessionsForWork.filter(
      (cession) => cession.abtretungId !== action.cessionId
    ),
    loadErrors: {
      ...state.loadErrors,
      [action.payingAgent]:
        state.loadErrors[action.payingAgent] != null
          ? state.loadErrors[action.payingAgent].filter(
              (cession) => cession.abtretungId !== action.cessionId
            )
          : [],
    },
    numTotal: state.numTotal - 1,
  };
}

function confirmCessionRequestReducer(state) {
  return {
    ...state,
    confirmCessionPending: true,
  };
}

function confirmCessionSuccessReducer(state) {
  return {
    ...state,
    editCession: null,
    detailCession: null,
    confirmCessionPending: false,
  };
}
function confirmCessionFailureReducer(state) {
  return {
    ...state,
    confirmCessionPending: false,
  };
}

const filterCessionTable = (state, action) => ({
  ...state,
  filters: action.filters,
});

const sortCessionTable = (state, action) => {
  const { column } = action;
  const { sortCriteria } = state;
  return {
    ...state,
    sortCriteria: {
      column,
      isDescending: column === sortCriteria.column ? !sortCriteria.isDescending : true,
    },
  };
};

const setPage = (state, action) => ({
  ...state,
  pagingData: {
    ...state.pagingData,
    pageNumber: action.pageNumber,
  },
});

const setPageSize = (state, action) => ({
  ...state,
  pagingData: {
    ...state.pagingData,
    pageSize: action.pageSize,
  },
});

function sumOfAllCessionsReducer(state, action) {
  return {
    ...state,
    sumNominal: action.summe,
  };
}

function updateFourEyesInArr(arr, subid, fourEyes, firstConfirmer) {
  let update = [...arr].map((entry) => {
    if (entry.abtretungId === subid)
      return {
        ...entry,
        fourEyesState: fourEyes,
        firstConfirmer: firstConfirmer,
      };
    return entry;
  });

  return update;
}

function updateCssdCessionFoureyesEventReducer(state, action) {
  const { subid, fourEyes, firstConfirmer } = action;
  const { cessions, cessionRequests, cessionsForWork } = state;
  return {
    ...state,
    cessions: updateFourEyesInArr(cessions, subid, fourEyes, firstConfirmer),
    cessionRequests: updateFourEyesInArr(cessionRequests, subid, fourEyes, firstConfirmer),
    cessionsForWork: updateFourEyesInArr(cessionsForWork, subid, fourEyes, firstConfirmer),
  };
}

function updateCssdCessionInArr(arr, subid, cession, isRequests) {
  let exists = false;
  let update = [...arr].map((entry) => {
    if (entry.abtretungId === subid) {
      exists = true;
      return {
        ...entry,
        ...cession,
      };
    }
    return entry;
  });
  if (!exists && cession) {
    if (!(isRequests && cession.neuerDarlehensgeber.idProxyAdresse !== getIdProxyAddr())) {
      update.push(cession);
    }
  }
  return update;
}

function updateCssdCessionReducer(state, action) {
  const { cession } = action;
  const { cessions, cessionRequests } = state;
  return {
    ...state,
    cessions: updateCssdCessionInArr(cessions, cession?.abtretungId, cession),
    cessionRequests: updateCssdCessionInArr(cessionRequests, cession?.abtretungId, cession, true),
  };
}

function removeCssdCessionReducer(state, action) {
  const { id } = action;
  const { cessions } = state;
  return {
    ...state,
    cessions: cessions.filter((entry) => {
      return entry.abtretungId !== id;
    }),
  };
}

function removeCssdCessionRequestReducer(state, action) {
  const { id } = action;
  const { cessionRequests } = state;
  return {
    ...state,
    cessionRequests: cessionRequests.filter((entry) => {
      return entry.abtretungId !== id;
    }),
  };
}

function openConfirmCancelCessionDialog(state, action) {
  return {
    ...state,
    confirmCancelCessionDialog: action.cession,
  };
}

function closeConfirmCancelCessionDialog(state) {
  return {
    ...state,
    confirmCancelCessionDialog: null,
  };
}

function cancelOrRejectCessionRequestReducer(state, action) {
  return {
    ...state,
    cancelCessionPending: true,
  };
}

function cancelOrRejectCessionSuccessReducer(state, action) {
  return {
    ...state,
    cancelCessionPending: false,
    confirmCancelCessionDialog: null,
    cessionRequests: findAndRemoveCessionRequest(state.cessionRequests, action?.rejectedCession),
  };
}

function findAndRemoveCessionRequest(cessionRequests, rejectedCession) {
  return rejectedCession
    ? cessionRequests.filter((request) => request.abtretungId !== rejectedCession.abtretungId)
    : cessionRequests;
}

function cancelOrRejectCessionFailureReducer(state) {
  return {
    ...state,
    cancelCessionPending: false,
  };
}

function notifyCessionRequestReducer(state) {
  return {
    ...state,
    notifyCessionPending: true,
  };
}

function notifyCessionSuccessReducer(state) {
  return {
    ...state,
    notifyCessionPending: false,
  };
}
function notifyCessionFailureReducer(state) {
  return {
    ...state,
    notifyCessionPending: false,
  };
}

function openConfirmNotifyCessionDialog(state, action) {
  return {
    ...state,
    partialClaimRef: action.partialClaim,
  };
}

function closeConfirmNotifyCessionDialog(state) {
  return {
    ...state,
    partialClaimRef: null,
  };
}

function openAcceptForExtDialogReducer(state, action) {
  return {
    ...state,
    extCessionForAccept: action.cession,
  };
}

function closeAcceptForExtDialogReducer(state) {
  return {
    ...state,
    extCessionForAccept: null,
  };
}

function acceptExtCessionRequestSagas(state) {
  return {
    ...state,
    acceptCessionExtPending: true,
  };
}

function acceptExtCessionSuccessSagas(state) {
  return {
    ...state,
    acceptCessionExtPending: false,
    extCessionForAccept: null,
  };
}
function acceptExtCessionFailureSagas(state) {
  return {
    ...state,
    acceptCessionExtPending: false,
  };
}

function requestChangeExternalCessionRequest(state) {
  return {
    ...state,
    requestChangesCessionExternalPending: true,
  };
}

function requestChangeExternalCessionSuccess(state) {
  return {
    ...state,
    requestChangesCessionExternalPending: false,
    extCessionForAccept: null,
  };
}
function requestChangeExternalCessionFailure(state) {
  return {
    ...state,
    requestChangesCessionExternalPending: false,
  };
}

function uploadCessionCertSuccessReducer(state) {
  return {
    ...state,
    certUploadPending: false,
    showUploadDialog: false,
    extCessionForCertUpload: null,
  };
}
const openUploadCertDialog = (state, action) => ({
  ...state,
  showUploadDialog: true,
  extCessionForCertUpload: action.cession,
});

const closeUploadCertDialog = (state) => ({
  ...state,
  showUploadDialog: false,
  extCessionForCertUpload: null,
});

const setUploadCertPending = (state, isPending) => ({
  ...state,
  certUploadPending: isPending,
});

const openRedocumentedRebuyRegistrationDialog = (state, action) => ({
  ...state,
  externRebuyRegistrationDialogRef: action.data,
});

const closeRedocumentedRebuyRegistrationDialog = (state) => ({
  ...state,
  externRebuyRegistrationDialogRef: null,
  sumNominal: 0,
});

const redocumentCessionSuccess = (state) => ({
  ...state,
  saveAndConfirmCessionPending: false,
  editCession: null,
});

export function cssdCessionReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CSSD_CESSION_OVERVIEW:
      return openCessionOverview(state, action);
    case OPEN_CESSION_OVERVIEW_FROM_HOME_REQUEST:
      return openCessionOverviewFromHomeRequestReducer(state, action);
    case OPEN_CESSION_OVERVIEW_FROM_HOME_SUCCESS:
      return openCessionOverviewFromHomeSuccessReducer(state, action);
    case OPEN_CESSION_OVERVIEW_FROM_HOME_FAILURE:
      return openCessionOverviewFromHomeFailureReducer(state, action);
    case CESSION_SET_CSSD_PARTIAL_CLAIM:
      return setPartialClaim(state, action);
    case CESSION_SET_CSSD:
      return setCssdRef(state, action);
    case EDIT_CSSD_CESSION:
      return editCessionReducer(state, action);
    case REVIEW_CSSD_CESSION_REQUEST:
      return reviewCessionRequestModal(state, action);
    case DETAIL_CSSD_CESSION:
      return detailCessionReducer(state, action);
    case OPEN_PAYMENT_CONFIRMED_DIALOG:
      return openPaymentConfirmed(state, action);
    case CSSD_PAYMENT_CONFIRMED_REQUEST:
      return paymentConfirmedRequest(state, action);
    case CSSD_PAYMENT_CONFIRMED_SUCCESS:
      return paymentConfirmedSuccess(state, action);
    case CSSD_PAYMENT_CONFIRMED_FAILURE:
      return paymentConfirmedFailure(state, action);
    case CLOSE_PAYMENT_CONFIRMED_DIALOG:
      return closePaymentConfirmed(state, action);
    case OPEN_CERTIFICATE_CONFIRMATION_DIALOG:
      return openCertificateConfirmationReducer(state, action);
    case CLOSE_CERTIFICATE_CONFIRMATION_DIALOG:
      return closeCertificateConfirmationReducer(state);
    case OPEN_CERTIFICATE_ACCEPT_DIALOG:
      return openCertificateAcceptReducer(state, action);
    case CLOSE_CERTIFICATE_ACCEPT_DIALOG:
      return closeCertificateAcceptReducer(state);
    case OFFER_CSSD_CESSION_CERTIFICATE_REQUEST:
      return offerCertificateRequestReducer(state);
    case OFFER_CSSD_CESSION_CERTIFICATE_SUCCESS:
      return offerCertificateSuccessReducer(state);
    case OFFER_CSSD_CESSION_CERTIFICATE_FAILURE:
      return offerCertificateFailureReducer(state);
    case ACCEPT_CSSD_CESSION_CERTIFICATE_REQUEST:
      return acceptCertificateRequestReducer(state);
    case ACCEPT_CSSD_CESSION_CERTIFICATE_SUCCESS:
      return acceptCertificateSuccessReducer(state);
    case ACCEPT_CSSD_CESSION_CERTIFICATE_FAILURE:
      return acceptCertificateFailureReducer(state);
    case CLOSE_CSSD_CESSION_MODALS:
      return closeModals(state, action);
    case CREATE_CSSD_CESSION_REQUEST:
      return createCessionRequestReducer(state);
    case CREATE_CSSD_CESSION_SUCCESS:
      return createCessionSuccessReducer(state);
    case CREATE_CSSD_CESSION_FAILURE:
      return createCessionFailureReducer(state);
    case CANCEL_CSSD_CESSION_REQUEST:
      return cancelOrRejectCessionRequestReducer(state);
    case CANCEL_CSSD_CESSION_REQUEST_SUCCESS:
      return cancelOrRejectCessionSuccessReducer(state);
    case CANCEL_CSSD_CESSION_REQUEST_FAILURE:
      return cancelOrRejectCessionFailureReducer(state);
    case REJECT_CSSD_CESSION_REQUEST:
      return cancelOrRejectCessionRequestReducer(state);
    case REJECT_CSSD_CESSION_REQUEST_SUCCESS:
      return cancelOrRejectCessionSuccessReducer(state, action);
    case REJECT_CSSD_CESSION_REQUEST_FAILURE:
      return cancelOrRejectCessionFailureReducer(state);
    case UPDATE_CSSD_CESSION_REQUEST:
      return updateCessionRequestReducer(state);
    case UPDATE_CSSD_CESSION_SUCCESS:
      return updateCessionSuccessReducer(state);
    case UPDATE_CSSD_CESSION_FAILURE:
      return updateCessionFailureReducer(state);
    case UPDATE_NEW_DG_DETAILS_REQUEST:
      return updateNewDgDetailsRequest(state);
    case UPDATE_NEW_DG_DETAILS_SUCCESS:
      return updateNewDgDetailsSuccess(state);
    case UPDATE_NEW_DG_DETAILS_FAILURE:
      return updateNewDgDetailsFailure(state);
    case FETCH_CSSD_CESSION_REQUEST:
      return fetchCessionsReducer(state, action);
    case FETCH_CSSD_CESSION_SUCCESS:
      return fetchCessionsSuccessReducer(state, action);
    case FETCH_CSSD_CESSION_FAILURE:
      return fetchCessionsFailureReducer(state, action);
    case FETCH_CSSD_CESSIONREQUEST_REQUEST:
      return fetchCssdCessionRequestRequestReducer(state, action);
    case FETCH_CSSD_CESSIONREQUEST_SUCCESS:
      return fetchCssdCessionRequestSuccessReducer(state, action);
    case FETCH_CSSD_CESSIONREQUEST_FAILURE:
      return fetchCssdCessionRequestFailureReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_REQUEST:
      return fetchCssdCessionsForWorkRequestReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_SUCCESS:
      return fetchCssdCessionsForWorkSuccessReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_FAILURE:
      return fetchCssdCessionsForWorkFailureReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_SET_TOTAL:
      return fetchCssdCessionsForWorkSetTotalReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_DESCREASE_TOTAL:
      return fetchCssdCessionsForWorkDecreaseTotalReducer(state, action);
    case FETCH_CSSD_CESSIONS_FOR_WORK_RETRY:
      return fetchCssdCessionsForWorkRetryReducer(state, action);
    case UPDATE_CSSD_CESSIONS_FOR_WORK_SUCCESS:
      return updateCssdCessionsForWorkSuccessReducer(state, action);
    case UPDATE_CSSD_CESSIONS_FOR_WORK_FAILURE:
      return updateCssdCessionsForWorkFailureReducer(state, action);
    case UPDATE_CSSD_CESSIONS_FOR_WORK_REMOVE:
      return updateCssdCessionsForWorkRemoveReducer(state, action);
    case UPDATE_CSSD_CESSIONS_FOR_WORK_UPDATE_STATUS:
      return updateCssdCessionsForWorkUpdateStatusReducer(state, action);
    case CONFIRM_CSSD_CESSION_REQUEST:
    case CONFIRM_REDOCUMENT_CESSION_REQUEST:
      return confirmCessionRequestReducer(state, action);
    case CONFIRM_CSSD_CESSION_SUCCESS:
    case CONFIRM_REDOCUMENT_CESSION_SUCCESS:
      return confirmCessionSuccessReducer(state, action);
    case CONFIRM_CSSD_CESSION_FAILURE:
    case CONFIRM_REDOCUMENT_CESSION_FAILURE:
      return confirmCessionFailureReducer(state, action);
    case REDOCUMENT_CESSION_REQUEST:
    case SAVE_AND_CONFIRM_CESSION_REQUEST:
      return saveAndConfirmCessionRequestReducer(state, action);
    case REDOCUMENT_CESSION_SUCCESS:
      return redocumentCessionSuccess(state, action);
    case SAVE_AND_CONFIRM_CESSION_SUCCESS:
      return saveAndConfirmCessionSuccessReducer(state, action);
    case REDOCUMENT_CESSION_FAILURE:
    case SAVE_AND_CONFIRM_CESSION_FAILURE:
      return saveAndConfirmCessionFailureReducer(state, action);
    case UPDATE_AND_ACCEPT_NEW_DG_DETAILS_REQUEST:
      return saveAndConfirmCessionRequestReducer(state, action);
    case UPDATE_AND_ACCEPT_NEW_DG_DETAILS_SUCCESS:
      return saveAndConfirmCessionSuccessReducer(state, action);
    case UPDATE_AND_ACCEPT_NEW_DG_DETAILS_FAILURE:
      return saveAndConfirmCessionFailureReducer(state, action);
    case ACCEPT_CSSD_CESSION_REQUEST:
      return acceptCssdCessionRequestReducer(state);
    case ACCEPT_CSSD_CESSION_SUCCESS:
      return acceptCssdCessionSuccessReducer(state);
    case ACCEPT_CSSD_CESSION_FAILURE:
      return acceptCssdCessionFailureReducer(state);
    case BULK_CSSD_CESSION_REQUEST:
      return bulkCessionRequestReducer(state);
    case BULK_CSSD_CESSION_SUCCESS:
      return bulkCessionSuccessReducer(state);
    case BULK_CSSD_CESSION_FAILURE:
      return bulkCessionFailureReducer(state);
    case BULK_CSSD_CESSION_NOTIFY_REQUEST:
      return bulkCessionRequestReducer(state);
    case BULK_CSSD_CESSION_NOTIFY_SUCCESS:
      return bulkCessionSuccessReducer(state);
    case BULK_CSSD_CESSION_NOTIFY_FAILURE:
      return bulkCessionFailureReducer(state);
    case FILTER_CSSD_CESSION_TABLE:
      return filterCessionTable(state, action);
    case SORT_CSSD_CESSION_TABLE:
      return sortCessionTable(state, action);
    case SET_PAGE_CSSD_CESSION_TABLE:
      return setPage(state, action);
    case SET_PAGE_SIZE_CSSD_CESSION_TABLE:
      return setPageSize(state, action);
    case FETCH_CSSD_CESSION_SUM_SUCCESS:
      return sumOfAllCessionsReducer(state, action);
    case UPDATE_CSSD_CESSION_FOUREYES_EVENT:
      return updateCssdCessionFoureyesEventReducer(state, action);
    case UPDATE_CSSD_CESSION:
      return updateCssdCessionReducer(state, action);
    case REMOVE_CSSD_CESSION:
      return removeCssdCessionReducer(state, action);
    case REMOVE_CSSD_CESSION_REQUEST:
      return removeCssdCessionRequestReducer(state, action);
    case OPEN_CONFIRM_CANCEL_CESSION_DIALOG:
      return openConfirmCancelCessionDialog(state, action);
    case CLOSE_CONFIRM_CANCEL_CESSION_DIALOG:
      return closeConfirmCancelCessionDialog(state);
    case NOTIFY_CSSD_CESSION_REQUEST:
      return notifyCessionRequestReducer(state);
    case NOTIFY_CSSD_CESSION_SUCCESS:
      return notifyCessionSuccessReducer(state);
    case NOTIFY_CSSD_CESSION_FAILURE:
      return notifyCessionFailureReducer(state);
    case OPEN_CONFIRM_NOTIFY_CESSION_DIALOG:
      return openConfirmNotifyCessionDialog(state, action);
    case CLOSE_CONFIRM_NOTIFY_CESSION_DIALOG:
      return closeConfirmNotifyCessionDialog(state);
    case OPEN_ACCEPT_FOR_EXT_DIALOG:
      return openAcceptForExtDialogReducer(state, action);
    case CLOSE_ACCEPT_FOR_EXT_DIALOG:
      return closeAcceptForExtDialogReducer(state);
    case ACCEPT_EXT_CESSION_REQUEST:
      return acceptExtCessionRequestSagas(state);
    case ACCEPT_EXT_CESSION_SUCCESS:
      return acceptExtCessionSuccessSagas(state);
    case ACCEPT_EXT_CESSION_FAILURE:
      return acceptExtCessionFailureSagas(state);
    case REQUEST_CHANGE_EXTERNAL_CESSION_REQUEST:
      return requestChangeExternalCessionRequest(state);
    case REQUEST_CHANGE_EXTERNAL_CESSION_SUCCESS:
      return requestChangeExternalCessionSuccess(state);
    case REQUEST_CHANGE_EXTERNAL_CESSION_FAILURE:
      return requestChangeExternalCessionFailure(state);
    case UPLOAD_CESSION_CERT_OPEN:
      return openUploadCertDialog(state, action);
    case UPLOAD_CESSION_CERT_REQUEST:
      return setUploadCertPending(state, true);
    case UPLOAD_CESSION_CERT_SUCCESS:
      return uploadCessionCertSuccessReducer(state);
    case UPLOAD_CESSION_CERT_CLOSE:
      return closeUploadCertDialog(state);
    case UPLOAD_CESSION_CERT_FAILURE:
      return setUploadCertPending(state, false);

    case OPEN_CONFIRM_PAYMENT_FOR_REBUY_DIALOG:
      return openConfirmPaymentForRebuyDialog(state, action);
    case CLOSE_CONFIRM_PAYMENT_FOR_REBUY_DIALOG:
      return closeConfirmPaymentForRebuyDialog(state);

    case PAYMENT_RECEIVED_FOR_REBUY_REQUEST:
      return confirmPaymentForRebuyRequestReducer(state);
    case PAYMENT_RECEIVED_FOR_REBUY_SUCCESS:
      return confirmPaymentForRebuySuccessReducer(state);
    case PAYMENT_RECEIVED_FOR_REBUY_FAILURE:
      return confirmPaymentForRebuyFailureReducer(state);

    case OPEN_REDOCUMENTED_REBUY_REGISTRATION_DIALOG:
      return openRedocumentedRebuyRegistrationDialog(state, action);
    case CLOSE_REDOCUMENTED_REBUY_REGISTRATION_DIALOG:
      return closeRedocumentedRebuyRegistrationDialog(state);

    default:
      return state;
  }
}
