import {
  OPEN_CSSD_HISTORY,
  FETCH_CSSD_HISTORY_REQUEST,
  FETCH_CSSD_HISTORY_FAILURE,
  FETCH_CSSD_HISTORY_SUCCESS,
  OPEN_PARTIALCLAIM_CESSION_HISTORY,
  FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST,
  FETCH_PARTIALCLAIM_CESSION_HISTORY_FAILURE,
  FETCH_PARTIALCLAIM_CESSION_HISTORY_SUCCESS,
  OPEN_INTEREST_NOTIFICATION_HISTORY,
  FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST,
  FETCH_INTEREST_NOTIFICATION_HISTORY_FAILURE,
  FETCH_INTEREST_NOTIFICATION_HISTORY_SUCCESS,
} from "./actions";

// reducer with initial state
const initialState = {
  cssd: {},
  partialClaim: {},
  cession: {},
  interestNotification: {},
  auditFetchPending: false,
  cssdAuditLog: [],
  cessionPartialClaimAuditLog: [],
  interestNotificationAuditLog: [],
};

function openCssdHistoryReducer(state, action) {
  let { cssd } = action;
  return {
    ...state,
    cssd,
    cssdAuditLog: [],
  };
}

function fetchCssdHistoryRequestReducer(state) {
  return {
    ...state,
    auditFetchPending: true,
  };
}

function fetchCssdHistoryFailureReducer(state) {
  return {
    ...state,
    auditFetchPending: false,
  };
}

function fetchCssdHistorySuccessReducer(state, action) {
  const { auditLog } = action;
  return {
    ...state,
    auditFetchPending: false,
    cssdAuditLog: auditLog,
  };
}

function openPartialClaimCessionHistoryReducer(state, action) {
  let { partialClaim, cession } = action;
  return {
    ...state,
    partialClaim,
    cession,
    cessionPartialClaimAuditLog: [],
  };
}

function fetchPartialClaimCessionHistoryRequestReducer(state) {
  return {
    ...state,
    auditFetchPending: true,
  };
}

function fetchPartialClaimCessionHistoryFailureReducer(state) {
  return {
    ...state,
    auditFetchPending: false,
  };
}

function fetchPartialClaimCessionHistorySuccessReducer(state, action) {
  const { auditLog } = action;
  return {
    ...state,
    auditFetchPending: false,
    cessionPartialClaimAuditLog: auditLog,
  };
}

function openInterestNotificationHistoryReducer(state, action) {
  let { interestNotification } = action;
  return {
    ...state,
    interestNotification,
    interestNotificationAuditLog: [],
  };
}

function fetchTerminationHistoryRequestReducer(state) {
  return {
    ...state,
    auditFetchPending: true,
  };
}

function fetchTerminationHistoryFailureReducer(state) {
  return {
    ...state,
    auditFetchPending: false,
  };
}

function fetchTerminationHistorySuccessReducer(state, action) {
  const { auditLog } = action;
  return {
    ...state,
    auditFetchPending: false,
    interestNotificationAuditLog: auditLog,
  };
}

export function cssdAuditReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CSSD_HISTORY:
      return openCssdHistoryReducer(state, action);
    case FETCH_CSSD_HISTORY_REQUEST:
      return fetchCssdHistoryRequestReducer(state);
    case FETCH_CSSD_HISTORY_FAILURE:
      return fetchCssdHistoryFailureReducer(state);
    case FETCH_CSSD_HISTORY_SUCCESS:
      return fetchCssdHistorySuccessReducer(state, action);

    case OPEN_PARTIALCLAIM_CESSION_HISTORY:
      return openPartialClaimCessionHistoryReducer(state, action);
    case FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST:
      return fetchPartialClaimCessionHistoryRequestReducer(state);
    case FETCH_PARTIALCLAIM_CESSION_HISTORY_FAILURE:
      return fetchPartialClaimCessionHistoryFailureReducer(state);
    case FETCH_PARTIALCLAIM_CESSION_HISTORY_SUCCESS:
      return fetchPartialClaimCessionHistorySuccessReducer(state, action);

    case OPEN_INTEREST_NOTIFICATION_HISTORY:
      return openInterestNotificationHistoryReducer(state, action);
    case FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST:
      return fetchTerminationHistoryRequestReducer(state);
    case FETCH_INTEREST_NOTIFICATION_HISTORY_FAILURE:
      return fetchTerminationHistoryFailureReducer(state);
    case FETCH_INTEREST_NOTIFICATION_HISTORY_SUCCESS:
      return fetchTerminationHistorySuccessReducer(state, action);
    default:
      return state;
  }
}
