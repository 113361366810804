import { takeLatest, put, call, spawn } from "redux-saga/effects";
import {
  SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST,
  cssdTerminationActions,
  cssdTerminationDialogActions,
  CONFIRM_CSSD_TERMINATION_REQUEST,
  CANCEL_CSSD_TERMINATION_REQUEST,
  partialClaimTerminationActions,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST,
  CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST,
  CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST,
  TRY_OPEN_CSSD_TERMINATION_DIALOG,
  CHECK_TERMINATIONS,
  OFFER_REVOKE_REQUEST,
  revokeTerminationActions,
  CANCEL_REVOKE_REQUEST,
  DOWNLOAD_TERMINATION,
  TRY_OPEN_CONFIRM_REVOKE_DIALOG,
  ACCEPT_REVOKE_REQUEST,
  OPEN_CSSD_TERMINATION_DIALOG,
} from "./actions";
import { messageDialogActions, snackbarActions } from "redux/shared/actions";
import { appIntl } from "components/i18n/intl";
import { messages } from "components/Shared/CssdForm/TerminationForm/TerminationMessages";
import {
  convertBooleanAsStringToNumber,
  removeDots,
  dateToTimestamp,
  hashArrayToNumberArray,
} from "util/convertor";
import { generateCssdTerminationFingerprint } from "util/fingerprint";
import { getIdProxyAddr, toHex } from "services/web3Services/commons";
import {
  modifyAndApproveCSSDTermination,
  approveCSSDTermination,
  approveReDocumentedCSSDTermination,
  modifyAndApprovePartialClaimTermination,
  modifyAndApproveReDocumentedPartialClaimTermination,
  approveReDocumentedPartialClaimTermination,
  approvePartialClaimTermination,
  rejectCSSDTermination as rejectCSSDTerminationOnBC,
  rejectPartialClaimTermination as rejectPartialClaimTerminationBC,
  modifyAndOfferRevokePartialClaimTermination,
  cancelRevokePartialClaimTermination,
  offerRevokePartialClaimTermination,
  acceptRevokePartialClaimTermination,
  hasActiveCessions,
  abortCessionsOnTermination,
  getAbortedIds,
  modifyAndApproveReDocumentedCssdTermination,
  rejectReDocumentedCssdTermination,
} from "services/web3Services/cssdTermination";
import {
  storeCssdKuendigung,
  updateCssdKuendigung,
  storeCssdTeilforderungKuendigung,
  updateCssdTeilforderungKuendigung,
  pruefeKuendigungStatus,
  pruefeKuendigungStatusBulk,
  updateCssdKuendigungStatus,
  erstelleKuendigungsPDF,
  downloadKuendigungsPDF,
  deleteDocuments,
  storeNachdokumentierteCssdKuendigung,
} from "services/cssdTermination";
import { CSSDTerminationState, GeschaeftspartnerTyp, zeroAddress, ZERO_HASH } from "util/constants";
import {
  sendCssdTerminationMail,
  sendPartialClaimTerminationMail,
  sendTerminationRevokeMail,
  sendCessionAbortMail,
} from "services/mailService";
import { cssdTerminationActions as checkTerminationsForCssd } from "../cssdEvents/actions";
import { partialClaimTerminationActions as checkTerminationsForPartialClaim } from "../cssdTeilforderungen/actions";
import { queryTeilforderungDetails } from "services/cssdService";

export const cssdTerminationSagas = [
  takeLatest(SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST, saveAndConfirmCssdTermination),
  takeLatest(CONFIRM_CSSD_TERMINATION_REQUEST, confirmCssdTermination),
  takeLatest(CANCEL_CSSD_TERMINATION_REQUEST, cancelCssdTermination),
  takeLatest(
    SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST,
    saveAndConfirmPartialClaimTermination
  ),
  takeLatest(CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST, confirmPartialClaimTermination),
  takeLatest(CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST, cancelPartialClaimTermination),
  takeLatest(TRY_OPEN_CSSD_TERMINATION_DIALOG, checkTerminationState),
  takeLatest(CHECK_TERMINATIONS, checkTerminationStateBulk),
  takeLatest(OFFER_REVOKE_REQUEST, offerTerminationRevoke),
  takeLatest(CANCEL_REVOKE_REQUEST, cancelTerminationRevoke),
  takeLatest(DOWNLOAD_TERMINATION, downloadTermination),
  takeLatest(TRY_OPEN_CONFIRM_REVOKE_DIALOG, checkTerminationState),
  takeLatest(ACCEPT_REVOKE_REQUEST, acceptTerminationRevoke),
  takeLatest(OPEN_CSSD_TERMINATION_DIALOG, openTermination),
];

function buildPostCssdTerminationPayload(termination, geschaeftspartnerTyp, extern) {
  let payload = {
    ...termination,
    extern,
    nachdokumentiert: !!extern,
    kuendigender: termination.kuendigender || geschaeftspartnerTyp,
    rueckzahlungsbetrag: termination?.rueckzahlungsbetrag
      ? Number(removeDots(termination?.rueckzahlungsbetrag))
      : null,
    kurs: termination?.kurs ? Number(removeDots(termination?.kurs)) : null,
  };
  return payload;
}

export function* saveAndConfirmCssdTermination({ account, cssd, termination }) {
  try {
    const terminationWithFP = yield generateCssdTerminationFingerprint(
      buildPostCssdTerminationPayload(
        termination,
        cssd.migriert ? GeschaeftspartnerTyp.ZAHLSTELLE : GeschaeftspartnerTyp.DARLEHENSNEHMER,
        cssd.migriert
      )
    );

    if (cssd.migriert) {
      const { data } = yield call(
        storeNachdokumentierteCssdKuendigung,
        terminationWithFP,
        cssd.cssdId,
        cssd.zahlstelle.idProxyAdresse,
        account.address.toLowerCase()
      );
      yield call(
        modifyAndApproveReDocumentedCssdTermination,
        account,
        cssd.zahlstelle.idProxyAdresse,
        toHex(`${cssd.cssdId}`),
        cssd.fingerprint,
        toHex(`${data.kuendigungId}`),
        terminationWithFP.fingerprint,
        dateToTimestamp(terminationWithFP.valuta)
      );
    } else {
      const { data } = yield call(
        storeCssdKuendigung,
        terminationWithFP,
        cssd.cssdId,
        cssd.zahlstelle.idProxyAdresse,
        account.address.toLowerCase()
      );
      yield call(
        modifyAndApproveCSSDTermination,
        account,
        cssd.zahlstelle.idProxyAdresse,
        toHex(`${cssd.cssdId}`),
        cssd.fingerprint,
        toHex(`${data.kuendigungId}`),
        terminationWithFP.fingerprint,
        convertBooleanAsStringToNumber(termination.ausserordentlicheKuendigung),
        dateToTimestamp(terminationWithFP.valuta)
      );
    }

    yield put(cssdTerminationActions.saveAndConfirmSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_termination_saveAndConfirm_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cssdTerminationActions.saveAndConfirmFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_termination_saveAndConfirm_failure)
      )
    );
  }
}

function* abortCessions(metadata) {
  yield call(abortCessionsOnTermination, metadata);

  const response = yield call(getAbortedIds, metadata);
  const cessionIds = [];
  const zessionarCessionIds = [];

  if (response.partialClaimIds.length > 0) {
    for (let index = 0; index < response.partialClaimIds.length; index++) {
      const result = yield call(abortCessions, {
        ...metadata,
        partialClaimId: response.partialClaimIds[index],
      });
      cessionIds.push(...result.cessionIds);
      zessionarCessionIds.push(...result.zessionarCessionIds);
    }
  }

  return {
    cessionIds: [...cessionIds, ...response.cessionIds],
    zessionarCessionIds: [...zessionarCessionIds, ...response.zessionarCessionIds],
  };
}

export function* confirmCssdTermination({ account, cssd, termination }) {
  try {
    yield call(updateCssdKuendigung, termination, cssd.cssdId, cssd.zahlstelle.idProxyAdresse);

    if (cssd.migriert) {
      yield call(
        approveReDocumentedCSSDTermination,
        account,
        cssd.zahlstelle.idProxyAdresse,
        toHex(`${cssd.cssdId}`),
        cssd.fingerprint,
        toHex(`${termination.kuendigungId}`),
        termination.fingerprint
      );
    } else {
      yield call(
        approveCSSDTermination,
        account,
        cssd.zahlstelle.idProxyAdresse,
        toHex(`${cssd.cssdId}`),
        toHex(`${termination.kuendigungId}`),
        termination.fingerprint
      );

      const { cessionIds, zessionarCessionIds } = yield call(abortCessions, {
        account,
        payingAgent: cssd.zahlstelle.idProxyAdresse,
        cssdId: toHex(`${cssd.cssdId}`),
        partialClaimId: ZERO_HASH,
        terminationTimestamp: dateToTimestamp(termination.valuta),
      });

      const cessionNumericIds = hashArrayToNumberArray(cessionIds);
      const zessionarCessionIdsNumeric = hashArrayToNumberArray(zessionarCessionIds);
      yield spawn(
        sendCessionAbortMail,
        cssd.zahlstelle.idProxyAdresse,
        cssd.cssdId,
        "",
        cessionNumericIds,
        zessionarCessionIdsNumeric
      );

      yield call(deleteDocuments, cssd.zahlstelle.idProxyAdresse, cssd.cssdId, cessionNumericIds);

      yield spawn(sendCssdTerminationMail, {
        arranger: cssd.zahlstelle.idProxyAdresse,
        cssdId: cssd.cssdId,
        kuendigungId: termination.kuendigungId,
      });

      yield call(
        erstelleKuendigungsPDF,
        cssd.cssdId,
        termination.kuendigungId,
        cssd.zahlstelle.idProxyAdresse
      );
    }

    yield put(cssdTerminationActions.confirmSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_termination_confirm_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cssdTerminationActions.confirmFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_termination_confirm_failure))
    );
  }
}

export function* cancelCssdTermination({ account, cssd, termination }) {
  try {
    yield call(
      termination.nachdokumentiert ? rejectReDocumentedCssdTermination : rejectCSSDTerminationOnBC,
      account,
      cssd.zahlstelle.idProxyAdresse,
      toHex(`${cssd.cssdId}`),
      toHex(`${termination.kuendigungId}`),
      termination.fingerprint
    );

    yield call(
      updateCssdKuendigungStatus,
      cssd.cssdId,
      {
        kuendigungId: termination.kuendigungId,
      },
      cssd.zahlstelle.idProxyAdresse
    );

    yield put(cssdTerminationActions.cancelSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_termination_cancel_success))
    );
  } catch (error) {
    console.error(error);
    yield put(cssdTerminationActions.cancelFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_termination_cancel_failure))
    );
  }
}

export function* saveAndConfirmPartialClaimTermination({ account, partialClaim, termination }) {
  try {
    const { extern } = partialClaim;
    const terminationWithFP = yield generateCssdTerminationFingerprint(
      buildPostCssdTerminationPayload(
        termination,
        partialClaim.idProxyAdresseDarlehensnehmer === getIdProxyAddr()
          ? GeschaeftspartnerTyp.DARLEHENSNEHMER
          : GeschaeftspartnerTyp.DARLEHENSGEBER,
        extern
      )
    );

    const { data } = yield call(
      storeCssdTeilforderungKuendigung,
      partialClaim.cssdId,
      partialClaim.id,
      partialClaim.idProxyAdresseZahlstelle,
      terminationWithFP,
      account.address.toLowerCase()
    );

    if (extern) {
      yield call(
        modifyAndApproveReDocumentedPartialClaimTermination,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.id}`),
        partialClaim.fingerprint,
        toHex(`${data.kuendigungId}`),
        terminationWithFP.fingerprint,
        dateToTimestamp(terminationWithFP.valuta)
      );
    } else {
      yield call(
        modifyAndApprovePartialClaimTermination,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.id}`),
        partialClaim.fingerprint,
        toHex(`${data.kuendigungId}`),
        terminationWithFP.fingerprint,
        convertBooleanAsStringToNumber(termination.ausserordentlicheKuendigung),
        dateToTimestamp(terminationWithFP.valuta)
      );
    }

    yield put(partialClaimTerminationActions.saveAndConfirmSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cssd_termination_saveAndConfirm_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(partialClaimTerminationActions.saveAndConfirmFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.cssd_termination_saveAndConfirm_failure)
      )
    );
  }
}

export function* confirmPartialClaimTermination({ account, partialClaim, termination }) {
  try {
    const { extern } = partialClaim;

    yield call(
      updateCssdTeilforderungKuendigung,
      partialClaim.cssdId,
      partialClaim.id,
      partialClaim.idProxyAdresseZahlstelle,
      termination
    );

    yield call(
      extern ? approveReDocumentedPartialClaimTermination : approvePartialClaimTermination,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      partialClaim.fingerprint,
      toHex(`${termination.kuendigungId}`),
      termination.fingerprint
    );

    const { cessionIds, zessionarCessionIds } = yield call(abortCessions, {
      account,
      payingAgent: partialClaim.idProxyAdresseZahlstelle,
      cssdId: toHex(`${partialClaim.cssdId}`),
      partialClaimId: toHex(`${partialClaim.id}`),
      terminationTimestamp: dateToTimestamp(termination.valuta),
    });

    if (!extern) {
      yield spawn(
        sendCessionAbortMail,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.cssdId,
        partialClaim.id,
        hashArrayToNumberArray(cessionIds),
        hashArrayToNumberArray(zessionarCessionIds)
      );

      yield call(
        erstelleKuendigungsPDF,
        partialClaim.cssdId,
        termination.kuendigungId,
        partialClaim.idProxyAdresseZahlstelle
      );

      yield call(sendPartialClaimTerminationMail, {
        arranger: partialClaim.idProxyAdresseZahlstelle,
        cssdId: partialClaim.cssdId,
        teilforderungId: partialClaim.id,
        kuendigungId: termination.kuendigungId,
      });
    }

    yield put(partialClaimTerminationActions.confirmSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.partialClaim_termination_confirm_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(partialClaimTerminationActions.confirmFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.partialClaim_termination_confirm_failure)
      )
    );
  }
}

export function* cancelPartialClaimTermination({ account, partialClaim, termination }) {
  try {
    yield call(
      rejectPartialClaimTerminationBC,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${termination.kuendigungId}`),
      termination.fingerprint
    );

    yield call(
      updateCssdKuendigungStatus,
      partialClaim.cssdId,
      {
        kuendigungId: termination.kuendigungId,
      },
      partialClaim.idProxyAdresseZahlstelle
    );

    yield put(partialClaimTerminationActions.cancelSuccess());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.cssd_termination_cancel_success))
    );
  } catch (error) {
    console.error(error);
    yield put(partialClaimTerminationActions.cancelFailure());
    yield put(cssdTerminationDialogActions.close());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cssd_termination_cancel_failure))
    );
  }
}

export function* checkTerminationState({ cssd, termination, partialClaim, onRevoke }) {
  try {
    const { data } = yield call(
      pruefeKuendigungStatus,
      cssd?.cssdId || partialClaim?.cssdId,
      termination,
      cssd?.zahlstelle?.idProxyAdresse || partialClaim?.idProxyAdresseZahlstelle
    );
    if (!data.requestValid) {
      cssd
        ? yield put(checkTerminationsForCssd.update(cssd?.cssdId, [data]))
        : yield put(checkTerminationsForPartialClaim.update(partialClaim?.id, [data]));
      yield put(
        messageDialogActions.open({
          title: appIntl().formatMessage(messages.titleTerminationStateNotValid),
          body: appIntl().formatMessage(messages.contentTextTerminationStateNotValid),
          buttonText: appIntl().formatMessage(messages.default),
        })
      );
    } else {
      onRevoke
        ? yield put(revokeTerminationActions.openConfirmDialog(partialClaim, termination))
        : yield put(cssdTerminationDialogActions.open(cssd, termination, partialClaim));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* openTermination({ cssd, termination, partialClaim }) {
  if (cssd) {
    return yield put(cssdTerminationDialogActions.open_success(cssd, termination, partialClaim));
  } else if (partialClaim.idProxyAdresseDarlehensnehmer === getIdProxyAddr()) {
    const { data } = yield call(
      queryTeilforderungDetails,
      partialClaim.idProxyAdresseZahlstelle,
      partialClaim.id
    );
    return yield put(
      cssdTerminationDialogActions.open_success(cssd, termination, partialClaim, data)
    );
  }

  try {
    const hasActiveCessionsOnBC = yield call(
      hasActiveCessions,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`)
    );
    if (hasActiveCessionsOnBC) {
      yield put(cssdTerminationDialogActions.open_failure());
      yield put(
        messageDialogActions.open({
          title: appIntl().formatMessage(messages.open_dialog_open_cession_error_title),
          body: appIntl().formatMessage(messages.open_dialog_open_cession_error_body),
          buttonText: appIntl().formatMessage(messages.open_dialog_open_cession_error_close),
        })
      );
    } else {
      const { data } = yield call(
        queryTeilforderungDetails,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.id
      );
      return yield put(
        cssdTerminationDialogActions.open_success(cssd, termination, partialClaim, data)
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* checkTerminationStateBulk({ cssd, terminations, partialClaim, callback }) {
  try {
    const openContextMenu = () => callback;
    let invalidTerminations = [];

    if (terminations.length > 0) {
      const { data } = yield call(
        pruefeKuendigungStatusBulk,
        cssd?.cssdId || partialClaim?.cssdId,
        terminations,
        cssd?.zahlstelle?.idProxyAdresse || partialClaim?.idProxyAdresseZahlstelle
      );

      invalidTerminations = data.statusDTOList.filter((termination) => {
        return termination.requestValid === false;
      });
    }

    if (invalidTerminations.length > 0) {
      cssd
        ? yield put(checkTerminationsForCssd.update(cssd?.cssdId, invalidTerminations))
        : yield put(checkTerminationsForPartialClaim.update(partialClaim?.id, invalidTerminations));
      yield put(
        messageDialogActions.open({
          title: appIntl().formatMessage(messages.titleTerminationStateNotValid),
          body: appIntl().formatMessage(messages.contentTextTerminationStateNotValid),
          buttonText: appIntl().formatMessage(messages.default),
        })
      );
    } else {
      openContextMenu();
    }
  } catch (error) {
    console.error(error);
  }
}

export function* offerTerminationRevoke({ account, partialClaim, termination }) {
  try {
    if (termination.status === CSSDTerminationState.APPROVED) {
      yield call(
        modifyAndOfferRevokePartialClaimTermination,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.id}`),
        toHex(`${termination.kuendigungId}`)
      );
    } else {
      yield call(
        offerRevokePartialClaimTermination,
        account,
        partialClaim.idProxyAdresseZahlstelle,
        toHex(`${partialClaim.cssdId}`),
        toHex(`${partialClaim.id}`),
        toHex(`${termination.kuendigungId}`)
      );

      yield spawn(
        sendTerminationRevokeMail,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.cssdId,
        termination.kuendigungId,
        "cssd_kuendigung_zurueckziehen_zweitbestaetigung"
      );
    }

    yield call(
      updateCssdKuendigungStatus,
      partialClaim.cssdId,
      {
        kuendigungId: termination.kuendigungId,
      },
      partialClaim.idProxyAdresseZahlstelle
    );

    yield put(revokeTerminationActions.offerSuccess());
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.offer_revoke_termination_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(revokeTerminationActions.offerFailure(error));
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.offer_revoke_termination_failure))
    );
  }
}

export function* cancelTerminationRevoke({ account, partialClaim, termination }) {
  try {
    const isDN = partialClaim?.idProxyAdresseDarlehensnehmer === getIdProxyAddr();

    yield call(
      cancelRevokePartialClaimTermination,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${termination.kuendigungId}`),
      isDN
    );

    yield call(
      updateCssdKuendigungStatus,
      partialClaim.cssdId,
      {
        kuendigungId: termination.kuendigungId,
      },
      partialClaim.idProxyAdresseZahlstelle
    );

    yield put(revokeTerminationActions.cancelSuccess());
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.cancel_revoke_termination_success)
      )
    );
  } catch (error) {
    console.error();
    yield put(revokeTerminationActions.cancelFailure(error));
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.cancel_revoke_termination_failure))
    );
  }
}
export function* downloadTermination({ partialClaim, termination }) {
  try {
    yield call(
      downloadKuendigungsPDF,
      partialClaim.cssdId,
      termination.kuendigungId,
      partialClaim.idProxyAdresseZahlstelle
    );
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.download_failed)));
  }
}

export function* acceptTerminationRevoke({ account, partialClaim, termination }) {
  try {
    yield call(
      acceptRevokePartialClaimTermination,
      account,
      partialClaim.idProxyAdresseZahlstelle,
      toHex(`${partialClaim.cssdId}`),
      toHex(`${partialClaim.id}`),
      toHex(`${termination.kuendigungId}`)
    );

    yield call(
      updateCssdKuendigungStatus,
      partialClaim.cssdId,
      {
        kuendigungId: termination.kuendigungId,
      },
      partialClaim.idProxyAdresseZahlstelle
    );

    if (termination.firstConfirmer !== zeroAddress) {
      yield spawn(
        sendTerminationRevokeMail,
        partialClaim.idProxyAdresseZahlstelle,
        partialClaim.cssdId,
        termination.kuendigungId,
        "cssd_kuendigung_zurueckziehen_gegenbestaetigung"
      );
    }

    yield put(revokeTerminationActions.acceptSuccess());
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.accept_revoke_termination_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(revokeTerminationActions.closeConfirmDialog());
    yield put(revokeTerminationActions.acceptFailure(error));
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.accept_revoke_termination_failure))
    );
  }
}
