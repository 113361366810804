// action types
export const INVITE_PLATFORM_MEMBER_SEND = "INVITE_PLATFORM_MEMBER_SEND";
export const INVITE_PLATFORM_MEMBER_SUCCESS = "INVITE_PLATFORM_MEMBER_SUCCESS";
export const INVITE_PLATFORM_MEMBER_FAILURE = "INVITE_PLATFORM_MEMBER_FAILURE";

export const INVITE_PLATFORM_MEMBER_AGAIN_SEND = "INVITE_PLATFORM_MEMBER_AGAIN_SEND";
export const INVITE_PLATFORM_MEMBER_AGAIN_SUCCESS = "INVITE_PLATFORM_MEMBER_AGAIN_SUCCESS";
export const INVITE_PLATFORM_MEMBER_AGAIN_FAILURE = "INVITE_PLATFORM_MEMBER_AGAIN_FAILURE";

export const REVOKE_INVITE_PLATFORM_MEMBER_REQUEST = "REVOKE_INVITE_PLATFORM_MEMBER_REQUEST";
export const REVOKE_INVITE_PLATFORM_MEMBER_SUCCESS = "REVOKE_INVITE_PLATFORM_MEMBER_SUCCESS";
export const REVOKE_INVITE_PLATFORM_MEMBER_FAILURE = "REVOKE_INVITE_PLATFORM_MEMBER_FAILURE";

export const GET_PLATFORM_MEMBER_INVITES_REQUEST = "GET_PLATFORM_MEMBER_INVITES_REQUEST";
export const GET_PLATFORM_MEMBER_INVITES_SUCCESS = "GET_PLATFORM_MEMBER_INVITES_SUCCESS";
export const GET_PLATFORM_MEMBER_INVITES_FAILURE = "GET_PLATFORM_MEMBER_INVITES_FAILURE";

export const GET_PLATFORM_MEMBERS_REQUEST = "GET_PLATFORM_MEMBERS_REQUEST";
export const GET_PLATFORM_MEMBERS_SUCCESS = "GET_PLATFORM_MEMBERS_SUCCESS";
export const GET_PLATFORM_MEMBERS_FAILURE = "GET_PLATFORM_MEMBERS_FAILURE";

export const GET_PLATFORM_MEMBER_DETAILS_REQUEST = "GET_PLATFORM_MEMBER_DETAILS_REQUEST";
export const GET_PLATFORM_MEMBER_DETAILS_RETRY = "GET_PLATFORM_MEMBER_DETAILS_RETRY";
export const GET_PLATFORM_MEMBER_DETAILS_SUCCESS = "GET_PLATFORM_MEMBER_DETAILS_SUCCESS";
export const GET_PLATFORM_MEMBER_DETAILS_FAILURE = "GET_PLATFORM_MEMBER_DETAILS_FAILURE";

export const GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST = "GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST";
export const GRANT_REVOKE_PLATFORM_PRIVILEGES_SUCCESS = "GRANT_REVOKE_PLATFORM_PRIVILEGES_SUCCESS";
export const GRANT_REVOKE_PLATFORM_PRIVILEGES_FAILURE = "GRANT_REVOKE_PLATFORM_PRIVILEGES_FAILURE";

export const OPEN_PLATFORM_MEMBERS_ROLE_MODAL = "OPEN_PLATFORM_MEMBERS_ROLE_MODAL";
export const CLOSE_PLATFORM_MEMBERS_ROLE_MODAL = "CLOSE_PLATFORM_MEMBERS_ROLE_MODAL";

export const GET_PLATFORM_MEMBER_PERMISSIONS_REQUEST = "GET_PLATFORM_MEMBER_PERMISSIONS_REQUEST";
export const GET_PLATFORM_MEMBER_PERMISSIONS_SUCCESS = "GET_PLATFORM_MEMBER_PERMISSIONS_SUCCESS";
export const GET_PLATFORM_MEMBER_PERMISSIONS_FAILURE = "GET_PLATFORM_MEMBER_PERMISSIONS_FAILURE";

export const FILTER_PLATFORM_MEMBERS = "FILTER_PLATFORM_MEMBERS";
export const RESET_FILTER_PLATFORM_MEMBERS = "RESET_FILTER_PLATFORM_MEMBERS";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const platformMemberPermissionActions = {
  request: (idProxyAddr) => action(GET_PLATFORM_MEMBER_PERMISSIONS_REQUEST, { idProxyAddr }),
  success: (idProxyAddr, permissions) =>
    action(GET_PLATFORM_MEMBER_PERMISSIONS_SUCCESS, {
      idProxyAddr,
      permissions,
    }),
  failure: (error) => action(GET_PLATFORM_MEMBER_PERMISSIONS_FAILURE, { error }),
};

export const getPlatformMembersActions = {
  request: () => action(GET_PLATFORM_MEMBERS_REQUEST),
  success: (platformMembers) => action(GET_PLATFORM_MEMBERS_SUCCESS, { platformMembers }),
  failure: (error) => action(GET_PLATFORM_MEMBERS_FAILURE, { error }),
};

export const getPlatformMemberDetailsActions = {
  request: () => action(GET_PLATFORM_MEMBER_DETAILS_REQUEST),
  retry: (loadErrors, idProxyAddr) =>
    action(GET_PLATFORM_MEMBER_DETAILS_RETRY, { loadErrors, idProxyAddr }),
  success: (details) => action(GET_PLATFORM_MEMBER_DETAILS_SUCCESS, { details }),
  failure: (idProxyAddr) => action(GET_PLATFORM_MEMBER_DETAILS_FAILURE, { idProxyAddr }),
};

export const invitePlatformMemberActions = {
  send: (invitationData, idProxyAddr) =>
    action(INVITE_PLATFORM_MEMBER_SEND, { invitationData, idProxyAddr }),
  success: () => action(INVITE_PLATFORM_MEMBER_SUCCESS),
  failure: (error) => action(INVITE_PLATFORM_MEMBER_FAILURE, { error }),
};

export const invitePlatformMemberAgainActions = {
  sendAgain: (invitationData, idProxyAddr) =>
    action(INVITE_PLATFORM_MEMBER_AGAIN_SEND, { invitationData, idProxyAddr }),
  success: () => action(INVITE_PLATFORM_MEMBER_AGAIN_SUCCESS),
  failure: (error) => action(INVITE_PLATFORM_MEMBER_AGAIN_FAILURE, { error }),
};

export const revokePlatformMemberInviteActions = {
  request: (email, idProxyAddr) =>
    action(REVOKE_INVITE_PLATFORM_MEMBER_REQUEST, { email, idProxyAddr }),
  success: () => action(REVOKE_INVITE_PLATFORM_MEMBER_SUCCESS),
  failure: (error) => action(REVOKE_INVITE_PLATFORM_MEMBER_FAILURE, { error }),
};

export const getPlatformMemberInvitesActions = {
  request: (idProxyAddr) => action(GET_PLATFORM_MEMBER_INVITES_REQUEST, { idProxyAddr }),
  success: (platformMemberInvites) =>
    action(GET_PLATFORM_MEMBER_INVITES_SUCCESS, { platformMemberInvites }),
  failure: (error) => action(GET_PLATFORM_MEMBER_INVITES_FAILURE, { error }),
};

export const platformMemberRoleModalActions = {
  open: (platformMember) => action(OPEN_PLATFORM_MEMBERS_ROLE_MODAL, { platformMember }),
  close: () => action(CLOSE_PLATFORM_MEMBERS_ROLE_MODAL, {}),
};

export const grantRevokePrivilegeActions = {
  request: (account, idProxyAddr, newPermissions, currentPermissions) =>
    action(GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST, {
      account,
      idProxyAddr,
      newPermissions,
      currentPermissions,
    }),
  success: () => action(GRANT_REVOKE_PLATFORM_PRIVILEGES_SUCCESS, {}),
  failure: (error) => action(GRANT_REVOKE_PLATFORM_PRIVILEGES_FAILURE, { error }),
};

export const platformMemberFilterActions = {
  filter: (filter) => action(FILTER_PLATFORM_MEMBERS, { filter }),
  resetFilter: () => action(RESET_FILTER_PLATFORM_MEMBERS),
};
