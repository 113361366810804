import { defineMessages } from "react-intl";

export const exportMessages = defineMessages({
  export_button: {
    id: "export.csv",
    defaultMessage: "Export CSV",
  },
  data_incomplete: {
    id: "export.data.incomplete.row",
    defaultMessage: "Die exportierten Daten konnten nicht vollständig geladen werden",
  },
  data_incomplete_tooltip: {
    id: "export.data.incomplete.tooltip",
    defaultMessage: "Die Daten wurden noch nicht vollständig geladen",
  },
  export_failure: {
    id: "export.fehler",
    defaultMessage: "Export fehlgeschlagen",
  },
});

export const bssdHeaderMessages = defineMessages({
  ssd_pre: {
    id: "export.ssd.prefix.ssd",
    defaultMessage: "SSD",
  },
  dn_pre: {
    id: "export.ssd.prefix.dn",
    defaultMessage: "DN",
  },
  dg_pre: {
    id: "export.ssd.prefix.dg",
    defaultMessage: "DG",
  },
  handelstag: {
    id: "export.ssd.handelstag",
    defaultMessage: "Handelstag",
  },
  nominal: {
    id: "export.ssd.nominal",
    defaultMessage: "Nominal",
  },
  waehrung_nominal: {
    id: "export.ssd.waehrung_nominal",
    defaultMessage: "Währung Nominal",
  },
  waehrung_preis: {
    id: "export.ssd.waehrung_kaufpreis",
    defaultMessage: "Währung Kaufpreis",
  },
  waehrung_mindestbetrag: {
    id: "export.ssd.waehrung_mindestbetragbeiabtretung",
    defaultMessage: "Währung Mindestbetrag bei Übertragung",
  },
  status: {
    id: "export.ssd.status",
    defaultMessage: "Status",
  },
  status_payment: {
    id: "export.ssd.status_zahlung",
    defaultMessage: "Status Zahlung",
  },
  kurs: {
    id: "export.ssd.kurs",
    defaultMessage: "Kurs",
  },
  notiz: {
    id: "export.ssd.notiz",
    defaultMessage: "Notiz",
  },
  kaufpreis: {
    id: "export.ssd.kaufpreis",
    defaultMessage: "Kaufpreis",
  },
  valuta: {
    id: "export.ssd.valuta",
    defaultMessage: "Valuta",
  },
  endfaelligkeit: {
    id: "export.ssd.endfaelligkeit",
    defaultMessage: "Endfälligkeit",
  },
  zinssatz: {
    id: "export.ssd.zinssatz",
    defaultMessage: "Zinssatz",
  },
  verzinsung: {
    id: "export.ssd.verzinsung",
    defaultMessage: "Verzinsung",
  },
  referenzzinssatz: {
    id: "export.ssd.referenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  aufschlag: {
    id: "export.ssd.aufschlag",
    defaultMessage: "Aufschlag",
  },
  abschlag: {
    id: "export.ssd.abschlag",
    defaultMessage: "Abschlag",
  },
  minimumZinssatz: {
    id: "export.ssd.minimumZinssatz",
    defaultMessage: "Minimumzinssatz",
  },
  maximumZinssatz: {
    id: "export.ssd.maximumZinssatz",
    defaultMessage: "Maximumzinssatz",
  },
  faktor: {
    id: "export.ssd.faktor",
    defaultMessage: "Faktor",
  },
  zinszahlungsrhythmus: {
    id: "export.ssd.zinszahlungsrhythmus",
    defaultMessage: "Zinszahlungsrhythmus",
  },
  bankarbeitstage: {
    id: "export.ssd.bankarbeitstage",
    defaultMessage: "Anzahl der Bankarbeitstage",
  },
  bildschirmseite: {
    id: "export.ssd.bildschirmseite",
    defaultMessage: "Bildschirmseite",
  },
  uhrzeit: {
    id: "export.ssd.uhrzeit",
    defaultMessage: "Relevante Uhrzeit",
  },
  ort: {
    id: "export.ssd.ort",
    defaultMessage: "Relevanter Ort",
  },
  fixing: {
    id: "export.ssd.fixing",
    defaultMessage: "Fixingtyp",
  },
  zinsmethode: {
    id: "export.ssd.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  faelligkeitstag: {
    id: "export.ssd.faelligkeitstag",
    defaultMessage: "Fälligkeitstag der Zinszahlung",
  },
  berechnungszeitraum: {
    id: "export.ssd.berechnungszeitraum",
    defaultMessage: "Berechnungszeitraum der Zinszahlung",
  },
  zinsterminTag: {
    id: "export.ssd.zinsterminTag",
    defaultMessage: "Zinstermin Tag",
  },
  zinsterminMonat: {
    id: "export.ssd.zinsterminMonat",
    defaultMessage: "Zinstermin Monat",
  },
  ersterZinstermin: {
    id: "export.ssd.ersterZinstermin",
    defaultMessage: "erster Zinstermin",
  },
  vorletzterZinstermin: {
    id: "export.ssd.vorletzterZinstermin",
    defaultMessage: "vorletzter Zinstermin",
  },
  geschaeftstagekalender: {
    id: "export.ssd.geschaeftstagekalender",
    defaultMessage: "Geschaeftstagekalender",
  },
  kuendigungsrecht: {
    id: "export.ssd.kuendigungsrecht",
    defaultMessage: "Kündigungsrecht",
  },
  kuendigungsfrist: {
    id: "export.ssd.kuendigungsfrist",
    defaultMessage: "Kündigungsfrist",
  },
  abtretbar: {
    id: "export.ssd.abtretbar",
    defaultMessage: "Abtretbarkeit",
  },
  mindestbetragBeiAbtretung: {
    id: "export.ssd.mindestbetragBeiAbtretung",
    defaultMessage: "Mindestbetrag bei Übertragung",
  },
  auslandsfaehig: {
    id: "export.ssd.auslandsfaehig",
    defaultMessage: "Auslandsfähigkeit",
  },
  vag: {
    id: "export.ssd.vag",
    defaultMessage: "VAG-konform",
  },
  mrel: {
    id: "export.ssd.mrel",
    defaultMessage: "MREL / 72b CRR",
  },
  kwg: {
    id: "export.ssd.kwg",
    defaultMessage: "46f KWG",
  },
  sag: {
    id: "export.ssd.sag",
    defaultMessage: "49 SAG (MREL aktuell)",
  },
  tlac: {
    id: "export.ssd.tlac",
    defaultMessage: "TLAC",
  },
  urkundenRegisternummer: {
    id: "export.ssd.urkundenRegisternummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  firmenname: {
    id: "export.ssd.firmenname",
    defaultMessage: "Firmenname",
  },
  umsatzsteuer_id: {
    id: "export.ssd.umsatzsteuer_id",
    defaultMessage: "Umsatzsteuer ID",
  },
  anschrift: {
    id: "export.ssd.anschrift",
    defaultMessage: "Anschrift",
  },
  telefonnummer: {
    id: "export.ssd.telefonnummer",
    defaultMessage: "Telefonnummer",
  },
  lei: {
    id: "export.ssd.lei",
    defaultMessage: "LEI",
  },
  finledger_kennung: {
    id: "export.ssd.finledger_kennung",
    defaultMessage: "Finledger Kennung",
  },
  erfasser: {
    id: "export.ssd.erfasser",
    defaultMessage: "Erfassender Mitarbeiter",
  },
  interneKennung: {
    id: "export.ssd.interneKennung",
    defaultMessage: "Interne Kennung",
  },
  vertragsrefNr: {
    id: "export.ssd.vertragsrefNr",
    defaultMessage: "Vetragsreferenz Nr.",
  },
  unserZeichen: {
    id: "export.ssd.unserZeichen",
    defaultMessage: "Unser Zeichen",
  },
  iban: {
    id: "export.ssd.iban",
    defaultMessage: "IBAN",
  },
  iban_tilgung: {
    id: "export.ssd.iban_tilgung",
    defaultMessage: "IBAN (Tilgung)",
  },
  bic: {
    id: "export.ssd.bic",
    defaultMessage: "BIC",
  },
  bic_tilgung: {
    id: "export.ssd.bic_tilgung",
    defaultMessage: "BIC (Tilgung)",
  },
});
