import { CSSDTerminationState, PartialClaimState } from "./constants";
import { getDateFromString } from "./convertor";

export const updateTerminations = (array, id, idRef, terminations) => {
  return [...array].map((entry) => {
    if (entry[idRef] === id) {
      return {
        ...entry,
        kuendigungen: [...entry.kuendigungen].map((terminationEntry) => {
          const update = terminations.find(
            (term) => terminationEntry.kuendigungId === term.kuendigungId
          );
          if (update) {
            const { requestValid, ...propsToUpdate } = update;
            return {
              ...terminationEntry,
              ...propsToUpdate,
            };
          }
          return terminationEntry;
        }),
      };
    }
    return entry;
  });
};

export const getActiveTermination = (terminations = []) => {
  return terminations
    .filter(
      (termination) =>
        termination.status === CSSDTerminationState.APPROVED ||
        termination.status === CSSDTerminationState.REVOKE_IN_WORK ||
        termination.status === CSSDTerminationState.REVOKE_OPEN
    )
    .sort((a, b) => getDateFromString(a.valuta) - getDateFromString(b.valuta))[0];
};

export const adjustPartialClaimTerminationStatus = (partialClaim) => {
  if (
    partialClaim.kuendigungen?.length > 0 &&
    getActiveTermination(partialClaim.kuendigungen) &&
    status <= PartialClaimState.NOTIFIED
  ) {
    return {
      ...partialClaim,
      status: PartialClaimState.TERMINATED,
    };
  } else {
    return partialClaim;
  }
};

export const findTerminations = (terminations = [], filterPermissons) => {
  return terminations
    .sort((a, b) => getDateFromString(a.valuta) - getDateFromString(b.valuta))
    .filter((termination) => {
      return filterPermissons({ ...termination });
    });
};

export const initFormDataForMigratedPartialClaim = (partialClaim) => {
  const {
    abgestimmterRestbestandDurchMigration,
    endfaelligkeitDurchKuendigung,
    statusDurchMigration,
    teilforderungId,
    darlehensgeber,
    alterDarlehensgeber,
    ...rest
  } = partialClaim;
  return {
    abtretung: {
      ...rest,
      alterDarlehensgeber,
      neuerDarlehensgeber: darlehensgeber,
    },
    abgestimmterRestbestandDurchMigration,
    endfaelligkeitDurchKuendigung,
    statusDurchMigration,
    teilforderungId,
  };
};
