// action types
export const WALLET_SAVE_REQUEST = "WALLET_SAVE_REQUEST";
export const WALLET_SAVE_SUCCESS = "WALLET_SAVE_SUCCESS";
export const WALLET_SAVE_FAILURE = "WALLET_SAVE_FAILURE";

export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";

export const OPEN_REGISTRATION_CONFIRM_MODAL = "OPEN_REGISTRATION_CONFIRM_MODAL";
export const CLOSE_REGISTRATION_CONFIRM_MODAL = "CLOSE_REGISTRATION_CONFIRM_MODAL";

export const FETCH_PENDING_WALLET_REQUEST = "GET_PENDING_WALLET_REQUEST";
export const FETCH_PENDING_WALLET_SUCCESS = "GET_PENDING_WALLET_SUCCESS";
export const FETCH_PENDING_WALLET_FAILURE = "GET_PENDING_WALLET_FAILURE";

export const ACCEPT_PENDING_WALLET_REQUEST = "ACCEPT_PENDING_WALLET_REQUEST";
export const ACCEPT_PENDING_WALLET_SUCCESS = "ACCEPT_PENDING_WALLET_SUCCESS";
export const ACCEPT_PENDING_WALLET_FAILURE = "ACCEPT_PENDING_WALLET_FAILURE";

export const REJECT_PENDING_WALLET_REQUEST = "REJECT_PENDING_WALLET_REQUEST";
export const REJECT_PENDING_WALLET_SUCCESS = "REJECT_PENDING_WALLET_SUCCESS";
export const REJECT_PENDING_WALLET_FAILURE = "REJECT_PENDING_WALLET_FAILURE";

export const SET_NAME_FROM_URL = "SET_NAME_FROM_URL";

function action(type, payload = {}) {
  return { type, ...payload };
}
export const setNameFromUrlActions = {
  setName: (name) => action(SET_NAME_FROM_URL, { name }),
};
export const saveWalletActions = {
  request: (walletData, backendUrl) => action(WALLET_SAVE_REQUEST, { walletData, backendUrl }),
  success: (response) => action(WALLET_SAVE_SUCCESS, { response }),
  failure: (error) => action(WALLET_SAVE_FAILURE, { error }),
};

export const saveWalletSuccessModalActions = {
  open: () => action(OPEN_SUCCESS_MODAL, {}),
  close: () => action(CLOSE_SUCCESS_MODAL, {}),
};

export const registrationConfirmModalActions = {
  open: (detailData) => action(OPEN_REGISTRATION_CONFIRM_MODAL, { detailData }),
  close: () => action(CLOSE_REGISTRATION_CONFIRM_MODAL, {}),
};

export const fetchPendingWalletActions = {
  request: () => action(FETCH_PENDING_WALLET_REQUEST, {}),
  success: (pendingWallets) => action(FETCH_PENDING_WALLET_SUCCESS, { pendingWallets }),
  failure: (error) => action(FETCH_PENDING_WALLET_FAILURE, { error }),
};

export const acceptPendingWalletActions = {
  request: (
    account,
    walletAddr,
    email_erfassender_mitarbeiter,
    backend_url_id_proxy,
    vier_augen_bestaetigung,
    recovererAddr
  ) =>
    action(ACCEPT_PENDING_WALLET_REQUEST, {
      account,
      walletAddr,
      email_erfassender_mitarbeiter,
      backend_url_id_proxy,
      vier_augen_bestaetigung,
      recovererAddr,
    }),
  success: () => action(ACCEPT_PENDING_WALLET_SUCCESS, {}),
  failure: (error) => action(ACCEPT_PENDING_WALLET_FAILURE, { error }),
};

export const rejectPendingWalletActions = {
  request: (walletAddr, rejectReason) =>
    action(REJECT_PENDING_WALLET_REQUEST, { walletAddr, rejectReason }),
  success: () => action(REJECT_PENDING_WALLET_SUCCESS, {}),
  failure: (error) => action(REJECT_PENDING_WALLET_FAILURE, { error }),
};
