import axios from "axios";
import { store } from "redux/store";
import { messageDialogActions } from "redux/shared/actions";
import { defineMessage } from "react-intl";
import { appIntl } from "components/i18n/intl";
import { formatSafeUrl } from "util/convertor";

const errorMessage = defineMessage({
  id: "general.error.fetchBootstrapAddr",
  defaultMessage:
    "Bootstrap-Addressabruf fehlgeschlagen:\n\nEs konnte keine Verbindung zum Service hergestellt werden.",
});

const BOOTSTRAP_PREFIX = "0x";

export function fetchBootstrapAddr() {
  return axios
    .get(
      formatSafeUrl(
        process.env.REACT_APP_SERVER_URL_PREFIX +
          process.env.REACT_APP_SERVER_URL +
          "/digits-server/bootstrap"
      )
    )
    .then(function (response) {
      return BOOTSTRAP_PREFIX + response.data;
    })
    .catch(function () {
      store.dispatch(
        messageDialogActions.open({
          title: "Error",
          body: appIntl().formatMessage(errorMessage),
        })
      );
    });
}
