import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import {
  UPLOAD_USER_INFORMATION_REQUEST,
  LIST_USER_INFORMATION_REQUEST,
  DOWNLOAD_USER_INFORMATION_REQUEST,
  DELETE_USER_INFORMATION_REQUEST,
  UPDATE_USER_INFORMATION_REQUEST,
} from "./actions";

import {
  dialogActions,
  uploadUserInformationActions,
  listUserInformationActions,
  downloadUserInformationActions,
  deleteUserInformationActions,
  updateUserInformationActions,
} from "./actions";

import { snackbarActions } from "redux/shared/actions";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import * as userInformationService from "services/userInformationService";
import { getKennung } from "redux/selectors";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const userInformationSagas = [
  takeLatest(UPLOAD_USER_INFORMATION_REQUEST, uploadUserInformation),
  takeLatest(LIST_USER_INFORMATION_REQUEST, listUserInformation),
  takeLatest(DOWNLOAD_USER_INFORMATION_REQUEST, downloadUserInformation),
  takeLatest(DELETE_USER_INFORMATION_REQUEST, deleteUserInformation),
  takeLeading(UPDATE_USER_INFORMATION_REQUEST, updateUserInformation),
];

const visibility = {
  UK_ONLY: "UK_ONLY",
  ALL: "ALL",
};

function* uploadUserInformation({ documentName, document, filename, fileType, visibleToUKsOnly }) {
  try {
    const username = yield select(getKennung);

    const request = {
      benutzerKennung: username,
      dokumentname: documentName,
      dokument: document,
      dateiName: filename,
      dateiTyp: fileType,
      sichtbarkeit: visibleToUKsOnly ? visibility.UK_ONLY : visibility.ALL,
    };

    yield call(userInformationService.uploadUserInformation, request);
    yield put(uploadUserInformationActions.success());
    yield put(listUserInformationActions.request());
    yield put(dialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_user_information_upload_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(uploadUserInformationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_user_information_upload_failure)
      )
    );
  }
}

function* listUserInformation() {
  try {
    const response = yield call(userInformationService.getUserInformationMetadataList);
    yield put(listUserInformationActions.success(response.data.nutzerinformationen));
  } catch (error) {
    console.error(error);
    yield put(listUserInformationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_user_information_list_failure)
      )
    );
  }
}

function* downloadUserInformation({ dateiId }) {
  try {
    yield call(userInformationService.getUserInformation, dateiId);
    yield put(downloadUserInformationActions.success(dateiId));
  } catch (error) {
    console.error(error);
    yield put(downloadUserInformationActions.failure(dateiId));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_user_information_download_failure)
      )
    );
  }
}

function* deleteUserInformation({ dateiId }) {
  try {
    yield call(userInformationService.deleteUserInformation, dateiId);
    yield put(deleteUserInformationActions.success(dateiId));
    yield put(listUserInformationActions.request());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_user_information_delete_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(deleteUserInformationActions.failure(dateiId));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_user_information_delete_failure)
      )
    );
  }
}

function* updateUserInformation({ dateiId, document, filename, fileType }) {
  try {
    const username = yield select(getKennung);

    const request = {
      benutzerKennung: username,
      dokument: document,
      dateiName: filename,
      dateiTyp: fileType,
    };

    yield call(userInformationService.updateUserInformation, dateiId, request);
    yield put(updateUserInformationActions.success());
    yield put(listUserInformationActions.request());
    yield put(dialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_user_information_update_success)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(updateUserInformationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_user_information_update_failure)
      )
    );
  }
}
