import { call, takeLatest, put } from "redux-saga/effects";
import history from "util/history";
import { PDF_VERIFICATION_REQUEST } from "./actions";
import { pdfVerificationActions } from "./actions";
import { toSha3 } from "services/web3Services/commons";
import { verifyDocument } from "services/documentVerifyService";

export const pdfSagas = [takeLatest(PDF_VERIFICATION_REQUEST, pdfVerifyService)];

export function* pdfVerifyService({ pdfFile, ssdType }) {
  try {
    const { data } = yield call(verifyDocument, toSha3(pdfFile), ssdType);
    yield put(pdfVerificationActions.success(data));
    history.push(`/verification/${ssdType}`);
  } catch (err) {
    yield put(pdfVerificationActions.failure(err));
  }
}
