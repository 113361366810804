import web3 from "util/web3";
import Contracts from "./contracts/contracts";
import {
  PRODUCT_SSD,
  PRODUCT_CSSD,
  PRIVILEGE_CONTROLLER_ZAHLSTELLE,
  PRIVILEGE_CONTROLLER_BSSD_OPERATIONS,
  PRIVILEGE_CONTROLLER_CSSD_OPERATIONS,
  PRIVILEGE_CONTROLLER_COORDINATOR,
  PRIVILEGE_PLATFORM_COORDINATOR,
  PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS,
  PRIVILEGE_CONTROLLER_READ_ONLY,
  PRIVILEGE_PLATFORM_OFFER_SSD,
  PRIVILEGE_PLATFORM_ZAHLSTELLE,
  PRIVILEGE_PLATFORM_READ_ONLY,
} from "util/constants";
import { fetchBootstrapAddr } from "services/bootstrapService";
import { zeroAddress } from "util/constants";

export const contractCache = {
  bootstrapContract: null, // bootstrap instance
  platformManagerContract: null, // platform manager
  privilegeManagerContract: null, // platform privilegeManager
  ssdManagerContract: null, // platform ssdManager
  cssdManagerContract: null, // platform cssdManager
  identityProxy: null, // companyIdentityProxy
  masterController: null,
  bssdController: null,
  cssdController: null,
  companyIPmContract: null, // company privilegeManager
  simpleMemberController: null
};

function updateBootstrap(bootstrapAddr) {
  contractCache.bootstrapContract = new web3.eth.Contract(Contracts.bootstrap.abi, bootstrapAddr);
}

async function updatePlatformManager() {
  return await contractCache.bootstrapContract.methods
    .platform()
    .call()
    .then((platformManagerAddr) => {
      contractCache.platformManagerContract = new web3.eth.Contract(
        Contracts.platform.abi,
        platformManagerAddr
      );
    });
}

async function updatePrivilegeManager() {
  return await contractCache.platformManagerContract.methods
    .pm()
    .call()
    .then((privilegeManagerAddr) => {
      contractCache.privilegeManagerContract = new web3.eth.Contract(
        Contracts.IPrivilegeManager.abi,
        privilegeManagerAddr
      );
    });
}

async function updateBSSDManager() {
  return await contractCache.platformManagerContract.methods
    .getProduct(PRODUCT_SSD)
    .call()
    .then((ssdManagerAddr) => {
      contractCache.ssdManagerContract = new web3.eth.Contract(
        Contracts.ssdManager.abi,
        ssdManagerAddr
      );
    });
}

async function updateCSSDManager() {
  return await contractCache.platformManagerContract.methods
    .getProduct(PRODUCT_CSSD)
    .call()
    .then((cssdManagerAddr) => {
      contractCache.cssdManagerContract = new web3.eth.Contract(
        Contracts.cssdManager.abi,
        cssdManagerAddr
      );
    });
}

async function updateCompanyProductManagers() {
  await contractCache.masterController.methods
    .subcontrollers(PRODUCT_CSSD)
    .call()
    .then((cssdControllerAddr) => {
      if (cssdControllerAddr !== zeroAddress) {
        contractCache.cssdController = new web3.eth.Contract(
          Contracts.cssdController.abi,
          cssdControllerAddr
        );
      }
    });

  await contractCache.masterController.methods
    .subcontrollers(PRODUCT_SSD)
    .call()
    .then((bssdControllerAddr) => {
      if (bssdControllerAddr !== zeroAddress) {
        contractCache.bssdController = new web3.eth.Contract(
          Contracts.bssdController.abi,
          bssdControllerAddr
        );
      }
    });
}

 async function updateSimpleMemberController() {
  await contractCache.masterController.methods
  .legacySMCAddress()
  .call()
  .then((simpleMemberControllerAddr) => {
    if (simpleMemberControllerAddr !== zeroAddress) {
      contractCache.simpleMemberController = new web3.eth.Contract(
        Contracts.simpleMemberController.abi,
        simpleMemberControllerAddr
      );
    }
  });
}

async function updateCompanyInfoInChache(
  idProxyAddr,
  companyMasterControllerAddr,
  companyIPrivelegeManagerAddr
) {
  contractCache.identityProxy = new web3.eth.Contract(Contracts.identityProxy.abi, idProxyAddr);
  contractCache.masterController = new web3.eth.Contract(
    Contracts.masterController.abi,
    companyMasterControllerAddr
  );
  contractCache.companyIPmContract = new web3.eth.Contract(
    Contracts.IPrivilegeManager.abi,
    companyIPrivelegeManagerAddr
  );
  await updateCompanyProductManagers();
  await updateSimpleMemberController();
}

export function getCompanyIdentityProxy(idProxyAddr) {
  return new web3.eth.Contract(Contracts.identityProxy.abi, idProxyAddr);
}

export async function getCompanyMasterControllerAddr(companyIdentityProxy) {
  return await companyIdentityProxy.methods
    .activeController()
    .call()
    .then((result) => {
      return result;
    });
}

export function getCompanyMasterController(masterControllerAddr) {
  return new web3.eth.Contract(Contracts.masterController.abi, masterControllerAddr);
}

async function getCompanyIPrivelegeManagerAddr(companyMasterController) {
  return await companyMasterController.methods
    .pm()
    .call()
    .then((result) => {
      return result;
    });
}

function getCompanyIPrivelegeManager(privilegeManagerAddr) {
  return new web3.eth.Contract(Contracts.IPrivilegeManager.abi, privilegeManagerAddr);
}

async function checkPrivileges(account, idProxyAddr) {
  try {
    const companyIdentityProxy = getCompanyIdentityProxy(idProxyAddr);
    const companyMasterControllerAddr = await getCompanyMasterControllerAddr(companyIdentityProxy);
    const companyMasterController = getCompanyMasterController(companyMasterControllerAddr);
    const companyIPrivelegeManagerAddr = await getCompanyIPrivelegeManagerAddr(
      companyMasterController
    );
    const companyIPrivelegeManager = getCompanyIPrivelegeManager(companyIPrivelegeManagerAddr);

    const privileges = [
      PRIVILEGE_CONTROLLER_BSSD_OPERATIONS,
      PRIVILEGE_CONTROLLER_CSSD_OPERATIONS,
      PRIVILEGE_CONTROLLER_ZAHLSTELLE,
      PRIVILEGE_CONTROLLER_COORDINATOR,
      PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS,
      PRIVILEGE_CONTROLLER_READ_ONLY
    ];

    for (const privilegeId of privileges) {
      if(contractCache.cssdController !== null){
        continue;
      }
      await companyIPrivelegeManager.methods
      .checkPrivilegeGranted(privilegeId, account.address)
      .call()
      .then( async (result) => {
        if (result) {
          await updateCompanyInfoInChache(
            idProxyAddr,
            companyMasterControllerAddr,
            companyIPrivelegeManagerAddr
          );
        }
      });
    }
  } catch (err) {
    console.error("Error in checkPrivileges: ",err);
  }
}

function updateMaCompanyInfo(account) {
  return contractCache.platformManagerContract
  .getPastEvents("MemberOnboardedEvent", {
    fromBlock: 0,
    toBlock: "latest",
  })
  .then(async function (events) {
    for (const event of [...events]) {
      if(contractCache.cssdController !== null){
        continue;
      }
      await checkPrivileges(
        account,
        event.returnValues.idProxy
      );
    }
  });
}

export async function updateContracts(account) {
  try {
    const bootstrapAddr = await fetchBootstrapAddr();
    updateBootstrap(bootstrapAddr);
    await updatePlatformManager();
    await updatePrivilegeManager();
    await updateBSSDManager();
    await updateCSSDManager();
    await updateMaCompanyInfo(account);
    return true;
  } catch (err) {
    console.error(err);
  }
  return false;
}

async function checkIfSuperUser(account) {
  return await contractCache.companyIPmContract.methods
    .isSuperUser(account.address)
    .call()
    .then((result) => {
      return result;
    });
}

async function checkIfCompanyRightGranted(account, privilegeId) {
  return await contractCache.companyIPmContract.methods
    .checkPrivilegeGranted(privilegeId, account.address)
    .call()
    .then((result) => {
      return result;
    });
}

async function checkIfPlatformRightGranted(privilegeId) {
  return await contractCache.privilegeManagerContract.methods
    .checkPrivilegeGranted(privilegeId, contractCache.identityProxy._address)
    .call()
    .then((result) => {
      return result;
    });
}

export async function getPrivilegesForAccount(account) {
  const privileges = {
    isSuperUser: false,
    companyIsPlaformCoordinator: false,
    privilegeBssdOperations: false,
    privilegeCssdOperations: false,
    privilegeSsdOffer: false,
    privilegeCssdOffer: false,
    privilegePlatformReadOnly: false,
    interestNotification: false,
    zahlstellenMa: false,
    readOnly: false,
  };

  // update permissions for onboarded user
  if (contractCache.identityProxy !== null) {
    privileges.isSuperUser = await checkIfSuperUser(account);
    privileges.companyIsPlaformCoordinator = await checkIfPlatformRightGranted(
      PRIVILEGE_PLATFORM_COORDINATOR
    );
    privileges.privilegeSsdOffer = await checkIfPlatformRightGranted(PRIVILEGE_PLATFORM_OFFER_SSD);
    privileges.privilegeCssdOffer = await checkIfPlatformRightGranted(
      PRIVILEGE_PLATFORM_ZAHLSTELLE
    );
    privileges.privilegePlatformReadOnly = await checkIfPlatformRightGranted(PRIVILEGE_PLATFORM_READ_ONLY);
    privileges.privilegeBssdOperations = await checkIfCompanyRightGranted(
      account,
      PRIVILEGE_CONTROLLER_BSSD_OPERATIONS
    );
    privileges.privilegeCssdOperations = await checkIfCompanyRightGranted(
      account,
      PRIVILEGE_CONTROLLER_CSSD_OPERATIONS
    );
    privileges.readOnly = await checkIfCompanyRightGranted(account, PRIVILEGE_CONTROLLER_READ_ONLY);
    privileges.interestNotification = await checkIfCompanyRightGranted(
      account,
      PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS
    );
    privileges.zahlstellenMa = await checkIfCompanyRightGranted(
      account,
      PRIVILEGE_CONTROLLER_ZAHLSTELLE
    );
  }

  //eslint-disable-next-line no-console
  console.log(privileges);

  return privileges;
}
