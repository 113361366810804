import { defineMessages } from "react-intl";

export const messages = defineMessages({
  claim_settlement_information_success: {
    id: "sagas.cssd.teilforderung.abwicklungsinformationen.speichern.erfolg",
    defaultMessage: "Abwicklungsinformationen erfolgreich aktualisiert",
  },
  claim_settlement_information_failure: {
    id: "sagas.cssd.teilforderung.abwicklungsinformationen.speichern.fehler",
    defaultMessage: "Abwicklungsinformationen aktualisieren fehlgeschlagen",
  },
  export_failure: {
    id: "sagas.cssd.teilforderung.export.fehler",
    defaultMessage: "Export fehlgeschlagen",
  },
  repayment_success: {
    id: "sagas.cssd.teilforderung.rueckzahlung.erfolg",
    defaultMessage: "Rückzahlung erfolgreich erfasst",
  },
  repayment_failure: {
    id: "sagas.cssd.teilforderung.rueckzahlung.fehler",
    defaultMessage: "Fehler beim Erfassen der Rückzahlung",
  },
  partial_claim_for_cssd_error: {
    id: "sagas.cssd.teilforderung.fuer.cssd.fehler",
    defaultMessage: "Teilforderungen für die Übersicht konnten nicht geladen werden",
  },
  save_and_confirm_partial_claim_success: {
    id: "sagas.cssd.teilforderung.saveAndConfirm.erfolg",
    defaultMessage: "Teilforderung wurde erfolgreich gespeichert und erstbestätigt.",
  },
  save_and_confirm_partial_claim_failure: {
    id: "sagas.cssd.teilforderung.saveAndConfirm.misserfolg",
    defaultMessage: "Teilforderung konnte nicht gespeichert werden.",
  },
  cancel_partial_claim_success: {
    id: "sagas.cssd.teilforderung.cancel.erfolg",
    defaultMessage: "Teilforderung wurde erfolgreich storniert.",
  },
  cancel_partial_claim_failure: {
    id: "sagas.cssd.teilforderung.cancel.misserfolg",
    defaultMessage: "Teilforderung konnte nicht storniert werden.",
  },
  cancel_partial_claim_bulk_action_success: {
    id: "sagas.cssd.teilforderung.cancel.bulk.erfolg",
    defaultMessage: "Die Aktion wurde erfolgreich durchgeführt.",
  },
  cancel_partial_claim_bulk_action_failure: {
    id: "sagas.cssd.teilforderung.cancel.bulk.misserfolg",
    defaultMessage: "Die Aktion konnte nicht durchgeführt werden.",
  },
  confirm_partial_claim_success: {
    id: "sagas.cssd.teilforderung.confirm.erfolg",
    defaultMessage: "Teilforderung wurde erfolgreich zweitbestätigt.",
  },
  confirm_partial_claim_failure: {
    id: "sagas.cssd.teilforderung.confirm.misserfolg",
    defaultMessage: "Teilforderung konnte nicht zweitbestätigt werden.",
  },
  loading_failure: {
    id: "sagas.cssd.teilforderung.loading.error",
    defaultMessage: "Teilforderung konnte nicht geladen werden.",
  },
});
