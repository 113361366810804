import React, { useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { snackbarActions } from "../../../redux/shared/actions";
import { connect } from "react-redux";
import SnackbarContentWithStatus from "./SnackbarContentWithStatus/SnackbarContentWithStatus";

const SnackBarContainer = (props) => {
  const maxSnackbars = props.maxSnackbars || 1;
  const { snackbars, closeSnackbar } = props;

  const bottomMargin = 24;
  const snackHeight = 66;

  const position = {
    vertical: "bottom",
    horizontal: "left",
  };

  useEffect(() => {
    if (snackbars.length > maxSnackbars) {
      const snackBarsToClose = snackbars
        .slice(0, snackbars.length - maxSnackbars)
        .map((sb) => sb.id);

      snackBarsToClose.forEach((id) => closeSnackbar(id));
    }
  }, [snackbars, maxSnackbars]);

  return snackbars.map((snackbar, index) => (
    <Snackbar
      style={{
        bottom: index ? index * snackHeight + bottomMargin + "px" : "24px",
      }}
      key={snackbar.id}
      anchorOrigin={position}
      open={snackbar.open}
      onClose={(event, reason) => {
        if (reason === "timeout") {
          closeSnackbar(snackbar.id);
        }
      }}
      autoHideDuration={5000}
    >
      <SnackbarContentWithStatus
        onClose={() => {
          closeSnackbar(snackbar.id);
        }}
        variant={snackbar.variant}
        message={snackbar.text}
      />
    </Snackbar>
  ));
};

const mapStateToProps = (state) => {
  return {
    snackbars: state.sharedReducer.snackbars,
  };
};

const mapDispatchToProps = {
  closeSnackbar: snackbarActions.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarContainer);
