import React from "react";
import { PartialClaimState } from "util/constants";
import {
  StateDisplayedIcon,
  StateInWorkIcon,
  StateUnknownIcon,
  StateInvalidIcon,
  StateNewIcon,
  TerminationIcon,
} from "components/Shared/Icons";
import { getActiveTermination } from "util/cssdUtils";

const CssdPartialClaimStatus = ({ status, kuendigungen }) => {
  let statusTextKey,
    icon,
    color = "default";

  const hasActiveTermination = getActiveTermination(kuendigungen);

  if (status <= PartialClaimState.NOTIFIED && hasActiveTermination) {
    return { statusTextKey: "status_gekuendigt", icon: <TerminationIcon />, color: "error" };
  }

  switch (status) {
    case PartialClaimState.INVALID:
      statusTextKey = "status_invalid";
      icon = <StateInvalidIcon />;
      color = "error";
      break;
    case PartialClaimState.NEW:
      statusTextKey = "status_new";
      icon = <StateNewIcon />;
      break;
    case PartialClaimState.IN_WORK:
      statusTextKey = "status_angelegt";
      icon = <StateInWorkIcon />;
      break;
    case PartialClaimState.NOTIFIED:
      statusTextKey = "status_angezeigt";
      icon = <StateDisplayedIcon />;
      break;
    case PartialClaimState.REPAID:
      statusTextKey = "status_zurueckgezahlt";
      icon = <StateDisplayedIcon />;
      color = "success";
      break;
    case PartialClaimState.CANCELLED:
      statusTextKey = "status_cancelled";
      icon = <StateInvalidIcon />;
      color = "error";
      break;
    case PartialClaimState.TERMINATED:
      statusTextKey = "status_gekuendigt";
      icon = <TerminationIcon />;
      color = "error";
      break;
    default:
      statusTextKey = "status_unknown";
      icon = <StateUnknownIcon />;
  }

  return { statusTextKey, icon, color };
};

export default CssdPartialClaimStatus;
