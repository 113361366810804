import {
  SAVE_SSD_REQUEST,
  SAVE_SSD_SUCCESS,
  SAVE_SSD_FAILURE,
  SUBMIT_SSD_REQUEST,
  SUBMIT_SSD_SUCCESS,
  SUBMIT_SSD_FAILURE,
  OFFER_SSD_CANCEL_CONFIRMATION_REQUEST,
  OFFER_SSD_CANCEL_CONFIRMATION_SUCCESS,
  OFFER_SSD_CANCEL_CONFIRMATION_FAILURE,
  ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST,
  ACCEPT_SSD_CANCEL_CONFIRMATION_SUCCESS,
  ACCEPT_SSD_CANCEL_CONFIRMATION_FAILURE,
  OFFER_SSD_REQUEST,
  OFFER_SSD_SUCCESS,
  OFFER_SSD_FAILURE,
  REJECT_SSD_REQUEST,
  REJECT_SSD_SUCCESS,
  REJECT_SSD_FAILURE,
  ACCEPT_SSD_REQUEST,
  ACCEPT_SSD_SUCCESS,
  ACCEPT_SSD_FAILURE,
  UPDATE_SSD_REQUEST,
  UPDATE_SSD_SUCCESS,
  UPDATE_SSD_FAILURE,
  UPDATE_ACCEPT_SSD_REQUEST,
  UPDATE_ACCEPT_SSD_SUCCESS,
  UPDATE_ACCEPT_SSD_FAILURE,
  CANCEL_SSD_REQUEST,
  CANCEL_SSD_SUCCESS,
  CANCEL_SSD_FAILURE,
  SIGN_DOCUMENT_REQUEST,
  SIGN_DOCUMENT_SUCCESS,
  SIGN_DOCUMENT_FAILURE,
  DOCUSIGN_DOCUMENT_REQUEST,
  DOCUSIGN_DOCUMENT_SUCCESS,
  DOCUSIGN_DOCUMENT_FAILURE,
  UPLOAD_SIGNED_DOCUMENT_REQUEST,
  UPLOAD_SIGNED_DOCUMENT_SUCCESS,
  UPLOAD_SIGNED_DOCUMENT_FAILURE,
} from "./actions";

const initialState = {
  saveSsdPending: false,
  submitSsdPending: false,
  offerSsdPending: false,
  cancelSsdPending: false,

  acceptSsdPending: false,
  updateSsdPending: false,
  updateAcceptSsdPending: false,
  rejectSsdPending: false,

  uploadSignedDocumentPending: false,

  globalPending: false,

  autoSignPending: false,
  docuSignPending: false,
};

function cancelSsdRequestReducer(state) {
  return {
    ...state,
    cancelSsdPending: true,
  };
}

function cancelSsdSuccessReducer(state) {
  return {
    ...state,
    cancelSsdPending: false,
  };
}

function cancelSsdFailureReducer(state) {
  return {
    ...state,
    cancelSsdPending: false,
  };
}

function acceptSsdRequestReducer(state) {
  return {
    ...state,
    acceptSsdPending: true,
  };
}

function acceptSsdSuccessReducer(state) {
  return {
    ...state,
    acceptSsdPending: false,
  };
}

function acceptSsdFailureReducer(state) {
  return {
    ...state,
    acceptSsdPending: false,
  };
}

function updateSsdRequestReducer(state) {
  return {
    ...state,
    updateSsdPending: true,
  };
}

function updateSsdSuccessReducer(state) {
  return {
    ...state,
    updateSsdPending: false,
  };
}

function updateSsdFailureReducer(state) {
  return {
    ...state,
    updateSsdPending: false,
  };
}

function updateAcceptSsdRequestReducer(state) {
  return {
    ...state,
    updateAcceptSsdPending: true,
  };
}

function updateAcceptSsdSuccessReducer(state) {
  return {
    ...state,
    updateAcceptSsdPending: false,
  };
}

function updateAcceptSsdFailureReducer(state) {
  return {
    ...state,
    updateAcceptSsdPending: false,
  };
}

function rejectSsdRequestReducer(state) {
  return {
    ...state,
    rejectSsdPending: true,
  };
}

function rejectSsdSuccessReducer(state) {
  return {
    ...state,
    rejectSsdPending: false,
  };
}

function rejectSsdFailureReducer(state) {
  return {
    ...state,
    rejectSsdPending: false,
  };
}

function offerSsdRequestReducer(state) {
  return {
    ...state,
    offerSsdPending: true,
  };
}

function offerSsdSuccessReducer(state) {
  return {
    ...state,
    offerSsdPending: false,
  };
}

function offerSsdFailureReducer(state) {
  return {
    ...state,
    offerSsdPending: false,
  };
}

function acceptSsdCancelConfirmRequestReducer(state) {
  return {
    ...state,
    globalPending: true,
  };
}

function acceptSsdCancelConfirmSuccessReducer(state) {
  return {
    ...state,
    globalPending: false,
  };
}

function acceptSsdCancelConfirmFailureReducer(state) {
  return {
    ...state,
    globalPending: false,
  };
}

function offerSsdCancelConfirmRequestReducer(state) {
  return {
    ...state,
    globalPending: true,
  };
}

function offerSsdCancelConfirmSuccessReducer(state) {
  return {
    ...state,
    globalPending: false,
  };
}

function offerSsdCancelConfirmFailureReducer(state) {
  return {
    ...state,
    globalPending: false,
  };
}

function saveSsdRequestReducer(state) {
  return {
    ...state,
    saveSsdPending: true,
  };
}

function saveSsdSuccessReducer(state) {
  return {
    ...state,
    saveSsdPending: false,
  };
}

function saveSsdFailureReducer(state) {
  return {
    ...state,
    saveSsdPending: false,
  };
}

function submitSsdRequestReducer(state) {
  return {
    ...state,
    submitSsdPending: true,
  };
}

function submitSsdSuccessReducer(state) {
  return {
    ...state,
    submitSsdPending: false,
  };
}

function submitSsdFailureReducer(state) {
  return {
    ...state,
    submitSsdPending: false,
  };
}

function signDocumentRequestReducer(state) {
  return {
    ...state,
    autoSignPending: true,
  };
}
function signDocumentSuccessReducer(state) {
  return {
    ...state,
    autoSignPending: false,
  };
}
function signDocumentFailureReducer(state) {
  return {
    ...state,
    autoSignPending: false,
  };
}

function docuSignDocumentRequestReducer(state) {
  return {
    ...state,
    docuSignPending: true,
  };
}
function docuSignDocumentSuccessReducer(state) {
  return {
    ...state,
    docuSignPending: false,
  };
}
function docuSignDocumentFailureReducer(state) {
  return {
    ...state,
    docuSignPending: false,
  };
}

function signDocumentUploadRequestReducer(state) {
  return {
    ...state,
    uploadSignedDocumentPending: true,
  };
}

function signDocumentUploadSuccessReducer(state) {
  return {
    ...state,
    uploadSignedDocumentPending: false,
  };
}

function signDocumentUploadFailureReducer(state) {
  return {
    ...state,
    uploadSignedDocumentPending: false,
  };
}

export function ssdStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SSD_REQUEST:
      return saveSsdRequestReducer(state, action);
    case SAVE_SSD_SUCCESS:
      return saveSsdSuccessReducer(state, action);
    case SAVE_SSD_FAILURE:
      return saveSsdFailureReducer(state, action);

    case SUBMIT_SSD_REQUEST:
      return submitSsdRequestReducer(state, action);
    case SUBMIT_SSD_SUCCESS:
      return submitSsdSuccessReducer(state, action);
    case SUBMIT_SSD_FAILURE:
      return submitSsdFailureReducer(state, action);

    case OFFER_SSD_REQUEST:
      return offerSsdRequestReducer(state, action);
    case OFFER_SSD_SUCCESS:
      return offerSsdSuccessReducer(state, action);
    case OFFER_SSD_FAILURE:
      return offerSsdFailureReducer(state, action);

    case REJECT_SSD_REQUEST:
      return rejectSsdRequestReducer(state, action);
    case REJECT_SSD_SUCCESS:
      return rejectSsdSuccessReducer(state, action);
    case REJECT_SSD_FAILURE:
      return rejectSsdFailureReducer(state, action);

    case ACCEPT_SSD_REQUEST:
      return acceptSsdRequestReducer(state, action);
    case ACCEPT_SSD_SUCCESS:
      return acceptSsdSuccessReducer(state, action);
    case ACCEPT_SSD_FAILURE:
      return acceptSsdFailureReducer(state, action);

    case UPDATE_SSD_REQUEST:
      return updateSsdRequestReducer(state, action);
    case UPDATE_SSD_SUCCESS:
      return updateSsdSuccessReducer(state, action);
    case UPDATE_SSD_FAILURE:
      return updateSsdFailureReducer(state, action);

    case UPDATE_ACCEPT_SSD_REQUEST:
      return updateAcceptSsdRequestReducer(state, action);
    case UPDATE_ACCEPT_SSD_SUCCESS:
      return updateAcceptSsdSuccessReducer(state, action);
    case UPDATE_ACCEPT_SSD_FAILURE:
      return updateAcceptSsdFailureReducer(state, action);

    case CANCEL_SSD_REQUEST:
      return cancelSsdRequestReducer(state, action);
    case CANCEL_SSD_SUCCESS:
      return cancelSsdSuccessReducer(state, action);
    case CANCEL_SSD_FAILURE:
      return cancelSsdFailureReducer(state, action);

    case OFFER_SSD_CANCEL_CONFIRMATION_REQUEST:
      return offerSsdCancelConfirmRequestReducer(state, action);
    case OFFER_SSD_CANCEL_CONFIRMATION_SUCCESS:
      return offerSsdCancelConfirmSuccessReducer(state, action);
    case OFFER_SSD_CANCEL_CONFIRMATION_FAILURE:
      return offerSsdCancelConfirmFailureReducer(state, action);

    case ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST:
      return acceptSsdCancelConfirmRequestReducer(state, action);
    case ACCEPT_SSD_CANCEL_CONFIRMATION_SUCCESS:
      return acceptSsdCancelConfirmSuccessReducer(state, action);
    case ACCEPT_SSD_CANCEL_CONFIRMATION_FAILURE:
      return acceptSsdCancelConfirmFailureReducer(state, action);

    case SIGN_DOCUMENT_REQUEST:
      return signDocumentRequestReducer(state, action);
    case SIGN_DOCUMENT_SUCCESS:
      return signDocumentSuccessReducer(state, action);
    case SIGN_DOCUMENT_FAILURE:
      return signDocumentFailureReducer(state, action);

    case DOCUSIGN_DOCUMENT_REQUEST:
      return docuSignDocumentRequestReducer(state, action);
    case DOCUSIGN_DOCUMENT_SUCCESS:
      return docuSignDocumentSuccessReducer(state, action);
    case DOCUSIGN_DOCUMENT_FAILURE:
      return docuSignDocumentFailureReducer(state, action);

    case UPLOAD_SIGNED_DOCUMENT_REQUEST:
      return signDocumentUploadRequestReducer(state, action);
    case UPLOAD_SIGNED_DOCUMENT_SUCCESS:
      return signDocumentUploadSuccessReducer(state, action);
    case UPLOAD_SIGNED_DOCUMENT_FAILURE:
      return signDocumentUploadFailureReducer(state, action);
    default:
      return state;
  }
}
