import { messages } from "./messages";
import { appIntl } from "components/i18n/intl";
import { SSDPaymentState, SSDRebuyState, SSDState, SSDTerminationState } from "util/constants";
import { checkifMaturityDateInPast } from "util/dateInPast";

export const getPaymentState = (
  paymentState,
  rebuyState,
  state,
  maturityDate,
  rebuyMaturityDate,
  terminationDate,
  terminationState
) => {
  if (
    paymentState !== SSDPaymentState.REPAID &&
    state === SSDState.DOCUMENT_SIGNED &&
    (checkifMaturityDateInPast(maturityDate) ||
      (terminationState === SSDTerminationState.APPROVED &&
        checkifMaturityDateInPast(terminationDate)) ||
      (rebuyState === SSDRebuyState.APPROVED && checkifMaturityDateInPast(rebuyMaturityDate)))
  ) {
    return appIntl().formatMessage(
      messages.BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Rueckzahlung_ausstehend
    );
  }
  if (paymentState === SSDPaymentState.INVALID) {
    return appIntl().formatMessage(
      messages.BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Initial
    );
  }
  if (paymentState === SSDPaymentState.REPAID) {
    return appIntl().formatMessage(
      messages.BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Rueckzahlung_erhalten
    );
  }
  if (paymentState === SSDPaymentState.PAID) {
    return appIntl().formatMessage(
      messages.BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Zahlung_erhalten
    );
  }

  return appIntl().formatMessage(
    messages.BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Zahlung_ausstehend
  );
};
