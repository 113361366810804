export const FETCH_RECOVERY_PLATFORM_MEMBER_REQUEST = "FETCH_RECOVERY_PLATFORM_MEMBER_REQUEST";
export const FETCH_RECOVERY_PLATFORM_MEMBER_SUCCESS = "FETCH_RECOVERY_PLATFORM_MEMBER_SUCCESS";
export const FETCH_RECOVERY_PLATFORM_MEMBER_FAILURE = "FETCH_RECOVERY_PLATFORM_MEMBER_FAILURE";

export const FETCH_EMPLOYEES_COMPANY_RECOVERY_SUCCESS = "FETCH_EMPLOYEES_COMPANY_RECOVERY_SUCCESS";
export const FETCH_EMPLOYEES_COMPANY_RECOVERY_FAILURE = "FETCH_EMPLOYEES_COMPANY_RECOVERY_FAILURE";

export const SELECT_COMPANY_FOR_RECOVERY = "SELECT_COMPANY_FOR_RECOVERY";

export const REQUEST_RECOVERY_FOR_WALLET_REQUEST = "REQUEST_RECOVERY_FOR_WALLET_REQUEST";
export const REQUEST_RECOVERY_FOR_WALLET_SUCCESS = "REQUEST_RECOVERY_FOR_WALLET_SUCCESS";
export const REQUEST_RECOVERY_FOR_WALLET_FAILURE = "REQUEST_RECOVERY_FOR_WALLET_FAILURE";

export const RECOVERY_GRANT_UK_REQUEST = "RECOVERY_GRANT_UK_REQUEST";
export const RECOVERY_GRANT_UK_SUCCESS = "RECOVERY_GRANT_UK_SUCCESS";
export const RECOVERY_GRANT_UK_FAILURE = "RECOVERY_GRANT_UK_FAILURE";

export const INVITE_WALLET_FOR_RECOVERY_REQUEST = "INVITE_WALLET_FOR_RECOVERY_REQUEST";
export const INVITE_WALLET_FOR_RECOVERY_SUCCESS = "INVITE_WALLET_FOR_RECOVERY_SUCCESS";
export const INVITE_WALLET_FOR_RECOVERY_FAILURE = "INVITE_WALLET_FOR_RECOVERY_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const recoveryGrantUkActions = {
  request: (account, employeeInfo, activeCompany) =>
    action(RECOVERY_GRANT_UK_REQUEST, { account, employeeInfo, activeCompany }),
  success: () => action(RECOVERY_GRANT_UK_SUCCESS, {}),
  failure: (error) => action(RECOVERY_GRANT_UK_FAILURE, { error }),
};

export const inviteWalletForRecoveryActions = {
  request: (companyIdProxy, invitationData) =>
    action(INVITE_WALLET_FOR_RECOVERY_REQUEST, {
      companyIdProxy,
      invitationData,
    }),
  success: () => action(INVITE_WALLET_FOR_RECOVERY_SUCCESS, {}),
  failure: (error) => action(INVITE_WALLET_FOR_RECOVERY_FAILURE, { error }),
};

export const fetchRecoveryPlatformMemberActions = {
  request: (recovererAddr) => action(FETCH_RECOVERY_PLATFORM_MEMBER_REQUEST, { recovererAddr }),
  success: (companies) => action(FETCH_RECOVERY_PLATFORM_MEMBER_SUCCESS, { companies }),
  failure: (error) => action(FETCH_RECOVERY_PLATFORM_MEMBER_FAILURE, { error }),
};

export const fetchEmployeesCompanyRecoveryActions = {
  success: (activeCompanyEmployees) =>
    action(FETCH_EMPLOYEES_COMPANY_RECOVERY_SUCCESS, {
      activeCompanyEmployees,
    }),
  failure: (error) => action(FETCH_EMPLOYEES_COMPANY_RECOVERY_FAILURE, { error }),
};

export const selectCompanyForRecoveryActions = {
  selectCompany: (companyIdProxy) => action(SELECT_COMPANY_FOR_RECOVERY, { companyIdProxy }),
};

export const requestRecoveryForWalletActions = {
  request: (account, employeeInfo, activeCompany) =>
    action(REQUEST_RECOVERY_FOR_WALLET_REQUEST, {
      account,
      employeeInfo,
      activeCompany,
    }),
  success: () => action(REQUEST_RECOVERY_FOR_WALLET_SUCCESS, {}),
  failure: (error) => action(REQUEST_RECOVERY_FOR_WALLET_FAILURE, { error }),
};
