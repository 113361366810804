import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_interest_notification_download_fehlgeschlagen: {
    id: "redux.interest.notification.download.fehlgeschlagen",
    defaultMessage: "Download fehlgeschlagen.",
  },
  redux_interest_notification_zinsmitteilung_wurde_storniert: {
    id: "redux.interest.notification.zinsmitteilung.wurde_storniert",
    defaultMessage: "Zinsmitteilung wurde storniert.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_storniert_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.storniert.werden",
    defaultMessage: "Zinsmitteilung konnte nicht storniert werden.",
  },
  redux_interest_notification_zinsmitteilung_wurde_abgelehnt: {
    id: "redux.interest.notification.zinsmitteilung.wurde.abgelehnt",
    defaultMessage: "Zinsmitteilung wurde abgelehnt.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_abgelehnt_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Zinsmitteilung konnte nicht abgelehnt werden.",
  },
  redux_interest_notification_zinsmitteilung_wurde_angepasst: {
    id: "redux.interest.notification.zinsmitteilung.wurde.angepasst",
    defaultMessage: "Zinsmitteilung wurde angepasst.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_angepasst_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.angepasst.werden",
    defaultMessage: "Zinsmitteilung konnte nicht angepasst werden.",
  },
  redux_interest_notification_zinsmitteilung_wurde_angepasst_und_bestaetigt: {
    id: "redux.interest.notification.zinsmitteilung.wurde.angepasst.und.bestaetigt",
    defaultMessage: "Zinsmitteilung wurde angepasst und bestätigt.",
  },
  redux_interest_notification_zinsmitteilung_wurde_geschlossen: {
    id: "redux.interest.notification.zinsmitteilung.wurde.geschlossen",
    defaultMessage: "Zinsmitteilung wurde geschlossen.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_geschlossen_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.geschlossen.werden",
    defaultMessage: "Zinsmitteilung konnte nicht geschlossen werden.",
  },
  redux_interest_notification_zinsmitteilung_wurde_bestaetigt: {
    id: "redux.interest.notification.zinsmitteilung.wurde.bestaetigt",
    defaultMessage: "Zinsmitteilung wurde bestätigt.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_bestaetigt_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Zinsmitteilung konnte nicht bestätigt werden.",
  },
  redux_interest_notification_zinsmitteilung_gespeichert: {
    id: "redux.interest.notification.zinsmitteilung.gespeichert",
    defaultMessage: "Zinsmitteilung gespeichert.",
  },
  redux_interest_notification_zinsmitteilung_konnte_nicht_gespeichert_werden: {
    id: "redux.interest.notification.zinsmitteilung.konnte.nicht.gespeichert.werden",
    defaultMessage: "Zinsmitteilung konnte nicht gespeichert werden.",
  },
});
