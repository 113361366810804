import { contractCache } from "services/smartContracts/smartContracts";

import { ssdFourEyesEvents, sendTransaction, getBSSDControllerContractMethods } from "./commons";

import { FourEyesState } from "util/constants";

export async function getInterestNotificationFourEyesConfirmer(ssdId, subId) {
  const confirmer = { first: "0x0", second: "0x0" };
  const fourEyesEvents = ssdFourEyesEvents(ssdId, subId);
  for (let fe of fourEyesEvents) {
    if (fe.returnValues.state === FourEyesState.INTEREST_NOTIFICATION_OFFER_FIRST) {
      confirmer.first = fe.returnValues.account;
    } else if (fe.returnValues.state === FourEyesState.INTEREST_NOTIFICATION_OFFER_SECOND) {
      confirmer.second = fe.returnValues.account;
    }
  }

  return confirmer;
}

export async function getInterestNotificationCount(arranger, ssdId) {
  const interestNotificationCount = await contractCache.ssdManagerContract.methods
    .getInterestNotificationCount(arranger, ssdId)
    .call();
  return interestNotificationCount;
}

export async function getInterestNotificationsForSsdId(arranger, ssdId) {
  const interestNotificationCount = await getInterestNotificationCount(arranger, ssdId);
  const interestNotifications = [];
  for (let i = 0; i < interestNotificationCount; i++) {
    interestNotifications.push(
      (async () => {
        return contractCache.ssdManagerContract.methods
          .getInterestNotificationAtIdx(arranger, ssdId, i)
          .call();
      })()
    );
  }
  return await Promise.all(interestNotifications);
}

export async function interestNotificationNew(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationNew(arranger, ssdId, interestNotificationId, interestNotificationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationNewAndOffer(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  interestNotificationHash,
  ssdHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationNewAndOffer(
      arranger,
      ssdId,
      interestNotificationId,
      interestNotificationHash,
      ssdHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationModify(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationModify(arranger, ssdId, interestNotificationId, interestNotificationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationModifyAndOffer(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  interestNotificationHash,
  ssdHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationModifyAndOffer(
      arranger,
      ssdId,
      interestNotificationId,
      interestNotificationHash,
      ssdHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationOffer(
  account,
  arranger,
  firstConfirmer,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationOffer(
      arranger,
      firstConfirmer,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationCancel(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationCancel(
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationReject(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationReject(
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function interestNotificationClose(
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .interestNotificationClose(
      arranger,
      ssdId,
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}
