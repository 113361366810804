import { toSha3, toHex, getDetailsUrl } from "services/web3Services/commons";
import web3 from "./web3";
import { account } from "services/web3Services/login";
import axios from "axios";
import stringify from "json-stable-stringify";
import { cleanObj, formatSafeUrl } from "./convertor";

export async function sendSignedHttpRequest(
  method,
  endpoint,
  idProxyAddr,
  data,
  header,
  serverUrl
) {
  const backendUrl = serverUrl || (await getDetailsUrl(idProxyAddr));
  const requestUrl = formatSafeUrl(backendUrl + endpoint);
  const requestBodyString = data ? JSON.stringify(JSON.parse(stringify(cleanObj(data)))) : null;
  const requestSignatureHeader = await buildRequestSignatureHeader(
    method.toUpperCase(),
    requestUrl,
    backendUrl,
    requestBodyString
  );

  if (header) {
    header["X-REQUEST-SIGNATURE"] = requestSignatureHeader["X-REQUEST-SIGNATURE"];
  }

  const requestHeader = header || requestSignatureHeader;

  return axios({
    method: method.toLowerCase(),
    url: requestUrl,
    data: requestBodyString ? JSON.parse(requestBodyString) : null,
    headers: requestHeader,
  });
}

export async function getSignatureString(httpMethod, requestUrl, backendUrl, payload) {
  const nonceResponse = await getNonce(backendUrl);
  const nonce = nonceResponse.data.nonce;
  let postBody = null;
  if (httpMethod.toUpperCase() === "POST") {
    postBody = payload ? payload : "";
  }
  const requestHash = toSha3(toHex(httpMethod.toUpperCase() + requestUrl + nonce + postBody));
  const signatureObj = web3.eth.accounts.sign(requestHash, account.privateKey);
  const signatureString =
    requestHash + "-" + nonce + "-" + signatureObj.r + "-" + signatureObj.s + "-" + signatureObj.v;

  return signatureString;
}

async function buildRequestSignatureHeader(httpMethod, requestUrl, backendUrl, payload) {
  return {
    "X-REQUEST-SIGNATURE": await getSignatureString(httpMethod, requestUrl, backendUrl, payload),
  };
}

async function getNonce(backendUrl) {
  const nonceUrl = formatSafeUrl(backendUrl + "/digits-server/nonce");
  return axios.get(nonceUrl);
}
