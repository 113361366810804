import { ZERO_HASH } from "util/constants";
import { clearSsdNumberFormat, clearRebuyNumberFormat, setSsdNumberFormat } from "util/convertor";
import { sendSignedHttpRequest } from "util/requestSigning";
import { startPdfDownload } from "services/commonService";

const METHOD = {
  GET: "GET",
  POST: "POST",
};
const URL_PREFIX = "/digits-server/ssd/";

function validateSSDResponse(response) {
  if (response.data.ssdId) return response;
  throw new Error("missing ssd response info");
}

export async function postSSD(ssdData) {
  const arranger = ssdData.darlehensnehmer;

  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}`,
    arranger,
    clearSsdNumberFormat(ssdData)
  ).then(function (response) {
    return validateSSDResponse(response);
  });
}
//---------------------------------------------------------------------------

export async function updateSSD(ssdData) {
  const arranger = ssdData.darlehensnehmer;

  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}`,
    arranger,
    clearSsdNumberFormat(ssdData)
  );
}
//---------------------------------------------------------------------------

export async function postRebuy(id, fingerprint, old_fingerprint_rebuy, rebuyData, arranger) {
  let url;
  if (!old_fingerprint_rebuy)
    url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/rebuy`;
  else url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${old_fingerprint_rebuy}/rebuy`;

  return sendSignedHttpRequest("post", url, arranger, clearRebuyNumberFormat(rebuyData)).then(
    function (response) {
      return response;
    }
  );
}
//---------------------------------------------------------------------------

export const fetchSSDDetails = async (id, fingerprint, rebuyHashFingerprint, arranger) => {
  const url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${
    !rebuyHashFingerprint ? ZERO_HASH : rebuyHashFingerprint
  }`;
  return sendSignedHttpRequest("get", url, arranger)
    .then((response) => setSsdNumberFormat(response))
    .catch((error) => ({ error }));
};

//---------------------------------------------------------------------------

export async function fetchSSDDetailsZedent(
  id,
  fingerprint,
  rebuyHashFingerprint,
  parentId,
  arranger
) {
  rebuyHashFingerprint = !rebuyHashFingerprint ? ZERO_HASH : rebuyHashFingerprint;
  let url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyHashFingerprint}/${parentId}/zedent`;

  return sendSignedHttpRequest("get", url, arranger)
    .then(function (response) {
      return setSsdNumberFormat(response);
    })
    .catch(function (error) {
      return { error };
    });
}
export const fetchSSDDetailsBulk = async (arranger, bundle) => {
  let url = `/digits-server/ssd/${arranger}/ssd`;
  return sendSignedHttpRequest("post", url, arranger, { ssdRequests: bundle }).catch((error) => ({
    error,
  }));
};
//-------------------------------------------------------------------------------------------------
export const getBssds = async (companyAddress) =>
  sendSignedHttpRequest(
    METHOD.GET,
    `${URL_PREFIX}${companyAddress}/ssd-uebersicht`,
    companyAddress
  );

export const getMigration = async (companyAddress) =>
  sendSignedHttpRequest(METHOD.GET, `${URL_PREFIX}/ssd-uebersicht/migration/start`, companyAddress);

export const postBssds = async (companyAddress, requestBody) =>
  sendSignedHttpRequest(
    METHOD.POST,
    `${URL_PREFIX}${companyAddress}/ssd/uebersicht`,
    companyAddress,
    { ssdRequests: requestBody }
  );

//-------------------------------------------------------------------------------------------------
export async function fetchSSDDetailsForNewDg(id, fingerprint, rebuyHashFingerprint, arranger) {
  let url;
  if (!rebuyHashFingerprint)
    url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/new-dg-information`;
  else
    url = `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyHashFingerprint}/new-dg-information`;

  return sendSignedHttpRequest("get", url, arranger)
    .then(function (response) {
      return setSsdNumberFormat(response);
    })
    .catch(function (error) {
      return { error };
    });
}
//---------------------------------------------------------------------------

export async function postRejectReason(id, fingerprint, rejectReason, usecase, arranger) {
  const data = {
    usecase,
    kommentar: rejectReason,
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/bemerkung`,
    arranger,
    data
  );
}
//---------------------------------------------------------------------------

export async function postRebuyRejectReason(
  id,
  fingerprint,
  rejectReason,
  usecase,
  rebuyFingerprint,
  arranger
) {
  const data = {
    usecase,
    kommentar: rejectReason,
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyFingerprint}/bemerkung`,
    arranger,
    data
  );
}
//---------------------------------------------------------------------------

export async function fetchRejectReason(id, fingerprint, arranger, rebuyHash = ZERO_HASH) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyHash}/bemerkung`,
    arranger
  ).catch(function () {
    return {};
  });
}

//---------------------------------------------------------------------------

export async function fetchAllRejectReason(id, fingerprint, arranger, rebuyHash = ZERO_HASH) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyHash}/bemerkungen`,
    arranger
  ).catch(function () {
    return {};
  });
}
//---------------------------------------------------------------------------

export async function fetchRebuyRejectReason(id, fingerprint, rebuyFingerprint, arranger) {
  if (rebuyFingerprint !== ZERO_HASH) {
    return sendSignedHttpRequest(
      "get",
      `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${rebuyFingerprint}/bemerkung`,
      arranger
    ).catch(function () {
      return {};
    });
  }
  return {};
}
//---------------------------------------------------------------------------

async function getMethodToDownload(id, fingerprint, fingerprint_rebuy, arranger, withWatermark) {
  const url = withWatermark
    ? `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/pdf/draft`
    : `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/pdf`;
  return sendSignedHttpRequest("get", url, arranger)
    .then(function (response) {
      startPdfDownload(response.data.base64EncodedPdf, response.data.dateiName);
      return response;
    })
    .catch(function (error) {
      console.error(error);
    });
}
//---------------------------------------------------------------------------

export async function handleFileDownload(
  id,
  fingerprint,
  fingerprint_rebuy,
  rootId,
  root_fingerprint,
  root_fingerprint_rebuy,
  parentId,
  parent_fingerprint,
  parent_fingerprint_rebuy,
  restId,
  rest_fingerprint,
  rest_fingerprint_rebuy,
  zessionarId,
  zessionar_fingerprint,
  zessionar_fingerprint_rebuy,
  arranger,
  withWatermark,
  pdfName,
  downloadForZessionar,
  currentSsdId,
  externUrkunde
) {
  const body = {
    pdfName,
    rootId,
    root_fingerprint,
    root_fingerprint_rebuy,
    parentId,
    parent_fingerprint,
    parent_fingerprint_rebuy,
    restId,
    rest_fingerprint,
    rest_fingerprint_rebuy,
    zessionarId,
    zessionar_fingerprint,
    zessionar_fingerprint_rebuy,
  };

  if (downloadForZessionar) {
    const url = withWatermark
      ? `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/${currentSsdId}/pdf/draft/zessionar`
      : `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/${currentSsdId}/pdf/zessionar`;
    return sendSignedHttpRequest("get", url, arranger)
      .then(function (response) {
        startPdfDownload(response.data.base64EncodedPdf, response.data.dateiName);
        return response;
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    if (externUrkunde) {
      return getMethodToDownload(id, fingerprint, fingerprint_rebuy, arranger, withWatermark);
    } else {
      return sendSignedHttpRequest(
        "post",
        `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/pdf`,
        arranger,
        body
      )
        .then(function (response) {
          getMethodToDownload(id, fingerprint, fingerprint_rebuy, arranger, withWatermark);
          return response;
        })
        .catch(function (error) {
          getMethodToDownload(id, fingerprint, fingerprint_rebuy, arranger, withWatermark);
          console.error(error);
        });
    }
  }
}

//---------------------------------------------------------------------------

export async function createTradeConfirmationPDF(id, fingerprint, fingerprint_rebuy, arranger) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/bestaetigung`,
    arranger
  ).catch((error) => {
    console.error(error);
  });
}

//---------------------------------------------------------------------------

export async function handleTradeConfirmationDownload(
  id,
  fingerprint,
  fingerprint_rebuy,
  arranger
) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/bestaetigung`,
    arranger
  ).then(function (response) {
    startPdfDownload(response.data.base64EncodedBestaetigung, response.data.dateiName);
    return response;
  });
}

//---------------------------------------------------------------------------

export async function createRebuyConfirmationPDF(id, fingerprint, fingerprint_rebuy, arranger) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/rebuy/bestaetigung`,
    arranger
  ).catch((error) => {
    console.error(error);
  });
}

//---------------------------------------------------------------------------

export async function handleRebuyConfirmationDownload(
  id,
  fingerprint,
  fingerprint_rebuy,
  arranger
) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${fingerprint_rebuy}/rebuy/bestaetigung`,
    arranger
  ).then(function (response) {
    startPdfDownload(response.data.base64EncodedBestaetigung, response.data.dateiName);
    return response;
  });
}

//---------------------------------------------------------------------------

export async function handleTerminationConfirmation(ssdInfo) {
  const body = {
    terminationHash: ssdInfo.terminationHash,
    terminationInitiator: ssdInfo.terminationInitiator,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${ssdInfo.arranger}/${ssdInfo.ssdid}/${ssdInfo.fingerprint}/${ssdInfo.fingerprint_rebuy}/kuendigungsbestaetigung`,
    ssdInfo.arranger,
    body
  ).catch(function () {
    return {};
  });
}

//---------------------------------------------------------------------------

export async function handleTerminationConfirmationDownload(ssdInfo) {
  const body = {
    terminationHash: ssdInfo.terminationHash,
    terminationInitiator: ssdInfo.terminationInitiator,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${ssdInfo.arranger}/${ssdInfo.ssdid}/${ssdInfo.fingerprint}/${ssdInfo.fingerprint_rebuy}/kuendigungsbestaetigung`,
    ssdInfo.arranger,
    body
  ).then((response) => {
    startPdfDownload(response.data.base64EncodedPdf, response.data.dateiName);
    return response;
  });
}

//---------------------------------------------------------------------------

export async function createPdf(id, fingerprint, arranger) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/pdf`,
    arranger
  ).catch(function (error) {
    console.error(error);
  });
}
//---------------------------------------------------------------------------

export async function signPdf(id, fingerprint, arranger) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/pdf/signing/service`,
    arranger
  )
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      return { error };
    });
}
//---------------------------------------------------------------------------

export async function docuSignPdf(id, fingerprint, arranger) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${id}/${fingerprint}/${ZERO_HASH}/pdf/signing/docusign`,
    arranger
  )
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      return { error };
    });
}
//---------------------------------------------------------------------------

export async function getHistory(ssdInfo, arranger) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdInfo.ssdId}/audit`,
    arranger,
    ssdInfo
  );
}
//---------------------------------------------------------------------------
export async function checkDocumentForUpload(pdfBase64String, arranger) {
  const body = {
    content: pdfBase64String,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/pdf/check`,
    arranger,
    body
  ).catch(function (error) {
    return error;
  });
}
//---------------------------------------------------------------------------
export async function uploadSignedDocument(pdfBase64String, arranger, ssdId, fingerprint) {
  const body = {
    content: pdfBase64String,
    ssdId,
    fingerprint,
  };
  return sendSignedHttpRequest("put", `/digits-server/ssd/${arranger}/pdf/upload`, arranger, body);
}

export async function getInterestAuditHistory(arranger, ssdId, auditRequest) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/audit/zinsmitteilung`,
    arranger,
    auditRequest
  );
}

export async function getCessionAuditHistory(arranger, dgOld, ssdId, auditRequest) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/${auditRequest.cessionId}/audit/cession`,
    dgOld,
    auditRequest
  );
}
