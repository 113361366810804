import { Button, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  },
});

export default withStyles(styles)(Button);

const stylesText = (theme) => ({
  root: {
    color: theme.palette.error.main,
  },
});

export const RejectTextButton = withStyles(stylesText)(Button);
