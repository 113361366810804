import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_ssd_payment_status_rueckkauf_gespeichert: {
    id: "redux.ssd_payment.status.rueckkauf.gespeichert",
    defaultMessage: "Rückkauf gespeichert.",
  },
  redux_ssd_payment_status_rueckkauf_konnte_nicht_gespeichert_werden: {
    id: "redux.ssd_payment.status.rueckkauf.konnte.nicht.gespeichert.werden",
    defaultMessage: "Rückkauf konnte nicht gespeichert werden.",
  },
  redux_ssd_payment_status_rueckkauf_wurde_gespeichert_und_bestaetigt: {
    id: "redux.ssd_payment.status.rueckkauf.wurde.gespeichert.und.bestaetigt",
    defaultMessage: "Rückkauf wurde gespeichert und bestätigt.",
  },
  redux_ssd_payment_status_rueckkauf_wurde_abgebrochen: {
    id: "redux.ssd_payment.status.rueckkauf.wurde.abgebrochen",
    defaultMessage: "Rückkauf wurde abgebrochen.",
  },
  redux_ssd_payment_status_rueckkauf_konnte_nicht_abgebrochen_werden: {
    id: "redux.ssd_payment.status.rueckkauf.konnte.nicht.abgebrochen.werden",
    defaultMessage: "Rückkauf konnte nicht abgebrochen werden.",
  },
  redux_ssd_payment_status_bestaetigung_wurde_zurueckgenommen: {
    id: "redux.ssd_payment.status.bestaetigung.wurde.zurueckgenommen",
    defaultMessage: "Bestätigung wurde zurückgenommen.",
  },
  redux_ssd_payment_status_bestaetigung_konnte_nicht_zurueck_genommen_werden: {
    id: "redux.ssd_payment.status.bestaetigung.konnte.nicht.zurueck.genommen.werden",
    defaultMessage: "Bestätigung konnte nicht zurück genommen werden.",
  },
  redux_ssd_payment_status_rueckkauf_wurde_bestaetigt: {
    id: "redux.ssd_payment.status.rueckkauf.wurde.bestaetigt",
    defaultMessage: "Rückkauf wurde bestätigt.",
  },
  redux_ssd_payment_status_rueckkauf_konnte_nicht_bestaetigt_werden: {
    id: "redux.ssd_payment.status.rueckkauf.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Rückkauf konnte nicht bestätigt werden.",
  },
  redux_ssd_payment_status_rueckkauf_abgelehnt: {
    id: "redux.ssd_payment.status.rueckkauf.abgelehnt",
    defaultMessage: "Rückkauf abgelehnt.",
  },
  redux_ssd_payment_status_rueckkauf_konnte_nicht_abgelehnt_werden: {
    id: "redux.ssd_payment.status.rueckkauf.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Rückkauf konnte nicht abgelehnt werden.",
  },
  redux_ssd_payment_status_zahlungskorrektur_wurde_angefordert: {
    id: "redux.ssd_payment.status.zahlungskorrektur.wurde.angefordert",
    defaultMessage: "Zahlungskorrektur wurde angefordert.",
  },
  redux_ssd_payment_status_fehler_beim_anfordern_der_zahlungskorrektur: {
    id: "redux.ssd_payment.status.fehler.beim.anfordern.der.zahlungskorrektur",
    defaultMessage: "Fehler beim anfordern der Zahlungskorrektur.",
  },
  redux_ssd_payment_status_zahlung_wurde_bestaetigt: {
    id: "redux.ssd_payment.status.zahlung.wurde.bestaetigt",
    defaultMessage: "Zahlung wurde bestätigt.",
  },
  redux_ssd_payment_status_zahlung_konnte_nicht_bestaetigt_werden: {
    id: "redux.ssd_payment.status.zahlung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Zahlung konnte nicht bestätigt werden.",
  },
  redux_ssd_payment_status_rueckzahlung_wurde_bestaetigt: {
    id: "redux.ssd_payment.status.rueckzahlung.wurde.bestaetigt",
    defaultMessage: "Rückzahlung wurde bestätigt.",
  },
  redux_ssd_payment_status_rueckzahlung_konnte_nicht_bestaetigt_werden: {
    id: "redux.ssd_payment.status.rueckzahlung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Rückzahlung konnte nicht bestätigt werden.",
  },
});
