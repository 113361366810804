import React from "react";
import { connect } from "react-redux";
import { saveWalletSuccessModalActions } from "redux/walletRegistration/actions";
import History from "util/history";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "450",
    height: "180",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  buttonsRow: {
    textAlign: "right",
  },
});

class WalletSuccessModal extends React.Component {
  handleClose = () => {
    this.props.onCloseSaveWalletSuccessModal();
    History.push("/login");
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog open={this.props.modalSaveWalletSuccessOpen} onClose={this.handleClose}>
        <DialogTitle id="form-dialog-title">
          <FormattedMessage
            {...messages.wallet_registration_success_modal_dialog_title_wichtiger_hinweis}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              {...messages.wallet_registration_success_modal_dialog_content_text_anmeldung_erfolgreich}
            />
          </DialogContentText>
          <DialogContentText>
            <FormattedMessage
              {...messages.wallet_registration_success_modal_dialog_content_text_ihre_finledger_kennung_lautet}
            />{" "}
            {this.props.saveWalletResponse.digits_kennung}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.handleClose()}
          >
            <FormattedMessage
              {...messages.wallet_registration_success_modal_dialog_button_text_ok}
            />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalSaveWalletSuccessOpen: state.walletRegistrationReducer.modalSaveWalletSuccessOpen,
    saveWalletResponse: state.walletRegistrationReducer.saveWalletResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseSaveWalletSuccessModal: () => dispatch(saveWalletSuccessModalActions.close()),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WalletSuccessModal));
