export const GET_BUSINESS_PARTNERS_REQUEST = "GET_BUSINESS_PARTNERS_REQUEST";
export const GET_BUSINESS_PARTNERS_SUCCESS = "GET_BUSINESS_PARTNERS_SUCCESS";
export const GET_BUSINESS_PARTNERS_FAILURE = "GET_BUSINESS_PARTNERS_FAILURE";

export const GET_BUSINESS_PARTNER_INVITES_REQUEST = "GET_BUSINESS_PARTNER_INVITES_REQUEST";
export const GET_BUSINESS_PARTNER_INVITES_SUCCESS = "GET_BUSINESS_PARTNER_INVITES_SUCCESS";
export const GET_BUSINESS_PARTNER_INVITES_FAILURE = "GET_BUSINESS_PARTNER_INVITES_FAILURE";

export const GET_BP_PLATFORM_MEMBER_DETAILS_REQUEST = "GET_BP_PLATFORM_MEMBER_DETAILS_REQUEST";
export const GET_BP_PLATFORM_MEMBER_DETAILS_SUCCESS = "GET_BP_PLATFORM_MEMBER_DETAILS_SUCCESS";
export const GET_BP_PLATFORM_MEMBER_DETAILS_FAILURE = "GET_BP_PLATFORM_MEMBER_DETAILS_FAILURE";
export const GET_BP_PLATFORM_MEMBER_DETAILS_RETRY = "GET_BP_PLATFORM_MEMBER_DETAILS_RETRY";

export const GET_BP_PLATFORM_MEMBERS_REQUEST = "GET_BP_PLATFORM_MEMBERS_REQUEST";
export const GET_BP_PLATFORM_MEMBERS_SUCCESS = "GET_BP_PLATFORM_MEMBERS_SUCCESS";
export const GET_BP_PLATFORM_MEMBERS_FAILURE = "GET_BP_PLATFORM_MEMBERS_FAILURE";

export const FILTER_BP_PLATFORM_MEMBERS = "FILTER_BP_PLATFORM_MEMBERS";
export const RESET_BP_FILTER_PLATFORM_MEMBERS = "RESET_BP_FILTER_PLATFORM_MEMBERS";

export const INVITE_BUSINESS_PARTNER_SEND = "INVITE_BUSINESS_PARTNER_SEND";
export const INVITE_BUSINESS_PARTNER_SUCCESS = "INVITE_BUSINESS_PARTNER_SUCCESS";
export const INVITE_BUSINESS_PARTNER_FAILURE = "INVITE_BUSINESS_PARTNER_FAILURE";

export const ACCEPT_BUSINESS_PARTNER_REQUEST = "ACCEPT_BUSINESS_PARTNER_REQUEST";
export const ACCEPT_BUSINESS_PARTNER_SUCCESS = "ACCEPT_BUSINESS_PARTNER_SUCCESS";
export const ACCEPT_BUSINESS_PARTNER_FAILURE = "ACCEPT_BUSINESS_PARTNER_FAILURE";

export const REJECT_BUSINESS_PARTNER_REQUEST = "REJECT_BUSINESS_PARTNER_REQUEST";
export const REJECT_BUSINESS_PARTNER_SUCCESS = "REJECT_BUSINESS_PARTNER_SUCCESS";
export const REJECT_BUSINESS_PARTNER_FAILURE = "REJECT_BUSINESS_PARTNER_FAILURE";

export const OPEN_REJECT_REASON_DIALOG = "OPEN_REJECT_REASON_DIALOG";
export const CLOSE_REJECT_REASON_DIALOG = "CLOSE_REJECT_REASON_DIALOG";

export const EDIT_BUSINESSPARTNER_PARTNER_ID = "EDIT_BUSINESSPARTNER_PARTNER_ID";
export const SAVE_BUSINESSPARTNER_PARTNER_ID = "SAVE_BUSINESSPARTNER_PARTNER_ID";
export const CLOSE_EDIT_BUSINESSPARTNER_PARTNER_ID = "CLOSE_EDIT_BUSINESSPARTNER_PARTNER_ID";
export const EDIT_BUSINESSPARTNER_PARTNER_ID_SUCCESS = "EDIT_BUSINESSPARTNER_PARTNER_ID_SUCCESS";
export const EDIT_BUSINESSPARTNER_PARTNER_ID_FAILURE = "EDIT_BUSINESSPARTNER_PARTNER_ID_FAILURE";

export const ASSIGN_GP_TO_TRADE_SHOW = "ASSIGN_GP_TO_TRADE_SHOW";
export const ASSIGN_GP_TO_TRADE_OPEN = "ASSIGN_GP_TO_TRADE_REQUEST_OPEN";
export const ASSIGN_GP_TO_TRADE_CLOSE = "ASSIGN_GP_TO_TRADE_REQUEST_CLOSE";
export const ASSIGN_GP_TO_TRADE_REQUEST = "ASSIGN_GP_TO_TRADE_REQUEST";
export const ASSIGN_GP_TO_TRADE_FAILURE = "ASSIGN_GP_TO_TRADE_FAILURE";
export const ASSIGN_GP_TO_TRADE_SUCCESS = "ASSIGN_GP_TO_TRADE_SUCCESS";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const assignGpToTradeActions = {
  show: (trade) => action(ASSIGN_GP_TO_TRADE_SHOW, { trade }),
  open: (trade) => action(ASSIGN_GP_TO_TRADE_OPEN, { trade }),
  close: () => action(ASSIGN_GP_TO_TRADE_CLOSE, {}),
  request: (gp, trade) => action(ASSIGN_GP_TO_TRADE_REQUEST, { gp, trade }),
  failure: () => action(ASSIGN_GP_TO_TRADE_FAILURE, {}),
  success: () => action(ASSIGN_GP_TO_TRADE_SUCCESS, {}),
};

export const DISCONNECT_GP_AND_TRADE_REQUEST = "DISCONNECT_GP_AND_TRADE_REQUEST";
export const DISCONNECT_GP_AND_TRADE_FAILURE = "DISCONNECT_GP_AND_TRADE_FAILURE";
export const DISCONNECT_GP_AND_TRADE_SUCCESS = "DISCONNECT_GP_AND_TRADE_SUCCESS";

export const disconnectGPAndTradeActions = {
  request: (trade) => action(DISCONNECT_GP_AND_TRADE_REQUEST, { trade }),
  failure: () => action(DISCONNECT_GP_AND_TRADE_FAILURE, {}),
  success: () => action(DISCONNECT_GP_AND_TRADE_SUCCESS, {}),
};

export const rejectDialogActions = {
  open: (companyInfo) => action(OPEN_REJECT_REASON_DIALOG, { companyInfo }),
  close: () => action(CLOSE_REJECT_REASON_DIALOG, {}),
};

export const getPlatformMembersActions = {
  request: (idProxyAddr) => action(GET_BP_PLATFORM_MEMBERS_REQUEST, { idProxyAddr }),
  success: (platformMembers) => action(GET_BP_PLATFORM_MEMBERS_SUCCESS, { platformMembers }),
  failure: (error) => action(GET_BP_PLATFORM_MEMBERS_FAILURE, { error }),
};

export const getPlatformMemberDetailsActions = {
  request: () => action(GET_BP_PLATFORM_MEMBER_DETAILS_REQUEST),
  retry: (loadErrors, idProxyAddr) =>
    action(GET_BP_PLATFORM_MEMBER_DETAILS_RETRY, { loadErrors, idProxyAddr }),
  success: (details) => action(GET_BP_PLATFORM_MEMBER_DETAILS_SUCCESS, { details }),
  failure: (idProxyAddr) => action(GET_BP_PLATFORM_MEMBER_DETAILS_FAILURE, { idProxyAddr }),
};

export const getBusinessPartnersActions = {
  request: (idProxyAddr) => action(GET_BUSINESS_PARTNERS_REQUEST, { idProxyAddr }),
  success: (businessPartners) => action(GET_BUSINESS_PARTNERS_SUCCESS, { businessPartners }),
  failure: (error) => action(GET_BUSINESS_PARTNERS_FAILURE, { error }),
};

export const platformMemberFilterActions = {
  filter: (filter) => action(FILTER_BP_PLATFORM_MEMBERS, { filter }),
  resetFilter: () => action(RESET_BP_FILTER_PLATFORM_MEMBERS),
};

export const getBusinessPartnerInvitesActions = {
  request: (idProxyAddr) => action(GET_BUSINESS_PARTNER_INVITES_REQUEST, { idProxyAddr }),
  success: (businessPartnerInvites) =>
    action(GET_BUSINESS_PARTNER_INVITES_SUCCESS, { businessPartnerInvites }),
  failure: (error) => action(GET_BUSINESS_PARTNER_INVITES_FAILURE, { error }),
};

export const inviteBusinessPartnerActions = {
  request: (invitationData, idProxyAddr) =>
    action(INVITE_BUSINESS_PARTNER_SEND, { invitationData, idProxyAddr }),
  success: () => action(INVITE_BUSINESS_PARTNER_SUCCESS),
  failure: (error) => action(INVITE_BUSINESS_PARTNER_FAILURE, { error }),
};

export const acceptBusinessPartnerActions = {
  request: (bpInfo, idProxyAddr) =>
    action(ACCEPT_BUSINESS_PARTNER_REQUEST, { bpInfo, idProxyAddr }),
  success: () => action(ACCEPT_BUSINESS_PARTNER_SUCCESS),
  failure: (error) => action(ACCEPT_BUSINESS_PARTNER_FAILURE, { error }),
};

export const rejectBusinessPartnerActions = {
  request: (bpInfo, idProxyAddr, rejector, rejectReason) =>
    action(REJECT_BUSINESS_PARTNER_REQUEST, {
      bpInfo,
      idProxyAddr,
      rejector,
      rejectReason,
    }),
  success: () => action(REJECT_BUSINESS_PARTNER_SUCCESS),
  failure: (error) => action(REJECT_BUSINESS_PARTNER_FAILURE, { error }),
};

export const editBusinesspartnerPartnerId = {
  openModal: (partner) => action(EDIT_BUSINESSPARTNER_PARTNER_ID, { partner }),
  save: (pmIdProxyAddr, gpIdProxyAddr, partnerId) =>
    action(SAVE_BUSINESSPARTNER_PARTNER_ID, {
      pmIdProxyAddr,
      gpIdProxyAddr,
      partnerId,
    }),
  failure: () => action(EDIT_BUSINESSPARTNER_PARTNER_ID_FAILURE),
  success: () => action(EDIT_BUSINESSPARTNER_PARTNER_ID_SUCCESS),
  closeModal: () => action(CLOSE_EDIT_BUSINESSPARTNER_PARTNER_ID),
};
