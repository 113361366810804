import { sendSignedHttpRequest } from "util/requestSigning";
import { downloadFileFromBase64 } from "util/fileReader";

export function fetchMonitorInfo(arranger) {
  return sendSignedHttpRequest("get", `/digits-server/monitor/${arranger}`, arranger)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
    });
}

export function startPdfDownload(base64, fileName) {
  try {
    downloadFileFromBase64(base64, fileName, "application/pdf");
  } catch (error) {
    console.error(error);
  }
}
