import { PartialClaimState } from "./constants";
import { dateToTimestamp } from "./convertor";

export const SortType = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  DATE: "DATE",
};

export const useSorting = ({ sortCriteria }) => {
  return (a, b) => {
    switch (sortCriteria.sortType) {
      case SortType.DATE:
        return sortCriteria.isDescending
          ? dateToTimestamp(b.sortKey) - dateToTimestamp(a.sortKey)
          : dateToTimestamp(a.sortKey) - dateToTimestamp(b.sortKey);
      case SortType.NUMBER:
        return sortCriteria.isDescending ? b.sortKey - a.sortKey : a.sortKey - b.sortKey;
      case SortType.TEXT:
      default:
        a.sortKey = typeof a.sortKey === "string" ? a.sortKey : "";
        b.sortKey = typeof b.sortKey === "string" ? b.sortKey : "";
        return sortCriteria.isDescending
          ? b.sortKey.localeCompare(a.sortKey)
          : a.sortKey.localeCompare(b.sortKey);
    }
  };
};

// properties, die nur auf einem PartialClaim existieren
const isPartialClaim = (item) =>
  Object.prototype.hasOwnProperty.call(item, "id") &&
  Object.prototype.hasOwnProperty.call(item, "abgestimmterRestbestand");

export const useFilter = ({ filters, enumFilters, textFilters }) => {
  const nonEmptyFilters = Object.keys(filters).filter((key) => filters[key] != null);

  const matchesText = (filterKey, value) => {
    return (
      !filters[filterKey] ||
      (value &&
        String(value).toLowerCase().includes(String(filters[filterKey]).toLocaleLowerCase()))
    );
  };

  const filterFunction = (item) => {
    let retVal = true;
    if (filters.datumsfeld) {
      if (item[filters.datumsfeld] === null) {
        retVal = false;
      }
      if (filters.datumVon) {
        retVal = retVal && dateToTimestamp(item[filters.datumsfeld]) >= filters.datumVon / 1000;
      }
      if (filters.datumBis) {
        retVal = retVal && dateToTimestamp(item[filters.datumsfeld]) <= filters.datumBis / 1000;
      }
    }

    // stornierte Partial Claims können der ZS angezeigt werden, wenn Filter gesetzt sind - DN kann diese TFs nicht sehen, Filterung erfolgt im Backend
    if (!filters.geloescht && isPartialClaim(item)) {
      retVal = retVal && item.status !== PartialClaimState.CANCELLED;
    }

    if (filters.status === "status_cancelled" && isPartialClaim(item)) {
      retVal = item.status === PartialClaimState.CANCELLED;
    }

    nonEmptyFilters.forEach((filterField) => {
      if (textFilters.includes(filterField)) {
        retVal = retVal && matchesText(filterField, item[filterField]);
      } else if (enumFilters.includes(filterField)) {
        retVal = retVal && filters[filterField] === item[filterField];
      }
    });
    return retVal;
  };

  return filterFunction;
};
