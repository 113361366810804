import { takeLatest, call, put } from "redux-saga/effects";
import {
  sendOfferSSD,
  sendRejectSSD,
  sendAcceptSSD,
  sendCancelSSD,
  sendModifySSD,
  sendOfferSSDCancelConfirmation,
  sendAcceptSSDCancelConfirmation,
  sendModifyAndOfferSSD,
  sendSignDocument,
} from "services/web3Services/confirmationState";
import { toHex, toSha3, getHashFromData } from "services/web3Services/commons";
import {
  OFFER_SSD_REQUEST,
  SAVE_SSD_REQUEST,
  SUBMIT_SSD_REQUEST,
  ACCEPT_SSD_REQUEST,
  UPDATE_SSD_REQUEST,
  UPDATE_ACCEPT_SSD_REQUEST,
  CANCEL_SSD_REQUEST,
  REJECT_SSD_REQUEST,
  OFFER_SSD_CANCEL_CONFIRMATION_REQUEST,
  ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST,
  SIGN_DOCUMENT_REQUEST,
  DOCUSIGN_DOCUMENT_REQUEST,
  UPLOAD_SIGNED_DOCUMENT_REQUEST,
} from "./actions";
import {
  offerSsdCancelConfirmationActions,
  acceptSsdCancelConfirmationActions,
  acceptSsdActions,
  updateSsdActions,
  updateAndAcceptSsdActions,
  offerSsdActions,
  saveSsdActions,
  submitSsdActions,
  rejectSsdActions,
  cancelSsdActions,
  signDocumentActions,
  docuSignDocumentActions,
  uploadSignedDocumentActions,
} from "./actions";
import {
  confirmModalActions,
  editModalActions,
  signDocumentModalActions,
} from "redux/ssdEvents/actions";
import {
  postSSD,
  updateSSD,
  postRejectReason,
  signPdf,
  docuSignPdf,
  createPdf,
  uploadSignedDocument,
  checkDocumentForUpload,
  createTradeConfirmationPDF,
} from "services/ssdService";
import { sendMail } from "services/mailService";
import { usecase, ZERO_HASH, mailTemplates, zeroAddress } from "util/constants";
import { snackbarActions } from "redux/shared/actions";
import history from "util/history";
import { ab2str } from "services/web3Services/commons";
import { _base64ToArrayBuffer } from "util/convertor";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generateSsdFingerprint } from "util/fingerprint";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const ssdStatusSagas = [
  takeLatest(SAVE_SSD_REQUEST, saveSsd),
  takeLatest(SUBMIT_SSD_REQUEST, saveAndSubmitSsd),
  takeLatest(OFFER_SSD_REQUEST, handleOfferSsdRequest),
  takeLatest(ACCEPT_SSD_REQUEST, handleAcceptSsdRequest),
  takeLatest(UPDATE_SSD_REQUEST, handleUpdateSsdRequest),
  takeLatest(UPDATE_ACCEPT_SSD_REQUEST, handleUpdateAcceptSsdRequest),
  takeLatest(CANCEL_SSD_REQUEST, cancelSsd),
  takeLatest(REJECT_SSD_REQUEST, rejectSsd),
  takeLatest(OFFER_SSD_CANCEL_CONFIRMATION_REQUEST, handleOfferSsdCancelConfirmationRequest),
  takeLatest(ACCEPT_SSD_CANCEL_CONFIRMATION_REQUEST, handleAcceptSsdCancelConfirmationRequest),
  takeLatest(SIGN_DOCUMENT_REQUEST, handleSignDocumentRequest),
  takeLatest(DOCUSIGN_DOCUMENT_REQUEST, handleDocuSignDocumentRequest),
  takeLatest(UPLOAD_SIGNED_DOCUMENT_REQUEST, uploadSignedDocumentHandler),
];
//---------------------------------------------------------------------------
// This is used for manual signing
export function* uploadSignedDocumentHandler({
  account,
  pdfFile,
  ssdId,
  skipMetadataCheck,
  fingerprint,
  arranger,
}) {
  try {
    if (!skipMetadataCheck) {
      const { data } = yield call(checkDocumentForUpload, pdfFile, arranger);

      if (
        data &&
        (ssdId !== `${data.ssdid}` ||
          fingerprint !== data.fingerprint ||
          ZERO_HASH !== data.fingerprintRebuy)
      ) {
        yield put(
          uploadSignedDocumentActions.failure(
            appIntl().formatMessage(messages.redux_ssd_status_ungueltiges_dokument)
          )
        );
        yield put(
          snackbarActions.openError(
            appIntl().formatMessage(messages.redux_ssd_status_urkunde_nicht_angenommen) +
              " \n " +
              appIntl().formatMessage(
                messages.redux_ssd_status_das_hochgeladene_dokument_entspricht_nicht_dem_bereitgestellten_dokument
              )
          )
        );
        yield put(signDocumentModalActions.close());
      } else if (data && !data.signatureDataResponse.isValid) {
        yield put(
          uploadSignedDocumentActions.failure(
            appIntl().formatMessage(messages.redux_ssd_status_ungueltiges_dokument)
          )
        );
        yield put(
          snackbarActions.openError(
            appIntl().formatMessage(messages.redux_ssd_status_das_dokument_wurde_nicht_angenommen) +
              " \n " +
              appIntl().formatMessage(
                messages.redux_ssd_status_weil_es_mindestens_zwei_digitale_signaturen_beinhalten_muss
              )
          )
        );
        yield put(signDocumentModalActions.close());
      }
    }
    yield call(uploadSignedDocument, pdfFile, arranger, ssdId, fingerprint);

    const pdfBuffer = _base64ToArrayBuffer(pdfFile);
    const pdfString = ab2str(pdfBuffer);
    const signedHash = toSha3(pdfString);

    yield call(sendSignDocument, account, arranger, toHex(ssdId), fingerprint, signedHash);
    yield put(uploadSignedDocumentActions.success());
    yield put(signDocumentModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_signieren_erfolgreich)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(uploadSignedDocumentActions.failure(error));
    yield put(signDocumentModalActions.close());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_signieren_fehlgeschlagen)
      )
    );
  }
}
//---------------------------------------------------------------------------
// This is used for signing via sign API
export function* handleSignDocumentRequest({ account, arranger, id, fingerprint }) {
  try {
    yield call(createPdf, id, fingerprint, arranger);
    const { response, error } = yield call(signPdf, id, fingerprint, arranger);

    const pdfBuffer = _base64ToArrayBuffer(response.data);
    const pdfString = ab2str(pdfBuffer);
    const signedHash = toSha3(pdfString);

    if (response) {
      yield call(sendSignDocument, account, arranger, toHex(id), fingerprint, signedHash);
      yield put(signDocumentActions.success());
      yield put(signDocumentModalActions.close());
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_ssd_status_signieren_erfolgreich)
        )
      );
    } else {
      throw error;
    }
  } catch (error) {
    yield put(signDocumentActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_signieren_fehlgeschlagen)
      )
    );
  }
}
//---------------------------------------------------------------------------
// This is used for signing via DocuSign
export function* handleDocuSignDocumentRequest({ account, arranger, id, fingerprint }) {
  try {
    yield call(createPdf, id, fingerprint, arranger);
    const { response, error } = yield call(docuSignPdf, id, fingerprint, arranger);

    if (response) {
      yield put(docuSignDocumentActions.success());
      yield put(signDocumentModalActions.close());
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(
            messages.redux_ssd_status_signatur_wurde_angefragt_sie_werden_per_email_benachrichtigt
          )
        )
      );
    } else {
      throw error;
    }
  } catch (error) {
    yield put(docuSignDocumentActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_docusign_signieren_fehlgeschlagen)
      )
    );
  }
}
//---------------------------------------------------------------------------
export function* handleOfferSsdCancelConfirmationRequest({ account, id }) {
  try {
    yield call(sendOfferSSDCancelConfirmation, account, toHex(id));
    yield put(offerSsdCancelConfirmationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_bestaetigung_wurde_zurueckgenommen)
      )
    );
  } catch (error) {
    yield put(offerSsdCancelConfirmationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_status_bestaetigung_konnte_nicht_zurueckgenommen_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------
function* handleAcceptSsdCancelConfirmationRequest({ account, arranger, id }) {
  try {
    yield call(sendAcceptSSDCancelConfirmation, account, arranger, toHex(id));
    yield put(acceptSsdCancelConfirmationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_bestaetigung_wurde_zurueckgenommen)
      )
    );
  } catch (error) {
    yield put(acceptSsdCancelConfirmationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_status_bestaetigung_konnte_nicht_zurueckgenommen_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------
function* handleAcceptSsdRequest({ account, arranger, ssdData, fingerprint, isSecondConfirm, firstConfirmer }) {
  try {
    yield call(sendAcceptSSD, account, arranger, firstConfirmer, toHex(ssdData.ssdid), fingerprint);
    yield put(acceptSsdActions.success());
    yield put(confirmModalActions.close());
    if (isSecondConfirm) {
      yield call(createTradeConfirmationPDF, ssdData.ssdid, fingerprint, ZERO_HASH, arranger);

      yield call(sendMail, {
        template: mailTemplates.ssd_gegenbestaetigung_zwei,
        arranger: arranger,
        ssdid: ssdData.ssdid,
        fingerprint: fingerprint,
        fingerprint_rebuy: ZERO_HASH,
      });
    }
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_wurde_bestaetigt)
      )
    );
  } catch (error) {
    yield put(acceptSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnte_nicht_bestaetigt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
function* handleUpdateSsdRequest({ ssdData, bicOrIbanChanged }) {
  try {
    const withFingerprint = yield generateSsdFingerprint(ssdData);
    yield call(updateSSD, withFingerprint);

    yield put(updateSsdActions.success());
    yield put(confirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_wurde_aktualisiert)
      )
    );
  } catch (error) {
    yield put(acceptSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnte_nicht_aktualisiert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
function* handleUpdateAcceptSsdRequest({
  account,
  arranger,
  id,
  fingerprint,
  isSecondConfirm,
  firstConfirmer,
  ssdData,
  bicOrIbanChanged,
}) {
  try {
    yield call(updateSSD, ssdData);
    yield call(sendAcceptSSD, account, arranger, firstConfirmer, toHex(id), fingerprint);
    if (isSecondConfirm) {
      yield call(createTradeConfirmationPDF, id, fingerprint, ZERO_HASH, arranger);

      yield call(sendMail, {
        template: mailTemplates.ssd_gegenbestaetigung_zwei,
        arranger: arranger,
        ssdid: id,
        fingerprint,
        fingerprint_rebuy: ZERO_HASH,
      });
    }

    yield put(updateAndAcceptSsdActions.success());
    yield put(confirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_wurde_aktualisiert_und_bestaetigt)
      )
    );
  } catch (error) {
    yield put(acceptSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_status_ssd_konnte_nicht_aktualisiert_oder_bestaetigt_werden
        )
      )
    );
  }
}
//---------------------------------------------------------------------------

function* handleOfferSsdRequest({ account, ssdData, fingerprint, arranger, isSecondConfirm, firstConfirmer }) {
  try {
    yield call(sendOfferSSD, account, firstConfirmer, ssdData.ssdid, fingerprint);
    yield put(offerSsdActions.success());
    yield put(editModalActions.close());
    if (isSecondConfirm) {
      yield call(sendMail, {
        template: mailTemplates.ssd_bestaetigung_zwei,
        arranger: arranger,
        ssdid: ssdData.ssdid,
        fingerprint: fingerprint,
        fingerprint_rebuy: ZERO_HASH,
      });
    }
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_wurde_bestaetigt)
      )
    );
  } catch (error) {
    yield put(offerSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnte_nicht_bestaetigt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
//saves ssd in DB & blockchain (SSDState = 2 = IN_WORK, SSDPaymentState = 0 = INVALID)
export function* saveSsd({ account, ssdData }) {
  try {
    const withFingerprint = yield generateSsdFingerprint(ssdData);
    const { fingerprint } = withFingerprint;
    const response = yield call(postSSD, withFingerprint);
    if (ssdData.fingerprint !== fingerprint) {
      yield call(sendModifySSD, account, response.data.ssdId, ssdData, fingerprint);
    }
    yield put(saveSsdActions.success());
    yield put(editModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_gespeichert)
      )
    );
    history.push("/bssdoverview");
  } catch (error) {
    console.error(error);
    yield put(saveSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnten_nicht_gespeichert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
//saves ssd in DB & offers ssd to blockchain (SSDState = 3 = OPEN)
export function* saveAndSubmitSsd({ account, ssdData }) {
  try {
    const withFingerprint = yield generateSsdFingerprint(ssdData);
    const { fingerprint } = withFingerprint;

    const response = yield call(postSSD, withFingerprint);

    if (ssdData.fingerprint !== fingerprint)
      yield call(sendModifyAndOfferSSD, account, response.data.ssdId, ssdData, fingerprint);

    else yield call(sendOfferSSD, account, zeroAddress, response.data.ssdId, fingerprint);

    yield put(submitSsdActions.success());
    yield put(editModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_gespeichert_und_bestaetigt)
      )
    );
    history.push("/bssdoverview");
  } catch (error) {
    console.error(error);
    yield put(submitSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnten_nicht_gespeichert_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
//offers ssd to blockchain (SSDState = 3 = OPEN)

//reject ssd (SSDState = 2 = IN_WORK)
function* rejectSsd({ account, arranger, id, fingerprint, reason }) {
  try {
    yield call(postRejectReason, id, fingerprint, reason, usecase.REJECT, arranger);
    yield call(sendRejectSSD, account, arranger, toHex(id), fingerprint, getHashFromData(reason));
    yield put(rejectSsdActions.success());
    yield put(confirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(messages.redux_ssd_status_ssd_abgelehnt))
    );
  } catch (error) {
    console.error(error);
    yield put(rejectSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnte_nicht_abgelehnt_werden)
      )
    );
  }
}
//---------------------------------------------------------------------------
//cancels ssd (SSDState = 5 = CANCELLED)
function* cancelSsd({ account, id, fingerprint, reason, arranger }) {
  try {
    yield call(postRejectReason, id, fingerprint, reason, usecase.CANCEL, arranger);
    yield call(sendCancelSSD, account, toHex(id), fingerprint, getHashFromData(reason));
    yield put(cancelSsdActions.success());
    yield put(editModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_wurde_storniert)
      )
    );
  } catch (error) {
    yield put(cancelSsdActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_ssd_status_ssd_konnte_nicht_storniert_werden)
      )
    );
  }
}
