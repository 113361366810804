import {
  FETCH_RECOVERY_PLATFORM_MEMBER_SUCCESS,
  SELECT_COMPANY_FOR_RECOVERY,
  FETCH_EMPLOYEES_COMPANY_RECOVERY_SUCCESS,
  REQUEST_RECOVERY_FOR_WALLET_REQUEST,
  REQUEST_RECOVERY_FOR_WALLET_FAILURE,
  REQUEST_RECOVERY_FOR_WALLET_SUCCESS,
  RECOVERY_GRANT_UK_REQUEST,
  RECOVERY_GRANT_UK_FAILURE,
  RECOVERY_GRANT_UK_SUCCESS,
  INVITE_WALLET_FOR_RECOVERY_REQUEST,
  INVITE_WALLET_FOR_RECOVERY_FAILURE,
  INVITE_WALLET_FOR_RECOVERY_SUCCESS,
} from "./actions";

// reducer with initial state
const initialState = {
  recoveryCompanies: [],
  activeCompany: null,
  activeCompanyEmployees: [],
  isPending: false,
};

function fetchRecoveryPlatformMemberSuccessReducer(state, action) {
  return {
    ...state,
    recoveryCompanies: action.companies,
  };
}

function selectCompanyForRecoveryReducer(state, action) {
  const activeCompany = [...state.recoveryCompanies].find(
    (company) => company.info.idProxyAddress === action.companyIdProxy
  );
  return {
    ...state,
    activeCompany,
  };
}

function fetchCompanyEmployeesSuccessReducer(state, action) {
  return {
    ...state,
    activeCompanyEmployees: action.activeCompanyEmployees,
  };
}

function recoveryIsPendingRequestReducer(state) {
  return {
    ...state,
    isPending: true,
  };
}

function recoveryIsPendingSuccessReducer(state) {
  return {
    ...state,
    isPending: false,
  };
}

function recoveryIsPendingFailureReducer(state) {
  return {
    ...state,
    isPending: false,
  };
}

export function recoveryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECOVERY_PLATFORM_MEMBER_SUCCESS:
      return fetchRecoveryPlatformMemberSuccessReducer(state, action);
    case SELECT_COMPANY_FOR_RECOVERY:
      return selectCompanyForRecoveryReducer(state, action);
    case FETCH_EMPLOYEES_COMPANY_RECOVERY_SUCCESS:
      return fetchCompanyEmployeesSuccessReducer(state, action);

    case REQUEST_RECOVERY_FOR_WALLET_REQUEST:
      return recoveryIsPendingRequestReducer(state, action);
    case REQUEST_RECOVERY_FOR_WALLET_SUCCESS:
      return recoveryIsPendingSuccessReducer(state, action);
    case REQUEST_RECOVERY_FOR_WALLET_FAILURE:
      return recoveryIsPendingFailureReducer(state, action);

    case INVITE_WALLET_FOR_RECOVERY_REQUEST:
      return recoveryIsPendingRequestReducer(state, action);
    case INVITE_WALLET_FOR_RECOVERY_SUCCESS:
      return recoveryIsPendingSuccessReducer(state, action);
    case INVITE_WALLET_FOR_RECOVERY_FAILURE:
      return recoveryIsPendingFailureReducer(state, action);

    case RECOVERY_GRANT_UK_REQUEST:
      return recoveryIsPendingRequestReducer(state, action);
    case RECOVERY_GRANT_UK_SUCCESS:
      return recoveryIsPendingSuccessReducer(state, action);
    case RECOVERY_GRANT_UK_FAILURE:
      return recoveryIsPendingFailureReducer(state, action);

    default:
      return state;
  }
}
