import { CSSDFilterType } from "util/constants";

export const getAccount = (state) => state.signInReducer.account;
export const getIsLoading = (state) => state.ssdEventReducer.isLoading;
export const getAccountInfo = (state) => state.accountReducer.accountInfo;
export const getCompanyInfo = (state) => state.accountReducer.companyInfo;
export const getCompanyAddress = (state) =>
  state.accountReducer.companyInfo.idProxyAddress.toLowerCase();
export const getCssdRefId = (state) => state.cssdInformationsmaterialReducer.cssdRef.cssdId;
export const getCssdRefFingerprint = (state) =>
  state.cssdInformationsmaterialReducer.cssdRef.fingerprint;
export const getPrivileges = (state) => state.signInReducer.privileges;
export const getKennung = (state) => state.accountReducer.accountInfo.benutzerkennung;
export const hasStartedLoadingPartialClaims = (state) =>
  state.cssdTeilforderungenReducer.lastBlockNumber > 0;
export const isPartialClaimCached = (state, partialClaimId) =>
  [
    ...state.cssdTeilforderungenReducer.teilforderungen.sortable,
    ...state.cssdTeilforderungenReducer.teilforderungen.newData,
  ]
    .map((partialClaim) => partialClaim.id)
    .includes(partialClaimId);
export const isPartialClaimLoading = (state, partialClaimId) =>
  state.cssdTeilforderungenReducer.teilforderungen.idsForUpdate.includes(partialClaimId);
export const isCessionCached = (state, cessionId) =>
  state.cssdCessionReducer.cessionsForWork
    .map((cession) => cession.abtretungId)
    .includes(cessionId);
export const isZahlstelleSelector = (state) =>
  state.cssdEventReducer.filters.view === CSSDFilterType.ZAHLSTELLE;
export const isPartialClaimReferencedByCession = (state, partialClaimId) =>
  state.cssdCessionReducer.partialClaim?.id === partialClaimId;
export const isCSSDReferencedByCession = (state, cssdId) =>
  state.cssdCessionReducer.cssdRef?.cssdId === cssdId;
export const isCSSDReferencedByPartialClaims = (state, cssdId) =>
  state.cssdTeilforderungenReducer.cssdRef?.cssdId === cssdId;
export const hasAccessToCssd = (state, cssdId, idProxyAddress) => {
  return (
    [
      state.cssdTerminationReducer.cssdRef,
      state.cssdInformationsmaterialReducer.cssdRef,
      state.cssdCessionReducer.cssdRef,
      state.cssdTeilforderungenReducer.cssdRef,
      ...state.cssdEventReducer.cssds,
    ].filter(
      (cssd) =>
        cssd &&
        cssd.cssdId === cssdId &&
        (cssd.darlehensnehmer?.idProxyAdresse === idProxyAddress ||
          cssd.zahlstelle?.idProxyAdresse === idProxyAddress)
    ).length > 0
  );
};
