import { sendSignedHttpRequest } from "util/requestSigning";
import { getDetailsUrl } from "services/web3Services/commons";

export async function invite(invitationData, idProxyAddr) {
  const backendUrl = await getDetailsUrl(idProxyAddr);
  const data = {
    ...invitationData,
    backendUrl,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/onboarding/${idProxyAddr}/invite`,
    idProxyAddr,
    data
  );
}

export async function revokeInvite(email, idProxyAddr) {
  const data = {
    email,
  };
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/onboarding/${idProxyAddr}/invite/delete`,
    idProxyAddr,
    data
  );
}

export async function getPlatformMembers(idProxyAddr) {
  const requestHeader = {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
  };

  return sendSignedHttpRequest(
    "get",
    `/digits-server/onboarding/${idProxyAddr}/invite/pending`,
    idProxyAddr,
    null,
    requestHeader
  ).catch(function (error) {
    return error;
  });
}

const PLATFORM_MEMBER_CACHE = [];
export async function getPlatformMemberDetails(idProxyAddr) {
  for (let entry of PLATFORM_MEMBER_CACHE) {
    if (idProxyAddr === entry.idProxyAddr) {
      return entry.response;
    }
  }

  const requestHeader = {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
  };

  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddr}/public`,
    idProxyAddr,
    null,
    requestHeader
  )
    .then(function (response) {
      PLATFORM_MEMBER_CACHE.push({ idProxyAddr, response: { response } });
      return { response };
    })
    .catch(function (error) {
      return { error };
    });
}
