import { contractCache } from "services/smartContracts/smartContracts";

import { sendTransaction, getBSSDControllerContractMethods } from "./commons";

//
// PAYMENT STATE MACHINE
//

export async function sendModifySsdRebuy(account, ssdId, rebuyHash, rebuyMaturityDate) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .modifySSDRebuy(contractCache.identityProxy._address, ssdId, rebuyHash, rebuyMaturityDate)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendModifyAndOfferSsdRebuy(account, ssdId, rebuyHash, rebuyMaturityDate) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferSSDRebuy(
      contractCache.identityProxy._address,
      ssdId,
      rebuyHash,
      rebuyMaturityDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendOfferSsdRebuy(account, firstConfirmer, ssdId, rebuyHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .offerSSDRebuy(contractCache.identityProxy._address, firstConfirmer, ssdId, rebuyHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendCancelOfferSsdRebuy(account, ssdId) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .offerSSDRebuyCancelConfirmation(contractCache.identityProxy._address, ssdId)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendCancelSsdRebuy(account, ssdId, rebuyHash, reasonHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cancelSSDRebuy(contractCache.identityProxy._address, ssdId, rebuyHash, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendAcceptSsdRebuy(account, arranger, firstConfirmer, ssdId, rebuyHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.acceptSSDRebuy(arranger, firstConfirmer, ssdId, rebuyHash).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendCancelAcceptSsdRebuy(account, arranger, ssdId) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.acceptSSDRebuyCancelConfirmation(arranger, ssdId).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendRejectSsdRebuy(account, arranger, ssdId, rebuyHash, reasonHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.rejectSSDRebuy(arranger, ssdId, rebuyHash, reasonHash).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

//
// PAYMENT STATE MACHINE
//

export async function sendPaymentCorrection(account, ssdId, reasonHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .paymentCorrection(contractCache.identityProxy._address, ssdId, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendPaymentReceived(account, ssdId) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .paymentReceived(contractCache.identityProxy._address, ssdId)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendRepaymentReceived(account, arranger, ssdId) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.repaymentReceived(arranger, ssdId).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}
