import React, { Component } from "react";
import { SSDState, SSDPaymentState, SSDRebuyState, SSDTerminationState } from "util/constants";
import { withStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Close";
import NewIcon from "@material-ui/icons/FiberNew";
import WorkIcon from "@material-ui/icons/Work";
import { checkifMaturityDateInPast } from "util/dateInPast";
import { getIdProxyAddr } from "services/web3Services/commons";
import { messages } from "./messages";
import { appIntl } from "components/i18n/intl";
import { SSDStatus } from "util/constants";
import ColoredChip from "components/Shared/Chip/ColoredChip";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
});

export const getSsdState = ({
  state,
  paymentState,
  rebuyState,
  maturityDate,
  rebuyMaturityDate,
  ssdRejected,
  rebuyRejected,
  terminationState,
  terminationDate,
  created_by_cession,
  terminationInitiator,
}) => {
  if (
    terminationState === SSDTerminationState.IN_WORK &&
    terminationInitiator === getIdProxyAddr()
  ) {
    return SSDStatus.Kuendigung_erfasst;
  }
  if (terminationState === SSDTerminationState.APPROVED) {
    return SSDStatus.gekuendigt;
  }
  if (state === SSDState.INVALID && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.Initial;
  }
  if (state === SSDState.NEW && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.Neu;
  }
  if (state === SSDState.IN_WORK && rebuyState === SSDRebuyState.INVALID && ssdRejected) {
    return SSDStatus.Abgelehnt;
  }
  if (state === SSDState.IN_WORK && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.Erfasst;
  }
  if (state === SSDState.OPEN && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.In_Pruefung;
  }
  if (state === SSDState.APPROVED && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.bestaetigt;
  }
  if (state === SSDState.CANCELLED && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.Storniert;
  }
  if (paymentState === SSDPaymentState.REPAID) {
    return SSDStatus.Zurueckgezahlt;
  }
  if (
    state === SSDState.DOCUMENT_SIGNED &&
    rebuyState === SSDRebuyState.INVALID &&
    !checkifMaturityDateInPast(maturityDate)
  ) {
    return `${
      created_by_cession
        ? SSDStatus.Abtretungszertifikat_ausgestellt
        : SSDStatus.Urkunde_ausgestellt
    }`;
  }
  if (
    state === SSDState.DOCUMENT_SIGNED &&
    (checkifMaturityDateInPast(maturityDate) ||
      (terminationState === SSDTerminationState.APPROVED &&
        checkifMaturityDateInPast(terminationDate)) ||
      (rebuyState === SSDRebuyState.APPROVED && checkifMaturityDateInPast(rebuyMaturityDate)))
  ) {
    return SSDStatus.Endfaellig;
  }
  if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.CANCELLED) {
    return SSDStatus.Rueckkauf_storniert;
  }

  if (state === SSDState.PREPARED_REST) {
    return SSDStatus.Angezeigt;
  }
  if (state === SSDState.EXTERNALLY_SETTLED && rebuyState === SSDRebuyState.INVALID) {
    return SSDStatus.Externes_Abtretungszertifikat_ausgestellt;
  }
  if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.IN_WORK && rebuyRejected) {
    return SSDStatus.Rueckkauf_abgelehnt;
  }
  if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.IN_WORK) {
    return SSDStatus.Rueckkauf_erfasst;
  }
  if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.OPEN) {
    return SSDStatus.Rueckkauf_in_Pruefung;
  }
  if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.APPROVED) {
    return SSDStatus.Rueckkauf_bestaetigt;
  }
};

export const getSsdLabelText = (code) => {
  return code
    ? appIntl().formatMessage(
        messages[`BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_${code}`]
      )
    : "";
};

class SsdStatusChip extends Component {
  getIconColor = (
    state,
    paymentState,
    rebuyState,
    maturityDate,
    rebuyMaturityDate,
    ssdRejected,
    rebuyRejected,
    terminationState,
    terminationDate
  ) => {
    if (terminationState === SSDTerminationState.IN_WORK) {
      return "default";
    }
    if (
      terminationState === SSDTerminationState.APPROVED ||
      state === SSDState.CANCELLED ||
      rebuyState === SSDRebuyState.CANCELLED ||
      ssdRejected ||
      rebuyRejected
    ) {
      return "error";
    }
    if (paymentState === SSDPaymentState.REPAID) {
      return "success";
    }
    if (
      state === SSDState.DOCUMENT_SIGNED &&
      (checkifMaturityDateInPast(maturityDate) ||
        checkifMaturityDateInPast(terminationDate) ||
        (rebuyState === SSDRebuyState.APPROVED && checkifMaturityDateInPast(rebuyMaturityDate)))
    ) {
      return "success";
    }
    if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.APPROVED) {
      return "success";
    }
    return "default";
  };

  getSsdIcon = (
    state,
    paymentState,
    rebuyState,
    maturityDate,
    rebuyMaturityDate,
    ssdRejected,
    rebuyRejected,
    terminationState,
    terminationDate
  ) => {
    if (terminationState === SSDTerminationState.IN_WORK) {
      return <WorkIcon />;
    }
    if (
      terminationState === SSDTerminationState.APPROVED ||
      state === SSDState.CANCELLED ||
      rebuyState === SSDRebuyState.CANCELLED ||
      ssdRejected ||
      rebuyRejected
    ) {
      return <CancelIcon />;
    }
    if (state === SSDState.NEW) {
      return <NewIcon />;
    }
    if (paymentState === SSDPaymentState.REPAID) {
      return <DoneIcon />;
    }
    if (
      state === SSDState.DOCUMENT_SIGNED &&
      (checkifMaturityDateInPast(maturityDate) ||
        checkifMaturityDateInPast(terminationDate) ||
        (rebuyState === SSDRebuyState.APPROVED && checkifMaturityDateInPast(rebuyMaturityDate)))
    ) {
      return <DoneIcon />;
    }
    if (state === SSDState.DOCUMENT_SIGNED && rebuyState === SSDRebuyState.APPROVED) {
      return <DoneIcon />;
    }
    return <WorkIcon />;
  };

  render() {
    const {
      classes,
      state,
      paymentState,
      rebuyState,
      maturityDate,
      rebuyMaturityDate,
      ssdRejected,
      rebuyRejected,
      terminationState,
      terminationDate,
    } = this.props;

    const ssdState = getSsdState({ ...this.props });

    const label = getSsdLabelText(ssdState);

    return (
      <ColoredChip
        className={classes.chip}
        label={label}
        icon={this.getSsdIcon(
          state,
          paymentState,
          rebuyState,
          maturityDate,
          rebuyMaturityDate,
          ssdRejected,
          rebuyRejected,
          terminationState,
          terminationDate
        )}
        color={this.getIconColor(
          state,
          paymentState,
          rebuyState,
          maturityDate,
          rebuyMaturityDate,
          ssdRejected,
          rebuyRejected,
          terminationState,
          terminationDate
        )}
      />
    );
  }
}

export default withStyles(styles)(SsdStatusChip);
