import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cssd_save_success: {
    id: "sagas.cssd_save_success",
    defaultMessage: "CSSD gespeichert.",
  },
  cssd_save_failure: {
    id: "sagas.cssd_save_failure",
    defaultMessage: "CSSD konnte nicht gespeichert werden.",
  },
  cssd_save_and_confirm_success: {
    id: "sagas.cssd_save_and_confirm_success",
    defaultMessage: "CSSD gespeichert und bestätigt.",
  },
  cssd_save_and_confirm_failure: {
    id: "sagas.cssd_save_and_confirm_failure",
    defaultMessage: "CSSD konnte nicht gespeichert werden.",
  },
  css_confirm_cssd_success: {
    id: "sagas.css_confirm_cssd_success",
    defaultMessage: "CSSD wurde bestätigt.",
  },
  css_confirm_cssd_failure: {
    id: "sagas.css_confirm_cssd_failure",
    defaultMessage: "CSSD konnte nicht bestätigt werden.",
  },
  css_accept_cssd_success: {
    id: "sagas.css_accept_cssd_success",
    defaultMessage: "CSSD wurde bestätigt.",
  },
  css_accept_cssd_failure: {
    id: "sagas.css_accept_cssd_failure",
    defaultMessage: "CSSD konnte nicht bestätigt werden.",
  },
  css_reject_cssd_success: {
    id: "sagas.css_reject_cssd_success",
    defaultMessage: "CSSD wurde abgelehnt.",
  },
  css_reject_cssd_failure: {
    id: "sagas.css_reject_cssd_failure",
    defaultMessage: "CSSD konnte nicht abgelehnt werden.",
  },
  css_cancel_cssd_success: {
    id: "sagas.css_cancel_cssd_success",
    defaultMessage: "CSSD wurde storniert.",
  },
  css_cancel_cssd_failure: {
    id: "sagas.css_cancel_cssd_failure",
    defaultMessage: "CSSD konnte nicht storniert werden.",
  },
  css_upload_document_failure: {
    id: "sagas.upload_document_failure",
    defaultMessage: "Beim Hochladen ist ein Fehler aufgetreten.",
  },
  css_upload_document_missing_signature_zs: {
    id: "sagas.css_upload_document_missing_signature_zs",
    defaultMessage:
      "Dokumente wurden nicht angenommen, \n da jeweils mindestens zwei unterschiedliche digitale Signaturen benötigt werden.",
  },
  css_upload_document_missing_signature_dn: {
    id: "sagas.css_upload_document_missing_signature_dn",
    defaultMessage:
      "Dokumente wurden nicht angenommen, \n da jeweils insgesamt mindestens vier unterschiedliche digitale Signaturen benötigt werden.",
  },
  css_upload_document_success: {
    id: "sagas.upload_document_success",
    defaultMessage: "Hochladen erfolgreich",
  },
  css_download_document_failure: {
    id: "sagas.css_download_document_failure",
    defaultMessage: "Herunterladen fehlgeschlagen",
  },
  cssd_repayment_received_success: {
    id: "sagas.cssd.repayment.received.success",
    defaultMessage: "Rückzahlung wurde erfolgreich veranlasst.",
  },
  cssd_repayment_received_failure: {
    id: "sagas.cssd.repayment.received.failure",
    defaultMessage: "Rückzahlung konnte nicht veranlasst werden.",
  },
  cssd_settlement_information_success: {
    id: "sagas.cssd.settlement.information.success",
    defaultMessage: "Abwicklungsinformationen wurden erfolgreich gespeichert.",
  },
  cssd_settlement_information_failure: {
    id: "sagas.cssd.settlement.information.failure",
    defaultMessage: "Abwicklungsinformationen konnten nicht gespeichert werden.",
  },
  cssd_documents_first_accept_success: {
    id: "sagas.cssd.documents.first.accept.success",
    defaultMessage: "Urkunde und Zahlstellenvertrag wurden erfolgreich erstbestätigt.",
  },
  cssd_documents_second_accept_success: {
    id: "sagas.cssd.documents.second.accept.success",
    defaultMessage:
      "Urkunde und Zahlstellenvertrag wurden erfolgreich bestätigt. Das CSSD gilt nun als signiert.",
  },
  cssd_documents_accept_failure: {
    id: "sagas.cssd.documents.accept.failure",
    defaultMessage:
      "Es ist ein Fehler aufgetreten. Urkunde und Zahlstellenvertrag konnten nicht bestätigt werden.",
  },
  cssd_documents_reject_success: {
    id: "sagas.cssd.documents.reject.success",
    defaultMessage: "Urkunde und Zahlstellenvertrag wurden erfolgreich abgelehnt.",
  },
  cssd_documents_reject_failure: {
    id: "sagas.cssd.documents.reject.failure",
    defaultMessage:
      "Es ist ein Fehler aufgetreten. Urkunde und Zahlstellenvertrag konnten nicht abgelehnt werden.",
  },
});
