import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_colleague_einladung_wurde_zurueckgezogen: {
    id: "redux.colleague.einladung.wurde.zurueckgezogen",
    defaultMessage: "Einladung wurde zurückgezogen.",
  },
  redux_colleague_einladung_konnte_nicht_zurueckgezogen_werden: {
    id: "redux.colleague.einladung.konnte.nicht.zurueckgezogen.werden",
    defaultMessage: "Einladung konnte nicht zurückgezogen werden.",
  },
  redux_colleague_benutzer_wurde_entfernt: {
    id: "redux.colleague.benutzer.wurde.entfernt",
    defaultMessage: "Benutzer wurde entfernt.",
  },
  redux_colleague_fehler_beim_entfernen_des_benutzers: {
    id: "redux.colleague.fehler.beim.entfernen.des.benutzers",
    defaultMessage: "Fehler beim Entfernen des Benutzers.",
  },
  redux_colleague_berechtigungen_wurden_angepasst: {
    id: "redux.colleague.berechtigungen.wurden.angepasst",
    defaultMessage: "Berechtigungen wurden angepasst.",
  },
  redux_colleague_fehler_beim_anpassen_der_berechtigungen: {
    id: "redux.colleague.fehler.beim.anpassen.der.berechtigungen",
    defaultMessage: "Fehler beim Anpassen der Berechtigungen.",
  },
  redux_colleague_einladung_wurde_versendet: {
    id: "redux.colleague.einladung.wurde.versendet",
    defaultMessage: "Einladung wurde versendet.",
  },
  redux_colleague_diese_email_wurde_bereits_erfolgreich_eingeladen: {
    id: "redux.colleague.diese.email.wurde.bereits.erfolgreich.eingeladen",
    defaultMessage: "Diese E-Mail wurde bereits erfolgreich eingeladen.",
  },
  redux_colleague_einladung_fehlgeschlagen: {
    id: "redux.colleague.einladung.fehlgeschlagen",
    defaultMessage: "Einladung fehlgeschlagen!",
  },
});
