export const snackbarStyles = (theme) => ({
  success: {
    color: theme.palette.success.main,
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.success.light : theme.palette.background.paper,
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.error.light : theme.palette.background.paper,
  },
  info: {
    color: theme.palette.info.dark,
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.info.light : theme.palette.background.paper,
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.warning.light : theme.palette.background.paper,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    whiteSpace: "pre-line",
  },
});
