import {
  OPEN_CREATE_INTEREST_NOTIFICATION_MODAL,
  CLOSE_CREATE_INTEREST_NOTIFICATION_MODAL,
  SET_SSD_INFO_FOR_INTEREST,
  FETCH_INTEREST_NOTIFICATIONS_SUCCESS,
  FETCH_INTEREST_NOTIFICATION_DETAILS_SUCCESS,
  INTEREST_NOTIFICATION_EDIT_MODAL_OPEN,
  INTEREST_NOTIFICATION_EDIT_MODAL_CLOSE,
  UPDATE_INTEREST_NOTIFICAION_FOUR_EYES,
  UPDATE_INTEREST_NOTIFICAION_STATE,
  ADD_INTEREST_NOTIFICATION_FROM_EVENT,
  CLOSE_INTEREST_NOTIFICATION_MODAL_OPEN,
  CLOSE_INTEREST_NOTIFICATION_MODAL_CLOSE,
  CREATE_INTEREST_NOTIFICATION_REQUEST,
  CREATE_INTEREST_NOTIFICATION_SUCCESS,
  CREATE_INTEREST_NOTIFICATION_FAILURE,
  CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST,
  CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_SUCCESS,
  CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_FAILURE,
  MODIFY_INTEREST_NOTIFICATION_REQUEST,
  MODIFY_INTEREST_NOTIFICATION_SUCCESS,
  MODIFY_INTEREST_NOTIFICATION_FAILURE,
  MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST,
  MODIFY_AND_SAVE_INTEREST_NOTIFICATION_SUCCESS,
  MODIFY_AND_SAVE_INTEREST_NOTIFICATION_FAILURE,
  REJECT_INTEREST_NOTIFICATION_REQUEST,
  REJECT_INTEREST_NOTIFICATION_SUCCESS,
  REJECT_INTEREST_NOTIFICATION_FAILURE,
  CANCEL_INTEREST_NOTIFICATION_REQUEST,
  CANCEL_INTEREST_NOTIFICATION_SUCCESS,
  CANCEL_INTEREST_NOTIFICATION_FAILURE,
  CLOSE_INTEREST_NOTIFICATION_REQUEST,
  CLOSE_INTEREST_NOTIFICATION_SUCCESS,
  CLOSE_INTEREST_NOTIFICATION_FAILURE,
  CONFIRM_INTEREST_NOTIFICATION_REQUEST,
  CONFIRM_INTEREST_NOTIFICATION_SUCCESS,
  CONFIRM_INTEREST_NOTIFICATION_FAILURE,
  CANCEL_INTEREST_NOTIFICATION_MODAL_OPEN,
  CANCEL_INTEREST_NOTIFICATION_MODAL_CLOSE,
} from "./actions";
import { toUtf8 } from "services/web3Services/commons";
import { zeroAddress, FourEyesState } from "util/constants";

// reducer with initial state
const initialState = {
  interestNotifications: [],
  currentInterestNotification: {},
  ssdInfoForInterest: {},
  createInterestNotificationModalOpen: false,
  interestNotificationEditModalOpen: false,
  closeInterestNotificationModalopen: false,
  cancelInterestNotificationModalopen: false,
  //progress inidcator
  saveInterestCessionPending: false,
  saveAndConfirmInterestCessionPending: false,
  modifyInterestCessionPending: false,
  modifyAndConfirmInterestCessionPending: false,
  cancelInterestNotificationPending: false,
  rejectInterestNotificationPending: false,
  closeInterestNotificationPending: false,
  confirmInterestNotificationPending: false,
};

function confirmInterestNotificationRequestReducer(state) {
  return {
    ...state,
    confirmInterestNotificationPending: true,
  };
}

function confirmInterestNotificationFailureReducer(state) {
  return {
    ...state,
    confirmInterestNotificationPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function confirmInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    confirmInterestNotificationPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function closeInterestNotificationRequestReducer(state) {
  return {
    ...state,
    closeInterestNotificationPending: true,
  };
}

function closeInterestNotificationFailureReducer(state) {
  return {
    ...state,
    closeInterestNotificationPending: false,
    closeInterestNotificationModalopen: false,
  };
}

function closeInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    closeInterestNotificationPending: false,
    closeInterestNotificationModalopen: false,
  };
}

function cancelInterestNotificationRequestReducer(state) {
  return {
    ...state,
    cancelInterestNotificationPending: true,
  };
}

function cancelInterestNotificationFailureReducer(state) {
  return {
    ...state,
    cancelInterestNotificationPending: false,
    cancelInterestNotificationModalopen: false,
    interestNotificationEditModalOpen: false,
  };
}

function cancelInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    cancelInterestNotificationPending: false,
    cancelInterestNotificationModalopen: false,
    interestNotificationEditModalOpen: false,
  };
}

function rejectInterestNotificationRequestReducer(state) {
  return {
    ...state,
    rejectInterestNotificationPending: true,
  };
}

function rejectInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    rejectInterestNotificationPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function rejectInterestNotificationFailureReducer(state) {
  return {
    ...state,
    rejectInterestNotificationPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function modifyAndSaveInterestNotificationRequestReducer(state) {
  return {
    ...state,
    modifyAndConfirmInterestCessionPending: true,
  };
}

function modifyAndSaveInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    modifyAndConfirmInterestCessionPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function modifyAndSaveInterestNotificationFailureReducer(state) {
  return {
    ...state,
    modifyAndConfirmInterestCessionPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function modifyInterestNotificationRequestReducer(state) {
  return {
    ...state,
    modifyInterestCessionPending: true,
  };
}

function modifyInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    modifyInterestCessionPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function modifyInterestNotificationFailureReducer(state) {
  return {
    ...state,
    modifyInterestCessionPending: false,
    interestNotificationEditModalOpen: false,
  };
}

function createAndConfirmInterestNotificationRequestReducer(state) {
  return {
    ...state,
    saveAndConfirmInterestCessionPending: true,
  };
}

function createAndConfirmInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    saveAndConfirmInterestCessionPending: false,
    createInterestNotificationModalOpen: false,
    currentInterestNotification: {},
  };
}

function createAndConfirmInterestNotificationFailureReducer(state) {
  return {
    ...state,
    saveAndConfirmInterestCessionPending: false,
    createInterestNotificationModalOpen: false,
    currentInterestNotification: {},
  };
}

function createInterestNotificationRequestReducer(state) {
  return {
    ...state,
    saveInterestCessionPending: true,
  };
}

function createInterestNotificationSuccessReducer(state) {
  return {
    ...state,
    saveInterestCessionPending: false,
    createInterestNotificationModalOpen: false,
    currentInterestNotification: {},
  };
}

function createInterestNotificationFailureReducer(state) {
  return {
    ...state,
    saveInterestCessionPending: false,
    createInterestNotificationModalOpen: false,
    currentInterestNotification: {},
  };
}

function openCloseInterestNotificationModal(state, action) {
  return {
    ...state,
    closeInterestNotificationModalopen: true,
    currentInterestNotification: action.interestData,
  };
}

function closeCloseInterestNotificationModal(state) {
  return {
    ...state,
    closeInterestNotificationModalopen: false,
  };
}

function openCancelInterestNotificationModal(state, action) {
  return {
    ...state,
    cancelInterestNotificationModalopen: true,
    currentInterestNotification: action.interestData,
  };
}

function closeCancelInterestNotificationModal(state) {
  return {
    ...state,
    cancelInterestNotificationModalopen: false,
  };
}

function openCreateInterestNotificationModal(state) {
  return {
    ...state,
    createInterestNotificationModalOpen: true,
  };
}

function closeCreateInterestNotificationModal(state) {
  return {
    ...state,
    createInterestNotificationModalOpen: false,
  };
}

function setSsdInforForInterestReducer(state, action) {
  return {
    ...state,
    ssdInfoForInterest: action.ssdInfo,
  };
}

function fetchInterestNotificationSuccessReducer(state, action) {
  return {
    ...state,
    interestNotifications: action.interestNotifications,
  };
}

function updateInterestNotificationDetails(interestNotifications, details) {
  return [...interestNotifications].map((entry) => {
    if (toUtf8(entry.id) === `${details.zinsmitteilungid}`) {
      return {
        ...entry,
        ...details,
      };
    }
    return entry;
  });
}

function fetchInterestNotificationDetailsSuccess(state, action) {
  const updatedArr = updateInterestNotificationDetails(
    state.interestNotifications,
    action.interestNotificationDetails
  );
  return { ...state, interestNotifications: updatedArr };
}

function openInterestNotificationEditModalReducer(state, action) {
  return {
    ...state,
    interestNotificationEditModalOpen: true,
    currentInterestNotification: action.interestData,
  };
}

function closeInterestNotificationEditModalReducer(state) {
  return {
    ...state,
    interestNotificationEditModalOpen: false,
    currentInterestNotification: {},
  };
}

function updateStatusInArr(arr, state, hash, id) {
  return [...arr].map((entry) => {
    if (id === entry.id) {
      return { ...entry, state, hash };
    }
    return entry;
  });
}

function updateStatusReducer(state, action) {
  if (toUtf8(action.ssdId) === state.ssdInfoForInterest.ssdid) {
    const interestNotifications = updateStatusInArr(
      state.interestNotifications,
      action.state,
      action.hash,
      action.id
    );
    return {
      ...state,
      interestNotifications,
    };
  }
  return { ...state };
}

function updateFourEyesStatusInArr(arr, state, id, firstConfirmer) {
  return [...arr].map((entry) => {
    if (id === entry.id) {
      if (
        state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED ||
        state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY
      ) {
        return { ...entry, fourEyesState: state, firstConfirmer: zeroAddress };
      }
      return { ...entry, fourEyesState: state, firstConfirmer };
    }
    return entry;
  });
}

function updateFourEyesStatusReducer(state, action) {
  if (toUtf8(action.ssdId) === state.ssdInfoForInterest.ssdid) {
    const interestNotifications = updateFourEyesStatusInArr(
      state.interestNotifications,
      action.state,
      action.id,
      action.firstConfirmer
    );
    return {
      ...state,
      interestNotifications,
    };
  }
  return { ...state };
}

function addInterestNotificationReducer(state, action) {
  const interestNotifications = [...state.interestNotifications];

  const hitsInArr = [...state.interestNotifications].filter(
    (x) => x.id === action.interestNotificationId
  );

  if (hitsInArr.length === 0 && toUtf8(action.ssdId) === state.ssdInfoForInterest.ssdid) {
    const newInterestNotification = {
      ssdId: toUtf8(action.ssdId),
      id: action.interestNotificationId,
      hash: action.interestNotificationHash,
      fingerprint: action.interestNotificationHash,
    };
    interestNotifications.push(newInterestNotification);
  }
  return {
    ...state,
    interestNotifications,
  };
}

export function interestNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CREATE_INTEREST_NOTIFICATION_MODAL:
      return openCreateInterestNotificationModal(state, action);
    case CLOSE_CREATE_INTEREST_NOTIFICATION_MODAL:
      return closeCreateInterestNotificationModal(state, action);
    case SET_SSD_INFO_FOR_INTEREST:
      return setSsdInforForInterestReducer(state, action);
    case FETCH_INTEREST_NOTIFICATIONS_SUCCESS:
      return fetchInterestNotificationSuccessReducer(state, action);
    case FETCH_INTEREST_NOTIFICATION_DETAILS_SUCCESS:
      return fetchInterestNotificationDetailsSuccess(state, action);
    case INTEREST_NOTIFICATION_EDIT_MODAL_OPEN:
      return openInterestNotificationEditModalReducer(state, action);
    case INTEREST_NOTIFICATION_EDIT_MODAL_CLOSE:
      return closeInterestNotificationEditModalReducer(state, action);
    case UPDATE_INTEREST_NOTIFICAION_STATE:
      return updateStatusReducer(state, action);
    case UPDATE_INTEREST_NOTIFICAION_FOUR_EYES:
      return updateFourEyesStatusReducer(state, action);
    case ADD_INTEREST_NOTIFICATION_FROM_EVENT:
      return addInterestNotificationReducer(state, action);
    case CLOSE_INTEREST_NOTIFICATION_MODAL_OPEN:
      return openCloseInterestNotificationModal(state, action);
    case CLOSE_INTEREST_NOTIFICATION_MODAL_CLOSE:
      return closeCloseInterestNotificationModal(state, action);
    case CREATE_INTEREST_NOTIFICATION_REQUEST:
      return createInterestNotificationRequestReducer(state, action);
    case CREATE_INTEREST_NOTIFICATION_SUCCESS:
      return createInterestNotificationSuccessReducer(state, action);
    case CREATE_INTEREST_NOTIFICATION_FAILURE:
      return createInterestNotificationFailureReducer(state, action);
    case CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST:
      return createAndConfirmInterestNotificationRequestReducer(state, action);
    case CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_SUCCESS:
      return createAndConfirmInterestNotificationSuccessReducer(state, action);
    case CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_FAILURE:
      return createAndConfirmInterestNotificationFailureReducer(state, action);
    case MODIFY_INTEREST_NOTIFICATION_REQUEST:
      return modifyInterestNotificationRequestReducer(state, action);
    case MODIFY_INTEREST_NOTIFICATION_SUCCESS:
      return modifyInterestNotificationSuccessReducer(state, action);
    case MODIFY_INTEREST_NOTIFICATION_FAILURE:
      return modifyInterestNotificationFailureReducer(state, action);
    case MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST:
      return modifyAndSaveInterestNotificationRequestReducer(state, action);
    case MODIFY_AND_SAVE_INTEREST_NOTIFICATION_SUCCESS:
      return modifyAndSaveInterestNotificationSuccessReducer(state, action);
    case MODIFY_AND_SAVE_INTEREST_NOTIFICATION_FAILURE:
      return modifyAndSaveInterestNotificationFailureReducer(state, action);
    case REJECT_INTEREST_NOTIFICATION_REQUEST:
      return rejectInterestNotificationRequestReducer(state, action);
    case REJECT_INTEREST_NOTIFICATION_SUCCESS:
      return rejectInterestNotificationSuccessReducer(state, action);
    case REJECT_INTEREST_NOTIFICATION_FAILURE:
      return rejectInterestNotificationFailureReducer(state, action);
    case CANCEL_INTEREST_NOTIFICATION_REQUEST:
      return cancelInterestNotificationRequestReducer(state, action);
    case CANCEL_INTEREST_NOTIFICATION_SUCCESS:
      return cancelInterestNotificationSuccessReducer(state, action);
    case CANCEL_INTEREST_NOTIFICATION_FAILURE:
      return cancelInterestNotificationFailureReducer(state, action);
    case CLOSE_INTEREST_NOTIFICATION_REQUEST:
      return closeInterestNotificationRequestReducer(state, action);
    case CLOSE_INTEREST_NOTIFICATION_FAILURE:
      return closeInterestNotificationFailureReducer(state, action);
    case CLOSE_INTEREST_NOTIFICATION_SUCCESS:
      return closeInterestNotificationSuccessReducer(state, action);
    case CONFIRM_INTEREST_NOTIFICATION_REQUEST:
      return confirmInterestNotificationRequestReducer(state, action);
    case CONFIRM_INTEREST_NOTIFICATION_FAILURE:
      return confirmInterestNotificationFailureReducer(state, action);
    case CONFIRM_INTEREST_NOTIFICATION_SUCCESS:
      return confirmInterestNotificationSuccessReducer(state, action);
    case CANCEL_INTEREST_NOTIFICATION_MODAL_OPEN:
      return openCancelInterestNotificationModal(state, action);
    case CANCEL_INTEREST_NOTIFICATION_MODAL_CLOSE:
      return closeCancelInterestNotificationModal(state, action);
    default:
      return state;
  }
}
