import { all, call, takeLatest, put, select } from "redux-saga/effects";

import {
  INVITE_COLLEAGUE_SEND,
  GET_COLLEAGUES_REQUEST,
  GET_COLLEAGUES_PERMISSIONS_REQUEST,
  GRANT_REVOKE_PRIVILEGES_REQUEST,
  DEACTIVATE_USER_REQUEST,
  REVOKE_INVITE_COLLEAGUE_REQUEST,
  CREATE_TECHNICALUSER,
} from "./actions";

import {
  inviteColleagueActions,
  getColleaguesActions,
  colleaguePermissionActions,
  deactivateUserActions,
} from "./actions";

import {
  PRIVILEGE_CONTROLLER_BSSD_OPERATIONS,
  PRIVILEGE_CONTROLLER_ZAHLSTELLE,
  PRIVILEGE_CONTROLLER_CSSD_OPERATIONS,
  PRIVILEGE_CONTROLLER_COORDINATOR,
  PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS,
  PRIVILEGE_CONTROLLER_READ_ONLY,
} from "util/constants";

import {
  invite,
  getColleagues,
  activateColleague,
  deactivateColleague,
  deleteColleague,
  revokeInvite,
  saveTechnicalUser,
} from "services/colleagueService";

import { evictWalletPermissionCache } from "services/permissionCacheService";

import { snackbarActions } from "redux/shared/actions";

import { grantRevokePrivilegeActions, revokeColleagueInviteActions } from "redux/colleague/actions";

import { permissions } from "util/constants";

import { checkPrivilege, grantRevokePrivileges } from "services/web3Services/privileges";

import { requestEtherForWallet } from "services/web3Services/faucet";
import { getIdProxyAddr, getDetailsUrl } from "services/web3Services/commons";
import { generateWalletInIE, generateWallet } from "services/web3Services/login";
import { startWalletDownload } from "redux/signIn/sagas";
import { isIE } from "util/isIEHelper";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { getAccountActions } from "redux/account/actions";
import { getAccountInfo } from "redux/selectors";
import { partnerCache } from "services/partnerService";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const colleagueSagas = [
  takeLatest(INVITE_COLLEAGUE_SEND, inviteService),
  takeLatest(GET_COLLEAGUES_REQUEST, fetchColleagues),
  takeLatest(GET_COLLEAGUES_PERMISSIONS_REQUEST, getColleaguePermissions),
  takeLatest(GRANT_REVOKE_PRIVILEGES_REQUEST, grantRevokeColleaguePrivileges),
  takeLatest(DEACTIVATE_USER_REQUEST, deactivateUser),
  takeLatest(REVOKE_INVITE_COLLEAGUE_REQUEST, revokeMitarbeiterInvite),
  takeLatest(CREATE_TECHNICALUSER, createTechnicalUser),
];

export function* revokeMitarbeiterInvite({ email, idProxyAddr }) {
  try {
    yield put(revokeColleagueInviteActions.success());
    yield call(revokeInvite, email, idProxyAddr);
    yield call(fetchColleagues, { idProxyAddr: getIdProxyAddr() });
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_colleague_einladung_wurde_zurueckgezogen)
      )
    );
  } catch (error) {
    yield put(revokeColleagueInviteActions.error());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_colleague_einladung_konnte_nicht_zurueckgezogen_werden
        )
      )
    );
  }
}

function* formatActivePermissions(walletAddress) {
  let privilegeIds = [];
  let granted = [];
  const result = yield all(
    permissions.map((permission) => {
      return call(checkPrivilege, permission.id, walletAddress);
    })
  );
  result.forEach(function (entry) {
    if (entry[Object.keys(entry)]) {
      privilegeIds = [...privilegeIds, Number(Object.keys(entry)[0])];
      granted = [...granted, !entry[Object.keys(entry)]];
    }
  });
  return { privilegeIds, granted };
}

export function* deactivateUser({ account, walletAddress }) {
  try {
    const myIdProxyAddr = getIdProxyAddr();
    const permsToRevoke = yield call(formatActivePermissions, walletAddress);
    if (permsToRevoke.privilegeIds.length >= 1) {
      yield call(
        grantRevokePrivileges,
        account,
        permsToRevoke.privilegeIds,
        permsToRevoke.granted,
        walletAddress
      );
    }
    yield call(deleteColleague, walletAddress, myIdProxyAddr);
    yield put(deactivateUserActions.deactivateUserSuccess());
    yield call(fetchColleagues, { idProxyAddr: myIdProxyAddr });
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_colleague_benutzer_wurde_entfernt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(deactivateUserActions.deactivateUserFailure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_colleague_fehler_beim_entfernen_des_benutzers)
      )
    );
  }
}

export function getIdForPermission(key) {
  let index = permissions.findIndex((x) => x.name === key);
  return permissions[index].id;
}

function splitPermissionsObj(obj) {
  const privilegeIds = [];
  const granted = [];
  for (let key in obj) {
    privilegeIds.push(getIdForPermission(key));
    granted.push(obj[key]);
  }
  return {
    privilegeIds,
    granted,
  };
}

export function comparePermissions(newPermissions, currentPermissions) {
  const permission = { ...newPermissions };
  Object.keys(permission).forEach(function (key) {
    if (permission[key] === currentPermissions[key]) {
      delete permission[key];
    }
  });
  return permission;
}

export function checkIfColleagueIsActive(activeArr) {
  return [...activeArr].includes(true);
}

export function* grantRevokeColleaguePrivileges({
  account,
  walletAddress,
  newPermissions,
  currentPermissions,
}) {
  const permissions = comparePermissions(newPermissions, currentPermissions);
  const changedPerm = splitPermissionsObj(permissions);
  const newPerm = splitPermissionsObj(newPermissions);
  const colleagueIsActive = checkIfColleagueIsActive(newPerm.granted);
  try {
    if (changedPerm.privilegeIds.length > 0) {
      const idProxyAddr = getIdProxyAddr();
      yield call(
        grantRevokePrivileges,
        account,
        changedPerm.privilegeIds,
        changedPerm.granted,
        walletAddress
      );
      if (colleagueIsActive) {
        yield call(activateColleague, walletAddress, idProxyAddr);
      } else {
        yield call(deactivateColleague, walletAddress, idProxyAddr);
      }

      for (const member of partnerCache) {
        if (member.idProxyAddress){
          yield call(evictWalletPermissionCache, member.idProxyAddress, walletAddress);
        }
      }

      try {
        yield call(requestEtherForWallet, account, walletAddress, getIdProxyAddr());
      } catch (error) {
        console.error("could not refuel wallet!", error);
      }
      yield call(fetchColleagues, { idProxyAddr: getIdProxyAddr() });
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_colleague_berechtigungen_wurden_angepasst)
        )
      );
    }
    yield put(grantRevokePrivilegeActions.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_colleague_fehler_beim_anpassen_der_berechtigungen)
      )
    );
    yield put(grantRevokePrivilegeActions.failure(error));
  }
}

export function getActiveFromPermissionsArray(arr, key) {
  let index = arr.findIndex((x) => Object.prototype.hasOwnProperty.call(x, key));
  return arr[index][key];
}

export function* getColleaguePermissions({ walletAddress }) {
  try {
    const result = yield all(
      permissions.map((permission) => {
        return call(checkPrivilege, permission.id, walletAddress);
      })
    );
    const perm = {
      PRIVILEGE_CONTROLLER_BSSD_OPERATIONS: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_BSSD_OPERATIONS
      ),
      PRIVILEGE_CONTROLLER_CSSD_OPERATIONS: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_CSSD_OPERATIONS
      ),
      PRIVILEGE_CONTROLLER_COORDINATOR: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_COORDINATOR
      ),
      PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS
      ),
      PRIVILEGE_CONTROLLER_READ_ONLY: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_READ_ONLY
      ),
      PRIVILEGE_CONTROLLER_ZAHLSTELLE: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_CONTROLLER_ZAHLSTELLE
      ),
    };
    yield put(colleaguePermissionActions.success(walletAddress, perm));
  } catch (error) {
    console.error(error);
  }
}

export function* inviteService({ invitationData }) {
  try {
    const response = yield call(invite, invitationData);

    if (response.status >= 200 && response.status < 300) {
      yield put(inviteColleagueActions.success());
      yield call(fetchColleagues, invitationData);
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_colleague_einladung_wurde_versendet)
        )
      );
    } else {
      throw response;
    }
  } catch (err) {
    if (err.response.status === 403)
      yield put(
        snackbarActions.open(
          appIntl().formatMessage(
            messages.redux_colleague_diese_email_wurde_bereits_erfolgreich_eingeladen
          )
        )
      );
    else
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.redux_colleague_einladung_fehlgeschlagen)
        )
      );
    yield put(inviteColleagueActions.failure(err));
  }
}

function compareColleagues(a, b) {
  if (a.nachname < b.nachname) return -1;
  if (a.nachname > b.nachname) return 1;
  return 0;
}

const filterActiveColleague = (colleague) =>
  colleague.status_email === "ACTIVE" ||
  colleague.status_email === "INACTIVE" ||
  colleague.status_email === "PENDING";

export function* fetchColleagues({ idProxyAddr }) {
  try {
    const colleagues = yield call(getColleagues, idProxyAddr);
    const activeColleagues = colleagues.data.filter(filterActiveColleague).map((colleague) => {
      return {
        ...colleague,
        technischeUser: colleague.technischeUser.filter(filterActiveColleague),
      };
    });
    const sorted = activeColleagues.sort(compareColleagues);
    yield put(getColleaguesActions.success(sorted));
  } catch (err) {
    yield put(getColleaguesActions.failure(err));
  }
}

const generateSuffix = (numericValue) => {
  if (numericValue < 10) {
    return "-00" + numericValue;
  } else if (numericValue < 100) {
    return "-0" + numericValue;
  }
  return "-" + numericValue;
};
export function* createTechnicalUser({ password, account }) {
  try {
    let wallet;
    if (isIE()) {
      wallet = yield call(generateWalletInIE, password);
    } else {
      wallet = yield call(generateWallet, password);
    }

    const accountInfo = yield select(getAccountInfo);
    const idProxyAddr = getIdProxyAddr();
    const backendUrl = yield call(getDetailsUrl, idProxyAddr);

    yield call(saveTechnicalUser, idProxyAddr, {
      benutzerkennung:
        accountInfo.benutzerkennung + generateSuffix(accountInfo.technischeUser.length + 1),
      walletAdresse: "0x" + wallet.address,
      walletAdresseUnternehmenskoordinator: account.address,

      vorname: accountInfo.vorname,
      nachname: accountInfo.nachname,
      anrede: accountInfo.anrede,
      titel: accountInfo.titel,
      email: accountInfo.email,
      backendUrl: backendUrl,
    });
    yield put(inviteColleagueActions.success());
    yield put(getAccountActions.request(account.address, idProxyAddr));
    yield put(getColleaguesActions.request(idProxyAddr));
    yield call(startWalletDownload, wallet);
  } catch (e) {
    console.error(e);
  }
}
