import { sendSignedHttpRequest } from "util/requestSigning";
import { clearInterestNumberFormat, addInterestNumberFormat } from "util/convertor";
import { startPdfDownload } from "services/commonService";

export async function postInterestNotification(arranger, ssdId, interestData) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/zinsmitteilung`,
    arranger,
    clearInterestNumberFormat(interestData)
  ).then(function (response) {
    return response.data;
  });
}

export async function getInterestNotificationDetails(arranger, ssdId, zinsmitteilungid) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${ssdId}/zinsmitteilung/${zinsmitteilungid}`,
    arranger
  ).then(function (response) {
    return addInterestNumberFormat(response.data);
  });
}

export async function getZinsmitteilungsPdf(
  arranger,
  ssdId,
  fingerprint,
  fingerprintRebuy,
  zinsmitteilungid
) {
  const data = {
    fingerprint,
    fingerprintRebuy,
    zinsmitteilungid,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/zinsmitteilung/pdf`,
    arranger,
    data
  ).then(function (response) {
    startPdfDownload(response.data.base64EncodedZinsmitteilung, response.data.dateiName);
  });
}

export async function createZinsmitteilungsPdf(
  arranger,
  ssdId,
  fingerprint,
  fingerprintRebuy,
  zinsmitteilungid
) {
  const data = { fingerprint, fingerprintRebuy, zinsmitteilungid };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/zinsmitteilung/pdf`,
    arranger,
    data
  )
    .then((response) => response)
    .catch(() => ({}));
}
