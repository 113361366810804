import { contractCache } from "services/smartContracts/smartContracts";

import { sendTransaction, toHex, getBSSDControllerContractMethods } from "./commons";

import { dateToTimestamp } from "util/convertor";
//
// CONFIRMATION STATE MACHINE
//

export async function sendSignDocument(account, arranger, ssdId, ssdFingerprint, signedHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .signDocument(arranger, ssdId, ssdFingerprint, signedHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendModifySSD(account, id, ssdData, ssdHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .modifySSD(
      contractCache.identityProxy._address,
      toHex(`${id}`),
      ssdHash,
      ssdData.darlehensgeber,
      dateToTimestamp(ssdData.valuta),
      dateToTimestamp(ssdData.endfaelligkeit)
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendOfferSSD(account, firstConfirmer, ssdId, ssdFingerprint) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .offerSSD(contractCache.identityProxy._address, firstConfirmer, toHex(`${ssdId}`), ssdFingerprint)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendModifyAndOfferSSD(account, id, ssdData, ssdHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferSSD(
      contractCache.identityProxy._address,
      toHex(`${id}`),
      ssdHash,
      ssdData.darlehensgeber,
      dateToTimestamp(ssdData.valuta),
      dateToTimestamp(ssdData.endfaelligkeit)
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendOfferSSDCancelConfirmation(account, id) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .offerSSDCancelConfirmation(contractCache.identityProxy._address, id)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendAcceptSSDCancelConfirmation(account, arranger, id) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.acceptSSDCancelConfirmation(arranger, id).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendRejectSSD(account, arranger, ssdId, ssdFingerprint, reasonFingerprint) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .rejectSSD(arranger, ssdId, ssdFingerprint, reasonFingerprint)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendAcceptSSD(account, arranger, firstConfirmer, ssdId, ssdFingerprint) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods.acceptSSD(arranger, firstConfirmer, ssdId, ssdFingerprint).encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function sendCancelSSD(account, ssdId, ssdFingerprint, reasonFingerprint) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cancelSSD(contractCache.identityProxy._address, ssdId, ssdFingerprint, reasonFingerprint)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}
