import {
  GET_ACCOUNT_SUCCESS,
  GET_COMPANY_SUCCESS,
  UPDATE_PLATFORMMEMBER_INFO_REQUEST,
  UPDATE_PLATFORMMEMBER_INFO_FAILURE,
  UPDATE_PLATFORMMEMBER_INFO_SUCCESS,
  FETCH_PLATFORMMEMBER_DRAFT_SUCCESS,
  ACCEPT_PLATFORMMEMBER_INFO_REQUEST,
  ACCEPT_PLATFORMMEMBER_INFO_SUCCESS,
  ACCEPT_PLATFORMMEMBER_INFO_FAILURE,
  REJECT_PLATFORMMEMBER_INFO_REQUEST,
  REJECT_PLATFORMMEMBER_INFO_SUCCESS,
  REJECT_PLATFORMMEMBER_INFO_FAILURE,
  MEMBER_DATA_REJECT_EVENT,
  MEMBER_DATA_ACCEPT_EVENT,
  OPEN_MEMBER_INFO_EDIT_DIALOG,
  CLOSE_MEMBER_INFO_EDIT_DIALOG,
  OPEN_EDIT_ADDRESS_BOOK_DIALOG,
  CLOSE_EDIT_ADDRESS_BOOK_DIALOG,
  GET_ADDRESS_BOOK_ENTRY_REQUEST,
  GET_ADDRESS_BOOK_ENTRY_SUCCESS,
  GET_ADDRESS_BOOK_ENTRY_FAILURE,
  GET_ADDRESS_BOOK_ENTRIES_REQUEST,
  GET_ADDRESS_BOOK_ENTRIES_SUCCESS,
  GET_ADDRESS_BOOK_ENTRIES_FAILURE,
  DELETE_ADDRESS_BOOK_ENTRY_REQUEST,
  DELETE_ADDRESS_BOOK_ENTRY_SUCCESS,
  DELETE_ADDRESS_BOOK_ENTRY_FAILURE,
  OPEN_DELETE_ADDRESS_BOOK_ENTRY_DIALOG,
  CLOSE_DELETE_ADDRESS_BOOK_ENTRY_DIALOG,
  SAVE_ADDRESS_BOOK_ENTRY_SUCCESS,
  SAVE_ADDRESS_BOOK_ENTRY_REQUEST,
  SAVE_ADDRESS_BOOK_ENTRY_FAILURE,
} from "./actions";

// reducer with initial state
const initialState = {
  accountInfo: {},
  companyInfo: {},
  updateCompanyInfoPending: false,
  acceptCompanyInfoPending: false,
  rejectCompanyInfoPending: false,
  companyInfoDraft: {},
  draftFirstConfirmer: "",
  editCompanyDataDialogOpen: false,
  editAddressBookEntry: null,
  getAddressBookEntryPending: false,
  addressBookEntries: {},
  getAddressBookEntriesPending: false,
  deleteAddressBookEntryPending: false,
  deleteAddressBookEntry: null,
  saveAddressBookEntryPending: false,
};

function fetchAccountSuccessReducer(state, action) {
  return {
    ...state,
    accountInfo: action.accountInfo,
  };
}

function fetchCompanySuccessReducer(state, action) {
  return {
    ...state,
    companyInfo: {
      ...state.companyInfo,
      ...action.companyInfo,
    },
  };
}

function updatePlatformMemberInfoRequestReducer(state) {
  return {
    ...state,
    updateCompanyInfoPending: true,
  };
}

function updatePlatformMemberInfoSuccessReducer(state) {
  return {
    ...state,
    updateCompanyInfoPending: false,
    editCompanyDataDialogOpen: false,
  };
}

function updatePlatformMemberInfoFailureReducer(state) {
  return {
    ...state,
    updateCompanyInfoPending: false,
  };
}

function acceptPlatformMemberInfoRequestReducer(state) {
  return {
    ...state,
    acceptCompanyInfoPending: true,
  };
}

function acceptPlatformMemberInfoSuccessReducer(state) {
  return {
    ...state,
    acceptCompanyInfoPending: false,
    editCompanyDataDialogOpen: false,
  };
}

function acceptPlatformMemberInfoFailureReducer(state) {
  return {
    ...state,
    acceptCompanyInfoPending: false,
  };
}

function rejectPlatformMemberInfoRequestReducer(state) {
  return {
    ...state,
    rejectCompanyInfoPending: true,
  };
}

function rejectPlatformMemberInfoSuccessReducer(state) {
  return {
    ...state,
    rejectCompanyInfoPending: false,
    editCompanyDataDialogOpen: false,
  };
}

function rejectPlatformMemberInfoFailureReducer(state) {
  return {
    ...state,
    rejectCompanyInfoPending: false,
  };
}

function fetchPlatformmemberDraftSuccess(state, action) {
  return {
    ...state,
    companyInfoDraft: action.draft,
    draftFirstConfirmer: action.firstConfirmer,
  };
}

function resetDraftConfirmationReducer(state) {
  return {
    ...state,
    companyInfoDraft: {},
    draftFirstConfirmer: "",
  };
}

function openMemberInfoEditDialogReducer(state) {
  return {
    ...state,
    editCompanyDataDialogOpen: true,
  };
}

function closeMemberInfoEditDialogReducer(state) {
  return {
    ...state,
    editCompanyDataDialogOpen: false,
  };
}

function openEditAddressBookDialogReducer(state, action) {
  return {
    ...state,
    editAddressBookEntry: action.data,
  };
}

function closeEditAddressBookDialogReducer(state) {
  return {
    ...state,
    editAddressBookEntry: null,
  };
}

function getAddressBookEntryRequestReducer(state) {
  return {
    ...state,
    getAddressBookEntryPending: true,
  };
}

function getAddressBookEntrySuccessReducer(state) {
  return {
    ...state,
    getAddressBookEntryPending: false,
  };
}

function getAddressBookEntryFailureReducer(state) {
  return {
    ...state,
    getAddressBookEntryPending: false,
  };
}

function getAddressBookEntriesRequestReducer(state) {
  return {
    ...state,
    getAddressBookEntriesPending: true,
  };
}

function getAddressBookEntriesSuccessReducer(state, action) {
  return {
    ...state,
    addressBookEntries: action.data,
    getAddressBookEntriesPending: false,
  };
}

function getAddressBookEntriesFailureReducer(state) {
  return {
    ...state,
    getAddressBookEntriesPending: false,
  };
}

function deleteAddressBookEntryRequestReducer(state) {
  return {
    ...state,
    deleteAddressBookEntryPending: true,
  };
}

function deleteAddressBookEntrySuccessReducer(state, action) {
  const { [action.entryId]: toRemove, ...entries } = state.addressBookEntries;
  return {
    ...state,
    deleteAddressBookEntryPending: false,
    addressBookEntries: entries,
  };
}

function deleteAddressBookEntryFailureReducer(state) {
  return {
    ...state,
    deleteAddressBookEntryPending: false,
  };
}

function openDeleteAddressBookEntryDialogReducer(state, action) {
  return {
    ...state,
    deleteAddressBookEntry: action.data,
  };
}

function closeDeleteAddressBookEntryDialogReducer(state) {
  return {
    ...state,
    deleteAddressBookEntry: null,
  };
}
function saveAddressBookEntryRequestReducer(state) {
  return {
    ...state,
    saveAddressBookEntryPending: true,
  };
}

function saveAddressBookEntrySuccessReducer(state) {
  return {
    ...state,
    saveAddressBookEntryPending: false,
  };
}

function saveAddressBookEntryFailureReducer(state) {
  return {
    ...state,
    saveAddressBookEntryPending: false,
  };
}

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_SUCCESS:
      return fetchAccountSuccessReducer(state, action);
    case GET_COMPANY_SUCCESS:
      return fetchCompanySuccessReducer(state, action);
    case UPDATE_PLATFORMMEMBER_INFO_REQUEST:
      return updatePlatformMemberInfoRequestReducer(state, action);
    case UPDATE_PLATFORMMEMBER_INFO_SUCCESS:
      return updatePlatformMemberInfoSuccessReducer(state, action);
    case UPDATE_PLATFORMMEMBER_INFO_FAILURE:
      return updatePlatformMemberInfoFailureReducer(state, action);
    case FETCH_PLATFORMMEMBER_DRAFT_SUCCESS:
      return fetchPlatformmemberDraftSuccess(state, action);
    case ACCEPT_PLATFORMMEMBER_INFO_REQUEST:
      return acceptPlatformMemberInfoRequestReducer(state, action);
    case ACCEPT_PLATFORMMEMBER_INFO_SUCCESS:
      return acceptPlatformMemberInfoSuccessReducer(state, action);
    case ACCEPT_PLATFORMMEMBER_INFO_FAILURE:
      return acceptPlatformMemberInfoFailureReducer(state, action);
    case REJECT_PLATFORMMEMBER_INFO_REQUEST:
      return rejectPlatformMemberInfoRequestReducer(state, action);
    case REJECT_PLATFORMMEMBER_INFO_SUCCESS:
      return rejectPlatformMemberInfoSuccessReducer(state, action);
    case REJECT_PLATFORMMEMBER_INFO_FAILURE:
      return rejectPlatformMemberInfoFailureReducer(state, action);
    case MEMBER_DATA_ACCEPT_EVENT:
      return resetDraftConfirmationReducer(state, action);
    case MEMBER_DATA_REJECT_EVENT:
      return resetDraftConfirmationReducer(state, action);
    case OPEN_MEMBER_INFO_EDIT_DIALOG:
      return openMemberInfoEditDialogReducer(state, action);
    case CLOSE_MEMBER_INFO_EDIT_DIALOG:
      return closeMemberInfoEditDialogReducer(state, action);
    case OPEN_EDIT_ADDRESS_BOOK_DIALOG:
      return openEditAddressBookDialogReducer(state, action);
    case CLOSE_EDIT_ADDRESS_BOOK_DIALOG:
      return closeEditAddressBookDialogReducer(state);
    case GET_ADDRESS_BOOK_ENTRY_REQUEST:
      return getAddressBookEntryRequestReducer(state);
    case GET_ADDRESS_BOOK_ENTRY_SUCCESS:
      return getAddressBookEntrySuccessReducer(state);
    case GET_ADDRESS_BOOK_ENTRY_FAILURE:
      return getAddressBookEntryFailureReducer(state);
    case GET_ADDRESS_BOOK_ENTRIES_REQUEST:
      return getAddressBookEntriesRequestReducer(state);
    case GET_ADDRESS_BOOK_ENTRIES_SUCCESS:
      return getAddressBookEntriesSuccessReducer(state, action);
    case GET_ADDRESS_BOOK_ENTRIES_FAILURE:
      return getAddressBookEntriesFailureReducer(state);
    case DELETE_ADDRESS_BOOK_ENTRY_REQUEST:
      return deleteAddressBookEntryRequestReducer(state);
    case DELETE_ADDRESS_BOOK_ENTRY_SUCCESS:
      return deleteAddressBookEntrySuccessReducer(state, action);
    case DELETE_ADDRESS_BOOK_ENTRY_FAILURE:
      return deleteAddressBookEntryFailureReducer(state);
    case OPEN_DELETE_ADDRESS_BOOK_ENTRY_DIALOG:
      return openDeleteAddressBookEntryDialogReducer(state, action);
    case CLOSE_DELETE_ADDRESS_BOOK_ENTRY_DIALOG:
      return closeDeleteAddressBookEntryDialogReducer(state);
    case SAVE_ADDRESS_BOOK_ENTRY_REQUEST:
      return saveAddressBookEntryRequestReducer(state);
    case SAVE_ADDRESS_BOOK_ENTRY_SUCCESS:
      return saveAddressBookEntrySuccessReducer(state);
    case SAVE_ADDRESS_BOOK_ENTRY_FAILURE:
      return saveAddressBookEntryFailureReducer(state);

    default:
      return state;
  }
}
