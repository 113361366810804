import { defineMessages } from "react-intl";

export const messages = defineMessages({
  wallet_registration_success_modal_dialog_title_wichtiger_hinweis: {
    id: "wallet.registration.success.modal.dialog.title.wichtiger.hinweis",
    defaultMessage: "Wichtiger Hinweis",
  },
  wallet_registration_success_modal_dialog_content_text_anmeldung_erfolgreich: {
    id: "wallet.registration.success.modal.dialog.content.text.anmeldung_erfolgreich",
    defaultMessage: "Anmeldung erfolgreich.",
  },
  wallet_registration_success_modal_dialog_content_text_ihre_finledger_kennung_lautet: {
    id: "wallet.registration.success.modal.dialog.content.text.ihre_finledger_kennung_lautet",
    defaultMessage: "Ihre Finledger-Kennung lautet:",
  },
  wallet_registration_success_modal_dialog_button_text_ok: {
    id: "wallet.registration.success.modal.dialog.button.text.ok",
    defaultMessage: "OK",
  },
});
