import { defineMessages } from "react-intl";

export const messages = defineMessages({
  no_description_found: {
    id: "cssd.audit.no.description.found",
    defaultMessage: "NO DESCRIPTION",
  },
  reDocumented: {
    id: "cssd.audit.redocumented",
    defaultMessage: "Nachdokumentierte ",
  },
  no_name_found: {
    id: "cssd.audit.no.name.found",
    defaultMessage: "n.A.",
  },
  notShown: {
    id: "cssd.audit.not.shown",
    defaultMessage: "Änderungen zu Kurs und Gegenwert werden nicht angezeigt.",
  },
  fetch_history_error: {
    id: "cssd.audit.fetch.history.error",
    defaultMessage: "Audit konnte nicht geladen werden.",
  },
  extern: {
    id: "cssd.audit.extern",
    defaultMessage: "Extern",
  },
  suffix_nachdokumentiert: {
    id: "cssd.audit.suffix.nachdokumentiert",
    defaultMessage: " (Nachdokumentation)",
  },
});

export const fourEyesStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.fourEyes.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.fourEyes.OFFER_FIRST",
    defaultMessage: "CSSD 1. Bestätigung",
  },
  2: {
    id: "cssd.audit.fourEyes.OFFER_SECOND",
    defaultMessage: "CSSD 2. Bestätigung",
  },
  3: {
    id: "cssd.audit.fourEyes.OFFER_CANCELLED",
    defaultMessage: "Bestätigung zurückgezogen ",
  },
  4: {
    id: "cssd.audit.fourEyes.OFFER_CANCELLED_ON_MODIFY",
    defaultMessage: "Bestätigung zurückgezogen ",
  },
  5: {
    id: "cssd.audit.fourEyes.ACCEPT_FIRST",
    defaultMessage: "CSSD 1. Gegenbestätigung",
  },
  6: {
    id: "cssd.audit.fourEyes.ACCEPT_SECOND",
    defaultMessage: "CSSD 2. Gegenbestätigung",
  },
  7: {
    id: "cssd.audit.fourEyes.ACCEPT_CANCELLED",
    defaultMessage: "Gegenbestätigung zurückgezogen",
  },
  8: {
    id: "cssd.audit.fourEyes.ACCEPT_CANCELLED_ON_REJECT",
    defaultMessage: "Gegenbestätigung zurückgezogen",
  },
  17: {
    id: "cssd.audit.fourEyes.CESSION_OFFER_FIRST",
    defaultMessage: "Übertragung 1. Bestätigung ",
  },
  18: {
    id: "cssd.audit.fourEyes.CESSION_OFFER_SECOND",
    defaultMessage: "Übertragung 2. Bestätigung",
  },
  19: {
    id: "cssd.audit.fourEyes.CESSION_OFFER_CANCELLED",
    defaultMessage: "Übertragung Bestätigung zurückgezogen",
  },
  20: {
    id: "cssd.audit.fourEyes.CESSION_OFFER_CANCELLED_ON_MODIFY",
    defaultMessage: "Übertragung Bestätigung zurückgezogen",
  },
  21: {
    id: "cssd.audit.fourEyes.CESSION_ACCEPT_FIRST",
    defaultMessage: "Übertragung 1. Gegenbestätigung",
  },
  22: {
    id: "cssd.audit.fourEyes.CESSION_ACCEPT_SECOND",
    defaultMessage: "Übertragung 2. Gegenbestätigung",
  },
  23: {
    id: "cssd.audit.fourEyes.CESSION_ACCEPT_CANCELLED",
    defaultMessage: "Übertragung Gegenbestätigung zurückgezogen",
  },
  24: {
    id: "cssd.audit.fourEyes.CESSION_ACCEPT_CANCELLED_ON_REJECT",
    defaultMessage: "Übertragung Gegenbestätigung zurückgezogen",
  },
  25: {
    id: "cssd.audit.fourEyes.INTEREST_NOTIFICATION_OFFER_FIRST",
    defaultMessage: "Zinsmitteilung 1. Bestätigung",
  },
  26: {
    id: "cssd.audit.fourEyes.INTEREST_NOTIFICATION_OFFER_SECOND",
    defaultMessage: "Zinsmitteilung 2. Bestätigung",
  },
  27: {
    id: "cssd.audit.fourEyes.INTEREST_NOTIFICATION_OFFER_CANCELLED",
    defaultMessage: "Zinsmitteilung Bestätigung zurückgezogen",
  },
  28: {
    id: "cssd.audit.fourEyes.INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY",
    defaultMessage: "Zinsmitteilung Bestätigung zurückgezogen",
  },
  29: {
    id: "cssd.audit.fourEyes.TERMINATION_APPROVE_FIRST",
    defaultMessage: "Kündigung 1. Bestätigung ",
  },
  30: {
    id: "cssd.audit.fourEyes.TERMINATION_APPROVE_SECOND",
    defaultMessage: "Kündigung 2. Bestätigung",
  },
  31: {
    id: "cssd.audit.fourEyes.TERMINATION_APPROVE_CANCELLED",
    defaultMessage: "Kündigung storniert",
  },
  32: {
    id: "cssd.audit.fourEyes.TERMINATION_APPROVE_CANCELLED_ON_MODIFY",
    defaultMessage: "Kündigung storniert",
  },
  33: {
    id: "cssd.audit.fourEyes.TERMINATION_APPROVE_CANCELLED_ON_DELETE",
    defaultMessage: "Kündigung storniert ",
  },
  38: {
    id: "cssd.audit.fourEyes.CESSION_CERTIFICATE_OFFER_FIRST",
    defaultMessage: "Übertragungszertifikat 1. Bestätigung",
  },
  39: {
    id: "cssd.audit.fourEyes.CESSION_CERTIFICATE_OFFER_SECOND",
    defaultMessage: "Übertragungszertifikat 2. Bestätigung",
  },
  40: {
    id: "cssd.audit.fourEyes.CESSION_CERTIFICATE_ACCEPT_FIRST",
    defaultMessage: "Übertragungszertifikat 1. Gegenbestätigung",
  },
  41: {
    id: "cssd.audit.fourEyes.CESSION_CERTIFICATE_ACCEPT_SECOND",
    defaultMessage: "Übertragungszertifikat 2. Gegenbestätigung",
  },
  42: {
    id: "cssd.audit.fourEyes.TERMINATION_REVOKE_OFFER_FIRST",
    defaultMessage: "Kündigung zurückziehen 1. Bestätigung",
  },
  43: {
    id: "cssd.audit.fourEyes.TERMINATION_REVOKE_OFFER_SECOND",
    defaultMessage: "Kündigung zurückziehen 2. Bestätigung",
  },
  46: {
    id: "cssd.audit.fourEyes.TERMINATION_REVOKE_ACCEPT_FIRST",
    defaultMessage: "Kündigung zurückziehen 1. Gegenbestätigung",
  },
  47: {
    id: "cssd.audit.fourEyes.TERMINATION_REVOKE_ACCEPT_SECOND",
    defaultMessage: "Kündigung zurückziehen 2. Gegenbestätigung",
  },
  51: {
    id: "cssd.audit.fourEyes.CONNECT_GP_FIRST",
    defaultMessage: "Geschäftspartnerzuordnung 1. Bestätigung",
  },
  52: {
    id: "cssd.audit.fourEyes.CONNECT_GP_SECOND",
    defaultMessage: "Geschäftspartnerzuordnung 2. Bestätigung",
  },
  53: {
    id: "cssd.audit.fourEyes.CONNECT_GP_CANCEL",
    defaultMessage: "Geschäftspartnerzuordnung zurückgezogen",
  },

  54: {
    id: "cssd.audit.fourEyes.ACCEPT_DOCUMENTS_FIRST",
    defaultMessage: "Verträge erstbestätigt",
  },
  55: {
    id: "cssd.audit.fourEyes.ACCEPT_DOCUMENTS_SECOND",
    defaultMessage: "Verträge zweitbestätigt",
  },
});

export const cssdStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.cssd.state.INVALID",
    defaultMessage: "INVALID",
  },
  1: {
    id: "cssd.audit.cssd.state.NEW",
    defaultMessage: "Erfasst",
  },
  2: {
    id: "cssd.audit.cssd.state.IN_WORK",
    defaultMessage: "In Bearbeitung",
  },
  3: {
    id: "cssd.audit.cssd.state.OPEN",
    defaultMessage: "Bestätigt",
  },
  4: {
    id: "cssd.audit.cssd.state.APPROVED",
    defaultMessage: "Gegenbestätigt",
  },
  5: {
    id: "cssd.audit.cssd.state.CANCELLED",
    defaultMessage: "Storniert",
  },
  6: {
    id: "cssd.audit.cssd.state.DOCUMENT_SIGNED_BUYER",
    defaultMessage: "Urkunde bereitgestellt",
  },
  7: {
    id: "cssd.audit.cssd.state.DOCUMENT_SIGNED_SELLER",
    defaultMessage: "Signiert",
  },
  8: {
    id: "cssd.audit.cssd.state.REPAID",
    defaultMessage: "Zurückgezahlt",
  },
});

export const cssdActionDescriptions = defineMessages({
  0: {
    id: "cssd.audit.cssd.action.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.cssd.action.MODIFY",
    defaultMessage: "Bearbeitet",
  },
  2: {
    id: "cssd.audit.cssd.action.OFFER",
    defaultMessage: "Bestätigt",
  },
  3: {
    id: "cssd.audit.cssd.action.ACCEPT_OFFER",
    defaultMessage: "Gegenbestätigt",
  },
  4: {
    id: "cssd.audit.cssd.action.REJECT_OFFER",
    defaultMessage: "Abgelehnt",
  },
  5: {
    id: "cssd.audit.cssd.action.CANCEL",
    defaultMessage: "Storniert",
  },
  6: {
    id: "cssd.audit.cssd.action.SIGN_DOCUMENT_BUYER",
    defaultMessage: "Urkunde bereitgestellt",
  },
  7: {
    id: "cssd.audit.cssd.action.SIGN_DOCUMENT_SELLER",
    defaultMessage: "Urkunde signiert",
  },
  8: {
    id: "cssd.audit.cssd.action.MODIFY_AND_APPROVE",
    defaultMessage: "Bearbeitet und bestätigt",
  },
  9: {
    id: "cssd.audit.cssd.action.UPDATE",
    defaultMessage: "Bearbeitet",
  },
  10: {
    id: "cssd.audit.cssd.action.REPAYMENT_RECEIVED",
    defaultMessage: "Rückzahlung veranlasst",
  },
  11: {
    id: "cssd.audit.cssd.action.CONNECT",
    defaultMessage: "Geschäftspartner zugeordnet",
  },
  12: {
    id: "cssd.audit.cssd.action.CANCEL_CONNECT",
    defaultMessage: "Geschäftspartnerzuordnung getrennt",
  },
  13: {
    id: "cssd.audit.cssd.action.REJECT_DOCUMENTS",
    defaultMessage: "Verträge abgelehnt",
  },
});

export const cssdCessionActionDescriptions = defineMessages({
  0: {
    id: "cssd.audit.cession.action.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.cession.action.MODIFY",
    defaultMessage: "Übertragung bearbeitet ",
  },
  2: {
    id: "cssd.audit.cession.action.OFFER",
    defaultMessage: "Übertragung bestätigt",
  },
  3: {
    id: "cssd.audit.cession.action.ACCEPT_OFFER",
    defaultMessage: "Übertragung gegenbestätigt",
  },
  4: {
    id: "cssd.audit.cession.action.REJECT_OFFER",
    defaultMessage: "Übertragung abgelehnt",
  },
  5: {
    id: "cssd.audit.cession.action.CANCEL",
    defaultMessage: "Übertragung storniert",
  },
  6: {
    id: "cssd.audit.cession.action.RECEIVE_PAYMENT",
    defaultMessage: "Zahlungseingangsbestätigung",
  },
  7: {
    id: "cssd.audit.cession.action.CERT_OFFER",
    defaultMessage: "Übertragungszertifikat bestätigt",
  },
  8: {
    id: "cssd.audit.cession.action.CERT_ACCEPT",
    defaultMessage: "Übertragungszertifikat gegenbestätigt",
  },
  9: {
    id: "cssd.audit.cession.action.NOTIFY",
    defaultMessage: "Übertragung angezeigt",
  },
  10: {
    id: "cssd.audit.cession.action.ABORT",
    defaultMessage: "Übertragung abgebrochen",
  },
  11: {
    id: "cssd.audit.cession.action.UPDATE",
    defaultMessage: "Bearbeitet",
  },
});

export const cssdCessionStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.cession.state.INVALID",
    defaultMessage: "INVALID",
  },
  1: {
    id: "cssd.audit.cession.state.NEW",
    defaultMessage: "Übertragung erfasst",
  },
  2: {
    id: "cssd.audit.cession.state.IN_WORK",
    defaultMessage: "IN_WORK",
  },
  3: {
    id: "cssd.audit.cession.state.OPEN",
    defaultMessage: "OPEN",
  },
  4: {
    id: "cssd.audit.cession.state.APPROVED",
    defaultMessage: "APPROVED",
  },
  5: {
    id: "cssd.audit.cession.state.CANCELLED",
    defaultMessage: "CANCELLED",
  },
  6: {
    id: "cssd.audit.cession.state.PAYMENT_RECEIVED",
    defaultMessage: "PAYMENT_RECEIVED",
  },
  7: {
    id: "cssd.audit.cession.state.CERT_OPEN",
    defaultMessage: "CERT_OPEN",
  },
  8: {
    id: "cssd.audit.cession.state.CERT_APPROVED",
    defaultMessage: "CERT_APPROVED",
  },
  9: {
    id: "cssd.audit.cession.state.NOTIFIED",
    defaultMessage: "NOTIFIED",
  },
  10: {
    id: "cssd.audit.cession.state.ABORTED",
    defaultMessage: "ABORTED",
  },
});

export const cssdPartialClaimStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.partialClaim.state.INVALID",
    defaultMessage: "INVALID",
  },
  1: {
    id: "cssd.audit.partialClaim.state.NEW",
    defaultMessage: "Teilforderung erstellt",
  },
  2: {
    id: "cssd.audit.partialClaim.state.IN_WORK",
    defaultMessage: "Teilforderung abgestimmt",
  },
  3: {
    id: "cssd.audit.partialClaim.state.NOTIFIED",
    defaultMessage: "Teilforderung angelegt",
  },
  4: {
    id: "cssd.audit.partialClaim.state.REPAID",
    defaultMessage: "Teilforderung zurückgezahlt",
  },
  5: {
    id: "cssd.audit.partialClaim.state.CANCELLED",
    defaultMessage: "Teilforderung gelöscht",
  },
});

export const cssdPartialClaimActionDescriptions = defineMessages({
  0: {
    id: "cssd.audit.partialClaim.action.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.partialClaim.action.MODIFY",
    defaultMessage: "MODIFY",
  },
  2: {
    id: "cssd.audit.partialClaim.action.NOTIFY",
    defaultMessage: "Teilforderung angelegt",
  },
  3: {
    id: "cssd.audit.partialClaim.action.REPAYMENT_RECEIVED",
    defaultMessage: "Teilforderung zurückgezahlt",
  },
  4: {
    id: "cssd.audit.partialClaim.action.TERMINATE",
    defaultMessage: "Teilforderung gekündigt",
  },
  5: {
    id: "cssd.audit.partialClaim.action.UPDATE",
    defaultMessage: "Bearbeitet",
  },
  6: {
    id: "cssd.audit.partialClaim.action.OFFER",
    defaultMessage: "OFFER",
  },
  7: {
    id: "cssd.audit.partialClaim.action.CANCEL",
    defaultMessage: "CANCEL",
  },
  8: {
    id: "cssd.audit.partialClaim.action.CONNECT",
    defaultMessage: "Geschäftspartner zugeordnet",
  },
  9: {
    id: "cssd.audit.partialClaim.action.CANCEL_BY_TERMINATION",
    defaultMessage: "Teilforderung gelöscht aufgrund einer Kündigung",
  },
  10: {
    id: "cssd.audit.partialClaim.action.CANCEL_BY_CSSD_MODIFICATION",
    defaultMessage: "Teilforderung gelöscht aufgrund einer CSSD-Änderung",
  },
  11: {
    id: "cssd.audit.partialClaim.action.CANCEL_CONNECT",
    defaultMessage: "Geschäftspartnerzuordnung getrennt",
  },
});

export const cssdInterestNotificationStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.interestNotification.state.INVALID",
    defaultMessage: "INVALID",
  },
  1: {
    id: "cssd.audit.interestNotification.state.NEW",
    defaultMessage: "Zinsmitteilung erfasst",
  },
  2: {
    id: "cssd.audit.interestNotification.state.IN_WORK",
    defaultMessage: "In Bearbeitung",
  },
  3: {
    id: "cssd.audit.interestNotification.state.APPROVED",
    defaultMessage: "Bestätigt",
  },
  4: {
    id: "cssd.audit.interestNotification.state.CANCELLED",
    defaultMessage: "Storniert",
  },
  5: {
    id: "cssd.audit.interestNotification.state.CLOSED",
    defaultMessage: "Gelöscht",
  },
});

export const cssdInterestNotificationActionDescriptions = defineMessages({
  0: {
    id: "cssd.audit.interestNotification.action.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.interestNotification.action.MODIFY",
    defaultMessage: "Zinsmitteilung bearbeitet",
  },
  2: {
    id: "cssd.audit.interestNotification.action.OFFER",
    defaultMessage: "Zinsmitteilung bestätigt",
  },
  3: {
    id: "cssd.audit.interestNotification.action.REJECT_OFFER",
    defaultMessage: "Zinsmitteilung Bestätigung zurückgezogen",
  },
  4: {
    id: "cssd.audit.interestNotification.action.CANCEL",
    defaultMessage: "Zinsmitteilung storniert",
  },
  5: {
    id: "cssd.audit.interestNotification.action.CLOSE",
    defaultMessage: "Zinsmitteilung gelöscht",
  },
});

export const cssdTerminationStateDescriptions = defineMessages({
  0: {
    id: "cssd.audit.termination.state.INVALID",
    defaultMessage: "INVALID",
  },
  1: {
    id: "cssd.audit.termination.state.NEW",
    defaultMessage: "Kündigung erfasst",
  },
  2: {
    id: "cssd.audit.termination.state.IN_WORK",
    defaultMessage: "Kündigung in Bearbeitung",
  },
  3: {
    id: "cssd.audit.termination.state.APPROVED",
    defaultMessage: "Kündigung bestätigt",
  },
  4: {
    id: "cssd.audit.termination.state.ABORTED",
    defaultMessage: "Kündigung abgebrochen",
  },
  5: {
    id: "cssd.audit.termination.state.CANCELLED",
    defaultMessage: "Kündigung storniert",
  },
  6: {
    id: "cssd.audit.termination.state.REVOKE_IN_WORK",
    defaultMessage: "Kündigung zurückziehen angefragt",
  },
  7: {
    id: "cssd.audit.termination.state.REVOKE_OPEN",
    defaultMessage: "Kündigung zurückziehen erstgegenbestätigt",
  },
  8: {
    id: "cssd.audit.termination.state.REVOKE_APPROVED",
    defaultMessage: "Kündigung zurückziehen zweitgegenbestätigt",
  },
});

export const cssdTerminationActionDescriptions = defineMessages({
  0: {
    id: "cssd.audit.termination.action.NONE",
    defaultMessage: "NONE",
  },
  1: {
    id: "cssd.audit.termination.action.MODIFY",
    defaultMessage: "Kündigung bearbeitet ",
  },
  2: {
    id: "cssd.audit.termination.action.APPROVE",
    defaultMessage: "Kündigung bestätigt",
  },
  3: {
    id: "cssd.audit.termination.action.DELETE",
    defaultMessage: "Kündigung gelöscht",
  },
  4: {
    id: "cssd.audit.termination.action.ABORT",
    defaultMessage: "Kündigung abgebrochen",
  },
  5: {
    id: "cssd.audit.termination.action.CANCEL",
    defaultMessage: "Kündigung storniert",
  },
  6: {
    id: "cssd.audit.termination.action.REVOKE_MODIFY",
    defaultMessage: "Kündigung zurückziehen angefragt",
  },
  7: {
    id: "cssd.audit.termination.action.REVOKE_APPROVE",
    defaultMessage: "Kündigung zurückziehen bestätigt",
  },
  8: {
    id: "cssd.audit.termination.action.REVOKE_CANCEL",
    defaultMessage: "Kündigung zurückziehen storniert",
  },
  9: {
    id: "cssd.audit.termination.action.REVOKE_OFFER",
    defaultMessage: "Kündigung zurückziehen angefragt",
  },
  10: {
    id: "cssd.audit.termination.action.REVOKE_MODIFY_MATURITY_DATE",
    defaultMessage: "REVOKE_MODIFY_MATURITY_DATE",
  },
  11: {
    id: "cssd.audit.termination.action.PROPAGATE",
    defaultMessage: "Kündigung des CSSD angewendet",
  },
});
