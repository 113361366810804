import { defineMessages } from "react-intl";

export const messages = {
  ...{
    informationsmaterial_table_label_rows_per_page: {
      id: "cssd.table.label.rows.per.page",
    },
    cssd_filter_suchen: {
      id: "cssd.filter.suchen",
    },
    cssd_filter_reset: {
      id: "cssd.filter.reset",
    },
  },
  ...defineMessages({
    uploadSuccess: {
      id: "cssd.infomaterial.uploadSuccess",
      defaultMessage: "Upload erfolgreich",
    },
    uploadFail: {
      id: "cssd.infomaterial.uploadFail",
      defaultMessage: "Upload fehlgeschlagen",
    },
    downloadSuccess: {
      id: "cssd.infomaterial.downloadSuccess",
      defaultMessage: "Download erfolgreich",
    },
    downloadFail: {
      id: "cssd.infomaterial.downloadFail",
      defaultMessage: "Download fehlgeschlagen",
    },
    deleteSuccess: {
      id: "cssd.infomaterial.deleteSuccess",
      defaultMessage: "Löschen erfolgreich",
    },
    deleteFail: {
      id: "cssd.infomaterial.deleteFail",
      defaultMessage: "Löschen fehlgeschlagen",
    },
    title: {
      id: "cssd.infomaterial.uploadTitle",
      defaultMessage: "Upload Informationsmaterial",
    },
    selectFiles: {
      id: "cssd.infomaterial.uploadButton",
      defaultMessage: "Dateien auswählen",
    },
    betreff: {
      id: "cssd.infomaterial.betreff",
      defaultMessage: "Betreff",
    },
    text: {
      id: "cssd.infomaterial.text",
      defaultMessage: "Text",
    },
    dokumentTyp: {
      id: "cssd.infomaterial.dokumentTyp",
      defaultMessage: "Dokumenttyp",
    },
    hinweis_zahlstelle: {
      id: "cssd.infomaterial.hinweis.zahlstelle",
      defaultMessage: "Das Dokument wurde am {datum} um {uhrzeit} von {mitarbeiter} gelöscht.",
    },
    hinweis: {
      id: "cssd.infomaterial.hinweis",
      defaultMessage: "Das Dokument wurde am {datum} um {uhrzeit} gelöscht.",
    },
    save: {
      id: "cssd.infomaterial.save",
      defaultMessage: "Speichern",
    },
    abort: {
      id: "cssd.infomaterial.abort",
      defaultMessage: "Abbrechen",
    },
    noData: {
      id: "cssd.infomaterial.empty",
      defaultMessage: "Es wurde noch kein Informationsmaterial hochgeladen.",
    },
    KYC_DARLEHENSNEHMER: {
      id: "cssd.infomaterial.doctypes.darlehensnehmer",
      defaultMessage: "KYC Darlehensnehmer",
    },
    KYC_PERSONEN: {
      id: "cssd.infomaterial.doctypes.personen",
      defaultMessage: "KYC Personen",
    },
    AUSZAHLUNGSVORAUSSETZUNGEN: {
      id: "cssd.infomaterial.doctypes.auszahlungsvoraussetzungen",
      defaultMessage: "Auszahlungsvoraussetzungen",
    },
    FIXING: {
      id: "cssd.infomaterial.doctypes.fixing",
      defaultMessage: "Fixing",
    },
    KUENDIGUNGSSCHREIBEN: {
      id: "cssd.infomaterial.doctypes.Kuendigungsschreiben",
      defaultMessage: "Kündigungsschreiben",
    },
    GESCHAEFTSBERICHTE: {
      id: "cssd.infomaterial.doctypes.geschaeftsberichte",
      defaultMessage: "Geschäftsberichte",
    },
    SONSTIGE_INFORMATIONEN: {
      id: "cssd.infomaterial.doctypes.sonstiges",
      defaultMessage: "Sonstige Informationen",
    },
    ZINSANPASSUNGEN: {
      id: "cssd.infomaterial.doctypes.zinsmanpassungen",
      defaultMessage: "Zinsanpassungen",
    },
    ZINSMITTEILUNG: {
      id: "cssd.infomaterial.doctypes.zinsmitteilung",
      defaultMessage: "Zinsmitteilung",
    },
    TYP: {
      id: "cssd.infomaterial.document.type",
      defaultMessage: "Typ",
    },
    NAME: {
      id: "cssd.infomaterial.document.name",
      defaultMessage: "Name",
    },
    STATUS: {
      id: "cssd.infomaterial.document.status",
      defaultMessage: "Status",
    },
    DATUM: {
      id: "cssd.infomaterial.document.date",
      defaultMessage: "Datum",
    },
    UHRZEIT: {
      id: "cssd.infomaterial.document.time",
      defaultMessage: "Uhrzeit",
    },
    UPLOAD_VON: {
      id: "cssd.infomaterial.document.uploader",
      defaultMessage: "Upload Von",
    },
    BETREFF: {
      id: "cssd.infomaterial.document.betreff",
      defaultMessage: "Betreff",
    },
    LOESCHEN: {
      id: "cssd.infomaterial.document.loeschen",
      defaultMessage: "Löschen",
    },
    ACTIVE: {
      id: "cssd.infomaterial.document.status.active",
      defaultMessage: "Aktiv",
    },
    INACTIVE: {
      id: "cssd.infomaterial.document.status.inactive",
      defaultMessage: "Inaktiv",
    },
    error_metadata_fetch_failed: {
      id: "cssd.infomaterial.metadata.fetch.errormessage",
      defaultMessage: "Informaionsmaterial konnte nicht abgerufen werden.",
    },
    overview_title: {
      id: "cssd.infomaterial.overview.title",
      defaultMessage: "Informationsmaterial - {urkundenRegisternummer}",
    },
    anlegen_button: {
      id: "cssd.infomaterial.anlegen",
      defaultMessage: "Hinzufügen",
    },
    informationsmaterial_dialog_title: {
      id: "cssd.infomaterial.dialog.title",
      defaultMessage: "Möchten Sie dieses Dokument wirklich löschen?",
    },
    informationsmaterial_dialog_body: {
      id: "cssd.infomaterial.dialog.body",
      defaultMessage: "Diese Aktion kann nicht rückgängig gemacht werden",
    },
    informationsmaterial_dialog_confirm_button: {
      id: "cssd.infomaterial.dialog.confirm",
      defaultMessage: "Ja",
    },
    informationsmaterial_dialog_cancel_button: {
      id: "cssd.infomaterial.dialog.cancel",
      defaultMessage: "Nein",
    },
    zinsmitteilung_dialog_title: {
      id: "cssd.zinsmitteilung.dialog.title",
      defaultMessage: "Zinsmitteilung löschen",
    },
    zinsmitteilung_dialog_body: {
      id: "cssd.zinsmitteilung.dialog.body",
      defaultMessage: "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
    },
    zinsmitteilung_dialog_confirm_button: {
      id: "cssd.zinsmitteilung.dialog.confirm",
      defaultMessage: "Bestätigen",
    },
    zinsmitteilung_dialog_cancel_button: {
      id: "cssd.zinsmitteilung.dialog.cancel",
      defaultMessage: "Abbrechen",
    },
  }),
};
