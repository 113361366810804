// action types
export const FETCH_CSSDS_SAGA = "FETCH_CSSDS_SAGA";
export const FETCH_CSSDS_SAGA_SUCCESS = "FETCH_CSSDS_SAGA_SUCCESS"
export const FETCH_CSSDS_SAGA_FAILURE = "FETCH_CSSDS_SAGA_FAILURE";
export const FETCH_CSSDS_REQUEST = "FETCH_CSSDS_REQUEST";
export const FETCH_CSSDS_SUCCESS = "FETCH_CSSDS_SUCCESS";
export const FETCH_CSSDS_FAILURE = "FETCH_CSSDS_FAILURE";
export const UPDATE_CSSD = "UPDATE_CSSD";

export const PROVIDER_CONNECTED = "PROVIDER_CONNECTED";


function action(type, payload = {}) {
  return { type, ...payload };
}

export const cssdActions = {
  saga: () => action(FETCH_CSSDS_SAGA),
  sagaSuccess: () => action(FETCH_CSSDS_SAGA_SUCCESS),
  sagaFailure: () => action(FETCH_CSSDS_SAGA_FAILURE),
  request: () => action(FETCH_CSSDS_REQUEST, {}),
  success: (cssds) => action(FETCH_CSSDS_SUCCESS, { cssds }),
  failure: (error) => action(FETCH_CSSDS_FAILURE, { error }),
  update: (cssd) => action(UPDATE_CSSD, { cssd }),
};

export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";

export const cssdConfirmModal = {
  open: (cssd) => action(OPEN_CONFIRM_MODAL, { cssd }),
  close: () => action(CLOSE_CONFIRM_MODAL),
};

export const SET_CSSD_FILTER = "SET_CSSD_FILTER";
export const RESET_CSSD_FILTER = "RESET_CSSD_FILTER";

export const cssdFilterActions = {
  setFilter: (filter) => action(SET_CSSD_FILTER, { filter }),
  resetFilter: () => action(RESET_CSSD_FILTER, {}),
};

export const CHANGE_CSSD_PAGE = "CHANGE_CSSD_PAGE";
export const CHANGE_CSSD_ROWS_PER_PAGE = "CHANGE_CSSD_ROWS_PER_PAGE";
export const CHANGE_CSSD_SORT_CRITERIA = "CHANGE_CSSD_SORT_CRITERIA";

export const cssdTableActions = {
  changeCssdPage: (pageNumber) => action(CHANGE_CSSD_PAGE, { pageNumber }),
  changeCssdRowsPerPage: (pageSize) => action(CHANGE_CSSD_ROWS_PER_PAGE, { pageSize }),
  setSortCriteria: (column, sortType) => action(CHANGE_CSSD_SORT_CRITERIA, { column, sortType }),
};

export const START_CSSD_EVENT_LISTENER = "START_CSSD_EVENT_LISTENER";
export const STOP_CSSD_EVENT_LISTENER = "STOP_CSSD_EVENT_LISTENER";
export const UPDATE_CSSD_FOUREYES_EVENT = "UPDATE_CSSD_FOUREYES_EVENT";
export const UPDATE_CSSD_EVENT = "UPDATE_CSSD_EVENT";
export const UPDATE_CSSD_CESSION_FOUREYES_EVENT = "UPDATE_CSSD_CESSION_FOUREYES_EVENT";
export const UPDATE_CSSD_PARTIAL_CLAIM_FOUREYES_EVENT = "UPDATE_CSSD_PARTIAL_CLAIM_FOUREYES_EVENT";
export const UPDATE_CSSD_CESSION_EVENT = "UPDATE_CSSD_CESSION_EVENT";
export const UPDATE_CSSD_CESSION_AND_REQUEST_EVENT = "UPDATE_CSSD_CESSION_AND_REQUEST_EVENT";
export const UPDATE_CSSD_CESSION_FOR_WORK_EVENT = "UPDATE_CSSD_CESSION_FOR_WORK_EVENT";

export const cssdEventActions = {
  startEventListener: () => action(START_CSSD_EVENT_LISTENER, {}),
  stopEventListener: () => action(STOP_CSSD_EVENT_LISTENER, {}),
  handleFourEyesEvent: (cssdId, fourEyes, firstConfirmer) =>
    action(UPDATE_CSSD_FOUREYES_EVENT, { cssdId, fourEyes, firstConfirmer }),
  handleCSSDEvent: (id, state, ssdAction, fingerprint, arranger, reasonHash) =>
    action(UPDATE_CSSD_EVENT, { id, state, ssdAction, fingerprint, arranger, reasonHash }),
  handleCSSDCessionFourEyesEvent: (id, subid, fourEyes, firstConfirmer) =>
    action(UPDATE_CSSD_CESSION_FOUREYES_EVENT, {
      id,
      subid,
      fourEyes,
      firstConfirmer,
    }),
  handleCSSDPartialClaimFourEyesEvent: (id, subid, fourEyes, firstConfirmer) =>
    action(UPDATE_CSSD_PARTIAL_CLAIM_FOUREYES_EVENT, {
      id,
      subid,
      fourEyes,
      firstConfirmer,
    }),
  handleCSSDCessionEvent: (id, cessionState, cessionAction, fingerprint, arranger, payingAgent) =>
    action(UPDATE_CSSD_CESSION_EVENT, {
      id,
      cessionState,
      cessionAction,
      fingerprint,
      arranger,
      payingAgent,
    }),
  handleCSSDCessionAndRequestEvent: (
    id,
    cessionState,
    cessionAction,
    fingerprint,
    arranger,
    payingAgent
  ) =>
    action(UPDATE_CSSD_CESSION_AND_REQUEST_EVENT, {
      id,
      cessionState,
      cessionAction,
      fingerprint,
      arranger,
      payingAgent,
    }),
  handleCSSDCessionForWorkEvent: (callerAddr, event) =>
    action(UPDATE_CSSD_CESSION_FOR_WORK_EVENT, {
      callerAddr,
      event,
    }),
};

export const OPEN_CSSD_DETAILS_MODAL = "OPEN_CSSD_DETAILS_MODAL";
export const CLOSE_CSSD_DETAILS_MODAL = "CLOSE_CSSD_DETAILS_MODAL";

export const cssdDetailsModal = {
  open: (cssd) => action(OPEN_CSSD_DETAILS_MODAL, { cssd }),
  close: () => action(CLOSE_CSSD_DETAILS_MODAL),
};

export const UPDATE_CSSD_TERMINATIONS = "UPDATE_CSSD_TERMINATIONS";
export const cssdTerminationActions = {
  update: (cssdId, terminations) => action(UPDATE_CSSD_TERMINATIONS, { cssdId, terminations }),
};
