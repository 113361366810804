export const OPEN_CSSD_INFORMATIONSMATERIAL_OVERVIEW = "OPEN_CSSD_INFORMATIONSMATERIAL_OVERVIEW";
export const LOAD_CSSD_FOR_TEILFORDERUNG = "LOAD_CSSD_FOR_TEILFORDERUNG";
export const FETCH_INFORMATIONSMATERIAL_METADATA = "FETCH_INFORMATIONSMATERIAL_METADATA";
export const FETCH_INFORMATIONSMATERIAL_METADATA_SUCCESS =
  "FETCH_INFORMATIONSMATERIAL_METADATA_SUCCESS";
export const FETCH_INFORMATIONSMATERIAL_METADATA_FAILURE =
  "FETCH_INFORMATIONSMATERIAL_METADATA_FAILURE";
export const SORT_METADATA_TABLE = "SORT_METADATA_TABLE";
export const FILTER_METADATA_TABLE = "FILTER_METADATA_TABLE";
export const SET_PAGE_METADATA_TABLE = "SET_PAGE_METADATA_TABLE";
export const SET_PAGE_SIZE_METADATA_TABLE = "SET_PAGE_SIZE_METADATA_TABLE";
export const CLEAR_FILTER_AND_SORTING = "CLEAR_FILTER_AND_SORTING";
export const DOWNLOAD_INFORMATIONSMATERIAL = "DOWNLOAD_INFORMATIONSMATERIAL";
export const DOWNLOAD_INFORMATIONSMATERIAL_STARTED = "DOWNLOAD_INFORMATIONSMATERIAL_STARTED";
export const DOWNLOAD_INFORMATIONSMATERIAL_SUCCESS = "DOWNLOAD_INFORMATIONSMATERIAL_SUCCESS";
export const DOWNLOAD_INFORMATIONSMATERIAL_FAILURE = "DOWNLOAD_INFORMATIONSMATERIAL_FAILURE";
export const UPLOAD_INFORMATIONSMATERIAL_STARTED = "UPLOAD_INFORMATIONSMATERIAL_STARTED";
export const UPLOAD_INFORMATIONSMATERIAL_SUCCESS = "UPLOAD_INFORMATIONSMATERIAL_SUCCESS";
export const UPLOAD_INFORMATIONSMATERIAL_FAILURE = "UPLOAD_INFORMATIONSMATERIAL_FAILURE";
export const DELETE_INFORMATIONSMATERIAL = "DELETE_INFORMATIONSMATERIAL";
export const DELETE_INFORMATIONSMATERIAL_STARTED = "DELETE_INFORMATIONSMATERIAL_STARTED";
export const DELETE_INFORMATIONSMATERIAL_SUCCESS = "DELETE_INFORMATIONSMATERIAL_SUCCESS";
export const DELETE_INFORMATIONSMATERIAL_FAILURE = "DELETE_INFORMATIONSMATERIAL_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const cssdInformationsmaterial = {
  openInfomaterialOverview: (teilforderung) =>
    action(LOAD_CSSD_FOR_TEILFORDERUNG, { teilforderung }),
  openInfomaterialOverviewWithCSSD: (cssd) =>
    action(OPEN_CSSD_INFORMATIONSMATERIAL_OVERVIEW, { cssd }),
  fetchInformationsMaterialMetaData: (queryData) =>
    action(FETCH_INFORMATIONSMATERIAL_METADATA, { queryData }),
  fetchInformationsMaterialMetadataSuccess: (data) =>
    action(FETCH_INFORMATIONSMATERIAL_METADATA_SUCCESS, data),
  fetchInformationsMaterialMetadataFailure: () =>
    action(FETCH_INFORMATIONSMATERIAL_METADATA_FAILURE),
  sortMetadataTable: (sortBy) => action(SORT_METADATA_TABLE, { sortBy }),
  filterMetadataTable: (filters) => action(FILTER_METADATA_TABLE, { filters }),
  setPageOnMetadataTable: (pageNumber) => action(SET_PAGE_METADATA_TABLE, { pageNumber }),
  setPageSizeOnMetadataTable: (pageSize) => action(SET_PAGE_SIZE_METADATA_TABLE, { pageSize }),
  clearFilterAndSorting: () => action(CLEAR_FILTER_AND_SORTING),
};

export const SAVE_INFORMATIONSMATERIAL_REQUEST = "SAVE_INFORMATIONSMATERIAL_REQUEST";

export const saveCssdInformationsmaterial = {
  save: (arranger, cssdId, informationsmaterial) =>
    action(SAVE_INFORMATIONSMATERIAL_REQUEST, {
      arranger,
      cssdId,
      informationsmaterial,
    }),
  uploading: () => action(UPLOAD_INFORMATIONSMATERIAL_STARTED),
  success: () => action(UPLOAD_INFORMATIONSMATERIAL_SUCCESS, {}),
  failure: () => action(UPLOAD_INFORMATIONSMATERIAL_FAILURE, {}),
};

export const downloadCssdInformationsmaterial = {
  download: (arranger, cssdId, dateiId) =>
    action(DOWNLOAD_INFORMATIONSMATERIAL, {
      arranger,
      cssdId,
      dateiId,
    }),
  downloading: (dateiId) => action(DOWNLOAD_INFORMATIONSMATERIAL_STARTED, { dateiId }),
  success: (dateiId) => action(DOWNLOAD_INFORMATIONSMATERIAL_SUCCESS, { dateiId }),
  failure: (dateiId) => action(DOWNLOAD_INFORMATIONSMATERIAL_FAILURE, { dateiId }),
};

export const deleteCssdInformationsmaterial = {
  delete: (arranger, cssdId, dateiId, mitarbeiter) =>
    action(DELETE_INFORMATIONSMATERIAL, {
      arranger,
      cssdId,
      dateiId,
      mitarbeiter,
    }),
  deleting: (dateiId) => action(DELETE_INFORMATIONSMATERIAL_STARTED, { dateiId }),
  success: (dateiId) => action(DELETE_INFORMATIONSMATERIAL_SUCCESS, { dateiId }),
  failure: (dateiId) => action(DELETE_INFORMATIONSMATERIAL_FAILURE, { dateiId }),
};

export const EXPAND_INFORMATIONSMATERIALMETADATA = "EXPAND_INFORMATIONSMATERIALMETADATA";
export const SHRINK_INFORMATIONSMATERIALMETADATA = "SHRINK_INFORMATIONSMATERIALMETADATA";

export const expandInformationsmaterialMetadata = {
  expand: (dateiId) => action(EXPAND_INFORMATIONSMATERIALMETADATA, { dateiId }),
  shrink: (dateiId) => action(SHRINK_INFORMATIONSMATERIALMETADATA, { dateiId }),
};

export const CSSD_INTEREST_NOTIFICATION_OPEN = "CSSD_INTEREST_NOTIFICATION_OPEN";
export const CSSD_INTEREST_NOTIFICATION_OPEN_REGISTER = "CSSD_INTEREST_NOTIFICATION_OPEN_REGISTER";
export const CSSD_INTEREST_NOTIFICATION_CLOSE_REGISTER =
  "CSSD_INTEREST_NOTIFICATION_CLOSE_REGISTER";
export const CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER = "CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER";
export const CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER =
  "CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER";
export const CSSD_INTEREST_NOTIFICATION_STORE = "CSSD_INTEREST_NOTIFICATION_STORE";
export const CSSD_INTEREST_NOTIFICATION_EDIT = "CSSD_INTEREST_NOTIFICATION_EDIT";
export const CSSD_INTEREST_NOTIFICATION_CONFIRM = "CSSD_INTEREST_NOTIFICATION_CONFIRM";
export const CSSD_INTEREST_NOTIFICATION_CANCEL = "CSSD_INTEREST_NOTIFICATION_CANCEL";
export const CSSD_INTEREST_NOTIFICATION_DELETE = "CSSD_INTEREST_NOTIFICATION_DELETE";
export const CSSD_INTEREST_NOTIFICATION_FAILURE = "CSSD_INTEREST_NOTIFICATION_FAILURE";

export const InterestNotificationActions = {
  openOverview: (cssd) => action(CSSD_INTEREST_NOTIFICATION_OPEN, { cssd }),
  openRegister: () => action(CSSD_INTEREST_NOTIFICATION_OPEN_REGISTER),
  storeNotifications: (notifications) =>
    action(CSSD_INTEREST_NOTIFICATION_STORE, { notifications }),
  closeRegister: () => action(CSSD_INTEREST_NOTIFICATION_CLOSE_REGISTER),
  save: (notification) => action(CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER, { notification }),
  saveAndConfirm: (account, notification) =>
    action(CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER, {
      account,
      notification,
    }),
  editNotification: (notification) => action(CSSD_INTEREST_NOTIFICATION_EDIT, { notification }),
  confirm: (account, notification) =>
    action(CSSD_INTEREST_NOTIFICATION_CONFIRM, { account, notification }),
  cancel: (account, notification) =>
    action(CSSD_INTEREST_NOTIFICATION_CANCEL, { account, notification }),
  deleteNotification: (account, notification) =>
    action(CSSD_INTEREST_NOTIFICATION_DELETE, { account, notification }),
  failure: () => action(CSSD_INTEREST_NOTIFICATION_FAILURE),
};

export const INTEREST_NOTIFICATION_EVENT = "INTEREST_NOTIFICATION_EVENT";
export const INTEREST_NOTIFICATION_FOUR_EYES_EVENT = "INTEREST_NOTIFICATION_FOUR_EYES_EVENT";
export const INTEREST_NOTIFICATION_UPDATE = "INTEREST_NOTIFICATION_UPDATE";

export const interestNotificationEventActions = {
  event: (event) => action(INTEREST_NOTIFICATION_EVENT, { event }),
  fourEyesUpdate: (event) => action(INTEREST_NOTIFICATION_FOUR_EYES_EVENT, { event }),
  update: (interestNotification) => action(INTEREST_NOTIFICATION_UPDATE, { interestNotification }),
};
export const OPEN_DELETE_INTEREST_NOTIFICATION_DIALOG = "OPEN_DELETE_INTEREST_NOTIFICATION_DIALOG";
export const CLOSE_DELETE_INTEREST_NOTIFICATION_DIALOG =
  "CLOSE_DELETE_INTEREST_NOTIFICATION_DIALOG";

export const deleteInterestNotificationDialogActions = {
  open: (notification) => action(OPEN_DELETE_INTEREST_NOTIFICATION_DIALOG, { notification }),
  close: () => action(CLOSE_DELETE_INTEREST_NOTIFICATION_DIALOG),
};
