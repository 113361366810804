import { messages } from "components/CssdRegister/Wizard/messages";
import { defineMessages } from "react-intl";
import { toUtf8 } from "services/web3Services/commons";
import { appIntl } from "../components/i18n/intl";
import { supportedLanguages } from "./constants";

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
//---------------------------------------------------------------------------
export function _base64ToArrayBuffer(base64) {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
//---------------------------------------------------------------------------
export function cleanObj(obj) {
  const cleaned = { ...obj };
  Object.keys(cleaned).forEach((key) => cleaned[key] === "" && delete cleaned[key]);
  return cleaned;
}

//---------------------------------------------------------------------------

export function formatNumber(value, decimalplaces) {
  return addDotsComma(Number(removeDots(value)).toFixed(decimalplaces));
}
//---------------------------------------------------------------------------
export function removeDots(number) {
  if (typeof number === "string" || number instanceof String) {
    return number.replace(/\./g, "").replace(",", ".");
  }
  return number;
}
//---------------------------------------------------------------------------
export function addCssdNumberFormat(cssd) {
  return {
    ...cssd,
    mindestbetrag_bei_abtretung: formatNumber(cssd.mindestbetrag_bei_abtretung, 2),
    nominal: formatNumber(cssd.nominal, 2),
  };
}
//---------------------------------------------------------------------------
export function addDotsComma(number) {
  if (number && number !== "" && number !== "0") {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else return "0";
}
//---------------------------------------------------------------------------
export function clearTerminationNumberFormat(terminationData) {
  return {
    ...terminationData,
    rueckzahlung: Number(removeDots(terminationData.rueckzahlung)),
    rueckzahlungsbetrag: Number(removeDots(terminationData.rueckzahlungsbetrag)),
    zinsbetrag: Number(removeDots(terminationData.zinsbetrag)),
  };
}
//---------------------------------------------------------------------------
export function addInterestNumberFormat(interestData) {
  return {
    ...interestData,
    zinsSatz: addDotsComma(interestData.zinsSatz),
    zinsBetrag: addDotsComma(interestData.zinsBetrag),
    referenzZinssatz: addDotsComma(interestData.referenzZinssatz),
  };
}
//---------------------------------------------------------------------------
export function clearInterestNumberFormat(interestData) {
  return {
    ...interestData,
    zinsSatz: Number(removeDots(interestData.zinsSatz)),
    zinsBetrag: Number(removeDots(interestData.zinsBetrag)),
    anzahlTage: Number(interestData.anzahlTage),
    referenzZinssatz: Number(removeDots(interestData.referenzZinssatz)),
  };
}
//---------------------------------------------------------------------------
export function clearSsdNumberFormat(ssdData) {
  return {
    ...ssdData,
    nominal: removeDots(ssdData.nominal),
    kurs: removeDots(ssdData.kurs),
    zinssatz: removeDots(ssdData.zinssatz),

    abschlag: removeDots(ssdData.abschlag),
    aufschlag: removeDots(ssdData.aufschlag),
    maximumzinssatz: ssdData.maximumzinssatz === "" ? null : removeDots(ssdData.maximumzinssatz),
    minimumzinssatz: ssdData.minimumzinssatz === "" ? null : removeDots(ssdData.minimumzinssatz),
    faktor: removeDots(ssdData.faktor),

    kuendigungTermine: ssdData.kuendigungTermine.map((entry) => {
      const formatted = removeDots(entry.kuendigungskurs);
      return { ...entry, kuendigungskurs: formatted };
    }),

    kaufpreis: removeDots(ssdData.kaufpreis),
    mindestbetrag_bei_abtretung: removeDots(ssdData.mindestbetrag_bei_abtretung),
  };
}
//---------------------------------------------------------------------------
export function clearRebuyNumberFormat(rebuyData) {
  return {
    ...rebuyData,
    nominal: removeDots(rebuyData.nominal),
    ruecknahmekurs: removeDots(rebuyData.ruecknahmekurs),
    ruecknahmebetrag: removeDots(rebuyData.ruecknahmebetrag),
    aufgelaufene_stueckzinsen: removeDots(rebuyData.aufgelaufene_stueckzinsen),
  };
}
//---------------------------------------------------------------------------
export function clearCessionNumberFormat(cessionData) {
  return {
    ...cessionData,
    abgetretenes_nominal: removeDots(cessionData.abgetretenes_nominal),
    abtretungskurs: removeDots(cessionData.abtretungskurs),
    gegenwert_abtretung: removeDots(cessionData.gegenwert_abtretung),
    stueckzinsen: removeDots(cessionData.stueckzinsen),
  };
}
//---------------------------------------------------------------------------
export const setSsdNumberFormat = (response) => {
  let ssdData = response.data;
  return {
    ...response,
    data: {
      ...ssdData,
      nominal: addDotsComma(ssdData.nominal),
      kurs: addDotsComma(ssdData.kurs),
      zinssatz: addDotsComma(ssdData.zinssatz),
      kaufpreis: addDotsComma(ssdData.kaufpreis),
      mindestbetrag_bei_abtretung: addDotsComma(ssdData.mindestbetrag_bei_abtretung),
      abschlag: addDotsComma(ssdData.abschlag),
      aufschlag: addDotsComma(ssdData.aufschlag),
      maximumzinssatz:
        ssdData.maximumzinssatz === null ? "" : addDotsComma(ssdData.maximumzinssatz),
      minimumzinssatz:
        ssdData.minimumzinssatz === null ? "" : addDotsComma(ssdData.minimumzinssatz),
      faktor: ssdData.faktor === null ? "" : addDotsComma(ssdData.faktor),

      kuendigungTermine: ssdData.kuendigungTermine.map((entry) => {
        const formatted = addDotsComma(entry.kuendigungskurs);
        return { ...entry, kuendigungskurs: formatted };
      }),

      ruecknahmekurs: addDotsComma(ssdData.ruecknahmekurs),
      ruecknahmebetrag: addDotsComma(ssdData.ruecknahmebetrag),
      aufgelaufene_stueckzinsen: addDotsComma(ssdData.aufgelaufene_stueckzinsen),
    },
  };
};
//---------------------------------------------------------------------------
export function dateToTimestamp(dateStr) {
  const [month, date, fullYear] = dateStr.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3").split("/");
  return Math.floor(
    new Date(Date.UTC(Number(fullYear), Number(month) - 1, Number(date), 0, 0, 0)).getTime() / 1000
  );
}
//---------------------------------------------------------------------------
export function formatDateForUrl(date) {
  if (date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return day + "-" + month + "-" + year;
  } else return "";
}
//---------------------------------------------------------------------------
export function formatDate(date) {
  if (date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return day + "." + month + "." + year;
  } else return "";
}
//---------------------------------------------------------------------------
export function formatTime(time) {
  if (time) {
    let h = time.getHours();
    let m = time.getMinutes();
    if (h < 10) h = "0" + h;
    if (m < 10) m = "0" + m;
    return h + ":" + m;
  } else return "";
}
//---------------------------------------------------------------------------
export function getNaechsterTermin(terminTag, terminMonate) {
  const monthList = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();

  let day = terminTag;
  let month = currentMonth;
  let year = new Date().getFullYear();

  let terminList = [];

  //----- if Zinszahlungsrhythmus = monatlich
  if (terminMonate.length === 0) {
    if (day <= currentDay) {
      if (month === 12) {
        month = 1;
        year++;
      } else month++;
    }
    terminList.push(new Date(year, month - 1, day));
  }

  //----- if Zinszahlungsrhythmus = jährlich, halbjährlich, vierteljährlich
  else {
    terminList = terminMonate.map((x) => {
      month = monthList.indexOf(x) + 1;

      if (month < currentMonth) year++;
      else if (month === currentMonth && day <= currentDay) year++;

      return new Date(year, month - 1, day);
    });
  }

  let sortedTerminList = terminList.sort((date1, date2) => {
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;
    return 0;
  });

  return formatDate(sortedTerminList[0]);
}

const languages = defineMessages({
  de: {
    id: "languages.deutsch",
    defaultMessage: "Deutsch",
  },
  en: {
    id: "languages.englisch",
    defaultMessage: "Englisch",
  },
});

export const getSprache = (sprache) => {
  if (supportedLanguages.includes(sprache)) {
    return appIntl().formatMessage(languages[sprache]);
  }
  return sprache;
};

export const getCountrybyCode = (code) => {
  if (!code) {
    return null;
  }
  const locale = appIntl().locale;
  code = code.toUpperCase();
  const countries = require("i18n-iso-countries");
  countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));
  const country = countries.getName(code, locale);
  return `${code}, ${country}`;
};

export const formatIban = (iban) => {
  iban = String(iban).toUpperCase().replace(/\s/g, "");
  const iterations = iban.length - Math.abs(iban.length % 4);
  for (let i = iterations; i > 0; i -= 4) {
    iban = iban.slice(0, i) + " " + iban.slice(i, iban.length);
  }
  return iban;
};

export const getDateFromString = (val) => {
  if (typeof val === "string") {
    let date = val.split(".");
    return new Date(date[2], Number(date[1]) - 1, date[0], 0, 0, 0);
  } else {
    return val;
  }
};

export const calculateGegenwert = (nominal, kurs) => {
  return (parseFloat(removeDots(nominal)) * parseFloat(removeDots(kurs))) / 100;
};

export function convertTimestampToDate(timestamp) {
  let dt = new Date(timestamp * 1000);
  return formatDate(dt);
}

export function convertTimestampToTime(timestamp) {
  let dt = new Date(timestamp * 1000);
  return formatTime(dt);
}

export function convertZinsrhythmusToMonthCount(rhythmus) {
  switch (rhythmus) {
    case "JAEHRLICH":
      return 1;
    case "HALBJAEHRLICH":
      return 2;
    case "VIERTELJAEHRLICH":
      return 4;
    default:
      return 0;
  }
}

export function convertBooleanAsStringToNumber(value) {
  return value === "true" ? 1 : 0;
}

export const formatNumberForCSV = (number) => {
  return number && number.toString().replace(".", ",");
};

export const getTrueFalse = (id) => {
  switch (id) {
    case true:
    case "true":
      return appIntl().formatMessage(messages.ja);
    case false:
    case "false":
      return appIntl().formatMessage(messages.nein);
    default:
      return null;
  }
};

export const getDarlehensart = (id) => {
  switch (id) {
    case "SCHULDSCHEINDARLEHEN":
      return appIntl().formatMessage(messages.schuldscheindarlehen);
    default:
      return null;
  }
};

export const getGeschaeftsnummer = (urkundenNr, geschaeftsnummer) => {
  return `${urkundenNr}-${String(geschaeftsnummer).padStart(3, "0")}`;
};

export const formatNumberWithEmptyData = (str) => {
  return str === "" || str == null ? null : Number(removeDots(str));
};

export const buildPostCssdPayload = (cssdData) => {
  const data = { ...cssdData };
  delete data.firstConfirmer;
  delete data.fourEyesState;
  delete data.status;
  delete data.valid;
  delete data.reasonHash;
  delete data.hasUrkundeUndZahlstellenvertragUploadedByDarlehensnehmer;
  delete data.hasUrkundeUndZahlstellenvertragUploadedByZahlstelle;
  delete data.imported;
  const { zinsUndTilgungskonditionen, kauf, zahlstelle } = data;
  return {
    ...data,
    darlehensbetrag: formatNumberWithEmptyData(data?.darlehensbetrag),
    mindestbetragBeiAbtretung: formatNumberWithEmptyData(data?.mindestbetragBeiAbtretung),
    zinsUndTilgungskonditionen: {
      ...zinsUndTilgungskonditionen,
      zinssatz:
        zinsUndTilgungskonditionen?.zinssatz != null
          ? Number(removeDots(zinsUndTilgungskonditionen?.zinssatz))
          : null,
      marge: zinsUndTilgungskonditionen?.marge
        ? Number(removeDots(zinsUndTilgungskonditionen?.marge))
        : null,
      minimumZinssatz: zinsUndTilgungskonditionen?.minimumZinssatz
        ? Number(removeDots(zinsUndTilgungskonditionen?.minimumZinssatz))
        : null,
      maximumZinssatz: zinsUndTilgungskonditionen?.maximumZinssatz
        ? Number(removeDots(zinsUndTilgungskonditionen?.maximumZinssatz))
        : null,
    },
    kauf: {
      ...kauf,
      nominal: formatNumberWithEmptyData(kauf?.nominal),
      kurs: formatNumberWithEmptyData(kauf?.kurs),
      kaufpreis: formatNumberWithEmptyData(kauf?.kaufpreis),
    },
    darlehensgeber: {
      ...zahlstelle,
    },
  };
};

export function hashArrayToNumberArray(arr) {
  return arr.map((id) => Number(toUtf8(id)));
}

export function formatSafeUrl(url) {
  return url.replace(/(https?:\/\/)|(\/){2,}/g, "$1$2");
}
