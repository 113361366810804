import { defineMessages } from "react-intl";

export const messages = defineMessages({
  details: {
    id: "cssd_register.details",
    defaultMessage: "CSSD Daten",
  },
  urkundenRegisternummer: {
    id: "cssd_register.urkunden_registernummer",
    defaultMessage: "Urkunden-/Registernummer",
  },
  warnUrkundenRegisternummer: {
    id: "cssd_register.warn_urkunden_registernummer",
    defaultMessage: "Urkunden-/Registernummer ist bereits vergeben",
  },
  cssd_interneKennung: {
    id: "cssd_register.cssd_interneKennung",
    defaultMessage: "Interne Kennung",
  },
  interneKennung: {
    id: "cssd_register.interneKennung",
    defaultMessage: "Interne Kennung",
  },
  warnInterneKennung: {
    id: "cssd_register.warnInterneKennung",
    defaultMessage: "Interne Kennung ist bereits vergeben",
  },
  konsortialkennung: {
    id: "cssd_register.konsortialkennung",
    defaultMessage: "Konsortialkennung",
  },
  warnKonsortialkennung: {
    id: "cssd_register.warn_konsortialkennung",
    defaultMessage: "Konsortialkennung ist bereits vergeben",
  },
  spaetesteAbtretungsanzeige: {
    id: "cssd_register.spaetesteAbtretungsanzeige",
    defaultMessage: "Späteste Übertragungsanzeige",
  },
  spaetesteAbtretungsanzeige_suffix: {
    id: "cssd_register.spaetesteAbtretungsanzeige.suffix",
    defaultMessage: "Bankarbeitstage vor Zahlungstermin",
  },
  telefonFormatError: {
    id: "cssd_register.telefonFormatError",
    defaultMessage: "Fehler - Beispiel: +49 30 12345",
  },
  emailFormatError: {
    id: "cssd_register.emailFormatError",
    defaultMessage: "Fehler - Beispiel: example@site.com",
  },
  darlehensart: {
    id: "cssd_register.darlehensart",
    defaultMessage: "Darlehensart",
  },
  schuldscheindarlehen: {
    id: "cssd_register.schuldscheindarlehen",
    defaultMessage: "Schuldscheindarlehen",
  },
  waehrung_nominal: {
    id: "cssd_register.waehrung_nominal",
    defaultMessage: "Währung Nominal",
  },
  eur: {
    id: "cssd_register.eur",
    defaultMessage: "EUR",
  },
  usd: {
    id: "cssd_register.usd",
    defaultMessage: "USD",
  },
  gbp: {
    id: "cssd_register.gbp",
    defaultMessage: "GBP",
  },
  chf: {
    id: "cssd_register.chf",
    defaultMessage: "CHF",
  },
  sprache_deutsch: {
    id: "cssd_register.deutsch",
    defaultMessage: "Deutsch",
  },
  sprache_englisch: {
    id: "cssd_register.englisch",
    defaultMessage: "Englisch",
  },
  darlehensbetrag: {
    id: "cssd_register.darlehensbetrag",
    defaultMessage: "Darlehensbetrag",
  },
  laufzeitbeginn: {
    id: "cssd_register.laufzeitbeginn",
    defaultMessage: "Laufzeitbeginn",
  },
  mindestbetragBeiAbtretung: {
    id: "cssd_register.mindestbetrag_bei_abtretung",
    defaultMessage: "Mindestbetrag bei Übertragung",
  },
  sprache_cssd: {
    id: "cssd_register.sprache_cssd",
    defaultMessage: "Sprache CSSD",
  },
  zahlstellengebuehr: {
    id: "cssd_register.zahlstellengebuehr",
    defaultMessage: "Zahlstellengebühr",
  },
  arrangierungsprovision: {
    id: "cssd_register.arrangierungsprovision",
    defaultMessage: "Arrangierungsprovision",
  },

  zins_tilgungskonditionen: {
    id: "cssd_register.zins_tilgungskonditionen",
    defaultMessage: "Zins- und Tilgungskonditionen",
  },
  zinskonditionen: {
    id: "cssd_register.zinskonditionen",
    defaultMessage: "Zinskonditionen",
  },
  zins_fix: {
    id: "cssd_register.zins_fix",
    defaultMessage: "Verzinsung",
  },
  zins_fix_fix: {
    id: "cssd_register.zins_fix_fix",
    defaultMessage: "fix",
  },
  zins_fix_var: {
    id: "cssd_register.zins_fix_var",
    defaultMessage: "variabel",
  },
  zins_fix_fix_vertrag: {
    id: "cssd_register.zins_fix_fix_vertrag",
    defaultMessage: "fix gemäß Vertrag",
  },
  zins_fix_var_vertrag: {
    id: "cssd_register.zins_fix_var_vertrag",
    defaultMessage: "variabel gemäß Vertrag",
  },
  zinssatz: {
    id: "cssd_register.zinssatz",
    defaultMessage: "Zinssatz",
  },
  verzinsung: {
    id: "cssd_register.verzinsung",
    defaultMessage: "Verzinsung",
  },
  marge: {
    id: "cssd_register.marge",
    defaultMessage: "Marge",
  },
  hasZahlstellenGebuehr: {
    id: "cssd_register.hasZahlstellenGebuehr",
    defaultMessage: "Zahlstellengebühr",
  },
  hasArrangierProvision: {
    id: "cssd_register.hasArrangierProvision",
    defaultMessage: "Arrangierungsprovision",
  },
  referenzzinssatz: {
    id: "cssd_register.referenzzinssatz",
    defaultMessage: "Referenzzinssatz",
  },
  margeTyp: {
    id: "cssd_register.margeTyp",
    defaultMessage: "Aufschlag/Abschlag",
  },
  keine_marge: {
    id: "cssd_register.keine_marge",
    defaultMessage: "Keine Marge",
  },
  aufschlag: {
    id: "cssd_register.aufschlag",
    defaultMessage: "Aufschlag",
  },
  abschlag: {
    id: "cssd_register.abschlag",
    defaultMessage: "Abschlag",
  },
  marge_aufschlag_abschlag: {
    id: "cssd_register.marge_aufschlag_abschlag",
    defaultMessage: "Aufschlag/Abschlag in %",
  },
  marge_aufschlag: {
    id: "cssd_register.marge_aufschlag",
    defaultMessage: "Aufschlag in %",
  },
  marge_abschlag: {
    id: "cssd_register.marge_abschlag",
    defaultMessage: "Abschlag in %",
  },
  minimumZinssatz: {
    id: "cssd_register.minimumZinssatz",
    defaultMessage: "Minimumzinssatz",
  },
  minimumZinssatzError: {
    id: "cssd_register.minimumZinssatzError",
    defaultMessage: "Muss kleiner sein als Maximumzinssatz",
  },
  maximumZinssatz: {
    id: "cssd_register.maximumZinssatz",
    defaultMessage: "Maximumzinssatz",
  },
  maximumZinssatzError: {
    id: "cssd_register.maximumZinssatzError",
    defaultMessage: "Muss größer sein als Minimumzinssatz",
  },
  zahlungsrhythmus: {
    id: "cssd_register.zahlungsrhythmus",
    defaultMessage: "Zinszahlungsrhythmus",
  },
  zahlungsrhythmus_jaehrlich: {
    id: "cssd_register.zahlungsrhythmus.jaehrlich",
    defaultMessage: "Jährlich",
  },
  zahlungsrhythmus_halbjaehrlich: {
    id: "cssd_register.zahlungsrhythmus.halbjaehrlich",
    defaultMessage: "Halbjährlich",
  },
  zahlungsrhythmus_vierteljaehrlich: {
    id: "cssd_register.zahlungsrhythmus.vierteljaehrlich",
    defaultMessage: "Vierteljährlich",
  },
  zahlungsrhythmus_monatlich: {
    id: "cssd_register.zahlungsrhythmus.monatlich",
    defaultMessage: "Monatlich",
  },
  zinsmethode: {
    id: "cssd_register.zinsmethode",
    defaultMessage: "Zinsmethode",
  },
  zinsmethode_act_act_icma: {
    id: "cssd_register.zinsmethode.act_act_icma",
    defaultMessage: "act/act ICMA Rule 251",
  },
  zinsmethode_act_act_isda: {
    id: "cssd_register.zinsmethode.act_act_isda",
    defaultMessage: "act/act ISDA",
  },
  zinsmethode_act_360: {
    id: "cssd_register.zinsmethode.act_360",
    defaultMessage: "act/360 (Eurozinsmethode, französische Zinsmethode)",
  },
  zinsmethode_act_365: {
    id: "cssd_register.zinsmethode.act_365",
    defaultMessage: "act/365 (englische Zinsmethode)",
  },
  zinsmethode_30_360: {
    id: "cssd_register.zinsmethode.30_360",
    defaultMessage: "30/360 (deutsche Zinsmethode)",
  },
  zinsmethode_30e_360: {
    id: "cssd_register.zinsmethode.30e_360",
    defaultMessage: "30E/360 (US-Zinsmethode)",
  },
  faelligkeitstagDerZinszahlung: {
    id: "cssd_register.faelligkeitstagDerZinszahlung",
    defaultMessage: "Fälligkeitstag der Zinszahlung",
  },
  // Hier sind bewusst die beiden Sprachen enthalten!
  faelligkeitstagDerZinszahlung_following_nachfolgend: {
    id: "cssd_register.faelligkeitstagDerZinszahlung.following_nachfolgend",
    defaultMessage: "following-nachfolgend",
  },
  faelligkeitstagDerZinszahlung_modified_following_nachfolgend_modifiziert: {
    id: "cssd_register.faelligkeitstagDerZinszahlung.modified_following_nachfolgend_modifiziert",
    defaultMessage: "modified following-nachfolgend modifiziert",
  },
  faelligkeitstagDerZinszahlung_preceding_vorhergehend: {
    id: "cssd_register.faelligkeitstagDerZinszahlung.preceding_vorhergehend",
    defaultMessage: "preceding-vorhergehend",
  },
  berechnungszeitraumDerZinszahlung: {
    id: "cssd_register.berechnungszeitraumDerZinszahlung",
    defaultMessage: "Berechnungszeitraum der Zinszahlung",
  },
  berechnungszeitraumDerZinszahlung_unadjusted: {
    id: "cssd_register.berechnungszeitraumDerZinszahlung.unadjusted",
    defaultMessage: "unadjusted-nicht angepasst",
  },
  berechnungszeitraumDerZinszahlung_adjusted: {
    id: "cssd_register.berechnungszeitraumDerZinszahlung.adjusted",
    defaultMessage: "adjusted-angepasst",
  },
  //-------
  zinstermin: {
    id: "cssd_register.zinstermin",
    defaultMessage: "Zinstermin",
  },
  zinsterminTag: {
    id: "cssd_register.zinsterminTag",
    defaultMessage: "Zinstermin Tag",
  },
  zinsterminMonat: {
    id: "cssd_register.zinsterminMonat",
    defaultMessage: "Zinstermin Monat",
  },
  zinsterminMonatError: {
    id: "cssd_register.zinsterminMonatError",
    defaultMessage: "Zinstermin passt nicht zum Zahlungsrhythmus",
  },
  ersterZinstermin: {
    id: "cssd_register.ersterZinstermin",
    defaultMessage: "Erster Zinstermin",
  },
  naechsterZinstermin: {
    id: "cssd_register.naechsterZinstermin",
    defaultMessage: "Nächster Zinstermin",
  },
  ersterZinsterminError: {
    id: "cssd_register.ersterZinsterminError",
    defaultMessage: "Erster Zinstermin muss nach dem Laufzeitbeginn liegen",
  },
  vorletzterZinstermin: {
    id: "cssd_register.vorletzterZinstermin",
    defaultMessage: "Vorletzter Zinstermin",
  },
  vorletzterZinsterminError: {
    id: "cssd_register.vorletzterZinsterminError",
    defaultMessage:
      "Vorletzter Zinstermin muss vor der Endfälligkeit und nach dem Laufzeitbeginn liegen",
  },
  stepupStepdownRegelung: {
    id: "cssd_register.stepupStepdownRegelung",
    defaultMessage: "Stepup/Stepdown-Regelung",
  },
  stepupStepdownRegelung_keine_regelung: {
    id: "cssd_register.stepupStepdownRegelung.keine_regelung",
    defaultMessage: "kein Stepup/-down",
  },
  stepupStepdownRegelung_vertragliche_regelung: {
    id: "cssd_register.stepupStepdownRegelung.vertragliche_regelung",
    defaultMessage: "Stepup/-down siehe Vertragliche Regelung",
  },
  tilgungskonditionen: {
    id: "cssd_register.tilgungskonditionen",
    defaultMessage: "Tilgungskonditionen",
  },
  endfaelligkeit: {
    id: "cssd_register.endfaelligkeit",
    defaultMessage: "Endfälligkeit",
  },
  endfaelligkeitError: {
    id: "cssd_register.endfaelligkeitError",
    defaultMessage:
      "Endfälligkeit muss in der Zukunft und nach dem Laufzeitbeginn und der Valuta liegen",
  },
  tilgungsart: {
    id: "cssd_register.tilgungsart",
    defaultMessage: "Tilgungsart",
  },
  gesamttilgung: {
    id: "cssd_register.gesamttilgung",
    defaultMessage: "Gesamttilgung am Laufzeitende",
  },

  darlehensnehmer: {
    id: "cssd_register.darlehensnehmer",
    defaultMessage: "Darlehensnehmer",
  },
  darlehensgeber: {
    id: "cssd_register.darlehensgeber",
    defaultMessage: "Darlehensgeber",
  },
  zahlstelle: {
    id: "cssd_register.zahlstelle",
    defaultMessage: "Zahlstelle",
  },
  firmenname: {
    id: "cssd_register.firmenname",
    defaultMessage: "Firmenname",
  },
  firmenname_zs: {
    id: "cssd_register.firmenname_zs",
    defaultMessage: "Firmenname ZS",
  },
  finledger_kennung: {
    id: "cssd_register.finledger_kennung",
    defaultMessage: "finledger Kennung",
  },
  finledger_kennung_zs: {
    id: "cssd_register.finledger_kennung_zs",
    defaultMessage: "finledger Kennung ZS",
  },
  digits_kennung_dn: {
    id: "cssd_register.digits_kennung_dn",
    defaultMessage: "Finledger Kennung Darlehensnehmer",
  },
  umsatzsteuer_id_dn: {
    id: "cssd_register.umsatzsteuer_id_dn",
    defaultMessage: "Umsatzsteuer ID DN",
  },
  anschrift: {
    id: "cssd_register.anschrift",
    defaultMessage: "Anschrift",
  },
  anschrift_zs: {
    id: "cssd_register.anschrift_zs",
    defaultMessage: "Anschrift ZS",
  },
  anschrift_dn: {
    id: "cssd_register.anschrift_dn",
    defaultMessage: "Anschrift DN",
  },
  sitzland: {
    id: "cssd_register.sitzland",
    defaultMessage: "Sitzland",
  },
  sitzland_zs: {
    id: "cssd_register.sitzland_zs",
    defaultMessage: "Sitzland ZS",
  },
  sitzland_dn: {
    id: "cssd_register.sitzland_dn",
    defaultMessage: "Sitzland DN",
  },
  sprache_zs: {
    id: "cssd_register.sprache_zs",
    defaultMessage: "Sprache ZS",
  },
  sprache_dn: {
    id: "cssd_register.sprache_dn",
    defaultMessage: "Sprache DN",
  },
  sprache: {
    id: "cssd_register.sprache",
    defaultMessage: "Sprache",
  },
  waehrung: {
    id: "cssd_register.waehrung",
    defaultMessage: "Währung",
  },
  ibanZinsEur: {
    id: "cssd_register.ibanZinsEur",
    defaultMessage: "IBAN Zinsen",
  },
  emailVerteiler: {
    id: "cssd_register.emailVerteiler",
    defaultMessage: "Email Verteiler",
  },
  ansprechpartner: {
    id: "cssd_register.ansprechpartner",
    defaultMessage: "Ansprechpartner",
  },
  ansprechpartner_ssd_abwicklung_zs: {
    id: "cssd_register.ansprechpartner_ssd_abwicklung_zs",
    defaultMessage: "Ansprechpartner SSD-Abwicklung ZS",
  },
  ansprechpartner_ssd_abwicklung_dn: {
    id: "cssd_register.ansprechpartner_ssd_abwicklung_dn",
    defaultMessage: "Ansprechpartner SSD-Abwicklung DN",
  },
  abteilung: {
    id: "cssd_register.abteilung",
    defaultMessage: "Abteilung",
  },
  abteilung_ssd_abwicklung_zs: {
    id: "cssd_register.abteilung_ssd_abwicklung_zs",
    defaultMessage: "Abteilung SSD-Abwicklung ZS",
  },
  abteilung_ssd_abwicklung_dn: {
    id: "cssd_register.abteilung_ssd_abwicklung_dn",
    defaultMessage: "Abteilung SSD-Abwicklung DN",
  },
  telefonnummer_zs: {
    id: "cssd_register.telefonnummer_zs",
    defaultMessage: "Telefonnummer ZS",
  },
  telefonnummer_dn: {
    id: "cssd_register.telefonnummer_dn",
    defaultMessage: "Telefonnummer Darlehensnehmer",
  },
  email_verteiler_zs: {
    id: "cssd_register.email_verteiler_zs",
    defaultMessage: "E-Mail-Verteiler ZS",
  },
  email_verteiler_dn: {
    id: "cssd_register.email_verteiler_dn",
    defaultMessage: "E-Mail-Verteiler DN",
  },
  bic: {
    id: "cssd_register.bic",
    defaultMessage: "BIC",
  },
  bic_zs: {
    id: "cssd_register.bic_zs",
    defaultMessage: "BIC der Zahlstelle",
  },
  bic_dn: {
    id: "cssd_register.bic_dn",
    defaultMessage: "BIC des Zahlungsempfängers",
  },
  konto_tilgung_dn: {
    id: "cssd_register.konto_tilgung_dn",
    defaultMessage: "Konto (Tilgung) DN",
  },
  konto_tilgung: {
    id: "cssd_register.konto_tilgung",
    defaultMessage: "Konto (Tilgung)",
  },
  bic_tilgung_dn: {
    id: "cssd_register.bic_tilgung_dn",
    defaultMessage: "BIC (Tilgung) DN",
  },
  bic_tilgung: {
    id: "cssd_register.bic_tilgung",
    defaultMessage: "BIC (Tilgung)",
  },
  hinweis_zahlung_tilgung_dn: {
    id: "cssd_register.hinweis_zahlung_tilgung_dn",
    defaultMessage: "Hinweis Zahlung (Tilgung) DN",
  },
  hinweis_zahlung_tilgung: {
    id: "cssd_register.hinweis_zahlung_tilgung",
    defaultMessage: "Hinweis Zahlung (Tilgung)",
  },
  belastung_internes_konto_dn: {
    id: "cssd_register.belastung_internes_konto_dn",
    defaultMessage: "Belastung internes Konto DN",
  },
  belastung_internes_konto: {
    id: "cssd_register.belastung_internes_konto",
    defaultMessage: "Belastung internes Konto",
  },
  konto_zins: {
    id: "cssd_register.konto_zins",
    defaultMessage: "Konto (Zins)",
  },
  bic_zins: {
    id: "cssd_register.bic_zins",
    defaultMessage: "BIC (Zins)",
  },
  konto_zins_dg: {
    id: "cssd_register.konto_zins_dg",
    defaultMessage: "Konto (Zins) DG",
  },
  bic_zins_dg: {
    id: "cssd_register.bic_zins_dg",
    defaultMessage: "BIC (Zins) DG",
  },
  hinweis_zahlung_zins_dg: {
    id: "cssd_register.hinweis_zahlung_zins_dg",
    defaultMessage: "Hinweis Zahlung (Zins) DG",
  },
  iban_tilgung_dg: {
    id: "cssd_register.konto_tilgung_dg",
    defaultMessage: "IBAN (Tilgung) DG",
  },
  bic_tilgung_dg: {
    id: "cssd_register.bic_tilgung_dg",
    defaultMessage: "BIC (Tilgung) DG",
  },
  hinweis_zahlung_tilgung_dg: {
    id: "cssd_register.hinweis_zahlung_tilgung_dg",
    defaultMessage: "Hinweis Zahlung (Tilgung) DG",
  },
  geschaeftsdaten: {
    id: "cssd_register.geschaeftsdaten",
    defaultMessage: "Geschäftsdaten",
  },
  geschaeftsnummer: {
    id: "cssd_register.geschaeftsnummer",
    defaultMessage: "Geschäftsnummer",
  },
  nominal: {
    id: "cssd_register.nominal",
    defaultMessage: "Nominal",
  },
  kurs: {
    id: "cssd_register.kurs",
    defaultMessage: "Kurs",
  },
  kaufpreis: {
    id: "cssd_register.kaufpreis",
    defaultMessage: "Kaufpreis",
  },
  handelstag: {
    id: "cssd_register.handelstag",
    defaultMessage: "Handelstag",
  },
  valuta: {
    id: "cssd_register.valuta",
    defaultMessage: "Valuta",
  },
  valutaError: {
    id: "cssd_register.valutaError",
    defaultMessage: "Valuta muss in der Zukunft und frühestens auf dem Laufzeitbeginn liegen",
  },
  maxLengthError: {
    id: "cssd_register.maxLengthError",
    defaultMessage: "Fehler - Text zu lang",
  },
  overview: {
    id: "cssd_register.overview",
    defaultMessage: "Überblick",
  },
  companyInfo: {
    id: "cssd_register.companyInfo",
    defaultMessage: "Unternehmensdaten",
  },
  deal: {
    id: "cssd_register.deal",
    defaultMessage: "Geschäft",
  },

  januar: {
    id: "cssd_register.januar",
    defaultMessage: "Januar",
  },
  februar: {
    id: "cssd_register.februar",
    defaultMessage: "Februar",
  },
  maerz: {
    id: "cssd_register.maerz",
    defaultMessage: "März",
  },
  april: {
    id: "cssd_register.april",
    defaultMessage: "April",
  },
  mai: {
    id: "cssd_register.mai",
    defaultMessage: "Mai",
  },
  juni: {
    id: "cssd_register.juni",
    defaultMessage: "Juni",
  },
  juli: {
    id: "cssd_register.juli",
    defaultMessage: "Juli",
  },
  august: {
    id: "cssd_register.august",
    defaultMessage: "August",
  },
  september: {
    id: "cssd_register.september",
    defaultMessage: "September",
  },
  oktober: {
    id: "cssd_register.oktober",
    defaultMessage: "Oktober",
  },
  november: {
    id: "cssd_register.november",
    defaultMessage: "November",
  },
  dezember: {
    id: "cssd_register.dezember",
    defaultMessage: "Dezember",
  },

  ja: {
    id: "cssd_register.ja",
    defaultMessage: "Ja",
  },
  nein: {
    id: "cssd_register.nein",
    defaultMessage: "Nein",
  },
  name: {
    id: "cssd_register.name",
    defaultMessage: "Darlehensnehmer",
  },
  back: {
    id: "cssd_register.back",
    defaultMessage: "zurück",
  },
  next: {
    id: "cssd_register.next",
    defaultMessage: "weiter",
  },
  reset: {
    id: "cssd_register.reset",
    defaultMessage: "abbrechen",
  },
  save: {
    id: "cssd_register.save",
    defaultMessage: "speichern",
  },
  edit: {
    id: "cssd_register.edit",
    defaultMessage: "Bearbeiten",
  },
  confirm: {
    id: "cssd_register.confirm",
    defaultMessage: "speichern & erstbestätigen",
  },
  bicFormat: {
    id: "cssd_register.bicFormat",
    defaultMessage: "Fehler - Beispiel: HELADEFF123",
  },
  ibanFormat: {
    id: "cssd_register.ibanFormat",
    defaultMessage: "Fehler - Beispiel: DE84500105176619333197",
  },
  lei: {
    id: "cssd_register.lei",
    defaultMessage: "LEI",
  },
  backendUrl: {
    id: "cssd_register.backendUrl",
    defaultMessage: "Backend Url",
  },
  erfassenderMitarbeiter: {
    id: "cssd_register.erfassenderMitarbeiter",
    defaultMessage: "Erfassender Mitarbeiter",
  },
  umsatzsteuer_id: {
    id: "cssd_register.umsatzsteuer_id",
    defaultMessage: "Umsatzsteuer Id",
  },
  hinweis_zahlung_zins: {
    id: "cssd_register.hinweis_zahlung_zins",
    defaultMessage: "Hinweis Zahlung Zins",
  },
  hinweis_dateInPast: {
    id: "cssd_register.hinweis_datum_vergangenheit",
    defaultMessage: "Hinweis: Datum liegt in der Vergangenheit",
  },
  //--
  ansprechpartnerAbwicklung: {
    id: "cssd_register.audit.ansprechpartnerAbwicklung",
    defaultMessage: "Ansprechpartner Abwicklung",
  },
  abteilungAbwicklung: {
    id: "cssd_register.audit.abteilungAbwicklung",
    defaultMessage: "Abteilung CSSD-Abwicklung",
  },
  telefonnummer: {
    id: "cssd_register.audit.telefonnummer",
    defaultMessage: "Telefonnummer",
  },
  ansprechpartnerAbbicAllgemeinwicklung: {
    id: "cssd_register.audit.bicAllgemein",
    defaultMessage: "BIC",
  },
  ibanTilgungEur: {
    id: "cssd_register.audit.ibanTilgungEur",
    defaultMessage: "Konto (Tilgung)",
  },
  bicTilgungEur: {
    id: "cssd_register.audit.bicTilgungEur",
    defaultMessage: "BIC (Tilgung)",
  },
  bicAllgemein: {
    id: "cssd_register.audit.bicAllgemein",
    defaultMessage: "BIC",
  },
  bicZinsEur: {
    id: "cssd_register.audit.bicZinsEur",
    defaultMessage: "BIC Zinsen",
  },
  hinweisZahlungZins: {
    id: "cssd_register.audit.hinweisZahlungZins",
    defaultMessage: "Hinweis Zahlung (Zins)",
  },
  hinweisZahlungTilgung: {
    id: "cssd_register.audit.hinweisZahlungTilgung",
    defaultMessage: "Hinweis Zahlung (Tilgung)",
  },
  belastungInternesKonto: {
    id: "cssd_register.audit.belastungInternesKonto",
    defaultMessage: "Belastung internes Konto",
  },
  gegenwert: {
    id: "cssd_register.gegenwert",
    defaultMessage: "Gegenwert",
  },
  status: {
    id: "cssd_register.status",
    defaultMessage: "Status",
  },
  datum: {
    id: "cssd_register.datum",
    defaultMessage: "Datum",
  },
  checked: {
    id: "cssd_register.verifiziert",
    defaultMessage: "Verifiziert",
  },
  valutaStueckzins: {
    id: "cssd_register.valuta_stueckzinsen",
    defaultMessage: "Valuta Stückzinsen",
  },
  gerichtsstand: {
    id: "cssd_register.gerichtsstand",
    defaultMessage: "Gerichtsstand",
  },

  abtretung_pre: {
    id: "cssd_register.abtretung_pre",
    defaultMessage: "Übertragung",
  },
  cssd_pre: {
    id: "cssd_register.cssd_pre",
    defaultMessage: "CSSD",
  },
  dn_pre: {
    id: "cssd_register.dn_pre",
    defaultMessage: "DN",
  },
  dg_bisher_pre: {
    id: "cssd_register.dg_bisher_pre",
    defaultMessage: "Bisheriger-DG",
  },
  dg_neu_pre: {
    id: "cssd_register.dg_neu_pre",
    defaultMessage: "Neuer-DG",
  },
  dg_pre: {
    id: "cssd_register.dg_pre",
    defaultMessage: "DG",
  },
  zs_pre: {
    id: "cssd_register.zs_pre",
    defaultMessage: "ZS",
  },
  tf_pre: {
    id: "cssd_register.tf_pre",
    defaultMessage: "TF",
  },
  partialClaimCurrentAmount: {
    id: "cssd_register.partialClaimCurrentAmount",
    defaultMessage: "Höhe der Teilforderung",
  },
  hinweis_mindest_greater_darlehens: {
    id: "cssd_register.mindestbetragError",
    defaultMessage: "Mindestbetrag einer Übertragung muss kleiner sein als der Darlehensbetrag",
  },
});
