import {
  takeLatest,
  call,
  put,
  fork,
  take,
  all,
  cancel,
  takeEvery,
  takeLeading,
  select,
  delay
} from "redux-saga/effects";
import {
  START_CSSD_EVENT_LISTENER,
  STOP_CSSD_EVENT_LISTENER,
  UPDATE_CSSD_EVENT,
  cssdActions,
  FETCH_CSSDS_SAGA,
  PROVIDER_CONNECTED,
} from "./actions";
import { queryCSSDsById } from "services/cssdService";
import { getBlockNumber, getIdProxyAddr, toUtf8, getCssdEventFromBc } from "services/web3Services/commons";
import {
  listenForCSSDEvents,
  listenForCSSDFourEyesEvents,
  listenForCSSDCessionEvents,
  listenForCSSDPartialClaimEvent,
  listenForCSSDInterestNotificationEvent,
  listenForCSSDPartialClaimTerminationEvent,
  listenForCSSDTerminationEvent,
  listenForProviderDisconnect
} from "services/web3Services/eventListener";
import {
  getCompanyInfo,
  getPrivileges,
  isCSSDReferencedByCession,
  isCSSDReferencedByPartialClaims,
} from "redux/selectors";
import { cessionActions } from "redux/cssdCession/actions";
import { cssdPartialClaimActions } from "redux/cssdTeilforderungen/actions";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const cssdEventSagas = [
  takeLeading(FETCH_CSSDS_SAGA, fetchCSSDsById),
  takeEvery(UPDATE_CSSD_EVENT, fetchCSSDForUpdate),
  takeLatest(START_CSSD_EVENT_LISTENER, startListener),
];

export function* startListener(companyAddr) {
  const currentBlock = yield call(getBlockNumber);
  const listener = [];
  listener.push(yield fork(providerConnectionListener, companyAddr));
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDEvents));
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDCessionEvents));
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDFourEyesEvents));
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDPartialClaimEvent));
  listener.push(
    yield fork(listenForEvent, currentBlock, listenForCSSDPartialClaimTerminationEvent)
  );
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDInterestNotificationEvent));
  listener.push(yield fork(listenForEvent, currentBlock, listenForCSSDTerminationEvent));
  yield take(STOP_CSSD_EVENT_LISTENER);
  yield all(listener.map((channel) => cancel(channel)));
}

function* providerConnectionListener(companyAddr) {
  try {
    const chan = yield call(listenForProviderDisconnect);
    while (true) {
      let result = yield take(chan);
      if (result.type === PROVIDER_CONNECTED) {
        yield call(startListener, companyAddr);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* listenForEvent(currentBlock, listener) {
  try {
    const chan = yield call(listener, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchCSSDsById() {
  try {
    const { privilegeCssdOffer: isZSM } = yield select(getPrivileges);
    const filteredEvents = yield call(getCssdEventFromBc, getIdProxyAddr(), isZSM);
    const uniqueBlocks = [];
    const map = new Map();
    for (const item of filteredEvents) {
      try {
        Number(toUtf8(item.returnValues.id));
      } catch (e) {
        console.error(e);
        continue;
      }
      if (!map.has(item.id)) {
        map.set(item.id, true);
        uniqueBlocks.push({
          arranger: item.returnValues.payingAgent,
          cssd: { cssdId: Number(toUtf8(item.returnValues.id)) },
        });
      }
    }
    const cssdsByArranger = uniqueBlocks.reduce((acc, curr) => {
      acc[curr.arranger] = acc[curr.arranger] ? [...acc[curr.arranger], curr.cssd] : [curr.cssd];
      return acc;
    }, {});

    // Array of CSSDs split to smaller arrays (chunks)
    const chunkSize = Number(process.env.REACT_APP_CHUNK_SIZE_CSSD) || 1;
    Object.keys(cssdsByArranger).forEach((arranger) => {
      cssdsByArranger[arranger] = new Array(Math.ceil(cssdsByArranger[arranger].length / chunkSize))
        .fill()
        .map((value) => cssdsByArranger[arranger].splice(0, chunkSize));
    });

    const arrangers = Object.keys(cssdsByArranger);
    for (let i = 0; i < arrangers.length; i++) {
      yield fork(fetchCssdsFromArranger, arrangers[i], cssdsByArranger[arrangers[i]]);
    }
    yield put(cssdActions.sagaSuccess());
  } catch (error) {
    console.error(error);
    yield put(cssdActions.sagaFailure({ error }));
    // Retry
    yield delay(5000);
    yield call(fetchCSSDsById)
  }
}

export function* fetchCssdFromArranger(arranger, cssd) {
    try {
      yield put(cssdActions.request());
      const { data } = yield call(queryCSSDsById, arranger, [cssd], getIdProxyAddr());
      yield put(cssdActions.success(data));
    } catch (e) {
      yield put(cssdActions.failure(arranger, [cssd.cssdId]));
      console.error(e);
    }
}

function* fetchCssdsFromArranger(arranger, payloads) {
  for (let i = 0; i < payloads.length; i++) {
    try {
      yield put(cssdActions.request());

      const { data } = yield call(queryCSSDsById, arranger, payloads[i], getIdProxyAddr());

      yield put(cssdActions.success(data));
    } catch (e) {
      const ids = payloads[i].map((cssd) => cssd.cssdId);
      yield put(cssdActions.failure(arranger, ids));
      console.error(e);
    }
  }
}

export function* fetchCSSDForUpdate({ arranger, id, state, reasonHash }) {
  try {
    const cssdId = Number(toUtf8(id));
    yield put(cssdActions.update({ cssdId, status: state, reasonHash }));
    const companyInfo = yield select(getCompanyInfo);
    yield put(cssdActions.request());
    const { data } = yield call(queryCSSDsById, arranger, [{ cssdId }], companyInfo.idProxyAddress);
    const { status, reasonHash: reason, ...details } = data; // Status and reason are already updated
    yield put(cssdActions.success(details));

    const referencedByCession = yield select(isCSSDReferencedByCession, cssdId);
    if (referencedByCession) {
      yield put(cessionActions.setCssd(data.cssdResponses[0]));
    }
    const referencedByPartialClaims = yield select(isCSSDReferencedByPartialClaims, cssdId);
    if (referencedByPartialClaims) {
      yield put(cssdPartialClaimActions.setCssd(data.cssdResponses[0]));
    }
  } catch (error) {
    console.error(error);
    yield put(cssdActions.failure({ error }));
  }
}
