import { sendSignedHttpRequest } from "../util/requestSigning";

export async function getAccountInfo(accountAddress, idProxyAddress) {
  const requestHeader = {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
  };

  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddress}/mitarbeiter/${accountAddress}`,
    idProxyAddress,
    null,
    requestHeader
  );
}

export async function getCompanyInfoDraft(idProxyAddress) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddress}/lastVersion`,
    idProxyAddress
  );
}

export async function getCompanyInfo(idProxyAddress) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/platformmember/${idProxyAddress}/public`,
    idProxyAddress
  ).then(function (response) {
    response.data.iban = response.data.iban || "";
    response.data.bic = response.data.bic || "";
    response.data.iban_tilgung = response.data.iban_tilgung || "";
    response.data.bic_tilgung = response.data.bic_tilgung || "";
    return response;
  });
}

export async function updateCompanyInfo(companyInfo, idProxyAddress) {
  const data = { ...companyInfo };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddress}/update`,
    idProxyAddress,
    data
  );
}
