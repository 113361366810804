import web3 from "util/web3";

import { contractCache } from "services/smartContracts/smartContracts";

import { sendRefuelTransaction, getPlatformManagerMethods } from "./commons";

//
// FAUCET
//

export async function getEtherBalance(walletAddress) {
  let balance = 0;
  try {
    balance = await web3.eth.getBalance(walletAddress);
  } catch (error) {
    console.error(error);
  }
  return balance;
}

export async function requestEtherIfEmpty(account) {
  const block = await web3.eth.getBlock("latest");
  const walletBalance = await web3.eth.getBalance(account.address);
  if (walletBalance < 5*block.gasLimit) {
    const contractMethods = getPlatformManagerMethods();
    const data = contractMethods
      .requestEther(account.address, contractCache.identityProxy._address)
      .encodeABI();
    return await sendRefuelTransaction(
      account,
      contractCache.platformManagerContract._address,
      data
    );
  }
}

export async function requestEtherForWallet(account, walletAddress, idProxyAddr) {
  const block = await web3.eth.getBlock("latest");
  const walletBalance = await web3.eth.getBalance(walletAddress);
  if (walletBalance < 5*block.gasLimit) {
    console.error("refueleing wallet!");
    const contractMethods = getPlatformManagerMethods();
    const data = contractMethods.requestEther(walletAddress, idProxyAddr).encodeABI();
    return await sendRefuelTransaction(
      account,
      contractCache.platformManagerContract._address,
      data
    );
  }
}
