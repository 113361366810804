import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pdf_verification_button_text_zurueck: {
    id: "pdf.verification.cssd.button_text_zurueck",
    defaultMessage: "ZURÜCK",
  },
  valid: {
    id: "pdf.verification.cssd.case.valid",
    defaultMessage: `Das Dokument ist dem System bekannt`,
  },
  notValid: {
    id: "pdf.verification.cssd.case.notValid",
    defaultMessage: `Das Dokument ist dem System nicht bekannt.`,
  },
  a: {
    id: "pdf.verification.cssd.case.a",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt und die Daten stimmen mit den unseren überein.`,
  },
  b: {
    id: "pdf.verification.cssd.case.b",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt und die Rückzahlung hat stattgefunden. Die Forderung besteht nicht mehr.`,
  },
  c: {
    id: "pdf.verification.cssd.case.c",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt und die Daten stimmen mit den unseren überein.
    Das Schuldscheindarlehen wurde zum {terminationDate} gekündigt.`,
  },
  d: {
    id: "pdf.verification.cssd.case.d",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt.
    Bitte beachten Sie jedoch, dass die darin dokumentierte Teilforderung nicht mehr besteht.`,
  },
  e: {
    id: "pdf.verification.cssd.case.e",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt.
    Die darin dokumentierte Teilforderung wurde zum {terminationDate} gekündigt.`,
  },
  f: {
    id: "pdf.verification.cssd.case.f",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt.
    Die hier dokumentierte Teilforderung wurde zum {terminationDate} gekündigt.
    Bitte beachten Sie jedoch, dass ein Teil oder Teile der Forderung zuvor abgetreten wurden.`,
  },
  g: {
    id: "pdf.verification.cssd.case.g",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt und die Daten stimmen mit den unseren überein. Der dazugehörige Darlehensvertrag kann ebenfalls über diese Dokumentenprüfung validiert werden.`,
  },
  h: {
    id: "pdf.verification.cssd.case.h",
    defaultMessage: `Das hochgeladene Dokument ist dem System bekannt.
    Bitte beachten Sie jedoch, dass ein Teil oder Teile der Forderung abgetreten wurden.
    Der dazugehörige Darlehensvertrag, sowie die weiteren Übertragungszertifikate können ebenfalls über diese Dokumentenprüfung validiert werden.`,
  },
  l: {
    id: "pdf.verification.cssd.case.l",
    defaultMessage: `Bitte beachten Sie, dass Sie das Originaldokument hochladen müssen. Bei Änderungen oder gescannten Dokumenten ist eine Prüfung per Upload nicht möglich.`,
  },
});
