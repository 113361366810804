import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  footer: {
    position: "fixed",
    bottom: 0,
    right: 10,
  },
});

const Footer = ({ classes }) => {
  return (
    <div className={classes.footer}>
      <p>{process.env.REACT_APP_VERSION_NUMBER}</p>
    </div>
  );
};

export default withStyles(styles)(Footer);
