import { contractCache } from "services/smartContracts/smartContracts";

import { sendTransaction, getBSSDControllerContractMethods } from "./commons";

export async function terminationModify(
  account,
  arranger,
  ssdId,
  ssdHash,
  terminationHash,
  terminationType,
  terminationDate
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .terminationModify(arranger, ssdId, ssdHash, terminationHash, terminationType, terminationDate)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function terminationModifyAndApprove(
  account,
  arranger,
  ssdId,
  ssdHash,
  terminationHash,
  terminationType,
  terminationDate
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .terminationModifyAndApprove(
      arranger,
      ssdId,
      terminationHash,
      ssdHash,
      terminationType,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function terminationApprove(account, arranger, ssdId, ssdHash, terminationHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .terminationApprove(arranger, ssdId, ssdHash, terminationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function terminationDelete(account, arranger, ssdId, ssdHash, terminationHash) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .terminationDelete(arranger, ssdId, ssdHash, terminationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}
