import {
  SAVE_CSSDS_REQUEST,
  SAVE_CSSDS_SUCCESS,
  SAVE_CSSDS_FAILURE,
  SAVE_CONFIRM_CSSDS_REQUEST,
  SAVE_CONFIRM_CSSDS_SUCCESS,
  SAVE_CONFIRM_CSSDS_FAILURE,
  CONFIRM_CSSD_REQUEST,
  CONFIRM_CSSD_FAILURE,
  CONFIRM_CSSD_SUCCESS,
  ACCEPT_CSSD_REQUEST,
  ACCEPT_CSSD_SUCCESS,
  ACCEPT_CSSD_FAILURE,
  REJECT_CSSD_REQUEST,
  REJECT_CSSD_SUCCESS,
  REJECT_CSSD_FAILURE,
  CANCEL_CSSD_REQUEST,
  CANCEL_CSSD_SUCCESS,
  CANCEL_CSSD_FAILURE,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_CLOSE_DIALOG,
  UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_OPEN_DIALOG,
  DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST,
  DOWNLOAD_CSSD_CONFIRMATION_PDF_SUCCESS,
  DOWNLOAD_CSSD_CONFIRMATION_PDF_FAILURE,
  DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST,
  DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_SUCCESS,
  DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_FAILURE,
  UPDATE_CSSD_REQUEST,
  UPDATE_CSSD_SUCCESS,
  UPDATE_CSSD_FAILURE,
  UPDATE_ACCEPT_CSSD_REQUEST,
  UPDATE_ACCEPT_CSSD_SUCCESS,
  UPDATE_ACCEPT_CSSD_FAILURE,
  DOWNLOAD_URKUNDE_PDF_REQUEST,
  DOWNLOAD_URKUNDE_PDF_SUCCESS,
  DOWNLOAD_URKUNDE_PDF_FAILURE,
  CSSD_REPAYMENT_RECEIVED_REQUEST,
  CSSD_REPAYMENT_RECEIVED_SUCCESS,
  CSSD_REPAYMENT_RECEIVED_FAILURE,
  OPEN_CONFIRM_REPAYMENT_RECEIVED_DIALOG,
  CLOSE_CONFIRM_REPAYMENT_RECEIVED_DIALOG,
  EDIT_CSSD_SETTLEMENT_INFORMATION,
  SAVE_CSSD_SETTLEMENT_INFORMATION,
  CLOSE_CSSD_SETTLEMENT_INFORMATION,
  SAVE_CSSD_SETTLEMENT_INFORMATION_FAILURE,
  ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
  ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS,
  ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE,
  REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST,
  REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS,
  REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE,
} from "./actions";

// reducer with initial state
const initialState = {
  saveCssdPending: false,
  saveAndConfirmCssdPending: false,
  confirmCssdPending: false,
  acceptCssdPending: false,
  cancelCssdPending: false,
  downloadCssdPdfPending: false,
  rejectCssdPending: false,
  uploadDialogIsOpen: false,
  repaymentReceivedPending: false,
  confirmRepaymentReceivedDialog: false,
  editSettlement: null,
  pendingSettlementInformation: false,
  acceptDocumentsPending: false,
  rejectDocumentsPending: false,
};

function acceptCssdRequestReducer(state) {
  return {
    ...state,
    acceptCssdPending: true,
  };
}

function acceptCssdSuccessReducer(state) {
  return {
    ...state,
    acceptCssdPending: false,
  };
}

function acceptCssdFailureReducer(state) {
  return {
    acceptCssdPending: false,
  };
}

function saveCssdRequestReducer(state) {
  return {
    ...state,
    saveCssdPending: true,
  };
}

function saveCssdSuccessReducer(state) {
  return {
    ...state,
    saveCssdPending: false,
  };
}

function saveCssdFailureReducer(state) {
  return {
    saveCssdPending: false,
  };
}

function saveAndConfirmCssdRequestReducer(state) {
  return {
    ...state,
    saveAndConfirmCssdPending: true,
  };
}

function saveAndConfirmCssdSuccessReducer(state) {
  return {
    ...state,
    saveAndConfirmCssdPending: false,
  };
}

function saveAndConfirmCssdFailureReducer(state) {
  return {
    ...state,
    saveAndConfirmCssdPending: false,
  };
}

function confirmCssdRequestReducer(state) {
  return {
    ...state,
    confirmCssdPending: true,
  };
}

function confirmCssdSuccessReducer(state) {
  return {
    ...state,
    confirmCssdPending: false,
  };
}

function confirmCssdFailureReducer(state) {
  return {
    ...state,
    confirmCssdPending: false,
  };
}

function rejectCssdRequestReducer(state) {
  return {
    ...state,
    rejectCssdPending: true,
  };
}

function rejectCssdSuccessReducer(state) {
  return {
    ...state,
    rejectCssdPending: false,
  };
}

function rejectCssdFailureReducer(state) {
  return {
    ...state,
    rejectCssdPending: false,
  };
}

function cancelCssdRequestReducer(state) {
  return {
    ...state,
    cancelCssdPending: true,
  };
}
function cancelCssdSuccessReducer(state) {
  return {
    ...state,
    cancelCssdPending: false,
  };
}
function cancelCssdFailureReducer(state) {
  return {
    ...state,
    cancelCssdPending: false,
  };
}

function uploadPDFCssdRequestReducer(state) {
  return {
    ...state,
    updateCssdPending: true,
  };
}

function uploadPDFCssdSuccessReducer(state) {
  return {
    ...state,
    updateCssdPending: false,
    uploadDialogIsOpen: false,
  };
}

function uploadPDFCssdFailureReducer(state) {
  return {
    ...state,
    updateCssdPending: false,
  };
}
function uploadPDFCloseDialogReducer(state) {
  return {
    ...state,
    uploadDialogIsOpen: null,
  };
}
function uploadPDFOpenDialogReducer(state, action) {
  return {
    ...state,
    uploadDialogIsOpen: action.cssd,
  };
}
function downloadCssdConfirmationPdfRequestReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: true,
  };
}
function downloadCssdConfirmationPdfSuccessReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}
function downloadCssdConfirmationPdfFailureReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}

function downloadUrkundeZahlstellenvertragPdfRequestReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: true,
  };
}
function downloadUrkundeZahlstellenvertragPdfSuccessReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}
function downloadUrkundeZahlstellenvertragPdfFailureReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}

function downloadUrkundePdfRequestReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: true,
  };
}
function downloadUrkundePdfSuccessReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}
function downloadUrkundePdfFailureReducer(state) {
  return {
    ...state,
    downloadCssdPdfPending: false,
  };
}

const cssdRepaymentReceivedRequestReducer = (state) => ({
  ...state,
  repaymentReceivedPending: true,
});

const cssdRepaymentReceivedSuccessReducer = (state) => ({
  ...state,
  repaymentReceivedPending: false,
});

const cssdRepaymentReceivedFailureReducer = (state) => ({
  ...state,
  repaymentReceivedPending: false,
});

const openConfirmRepaymentReceivedDialogReducer = (state, action) => ({
  ...state,
  confirmRepaymentReceivedDialog: action.cssd,
});

const closeConfirmRepaymentReceivedDialogReducer = (state) => ({
  ...state,
  confirmRepaymentReceivedDialog: false,
});

const openSettlementDialog = (state, action) => ({
  ...state,
  editSettlement: action.cssd,
});

const closeSettlementDialog = (state, action) => ({
  ...state,
  editSettlement: null,
  pendingSettlementInformation: false,
});

const resetPendingSettlementDialog = (state, action) => ({
  ...state,
  pendingSettlementInformation: false,
});

const pendingSettlementDialog = (state, action) => ({
  ...state,
  pendingSettlementInformation: true,
});

const confirmUrkundeAndZahlstellenvertragRequestReducer = (state) => ({
  ...state,
  acceptDocumentsPending: true,
});

const confirmUrkundeAndZahlstellenvertragSuccessReducer = (state) => ({
  ...state,
  acceptDocumentsPending: false,
});

const confirmUrkundeAndZahlstellenvertragFailureReducer = (state) => ({
  ...state,
  acceptDocumentsPending: false,
});

const rejectUrkundeAndZahlstellenvertragRequestReducer = (state) => ({
  ...state,
  rejectDocumentsPending: true,
});

const rejectUrkundeAndZahlstellenvertragSuccessReducer = (state) => ({
  ...state,
  rejectDocumentsPending: false,
});

const rejectUrkundeAndZahlstellenvertragFailureReducer = (state) => ({
  ...state,
  rejectDocumentsPending: false,
});

export function cssdActionReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CSSDS_REQUEST:
      return saveCssdRequestReducer(state, action);
    case SAVE_CSSDS_SUCCESS:
      return saveCssdSuccessReducer(state, action);
    case SAVE_CSSDS_FAILURE:
      return saveCssdFailureReducer(state, action);

    case UPDATE_CSSD_REQUEST:
      return saveCssdRequestReducer(state, action);
    case UPDATE_CSSD_SUCCESS:
      return saveCssdSuccessReducer(state, action);
    case UPDATE_CSSD_FAILURE:
      return saveCssdFailureReducer(state, action);

    case UPDATE_ACCEPT_CSSD_REQUEST:
      return saveAndConfirmCssdRequestReducer(state, action);
    case UPDATE_ACCEPT_CSSD_SUCCESS:
      return saveAndConfirmCssdSuccessReducer(state, action);
    case UPDATE_ACCEPT_CSSD_FAILURE:
      return saveAndConfirmCssdFailureReducer(state, action);

    case SAVE_CONFIRM_CSSDS_REQUEST:
      return saveAndConfirmCssdRequestReducer(state, action);
    case SAVE_CONFIRM_CSSDS_SUCCESS:
      return saveAndConfirmCssdSuccessReducer(state, action);
    case SAVE_CONFIRM_CSSDS_FAILURE:
      return saveAndConfirmCssdFailureReducer(state, action);

    case CONFIRM_CSSD_REQUEST:
      return confirmCssdRequestReducer(state, action);
    case CONFIRM_CSSD_SUCCESS:
      return confirmCssdSuccessReducer(state, action);
    case CONFIRM_CSSD_FAILURE:
      return confirmCssdFailureReducer(state, action);

    case ACCEPT_CSSD_REQUEST:
      return acceptCssdRequestReducer(state, action);
    case ACCEPT_CSSD_SUCCESS:
      return acceptCssdSuccessReducer(state, action);
    case ACCEPT_CSSD_FAILURE:
      return acceptCssdFailureReducer(state, action);

    case REJECT_CSSD_REQUEST:
      return rejectCssdRequestReducer(state, action);
    case REJECT_CSSD_SUCCESS:
      return rejectCssdSuccessReducer(state, action);
    case REJECT_CSSD_FAILURE:
      return rejectCssdFailureReducer(state, action);

    case CANCEL_CSSD_REQUEST:
      return cancelCssdRequestReducer(state, action);
    case CANCEL_CSSD_SUCCESS:
      return cancelCssdSuccessReducer(state, action);
    case CANCEL_CSSD_FAILURE:
      return cancelCssdFailureReducer(state, action);

    case UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST:
      return uploadPDFCssdRequestReducer(state, action);
    case UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS:
      return uploadPDFCssdSuccessReducer(state, action);
    case UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE:
      return uploadPDFCssdFailureReducer(state, action);
    case UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_CLOSE_DIALOG:
      return uploadPDFCloseDialogReducer(state, action);
    case UPLOAD_URKUNDE_AND_ZAHLSTELLENVERTRAG_OPEN_DIALOG:
      return uploadPDFOpenDialogReducer(state, action);

    case DOWNLOAD_CSSD_CONFIRMATION_PDF_REQUEST:
      return downloadCssdConfirmationPdfRequestReducer(state, action);
    case DOWNLOAD_CSSD_CONFIRMATION_PDF_SUCCESS:
      return downloadCssdConfirmationPdfSuccessReducer(state, action);
    case DOWNLOAD_CSSD_CONFIRMATION_PDF_FAILURE:
      return downloadCssdConfirmationPdfFailureReducer(state, action);

    case DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_REQUEST:
      return downloadUrkundeZahlstellenvertragPdfRequestReducer(state, action);
    case DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_SUCCESS:
      return downloadUrkundeZahlstellenvertragPdfSuccessReducer(state, action);
    case DOWNLOAD_URKUNDE_ZAHLSTELLVERTRAG_PDF_FAILURE:
      return downloadUrkundeZahlstellenvertragPdfFailureReducer(state, action);

    case DOWNLOAD_URKUNDE_PDF_REQUEST:
      return downloadUrkundePdfRequestReducer(state, action);
    case DOWNLOAD_URKUNDE_PDF_SUCCESS:
      return downloadUrkundePdfSuccessReducer(state, action);
    case DOWNLOAD_URKUNDE_PDF_FAILURE:
      return downloadUrkundePdfFailureReducer(state, action);

    case CSSD_REPAYMENT_RECEIVED_REQUEST:
      return cssdRepaymentReceivedRequestReducer(state);
    case CSSD_REPAYMENT_RECEIVED_SUCCESS:
      return cssdRepaymentReceivedSuccessReducer(state);
    case CSSD_REPAYMENT_RECEIVED_FAILURE:
      return cssdRepaymentReceivedFailureReducer(state);

    case OPEN_CONFIRM_REPAYMENT_RECEIVED_DIALOG:
      return openConfirmRepaymentReceivedDialogReducer(state, action);
    case CLOSE_CONFIRM_REPAYMENT_RECEIVED_DIALOG:
      return closeConfirmRepaymentReceivedDialogReducer(state);

    case EDIT_CSSD_SETTLEMENT_INFORMATION:
      return openSettlementDialog(state, action);
    case SAVE_CSSD_SETTLEMENT_INFORMATION:
      return pendingSettlementDialog(state, action);
    case SAVE_CSSD_SETTLEMENT_INFORMATION_FAILURE:
      return resetPendingSettlementDialog(state, action);
    case CLOSE_CSSD_SETTLEMENT_INFORMATION:
      return closeSettlementDialog(state, action);

    case ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST:
      return confirmUrkundeAndZahlstellenvertragRequestReducer(state);
    case ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS:
      return confirmUrkundeAndZahlstellenvertragSuccessReducer(state);
    case ACCEPT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE:
      return confirmUrkundeAndZahlstellenvertragFailureReducer(state);

    case REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_REQUEST:
      return rejectUrkundeAndZahlstellenvertragRequestReducer(state);
    case REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_SUCCESS:
      return rejectUrkundeAndZahlstellenvertragSuccessReducer(state);
    case REJECT_URKUNDE_AND_ZAHLSTELLENVERTRAG_FAILURE:
      return rejectUrkundeAndZahlstellenvertragFailureReducer(state);

    default:
      return state;
  }
}
