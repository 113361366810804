import React from "react";
import { connect } from "react-redux";
import history from "util/history";
import { formatDate } from "util/convertor";
import { Grid, Typography, useTheme, withStyles } from "@material-ui/core";
import { verificationHeader, verificationText } from "./verificationText";

import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import BackButton from "components/Shared/Button/BackButton";
import { getVerificationColor, VerificationType } from "../verificationColors";

export const pdf_verification_styles = (theme) => ({
  panel: {
    padding: theme.spacing(2),
    paddingTop: 15,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "50%",
    marginTop: 50,
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },
});

const CSSDDocumentTypes = {
  URKUNDE: 0,
  ZAHLSTELLENVERTRAG: 1,
  UEBERTRAGUNGSZERTIFIKAT: 2,
  KUENDIGUNGSURKUNDE: 3,
};

const PdfVerification = ({ pdfStatus, classes }) => {
  const theme = useTheme();

  const handleBack = () => {
    history.push("/login");
  };

  const getMessage = (
    isSigned,
    documentType,
    cssdIsRepaid,
    isTerminated,
    partialClaimIsRepaid,
    partialClaimIsTerminated,
    hasAdditionalCessions,
    cssdTerminationDate,
    partialClaimIsCancelled
  ) => {
    const terminationDateFormatted = formatDate(new Date(cssdTerminationDate * 1000));

    if (isSigned) {
      if (documentType === CSSDDocumentTypes.URKUNDE) {
        if (cssdIsRepaid) {
          return {
            title: verificationHeader.valid(),
            body: verificationText.B(),
            verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
          };
        }
        if (isTerminated) {
          return {
            title: verificationHeader.valid(),
            body: verificationText.C(terminationDateFormatted),
            verificationColor: getVerificationColor(theme, VerificationType.WARN),
          };
        }
        return {
          title: verificationHeader.valid(),
          body: verificationText.A(),
          verificationColor: getVerificationColor(theme, VerificationType.VALID),
        };
      } else if (documentType === CSSDDocumentTypes.UEBERTRAGUNGSZERTIFIKAT) {
        if (partialClaimIsCancelled) {
          return {
            title: verificationHeader.notValid(),
            body: verificationText.L(),
            verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
            };
        }
        if (partialClaimIsRepaid) {
          return {
            title: verificationHeader.valid(),
            body: verificationText.D(),
            verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
          };
        }
        if (partialClaimIsTerminated) {
          if (!hasAdditionalCessions) {
            return {
              title: verificationHeader.valid(),
              body: verificationText.E(terminationDateFormatted),
              verificationColor: getVerificationColor(theme, VerificationType.WARN),
            };
          } else {
            return {
              title: verificationHeader.valid(),
              body: verificationText.F(terminationDateFormatted),
              verificationColor: getVerificationColor(theme, VerificationType.WARN),
            };
          }
        }
        if (!hasAdditionalCessions) {
          return {
            title: verificationHeader.valid(),
            body: verificationText.G(),
            verificationColor: getVerificationColor(theme, VerificationType.VALID),
          };
        } else {
          return {
            title: verificationHeader.valid(),
            body: verificationText.H(),
            verificationColor: getVerificationColor(theme, VerificationType.VALID),
          };
        }
      } else {
        return {
          title: verificationHeader.notValid(),
          body: verificationText.L(),
          verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
        };
      }
    } else {
      return {
        title: verificationHeader.notValid(),
        body: verificationText.L(),
        verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
      };
    }
  };

  const {
    isSigned,
    documentType,
    cssdIsRepaid,
    isTerminated,
    partialClaimIsRepaid,
    partialClaimIsTerminated,
    hasAdditionalCessions,
    cssdTerminationDate,
    partialClaimIsCancelled
  } = pdfStatus;

  const message = getMessage(
    isSigned,
    documentType,
    cssdIsRepaid,
    isTerminated,
    partialClaimIsRepaid,
    partialClaimIsTerminated,
    hasAdditionalCessions,
    cssdTerminationDate,
    partialClaimIsCancelled
  );

  return (
    <Grid
      container
      spacing={3}
      className={classes.panel}
      style={{
        backgroundColor: message.verificationColor.bgColor,
        borderColor: message.verificationColor.borderColor,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: message.verificationColor.color }}>
          {message.title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: message.verificationColor.color,
            lineHeight: 1.7,
            marginTop: 15,
          }}
        >
          {message.body}
        </Typography>
        <br />
        <BackButton onClick={handleBack} color={message.verificationColor.buttonColor}>
          <FormattedMessage {...messages.pdf_verification_button_text_zurueck} />
        </BackButton>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    pdfStatus: state.pdfVerificationReducer.pdfStatus,
  };
};

export default withStyles(pdf_verification_styles)(connect(mapStateToProps, {})(PdfVerification));
