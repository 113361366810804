import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_user_information_upload_success: {
    id: "redux.user.information.upload.success",
    defaultMessage: "Dokument wurde erfolgreich gespeichert.",
  },
  redux_user_information_upload_failure: {
    id: "redux.user.information.upload.failure",
    defaultMessage: "Dokument konnte nicht gespeichert werden.",
  },
  redux_user_information_list_failure: {
    id: "redux.user.information.list.failure",
    defaultMessage: "Nutzerinformationen konnten nicht geladen werden.",
  },
  redux_user_information_download_failure: {
    id: "redux.user.information.download.failure",
    defaultMessage: "Dokument konnte nicht heruntergeladen werden.",
  },
  redux_user_information_delete_success: {
    id: "redux.user.information.delete.success",
    defaultMessage: "Dokument erfolgreich gelöscht.",
  },
  redux_user_information_delete_failure: {
    id: "redux.user.information.delete.failure",
    defaultMessage: "Dokument konnte nicht gelöscht werden.",
  },

  redux_user_information_update_success: {
    id: "redux.user.information.update.success",
    defaultMessage: "Dokument wurde erfolgreich aktualisiert.",
  },
  redux_user_information_update_failure: {
    id: "redux.user.information.update.failure",
    defaultMessage: "Dokument konnte nicht aktualisiert werden.",
  },
});
