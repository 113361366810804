import {
  GET_BUSINESS_PARTNERS_SUCCESS,
  GET_BUSINESS_PARTNER_INVITES_SUCCESS,
  GET_BP_PLATFORM_MEMBERS_SUCCESS,
  GET_BP_PLATFORM_MEMBER_DETAILS_SUCCESS,
  FILTER_BP_PLATFORM_MEMBERS,
  RESET_BP_FILTER_PLATFORM_MEMBERS,
  INVITE_BUSINESS_PARTNER_SEND,
  INVITE_BUSINESS_PARTNER_SUCCESS,
  INVITE_BUSINESS_PARTNER_FAILURE,
  ACCEPT_BUSINESS_PARTNER_SUCCESS,
  ACCEPT_BUSINESS_PARTNER_FAILURE,
  REJECT_BUSINESS_PARTNER_SUCCESS,
  REJECT_BUSINESS_PARTNER_FAILURE,
  ACCEPT_BUSINESS_PARTNER_REQUEST,
  REJECT_BUSINESS_PARTNER_REQUEST,
  OPEN_REJECT_REASON_DIALOG,
  CLOSE_REJECT_REASON_DIALOG,
  EDIT_BUSINESSPARTNER_PARTNER_ID,
  CLOSE_EDIT_BUSINESSPARTNER_PARTNER_ID,
  SAVE_BUSINESSPARTNER_PARTNER_ID,
  EDIT_BUSINESSPARTNER_PARTNER_ID_FAILURE,
  EDIT_BUSINESSPARTNER_PARTNER_ID_SUCCESS,
  ASSIGN_GP_TO_TRADE_OPEN,
  ASSIGN_GP_TO_TRADE_CLOSE,
  ASSIGN_GP_TO_TRADE_REQUEST,
  ASSIGN_GP_TO_TRADE_FAILURE,
  ASSIGN_GP_TO_TRADE_SUCCESS,
  DISCONNECT_GP_AND_TRADE_SUCCESS,
  DISCONNECT_GP_AND_TRADE_FAILURE,
  DISCONNECT_GP_AND_TRADE_REQUEST,
  GET_BP_PLATFORM_MEMBER_DETAILS_FAILURE,
  GET_BP_PLATFORM_MEMBER_DETAILS_RETRY,
} from "./actions";
import { onlyUnique } from "../../util/convertor";

// reducer with initial state
const initialState = {
  numPlatformMembers: 0,
  inviteIsPending: false,
  acceptIsPending: false,
  rejectIsPending: false,
  businessPartners: [],
  businessPartnerInvites: [],
  filteredPlatformMembers: [],
  platformMembers: [],
  companyInfo: {},
  rejectReasonDialogIsOpen: false,
  editPartnerId: null,
  loading: false,
  assignGPDialogOpen: false,
  assignGPTrade: {},
  isAssignGPToTradePending: false,
  loadErrors: {},
};

function openAssignGPDialogReducer(state, action) {
  return {
    ...state,
    assignGPDialogOpen: true,
    assignGPTrade: action.trade,
  };
}

function closeAssignGPDialogReducer(state, action) {
  return {
    ...state,
    assignGPDialogOpen: false,
    assignGPTrade: {},
  };
}

function fetchBusinessPartnersSuccessReducer(state, action) {
  return {
    ...state,
    businessPartners: action.businessPartners,
  };
}

function updatePlatformemberDetail(platformMembers, details) {
  const isNew =
    platformMembers.filter((entry) => entry.idProxyAddress === details.idProxyAddress).length === 0;
  if (isNew) {
    return [...platformMembers, { ...details }];
  } else {
    return [...platformMembers].map((member) => {
      if (member.idProxyAddress === details.idProxyAddress) {
        return { ...details };
      }
      return member;
    });
  }
}

function getPlatformMemberDetailsSuccessReducer(state, action) {
  const platformMembers = updatePlatformemberDetail(state.platformMembers, action.details);
  return {
    ...state,
    platformMembers,
    filteredPlatformMembers: platformMembers,
  };
}

function getPlatformMemberDetailsFailureReducer(state, action) {
  return {
    ...state,
    loadErrors: {
      ...state.loadErrors,
      [action.idProxyAddr]:
        state.loadErrors[action.idProxyAddr] != null
          ? [...state.loadErrors[action.idProxyAddr], action.idProxyAddr].filter(onlyUnique)
          : [action.idProxyAddr],
    },
  };
}

function getPlatformMemberDetailsRetryReducer(state, action) {
  return {
    ...state,
    loadErrors: action.idProxyAddr ? { ...state.loadErrors, [action.idProxyAddr]: [] } : {},
  };
}

function getPlatformMembersSuccessReducer(state, action) {
  return {
    ...state,
    numPlatformMembers: action.platformMembers.length,
    loadErrors: initialState.loadErrors,
    platformMembers: initialState.platformMembers,
    filteredPlatformMembers: initialState.platformMembers,
  };
}

function filterPlatformmembers(platformMembers, filter) {
  let result = [...platformMembers];

  if (filter.status === "pending") {
    result = result.filter((member) => !member.active && member.pending);
  }

  if (filter.status === "active") {
    result = result.filter((member) => member.active && !member.pending);
  }

  if (filter.status === "inactive") {
    result = result.filter((member) => !member.active && !member.pending);
  }

  if (filter.name !== "") {
    result = result.filter(
      (member) => member.name && member.name.toUpperCase().includes(filter.name.toUpperCase())
    );
  }
  if (filter.digitsKennung !== "") {
    result = result.filter(
      (member) =>
        member.digitsKennung &&
        member.digitsKennung.toUpperCase().includes(filter.digitsKennung.toUpperCase())
    );
  }
  return result;
}

function filterPlatformmembersReducer(state, action) {
  const filteredPlatformMembers = filterPlatformmembers(state.platformMembers, action.filter);
  return {
    ...state,
    filteredPlatformMembers,
  };
}

function resetFilterPlatformMembersReducer(state) {
  return {
    ...state,
    filteredPlatformMembers: state.platformMembers,
  };
}

function fetchBusinessPartnerInvitesSuccessReducer(state, action) {
  return {
    ...state,
    businessPartnerInvites: action.businessPartnerInvites,
  };
}

function inviteBusinessPartnerRequestReducer(state) {
  return {
    ...state,
    inviteIsPending: true,
  };
}

function inviteBusinessPartnerSuccessReducer(state) {
  return {
    ...state,
    inviteIsPending: false,
  };
}

function inviteBusinessPartnerFailureReducer(state) {
  return {
    ...state,
    inviteIsPending: false,
  };
}

function acceptBusinessPartnerRequestReducer(state) {
  return {
    ...state,
    acceptIsPending: true,
  };
}

function acceptBusinessPartnerSuccessReducer(state) {
  return {
    ...state,
    acceptIsPending: false,
  };
}

function acceptBusinessPartnerFailureReducer(state) {
  return {
    ...state,
    acceptIsPending: false,
  };
}

function rejectBusinessPartnerRequestReducer(state) {
  return {
    ...state,
    rejectIsPending: true,
  };
}

function rejectBusinessPartnerSuccessReducer(state) {
  return {
    ...state,
    rejectIsPending: false,
    rejectReasonDialogIsOpen: false,
  };
}

function rejectBusinessPartnerFailureReducer(state) {
  return {
    ...state,
    rejectIsPending: false,
    rejectReasonDialogIsOpen: false,
  };
}

function openRejectReasonDialogReducer(state, action) {
  return {
    ...state,
    companyInfo: action.companyInfo,
    rejectReasonDialogIsOpen: true,
  };
}

function closeRejectReasonDialogReducer(state) {
  return {
    ...state,
    companyInfo: {},
    rejectReasonDialogIsOpen: false,
  };
}

function editBusinesspartnerPartnerId(state, action) {
  return {
    ...state,
    editPartnerId: action.partner,
  };
}

function closeEditBusinesspartnerPartnerId(state) {
  return {
    ...state,
    editPartnerId: null,
  };
}

function setLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function resetLoading(state) {
  return {
    ...state,
    loading: false,
  };
}

function isAssignGPToTradePendingReducer(state, action) {
  return {
    ...state,
    isAssignGPToTradePending: action.type,
  };
}

export function businessPartnerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESS_PARTNER_INVITES_SUCCESS:
      return fetchBusinessPartnerInvitesSuccessReducer(state, action);

    case GET_BP_PLATFORM_MEMBER_DETAILS_SUCCESS:
      return getPlatformMemberDetailsSuccessReducer(state, action);

    case GET_BP_PLATFORM_MEMBERS_SUCCESS:
      return getPlatformMembersSuccessReducer(state, action);

    case GET_BP_PLATFORM_MEMBER_DETAILS_FAILURE:
      return getPlatformMemberDetailsFailureReducer(state, action);

    case GET_BP_PLATFORM_MEMBER_DETAILS_RETRY:
      return getPlatformMemberDetailsRetryReducer(state, action);

    case GET_BUSINESS_PARTNERS_SUCCESS:
      return fetchBusinessPartnersSuccessReducer(state, action);

    case FILTER_BP_PLATFORM_MEMBERS:
      return filterPlatformmembersReducer(state, action);

    case RESET_BP_FILTER_PLATFORM_MEMBERS:
      return resetFilterPlatformMembersReducer(state, action);

    case INVITE_BUSINESS_PARTNER_SEND:
      return inviteBusinessPartnerRequestReducer(state, action);

    case INVITE_BUSINESS_PARTNER_SUCCESS:
      return inviteBusinessPartnerSuccessReducer(state, action);
    case INVITE_BUSINESS_PARTNER_FAILURE:
      return inviteBusinessPartnerFailureReducer(state, action);

    case ACCEPT_BUSINESS_PARTNER_REQUEST:
      return acceptBusinessPartnerRequestReducer(state, action);
    case ACCEPT_BUSINESS_PARTNER_SUCCESS:
      return acceptBusinessPartnerSuccessReducer(state, action);
    case ACCEPT_BUSINESS_PARTNER_FAILURE:
      return acceptBusinessPartnerFailureReducer(state, action);

    case REJECT_BUSINESS_PARTNER_REQUEST:
      return rejectBusinessPartnerRequestReducer(state, action);
    case REJECT_BUSINESS_PARTNER_SUCCESS:
      return rejectBusinessPartnerSuccessReducer(state, action);
    case REJECT_BUSINESS_PARTNER_FAILURE:
      return rejectBusinessPartnerFailureReducer(state, action);

    case OPEN_REJECT_REASON_DIALOG:
      return openRejectReasonDialogReducer(state, action);
    case CLOSE_REJECT_REASON_DIALOG:
      return closeRejectReasonDialogReducer(state, action);

    case EDIT_BUSINESSPARTNER_PARTNER_ID:
      return editBusinesspartnerPartnerId(state, action);
    case CLOSE_EDIT_BUSINESSPARTNER_PARTNER_ID:
      return closeEditBusinesspartnerPartnerId(state, action);
    case SAVE_BUSINESSPARTNER_PARTNER_ID:
      return setLoading(state, action);
    case EDIT_BUSINESSPARTNER_PARTNER_ID_FAILURE:
    case EDIT_BUSINESSPARTNER_PARTNER_ID_SUCCESS:
      return resetLoading(state, action);

    case ASSIGN_GP_TO_TRADE_OPEN:
      return openAssignGPDialogReducer(state, action);
    case ASSIGN_GP_TO_TRADE_CLOSE:
      return closeAssignGPDialogReducer(state, action);

    case ASSIGN_GP_TO_TRADE_REQUEST:
    case DISCONNECT_GP_AND_TRADE_REQUEST:
      return isAssignGPToTradePendingReducer(state, action);
    case ASSIGN_GP_TO_TRADE_FAILURE:
    case DISCONNECT_GP_AND_TRADE_FAILURE:
      return isAssignGPToTradePendingReducer(state, action);
    case ASSIGN_GP_TO_TRADE_SUCCESS:
    case DISCONNECT_GP_AND_TRADE_SUCCESS:
      return isAssignGPToTradePendingReducer(state, action);

    default:
      return state;
  }
}
