function action(type, payload = {}) {
  return { type, ...payload };
}

export const UPLOAD_USER_INFORMATION_OPEN_DIALOG = "UPLOAD_USER_INFORMATION_OPEN_DIALOG";
export const UPLOAD_USER_INFORMATION_CLOSE_DIALOG = "UPLOAD_USER_INFORMATION_CLOSE_DIALOG";

export const dialogActions = {
  open: (documentForUpdate) => action(UPLOAD_USER_INFORMATION_OPEN_DIALOG, { documentForUpdate }),
  close: () => action(UPLOAD_USER_INFORMATION_CLOSE_DIALOG),
};

export const UPLOAD_USER_INFORMATION_REQUEST = "UPLOAD_USER_INFORMATION_REQUEST";
export const UPLOAD_USER_INFORMATION_SUCCESS = "UPLOAD_USER_INFORMATION_SUCCESS";
export const UPLOAD_USER_INFORMATION_FAILURE = "UPLOAD_USER_INFORMATION_FAILURE";

export const uploadUserInformationActions = {
  request: (documentName, document, filename, fileType, visibleToUKsOnly) =>
    action(UPLOAD_USER_INFORMATION_REQUEST, {
      documentName,
      document,
      filename,
      fileType,
      visibleToUKsOnly,
    }),
  success: () => action(UPLOAD_USER_INFORMATION_SUCCESS),
  failure: (error) => action(UPLOAD_USER_INFORMATION_FAILURE, { error }),
};

export const LIST_USER_INFORMATION_REQUEST = "LIST_USER_INFORMATION_REQUEST";
export const LIST_USER_INFORMATION_SUCCESS = "LIST_USER_INFORMATION_SUCCESS";
export const LIST_USER_INFORMATION_FAILURE = "LIST_USER_INFORMATION_FAILURE";

export const listUserInformationActions = {
  request: () => action(LIST_USER_INFORMATION_REQUEST),
  success: (documents) => action(LIST_USER_INFORMATION_SUCCESS, { documents }),
  failure: (error) => action(LIST_USER_INFORMATION_FAILURE, { error }),
};

export const DOWNLOAD_USER_INFORMATION_REQUEST = "DOWNLOAD_USER_INFORMATION_REQUEST";
export const DOWNLOAD_USER_INFORMATION_SUCCESS = "DOWNLOAD_USER_INFORMATION_SUCCESS";
export const DOWNLOAD_USER_INFORMATION_FAILURE = "DOWNLOAD_USER_INFORMATION_FAILURE";

export const downloadUserInformationActions = {
  request: (dateiId) => action(DOWNLOAD_USER_INFORMATION_REQUEST, { dateiId }),
  success: (dateiId) => action(DOWNLOAD_USER_INFORMATION_SUCCESS, { dateiId }),
  failure: (dateiId) => action(DOWNLOAD_USER_INFORMATION_FAILURE, { dateiId }),
};

export const DELETE_USER_INFORMATION_REQUEST = "DELETE_USER_INFORMATION_REQUEST";
export const DELETE_USER_INFORMATION_SUCCESS = "DELETE_USER_INFORMATION_SUCCESS";
export const DELETE_USER_INFORMATION_FAILURE = "DELETE_USER_INFORMATION_FAILURE";

export const deleteUserInformationActions = {
  request: (dateiId) => action(DELETE_USER_INFORMATION_REQUEST, { dateiId }),
  success: (dateiId) => action(DELETE_USER_INFORMATION_SUCCESS, { dateiId }),
  failure: (dateiId) => action(DELETE_USER_INFORMATION_FAILURE, { dateiId }),
};

export const UPDATE_USER_INFORMATION_REQUEST = "UPDATE_USER_INFORMATION_REQUEST";
export const UPDATE_USER_INFORMATION_SUCCESS = "UPDATE_USER_INFORMATION_SUCCESS";
export const UPDATE_USER_INFORMATION_FAILURE = "UPDATE_USER_INFORMATION_FAILURE";

export const updateUserInformationActions = {
  request: (dateiId, document, filename, fileType, username) =>
    action(UPDATE_USER_INFORMATION_REQUEST, {
      dateiId,
      document,
      filename,
      fileType,
      username,
    }),
  success: () => action(UPDATE_USER_INFORMATION_SUCCESS),
  failure: (error) => action(UPDATE_USER_INFORMATION_FAILURE, { error }),
};
