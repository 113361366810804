import web3 from "util/web3";
import { contractCache } from "services/smartContracts/smartContracts";
import Contracts from "../smartContracts/contracts/contracts";
import { PRIVILEGE_CONTROLLER_RECOVERY, PRIVILEGE_CONTROLLER_COORDINATOR } from "util/constants";
import { sendTransaction } from "./commons";

import {
  getCompanyMasterControllerAddr,
  getCompanyMasterController,
} from "services/smartContracts/smartContracts";

export function getPlatformMemberForRecovery(recovererAddr) {
  return contractCache.platformManagerContract
    .getPastEvents("MemberOnboardedEvent", {
      fromBlock: 0,
      toBlock: "latest",
    })
    .then(async function (events) {
      const companies = [];
      for (const event of [...events]) {
        const result = await getCompanyIdentityForRecovery(
          event.returnValues.idProxy,
          recovererAddr
        );
        if (result) {
          companies.push(result);
        }
      }
      return companies;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getPmforIdProx(companyIdProxyAddr) {
  const companyIdentityProxy = getCompanyIdentityProxy(companyIdProxyAddr);

  const companyMasterControllerAddr = await getCompanyMasterControllerAddr(companyIdentityProxy);

  const companyMasterController = getCompanyMasterController(companyMasterControllerAddr);

  const companyIPrivelegeManagerAddr = await getCompanyIPrivelegeManagerAddr(
    companyMasterController
  );
  return getCompanyIPrivelegeManager(companyIPrivelegeManagerAddr);
}

async function getCompanyIdentityForRecovery(companyIdProxyAddr, recovererAddr) {
  let companyContracts = {
    idProxy: null,
    masterController: null,
    privilegeManager: null,
  };

  const companyIdentityProxy = getCompanyIdentityProxy(companyIdProxyAddr);

  const companyMasterControllerAddr = await getCompanyMasterControllerAddr(companyIdentityProxy);

  const companyMasterController = getCompanyMasterController(companyMasterControllerAddr);

  const companyIPrivelegeManagerAddr = await getCompanyIPrivelegeManagerAddr(
    companyMasterController
  );
  const companyIPrivelegeManager = getCompanyIPrivelegeManager(companyIPrivelegeManagerAddr);

  companyContracts.idProxy = companyIdentityProxy;
  companyContracts.masterController = companyMasterController;
  companyContracts.privilegeManager = companyIPrivelegeManager;

  const isRecoverer = await checkIfIsRecovererOfCompany(companyIPrivelegeManager, recovererAddr);

  if (isRecoverer) {
    return companyContracts;
  }
  return false;
}

function getCompanyIdentityProxy(idProxyAddr) {
  return new web3.eth.Contract(Contracts.identityProxy.abi, idProxyAddr);
}

async function getCompanyIPrivelegeManagerAddr(companyMasterController) {
  return await companyMasterController.methods
    .pm()
    .call()
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

function getCompanyIPrivelegeManager(privilegeManagerAddr) {
  return new web3.eth.Contract(Contracts.IPrivilegeManager.abi, privilegeManagerAddr);
}

async function checkIfIsRecovererOfCompany(companyPrivilegeManager, recovererAddr) {
  return await companyPrivilegeManager.methods
    .checkPrivilegeGranted(PRIVILEGE_CONTROLLER_RECOVERY, recovererAddr)
    .call()
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function checkIfIsUkOfCompany(companyPrivilegeManager, walletAddr) {
  return await companyPrivilegeManager.methods
    .checkPrivilegeGranted(PRIVILEGE_CONTROLLER_COORDINATOR, walletAddr)
    .call()
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function checkPrivilegeForCompany(privilegeId, walletAddress, privilegeManager) {
  const active = await privilegeManager.methods
    .checkPrivilegeGranted(privilegeId, walletAddress)
    .call();
  return { id: privilegeId, status: active };
}

export async function revokeAllPrivileges(recoverer, privileges, walletAddress, privilegeManager) {
  const privilegeIds = [];
  const granted = [];

  for (let perm of privileges) {
    if (perm.status) {
      privilegeIds.push(perm.id);
      granted.push(false);
    }
  }
  const data = contractCache.masterController.methods
    .recoverAccountFor(privilegeManager._address, privilegeIds, granted, walletAddress)
    .encodeABI();
  return await sendTransaction(recoverer, contractCache.masterController._address, data);
}

export async function grantUkPrivilegeForRecovery(recoverer, walletAddress, privilegeManager) {
  const privilegeIds = [1001];
  const granted = [true];
  const data = contractCache.masterController.methods
    .recoverAccountFor(privilegeManager._address, privilegeIds, granted, walletAddress)
    .encodeABI();
  return await sendTransaction(recoverer, contractCache.masterController._address, data);
}
