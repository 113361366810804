import { sendSignedHttpRequest } from "util/requestSigning";

export async function fetchMaInviteData(walletAddr, idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/onboarding/${idProxyAddr}/invite/pending/${walletAddr}`,
    idProxyAddr
  );
}

export async function postMaInviteData(data, backendUrl, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/uk`,
    idProxyAddr,
    data,
    null,
    backendUrl
  );
}

export async function fetchPlatformMemberData(walletAddr, idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/onboarding/${idProxyAddr}/pending/${walletAddr}`,
    idProxyAddr
  );
}

export async function postPlatformMemberData(data, backendUrl, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}`,
    idProxyAddr,
    data,
    null,
    backendUrl
  );
}

export async function acceptWallet(walletAddr, idProxyAddr) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/onboarding/${idProxyAddr}/${walletAddr}/accept`,
    idProxyAddr
  );
}

export async function addUK(idProxyAddr, email, senderIdProxy) {
  const data = {
    email: email,
  };

  return sendSignedHttpRequest(
    "post",
    `/digits-server/platformmember/${idProxyAddr}/uk`,
    senderIdProxy,
    data
  );
}

export async function rejectWallet(walletAddr, idProxyAddr, rejectReason) {
  const data = {
    ablehnungsgrund: rejectReason,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/onboarding/${idProxyAddr}/${walletAddr}/reject`,
    idProxyAddr,
    data
  );
}

export async function saveWallet(walletData, backendUrl) {
  return sendSignedHttpRequest(
    "post",
    "/digits-server/onboarding",
    null,
    walletData,
    null,
    backendUrl
  );
}

export async function getPendingWallets(idProxyAddr) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/onboarding/${idProxyAddr}/pending`,
    idProxyAddr
  );
}
