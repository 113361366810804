import { defineMessages } from "react-intl";
import { isGermanOnly } from "../components/i18n/intl";

export const PRODUCT_SSD = "1000"; // product id for ssds
export const PRODUCT_CSSD = "1001"; // product id for ssds

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const ZERO_HASH = "0x0000000000000000000000000000000000000000000000000000000000000000";
export const ZERO_VALUE = "0x0";

export const mailTemplates = {
  ssd_bestaetigung_zwei: "ssd_bestaetigung_zwei",
  ssd_gegenbestaetigung_zwei: "ssd_gegenbestaetigung_zwei",
  rueckkauf_gegenbestaetigung: "rueckkauf_gegenbestaetigung",
  abtretung_bestaetigung_zwei: "abtretung_bestaetigung_zwei",
  abtretung_gegenbestaetigung: "abtretung_gegenbestaetigung",
  abtretung_anzeige: "abtretung_anzeige",
  zinsmitteilung_abgestimmt: "zinsmitteilung_abgestimmt",
  zinsmitteilung_ungueltig: "zinsmitteilung_ungueltig",
  kuendigung: "kuendigung",
  cssdabtretung_anzeige_darlehensnehmer: "cssdabtretung_anzeige_darlehensnehmer",
  cssdabtretung_anzeige_alter_und_neuer_darlehensgeber:
    "cssdabtretung_anzeige_alter_und_neuer_darlehensgeber",
  gp_zuordnung_bei_abtretung: "gp_zuordnung_bei_abtretung",
  kuendigung_abgebrochen: "kuendigung_abgebrochen",
  abtretung_abgebrochen_zedent: "abtretung_abgebrochen_zedent",
  abtretung_abgebrochen_zessionar: "abtretung_abgebrochen_zessionar",
  abtretunganzeige_bei_kuendigung: "abtretunganzeige_bei_kuendigung",
  cssd_bestaetigung_zwei: "cssd_bestaetigung_zwei",
  cssd_gegenbestaetigung_zwei: "cssd_gegenbestaetigung_zwei",
  cssd_gegenbestaetigung_abgelehnt: "cssd_gegenbestaetigung_abgelehnt",
  cssd_abwicklungsinformationen_geaendert: "cssd_abwicklungsinformationen_geaendert",
  cssd_urkunde_und_zahlstellenvertrag_hochgeladen:
    "cssd_urkunde_und_zahlstellenvertrag_hochgeladen",
  cssd_urkunde_und_zahlstellenvertrag_signiert: "cssd_urkunde_und_zahlstellenvertrag_signiert",
  cssdabtretung_bestaetigung_zwei: "cssdabtretung_bestaetigung_zwei",
  cssdabtretung_gegenbestaetigung_zwei: "cssdabtretung_gegenbestaetigung_zwei",
  cssdabtretung_rueckkauf_gegenbestaetigung: "cssdabtretung_rueckkauf_gegenbestaetigung",
  cssdabtretung_abwicklungsinformationen_geaendert:
    "cssdabtretung_abwicklungsinformationen_geaendert",
  cssdabtretung_uebertragungszertifikat_bestaetigung_zwei:
    "cssdabtretung_uebertragungszertifikat_bestaetigung_zwei",
  cssdabtretung_uebertragungszertifikat_gegenbestaetigung_zwei:
    "cssdabtretung_uebertragungszertifikat_gegenbestaetigung_zwei",
  cssdabtretung_neuer_geschaeftspartner_dg: "cssdabtretung_neuer_geschaeftspartner_dg",
  cssdabtretung_ablehnung: "cssdabtretung_ablehnung",
  cssd_zinsmitteilung_zweitbestaetigt: "cssd_zinsmitteilung_zweitbestaetigt",
  cssd_kuendigung_zweitbestaetigung: "cssd_kuendigung_zweitbestaetigung",
  cssd_abtretung_kuendigung_hinweis: "cssd_abtretung_kuendigung_hinweis",
  cssd_urkunde_und_zahlstellenvertrag_abgelehnt: "cssd_urkunde_und_zahlstellenvertrag_abgelehnt",
};

export const TRANSACTION_GAS = 1000000;

export const NUMBER_OF_WALLETS_TO_CREATE = 1;

export const PRIVILEGE_CONTROLLER_BSSD_OPERATIONS = "1100";
export const PRIVILEGE_CONTROLLER_CSSD_OPERATIONS = "1101";
export const PRIVILEGE_CONTROLLER_ZAHLSTELLE = "1102";
export const PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS = "1200";
export const PRIVILEGE_CONTROLLER_READ_ONLY = "1300";
export const PRIVILEGE_CONTROLLER_RECOVERY = "1000";
export const PRIVILEGE_CONTROLLER_COORDINATOR = "1001";
export const PRIVILEGE_PLATFORM_MEMBER = "2000";
export const PRIVILEGE_PLATFORM_COORDINATOR = "2001";
export const PRIVILEGE_PLATFORM_OFFER_SSD = "2100";
export const PRIVILEGE_PLATFORM_ZAHLSTELLE = "2101";
export const PRIVILEGE_PLATFORM_READ_ONLY = "2102";

export const SSD_TYPE = {
  BSSD: "bssd",
  CSSD: "cssd",
};
export const SSD_PDF_TYPE = {
  URKUNDE_PDF: "Elektronischer-Schuldschein",
  ABTRETUNGSZERTIFIKAT_PDF: "Abtretungszertifikat",
};

export const EMPLOYEE_STATUS = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  PENDING: "PENDING",
  INACTIVE: "INACTIVE",
};

export const permissions = [
  { name: "PRIVILEGE_CONTROLLER_COORDINATOR", id: 1001 },
  { name: "PRIVILEGE_CONTROLLER_BSSD_OPERATIONS", id: 1100 },
  { name: "PRIVILEGE_CONTROLLER_CSSD_OPERATIONS", id: 1101 },
  { name: "PRIVILEGE_CONTROLLER_ZAHLSTELLE", id: 1102 },
  { name: "PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS", id: 1200 },
  { name: "PRIVILEGE_CONTROLLER_READ_ONLY", id: 1300 },
];

export const platformPermissions = [
  { name: "PRIVILEGE_PLATFORM_OFFER_SSD", id: 2100 },
  { name: "PRIVILEGE_PLATFORM_ZAHLSTELLE", id: 2101 },
  { name: "PRIVILEGE_PLATFORM_READ_ONLY", id: 2102 },
];

export const usecase = {
  REJECT: "reject",
  CANCEL: "cancel",
};

export const SSDCessionState = {
  NONE: "0",
  CESSION_NOTIFIED: "1",
  CESSION_DONE: "2",
};

export const CessionState = {
  INVALID: "0",
  NEW: "1",
  IN_WORK: "2",
  OPEN: "3",
  APPROVED: "4",
  CANCELLED: "5",
  CESSION_NOTIFIED: "6",
  CESSION_PROCESSED: "7",
  ABORTED: "8",
};

export const CessionAction = {
  NONE: "0",
  MODIFY: "1",
  OFFER: "2",
  ACCEPT_OFFER: "3",
  REJECT_OFFER: "4",
  CANCEL: "5",
  NOTIFY: "6",
  PROCESS: "7",
  ABORT: "8",
};

export const SSDStateType = {
  SSD: "0",
  PAYMENT: "1",
  REBUY: "2",
  CESSION: "3",
  TERMINATION: "4",
};

export const SSDState = {
  INVALID: "0",
  NEW: "1",
  IN_WORK: "2",
  OPEN: "3",
  APPROVED: "4",
  CANCELLED: "5",
  DOCUMENT_SIGNED: "6",
  PREPARED: "7",
  PREPARED_REST: "8",
  EXTERNALLY_SETTLED: "9",
};

export const SSDActions = {
  NONE: "0",
  MODIFY: "1",
  OFFER: "2",
  ACCEPT_OFFER: "3",
  REJECT_OFFER: "4",
  CANCEL: "5",
  SIGN_DOCUMENT: "6",
  SIGN_DOCUMENT_CESSION: "7",
  MODIFY_AND_APPROVE: "8",
};

export const SSDTerminationState = {
  INVALID: "0",
  NEW: "1",
  IN_WORK: "2",
  APPROVED: "3",
  ABORTED: "4",
};

export const SSDTerminationAction = {
  NONE: "0",
  MODIFY: "1",
  APPROVE: "2",
  DELETE: "3",
  ABORT: "4",
};

export const SSDPaymentState = {
  INVALID: "0",
  AWAITING_PAYMENT: "1",
  PAID: "2",
  AWAITING_REPAYMENT: "3",
  REPAID: "4",
};
export const SSDPaymentAction = {
  NONE: "0",
  PAYMENT_RECEIVED: "1",
  PAYMENT_CORRECTION: "2",
  REQUEST_REPAYMENT: "3", // obsolete
  REPAYMENT_RECEIVED: "4",
  REPAYMENT_CORRECTION: "5",
};

export const FourEyesState = {
  NONE: "0",
  OFFER_FIRST: "1",
  OFFER_SECOND: "2",
  OFFER_CANCELLED: "3",
  OFFER_CANCELLED_ON_MODIFY: "4",

  ACCEPT_FIRST: "5",
  ACCEPT_SECOND: "6",
  ACCEPT_CANCELLED: "7",
  ACCEPT_CANCELLED_ON_REJECT: "8",

  REBUY_OFFER_FIRST: "9",
  REBUY_OFFER_SECOND: "10",
  REBUY_OFFER_CANCELLED: "11",
  REBUY_OFFER_CANCELLED_ON_MODIFY: "12",

  REBUY_ACCEPT_FIRST: "13",
  REBUY_ACCEPT_SECOND: "14",
  REBUY_ACCEPT_CANCELLED: "15",
  REBUY_ACCEPT_CANCELLED_ON_REJECT: "16",

  CESSION_OFFER_FIRST: "17",
  CESSION_OFFER_SECOND: "18",
  CESSION_OFFER_CANCELLED: "19",
  CESSION_OFFER_CANCELLED_ON_MODIFY: "20",

  CESSION_ACCEPT_FIRST: "21",
  CESSION_ACCEPT_SECOND: "22",
  CESSION_ACCEPT_CANCELLED: "23",
  CESSION_ACCEPT_CANCELLED_ON_REJECT: "24",

  INTEREST_NOTIFICATION_OFFER_FIRST: "25",
  INTEREST_NOTIFICATION_OFFER_SECOND: "26",
  INTEREST_NOTIFICATION_OFFER_CANCELLED: "27",
  INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY: "28",

  TERMINATION_APPROVE_FIRST: "29",
  TERMINATION_APPROVE_SECOND: "30",
  TERMINATION_APPROVE_CANCELLED: "31",
  TERMINATION_APPROVE_CANCELLED_ON_MODIFY: "32",
  TERMINATION_APPROVE_CANCELLED_ON_DELETE: "33",

  MEMBER_DATA_UPDATE_APPROVE_FIRST: "34",
  MEMBER_DATA_UPDATE_APPROVE_SECOND: "35",
  MEMBER_DATA_UPDATE_APPROVE_CANCELLED: "36",
  MEMBER_DATA_UPDATE_APPROVE_CANCELLED_ON_REJECT: "37",

  TERMINATION_REVOKE_OFFER_FIRST: "42",
  TERMINATION_REVOKE_OFFER_SECOND: "43",

  TERMINATION_REVOKE_ACCEPT_FIRST: "46",
  TERMINATION_REVOKE_ACCEPT_SECOND: "47",

  CONNECT_GP_FIRST: "51",
  CONNECT_GP_SECOND: "52",
  CONNECT_GP_CANCEL: "53",

  ACCEPT_DOCUMENTS_FIRST: "54",
  ACCEPT_DOCUMENTS_SECOND: "55",
};

export const SSDRebuyState = {
  INVALID: "0",
  IN_WORK: "1",
  OPEN: "2",
  APPROVED: "3",
  CANCELLED: "4",
  ABORTED: "5",
};

export const SSDRebuyAction = {
  NONE: "0",
  MODIFY: "1",
  OFFER: "2",
  ACCEPT_OFFER: "3",
  REJECT_OFFER: "4",
  CANCEL: "5",
};

export const SSDStatus = {
  Kuendigung_erfasst: "Kuendigung_erfasst",
  gekuendigt: "gekuendigt",
  Initial: "Initial",
  Neu: "Neu",
  Abgelehnt: "Abgelehnt",
  Erfasst: "Erfasst",
  In_Pruefung: "In_Pruefung",
  bestaetigt: "bestaetigt",
  Storniert: "Storniert",
  Zurueckgezahlt: "Zurueckgezahlt",
  Abtretungszertifikat_ausgestellt: "Abtretungszertifikat_ausgestellt",
  Urkunde_ausgestellt: "Urkunde_ausgestellt",
  Endfaellig: "Endfaellig",
  Rueckkauf_storniert: "Rueckkauf_storniert",
  Angezeigt: "Angezeigt",
  Externes_Abtretungszertifikat_ausgestellt: "Externes_Abtretungszertifikat_ausgestellt",
  Rueckkauf_abgelehnt: "Rueckkauf_abgelehnt",
  Rueckkauf_erfasst: "Rueckkauf_erfasst",
  Rueckkauf_in_Pruefung: "Rueckkauf_in_Pruefung",
  Rueckkauf_bestaetigt: "Rueckkauf_bestaetigt",
};

export const businessPartnerState = {
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
};

export const InterestNotificationState = {
  INVALID: "0",
  NEW: "1",
  IN_WORK: "2",
  APPROVED: "3",
  CANCELLED: "4",
  CLOSED: "5",
};

export const InterestNotificationAction = {
  NONE: "0",
  MODIFY: "1",
  OFFER: "2",
  REJECT_OFFER: "3",
  CANCEL: "4",
  CLOSE: "5",
};

export const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export const cities = ["TARGET", "Frankfurt am Main", "London", "New York", "Paris", "Luxemburg"];

export const MemberInfoStatus = {
  DRAFT: "DRAFT",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",
};

export const CSSDState = {
  INVALID: 0,
  NEW: 1,
  IN_WORK: 2,
  OPEN: 3,
  APPROVED: 4,
  CANCELLED: 5,
  DOCUMENT_SIGNED_BUYER: 6,
  DOCUMENT_SIGNED_SELLER: 7,
  REPAID: 8,
};

export const CSSDAction = {
  NONE: 0,
  MODIFY: 1,
  OFFER: 2,
  ACCEPT_OFFER: 3,
  REJECT_OFFER: 4,
  CANCEL: 5,
  SIGN_DOCUMENT_BUYER: 6,
  SIGN_DOCUMENT_SELLER: 7,
  MODIFY_AND_APPROVE: 8,
  UPDATE: 9,
  REPAYMENT_RECEIVED: 10,
  CONNECT: 11,
  CANCEL_CONNECT: 12,
  REJECT_DOCUMENTS: 13,
};

export const CSSDFourEyesState = {
  NONE: 0,
  OFFER_FIRST: 1,
  OFFER_SECOND: 2,
  OFFER_CANCELLED: 3,
  OFFER_CANCELLED_ON_MODIFY: 4,

  ACCEPT_FIRST: 5,
  ACCEPT_SECOND: 6,
  ACCEPT_CANCELLED: 7,
  ACCEPT_CANCELLED_ON_REJECT: 8,

  REBUY_OFFER_FIRST: 9,
  REBUY_OFFER_SECOND: 10,
  REBUY_OFFER_CANCELLED: 11,
  REBUY_OFFER_CANCELLED_ON_MODIFY: 12,

  REBUY_ACCEPT_FIRST: 13,
  REBUY_ACCEPT_SECOND: 14,
  REBUY_ACCEPT_CANCELLED: 15,
  REBUY_ACCEPT_CANCELLED_ON_REJECT: 16,

  CESSION_OFFER_FIRST: 17,
  CESSION_OFFER_SECOND: 18,
  CESSION_OFFER_CANCELLED: 19,
  CESSION_OFFER_CANCELLED_ON_MODIFY: 20,

  CESSION_ACCEPT_FIRST: 21,
  CESSION_ACCEPT_SECOND: 22,
  CESSION_ACCEPT_CANCELLED: 23,
  CESSION_ACCEPT_CANCELLED_ON_REJECT: 24,

  INTEREST_NOTIFICATION_OFFER_FIRST: 25,
  INTEREST_NOTIFICATION_OFFER_SECOND: 26,
  INTEREST_NOTIFICATION_OFFER_CANCELLED: 27,
  INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY: 28,

  TERMINATION_APPROVE_FIRST: 29,
  TERMINATION_APPROVE_SECOND: 30,
  TERMINATION_APPROVE_CANCELLED: 31,
  TERMINATION_APPROVE_CANCELLED_ON_MODIFY: 32,
  TERMINATION_APPROVE_CANCELLED_ON_DELETE: 33,

  MEMBER_DATA_UPDATE_APPROVE_FIRST: 34,
  MEMBER_DATA_UPDATE_APPROVE_SECOND: 35,
  MEMBER_DATA_UPDATE_APPROVE_CANCELLED: 36,
  MEMBER_DATA_UPDATE_APPROVE_CANCELLED_ON_REJECT: 37,

  CESSION_CERTIFICATE_OFFER_FIRST: 38,
  CESSION_CERTIFICATE_OFFER_SECOND: 39,
  CESSION_CERTIFICATE_ACCEPT_FIRST: 40,
  CESSION_CERTIFICATE_ACCEPT_SECOND: 41,

  TERMINATION_REVOKE_OFFER_FIRST: 42,
  TERMINATION_REVOKE_OFFER_SECOND: 43,
  TERMINATION_REVOKE_OFFER_CANCELLED: 44,
  TERMINATION_REVOKE_OFFER_CANCELLED_ON_MODIFY: 45,
  TERMINATION_REVOKE_ACCEPT_FIRST: 46,
  TERMINATION_REVOKE_ACCEPT_SECOND: 47,

  PARTIAL_CLAIM_OFFER_FIRST: 48,
  PARTIAL_CLAIM_OFFER_SECOND: 49,
  PARTIAL_CLAIM_OFFER_CANCELED: 50,
  CONNECT_GP_FIRST: 51,
  CONNECT_GP_SECOND: 52,
  CONNECT_GP_CANCEL: 53,
  ACCEPT_DOCUMENTS_FIRST: 54,
  ACCEPT_DOCUMENTS_SECOND: 55,
};

export const FourEyesStateType = {
  MEMBER_DATA_UPDATE: "0",
  CSSD: "1",
  CESSION: "2",
  INTEREST_NOTIFICATION: "3",
  TERMINATION: "4",
  REVOKE_TERMINATION: "5",
  PARTIAL_CLAIM: "6",
  CONNECT_GP: "7",
};

// =========================================

export const CSSDCessionState = {
  INVALID: 0,
  NEW: 1,
  IN_WORK: 2,
  OPEN: 3,
  APPROVED: 4,
  CANCELLED: 5,
  PAYMENT_RECEIVED: 6,
  CERT_OPEN: 7,
  CERT_APPROVED: 8,
  NOTIFIED: 9,
  ABORTED: 10,
};

export const CSSDCessionFourEyesState = {
  NONE: 0,
  OFFER_FIRST: 1,
  OFFER_SECOND: 2,

  ACCEPT_FIRST: 5,
  ACCEPT_SECOND: 6,

  OFFER_CERT_FIRST: 11,
  OFFER_CERT_SECOND: 12,

  ACCEPT_CERT_FIRST: 14,
  ACCEPT_CERT_SECOND: 15,
};

export const CSSDCessionAction = {
  NONE: 0,
  MODIFY: 1,
  OFFER: 2,
  ACCEPT_OFFER: 3,
  REJECT_OFFER: 4,
  CANCEL: 5,
  RECEIVE_PAYMENT: 6,
  CERT_OFFER: 7,
  CERT_ACCEPT: 8,
  NOTIFY: 9,
  ABORT: 10,
};

export const CSSDCessionBulkAction = {
  OFFER_CESSION: 0,
  OFFER_CERT: 1,
  RECEIVE_PAYMENT: 2,
  NOTIFY: 3,
};

export const PartialClaimState = {
  INVALID: 0,
  NEW: 1,
  IN_WORK: 2,
  NOTIFIED: 3,
  REPAID: 4,
  CANCELLED: 5,
  TERMINATED: 6, //Pseudostate only for migration
};

export const PartialClaimAction = {
  NONE: 0,
  MODIFY: 1,
  NOTIFY: 2,
  REPAYMENT_RECEIVED: 3,
  TERMINATE: 4,
  UPDATE: 5,
  OFFER: 6,
  CANCEL: 7, //7
  CONNECT: 8, //8
  CANCEL_BY_TERMINATION: 9, //9
  CANCEL_BY_CSSD_MODIFICATION: 10, //10
  CANCEL_CONNECT: 11,
};

export const CSSDFilterType = {
  ZAHLSTELLE: "zahlstelle",
  DN: "darlehensnehmer",
};

export const IBAN_CODE_LENGTHS = {
  DE: 22,
  AT: 20,
  CH: 21,
  FR: 27,
  BE: 16,
  LU: 20,
  /*
  EG: 27,
  AL: 28,
  DZ: 24,
  AD: 24,
  AO: 25,
  AZ: 28,
  BH: 22,
  BJ: 28,
  BA: 20,
  BR: 29,
  VG: 24,
  BG: 22,
  BF: 27,
  BI: 16,
  CR: 22,
  CI: 28,
  DK: 18,
  DO: 28,
  SV: 28,
  EE: 20,
  FO: 18,
  FI: 18,
  GA: 27,
  GE: 22,
  GI: 23,
  GR: 27,
  GL: 18,
  GT: 28,
  IQ: 23,
  IR: 26,
  IE: 22,
  IS: 26,
  IL: 23,
  IT: 27,
  JO: 30,
  CM: 27,
  CV: 25,
  KZ: 20,
  QA: 29,
  CG: 27,
  XK: 20,
  HR: 21,
  KW: 30,
  LV: 21,
  LB: 28,
  LI: 21,
  LT: 20,
  MG: 27,
  ML: 28,
  MT: 31,
  MR: 27,
  MU: 30,
  MD: 24,
  MC: 27,
  ME: 22,
  MZ: 25,
  NL: 18,
  MK: 19,
  NO: 15,
  TL: 23,
  PK: 24,
  PS: 29,
  PL: 28,
  PT: 25,
  RO: 24,
  SM: 27,
  ST: 25,
  SA: 24,
  SE: 24,
  SN: 28,
  RS: 22,
  SC: 31,
  SK: 24,
  SI: 19,
  ES: 24,
  LC: 32,
  CZ: 24,
  TN: 24,
  TR: 26,
  UA: 29,
  HU: 28,
  VA: 22,
  AE: 23,
  GB: 22,
  BY: 28,
  CY: 28,
  CF: 27,
  */
};

export const GeschaeftsvorfallTyp = {
  KAUF: "KAUF",
  KUENDIGUNG: "KUENDIGUNG",
  ABTRETUNG: "ABTRETUNG",
};

export const messagesReferenzzinssaetze = defineMessages({
  constants_referenzzinssatz_RZ_1_Monats_EURIBOR: {
    id: "constants.referenzzinssatz.RZ_1_Monats_EURIBOR",
    defaultMessage: "1-Monats-EURIBOR",
  },
  constants_referenzzinssatz_RZ_3_Monats_EURIBOR: {
    id: "constants.referenzzinssatz.RZ_3_Monats_EURIBOR",
    defaultMessage: "3-Monats-EURIBOR",
  },
  constants_referenzzinssatz_RZ_6_Monats_EURIBOR: {
    id: "constants.referenzzinssatz.RZ_6_Monats_EURIBOR",
    defaultMessage: "6-Monats-EURIBOR",
  },
  constants_referenzzinssatz_RZ_12_Monats_EURIBOR: {
    id: "constants.referenzzinssatz.RZ_12_Monats_EURIBOR",
    defaultMessage: "12-Monats-EURIBOR",
  },
  constants_referenzzinssatz_RZ_1_Monats_GBP_LIBOR: {
    id: "constants.referenzzinssatz.RZ_1_Monats_GBP_LIBOR",
    defaultMessage: "1-Monats-GBP-LIBOR",
  },
  constants_referenzzinssatz_RZ_3_Monats_GBP_LIBOR: {
    id: "constants.referenzzinssatz.RZ_3_Monats_GBP_LIBOR",
    defaultMessage: "3-Monats_GBP_LIBOR",
  },
  constants_referenzzinssatz_RZ_6_Monats_GBP_LIBOR: {
    id: "constants.referenzzinssatz.RZ_6_Monats_GBP_LIBOR",
    defaultMessage: "6-Monats-GBP-LIBOR",
  },
  constants_referenzzinssatz_RZ_12_Monats_GBP_LIBOR: {
    id: "constants.referenzzinssatz.RZ_12_Monats_GBP_LIBOR",
    defaultMessage: "12-Monats-GBP-LIBOR",
  },
  constants_referenzzinssatz_RZ_1_Monats_USD_LIBOR: {
    id: "constants.referenzzinssatz.RZ_1_Monats_USD_LIBOR",
    defaultMessage: "1-Monats-USD-LIBOR",
  },
  constants_referenzzinssatz_RZ_3_Monats_USD_LIBOR: {
    id: "constants.referenzzinssatz.RZ_3_Monats_USD_LIBOR",
    defaultMessage: "3-Monats-USD-LIBOR",
  },
  constants_referenzzinssatz_RZ_6_Monats_USD_LIBOR: {
    id: "constants.referenzzinssatz.RZ_6_Monats_USD_LIBOR",
    defaultMessage: "6-Monats-USD-LIBOR",
  },
  constants_referenzzinssatz_RZ_12_Monats_USD_LIBOR: {
    id: "constants.referenzzinssatz.RZ_12_Monats_USD_LIBOR",
    defaultMessage: "12-Monats-USD-LIBOR",
  },
  constants_referenzzinssatz_RZ_2Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_2Y_EUR_CMS_Satz",
    defaultMessage: "2Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_3Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_3Y_EUR_CMS_Satz",
    defaultMessage: "3Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_5Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_5Y_EUR_CMS_Satz",
    defaultMessage: "5Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_10Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_10Y_EUR_CMS_Satz",
    defaultMessage: "10Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_15Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_15Y_EUR_CMS_Satz",
    defaultMessage: "15Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_20Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_20Y_EUR_CMS_Satz",
    defaultMessage: "20Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_30Y_10Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_30Y_10Y_EUR_CMS_Satz",
    defaultMessage: "30Y-10Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_10Y_2Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_10Y_2Y_EUR_CMS_Satz",
    defaultMessage: "10Y-2Y-EUR-CMS-Satz",
  },
  constants_referenzzinssatz_RZ_30Y_2Y_EUR_CMS_Satz: {
    id: "constants.referenzzinssatz.RZ_30Y_2Y_EUR_CMS_Satz",
    defaultMessage: "30Y-2Y-EUR-CMS-Satz",
  },
});

export const referenzzinssaetze = {
  RZ_1_Monats_EURIBOR: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_1_Monats_EURIBOR,
  RZ_3_Monats_EURIBOR: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_3_Monats_EURIBOR,
  RZ_6_Monats_EURIBOR: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_6_Monats_EURIBOR,
  RZ_12_Monats_EURIBOR: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_12_Monats_EURIBOR,
  RZ_1_Monats_GBP_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_1_Monats_GBP_LIBOR,
  RZ_3_Monats_GBP_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_3_Monats_GBP_LIBOR,
  RZ_6_Monats_GBP_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_6_Monats_GBP_LIBOR,
  RZ_12_Monats_GBP_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_12_Monats_GBP_LIBOR,
  RZ_1_Monats_USD_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_1_Monats_USD_LIBOR,
  RZ_3_Monats_USD_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_3_Monats_USD_LIBOR,
  RZ_6_Monats_USD_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_6_Monats_USD_LIBOR,
  RZ_12_Monats_USD_LIBOR:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_12_Monats_USD_LIBOR,
  RZ_2Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_2Y_EUR_CMS_Satz,
  RZ_3Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_3Y_EUR_CMS_Satz,
  RZ_5Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_5Y_EUR_CMS_Satz,
  RZ_10Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_10Y_EUR_CMS_Satz,
  RZ_15Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_15Y_EUR_CMS_Satz,
  RZ_20Y_EUR_CMS_Satz: messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_20Y_EUR_CMS_Satz,
  RZ_30Y_10Y_EUR_CMS_Satz:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_30Y_10Y_EUR_CMS_Satz,
  RZ_10Y_2Y_EUR_CMS_Satz:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_10Y_2Y_EUR_CMS_Satz,
  RZ_30Y_2Y_EUR_CMS_Satz:
    messagesReferenzzinssaetze.constants_referenzzinssatz_RZ_30Y_2Y_EUR_CMS_Satz,
};

export const monthValues = {
  januar: 1,
  februar: 2,
  maerz: 3,
  april: 4,
  mai: 5,
  juni: 6,
  juli: 7,
  august: 8,
  september: 9,
  oktober: 10,
  november: 11,
  dezember: 12,
};

export const DOCUMENT_TYPES = [
  "KYC_DARLEHENSNEHMER",
  "KYC_PERSONEN",
  "AUSZAHLUNGSVORAUSSETZUNGEN",
  "FIXING",
  "KUENDIGUNGSSCHREIBEN",
  "GESCHAEFTSBERICHTE",
  "SONSTIGE_INFORMATIONEN",
  "ZINSANPASSUNGEN",
];

export const paginationSteps = [10, 50, 100];

export const CSSDInterestNotificationState = {
  INVALID: 0,
  NEW: 1,
  IN_WORK: 2,
  APPROVED: 3,
  CANCELLED: 4,
  CLOSED: 5,
};

export const CSSDInterestNotificationAction = {
  NONE: 0,
  MODIFY: 1,
  OFFER: 2,
  REJECT_OFFER: 3,
  CANCEL: 4,
  CLOSE: 5,
};

export const GeschaeftspartnerTyp = {
  ZAHLSTELLE: "ZAHLSTELLE",
  DARLEHENSGEBER: "DARLEHENSGEBER",
  DARLEHENSNEHMER: "DARLEHENSNEHMER",
};

export const CSSDTerminationState = {
  INVALID: 0,
  NEW: 1,
  IN_WORK: 2,
  APPROVED: 3,
  ABORTED: 4,
  CANCELLED: 5,
  REVOKE_IN_WORK: 6,
  REVOKE_OPEN: 7,
  REVOKE_APPROVED: 8,
};

export const CSSDTerminationAction = {
  NONE: 0,
  MODIFY: 1,
  APPROVE: 2,
  DELETE: 3,
  ABORT: 4,
  CANCEL: 5,
  REVOKE_MODIFY: 6,
  REVOKE_APPROVE: 7,
  REVOKE_CANCEL: 8,
  REVOKE_OFFER: 9,
  REVOKE_MODIFY_MATURITY_DATE: 10,
  PROPAGATE: 11
};

export const supportedLanguages = isGermanOnly() ? ["de"] : ["de", "en"];

export const Kuendigungsrecht = {
  ORDENTLICH: "ordentlich",
  AUSSERORDENTLICH: "außerordentlich",
};

export const DokumentTyp = {
  GESCHAEFTSBESTATIGUNG: "GESCHAEFTSBESTATIGUNG",
  ZAHLSTELLENVERTRAG_ZAHLSTELLE: "ZAHLSTELLENVERTRAG_ZAHLSTELLE",
  URKUNDE_ZAHLSTELLE: "URKUNDE_ZAHLSTELLE",
  KYC_DARLEHENSNEHMER: "KYC_DARLEHENSNEHMER",
  KYC_PERSONEN: "KYC_PERSONEN",
  AUSZAHLUNGSVORAUSSETZUNGEN: "AUSZAHLUNGSVORAUSSETZUNGEN",
  FIXING: "FIXING",
  KUENDIGUNGSSCHREIBEN: "KUENDIGUNGSSCHREIBEN",
  GESCHAEFTSBERICHTE: "GESCHAEFTSBERICHTE",
  SONSTIGE_INFORMATIONEN: "SONSTIGE_INFORMATIONEN",
  ZINSANPASSUNGEN: "ZINSANPASSUNGEN",
  ABTRETUNG_GESCHAEFTSBESTATIGUNG: "ABTRETUNG_GESCHAEFTSBESTATIGUNG",
  ABTRETUNG_ANZEIGE_EXTERN: "ABTRETUNG_ANZEIGE_EXTERN",
  ABTRETUNG_UEBERTRAGUNGSZERTIFIKAT: "ABTRETUNG_UEBERTRAGUNGSZERTIFIKAT",
  ZINSMITTEILUNG: "ZINSMITTEILUNG",
};
