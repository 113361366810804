import {
  UPDATE_CSSD,
  FETCH_CSSDS_SUCCESS,
  OPEN_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL,
  SET_CSSD_FILTER,
  RESET_CSSD_FILTER,
  CHANGE_CSSD_PAGE,
  CHANGE_CSSD_ROWS_PER_PAGE,
  UPDATE_CSSD_FOUREYES_EVENT,
  OPEN_CSSD_DETAILS_MODAL,
  CLOSE_CSSD_DETAILS_MODAL,
  FETCH_CSSDS_REQUEST,
  FETCH_CSSDS_FAILURE,
  UPDATE_CSSD_TERMINATIONS,
  CHANGE_CSSD_SORT_CRITERIA,
  FETCH_CSSDS_SAGA,
  FETCH_CSSDS_SAGA_FAILURE,
  FETCH_CSSDS_SAGA_SUCCESS,
} from "./actions";
import { SIGN_IN_SUCCESS } from "../signIn/actions";
import { CSSDFilterType } from "util/constants";
import { updateTerminations } from "util/cssdUtils";
import { SortType } from "util/filters";

// reducer with initial state
const initialState = {
  cssds: [],
  sortCriteria: {
    column: "urkundenRegisternummer",
    isDescending: true,
    sortType: SortType.TEXT,
  },
  filters: {
    view: CSSDFilterType.ZAHLSTELLE,
  },
  pageNumber: 0,
  pageSize: 50,
  amount: 0,
  cssdsRequestPending: 0,
  hasStartedLoading: false,
  confirmCssd: null,
  detailCssd: null,
  loadFailed: false,
};

function fetchCssdSagaReducer(state) {
  return {
    ...state,
    hasStartedLoading: true,
  };
}

function fetchCssdSagaSuccessReducer(state) {
  return {
    ...state,
    loadFailed: false
  };
}

function fetchCssdSagaFailureReducer(state) {
  return {
    ...state,
    loadFailed: true,
  };
}

function fetchCssdRequestReducer(state) {
  return {
    ...state,
    cssdsRequestPending: state.cssdsRequestPending + 1,
  };
}

function fetchCssdFailureReducer(state) {
  return {
    ...state,
    cssdsRequestPending: state.cssdsRequestPending - 1,
  };
}

function fetchCssdSuccessReducer(state, action) {
  const newState = {
    ...state,
    cssdsRequestPending: state.cssdsRequestPending - 1,
    cssds: [
      ...updateCssdDetails(state.cssds, action.cssds.cssdResponses),
      ...action.cssds.cssdResponses.filter(
        (newCssd) => !state.cssds.map((storedCssd) => storedCssd.cssdId).includes(newCssd.cssdId)
      ),
    ],
  };
  return {
    ...newState,
    amount: newState.cssds.length,
  };
}

function setCssdFilter(state, action) {
  return {
    ...state,
    filters: action.filter,
  };
}

function resetCssdFilter(state) {
  return {
    ...state,
    filters: { ...initialState.filters, view: state.filters.view },
  };
}

function changeCssdPage(state, action) {
  return {
    ...state,
    pageNumber: action.pageNumber,
  };
}

function changeCssdRowsPerPage(state, action) {
  return {
    ...state,
    pageNumber: 0,
    pageSize: action.pageSize,
  };
}

function setSortCriteria(state, action) {
  const { column, sortType } = action;
  const { sortCriteria } = state;
  return {
    ...state,
    sortCriteria: {
      column,
      isDescending: column === sortCriteria.column ? !sortCriteria.isDescending : true,
      sortType,
    },
  };
}

function openConfirmModal(state, action) {
  return {
    ...state,
    confirmCssd: action.cssd,
  };
}

function closeConfirmModal(state) {
  return {
    ...state,
    confirmCssd: null,
  };
}

function updateCssdFoureyesEventReducer(state, action) {
  const { cssdId, fourEyes, firstConfirmer } = action;
  const { cssds } = state;
  let update = cssds.map((entry) => {
    if (entry.cssdId === cssdId)
      return {
        ...entry,
        fourEyesState: fourEyes,
        firstConfirmer: firstConfirmer,
      };
    return entry;
  });
  return {
    ...state,
    cssds: update,
  };
}

function updateCssdDetails(cssds, newCssds) {
  return cssds.map((entry) => {
    const update = newCssds.find((newCssd) => newCssd.cssdId === entry.cssdId);
    if (update) {
      return {
        ...entry,
        ...update,
      };
    }
    return entry;
  });
}

function updateCssdReducer(state, action) {
  const { cssd } = action;
  const { cssds } = state;
  return {
    ...state,
    cssds: updateCssdDetails(cssds, [cssd]),
  };
}

export function updateFilterOnInitialPermissions(state, action) {
  const { privileges } = action;
  const filters = {
    view:
      privileges.privilegeCssdOffer && (privileges.zahlstellenMa || privileges.readOnly)
        ? CSSDFilterType.ZAHLSTELLE
        : CSSDFilterType.DN,
  };
  return {
    ...state,
    filters,
  };
}

function openDetailsModal(state, action) {
  return {
    ...state,
    detailCssd: action.cssd,
  };
}

function closeDetailsModal(state, action) {
  return {
    ...state,
    detailCssd: null,
  };
}

const updateCssdTerminations = (state, action) => {
  return {
    ...state,
    cssds: updateTerminations(state.cssds, action.cssdId, "cssdId", action.terminations),
  };
};

export function cssdEventReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CSSDS_SAGA:
      return fetchCssdSagaReducer(state, action);
    case FETCH_CSSDS_SAGA_SUCCESS:
      return fetchCssdSagaSuccessReducer(state, action);
    case FETCH_CSSDS_SAGA_FAILURE:
      return fetchCssdSagaFailureReducer(state, action);
    case FETCH_CSSDS_REQUEST:
      return fetchCssdRequestReducer(state, action);
    case FETCH_CSSDS_SUCCESS:
      return fetchCssdSuccessReducer(state, action);
    case FETCH_CSSDS_FAILURE:
      return fetchCssdFailureReducer(state, action);
    case OPEN_CONFIRM_MODAL:
      return openConfirmModal(state, action);
    case CLOSE_CONFIRM_MODAL:
      return closeConfirmModal(state, action);
    case SET_CSSD_FILTER:
      return setCssdFilter(state, action);
    case RESET_CSSD_FILTER:
      return resetCssdFilter(state, action);
    case CHANGE_CSSD_PAGE:
      return changeCssdPage(state, action);
    case CHANGE_CSSD_ROWS_PER_PAGE:
      return changeCssdRowsPerPage(state, action);
    case CHANGE_CSSD_SORT_CRITERIA:
      return setSortCriteria(state, action);
    case UPDATE_CSSD_FOUREYES_EVENT:
      return updateCssdFoureyesEventReducer(state, action);
    case UPDATE_CSSD:
      return updateCssdReducer(state, action);
    case SIGN_IN_SUCCESS:
      return updateFilterOnInitialPermissions(state, action);
    case OPEN_CSSD_DETAILS_MODAL:
      return openDetailsModal(state, action);
    case CLOSE_CSSD_DETAILS_MODAL:
      return closeDetailsModal(state, action);

    case UPDATE_CSSD_TERMINATIONS:
      return updateCssdTerminations(state, action);

    default:
      return state;
  }
}
