// action types
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const OPEN_SNACKBAR_SUCCESS = "OPEN_SNACKBAR_SUCCESS";
export const OPEN_SNACKBAR_ERROR = "OPEN_SNACKBAR_ERROR";
export const OPEN_SNACKBAR_WARNING = "OPEN_SNACKBAR_WARNING";

export const OPEN_MESSAGE_DIALOG = "OPEN_MESSAGE_DIALOG";
export const CLOSE_MESSAGE_DIALOG = "CLOSE_MESSAGE_DIALOG";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const snackbarActions = {
  open: (text) => action(OPEN_SNACKBAR, { text }),
  openSuccess: (text) => action(OPEN_SNACKBAR_SUCCESS, { text }),
  openError: (text) => action(OPEN_SNACKBAR_ERROR, { text }),
  openWarning: (text) => action(OPEN_SNACKBAR_WARNING, { text }),
  close: (id) => action(CLOSE_SNACKBAR, { id }),
};

export const messageDialogActions = {
  open: ({ icon, title, body, buttonText }) =>
    action(OPEN_MESSAGE_DIALOG, { icon, title, body, buttonText }),
  close: () => action(CLOSE_MESSAGE_DIALOG, {}),
};

export const OPEN_ALERT_DIALOG = "OPEN_ALERT_DIALOG";
export const CLOSE_ALERT_DIALOG = "CLOSE_ALERT_DIALOG";

export const invalidDataAlertDialog = {
  open: (callback) => action(OPEN_ALERT_DIALOG, { callback }),
  close: () => action(CLOSE_ALERT_DIALOG),
};
