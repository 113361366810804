import { defineMessages } from "react-intl";

export const messages = defineMessages({
  wallet_registration_paper_unternehmen_registrieren_stammdaten: {
    id: "wallet.registration.paper.unternehmen.registrieren.stammdaten",
    defaultMessage: "Unternehmen registrieren - Stammdaten",
  },
  wallet_registration_paper_folgende_pflichtfelder_unveraenderbar: {
    id: "wallet.registration.paper.folgende.pflichtfelder.unveraenderbar",
    defaultMessage: "* Folgende Pflichtfelder sind nachträglich nicht änderbar:",
  },
  wallet_registration_paper_text_field_label_firmenname: {
    id: "wallet.registration.paper.text.field.label.firmenname",
    defaultMessage: "Firmenname",
  },
  wallet_registration_paper_text_field_label_umsatzsteuer_id: {
    id: "wallet.registration.paper.text.field.label.umsatzsteuer.id",
    defaultMessage: "Umsatzsteuer ID",
  },
  wallet_registration_paper_form_helper_text_fehler_umsatzsteuer_id_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.umsatzsteuer.id.beispiel",
    defaultMessage: "Fehler - Beispiel: DE123456789",
  },
  wallet_registration_paper_text_field_label_anschrift: {
    id: "wallet.registration.paper.text.field.label.anschrift",
    defaultMessage: "Anschrift",
  },
  wallet_registration_paper_text_field_label_sitzland: {
    id: "wallet.registration.paper.text.field.label.sitzland",
    defaultMessage: "Sitzland",
  },
  wallet_registration_paper_text_field_label_sprache: {
    id: "wallet.registration.paper.text.field.label.sprache",
    defaultMessage: "Sprache",
  },
  wallet_registration_paper_text_field_label_lei: {
    id: "wallet.registration.paper.text.field.label.lei",
    defaultMessage: "LEI",
  },
  wallet_registration_paper_form_helper_text_fehler_lei_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.lei.beispiel",
    defaultMessage: "Fehler - Beispiel: 724500VKKSH9QOLTFR81",
  },
  wallet_registration_paper_text_field_label_email_verteiler: {
    id: "wallet.registration.paper.text.field.label.email.verteiler",
    defaultMessage: "E-Mail-Verteiler",
  },
  wallet_registration_paper_form_helper_text_fehler_email_verteiler_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.email.verteiler.beispiel",
    defaultMessage: "Fehler - Beispiel: example@site.com",
  },
  wallet_registration_paper_text_field_label_backend_url: {
    id: "wallet.registration.paper.text.field.label.backend.url",
    defaultMessage: "Backend URL",
  },
  wallet_registration_paper_form_helper_text_fehler_backend_url_muss_mit_http_s_beginnen: {
    id: "wallet.registration.paper.form.helper.text.fehler.backend.url.muss.mit.http.s.beginnen",
    defaultMessage: "Die Url muss mit http:// oder https:// beginnen.",
  },
  wallet_registration_paper_folgende_optionale_felder_als_vorbelegung: {
    id: "wallet.registration.paper.folgende.optionale.felder.als.vorbelegung",
    defaultMessage:
      "Folgende optionale Felder dienen als Vorbelegung bei Erfassung von Geschäften:",
  },
  wallet_registration_paper_text_field_label_telefonnummer: {
    id: "wallet.registration.paper.text.field.label.telefonnummer",
    defaultMessage: "Telefonnummer",
  },
  wallet_registration_paper_form_helper_text_fehler_telefonnummer_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.telefonnummer.beispiel",
    defaultMessage: "Fehler - Beispiel: +49 30 12345",
  },
  wallet_registration_paper_text_field_label_erfassender_mitarbeiter: {
    id: "wallet.registration.paper.text.field.label.erfassender.mitarbeiter",
    defaultMessage: "Erfassender Mitarbeiter",
  },
  wallet_registration_paper_text_field_label_alternativer_ansprechpartner_fuer_mitteilungen: {
    id: "wallet.registration.paper.text.field.label.alternativer.ansprechpartner.fuer.mitteilungen",
    defaultMessage: "Alternativer Ansprechpartner für Mitteilungen",
  },
  wallet_registration_paper_text_field_label_telefonnummer_alternativer_ansprechpartner_fuer_mitteilungen: {
    id:
      "wallet.registration.paper.text.field.label.telefonnummer.alternativer.ansprechpartner.fuer.mitteilungen",
    defaultMessage: "Telefonnummer des alternativen Ansprechpartners für Mitteilungen",
  },
  wallet_registration_paper_checkbox_label_zweite_bankverbindung_erfassen: {
    id: "wallet.registration.paper.checkbox.label.zweite.bankverbindung.erfassen",
    defaultMessage: "Zweite Bankverbindung erfassen",
  },
  wallet_registration_paper_bankverbindung_fuer_zinsen: {
    id: "wallet.registration.paper.bankverbindung.fuer.zinsen",
    defaultMessage: "Bankverbindung für Zinsen:",
  },
  wallet_registration_paper_bankverbindung_fuer_zinsen_und_tilgung: {
    id: "wallet.registration.paper.bankverbindung.fuer.zinsen.und.tilgung",
    defaultMessage: "Bankverbindung für Zinsen und Tilgung:",
  },
  wallet_registration_paper_text_field_label_iban: {
    id: "wallet.registration.paper.text.field.label.iban",
    defaultMessage: "IBAN",
  },
  wallet_registration_paper_form_helper_text_fehler_iban_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.iban.beispiel",
    defaultMessage: "Fehler - Beispiel: DE84500105176619333197",
  },
  wallet_registration_paper_text_field_label_bic: {
    id: "wallet.registration.paper.text.field.label.bic",
    defaultMessage: "BIC",
  },
  wallet_registration_paper_text_field_label_bic_allgemein: {
    id: "wallet.registration.paper.text.field.label.bic.allgemein",
    defaultMessage: "BIC des Zahlungsempfängers",
  },
  wallet_registration_paper_form_helper_text_fehler_bic_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.bic.beispiel",
    defaultMessage: "Fehler - Beispiel: HELADEF1234",
  },
  wallet_registration_paper_bankverbindung_fuer_tilgung: {
    id: "wallet.registration.paper.bankverbindung.fuer.tilgung",
    defaultMessage: "Bankverbindung für Tilgung:",
  },
  wallet_registration_paper_text_field_label_iban_tilgung: {
    id: "wallet.registration.paper.text.field.label.iban.tilgung",
    defaultMessage: "IBAN",
  },
  wallet_registration_paper_form_helper_text_fehler_iban_tilgung_beispiel: {
    id: "wallet.registration.paper.form.helper.text.fehler.iban.tilgung_beispiel",
    defaultMessage: "Fehler - Beispiel: DE11 2222 3333 4444 5555 67",
  },
  wallet_registration_paper_form_helper_text_muss_sich_von_iban_zinsen_unterscheiden: {
    id: "wallet.registration.paper.form.helper.text.muss.sich.von.iban.zinsen.unterscheiden",
    defaultMessage: "Muss sich von IBAN-Zinsen unterscheiden",
  },
  wallet_registration_paper_text_field_label_bic_tilgung: {
    id: "wallet.registration.paper.text.field.label.bic.tilgung",
    defaultMessage: "BIC",
  },
  wallet_registration_paper_checkbox_label_vier_augen_bestaetigung_erforderlich: {
    id: "wallet.registration.paper.checkbox.label.vier.augen.bestaetigung.erforderlich",
    defaultMessage: "Vier Augen Bestätigung erforderlich",
  },
  wallet_registration_paper_button_text_zurueck: {
    id: "wallet.registration.paper.button.text.zurueck",
    defaultMessage: "zurück",
  },
  wallet_registration_paper_button_text_erfassen: {
    id: "wallet.registration.paper.button.text.erfassen",
    defaultMessage: "Erfassen",
  },
});
