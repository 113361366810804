

export function saveLogs(json) {
  try {
    const fse = require("fs-extra");
    fse.writeFile("./src/logs/error.json", json, "utf8");
    // eslint-disable-next-line no-console
    console.log("Logs created");
  } catch (err) {
    console.error(err);
  }
}

