// action types
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const CREATE_WALLET_REQUEST = "CREATE_WALLET_REQUEST";
export const CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS";
export const CREATE_WALLET_FAILURE = "CREATE_WALLET_FAILURE";

export const USER_IS_ONBOARDED = "USER_IS_ONBOARDED";

export const VALIDATE_ONBOARDING_TOKEN_REQUEST = "VALIDATE_ONBOARDING_TOKEN_REQUEST";
export const VALIDATE_ONBOARDING_TOKEN_SUCCESS = "VALIDATE_ONBOARDING_TOKEN_SUCCESS";
export const VALIDATE_ONBOARDING_TOKEN_FAILURE = "VALIDATE_ONBOARDING_TOKEN_FAILURE";

export const OPEN_MESSAGE_MODAL = "OPEN_MESSAGE_MODAL";
export const CLOSE_MESSAGE_MODAL = "CLOSE_MESSAGE_MODAL";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const signInActions = {
  request: (instance, onBoardingToken, isPlatformMemberInvited) =>
    action(SIGN_IN_REQUEST, {
      instance,
      onBoardingToken,
      isPlatformMemberInvited,
    }),
  success: (accountWithPrivileges) => action(SIGN_IN_SUCCESS, accountWithPrivileges),
  failure: (error) => action(SIGN_IN_FAILURE, { error }),
};

export const userOnboardActions = {
  userIsOnboarded: () => action(USER_IS_ONBOARDED, {}),
};

export const createWalletAction = {
  request: (password) => action(CREATE_WALLET_REQUEST, { password }),
  success: (wallet) => action(CREATE_WALLET_SUCCESS, { wallet }),
  failure: (error) => action(CREATE_WALLET_FAILURE, { error }),
};

export const onboardingTokenActions = {
  request: (token, backendUrl) => action(VALIDATE_ONBOARDING_TOKEN_REQUEST, { token, backendUrl }),
  success: (token, backendUrl, isPlatformMemberInvited, platformMemberEmail) =>
    action(VALIDATE_ONBOARDING_TOKEN_SUCCESS, {
      token,
      backendUrl,
      isPlatformMemberInvited,
      platformMemberEmail,
    }),
  failure: (error) => action(VALIDATE_ONBOARDING_TOKEN_FAILURE, { error }),
};
