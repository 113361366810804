import { call, takeLatest, put, fork, take, all, cancel } from "redux-saga/effects";
import {
  CREATE_INTEREST_NOTIFICATION_REQUEST,
  FETCH_INTEREST_NOTIFICATIONS_REQUEST,
  START_INTEREST_NOTIFICATION_EVENT_LISTENER,
  STOP_INTEREST_NOTIFICATION_EVENT_LISTENER,
  CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST,
  ADD_INTEREST_NOTIFICATION_FROM_EVENT,
  CONFIRM_INTEREST_NOTIFICATION_REQUEST,
  CLOSE_INTEREST_NOTIFICATION_REQUEST,
  MODIFY_INTEREST_NOTIFICATION_REQUEST,
  MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST,
  REJECT_INTEREST_NOTIFICATION_REQUEST,
  CANCEL_INTEREST_NOTIFICATION_REQUEST,
  DOWNLOAD_INTEREST_NOTIFICATION_REQUEST,
  UPDATE_INTEREST_NOTIFICAION_STATE,
  UPDATE_BSSD_INTEREST_NOTIFICAION,
} from "./actions";
import {
  createInterestNotificationActions,
  fetchInterestNotificationActions,
  fetchInterestNotificationDetailsActions,
  createAndConfirmInterestNotifications,
  modifyInterestNotificationActions,
  modifyAndSaveInterestNotificationActions,
  closeInterestNotificationActions,
  confirmInterestNotificationActions,
  rejectInterestNotificationActions,
  cancelInterestNotificationActions,
} from "./actions";
import {
  postInterestNotification,
  getInterestNotificationDetails,
  getZinsmitteilungsPdf,
  createZinsmitteilungsPdf,
} from "services/interestNotificationService";
import { sendMail } from "services/mailService";

import {
  interestNotificationNew,
  interestNotificationNewAndOffer,
  getInterestNotificationsForSsdId,
  interestNotificationOffer,
  interestNotificationClose,
  interestNotificationModify,
  interestNotificationModifyAndOffer,
  interestNotificationReject,
  interestNotificationCancel,
} from "services/web3Services/interest";
import {
  toHex,
  toUtf8,
  getFourEyesState,
  getInterestNotificationState,
  getBlockNumber,
} from "services/web3Services/commons";
import {
  listenForInterestNotificationEvent,
  listenForInterestNotificationFourEyesEvents,
} from "services/web3Services/eventListener";
import { snackbarActions } from "redux/shared/actions";
import { mailTemplates } from "util/constants";
import { ZERO_HASH, zeroAddress } from "util/constants";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generateSsdInterestNotificationFingerprint } from "util/fingerprint";
import { updateSsdDetails } from "redux/ssdEvents/sagas";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const interestNotificationSagas = [
  takeLatest(START_INTEREST_NOTIFICATION_EVENT_LISTENER, startEventListener),
  takeLatest(CREATE_INTEREST_NOTIFICATION_REQUEST, createInterestNotification),
  takeLatest(FETCH_INTEREST_NOTIFICATIONS_REQUEST, fetchInterestNotification),
  takeLatest(ADD_INTEREST_NOTIFICATION_FROM_EVENT, fetchInterstNotificationDetails),
  takeLatest(
    CREATE_AND_CONFIRM_INTEREST_NOTIFICATION_REQUEST,
    createAndConfirmInterestNotification
  ),
  takeLatest(CONFIRM_INTEREST_NOTIFICATION_REQUEST, confirmInterestNotification),
  takeLatest(CLOSE_INTEREST_NOTIFICATION_REQUEST, closeInterestNotification),
  takeLatest(MODIFY_INTEREST_NOTIFICATION_REQUEST, modifyInterestNotification),
  takeLatest(MODIFY_AND_SAVE_INTEREST_NOTIFICATION_REQUEST, modifyAndSaveInterestNotification),
  takeLatest(REJECT_INTEREST_NOTIFICATION_REQUEST, rejectInterestNotification),
  takeLatest(CANCEL_INTEREST_NOTIFICATION_REQUEST, cancelInterestNotification),
  takeLatest(DOWNLOAD_INTEREST_NOTIFICATION_REQUEST, downloadInterestNotification),
  takeLatest(UPDATE_INTEREST_NOTIFICAION_STATE, updateInterestNotification),
  takeLatest(UPDATE_BSSD_INTEREST_NOTIFICAION, updateBSSDInterestNotification),
];

export function* updateBSSDInterestNotification({ arranger, ssdId }) {
  try {
    yield call(updateSsdDetails, arranger, ssdId, null, true);
  } catch (error) {
    console.error(error);
  }
}

export function* updateInterestNotification({ ssdId, id, arranger }) {
  yield fork(fetchInterestNotificationDetail, arranger, toUtf8(ssdId), id);
}

export function* downloadInterestNotification({
  arranger,
  ssdId,
  fingerprint,
  fingerprintRebuy,
  interestId,
}) {
  try {
    yield call(getZinsmitteilungsPdf, arranger, ssdId, fingerprint, fingerprintRebuy, interestId);
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_interest_notification_download_fehlgeschlagen)
      )
    );
  }
}

export function* cancelInterestNotification({
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash,
}) {
  try {
    yield call(
      interestNotificationCancel,
      account,
      arranger,
      toHex(ssdId),
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    );
    yield put(cancelInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_interest_notification_zinsmitteilung_wurde_storniert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cancelInterestNotificationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_storniert_werden
        )
      )
    );
  }
}

export function* rejectInterestNotification({
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash,
}) {
  try {
    yield call(
      interestNotificationReject,
      account,
      arranger,
      toHex(ssdId),
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    );
    yield put(rejectInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_interest_notification_zinsmitteilung_wurde_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectInterestNotificationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_abgelehnt_werden
        )
      )
    );
  }
}

export function* modifyInterestNotification({
  account,
  arranger,
  ssdId,
  interestNotificationData,
}) {
  try {
    const interestDataWithFingerprint = yield generateSsdInterestNotificationFingerprint(
      interestNotificationData
    );

    const { zinsmitteilungid } = yield call(
      postInterestNotification,
      arranger,
      ssdId,
      interestDataWithFingerprint
    );

    yield call(
      interestNotificationModify,
      account,
      arranger,
      toHex(ssdId),
      toHex(`${zinsmitteilungid}`),
      interestDataWithFingerprint.fingerprint
    );

    yield call(fetchInterestNotificationDetail, arranger, ssdId, toHex(`${zinsmitteilungid}`));

    yield put(modifyInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_interest_notification_zinsmitteilung_wurde_angepasst)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(modifyInterestNotificationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_angepasst_werden
        )
      )
    );
  }
}

export function* modifyAndSaveInterestNotification({
  account,
  arranger,
  ssdId,
  ssdHash,
  interestNotificationData,
}) {
  try {
    const interestDataWithFingerprint = yield generateSsdInterestNotificationFingerprint(
      interestNotificationData
    );

    const { zinsmitteilungid } = yield call(
      postInterestNotification,
      arranger,
      ssdId,
      interestDataWithFingerprint
    );

    yield call(
      interestNotificationModifyAndOffer,
      account,
      arranger,
      toHex(ssdId),
      toHex(`${zinsmitteilungid}`),
      interestDataWithFingerprint.fingerprint,
      ssdHash
    );

    yield call(fetchInterestNotificationDetail, arranger, ssdId, toHex(`${zinsmitteilungid}`));

    yield put(modifyAndSaveInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_wurde_angepasst_und_bestaetigt
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(modifyAndSaveInterestNotificationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_angepasst_werden
        )
      )
    );
  }
}

export function* closeInterestNotification({
  account,
  arranger,
  ssdId,
  interestNotificationId,
  ssdHash,
  interestNotificationHash,
}) {
  try {
    yield call(
      interestNotificationClose,
      account,
      arranger,
      toHex(ssdId),
      interestNotificationId,
      ssdHash,
      interestNotificationHash
    );

    yield call(sendMail, {
      template: mailTemplates.zinsmitteilung_ungueltig,
      arranger: arranger,
      ssdid: ssdId,
      fingerprint: ssdHash,
      fingerprint_rebuy: ZERO_HASH,
      recipient: arranger,
      subid: toUtf8(interestNotificationId),
    });

    yield put(closeInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_wurde_geschlossen
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(closeInterestNotificationActions.success(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_geschlossen_werden
        )
      )
    );
  }
}

export function* confirmInterestNotification({
  account,
  interestNotificationData,
  ssdInfoForInterest,
  fourEyesFlag,
}) {
  try {
    const { firstConfirmer } = yield call(
      getFourEyesState,
      ssdInfoForInterest.arranger,
      ssdInfoForInterest.ssdid,
      toUtf8(interestNotificationData.id)
    );

    yield call(
      interestNotificationOffer,
      account,
      ssdInfoForInterest.arranger,
      interestNotificationData.firstConfirmer,
      toHex(ssdInfoForInterest.ssdid),
      interestNotificationData.id,
      ssdInfoForInterest.fingerprint,
      interestNotificationData.hash
    );

    if (firstConfirmer !== zeroAddress || !fourEyesFlag) {
      const interestDataWithFingerprint = yield generateSsdInterestNotificationFingerprint(
        interestNotificationData
      );

      interestNotificationData.mitarbeiterEins = firstConfirmer;
      interestNotificationData.mitarbeiterZwei = account.address;

      yield call(
        postInterestNotification,
        ssdInfoForInterest.arranger,
        ssdInfoForInterest.ssdid,
        interestDataWithFingerprint
      );

      yield call(
        createZinsmitteilungsPdf,
        ssdInfoForInterest.arranger,
        ssdInfoForInterest.ssdid,
        ssdInfoForInterest.fingerprint,
        ZERO_HASH,
        toUtf8(interestNotificationData.id)
      );

      yield call(sendMail, {
        template: mailTemplates.zinsmitteilung_abgestimmt,
        arranger: ssdInfoForInterest.arranger,
        ssdid: ssdInfoForInterest.ssdid,
        fingerprint: ssdInfoForInterest.fingerprint,
        fingerprint_rebuy: ZERO_HASH,
        recipient: ssdInfoForInterest.buyer,
        subid: toUtf8(interestNotificationData.id),
      });
    }
    yield put(confirmInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_wurde_bestaetigt
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(confirmInterestNotificationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* fetchInterstNotificationDetails({ arranger, interestNotificationId, ssdId }) {
  yield call(fetchInterestNotificationDetail, arranger, toUtf8(ssdId), interestNotificationId);
}

export function* createAndConfirmInterestNotification({
  account,
  ssdInfoForInterest,
  interestData,
  fourEyesFlag,
}) {
  try {
    const interestDataWithFingerprint = yield generateSsdInterestNotificationFingerprint(
      interestData
    );

    const { zinsmitteilungid } = yield call(
      postInterestNotification,
      ssdInfoForInterest.arranger,
      ssdInfoForInterest.ssdid,
      interestDataWithFingerprint
    );

    yield call(
      interestNotificationNewAndOffer,
      account,
      ssdInfoForInterest.arranger,
      toHex(ssdInfoForInterest.ssdid),
      toHex(`${zinsmitteilungid}`),
      interestDataWithFingerprint.fingerprint,
      ssdInfoForInterest.fingerprint
    );

    if (!fourEyesFlag) {
      yield call(
        createZinsmitteilungsPdf,
        ssdInfoForInterest.arranger,
        ssdInfoForInterest.ssdid,
        ssdInfoForInterest.fingerprint,
        ZERO_HASH,
        zinsmitteilungid
      );

      yield call(sendMail, {
        template: mailTemplates.zinsmitteilung_abgestimmt,
        arranger: ssdInfoForInterest.arranger,
        ssdid: ssdInfoForInterest.ssdid,
        fingerprint: ssdInfoForInterest.fingerprint,
        fingerprint_rebuy: ZERO_HASH,
        recipient: ssdInfoForInterest.buyer,
        subid: zinsmitteilungid,
      });
    }

    yield put(createAndConfirmInterestNotifications.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_interest_notification_zinsmitteilung_gespeichert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(createAndConfirmInterestNotifications.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_gespeichert_werden
        )
      )
    );
  }
}

export function* startEventListener() {
  const currentBlock = yield call(getBlockNumber);
  const listener = [];
  listener.push(yield fork(fourEyesEventListener, currentBlock));
  listener.push(yield fork(InterestNotificationEventListener, currentBlock));
  yield take(STOP_INTEREST_NOTIFICATION_EVENT_LISTENER);
  yield all(listener.map((channel) => cancel(channel)));
}

export function* fourEyesEventListener(currentBlock) {
  try {
    const chan = yield call(listenForInterestNotificationFourEyesEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* InterestNotificationEventListener(currentBlock) {
  try {
    const chan = yield call(listenForInterestNotificationEvent, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchInterestNotificationDetail(arranger, ssdId, interestId) {
  try {
    const details = yield call(getInterestNotificationDetails, arranger, ssdId, toUtf8(interestId));

    const fourEyes = yield call(getFourEyesState, arranger, ssdId, toUtf8(interestId));

    const interestNotificationState = yield call(
      getInterestNotificationState,
      arranger,
      ssdId,
      toUtf8(interestId)
    );

    const info = {
      ...details,
      ssdId,
      firstConfirmer: fourEyes.firstConfirmer,
      fourEyesState: fourEyes.state,
      state: interestNotificationState.state,
    };

    yield put(fetchInterestNotificationDetailsActions.success(info));
  } catch (error) {
    console.error(error);
    yield put(fetchInterestNotificationDetailsActions.failure(error));
  }
}

export function* fetchInterestNotification({ arranger, ssdId }) {
  try {
    const interestNotifications = yield call(
      getInterestNotificationsForSsdId,
      arranger,
      toHex(`${ssdId}`)
    );

    yield put(fetchInterestNotificationActions.success(interestNotifications));

    for (let notification of interestNotifications) {
      yield fork(fetchInterestNotificationDetail, arranger, ssdId, notification.id);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createInterestNotification({ account, arranger, ssdId, interestData }) {
  try {
    const interestDataWithFingerprint = yield generateSsdInterestNotificationFingerprint(
      interestData
    );

    const { zinsmitteilungid } = yield call(
      postInterestNotification,
      arranger,
      ssdId,
      interestDataWithFingerprint
    );

    yield call(
      interestNotificationNew,
      account,
      arranger,
      toHex(ssdId),
      toHex(`${zinsmitteilungid}`),
      interestDataWithFingerprint.fingerprint
    );

    yield put(createInterestNotificationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_interest_notification_zinsmitteilung_gespeichert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(createInterestNotificationActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_interest_notification_zinsmitteilung_konnte_nicht_gespeichert_werden
        )
      )
    );
  }
}
