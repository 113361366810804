import React from "react";
import { useTheme, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  logo: {
    display: "block",
    width: "100%",
    height: "auto",
  },
  logoWrapper: {
    width: "200px",
    padding: "20px 25px 20px 25px",
  },
});

const Logo = ({ classes }) => {
  const theme = useTheme();
  const logoFile = theme.palette.type === "light" ? "logo.png" : "logo_white.png";

  return (
    <div className={classes.logoWrapper}>
      <img src={logoFile} className={classes.logo} alt="logo" />
    </div>
  );
};

export default withStyles(styles)(Logo);
