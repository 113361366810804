import {
  INVITE_COLLEAGUE_SEND,
  INVITE_COLLEAGUE_SUCCESS,
  INVITE_COLLEAGUE_FAILURE,
  REVOKE_INVITE_COLLEAGUE_REQUEST,
  REVOKE_INVITE_COLLEAGUE_SUCCESS,
  REVOKE_INVITE_COLLEAGUE_FAILURE,
  GET_COLLEAGUES_SUCCESS,
  GET_COLLEAGUES_PERMISSIONS_SUCCESS,
  OPEN_ROLE_MODAL,
  CLOSE_ROLE_MODAL,
  GRANT_REVOKE_PRIVILEGES_REQUEST,
  GRANT_REVOKE_PRIVILEGES_SUCCESS,
  GRANT_REVOKE_PRIVILEGES_FAILURE,
  OPEN_DEACTIVATE_USER_DIALOG,
  CLOSE_DEACTIVATE_USER_DIALOG,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_ERROR,
  CREATE_TECHNICALUSER,
} from "./actions";

// reducer with initial state
const initialState = {
  colleagues: [],
  colleagueData: {},
  walletAddress: "",
  permissions: {
    PRIVILEGE_CONTROLLER_BSSD_OPERATIONS: false,
    PRIVILEGE_CONTROLLER_CSSD_OPERATIONS: false,
    PRIVILEGE_CONTROLLER_ZAHLSTELLE: false,
    PRIVILEGE_CONTROLLER_COORDINATOR: false,
    PRIVILEGE_CONTROLLER_INTEREST_OPERATIONS: false,
    PRIVILEGE_CONTROLLER_READ_ONLY: false,
  },
  invitePending: false,
  revokeInvitePending: false,
  roleModalOpen: false,
  saveRolesPending: false,
  deactivateUserModalOpen: false,
  deactivateUserPending: false,
};

function colleagueInviteSendReducer(state) {
  return {
    ...state,
    invitePending: true,
  };
}

function colleagueInviteSuccessReducer(state) {
  return {
    ...state,
    invitePending: false,
  };
}

function colleagueInviteFailureReducer(state) {
  return {
    ...state,
    invitePending: false,
  };
}

function revokeColleagueInviteSendReducer(state) {
  return {
    ...state,
    revokeInvitePending: true,
  };
}

function revokeColleagueInviteSuccessReducer(state) {
  return {
    ...state,
    revokeInvitePending: false,
  };
}

function revokeColleagueInviteFailureReducer(state) {
  return {
    ...state,
    revokeInvitePending: false,
  };
}

function fetchColleaguesSuccessReducer(state, action) {
  return {
    ...state,
    colleagues: action.colleagues,
  };
}

function fetchColleaguesPermissionsSuccessReducer(state, action) {
  return {
    ...state,
    walletAddress: action.walletAddress,
    permissions: action.permissions,
  };
}

function openRoleModalReducer(state, action) {
  return {
    ...state,
    roleModalOpen: true,
    colleagueData: action.colleagueData,
  };
}

function closeRoleModalReducer(state) {
  return {
    ...state,
    roleModalOpen: false,
  };
}

function grantRevokePrivilegesRequestReducer(state) {
  return {
    ...state,
    saveRolesPending: true,
  };
}

function grantRevokePrivilegesSuccessReducer(state) {
  return {
    ...state,
    saveRolesPending: false,
    roleModalOpen: false,
  };
}

function grantRevokePrivilegesFailureReducer(state) {
  return {
    ...state,
    saveRolesPending: false,
    roleModalOpen: false,
  };
}

function openDeactivateUserModalReducer(state, action) {
  return {
    ...state,
    deactivateUserModalOpen: true,
    colleagueData: action.colleagueData,
  };
}

function closeDeactivateUserModalReducer(state) {
  return {
    ...state,
    deactivateUserModalOpen: false,
  };
}

function deactivateUserRequestReducer(state) {
  return {
    ...state,
    deactivateUserPending: true,
  };
}

function deactivateUserSuccessReducer(state) {
  return {
    ...state,
    deactivateUserPending: false,
    deactivateUserModalOpen: false,
  };
}

function deactivateUserFailureReducer(state) {
  return {
    ...state,
    deactivateUserPending: false,
    deactivateUserModalOpen: false,
  };
}

function createTechnicalUser(state) {
  return {
    ...state,
    invitePending: true,
  };
}

export function colleagueReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_COLLEAGUE_SEND:
      return colleagueInviteSendReducer(state, action);
    case INVITE_COLLEAGUE_SUCCESS:
      return colleagueInviteSuccessReducer(state, action);
    case INVITE_COLLEAGUE_FAILURE:
      return colleagueInviteFailureReducer(state);

    case REVOKE_INVITE_COLLEAGUE_REQUEST:
      return revokeColleagueInviteSendReducer(state, action);
    case REVOKE_INVITE_COLLEAGUE_SUCCESS:
      return revokeColleagueInviteSuccessReducer(state, action);
    case REVOKE_INVITE_COLLEAGUE_FAILURE:
      return revokeColleagueInviteFailureReducer(state);

    case GET_COLLEAGUES_SUCCESS:
      return fetchColleaguesSuccessReducer(state, action);

    case GET_COLLEAGUES_PERMISSIONS_SUCCESS:
      return fetchColleaguesPermissionsSuccessReducer(state, action);

    case OPEN_ROLE_MODAL:
      return openRoleModalReducer(state, action);
    case CLOSE_ROLE_MODAL:
      return closeRoleModalReducer(state, action);

    case OPEN_DEACTIVATE_USER_DIALOG:
      return openDeactivateUserModalReducer(state, action);
    case CLOSE_DEACTIVATE_USER_DIALOG:
      return closeDeactivateUserModalReducer(state, action);

    case DEACTIVATE_USER_REQUEST:
      return deactivateUserRequestReducer(state, action);
    case DEACTIVATE_USER_SUCCESS:
      return deactivateUserSuccessReducer(state, action);
    case DEACTIVATE_USER_ERROR:
      return deactivateUserFailureReducer(state, action);

    case GRANT_REVOKE_PRIVILEGES_REQUEST:
      return grantRevokePrivilegesRequestReducer(state, action);
    case GRANT_REVOKE_PRIVILEGES_SUCCESS:
      return grantRevokePrivilegesSuccessReducer(state, action);
    case GRANT_REVOKE_PRIVILEGES_FAILURE:
      return grantRevokePrivilegesFailureReducer(state, action);
    case CREATE_TECHNICALUSER:
      return createTechnicalUser(state, action);
    default:
      return state;
  }
}
