import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { signInReducer } from "redux/signIn/reducers";
import { ssdEventReducer } from "redux/ssdEvents/reducers";
import { ssdStatusReducer } from "redux/ssdStatus/reducers";
import { cessionReducer } from "redux/cession/reducers";
import { walletRegistrationReducer } from "redux/walletRegistration/reducers";
import { colleagueReducer } from "redux/colleague/reducers";
import { accountReducer } from "redux/account/reducers";
import { pdfVerificationReducer } from "redux/pdfVerification/reducers";
import { platformMemberReducer } from "redux/platformMember/reducers";
import { businessPartnerReducer } from "redux/businessPartner/reducers";
import { ssdPaymentStatusReducer } from "redux/ssdPaymentStatus/reducers";
import { interestNotificationReducer } from "redux/interestNotification/reducers";
import { terminationReducer } from "redux/termination/reducers";
import { auditReducer } from "redux/audit/reducers";
import { recoveryReducer } from "redux/recovery/reducers";
import { cssdEventReducer } from "redux/cssdEvents/reducers";
import { cssdActionReducer } from "redux/cssdActions/reducers";
import { cssdTeilforderungenReducer } from "redux/cssdTeilforderungen/reducers";
import { cssdCessionReducer } from "redux/cssdCession/reducers";
import { cssdInformationsmaterialReducer } from "redux/cssdInformationsmaterial/reducers";
import { sharedReducer } from "redux/shared/reducers";
import { cssdAuditReducer } from "redux/cssdAudit/reducers";
import { cssdTerminationReducer } from "redux/cssdTermination/reducers";
import { userInformationReducer } from "./userInformation/reducers";

import { rootSaga } from "redux/rootSaga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// redux dev-tool enhancer
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  sharedReducer,
  signInReducer,
  ssdEventReducer,
  ssdStatusReducer,
  cessionReducer,
  walletRegistrationReducer,
  colleagueReducer,
  accountReducer,
  pdfVerificationReducer,
  platformMemberReducer,
  businessPartnerReducer,
  ssdPaymentStatusReducer,
  interestNotificationReducer,
  terminationReducer,
  auditReducer,
  recoveryReducer,
  cssdEventReducer,
  cssdActionReducer,
  cssdTeilforderungenReducer,
  cssdCessionReducer,
  cssdInformationsmaterialReducer,
  cssdAuditReducer,
  cssdTerminationReducer,
  userInformationReducer,
});

// redux store
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// run the saga
sagaMiddleware.run(rootSaga);
