export const wallet_styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 680,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  buttonsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  wrapper: {
    marginLeft: theme.spacing(4),
    marginTop: "16px",
    position: "relative",
  },
  warning: {
    color: theme.palette.error.main,
    float: "left",
  },
  warningOptional: {
    color: theme.palette.primary2.main,
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});
