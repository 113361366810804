import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { invalidDataAlertDialog } from "redux/shared/actions";
import BackButton from "components/Shared/Button/BackButton";

const InvalidDataAlertDialog = ({ open, callback, close }) => {
  const handleClose = () => {
    callback(false);
    close();
  };
  const handleContinue = () => {
    callback(true);
    close();
  };
  return (
    <Dialog disableBackdropClick open={open}>
      <DialogTitle>
        <FormattedMessage {...messages.title} />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage {...messages.body} />
      </DialogContent>
      <DialogActions>
        <BackButton onClick={handleClose}>
          <FormattedMessage {...messages.abort} />
        </BackButton>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          <FormattedMessage {...messages.continue} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  open: state.sharedReducer.alertDialog.open,
  callback: state.sharedReducer.alertDialog.callback,
});

const mapDispatchToProps = {
  close: invalidDataAlertDialog.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvalidDataAlertDialog);
