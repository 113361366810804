import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import Dashboard from "../Dashboard/Dashboard";
import SignIn from "../Signin/SignIn";
import WalletRegistration from "../WalletRegistration/WalletRegistration";
import PdfVerificationBSSD from "../PdfVerification/BSSD/PdfVerification";
import PdfVerificationCSSD from "../PdfVerification/CSSD/PdfVerification";
import history from "util/history";
import { withStyles, LinearProgress } from "@material-ui/core";
const styles = (theme) => ({
  progress: {
    position: "relative",
    zIndex: theme.zIndex.drawer + 2,
  },
});

class Navigation extends Component {
  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        this.props.isLoggedIn === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  render() {
    const { classes, privileges, isLoggedIn } = this.props;
    const hasAnyPrivilege = Object.values(privileges).reduce((a, b) => a || b);
    return (
      <Router history={history}>
        <React.Fragment>
          {this.props.isPending && <LinearProgress color="primary" className={classes.progress} />}
          <Switch>
            {(!isLoggedIn || !hasAnyPrivilege) && <Route path="/login" component={SignIn} />}
            <Route path="/verification/bssd" component={PdfVerificationBSSD} />
            <Route path="/verification/cssd" component={PdfVerificationCSSD} />
            <this.PrivateRoute path="/home" component={Dashboard} />
            {this.props.isLoggedIn && this.props.isPlatformMemberInvited && (
              <Route path="/wallet" component={WalletRegistration} />
            )}
            {(privileges.privilegeBssdOperations ||
              privileges.readOnly ||
              privileges.interestNotification) && (
              <this.PrivateRoute path="/bssdoverview" component={Dashboard} />
            )}
            {(privileges.privilegeBssdOperations || privileges.readOnly) && (
              <this.PrivateRoute path="/cessionoverview" component={Dashboard} />
            )}
            {(privileges.privilegeBssdOperations || privileges.readOnly) &&
              privileges.privilegeSsdOffer && (
                <this.PrivateRoute path="/bssd" component={Dashboard} />
              )}
            {privileges.zahlstellenMa && privileges.privilegeCssdOffer && (
              <this.PrivateRoute path="/cssd" component={Dashboard} />
            )}
            {(privileges.privilegeBssdOperations || privileges.readOnly) && (
              <this.PrivateRoute path="/cession/:ssdid" component={Dashboard} />
            )}
            {(privileges.privilegeBssdOperations || privileges.readOnly) && (
              <this.PrivateRoute path="/cessionnotify/:ssdid" component={Dashboard} />
            )}
            {privileges.isSuperUser && privileges.companyIsPlaformCoordinator && (
              <this.PrivateRoute path="/registrations" component={Dashboard} />
            )}
            {privileges.isSuperUser && (
              <this.PrivateRoute path="/colleague" component={Dashboard} />
            )}
            {privileges.isSuperUser && privileges.companyIsPlaformCoordinator && (
              <this.PrivateRoute path="/platformmember" component={Dashboard} />
            )}
            {privileges.isSuperUser && (
              <this.PrivateRoute path="/businesspartner" component={Dashboard} />
            )}
            {privileges.isSuperUser && <this.PrivateRoute path="/recovery" component={Dashboard} />}
            {(privileges.privilegeSsdOffer || privileges.interestNotification) && (
              <this.PrivateRoute path="/interest-notification/:ssdid" component={Dashboard} />
            )}
            {((privileges.zahlstellenMa && privileges.privilegeCssdOffer) ||
              privileges.privilegeCssdOperations ||
              privileges.readOnly) && (
              <this.PrivateRoute path="/cssdoverview" component={Dashboard} />
            )}
            {(privileges.zahlstellenMa ||
              privileges.privilegeCssdOperations ||
              privileges.readOnly) && (
              <this.PrivateRoute path="/cssdpartialclaimoverview" component={Dashboard} />
            )}
            {(privileges.readOnly || privileges.privilegeCssdOperations) &&
              !privileges.privilegePlatformReadOnly && (
                <this.PrivateRoute path="/cssdcessionrequest" component={Dashboard} />
              )}
            <this.PrivateRoute path="/profile" component={Dashboard} />
            <this.PrivateRoute path="/userinformation" component={Dashboard} />
            <this.PrivateRoute path="/migrate" component={Dashboard} />
            {hasAnyPrivilege ? (
              <Route render={() => <Redirect to="/home" />} />
            ) : (
              <Route render={() => <Redirect to="/login" />} />
            )}
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}

const isPending = (state) => {
  return (
    state.ssdStatusReducer.globalPending ||
    state.businessPartnerReducer.inviteIsPending ||
    state.businessPartnerReducer.acceptIsPending ||
    state.ssdPaymentStatusReducer.ssdPaymentActionPending ||
    state.platformMemberReducer.revokePlatformMemberPending ||
    state.platformMemberReducer.inviteAgainPending ||
    state.platformMemberReducer.invitePending ||
    state.colleagueReducer.revokeInvitePending ||
    state.colleagueReducer.invitePending ||
    state.recoveryReducer.isPending ||
    state.ssdPaymentStatusReducer.cancelPending ||
    state.cssdActionReducer.downloadCssdPdfPending ||
    state.cssdInformationsmaterialReducer.pendingIds.length > 0 ||
    state.cssdInformationsmaterialReducer.uploadPending ||
    state.cssdTeilforderungenReducer.exporting ||
    state.cssdCessionReducer.loadingCessionOverview ||
    state.cssdTeilforderungenReducer.isLoadingDetails
  );
};

const mapStateToProps = (state) => {
  return {
    privileges: state.signInReducer.privileges,
    isLoggedIn: state.signInReducer.isLoggedIn,
    isPlatformMemberInvited: state.signInReducer.isPlatformMemberInvited,
    isPending: isPending(state),
  };
};

export default withStyles(styles)(connect(mapStateToProps, {})(Navigation));
