export const OPEN_CESSION_REGISTER_MODAL = "OPEN_CESSION_REGISTER_MODAL";
export const CLOSE_CESSION_REGISTER_MODAL = "CLOSE_CESSION_REGISTER_MODAL";

export const OPEN_CESSION_CONFIRM_MODAL = "OPEN_CESSION_CONFIRM_MODAL";
export const CLOSE_CESSION_CONFIRM_MODAL = "CLOSE_CESSION_CONFIRM_MODAL";

export const FETCH_REJECT_REASON_SUCCESS = "FETCH_REJECT_REASON_SUCCESS";
export const FETCH_REJECT_REASON_EMPTY = "FETCH_REJECT_REASON_EMPTY";

export const OPEN_CESSION_PREVIEW_MODAL = "OPEN_CESSION_PREVIEW_MODAL";
export const CLOSE_CESSION_PREVIEW_MODAL = "CLOSE_CESSION_PREVIEW_MODAL";

export const SAVE_CESSION_REQUEST = "SAVE_CESSION_REQUEST";
export const SAVE_CESSION_SUCCESS = "SAVE_CESSION_SUCCESS";
export const SAVE_CESSION_FAILURE = "SAVE_CESSION_FAILURE";

export const MODIFY_CESSION_REQUEST = "MODIFY_CESSION_REQUEST";
export const MODIFY_CESSION_SUCCESS = "MODIFY_CESSION_SUCCESS";
export const MODIFY_CESSION_FAILURE = "MODIFY_CESSION_FAILURE";

export const MODIFY_AND_OFFER_CESSION_REQUEST = "MODIFY_AND_OFFER_CESSION_REQUEST";
export const MODIFY_AND_OFFER_CESSION_SUCCESS = "MODIFY_AND_OFFER_CESSION_SUCCESS";
export const MODIFY_AND_OFFER_CESSION_FAILURE = "MODIFY_AND_OFFER_CESSION_FAILURE";

export const SUBMIT_CESSION_REQUEST = "SUBMIT_CESSION_REQUEST";
export const SUBMIT_CESSION_SUCCESS = "SUBMIT_CESSION_SUCCESS";
export const SUBMIT_CESSION_FAILURE = "SUBMIT_CESSION_FAILURE";

export const OFFER_CESSION_REQUEST = "OFFER_CESSION_REQUEST";
export const OFFER_CESSION_SUCCESS = "OFFER_CESSION_SUCCESS";
export const OFFER_CESSION_FAILURE = "OFFER_CESSION_FAILURE";

export const FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST = "FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST";
export const FETCH_CESSION_FOR_SSD_REQUEST = "FETCH_CESSION_FOR_SSD_REQUEST";
export const FETCH_CESSION_FOR_SSD_SUCCESS = "FETCH_CESSION_FOR_SSD_SUCCESS";
export const FETCH_CESSION_FOR_SSD_FAILURE = "FETCH_CESSION_FOR_SSD_FAILURE";

export const FETCH_CESSION_NOTIFY_FOR_SSD_UPDATE_AND_REQUEST =
  "FETCH_CESSION_NOTIFY_FOR_SSD_UPDATE_AND_REQUEST";
export const FETCH_CESSION_NOTIFY_FOR_SSD_REQUEST = "FETCH_CESSION_NOTIFY_FOR_SSD_REQUEST";
export const FETCH_CESSION_NOTIFY_FOR_SSD_SUCCESS = "FETCH_CESSION_NOTIFY_FOR_SSD_SUCCESS";
export const FETCH_CESSION_NOTIFY_FOR_SSD_FAILURE = "FETCH_CESSION_NOTIFY_FOR_SSD_FAILURE";

export const FETCH_CESSION_DETAILS_FOR_SSD_SUCCESS = "FETCH_CESSION_DETAILS_FOR_SSD_SUCCESS";
export const FETCH_CESSION_DETAILS_FOR_SSD_NO_PERMISSION =
  "FETCH_CESSION_DETAILS_FOR_SSD_NO_PERMISSION";
export const FETCH_CESSION_DETAILS_FOR_SSD_FAILURE = "FETCH_CESSION_DETAILS_FOR_SSD_FAILURE";

export const FETCH_CESSION_REQUESTS_REQUEST = "FETCH_CESSION_REQUESTS_REQUEST";
export const FETCH_CESSION_REQUESTS_SUCCESS = "FETCH_CESSION_REQUESTS_SUCCESS";
export const FETCH_CESSION_REQUESTS_FAILURE = "FETCH_CESSION_REQUESTS_FAILURE";

export const FETCH_CESSION_REQUEST_DETAILS_SUCCESS = "FETCH_CESSION_REQUEST_DETAILS_SUCCESS";
export const FETCH_CESSION_REQUEST_DETAILS_NO_PERMISSION =
  "FETCH_CESSION_REQUEST_DETAILS_NO_PERMISSION";
export const FETCH_CESSION_REQUEST_DETAILS_FAILURE = "FETCH_CESSION_REQUEST_DETAILS_FAILURE";

export const STOP_CESSION_LISTENER = "STOP_CESSION_LISTENER";

export const CESSION_EVENT_UPDATE = "CESSION_EVENT_UPDATE";
export const ADD_CESSION_EVENT_DG = "ADD_CESSION_EVENT_DG";
export const ADD_CESSION_EVENT_NEW_DG = "ADD_CESSION_EVENT_NEW_DG";

export const CESSION_FOUR_EYES_EVENT_UPDATE = "CESSION_FOUR_EYES_EVENT_UPDATE";

export const ACCEPT_CESSION_REQUEST = "ACCEPT_CESSION_REQUEST";
export const ACCEPT_CESSION_SUCCESS = "ACCEPT_CESSION_SUCCESS";
export const ACCEPT_CESSION_FAILURE = "ACCEPT_CESSION_FAILURE";

export const REJECT_CESSION_REQUEST = "REJECT_CESSION_REQUEST";
export const REJECT_CESSION_SUCCESS = "REJECT_CESSION_SUCCESS";
export const REJECT_CESSION_FAILURE = "REJECT_CESSION_FAILURE";

export const CANCEL_CESSION_REQUEST = "CANCEL_CESSION_REQUEST";
export const CANCEL_CESSION_SUCCESS = "CANCEL_CESSION_SUCCESS";
export const CANCEL_CESSION_FAILURE = "CANCEL_CESSION_FAILURE";

export const NOTIFY_CESSION_REQUEST = "NOTIFY_CESSION_REQUEST";
export const NOTIFY_CESSION_SUCCESS = "NOTIFY_CESSION_SUCCESS";
export const NOTIFY_CESSION_FAILURE = "NOTIFY_CESSION_FAILURE";

export const CESSION_PROCESS_NOTIFICATION_REQUEST = "CESSION_PROCESS_NOTIFICATION_REQUEST";
export const CESSION_PROCESS_NOTIFICATION_SUCCESS = "CESSION_PROCESS_NOTIFICATION_SUCCESS";
export const CESSION_PROCESS_NOTIFICATION_FAILURE = "CESSION_PROCESS_NOTIFICATION_FAILURE";

export const DOWNLOAD_CESSION_ANZEIGE_REQUEST = "DOWNLOAD_CESSION_ANZEIGE_REQUEST";
export const DOWNLOAD_CESSION_ANZEIGE_SUCCESS = "DOWNLOAD_CESSION_ANZEIGE_SUCCESS";
export const DOWNLOAD_CESSION_ANZEIGE_FAILURE = "DOWNLOAD_CESSION_ANZEIGE_FAILURE";

export const DOWNLOAD_CESSION_CONFIRMATION_REQUEST = "DOWNLOAD_CESSION_CONFIRMATION_REQUEST";
export const DOWNLOAD_CESSION_CONFIRMATION_SUCCESS = "DOWNLOAD_CESSION_CONFIRMATION_SUCCESS";
export const DOWNLOAD_CESSION_CONFIRMATION_FAILURE = "DOWNLOAD_CESSION_CONFIRMATION_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const downloadCessionConfirmationPdfActions = {
  request: (cessionInitiator, ssdId, cessionId, cessionHash, cessionInfo, ssdDetails) =>
    action(DOWNLOAD_CESSION_CONFIRMATION_REQUEST, {
      cessionInitiator,
      ssdId,
      cessionId,
      cessionHash,
      cessionInfo,
      ssdDetails,
    }),
  success: (response) => action(DOWNLOAD_CESSION_CONFIRMATION_SUCCESS, { response }),
  failure: (error) => action(DOWNLOAD_CESSION_CONFIRMATION_FAILURE, { error }),
};

export const downloadCessionAnzeigePdfActions = {
  request: (darlehensnehmer, cessionInitiator, ssdId, cessionId, cessionHash, oldSsd) =>
    action(DOWNLOAD_CESSION_ANZEIGE_REQUEST, {
      darlehensnehmer,
      cessionInitiator,
      ssdId,
      cessionId,
      cessionHash,
      oldSsd,
    }),
  success: (response) => action(DOWNLOAD_CESSION_ANZEIGE_SUCCESS, { response }),
  failure: (error) => action(DOWNLOAD_CESSION_ANZEIGE_FAILURE, { error }),
};

export const cessionProcessNotificationActions = {
  request: (
    account,
    arranger,
    ssdId,
    cessionIds,
    cessionExpectedHashes,
    newSsdIds,
    newSsdHashes,
    urkunden_registernummer,
    cessions
  ) =>
    action(CESSION_PROCESS_NOTIFICATION_REQUEST, {
      account,
      arranger,
      ssdId,
      cessionIds,
      cessionExpectedHashes,
      newSsdIds,
      newSsdHashes,
      urkunden_registernummer,
      cessions,
    }),
  success: () => action(CESSION_PROCESS_NOTIFICATION_SUCCESS, {}),
  failure: (error) => action(CESSION_PROCESS_NOTIFICATION_FAILURE, { error }),
};

export const cessionNotifyActions = {
  request: (account, arranger, ssdId, ssdHash, cessionsToNotify, cessionToMove, ssdDetails) =>
    action(NOTIFY_CESSION_REQUEST, {
      account,
      arranger,
      ssdId,
      ssdHash,
      cessionsToNotify,
      cessionToMove,
      ssdDetails,
    }),
  success: () => action(NOTIFY_CESSION_SUCCESS, {}),
  failure: (err) => action(NOTIFY_CESSION_FAILURE, { err }),
};

export const cessionListenerActions = {
  stopListener: () => action(STOP_CESSION_LISTENER, {}),
};

export const cessionRegisterModalActions = {
  open: () => action(OPEN_CESSION_REGISTER_MODAL, {}),
  close: () => action(CLOSE_CESSION_REGISTER_MODAL, {}),
};

export const cessionConfirmModalActions = {
  open: (darlehensnehmer, ssdId, cessionId, cessionInitiator, cessionHash) =>
    action(OPEN_CESSION_CONFIRM_MODAL, {
      darlehensnehmer,
      ssdId,
      cessionId,
      cessionInitiator,
      cessionHash,
    }),
  close: () => action(CLOSE_CESSION_CONFIRM_MODAL, {}),
};

export const cessionFetchRejectReasonActions = {
  success: (reason) => action(FETCH_REJECT_REASON_SUCCESS, { reason }),
  empty: () => action(FETCH_REJECT_REASON_EMPTY),
};

export const cessionPreviewModalActions = {
  open: (cessionInfo) => action(OPEN_CESSION_PREVIEW_MODAL, { cessionInfo }),
  close: () => action(CLOSE_CESSION_PREVIEW_MODAL, {}),
};

export const fetchCessionEventsForSsdActions = {
  updateAndRequest: (account, arranger, ssdId) =>
    action(FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST, { account, arranger, ssdId }),
  request: (account, arranger, ssdId) =>
    action(FETCH_CESSION_FOR_SSD_REQUEST, { account, arranger, ssdId }),
  success: (cessions) => action(FETCH_CESSION_FOR_SSD_SUCCESS, { cessions }),
  failure: (error) => action(FETCH_CESSION_FOR_SSD_FAILURE, { error }),
};

export const fetchCessionNotifyEventsForSsdActions = {
  updateAndRequest: (account, arranger, ssdId) =>
    action(FETCH_CESSION_NOTIFY_FOR_SSD_UPDATE_AND_REQUEST, { account, arranger, ssdId }),
  request: (account, arranger, ssdId) =>
    action(FETCH_CESSION_NOTIFY_FOR_SSD_REQUEST, { account, arranger, ssdId }),
  success: (cessions) => action(FETCH_CESSION_NOTIFY_FOR_SSD_SUCCESS, { cessions }),
  failure: (error) => action(FETCH_CESSION_NOTIFY_FOR_SSD_FAILURE, { error }),
};

export const fetchCessionDetailsForSsdActions = {
  success: (cessionDetails) => action(FETCH_CESSION_DETAILS_FOR_SSD_SUCCESS, { cessionDetails }),
  notAllowed: (cessionId) => action(FETCH_CESSION_DETAILS_FOR_SSD_NO_PERMISSION, { cessionId }),
  failure: (error) => action(FETCH_CESSION_DETAILS_FOR_SSD_FAILURE, { error }),
};

export const fetchCessionRequestActions = {
  request: (companyIdProxy) => action(FETCH_CESSION_REQUESTS_REQUEST, { companyIdProxy }),
  success: (cessions) => action(FETCH_CESSION_REQUESTS_SUCCESS, { cessions }),
  failure: (error) => action(FETCH_CESSION_REQUESTS_FAILURE, { error }),
};

export const fetchCessionRequestDetailsActions = {
  success: (cessionDetails) => action(FETCH_CESSION_REQUEST_DETAILS_SUCCESS, { cessionDetails }),
  notAllowed: (cessionId) => action(FETCH_CESSION_REQUEST_DETAILS_NO_PERMISSION, { cessionId }),
  failure: (cessionId, error) =>
    action(FETCH_CESSION_REQUEST_DETAILS_FAILURE, { cessionId, error }),
};

export const cessionEventActions = {
  updateCession: (
    cessionId,
    cessionHash,
    cessionInitiator,
    ssdId,
    arranger,
    cessionState,
    seller
  ) =>
    action(CESSION_EVENT_UPDATE, {
      cessionId,
      cessionHash,
      cessionInitiator,
      ssdId,
      arranger,
      cessionState,
      seller,
    }),
  addNewCessionDG: (
    cessionId,
    cessionHash,
    cessionInitiator,
    ssdId,
    arranger,
    cessionState,
    seller
  ) =>
    action(ADD_CESSION_EVENT_DG, {
      cessionId,
      cessionHash,
      cessionInitiator,
      ssdId,
      arranger,
      cessionState,
      seller,
    }),
  addNewCessionNewDG: (
    cessionId,
    cessionHash,
    cessionInitiator,
    ssdId,
    arranger,
    cessionState,
    seller
  ) =>
    action(ADD_CESSION_EVENT_NEW_DG, {
      cessionId,
      cessionHash,
      cessionInitiator,
      ssdId,
      arranger,
      cessionState,
      seller,
    }),
};

export const cessionFourEyesEventActions = {
  updateCessionFourEyesState: (ssdId, cessionId, state, arranger, confirmer) =>
    action(CESSION_FOUR_EYES_EVENT_UPDATE, {
      ssdId,
      cessionId,
      state,
      arranger,
      confirmer,
    }),
};

export const saveCessionActions = {
  request: (account, oldSsdInfo, cessionData, cessionToExt) =>
    action(SAVE_CESSION_REQUEST, {
      account,
      oldSsdInfo,
      cessionData,
      cessionToExt,
    }),
  success: () => action(SAVE_CESSION_SUCCESS, {}),
  failure: (error) => action(SAVE_CESSION_FAILURE, { error }),
};

export const modifyCessionActions = {
  request: (account, oldSsdInfo, cessionData) =>
    action(MODIFY_CESSION_REQUEST, { account, oldSsdInfo, cessionData }),
  success: () => action(MODIFY_CESSION_SUCCESS, {}),
  failure: (error) => action(MODIFY_CESSION_FAILURE, { error }),
};

export const modifyAndOfferCessionActions = {
  request: (account, oldSsdInfo, cessionData, fourEyesFlag) =>
    action(MODIFY_AND_OFFER_CESSION_REQUEST, {
      account,
      oldSsdInfo,
      cessionData,
      fourEyesFlag,
    }),
  success: () => action(MODIFY_AND_OFFER_CESSION_SUCCESS, {}),
  failure: (error) => action(MODIFY_AND_OFFER_CESSION_FAILURE, { error }),
};

export const submitCessionActions = {
  request: (account, oldSsdInfo, cessionData, cessionToExt, fourEyesFlag) =>
    action(SUBMIT_CESSION_REQUEST, {
      account,
      oldSsdInfo,
      cessionData,
      cessionToExt,
      fourEyesFlag,
    }),
  success: () => action(SUBMIT_CESSION_SUCCESS, {}),
  failure: (error) => action(SUBMIT_CESSION_FAILURE, { error }),
};

export const offerCessionActions = {
  request: (
    account,
    arranger,
    ssdId,
    cessionId,
    ssdHash,
    cessionHash,
    confirmationState,
    firstConfirmer,
    externallySettled,
    fourEyesFlag
  ) =>
    action(OFFER_CESSION_REQUEST, {
      account,
      arranger,
      ssdId,
      cessionId,
      ssdHash,
      cessionHash,
      confirmationState,
      firstConfirmer,
      externallySettled,
      fourEyesFlag,
    }),
  success: () => action(OFFER_CESSION_SUCCESS, {}),
  failure: (error) => action(OFFER_CESSION_FAILURE, { error }),
};

export const acceptCessionActions = {
  request: (account, currentCession, isDataModified, fourEyesFlag) =>
    action(ACCEPT_CESSION_REQUEST, {
      account,
      currentCession,
      isDataModified,
      fourEyesFlag,
    }),
  success: () => action(ACCEPT_CESSION_SUCCESS, {}),
  failure: (error) => action(ACCEPT_CESSION_FAILURE, { error }),
};

export const rejectCessionActions = {
  request: (account, arranger, ssdId, cessionId, ssdHash, cessionHash, reason, actionInitiator) =>
    action(REJECT_CESSION_REQUEST, {
      account,
      arranger,
      ssdId,
      cessionId,
      ssdHash,
      cessionHash,
      reason,
      actionInitiator,
    }),
  success: () => action(REJECT_CESSION_SUCCESS, {}),
  failure: (error) => action(REJECT_CESSION_FAILURE, { error }),
};

export const cancelCessionActions = {
  request: (account, arranger, ssdId, cessionId, ssdHash, cessionHash, reason) =>
    action(CANCEL_CESSION_REQUEST, {
      account,
      arranger,
      ssdId,
      cessionId,
      ssdHash,
      cessionHash,
      reason,
    }),
  success: () => action(CANCEL_CESSION_SUCCESS, {}),
  failure: (error) => action(CANCEL_CESSION_FAILURE, { error }),
};
