import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Provider } from "react-redux";
//import registerServiceWorker from "./registerServiceWorker";
import { LocaleContextProvider } from "components/i18n/LocaleContext";
import { store } from "redux/store";

/* eslint-disable-next-line no-console */
console.log(process.env);

ReactDOM.render(
  <LocaleContextProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </LocaleContextProvider>,
  document.getElementById("root")
);
//registerServiceWorker();
