import { fade } from "@material-ui/core";

export const signin_styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
  },
  toolbar: {
    paddingLeft: 0,
  },
  toolbarButton: {
    textTransform: "none",
    color: theme.palette.text.secondary,
    "&:hover": {
      textDecoration: "none",
    },
  },
  toolbarLinks: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  launguageButtonWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  welcomeMessage: {
    marginTop: theme.spacing(12),
    textAlign: "center",
  },
  subTitle: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  loginTabs: {
    width: "100%",
  },
  registerTab: {
    color: theme.palette.primary.light,
    opacity: 0.5,
  },
  registerTabInkBar: {
    backgroundColor: theme.palette.primary.light,
    opacity: 0.5,
  },
  loginTab: {
    color: theme.palette.primary.main,
  },
  loginTabInkBar: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  loginTabSpacer: {
    paddingTop: theme.spacing(6),
  },
  onBoardingSpacer: {
    height: theme.spacing(5),
  },
  passwordRepeatSpacer: {
    height: theme.spacing(10),
  },
  loginIconWrapper: {
    textAlign: "center",
  },
  avatar: {
    color: theme.palette.primary.light,
    height: "100px",
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  formWithPassword: {
    width: "100%", // Fix IE11 issue.
    marginTop: -theme.spacing(0.5),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: "none",
  },
  button: {
    width: "100%", // Fix IE11 issue.
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  walletInformation: {
    fontSize: "16px",
  },
  nextButton: {
    margin: "20px 0",
    width: "100%",
  },
  nextButtonContent: {
    display: "flex",
    alignItems: "center",
  },
  bigAvatar: {
    marginTop: 10,
    width: 160,
    height: 160,
  },
  wrapper: {
    marginTop: "16px",
    position: "relative",
    textAlign: "center",
  },
  wrapperSmallMargin: {
    marginTop: "4px",
    position: "relative",
    textAlign: "center",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    width: "100%", // Fix IE11 issue.
    backgroundColor: theme.palette.primary2.main,
  },
  infoButton: {
    fontSize: "12px",
    color: theme.palette.text.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  passwordInput: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 1000px ${fade(theme.palette.primary.main, 0.1)} inset`,
      marginRight: -theme.spacing(9),
    },
  },
  showPassword: {
    marginRight: 0,
  },
});
