// action types

export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILURE = "GET_ACCOUNT_FAILURE";
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const getAccountActions = {
  request: (accountAddress, idProxyAddr) =>
    action(GET_ACCOUNT_REQUEST, { accountAddress, idProxyAddr }),
  success: (accountInfo) => action(GET_ACCOUNT_SUCCESS, { accountInfo }),
  failure: (error) => action(GET_ACCOUNT_FAILURE, { error }),
};

export const getCompanyActions = {
  request: (idProxyAddress) => action(GET_COMPANY_REQUEST, { idProxyAddress }),
  success: (companyInfo) => action(GET_COMPANY_SUCCESS, { companyInfo }),
  failure: (error) => action(GET_COMPANY_FAILURE, { error }),
};

export const UPDATE_PLATFORMMEMBER_INFO_REQUEST = "UPDATE_PLATFORMMEMBER_INFO_REQUEST";
export const UPDATE_PLATFORMMEMBER_INFO_SUCCESS = "UPDATE_PLATFORMMEMBER_INFO_SUCCESS";
export const UPDATE_PLATFORMMEMBER_INFO_FAILURE = "UPDATE_PLATFORMMEMBER_INFO_FAILURE";

export const updatePlatformMemberInfoActions = {
  request: (account, platformmeberInfo) =>
    action(UPDATE_PLATFORMMEMBER_INFO_REQUEST, { account, platformmeberInfo }),
  success: () => action(UPDATE_PLATFORMMEMBER_INFO_SUCCESS, {}),
  failure: (error) => action(UPDATE_PLATFORMMEMBER_INFO_FAILURE, { error }),
};

export const ACCEPT_PLATFORMMEMBER_INFO_REQUEST = "ACCEPT_PLATFORMMEMBER_INFO_REQUEST";
export const ACCEPT_PLATFORMMEMBER_INFO_SUCCESS = "ACCEPT_PLATFORMMEMBER_INFO_SUCCESS";
export const ACCEPT_PLATFORMMEMBER_INFO_FAILURE = "ACCEPT_PLATFORMMEMBER_INFO_FAILURE";

export const acceptPlatformMemberInfoActions = {
  request: (account, platformmeberInfo) =>
    action(ACCEPT_PLATFORMMEMBER_INFO_REQUEST, {
      account,
      platformmeberInfo,
    }),
  success: () => action(ACCEPT_PLATFORMMEMBER_INFO_SUCCESS, {}),
  failure: (error) => action(ACCEPT_PLATFORMMEMBER_INFO_FAILURE, { error }),
};

export const REJECT_PLATFORMMEMBER_INFO_REQUEST = "REJECT_PLATFORMMEMBER_INFO_REQUEST";
export const REJECT_PLATFORMMEMBER_INFO_SUCCESS = "REJECT_PLATFORMMEMBER_INFO_SUCCESS";
export const REJECT_PLATFORMMEMBER_INFO_FAILURE = "REJECT_PLATFORMMEMBER_INFO_FAILURE";

export const rejectPlatformMemberInfoActions = {
  request: (account, platformmeberInfo) =>
    action(REJECT_PLATFORMMEMBER_INFO_REQUEST, { account, platformmeberInfo }),
  success: () => action(REJECT_PLATFORMMEMBER_INFO_SUCCESS, {}),
  failure: (error) => action(REJECT_PLATFORMMEMBER_INFO_FAILURE, { error }),
};

export const START_MEMBER_DATA_LISTENER = "START_MEMBER_DATA_LISTENER";
export const STOP_MEMBER_DATA_LISTENER = "STOP_MEMBER_DATA_LISTENER";
export const MEMBER_DATA_MODIFY_EVENT = "MEMBER_DATA_MODIFY_EVENT";
export const MEMBER_DATA_REJECT_EVENT = "MEMBER_DATA_REJECT_EVENT";
export const MEMBER_DATA_ACCEPT_EVENT = "MEMBER_DATA_ACCEPT_EVENT";

export const memberDataEventListenerActions = {
  start: () => action(START_MEMBER_DATA_LISTENER, {}),
  stop: () => action(STOP_MEMBER_DATA_LISTENER, {}),
  update: (idProxyAddress, firstConfirmer) =>
    action(MEMBER_DATA_MODIFY_EVENT, { idProxyAddress, firstConfirmer }),
  reject: (idProxyAddress) => action(MEMBER_DATA_REJECT_EVENT, { idProxyAddress }),
  accept: (idProxyAddress) => action(MEMBER_DATA_ACCEPT_EVENT, { idProxyAddress }),
};

export const FETCH_PLATFORMMEMBER_DRAFT_SUCCESS = "FETCH_PLATFORMMEMBER_DRAFT_SUCCESS";
export const FETCH_PLATFORMMEMBER_DRAFT_FAILURE = "FETCH_PLATFORMMEMBER_DRAFT_FAILURE";

export const fetchPlatformMemberInfoDraftActions = {
  success: (draft, firstConfirmer) =>
    action(FETCH_PLATFORMMEMBER_DRAFT_SUCCESS, { draft, firstConfirmer }),
  failure: (error) => action(FETCH_PLATFORMMEMBER_DRAFT_FAILURE, { error }),
};

export const OPEN_MEMBER_INFO_EDIT_DIALOG = "OPEN_MEMBER_INFO_EDIT_DIALOG";
export const CLOSE_MEMBER_INFO_EDIT_DIALOG = "CLOSE_MEMBER_INFO_EDIT_DIALOG";

export const memberInfoEditDialogActions = {
  open: () => action(OPEN_MEMBER_INFO_EDIT_DIALOG, {}),
  close: () => action(CLOSE_MEMBER_INFO_EDIT_DIALOG, {}),
};

export const OPEN_EDIT_ADDRESS_BOOK_DIALOG = "OPEN_EDIT_ADDRESS_BOOK_DIALOG";
export const CLOSE_EDIT_ADDRESS_BOOK_DIALOG = "CLOSE_EDIT_ADDRESS_BOOK_DIALOG";
export const editAddressBookDialogActions = {
  open: (data) => action(OPEN_EDIT_ADDRESS_BOOK_DIALOG, { data }),
  close: () => action(CLOSE_EDIT_ADDRESS_BOOK_DIALOG, {}),
};

export const GET_ADDRESS_BOOK_ENTRY_REQUEST = "GET_ADDRESS_BOOK_ENTRY_REQUEST";
export const GET_ADDRESS_BOOK_ENTRY_SUCCESS = "GET_ADDRESS_BOOK_ENTRY_SUCCESS";
export const GET_ADDRESS_BOOK_ENTRY_FAILURE = "GET_ADDRESS_BOOK_ENTRY_FAILURE";
export const getAddressBookEntryActions = {
  request: (entryId, idProxyAddress) =>
    action(GET_ADDRESS_BOOK_ENTRY_REQUEST, { entryId, idProxyAddress }),
  success: () => action(GET_ADDRESS_BOOK_ENTRY_SUCCESS, {}),
  failure: (error) => action(GET_ADDRESS_BOOK_ENTRY_FAILURE, { error }),
};

export const GET_ADDRESS_BOOK_ENTRIES_REQUEST = "GET_ADDRESS_BOOK_ENTRIES_REQUEST";
export const GET_ADDRESS_BOOK_ENTRIES_SUCCESS = "GET_ADDRESS_BOOK_ENTRIES_SUCCESS";
export const GET_ADDRESS_BOOK_ENTRIES_FAILURE = "GET_ADDRESS_BOOK_ENTRIES_FAILURE";
export const getAddressBookEntriesActions = {
  request: () => action(GET_ADDRESS_BOOK_ENTRIES_REQUEST, {}),
  success: (data) => action(GET_ADDRESS_BOOK_ENTRIES_SUCCESS, { data }),
  failure: (error) => action(GET_ADDRESS_BOOK_ENTRIES_FAILURE, { error }),
};

export const DELETE_ADDRESS_BOOK_ENTRY_REQUEST = "DELETE_ADDRESS_BOOK_ENTRY_REQUEST";
export const DELETE_ADDRESS_BOOK_ENTRY_SUCCESS = "DELETE_ADDRESS_BOOK_ENTRY_SUCCESS";
export const DELETE_ADDRESS_BOOK_ENTRY_FAILURE = "DELETE_ADDRESS_BOOK_ENTRY_FAILURE";
export const deleteAddressBookEntryActions = {
  request: (entryId) => action(DELETE_ADDRESS_BOOK_ENTRY_REQUEST, { entryId }),
  success: (entryId) => action(DELETE_ADDRESS_BOOK_ENTRY_SUCCESS, { entryId }),
  failure: (error) => action(DELETE_ADDRESS_BOOK_ENTRY_FAILURE, { error }),
};

export const OPEN_DELETE_ADDRESS_BOOK_ENTRY_DIALOG = "OPEN_DELETE_ADDRESS_BOOK_ENTRY_DIALOG";
export const CLOSE_DELETE_ADDRESS_BOOK_ENTRY_DIALOG = "CLOSE_DELETE_ADDRESS_BOOK_ENTRY_DIALOG";
export const deleteAddressBookEntryDialogActions = {
  open: (data) => action(OPEN_DELETE_ADDRESS_BOOK_ENTRY_DIALOG, { data }),
  close: () => action(CLOSE_DELETE_ADDRESS_BOOK_ENTRY_DIALOG, {}),
};
export const SAVE_ADDRESS_BOOK_ENTRY_REQUEST = "SAVE_ADDRESS_BOOK_ENTRY_REQUEST";
export const SAVE_ADDRESS_BOOK_ENTRY_SUCCESS = "SAVE_ADDRESS_BOOK_ENTRY_SUCCESS";
export const SAVE_ADDRESS_BOOK_ENTRY_FAILURE = "SAVE_ADDRESS_BOOK_ENTRY_FAILURE";
export const saveAddressBookEntryActions = {
  request: (payload) => action(SAVE_ADDRESS_BOOK_ENTRY_REQUEST, { payload }),
  success: () => action(SAVE_ADDRESS_BOOK_ENTRY_SUCCESS, {}),
  failure: (error) => action(SAVE_ADDRESS_BOOK_ENTRY_FAILURE, { error }),
};
