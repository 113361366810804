import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_account_informationen_gespeichert: {
    id: "redux.account.informationen.gespeichert",
    defaultMessage: "Informationen gespeichert.",
  },
  redux_account_informationen_konnten_nicht_gespeichert_werden: {
    id: "redux.account.informationen.konnten.nicht.gespeichert.werden",
    defaultMessage: "Informationen konnten nicht gespeichert werden.",
  },
  redux_account_informationen_akzeptiert: {
    id: "redux.account.informationen.akzeptiert",
    defaultMessage: "Informationen akzeptiert.",
  },
  redux_account_informationen_konnten_nicht_akzeptiert_werden: {
    id: "redux.account.informationen.konnten.nicht.akzeptiert.werden",
    defaultMessage: "Informationen konnten nicht akzeptiert werden.",
  },
  redux_account_informationen_abgelehnt: {
    id: "redux.account.informationen.abgelehnt",
    defaultMessage: "Informationen abgelehnt.",
  },
  redux_account_informationen_konnten_nicht_abgelehnt_werden: {
    id: "redux.account.informationen.konnten.nicht.abgelehnt.werden",
    defaultMessage: "Informationen konnten nicht abgelehnt werden.",
  },
  redux_account_ein_fehler_ist_aufgetreten: {
    id: "redux.account.ein.fehler.ist.aufgetreten",
    defaultMessage: "Ein Fehler ist aufgetreten.",
  },
  redux_account_mitarbeiter_info_fehler: {
    id: "redux.account.mitarbeiter.info.fehler",
    defaultMessage: "Benutzerinformationen konnten nicht abgerufen werden.\nSie werden in Kürze abgemeldet.",
  },
  redux_account_adressbucheintrag_wurde_erfolgreich_geloescht: {
    id: "redux.account.adressbucheintrag.wurde.erfolgreich.geloescht",
    defaultMessage: "Adressbucheintrag wurde erfolgreich gelöscht.",
  },
  redux_account_adressbucheintrag_konnte_nicht_geloescht_werden: {
    id: "redux.account.adressbucheintrag.konnte.nicht.geloescht.werden",
    defaultMessage: "Adressbucheintrag konnte nicht gelöscht werden.",
  },
  redux_account_adressbucheintrag_wurde_erfolgreich_gespeichert: {
    id: "redux.account.adressbucheintrag.wurde.erfolgreich.gespeichert",
    defaultMessage: "Adressbucheintrag wurde erfolgreich gespeichert.",
  },
  redux_account_adressbucheintrag_konnte_nicht_gespeichert_werden: {
    id: "redux.account.adressbucheintrag.konnte.nicht.gespeichert.werden",
    defaultMessage: "Adressbucheintrag konnte nicht gespeichert werden.",
  },
  redux_account_adressbucheintrag_konnte_nicht_gespeichert_werden_weil_bereits_vorhanden: {
    id: "redux.account.adressbucheintrag.konnte.nicht.gespeichert.werden.weil.bereits.vorhanden",
    defaultMessage:
      "Adressbucheintrag konnte nicht gespeichert werden, da die Partner ID bereits vergeben ist.",
  },
});
