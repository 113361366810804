export const FETCH_SSD_EVENTS_REQUEST = "FETCH_SSD_EVENTS_REQUEST";
export const FETCH_SSD_EVENT_INFO_SUCCESS = "FETCH_SSD_EVENT_INFO_SUCCESS";
export const FETCH_SSD_EVENT_INFO_FAILURE = "FETCH_SSD_EVENT_INFO_FAILURE";
export const FETCH_SSD_EVENT_INFO_BULK_FAILURE = "FETCH_SSD_EVENT_INFO_BULK_FAILURE";
export const FETCH_SSD_EVENT_INFO_BULK_RETRY = "FETCH_SSD_EVENT_INFO_BULK_RETRY";
export const FETCH_SSD_EVENT_INFO_SET_TOTAL = "FETCH_SSD_EVENT_INFO_SET_TOTAL";
export const FETCH_SSD_EVENT_INFO_NO_PERMISSIONS = "FETCH_SSD_EVENT_INFO_NO_PERMISSIONS";
export const FETCH_SSD_EVENTS_FAILURE = "FETCH_SSD_EVENTS_FAILURE";
export const ADD_SSD_FROM_EVENT = "ADD_SSD_FROM_EVENT";
export const UPDATE_SSD_STATUS = "UPDATE_SSD_STATUS";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const UPDATE_FOUR_EYES_STATUS = "UPDATE_FOUR_EYES_STATUS";
export const UPDATE_REBUY_STATUS = "UPDATE_REBUY_STATUS";
export const UPDATE_TERMINATION_STATUS = "UPDATE_TERMINATION_STATUS";
export const STOP_SSD_LISTENER = "STOP_SSD_LISTENER";
export const FETCH_SSD_PREVIEW = "FETCH_SSD_PREVIEW";
export const FETCH_PAYMENT_PREVIEW = "FETCH_PAYMENT_PREVIEW";
export const UPDATE_SSD_PREVIEW = "UPDATE_SSD_PREVIEW";
export const OPEN_DETAIL_MODAL = "OPEN_DETAIL_MODAL";
export const CLOSE_DETAIL_MODAL = "CLOSE_DETAIL_MODAL";
export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";
export const OPEN_EDIT_MODAL = "OPEN_EDIT_MODAL";
export const CLOSE_EDIT_MODAL = "CLOSE_EDIT_MODAL";
export const UPDATE_ID_PROXY_ADDR = "UPDATE_ID_PROXY_ADDR";
export const PROVIDER_DISCONNECTED = "PROVIDER_DISCONNECTED";
export const PROVIDER_CONNECTED = "PROVIDER_CONNECTED";
export const PDF_DOWNLOAD = "PDF_DOWNLOAD";
export const TRADE_CONFIRMATION_DOWNLOAD = "TRADE_CONFIRMATION_DOWNLOAD";
export const REBUY_CONFIRMATION_DOWNLOAD = "REBUY_CONFIRMATION_DOWNLOAD";
export const TERMINATION_CONFIRMATION_DOWNLOAD = "TERMINATION_CONFIRMATION_DOWNLOAD";
export const OPEN_REBUY_MODAL = "OPEN_REBUY_MODAL";
export const CLOSE_REBUY_MODAL = "CLOSE_REBUY_MODAL";
export const OPEN_REBUY_PREVIEW_MODAL = "OPEN_REBUY_PREVIEW_MODAL";
export const CLOSE_REBUY_PREVIEW_MODAL = "CLOSE_REBUY_PREVIEW_MODAL";
export const OPEN_SIGN_DOCUMENT_MODAL = "OPEN_SIGN_DOCUMENT_MODAL";
export const CLOSE_SIGN_DOCUMENT_MODAL = "CLOSE_SIGN_DOCUMENT_MODAL";
export const FILTER_SSD_INFOS = "FILTER_SSD_INFOS";
export const RESET_SSD_INFOS_FILTER = "RESET_SSD_INFOS_FILTER";
export const EXPORT_BSSD_CSV = "EXPORT_BSSD_CSV";
export const EXPORT_BSSD_CSV_SUCCESS = "EXPORT_BSSD_CSV_SUCCESS";
export const EXPORT_BSSD_CSV_FAILURE = "EXPORT_BSSD_CSV_FAILURE";
export const ON_START_EVENT_LISTENER = "ON_START_EVENT_LISTENER";
export const ON_LOAD_BSSDS = "ON_LOAD_BSSDS";
export const ON_START_MIGRATION = "ON_START_MIGRATION";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_BSSDS = "SET_BSSDS";

const action = (type, payload = {}) => ({ type, ...payload });

export const ssdEventActions = {
  request: (companyAddr) => action(FETCH_SSD_EVENTS_REQUEST, { companyAddr }),
  infoSuccess: (ssdInfo) => action(FETCH_SSD_EVENT_INFO_SUCCESS, { ssdInfo }),
  infoFailure: (ssdid, arranger) => action(FETCH_SSD_EVENT_INFO_FAILURE, { ssdid, arranger }),
  infoBulkFailure: (ssdids, arranger) =>
    action(FETCH_SSD_EVENT_INFO_BULK_FAILURE, { ssdids, arranger }),
  infoBulkRetry: (loadErrors, arranger) =>
    action(FETCH_SSD_EVENT_INFO_BULK_RETRY, { loadErrors, arranger }),
  setTotal: (numTotal) => action(FETCH_SSD_EVENT_INFO_SET_TOTAL, { numTotal }),
  infoNoPermission: (ssdid) => action(FETCH_SSD_EVENT_INFO_NO_PERMISSIONS, { ssdid }),
  failure: (error) => action(FETCH_SSD_EVENTS_FAILURE, { error }),
  updateStatus: (id, state, ssdAction, fingerprint, arranger) =>
    action(UPDATE_SSD_STATUS, { id, state, ssdAction, fingerprint, arranger }),
  addSsd: (arranger, buyer, id) => action(ADD_SSD_FROM_EVENT, { arranger, buyer, id }),
};

export const bssdOverviewActions = {
  onStartEventListener: (companyAddr) => action(ON_START_EVENT_LISTENER, { companyAddr }),
  onLoadBssds: () => action(ON_LOAD_BSSDS),
  onStartMigration: () => action(ON_START_MIGRATION),
  setIsLoading: (isLoading) => action(SET_IS_LOADING, { isLoading }),
  setBssds: (bssds) => action(SET_BSSDS, { bssds }),
};

export const ssdListenerActions = {
  stopListener: () => action(STOP_SSD_LISTENER, {}),
};

export const ssdPaymentEventActions = {
  updateStatus: (id, state) => action(UPDATE_PAYMENT_STATUS, { id, state }),
};

export const fourEyesEventActions = {
  updateStatus: (id, state, firstConfirmer, arranger) =>
    action(UPDATE_FOUR_EYES_STATUS, { id, state, firstConfirmer, arranger }),
};

export const updateIdProxyAddrActions = {
  update: (idProxyAddr) => action(UPDATE_ID_PROXY_ADDR, { idProxyAddr }),
};

export const ssdRebuyEventActions = {
  updateStatus: (id, state, hash, ssdAction, arranger) =>
    action(UPDATE_REBUY_STATUS, { id, state, hash, ssdAction, arranger }),
};

export const ssdTerminationEventActions = {
  updateStatus: (
    arranger,
    ssdId,
    terminationHash,
    state,
    terminationAction,
    terminationInitiator,
    actionInitiator,
    terminationDate,
    terminationType
  ) =>
    action(UPDATE_TERMINATION_STATUS, {
      arranger,
      ssdId,
      terminationHash,
      state,
      terminationAction,
      terminationInitiator,
      actionInitiator,
      terminationDate,
      terminationType,
    }),
};

export const detailModalActions = {
  open: () => action(OPEN_DETAIL_MODAL, {}),
  close: () => action(CLOSE_DETAIL_MODAL, {}),
};

export const confirmModalActions = {
  open: () => action(OPEN_CONFIRM_MODAL, {}),
  close: () => action(CLOSE_CONFIRM_MODAL, {}),
};

export const editModalActions = {
  open: () => action(OPEN_EDIT_MODAL, {}),
  close: () => action(CLOSE_EDIT_MODAL, {}),
};

export const updatePreviewActions = {
  fetchPaymentPreview: (ssdStatus, modalActions, rebuyFingerprint) =>
    action(FETCH_PAYMENT_PREVIEW, {
      ssdStatus,
      modalActions,
      rebuyFingerprint,
    }),
  fetchPreview: (ssdStatus, modalActions) => action(FETCH_SSD_PREVIEW, { ssdStatus, modalActions }),
  updatePreviewInStore: (ssdDetails, rejectReason, ssdStatus) =>
    action(UPDATE_SSD_PREVIEW, { ssdDetails, rejectReason, ssdStatus }),
};

export const providerConnectionStatus = {
  connected: () => action(PROVIDER_CONNECTED, {}),
};

export const pdfActions = {
  download: (
    id,
    fingerprint,
    fingerprint_rebuy,
    arranger,
    withWatermark,
    created_by_cession,
    pdfName,
    companyIdProxyAddress,
    externUrkunde
  ) =>
    action(PDF_DOWNLOAD, {
      id,
      fingerprint,
      fingerprint_rebuy,
      arranger,
      withWatermark,
      created_by_cession,
      pdfName,
      companyIdProxyAddress,
      externUrkunde,
    }),
};

export const tradeConfirmationActions = {
  download: (id, fingerprint, fingerprint_rebuy, arranger) =>
    action(TRADE_CONFIRMATION_DOWNLOAD, {
      id,
      fingerprint,
      fingerprint_rebuy,
      arranger,
    }),
};

export const rebuyConfirmationActions = {
  download: (id, fingerprint, fingerprint_rebuy, arranger) =>
    action(REBUY_CONFIRMATION_DOWNLOAD, {
      id,
      fingerprint,
      fingerprint_rebuy,
      arranger,
    }),
};

export const terminationConfirmationActions = {
  download: (ssdInfo) => action(TERMINATION_CONFIRMATION_DOWNLOAD, { ssdInfo }),
};

export const rebuyModalActions = {
  open: () => action(OPEN_REBUY_MODAL, {}),
  close: () => action(CLOSE_REBUY_MODAL, {}),
};

export const rebuyPreviewModalActions = {
  open: () => action(OPEN_REBUY_PREVIEW_MODAL, {}),
  close: () => action(CLOSE_REBUY_PREVIEW_MODAL, {}),
};

export const signDocumentModalActions = {
  open: () => action(OPEN_SIGN_DOCUMENT_MODAL, {}),
  close: () => action(CLOSE_SIGN_DOCUMENT_MODAL, {}),
};

export const filterSsdInfosActions = {
  filter: (filter) => action(FILTER_SSD_INFOS, { filter }),
  reset: () => action(RESET_SSD_INFOS_FILTER, {}),
};

export const exportBssdActions = {
  doExport: (bssds) => action(EXPORT_BSSD_CSV, { bssds }),
  success: () => action(EXPORT_BSSD_CSV_SUCCESS),
  failure: () => action(EXPORT_BSSD_CSV_FAILURE),
};
