import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pdf_verification_button_text_zurueck: {
    id: "pdf.verification.bssd.button_text_zurueck",
    defaultMessage: "ZURÜCK",
  },
  valid: {
    id: "pdf.verification.bssd.case.valid",
    defaultMessage: `Das Dokument ist dem System bekannt`,
  },
  notValid: {
    id: "pdf.verification.bssd.case.notValid",
    defaultMessage: `Das Dokument ist dem System nicht bekannt.`,
  },
  a: {
    id: "pdf.verification.bssd.case.a",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Die Urkunde stimmt mit unseren Daten überein.
    Bitte beachten Sie, dass die bzw. Teile der Forderung abgetreten wurden. Die Abtretung ist in Ihrem Abtretungszertifikat festgehalten und kann ebenfalls über diese Dokumentenprüfung validiert werden.`,
  },
  b: {
    id: "pdf.verification.bssd.case.b",
    defaultMessage: `Dieser Teil der Forderung besteht nicht mehr.`,
  },
  c: {
    id: "pdf.verification.bssd.case.c",
    defaultMessage: `Dieser Teil der Forderung besteht nicht mehr.`,
  },
  d: {
    id: "pdf.verification.bssd.case.d",
    defaultMessage: `Die Forderung besteht nicht mehr.`,
  },
  e: {
    id: "pdf.verification.bssd.case.e",
    defaultMessage: `Die Forderung besteht nicht mehr.`,
  },
  f: {
    id: "pdf.verification.bssd.case.f",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Das Abtretungszertifikat stimmt mit unseren Daten überein. Die dazugehörige Urkunde kann ebenfalls über diese Dokumentenprüfung validiert werden.`,
  },
  g: {
    id: "pdf.verification.bssd.case.g",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Die Urkunde stimmt mit unseren Daten überein.`,
  },
  h: {
    id: "pdf.verification.bssd.case.h",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Die Urkunde stimmt mit unseren Daten überein.

    Das Geschäft wurde zum {terminationDate} gekündigt.`,
  },
  i: {
    id: "pdf.verification.bssd.case.i",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Das Abtretungszertifikat stimmt mit unseren Daten überein. Die dazugehörige Urkunde kann ebenfalls über diese Dokumentenprüfung validiert werden.
  
    Das Geschäft wurde zum {terminationDate} gekündigt.`,
  },
  j: {
    id: "pdf.verification.bssd.case.j",
    defaultMessage: `Aufgrund einer Abtretung ist dieses Abtretungszertifikat nicht mehr gültig.`,
  },
  k: {
    id: "pdf.verification.bssd.case.k",
    defaultMessage: `Die Endfälligkeit des Geschäfts ist erreicht. Die Forderung besteht nicht mehr.`,
  },
  l: {
    id: "pdf.verification.bssd.case.l",
    defaultMessage: `Bitte beachten Sie, dass Sie das Originaldokument hochladen müssen. Bei Änderungen oder gescannten Dokumenten ist eine Prüfung per Upload nicht möglich.`,
  },
  m: {
    id: "pdf.verification.bssd.case.m",
    defaultMessage: `Das hochgeladene Dokument wurde mit unseren Daten verglichen. Die Urkunde stimmt mit unseren Daten überein.`,
  },
});
