import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_recovery_wallet_wurde_als_uk_berechtigt: {
    id: "redux.recovery.wallet.wurde.als.uk.berechtigt",
    defaultMessage: "Wallet wurde als UK berechtigt.",
  },
  redux_recovery_recovery_fehlgeschlagen: {
    id: "redux.recovery.recovery.fehlgeschlagen",
    defaultMessage: "Recovery fehlgeschlagen.",
  },
  redux_recovery_einladung_wurde_versendet: {
    id: "redux.recovery.einladung.wurde.versendet",
    defaultMessage: "Einladung wurde versendet.",
  },
  redux_recovery_diese_email_wurde_bereits_erfolgreich_eingeladen: {
    id: "redux.recovery.diese.email.wurde_bereits_erfolgreich_eingeladen",
    defaultMessage: "Diese E-Mail wurde bereits erfolgreich eingeladen.",
  },
  redux_recovery_einladung_fehlgeschlagen: {
    id: "redux.recovery.einladung.fehlgeschlagen",
    defaultMessage: "Einladung fehlgeschlagen!",
  },
  redux_recovery_nutzer_wurde_zur_recovery_aufgefordert: {
    id: "redux.recovery.nutzer.wurde.zur.recovery.aufgefordert",
    defaultMessage: "Nutzer wurde zur Recovery aufgefordert.",
  },
});
