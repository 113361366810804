export default {
  bootstrap: require("./interfaces/Bootstrap.json"),
  platform: require("./interfaces/Platform.json"),
  ssdManager: require("./interfaces/SSDManager.json"),
  cssdManager: require("./interfaces/CSSDManager.json"),
  masterController: require("./interfaces/MasterController.json"),
  bssdController: require("./interfaces/BSSDController.json"),
  cssdController: require("./interfaces/CSSDController.json"),
  simplePrivilegeManager: require("./interfaces/SimplePrivilegeManager.json"),
  identityProxy: require("./interfaces/IdentityProxy.json"),
  IPrivilegeManager: require("./interfaces/IPrivilegeManager.json"),
  sharedLib: require("./interfaces/SharedLib.json"),
  CSSDControllerCSSDLib: require("./interfaces/CSSDControllerCSSDLib.json"),
  CSSDControllerInterestLib: require("./interfaces/CSSDControllerInterestLib.json"),
  CSSDControllerCessionLib: require("./interfaces/CSSDControllerCessionLib.json"),
  CSSDControllerTerminationLib: require("./interfaces/CSSDControllerTerminationLib.json"),
  NameRegistry: require("./interfaces/NameRegistry.json"),
  CSSDControllerPartialClaimLib: require("./interfaces/CSSDControllerPartialClaimLib.json"),
  simpleMemberController: require("./interfaces/SimpleMemberController.json"),
};
