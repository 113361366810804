import React from "react";

import { Button } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

import { LocaleContext } from "../../i18n/LocaleContext";

function ToggleLanguageButton(props) {
  const { width, isTextVisible } = props;
  const [locale, setLocale] = React.useContext(LocaleContext);

  const toggleLanguage = () => {
    if (locale === "de") {
      setLocale("en");
    } else {
      setLocale("de");
    }
  };

  return (
    <Button width={width} onClick={toggleLanguage}>
      {isTextVisible && (locale === "de" ? "English" : "Deutsch")}
      <LanguageIcon style={{ marginLeft: "0.3em" }} />
    </Button>
  );
}

export default ToggleLanguageButton;
