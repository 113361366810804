import { call, takeLatest, put, fork, all } from "redux-saga/effects";
import {
  INVITE_PLATFORM_MEMBER_SEND,
  INVITE_PLATFORM_MEMBER_AGAIN_SEND,
  GET_PLATFORM_MEMBER_INVITES_REQUEST,
  GET_PLATFORM_MEMBERS_REQUEST,
  GET_PLATFORM_MEMBER_PERMISSIONS_REQUEST,
  GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST,
  REVOKE_INVITE_PLATFORM_MEMBER_REQUEST,
  invitePlatformMemberActions,
  invitePlatformMemberAgainActions,
  getPlatformMemberInvitesActions,
  getPlatformMembersActions,
  getPlatformMemberDetailsActions,
  GET_PLATFORM_MEMBER_DETAILS_RETRY,
} from "./actions";
import {
  invite,
  getPlatformMembers,
  getPlatformMemberDetails,
  revokeInvite,
} from "services/platformMemberService";
import { snackbarActions } from "redux/shared/actions";
import { fetchMemberOnboardedEvents } from "services/web3Services/platformMembers";
import {
  platformPermissions,
  PRIVILEGE_PLATFORM_OFFER_SSD,
  PRIVILEGE_PLATFORM_ZAHLSTELLE,
  PRIVILEGE_PLATFORM_READ_ONLY,
} from "util/constants";
import {
  platformMemberPermissionActions,
  grantRevokePrivilegeActions,
  revokePlatformMemberInviteActions,
} from "./actions";
import {
  checkPlatformPrivilege,
  grantRevokePlatformPrivileges,
  revokeAllPrivelegesAndGrantReadOny,
} from "services/web3Services/privileges";
import { evictAllPermissionCache } from "services/permissionCacheService";
import { getIdProxyAddr } from "services/web3Services/commons";

import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { getPrivilegeGrantedForCompany } from "services/web3Services/commons";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const platformMemberSagas = [
  takeLatest(INVITE_PLATFORM_MEMBER_SEND, inviteService),
  takeLatest(INVITE_PLATFORM_MEMBER_AGAIN_SEND, inviteAgainService),
  takeLatest(GET_PLATFORM_MEMBER_INVITES_REQUEST, fetchPlatformMemberInvites),
  takeLatest(GET_PLATFORM_MEMBERS_REQUEST, fetchPlatformMembers),
  takeLatest(GET_PLATFORM_MEMBER_DETAILS_RETRY, retryFetch),
  takeLatest(GET_PLATFORM_MEMBER_PERMISSIONS_REQUEST, getPlatformPermissions),
  takeLatest(GRANT_REVOKE_PLATFORM_PRIVILEGES_REQUEST, updatePlatformPrivileges),
  takeLatest(REVOKE_INVITE_PLATFORM_MEMBER_REQUEST, revokePlatformMemberInvite),
];

export function* revokePlatformMemberInvite({ email, idProxyAddr }) {
  try {
    yield put(revokePlatformMemberInviteActions.success());
    yield call(revokeInvite, email, idProxyAddr);
    yield call(fetchPlatformMemberInvites, { idProxyAddr });
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_platform_member_einladung_wurde_zurueckgezogen)
      )
    );
  } catch (error) {
    yield put(revokePlatformMemberInviteActions.error());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_platform_member_einladung_konnte_nicht_zurueckgezogen_werden
        )
      )
    );
  }
}

export function getIdForPermission(key) {
  let index = platformPermissions.findIndex((x) => x.name === key);
  return platformPermissions[index].id;
}

function splitPermissionsObj(obj) {
  const privilegeIds = [];
  const granted = [];

  for (let key in obj) {
    privilegeIds.push(getIdForPermission(key));
    granted.push(obj[key]);
  }

  return {
    privilegeIds,
    granted,
  };
}

export function comparePermissions(newPermissions, currentPermissions) {
  const permission = { ...newPermissions };

  Object.keys(permission).forEach(function (key) {
    if (permission[key] === currentPermissions[key]) {
      delete permission[key];
    }
  });

  return permission;
}

export function* updatePlatformPrivileges({
  account,
  idProxyAddr,
  newPermissions,
  currentPermissions,
}) {
  const permissions = comparePermissions(newPermissions, currentPermissions);
  const changedPerm = splitPermissionsObj(permissions);
  try {
    if (permissions.PRIVILEGE_PLATFORM_READ_ONLY) {
      const { events, pmAddr } = yield call(getPrivilegeGrantedForCompany, idProxyAddr);
      let walletAddr = new Set();
      for (let e of events) {
        walletAddr.add(e.returnValues.a);
      }
      for (let addr of walletAddr) {
        yield call(revokeAllPrivelegesAndGrantReadOny, account, pmAddr, addr);
      }
    }
    if (changedPerm.privilegeIds.length > 0) {
      yield call(
        grantRevokePlatformPrivileges,
        account,
        idProxyAddr,
        changedPerm.privilegeIds,
        changedPerm.granted
      );
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_platform_member_berechtigungen_wurden_angepasst)
        )
      );
    }
    yield call(evictAllPermissionCache, getIdProxyAddr());
    yield put(grantRevokePrivilegeActions.success());
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_platform_member_fehler_beim_anpassen_der_berechtigungen
        )
      )
    );
    yield put(grantRevokePrivilegeActions.failure(error));
  }
}

export function getActiveFromPermissionsArray(arr, key) {
  let index = arr.findIndex((x) => Object.prototype.hasOwnProperty.call(x, key));
  return arr[index][key];
}

export function* getPlatformPermissions({ idProxyAddr }) {
  try {
    const result = yield all(
      platformPermissions.map((permission) => {
        return call(checkPlatformPrivilege, permission.id, idProxyAddr);
      })
    );
    const perm = {
      PRIVILEGE_PLATFORM_OFFER_SSD: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_PLATFORM_OFFER_SSD
      ),
      PRIVILEGE_PLATFORM_ZAHLSTELLE: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_PLATFORM_ZAHLSTELLE
      ),
      PRIVILEGE_PLATFORM_READ_ONLY: getActiveFromPermissionsArray(
        result,
        PRIVILEGE_PLATFORM_READ_ONLY
      ),
    };
    yield put(platformMemberPermissionActions.success(idProxyAddr, perm));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchPlatformMemberDetails(idProxyAddr) {
  try {
    const { response, error } = yield call(getPlatformMemberDetails, idProxyAddr);
    if (response) {
      yield put(getPlatformMemberDetailsActions.success(response.data));
    } else {
      throw error;
    }
  } catch (error) {
    yield put(getPlatformMemberDetailsActions.failure(idProxyAddr));
  }
}

export function* retryFetch({ loadErrors, idProxyAddr }) {
  if (idProxyAddr) {
    yield fetchPlatformMemberDetails(idProxyAddr);
  } else {
    yield all(
      Object.keys(loadErrors).map((idProxyAddress) =>
        fork(fetchPlatformMemberDetails, idProxyAddress)
      )
    );
  }
}

export function* fetchPlatformMembers() {
  try {
    const { data, error } = yield call(fetchMemberOnboardedEvents);
    if (data) {
      yield all(data.map((event) => fork(fetchPlatformMemberDetails, event.idProxyAddress)));
      yield put(getPlatformMembersActions.success(data));
    } else {
      throw error;
    }
  } catch (error) {
    console.error(error);
    yield put(getPlatformMembersActions.failure(error));
  }
}

export function* inviteService({ invitationData, idProxyAddr }) {
  try {
    const response = yield call(invite, invitationData, idProxyAddr);
    if (response.status >= 200 && response.status < 300) {
      yield put(invitePlatformMemberActions.success());
      yield call(fetchPlatformMemberInvites, { idProxyAddr });
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_platform_einladung_wurde_versendet)
        )
      );
    } else {
      throw response;
    }
  } catch (err) {
    if (err.response.status === 409)
      yield put(
        snackbarActions.open(
          appIntl().formatMessage(
            messages.redux_platform_diese_email_wurde_bereits_erfolgreich_eingeladen
          )
        )
      );
    else
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.redux_platform_einladung_fehlgeschlagen)
        )
      );
    yield put(invitePlatformMemberActions.failure(err));
  }
}

export function* inviteAgainService({ invitationData, idProxyAddr }) {
  try {
    const response = yield call(invite, invitationData, idProxyAddr);
    if (response.status >= 200 && response.status < 300) {
      yield put(invitePlatformMemberAgainActions.success());
      yield call(fetchPlatformMemberInvites, { idProxyAddr });
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_platform_einladung_wurde_erneut_versendet)
        )
      );
    } else {
      throw response;
    }
  } catch (err) {
    if (err.response.status === 409)
      yield put(
        snackbarActions.open(
          appIntl().formatMessage(
            messages.redux_platform_diese_email_wurde_bereits_erfolgreich_eingeladen
          )
        )
      );
    else
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(messages.redux_platform_einladung_fehlgeschlagen)
        )
      );
    yield put(invitePlatformMemberAgainActions.failure(err));
  }
}

export function* fetchPlatformMemberInvites({ idProxyAddr }) {
  try {
    const platformMembers = yield call(getPlatformMembers, idProxyAddr);
    const sorted = platformMembers.data.sort(comparePlatformMembers);
    yield put(getPlatformMemberInvitesActions.success(sorted));
  } catch (err) {
    yield put(getPlatformMemberInvitesActions.failure(err));
  }
}

function comparePlatformMembers(a, b) {
  if (a.nachname < b.nachname) return -1;
  if (a.nachname > b.nachname) return 1;
  return 0;
}
