import { sendSignedHttpRequest } from "../util/requestSigning";
import { getCompanyDataUpdateEvent } from "services/web3Services/commons";
import { getPublicPlatformMemberInfo } from "services/businessPartnerService";
import { checkPlatformPrivilege } from "./web3Services/privileges";
import { PRIVILEGE_PLATFORM_READ_ONLY } from "util/constants";
export let partnerCache = [];

export async function getAllPartnerInfo(idProxyAddr) {
  partnerCache = [];
  return sendSignedHttpRequest(
    "get",
    `/digits-server/${idProxyAddr}/geschaeftspartner/all/accepted`,
    idProxyAddr
  )
    .then(async function (response) {
      if (response.status >= 200 && response.status < 300) {
        for (let partner of response.data) {
          const res = await checkPlatformPrivilege(
            PRIVILEGE_PLATFORM_READ_ONLY,
            partner.idProxyAddress
          );
          partnerCache.push({ ...partner, readOnly: res[PRIVILEGE_PLATFORM_READ_ONLY] });
        }
        checkLatestGpInfo(idProxyAddr, partnerCache);
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function getGpFromPartners(idProxyAddr) {
  let result = partnerCache.find((partner) => partner.idProxyAddress === idProxyAddr);
  if (!result) {
    return getPublicPlatformMemberInfo(idProxyAddr).then(response => {
      if (response.status >= 200 && response.status < 300) {
        updatePartnerCache(partnerCache, response.data);
        return response.data;  
      } else {
        return null;
      }
    });
  } else {
    return (new Promise((resolve,reject) => {resolve(true);}))
      .then( () => result);
  }
}

export function getNameForAddr(addr, companyInfo) {
  let name = addr;
  partnerCache.forEach((member) => {
    if (member.idProxyAddress?.toUpperCase() === addr?.toUpperCase()) {
      name = `${member.name} / ${member.digitsKennung}`;
    }
  });

  if (name === addr) {
    name =
      addr === companyInfo.idProxyAddress
        ? `${companyInfo.name} / ${companyInfo.digitsKennung}`
        : addr;
  }

  return name;
}

export function updatePartnerInfo(idProxyAddr, info) {
  const data = {
    ...info,
    firmenname: info.name,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/${idProxyAddr}/geschaeftspartner/update`,
    idProxyAddr,
    data
  );
}

export function updatePartnerCache(oldCache, update) {
  const newCache = [...oldCache].map((entry) =>
    entry.idProxyAddress === update.idProxyAddress ? { ...entry, ...update } : entry
  );
  partnerCache = newCache;
}

function checkLatestGpInfo(idProxyAddr, partnerArr) {
  try {
    partnerArr.forEach(async (partner) => {
      const events = await getCompanyDataUpdateEvent(partner.idProxyAddress);
      const lastEvent = events[events.length - 1];
      if (lastEvent) {
        const { fingerprint } = partner;
        const { hash, companyAddress } = lastEvent.returnValues;
        if (fingerprint !== hash) {
          const { data } = await getPublicPlatformMemberInfo(companyAddress);
          await updatePartnerInfo(idProxyAddr, data).catch((e) => {
            if (e.response.status !== 409) {
              //409 => already stored with the same fingerprint
              console.error(e);
            }
          });
          updatePartnerCache(partnerCache, data);
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
}
