import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_audit_abtretung_erfasst: {
    id: "redux.audit.abtretung.erfasst",
    defaultMessage: "Abtretung erfasst",
  },
  redux_audit_abtretung_bearbeitet: {
    id: "redux.audit.abtretung.bearbeitet",
    defaultMessage: "Abtretung bearbeitet",
  },
  redux_audit_abtretung_bestaetigt: {
    id: "redux.audit.abtretung.bestaetigt",
    defaultMessage: "Abtretung bestätigt",
  },
  redux_audit_abtretung_gegenbestaetigt: {
    id: "redux.audit.abtretung.gegenbestaetigt",
    defaultMessage: "Abtretung gegenbestätigt",
  },
  redux_audit_abtretung_abgelehnt: {
    id: "redux.audit.abtretung.abgelehnt",
    defaultMessage: "Abtretung abgelehnt",
  },
  redux_audit_abtretung_abgebrochen: {
    id: "redux.audit.abtretung.abgebrochen",
    defaultMessage: "Abtretung abgebrochen",
  },
  redux_audit_abtretung_storniert: {
    id: "redux.audit.abtretung.storniert",
    defaultMessage: "Abtretung storniert",
  },
  redux_audit_abtretung_angezeigt: {
    id: "redux.audit.abtretung.angezeigt",
    defaultMessage: "Abtretung angezeigt",
  },
  redux_audit_abtretung_abgeschlossen: {
    id: "redux.audit.abtretung.abgeschlossen",
    defaultMessage: "Abtretung abgeschlossen",
  },
  redux_audit_abtretung_erste_bestaegigung: {
    id: "redux.audit.abtretung.erste.bestaegigung",
    defaultMessage: "Abtretung 1. Bestätigung",
  },
  redux_audit_abtretung_zweite_bestaegigung: {
    id: "redux.audit.abtretung.zweite.bestaegigung",
    defaultMessage: "Abtretung 2. Bestätigung",
  },
  redux_audit_abtretung_bestaetigung_zurueckgezogen: {
    id: "redux.audit.abtretung.bestaetigung.zurueckgezogen",
    defaultMessage: "Abtretung Bestätigung zurückgezogen",
  },
  redux_audit_abtretung_erste_gegenbestaegigung: {
    id: "redux.audit.abtretung.erste.gegenbestaegigung",
    defaultMessage: "Abtretung 1. Gegenbestätigung",
  },
  redux_audit_abtretung_zweite_gegenbestaegigung: {
    id: "redux.audit.abtretung.zweite.gegenbestaegigung",
    defaultMessage: "Abtretung 2. Gegenbestätigung",
  },
  redux_audit_abtretung_gegenbestaetigung_zurueckgezogen: {
    id: "redux.audit.abtretung.gegenbestaetigung.zurueckgezogen",
    defaultMessage: "Abtretung Gegenbestätigung zurückgezogen",
  },
  redux_audit_abtretung_aufgrund_einer_kuendigung_abgebrochen: {
    id: "redux.audit.abtretung.aufgrund.einer.kuendigung.abgebrochen",
    defaultMessage: "Abtretung aufgrund einer Kündigung abgebrochen",
  },
  redux_audit_zinsmitteilung_erfasst: {
    id: "redux.audit.zinsmitteilung.erfasst",
    defaultMessage: "Zinsmitteilung erfasst",
  },
  redux_audit_zinsmitteilung_bestaetigt: {
    id: "redux.audit.zinsmitteilung.bestaetigt",
    defaultMessage: "Zinsmitteilung bestätigt",
  },
  redux_audit_zinsmitteilung_abgelehnt: {
    id: "redux.audit.zinsmitteilung.abgelehnt",
    defaultMessage: "Zinsmitteilung abgelehnt",
  },
  redux_audit_zinsmitteilung_storniert: {
    id: "redux.audit.zinsmitteilung.storniert",
    defaultMessage: "Zinsmitteilung storniert",
  },
  redux_audit_zinsmitteilung_ungueltig_gemacht: {
    id: "redux.audit.zinsmitteilung.ungueltig.gemacht",
    defaultMessage: "Zinsmitteilung ungültig gemacht",
  },
  redux_audit_zinsmitteilung_bearbeitet: {
    id: "redux.audit.zinsmitteilung.bearbeitet",
    defaultMessage: "Zinsmitteilung bearbeitet",
  },
  redux_audit_zinsmitteilung_erste_bestaetigung: {
    id: "redux.audit.zinsmitteilung.erste.bestaetigung",
    defaultMessage: "Zinsmitteilung 1. Bestätigung",
  },
  redux_audit_zinsmitteilung_zweite_bestaetigung: {
    id: "redux.audit.zinsmitteilung.zweite.bestaetigung",
    defaultMessage: "Zinsmitteilung 2. Bestätigung",
  },
  redux_audit_zinsmitteilung_bestaetigung_zurueckgezogen: {
    id: "redux.audit.zinsmitteilung.bestaetigung.zurueckgezogen",
    defaultMessage: "Zinsmitteilung Bestätigung zurückgezogen",
  },
  redux_audit_nicht_erfasst: {
    id: "redux.audit.nicht.erfasst",
    defaultMessage: "nicht erfasst",
  },
  redux_audit_kuendigung_erfasst: {
    id: "redux.audit.kuendigung.erfasst",
    defaultMessage: "Kündigung erfasst",
  },
  redux_audit_kuendigung_storniert: {
    id: "redux.audit.kuendigung.storniert",
    defaultMessage: "Kündigung storniert",
  },
  redux_audit_kuendigung_abgebrochen: {
    id: "redux.audit.kuendigung.abgebrochen",
    defaultMessage: "Kündigung abgebrochen",
  },
  redux_audit_kuendigung_bestaetigt: {
    id: "redux.audit.kuendigung.bestaetigt",
    defaultMessage: "Kündigung bestätigt",
  },
  redux_audit_erfasst: {
    id: "redux.audit.erfasst",
    defaultMessage: "erfasst",
  },
  redux_audit_bearbeitet: {
    id: "redux.audit.bearbeitet",
    defaultMessage: "bearbeitet",
  },
  redux_audit_zweitbestaetigt: {
    id: "redux.audit.zweitbestaetigt",
    defaultMessage: "zweitbestätigt",
  },
  redux_audit_zweitgegenbestaetigt: {
    id: "redux.audit.zweitgegenbestaetigt",
    defaultMessage: "zweitgegenbestätigt",
  },
  redux_audit_abgelehnt: {
    id: "redux.audit.abgelehnt",
    defaultMessage: "abgelehnt",
  },
  redux_audit_storniert: {
    id: "redux.audit.storniert",
    defaultMessage: "storniert",
  },
  redux_audit_urkunde_ausgestellt: {
    id: "redux.audit.urkunde.ausgestellt",
    defaultMessage: "Urkunde ausgestellt",
  },
  redux_audit_abtretungszertifikat_ausgestellt: {
    id: "redux.audit.abtretungszertifikat.ausgestellt",
    defaultMessage: "Abtretungszertifikat ausgestellt",
  },
  redux_audit_zahlung_erhalten: {
    id: "redux.audit.zahlung.erhalten",
    defaultMessage: "Zahlung erhalten",
  },
  redux_audit_zahlung_ausstehend: {
    id: "redux.audit.zahlung.ausstehend",
    defaultMessage: "Zahlung ausstehend",
  },
  redux_audit_zahlung_nicht_erhalten: {
    id: "redux.audit.zahlung.nicht.erhalten",
    defaultMessage: "Zahlung nicht erhalten",
  },
  redux_audit_rueckkauf_erfasst: {
    id: "redux.audit.rueckkauf.erfasst",
    defaultMessage: "Rückkauf erfasst",
  },
  redux_audit_rueckkauf_bearbeitet: {
    id: "redux.audit.rueckkauf.bearbeitet",
    defaultMessage: "Rückkauf bearbeitet",
  },
  redux_audit_rueckkauf_zweitbestaetigt: {
    id: "redux.audit.rueckkauf.zweitbestaetigt",
    defaultMessage: "Rückkauf zweitbestätigt",
  },
  redux_audit_rueckkauf_zweitgegenbestaetigt: {
    id: "redux.audit.rueckkauf.zweitgegenbestaetigt",
    defaultMessage: "Rückkauf zweitgegenbestätigt",
  },
  redux_audit_rueckkauf_abgelehnt: {
    id: "redux.audit.rueckkauf.abgelehnt",
    defaultMessage: "Rückkauf abgelehnt",
  },
  redux_audit_rueckkauf_storniert: {
    id: "redux.audit.rueckkauf.storniert",
    defaultMessage: "Rückkauf storniert",
  },
  redux_audit_rueckkauf_abgebrochen: {
    id: "redux.audit.rueckkauf.abgebrochen",
    defaultMessage: "Rückkauf abgebrochen",
  },
  redux_audit_rueckzahlung_erhalten: {
    id: "redux.audit.rueckzahlung.erhalten",
    defaultMessage: "Rückzahlung erhalten",
  },
  redux_audit_abgetreten: {
    id: "redux.audit.abgetreten",
    defaultMessage: "abgetreten",
  },
  redux_audit_kuendigung_erste_bestaetigung: {
    id: "redux.audit.kuendigung.erste.bestaetigung",
    defaultMessage: "Kündigung 1. Bestätigung",
  },
  redux_audit_kuendigung_zweite_bestaetigung: {
    id: "redux.audit.kuendigung.zweite.bestaetigung",
    defaultMessage: "Kündigung 2. Bestätigung",
  },
  redux_audit_erstbestaetigt: {
    id: "redux.audit.erstbestaetigt",
    defaultMessage: "erstbestätigt",
  },
  redux_audit_erstbestaetigung_zurueckgezogen: {
    id: "redux.audit.erstbestaetigung.zurueckgezogen",
    defaultMessage: "Erstbestätigung zurückgezogen",
  },
  redux_audit_erstbestaetigung_automatisch_zurueckgezogen: {
    id: "redux.audit.erstbestaetigung.automatisch.zurueckgezogen",
    defaultMessage: "Erstbestätigung automatisch zurückgezogen",
  },
  redux_audit_erstgegenbestaetigt: {
    id: "redux.audit.erstgegenbestaetigt",
    defaultMessage: "erstgegenbestätigt",
  },
  redux_audit_erstgegenbestaetigung_zurueckgezogen: {
    id: "redux.audit.erstgegenbestaetigung.zurueckgezogen",
    defaultMessage: "Erstgegenbestätigung zurückgezogen",
  },
  redux_audit_erstgegenbestaetigung_automatisch_zurueckgezogen: {
    id: "redux.audit.erstgegenbestaetigung.automatisch.zurueckgezogen",
    defaultMessage: "Erstgegenbestätigung automatisch zurückgezogen",
  },
  redux_audit_rueckkauf_erstbestaetigt: {
    id: "redux.audit.rueckkauf.erstbestaetigt",
    defaultMessage: "Rückkauf erstbestätigt",
  },
  redux_audit_rueckkauf_erstbestaetigung_zurueckgezogen: {
    id: "redux.audit.rueckkauf.erstbestaetigung.zurueckgezogen",
    defaultMessage: "Rückkauf Erstbestätigung zurückgezogen",
  },
  redux_audit_rueckkauf_erstbestaetigung_automatisch_zurueckgezogen: {
    id: "redux.audit.rueckkauf.erstbestaetigung.automatisch.zurueckgezogen",
    defaultMessage: "Rückkauf Erstbestätigung automatisch zurückgezogen",
  },
  redux_audit_rueckkauf_erstgegenbestaetigt: {
    id: "redux.audit.rueckkauf.erstgegenbestaetigt",
    defaultMessage: "Rückkauf erstgegenbestätigt",
  },
  redux_audit_rueckkauf_erstgegenbestaetigung_zurueckgezogen: {
    id: "redux.audit.rueckkauf.erstgegenbestaetigung.zurueckgezogen",
    defaultMessage: "Rückkauf Erstgegenbestätigung zurückgezogen",
  },
  redux_audit_rueckkauf_erstgegenbestaetigung_automatisch_zurueckgezogen: {
    id: "redux.audit.rueckkauf.erstgegenbestaetigung.automatisch.zurueckgezogen",
    defaultMessage: "Rückkauf Erstgegenbestätigung automatisch zurückgezogen",
  },
  redux_audit_kuendigung_aufgrund_einer_abtretung_abgebrochen: {
    id: "redux.audit.kuendigung.aufgrund.einer.abtretung.abgebrochen",
    defaultMessage: "Kündigung aufgrund einer parallel erfassten Abtretung abgebrochen",
  },
});
