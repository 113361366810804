export const MODIFY_SSD_REBUY_REQUEST = "MODIFY_SSD_REBUY_REQUEST";
export const MODIFY_SSD_REBUY_SUCCESS = "MODIFY_SSD_REBUY_SUCCESS";
export const MODIFY_SSD_REBUY_FAILURE = "MODIFY_SSD_REBUY_FAILURE";

export const MODIYY_AND_OFFER_SSD_REBUY_REQUEST = "MODIYY_AND_OFFER_SSD_REBUY_REQUEST";
export const MODIYY_AND_OFFER_SSD_REBUY_SUCCESS = "MODIYY_AND_OFFER_SSD_REBUY_SUCCESS";
export const MODIYY_AND_OFFER_SSD_REBUY_FAILURE = "MODIYY_AND_OFFER_SSD_REBUY_FAILURE";

export const CANCEL_SSD_REBUY_REQUEST = "CANCEL_SSD_REBUY_REQUEST";
export const CANCEL_SSD_REBUY_SUCCESS = "CANCEL_SSD_REBUY_SUCCESS";
export const CANCEL_SSD_REBUY_FAILURE = "CANCEL_SSD_REBUY_FAILURE";

export const OFFER_SSD_REBUY_REQUEST = "OFFER_SSD_REBUY_REQUEST";
export const OFFER_SSD_REBUY_SUCCESS = "OFFER_SSD_REBUY_SUCCESS";
export const OFFER_SSD_REBUY_FAILURE = "OFFER_SSD_REBUY_FAILURE";

export const CANCEL_OFFER_SSD_REBUY_REQUEST = "CANCEL_OFFER_SSD_REBUY_REQUEST";
export const CANCEL_OFFER_SSD_REBUY_SUCCESS = "CANCEL_OFFER_SSD_REBUY_SUCCESS";
export const CANCEL_OFFER_SSD_REBUY_FAILURE = "CANCEL_OFFER_SSD_REBUY_FAILURE";

export const ACCEPT_SSD_REBUY_REQUEST = "ACCEPT_SSD_REBUY_REQUEST";
export const ACCEPT_SSD_REBUY_SUCCESS = "ACCEPT_SSD_REBUY_SUCCESS";
export const ACCEPT_SSD_REBUY_FAILURE = "ACCEPT_SSD_REBUY_FAILURE";

export const CANCEL_ACCEPT_SSD_REBUY_REQUEST = "CANCEL_ACCEPT_SSD_REBUY_REQUEST";
export const CANCEL_ACCEPT_SSD_REBUY_SUCCESS = "CANCEL_ACCEPT_SSD_REBUY_SUCCESS";
export const CANCEL_ACCEPT_SSD_REBUY_FAILURE = "CANCEL_ACCEPT_SSD_REBUY_FAILURE";

export const REJECT_SSD_REBUY_REQUEST = "REJECT_SSD_REBUY_REQUEST";
export const REJECT_SSD_REBUY_SUCCESS = "REJECT_SSD_REBUY_SUCCESS";
export const REJECT_SSD_REBUY_FAILURE = "REJECT_SSD_REBUY_FAILURE";

export const PAYMENT_RECEIVED_REQUEST = "PAYMENT_RECEIVED_REQUEST";
export const PAYMENT_RECEIVED_SUCCESS = "PAYMENT_RECEIVED_SUCCESS";
export const PAYMENT_RECEIVED_FAILURE = "PAYMENT_RECEIVED_FAILURE";

export const PAYMENT_CORRECTION_REQUEST = "PAYMENT_CORRECTION_REQUEST";
export const PAYMENT_CORRECTION_SUCCESS = "PAYMENT_CORRECTION_SUCCESS";
export const PAYMENT_CORRECTION_FAILURE = "PAYMENT_CORRECTION_FAILURE";

export const REPAYMENT_RECEIVED_REQUEST = "REPAYMENT_RECEIVED_REQUEST";
export const REPAYMENT_RECEIVED_SUCCESS = "REPAYMENT_RECEIVED_SUCCESS";
export const REPAYMENT_RECEIVED_FAILURE = "REPAYMENT_RECEIVED_FAILURE";

export const OPEN_CONFIRM_REPAYMENT_DIALOG = "OPEN_CONFIRM_REPAYMENT_DIALOG";
export const CLOSE_CONFIRM_REPAYMENT_DIALOG = "CLOSE_CONFIRM_REPAYMENT_DIALOG";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const confirmRepaymentDialogActions = {
  open: (ssdInfos) => action(OPEN_CONFIRM_REPAYMENT_DIALOG, { ssdInfos }),
  close: () => action(CLOSE_CONFIRM_REPAYMENT_DIALOG),
};

export const modifySsdRebuyActions = {
  request: (account, ssdData, old_fingerprint_rebuy, rebuyData) =>
    action(MODIFY_SSD_REBUY_REQUEST, {
      account,
      ssdData,
      old_fingerprint_rebuy,
      rebuyData,
    }),
  success: () => action(MODIFY_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(MODIFY_SSD_REBUY_FAILURE, { error }),
};

export const modifyAndOfferSsdRebuyActions = {
  request: (account, ssdData, old_fingerprint_rebuy, rebuyData) =>
    action(MODIYY_AND_OFFER_SSD_REBUY_REQUEST, {
      account,
      ssdData,
      old_fingerprint_rebuy,
      rebuyData,
    }),
  success: () => action(MODIYY_AND_OFFER_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(MODIYY_AND_OFFER_SSD_REBUY_FAILURE, { error }),
};

export const cancelSsdRebuyActions = {
  request: (account, ssdData, reason) =>
    action(CANCEL_SSD_REBUY_REQUEST, { account, ssdData, reason }),
  success: () => action(CANCEL_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(CANCEL_SSD_REBUY_FAILURE, { error }),
};

export const offerSsdRebuyActions = {
  request: (account, ssdData, isSecondConfirm) =>
    action(OFFER_SSD_REBUY_REQUEST, { account, ssdData, isSecondConfirm }),
  success: () => action(OFFER_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(OFFER_SSD_REBUY_FAILURE, { error }),
};

export const cancelOfferSsdRebuyActions = {
  request: (account, ssdData) => action(CANCEL_OFFER_SSD_REBUY_REQUEST, { account, ssdData }),
  success: () => action(CANCEL_OFFER_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(CANCEL_OFFER_SSD_REBUY_FAILURE, { error }),
};

export const acceptSsdRebuyActions = {
  request: (account, ssdData, fourEyesFlag) => action(ACCEPT_SSD_REBUY_REQUEST, { account, ssdData, fourEyesFlag }),
  success: () => action(ACCEPT_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(ACCEPT_SSD_REBUY_FAILURE, { error }),
};

export const cancelAcceptSsdRebuyActions = {
  request: (account, ssdData) => action(CANCEL_ACCEPT_SSD_REBUY_REQUEST, { account, ssdData }),
  success: () => action(CANCEL_ACCEPT_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(CANCEL_ACCEPT_SSD_REBUY_FAILURE, { error }),
};

export const rejectSsdRebuyActions = {
  request: (account, ssdData, reason, rebuyFingerprint) =>
    action(REJECT_SSD_REBUY_REQUEST, {
      account,
      ssdData,
      reason,
      rebuyFingerprint,
    }),
  success: () => action(REJECT_SSD_REBUY_SUCCESS, {}),
  failure: (error) => action(REJECT_SSD_REBUY_FAILURE, { error }),
};

export const paymentCorrectionActions = {
  request: (account, ssdData, reason) =>
    action(PAYMENT_CORRECTION_REQUEST, { account, ssdData, reason }),
  success: () => action(PAYMENT_CORRECTION_SUCCESS, {}),
  failure: (error) => action(PAYMENT_CORRECTION_FAILURE, { error }),
};

export const paymentReceivedActions = {
  request: (account, ssdData) => action(PAYMENT_RECEIVED_REQUEST, { account, ssdData }),
  success: () => action(PAYMENT_RECEIVED_SUCCESS, {}),
  failure: (error) => action(PAYMENT_RECEIVED_FAILURE, { error }),
};

export const repaymentReceivedActions = {
  request: (account, ssdData) => action(REPAYMENT_RECEIVED_REQUEST, { account, ssdData }),
  success: () => action(REPAYMENT_RECEIVED_SUCCESS, {}),
  failure: (error) => action(REPAYMENT_RECEIVED_FAILURE, { error }),
};
