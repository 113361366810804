import { contractCache } from "services/smartContracts/smartContracts";

import {
  getCompanyIPrivelegeManagerMethods,
  sendTransaction,
  getMasterControllerContractMethods,
} from "./commons";

//
// PRIVILEGES
//

export async function checkPrivilege(privilegeId, walletAddress) {
  const active = await contractCache.companyIPmContract.methods
    .checkPrivilegeGranted(privilegeId, walletAddress)
    .call();
  return { [privilegeId]: active };
}

export async function checkPlatformPrivilege(privilegeId, identityProxy) {
  const active = await contractCache.privilegeManagerContract.methods
    .checkPrivilegeGranted(privilegeId, identityProxy)
    .call();
  return { [privilegeId]: active };
}

export async function grantRevokePrivileges(account, privilegeIds, granted, walletAddress) {
  const contractMethods = getCompanyIPrivelegeManagerMethods();
  const data = contractMethods
    .grantRevokePrivileges(privilegeIds, granted, walletAddress)
    .encodeABI();
  return await sendTransaction(account, contractCache.companyIPmContract._address, data);
}

export async function grantRevokePlatformPrivileges(
  account,
  identityProxyAddr,
  privilegeIds,
  granted
) {
  const contractMethods = getMasterControllerContractMethods();
  const data = contractMethods
    .grantRevokePrivileges(privilegeIds, granted, identityProxyAddr)
    .encodeABI();
  return await sendTransaction(account, contractCache.masterController._address, data);
}

export async function revokeAllPrivelegesAndGrantReadOny(
  account,
  companyPrivilegeManager,
  maAddress
) {
  const data = contractCache.masterController.methods
    .revokeAllPrivelegesAndGrantReadOny(companyPrivilegeManager, maAddress)
    .encodeABI();
  return await sendTransaction(account, contractCache.masterController._address, data);
}
