import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { messages } from "../messages";
import { signin_styles } from "../signin_styles";
import { connect } from "react-redux";
import { createWalletAction, signInActions } from "redux/signIn/actions";
import WalletInformation from "./WalletInformation";

const WalletRegistration = ({
  createWalletPending,
  onCreateWalletRequest,
  generatedWalletEncrypted,
  onBoardingToken,
  isPlatformMemberInvited,
  onSignInRequest,
  showWalletInformation,
  classes,
}) => {
  const [walletPassword, setWalletPassword] = useState("");
  const [walletPasswordCompare, setWalletPasswordCompare] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(true);

  const onWalletPasswordChange = (event) => {
    setWalletPassword(event.target.value);
  };

  const onWalletPasswordCompareChange = (event) => {
    setWalletPasswordCompare(event.target.value);
  };

  const validatePasswordFormat = (event) => {
    const password = event.target.value;

    const isLengthValid = password.length >= 10;
    const containsNumber = /\d/.test(password);
    const containsSpecialCharacter = /[()!@#$%^&*_-]/.test(password);
    const containsCapitalLetter = /[A-ZÄÖÜ]/.test(password);
    const containsSmallLetter = /[a-zäöü]/.test(password);

    setPasswordValid(
      isLengthValid &&
        containsNumber &&
        containsSpecialCharacter &&
        containsCapitalLetter &&
        containsSmallLetter
    );
  };

  const doPasswordsMatch = () => {
    return walletPassword === walletPasswordCompare && walletPassword !== "";
  };

  const handleCreateWalletClick = (event) => {
    event.preventDefault();

    if (doPasswordsMatch && isPasswordValid) {
      onCreateWalletRequest(walletPassword);
    }
  };

  const handleNextClick = () => {
    if (generatedWalletEncrypted && walletPassword) {
      onSignInRequest(
        {
          password: walletPassword,
          wallet: generatedWalletEncrypted,
        },
        onBoardingToken,
        isPlatformMemberInvited
      );
    }
  };

  return showWalletInformation ? (
    <WalletInformation handleNextClick={handleNextClick} />
  ) : (
    <form className={classes.formWithPassword}>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="password">
          <FormattedMessage {...messages.passwordLabel} />
        </InputLabel>
        <Input
          name="password"
          type="password"
          id="passwordWallet"
          error={!isPasswordValid}
          autoComplete="current-password"
          onChange={onWalletPasswordChange}
          onBlur={validatePasswordFormat}
        />
        {!isPasswordValid && (
          <FormHelperText error={!isPasswordValid}>
            <FormattedMessage {...messages.passwordHelper} />
          </FormHelperText>
        )}
      </FormControl>
      <FormControl margin="dense" required fullWidth>
        <InputLabel htmlFor="passwordCompare">
          <FormattedMessage {...messages.repeatPasswordLabel} />
        </InputLabel>
        <Input
          name="passwordCompare"
          type="password"
          id="passwordWalletCompare"
          onChange={onWalletPasswordCompareChange}
        />
      </FormControl>
      <div className={classes.wrapper}>
        <Button
          type="submit"
          fullWidth
          variant="text"
          color="primary"
          disabled={createWalletPending || !doPasswordsMatch() || !isPasswordValid}
          onClick={handleCreateWalletClick}
        >
          <FormattedMessage {...messages.createWalletButton} />
        </Button>
        {createWalletPending && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    createWalletPending: state.signInReducer.createWalletPending,
    generatedWalletEncrypted: state.signInReducer.generatedWalletEncrypted,
    onBoardingToken: state.signInReducer.onBoardingToken,
    isPlatformMemberInvited: state.signInReducer.isPlatformMemberInvited,
    showWalletInformation: state.signInReducer.showWalletInformation,
  };
};

const mapDispatchToProps = {
  onCreateWalletRequest: createWalletAction.request,
  onSignInRequest: signInActions.request,
};

export default withStyles(signin_styles)(
  connect(mapStateToProps, mapDispatchToProps)(WalletRegistration)
);
