const { defineMessages } = require("react-intl");

export const messages = defineMessages({
  title: {
    id: "warnung.ungültigeDaten.titel",
    defaultMessage: "Achtung!",
  },
  body: {
    id: "warnung.ungültigeDaten.hinweistext",
    defaultMessage: "Die Integrität der Daten konnte nicht bestätigt werden!",
  },
  continue: {
    id: "warnung.ungültigeDaten.fortsetzen",
    defaultMessage: "Trotzdem fortfahren",
  },
  abort: {
    id: "warnung.ungültigeDaten.abbrechen",
    defaultMessage: "Abbrechen",
  },
});
