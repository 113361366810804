import React, { Fragment } from "react";
import { addDotsComma, getTrueFalse, removeDots } from "util/convertor";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { messages as messagesBssdForm } from "../BssdForm/messages";

export default function FormatField(props) {
  const formatNumber = (value, decimalplaces) =>
    addDotsComma(Number(removeDots(value)).toFixed(decimalplaces));

  const formatKurs = (value) => {
    const numberValue = Number(removeDots(value));
    if (numberValue % 1 === 0 || numberValue.toString().split(".")[1].length === 1) {
      return addDotsComma(Number(removeDots(value)).toFixed(2));
    }

    return addDotsComma(numberValue);
  };

  const formatKuendigungsTermine = (value) => {
    const t = JSON.parse(value.replace(/\\/g, ""));
    return (
      <Table style={{ width: "250px" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <>
                {`${appIntl().formatMessage(
                  messages.shared_format_field_table_cell_text_kuendigungstermin
                )} / ${appIntl().formatMessage(
                  messages.shared_format_field_table_cell_text_valuta
                )} /`}
                <br />
                {`${appIntl().formatMessage(
                  messages.shared_format_field_table_cell_text_kurs_in_prozent
                )}`}
              </>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {t.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{`${row.kuendigungstermin} / ${row.kuendigungsvaluta} /
           ${row.kuendigungskurs}`}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const formatField = (key, value) => {
    if (!value && value !== false) {
      return " -/- ";
    }
    if (Array.isArray(value)) return value.join(", ");

    switch (key) {
      case "zinstermin_monat":
      case "geschaeftstagekalender":
        return value.replace(/,/g, ", ");
      case "nominal":
      case "kaufpreis":
      case "mindestbetrag_bei_abtretung":
      case "rueckzahlungsbetrag":
      case "zinsbetrag":
      case "zinsBetrag":
      case "stueckzinsen":
      case "abgetretenes_nominal":
      case "gegenwert_abtretung":
        return formatNumber(value, 2);
      case "kurs":
      case "kuendigungskurs":
      case "zinssatz":
      case "minimumzinssatz":
      case "maximumzinssatz":
      case "aufschlag":
      case "abschlag":
      case "faktor":
      case "rueckzahlung":
      case "referenzZinssatz":
      case "zinsSatz":
      case "abtretungskurs":
        return formatKurs(value);
      case "kuendigungTermine":
        return formatKuendigungsTermine(value);
      case "finledger_urkunde_verwenden":
        return getTrueFalse(value);
      case "zins_fix":
        return formatZinsFix(value);
      case "zinszahlungsrhythmus":
        return formatZinszahlungsrhythmus(value);
      case "kuendigungsrecht":
        return formatKuendigungsrecht(value);
      case "abtretbarkeit":
        return formatAbtretbarkeit(value);
      case "auslandsfaehigkeit":
        return formatAuslandsfaehigkeit(value);
      case "bildschirmseite":
        return formatBildschirmseite(value);
      case "tlac":
      case "vag_konform":
      case "sag_49":
      case "mrel_72b_crr":
        return formatJaNein(value);
      case "faelligkeitstag_der_zinszahlung":
        return formatFaelligkeitstagDerZinszahlung(value);
      case "berechnungszeitraum_der_zinszahlung":
        return formatBerechnungszeitraumDerZinszahlung(value);
      default:
        return value;
    }
  };

  const { keyName, value } = props;
  const t = formatField(keyName, value);
  return <Fragment>{t}</Fragment>;
}

export const formatBildschirmseite = (value) => {
  switch (value) {
    case "REUTERS Seite EURIBOR01":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_reuters_seite_euribor01
      );
    case "REUTERS Seite LIBOR01":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_reuters_seite_libibor01
      );
    case "REUTERS Seite EURSFIXA=":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_reuters_seite_eursfixa
      );
    default:
      return "";
  }
};

export const formatZinszahlungsrhythmus = (value) => {
  switch (value) {
    case "jährlich":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_jaehrlich
      );
    case "halbjährlich":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_halbjaehrlich
      );
    case "vierteljährlich":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_vierteljaehrlich
      );
    case "monatlich":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_monatlich
      );
    default:
      return "";
  }
};

export const formatZinsFix = (value) => {
  switch (value) {
    case "fix":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_fix
      );
    case "variabel":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_variabel
      );
    default:
      return "";
  }
};

export const formatKuendigungsrecht = (value) => {
  switch (value) {
    case "kündbar":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_kuendbar
      );
    case "nicht kündbar":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_nicht_kuendbar
      );
    default:
      return "";
  }
};

export const formatAbtretbarkeit = (value) => {
  switch (value) {
    case "abtretbar":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_form_control_label_label_abtretbar
      );
    default:
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_form_control_label_label_nicht_abtretbar
      );
  }
};

export const formatAuslandsfaehigkeit = (value) => {
  switch (value) {
    case "auslandsfähig":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_form_control_label_label_auslandsfaehig
      );
    default:
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_form_control_label_label_nicht_auslandsfaehig
      );
  }
};

export const formatFaelligkeitstagDerZinszahlung = (value) => {
  switch (value) {
    case "following - nachfolgend":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_following_nachfolgend,
      );

    case "modified following - modifiziert nachfolgend":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_modified_following_modifiziert_nachfolgend,
      );
    default:
      return "";
  }
};

export const formatBerechnungszeitraumDerZinszahlung = (value) => {
  switch (value) {
    case "unadjusted - nicht angepasst":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_unadjusted_nicht_angepasst,
      );
    case "adjusted - angepasst":
      return appIntl().formatMessage(
        messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_adjusted_angepasst,
      );
    default:
      return "";
  }
};

export const formatReferenzzinssatz = (value) => {
  const translations = {
    "1-Monats-EURIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_1_monats_euribor,
    "3-Monats-EURIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_3_monats_euribor,
    "6-Monats-EURIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_6_monats_euribor,
    "12-Monats-EURIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_12_monats_euribor,
    "1-Monats-GBP-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_1_monats_gbp_libor,
    "3-Monats-GBP-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_3_monats_gbp_libor,
    "6-Monats-GBP-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_6_monats_gbp_libor,
    "12-Monats-GBP-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_12_monats_gbp_libor,
    "1-Monats-USD-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_1_monats_usd_libor,
    "3-Monats-USD-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_3_monats_usd_libor,
    "6-Monats-USD-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_6_monats_usd_libor,
    "12-Monats-USD-LIBOR": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_12_monats_usd_libor,
    "2Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_2y_eur_cms_satz,
    "3Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_3y_eur_cms_satz,
    "5Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_5y_eur_cms_satz,
    "10Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_10y_eur_cms_satz,
    "15Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_15y_eur_cms_satz,
    "20Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_20y_eur_cms_satz,
    "30Y-10Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_30y_10y_eur_cms_satz,
    "10Y-2Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_10y_2y_eur_cms_satz,
    "30Y-2Y-EUR-CMS-Satz": messagesBssdForm.shared_bssd_form_ssd_form_menu_item_text_30y_2y_eur_cms_satz,
  };

  return translations[value] ? appIntl().formatMessage(translations[value]) : "";
};

export const formatJaNein = (value) => getTrueFalse(value === "Ja");
