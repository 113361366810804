import { fade } from "@material-ui/core";

export const VerificationType = {
  VALID: "valid",
  WARN: "warn",
  NOT_VALID: "notValid",
};
export const getVerificationColor = (theme, type) => {
  switch (type) {
    case VerificationType.VALID:
      return theme.palette.type === "light"
        ? {
            color: theme.palette.primary2.main,
            bgColor: theme.palette.primary2.light,
            borderColor: theme.palette.primary2.main,
            buttonColor: "default",
          }
        : {
            color: theme.palette.text.primary,
            bgColor: fade(theme.palette.primary2.main, 0.5),
            borderColor: theme.palette.primary2.main,
            buttonColor: "default",
          };
    case VerificationType.WARN:
      return theme.palette.type === "light"
        ? {
            color: theme.palette.common.black,
            bgColor: theme.palette.warning.light,
            borderColor: theme.palette.warning.main,
            buttonColor: "secondary",
          }
        : {
            color: theme.palette.text.primary,
            bgColor: fade(theme.palette.warning.main, 0.5),
            borderColor: theme.palette.warning.main,
            buttonColor: "default",
          };
    case VerificationType.NOT_VALID:
    default:
      return theme.palette.type === "light"
        ? {
            color: theme.palette.error.main,
            bgColor: theme.palette.error.light,
            borderColor: theme.palette.error.main,
            buttonColor: "default",
          }
        : {
            color: theme.palette.text.primary,
            bgColor: fade(theme.palette.error.main, 0.5),
            borderColor: theme.palette.error.main,
            buttonColor: "default",
          };
  }
};
