function action(type, payload = {}) {
  return { type, ...payload };
}

export const OPEN_CSSD_HISTORY = "OPEN_CSSD_HISTORY";

export const cssdHistoryActions = {
  open: (cssd) => action(OPEN_CSSD_HISTORY, { cssd }),
};

export const FETCH_CSSD_HISTORY_REQUEST = "FETCH_CSSD_HISTORY_REQUEST";
export const FETCH_CSSD_HISTORY_FAILURE = "FETCH_CSSD_HISTORY_FAILURE";
export const FETCH_CSSD_HISTORY_SUCCESS = "FETCH_CSSD_HISTORY_SUCCESS";

export const fetchCssdHistoryActions = {
  request: (cssd) => action(FETCH_CSSD_HISTORY_REQUEST, { cssd }),
  success: (auditLog) => action(FETCH_CSSD_HISTORY_SUCCESS, { auditLog }),
  failure: () => action(FETCH_CSSD_HISTORY_FAILURE),
};

export const OPEN_PARTIALCLAIM_CESSION_HISTORY = "OPEN_PARTIALCLAIM_CESSION_HISTORY";

export const partialClaimCessionHistoryActions = {
  open: (partialClaim, cession) =>
    action(OPEN_PARTIALCLAIM_CESSION_HISTORY, { partialClaim, cession }),
};

export const FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST =
  "FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST";
export const FETCH_PARTIALCLAIM_CESSION_HISTORY_FAILURE =
  "FETCH_PARTIALCLAIM_CESSION_HISTORY_FAILURE";
export const FETCH_PARTIALCLAIM_CESSION_HISTORY_SUCCESS =
  "FETCH_PARTIALCLAIM_CESSION_HISTORY_SUCCESS";

export const fetchPartialClaimCessionHistoryActions = {
  request: (partialClaim, cession) =>
    action(FETCH_PARTIALCLAIM_CESSION_HISTORY_REQUEST, { partialClaim, cession }),
  success: (auditLog) => action(FETCH_PARTIALCLAIM_CESSION_HISTORY_SUCCESS, { auditLog }),
  failure: () => action(FETCH_PARTIALCLAIM_CESSION_HISTORY_FAILURE),
};

export const OPEN_INTEREST_NOTIFICATION_HISTORY = "OPEN_INTEREST_NOTIFICATION_HISTORY";

export const interestNotificationHistoryActions = {
  open: (interestNotification) => action(OPEN_INTEREST_NOTIFICATION_HISTORY, { interestNotification }),
};

export const FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST =
  "FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST";
export const FETCH_INTEREST_NOTIFICATION_HISTORY_FAILURE =
  "FETCH_INTEREST_NOTIFICATION_HISTORY_FAILURE";
export const FETCH_INTEREST_NOTIFICATION_HISTORY_SUCCESS =
  "FETCH_INTEREST_NOTIFICATION_HISTORY_SUCCESS";

export const fetchInterestNotificationHistoryActions = {
  request: (interestNotification) => action(FETCH_INTEREST_NOTIFICATION_HISTORY_REQUEST, { interestNotification }),
  success: (auditLog) => action(FETCH_INTEREST_NOTIFICATION_HISTORY_SUCCESS, { auditLog }),
  failure: () => action(FETCH_INTEREST_NOTIFICATION_HISTORY_FAILURE),
};
