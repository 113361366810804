import { defineMessages } from "react-intl";

export const statusMessages = defineMessages({
  extern_flag: {
    id: "cssd.teilforderungen.status.extern",
    defaultMessage: "Extern",
  },
  migrated: {
    id: "cssd.teilforderungen.status.migrated",
    defaultMessage: "Migriert",
  },
  imported: {
    id: "cssd.teilforderungen.status.imported",
    defaultMessage: "Nachdokumentiert",
  },
  status_angelegt: {
    id: "cssd.teilforderungen.status.angelegt",
    defaultMessage: "Abgestimmt",
  },
  status_angezeigt: {
    id: "cssd.teilforderungen.status.angezeigt",
    defaultMessage: "Angelegt",
  },
  status_gekuendigt: {
    id: "cssd.teilforderungen.status.gekuendigt",
    defaultMessage: "Gekündigt",
  },
  status_zurueckgezahlt: {
    id: "cssd.teilforderungen.status.zurueckgezahlt",
    defaultMessage: "Zurückgezahlt",
  },
  status_invalid: {
    id: "cssd.teilforderungen.status.invalid",
    defaultMessage: "Ungültig",
  },
  status_new: {
    id: "cssd.teilforderungen.status.new",
    defaultMessage: "Abgestimmt",
  },
  status_unknown: {
    id: "cssd.teilforderungen.status.unknown",
    defaultMessage: "Unbekannt",
  },
  status_termination: {
    id: "cssd.teilforderungen.status.kuendigung",
    defaultMessage: "Kündigung erstbestätigt",
  },
  status_cancelled: {
    id: "cssd.teilforderungen.status.geloescht",
    defaultMessage: "Gelöscht",
  },
  status_termination_revoke_first_confirmed: {
    id: "cssd.teilforderungen.status.kuendigung.zurueckziehen.erstbestaetigt",
    defaultMessage: "Zurückziehen erstbestätigt",
  },
  status_termination_revoke_second_confirmed: {
    id: "cssd.teilforderungen.status.kuendigung.zurueckziehen.zweitbestaetigt",
    defaultMessage: "Zurückziehen angefragt",
  },
  status_termination_revoke_first_accepted: {
    id: "cssd.teilforderungen.status.kuendigung.zurueckziehen.erstgegenbestaetigt",
    defaultMessage: "Zurückziehen erstgegenbestätigt",
  },
  status_termination_dn: {
    id: "cssd.teilforderungen.status.kuendigung.dn",
    defaultMessage: " DN",
  },
  status_termination_dg: {
    id: "cssd.teilforderungen.status.kuendigung.dg",
    defaultMessage: " DG",
  },
  status_termination_zs: {
    id: "cssd.teilforderungen.status.kuendigung.zs",
    defaultMessage: " ZS",
  },
});
