import {
  FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST,
  FETCH_CESSION_FOR_SSD_REQUEST,
  OPEN_CESSION_REGISTER_MODAL,
  CLOSE_CESSION_REGISTER_MODAL,
  OPEN_CESSION_CONFIRM_MODAL,
  CLOSE_CESSION_CONFIRM_MODAL,
  OPEN_CESSION_PREVIEW_MODAL,
  CLOSE_CESSION_PREVIEW_MODAL,
  SAVE_CESSION_REQUEST,
  SAVE_CESSION_SUCCESS,
  SAVE_CESSION_FAILURE,
  MODIFY_CESSION_REQUEST,
  MODIFY_CESSION_SUCCESS,
  MODIFY_CESSION_FAILURE,
  SUBMIT_CESSION_REQUEST,
  SUBMIT_CESSION_SUCCESS,
  SUBMIT_CESSION_FAILURE,
  OFFER_CESSION_REQUEST,
  OFFER_CESSION_SUCCESS,
  OFFER_CESSION_FAILURE,
  ACCEPT_CESSION_REQUEST,
  ACCEPT_CESSION_SUCCESS,
  ACCEPT_CESSION_FAILURE,
  REJECT_CESSION_REQUEST,
  REJECT_CESSION_SUCCESS,
  REJECT_CESSION_FAILURE,
  FETCH_CESSION_FOR_SSD_SUCCESS,
  FETCH_CESSION_DETAILS_FOR_SSD_SUCCESS,
  FETCH_CESSION_REQUESTS_SUCCESS,
  FETCH_CESSION_DETAILS_FOR_SSD_NO_PERMISSION,
  FETCH_CESSION_REQUEST_DETAILS_SUCCESS,
  FETCH_CESSION_REQUEST_DETAILS_NO_PERMISSION,
  CESSION_EVENT_UPDATE,
  CESSION_FOUR_EYES_EVENT_UPDATE,
  ADD_CESSION_EVENT_DG,
  ADD_CESSION_EVENT_NEW_DG,
  MODIFY_AND_OFFER_CESSION_REQUEST,
  MODIFY_AND_OFFER_CESSION_SUCCESS,
  MODIFY_AND_OFFER_CESSION_FAILURE,
  NOTIFY_CESSION_REQUEST,
  NOTIFY_CESSION_SUCCESS,
  NOTIFY_CESSION_FAILURE,
  CESSION_PROCESS_NOTIFICATION_REQUEST,
  CESSION_PROCESS_NOTIFICATION_SUCCESS,
  CESSION_PROCESS_NOTIFICATION_FAILURE,
  CANCEL_CESSION_REQUEST,
  CANCEL_CESSION_SUCCESS,
  CANCEL_CESSION_FAILURE,
  FETCH_REJECT_REASON_SUCCESS,
  FETCH_REJECT_REASON_EMPTY,
  FETCH_CESSION_REQUEST_DETAILS_FAILURE,
} from "./actions";
import { toUtf8 } from "services/web3Services/commons";
import { FourEyesState, zeroAddress } from "util/constants";
// reducer with initial state
const initialState = {
  ssdId: "",
  cessions: [],
  currentCession: {},
  cessionRequests: [],
  cessionRegisterModalOpen: false,
  cessionConfirmModalOpen: false,
  cessionPreviewModalOpen: false,
  saveCessionPending: false,
  submitCessionPending: false,
  offerCessionPending: false,
  acceptCessionPending: false,
  rejectCessionPending: false,
  notifyCessionPending: false,
  processNotificationPending: false,
  cancelCessionPending: false,
  rejectReason: null,
};

function setSsdIdReducer(state, action) {
  return {
    ...state,
    ssdId: action.ssdId,
  };
}

function openCessionRegisterModalReducer(state) {
  return {
    ...state,
    cessionRegisterModalOpen: true,
  };
}

function closeCessionRegisterModalReducer(state) {
  return {
    ...state,
    cessionRegisterModalOpen: false,
  };
}

function openCessionConfirmModalReducer(state, action) {
  const index = state.cessions.findIndex((x) => `${x.abtretung_id}` === action.cessionId);
  let currentCession = state.cessions[index];
  return {
    ...state,
    currentCession: currentCession,
    cessionConfirmModalOpen: true,
  };
}

function closeCessionConfirmModalReducer(state) {
  return {
    ...state,
    cessionConfirmModalOpen: false,
    rejectReason: null,
  };
}

function openCessionPreviewModalReducer(state, action) {
  return {
    ...state,
    cessionPreviewModalOpen: true,
    currentCession: action.cessionInfo,
  };
}

function closeCessionPreviewModalReducer(state) {
  return {
    ...state,
    cessionPreviewModalOpen: false,
  };
}

function saveCessionRequestReducer(state) {
  return {
    ...state,
    saveCessionPending: true,
  };
}

function saveCessionSuccessReducer(state) {
  return {
    ...state,
    saveCessionPending: false,
  };
}

function saveCessionFailureReducer(state) {
  return {
    ...state,
    saveCessionPending: false,
  };
}

function modifyCessionRequestReducer(state) {
  return {
    ...state,
    saveCessionPending: true,
  };
}

function modifyCessionSuccessReducer(state) {
  return {
    ...state,
    saveCessionPending: false,
  };
}

function modifyCessionFailureReducer(state) {
  return {
    ...state,
    saveCessionPending: false,
  };
}

function modifyAndOfferCessionRequestReducer(state) {
  return {
    ...state,
    offerCessionPending: true,
  };
}

function modifyAndOfferCessionSuccessReducer(state) {
  return {
    ...state,
    offerCessionPending: false,
  };
}

function modifyAndOfferCessionFailureReducer(state) {
  return {
    ...state,
    offerCessionPending: false,
  };
}

function submitCessionRequestReducer(state) {
  return {
    ...state,
    submitCessionPending: true,
  };
}

function submitCessionSuccessReducer(state) {
  return {
    ...state,
    submitCessionPending: false,
  };
}

function submitCessionFailureReducer(state) {
  return {
    ...state,
    submitCessionPending: false,
  };
}

function offerCessionRequestReducer(state) {
  return {
    ...state,
    offerCessionPending: true,
  };
}

function offerCessionSuccessReducer(state) {
  return {
    ...state,
    offerCessionPending: false,
  };
}

function offerCessionFailureReducer(state) {
  return {
    ...state,
    offerCessionPending: false,
  };
}

function acceptCessionRequestReducer(state) {
  return {
    ...state,
    acceptCessionPending: true,
  };
}

function acceptCessionSuccessReducer(state) {
  return {
    ...state,
    acceptCessionPending: false,
  };
}

function acceptCessionFailureReducer(state) {
  return {
    ...state,
    acceptCessionPending: false,
  };
}

function notifyCessionRequestReducer(state) {
  return {
    ...state,
    notifyCessionPending: true,
  };
}

function notifyCessionSuccessReducer(state) {
  return {
    ...state,
    notifyCessionPending: false,
  };
}

function notifyCessionFailureReducer(state) {
  return {
    ...state,
    notifyCessionPending: false,
  };
}

function rejectCessionRequestReducer(state) {
  return {
    ...state,
    rejectCessionPending: true,
  };
}

function rejectCessionSuccessReducer(state) {
  return {
    ...state,
    rejectCessionPending: false,
  };
}

function rejectCessionFailureReducer(state) {
  return {
    ...state,
    rejectCessionPending: false,
  };
}

function cancelCessionRequestReducer(state) {
  return {
    ...state,
    cancelCessionPending: true,
  };
}

function cancelCessionSuccessReducer(state) {
  return {
    ...state,
    cancelCessionPending: false,
  };
}

function cancelCessionFailureReducer(state) {
  return {
    ...state,
    cancelCessionPending: false,
  };
}

function fetchCessionForSsdSuccessReducer(state, action) {
  return {
    ...state,
    cessions: action.cessions,
  };
}
function updateCessionInArray(cessionsArr, cessionDetails) {
  return [...cessionsArr].map((entry) => {
    if (toUtf8(entry.cessionId) === `${cessionDetails.abtretung_id}`) {
      return {
        ...entry,
        ...cessionDetails,
      };
    }
    return entry;
  });
}

function fetchCessionDetailsSuccessReducer(state, action) {
  const cessions = updateCessionInArray(state.cessions, action.cessionDetails);
  const cessionRequests = updateCessionInArray(state.cessionRequests, action.cessionDetails);
  return {
    ...state,
    cessions,
    cessionRequests,
  };
}

function fetchCessionDetailsNotAllowedReducer(state, action) {
  const cessions = removeCessionFromArray([...state.cessions], action.cessionId);
  return {
    ...state,
    cessions,
  };
}

function fetchCessionRequestSuccessReducer(state, action) {
  const cessionRequests = [...action.cessions].map((cession) => {
    return {
      cessionId: cession.cessionId,
      hash: cession.cessionHash,
      newSsdId: cession.ssdId,
      confirmationState: FourEyesState.NONE,
      firstConfirmer: zeroAddress,
    };
  });
  return {
    ...state,
    cessionRequests,
  };
}

function fetchCessionRequestDetailsSuccessReducer(state, action) {
  const updatedCessionRequestArr = updateCessionInArray(
    state.cessionRequests,
    action.cessionDetails
  );
  return {
    ...state,
    cessionRequests: updatedCessionRequestArr,
  };
}

function removeCessionFromArray(cessionsArr, cessionIdToRemove) {
  return cessionsArr.filter((cession) => toUtf8(cession.cessionId) !== cessionIdToRemove);
}

function fetchCessionRequestDetailsNoPermissionReducer(state, action) {
  const cessionRequests = removeCessionFromArray([...state.cessionRequests], action.cessionId);
  return {
    ...state,
    cessionRequests,
  };
}

function fetchCessionRequestDetailsFailureReducer(state, action) {
  const cessionRequests = removeCessionFromArray([...state.cessionRequests], action.cessionId);
  return {
    ...state,
    cessionRequests,
  };
}

function updatecessioninArr(cessionsArr, cessionId, cessionState) {
  return [...cessionsArr].map((cession) => {
    if (toUtf8(cession.cessionId).localeCompare(toUtf8(cessionId)) === 0) {
      return {
        ...cession,
        state: cessionState,
      };
    } else {
      return cession;
    }
  });
}

function cessionEventUpdateReducer(state, action) {
  const cessions = updatecessioninArr(state.cessions, action.cessionId, action.cessionState);
  const cessionRequests = updatecessioninArr(
    state.cessionRequests,
    action.cessionId,
    action.cessionState
  );

  return {
    ...state,
    cessions,
    cessionRequests,
  };
}

function updateCessionFourEyesStateInArray(cessions, id, state, firstConfirmer) {
  let confirmer = firstConfirmer;
  //remove firstconfirmer if foureyes event state is "CANCELLED"
  if (
    state === FourEyesState.CESSION_OFFER_SECOND ||
    state === FourEyesState.CESSION_OFFER_CANCELLED ||
    state === FourEyesState.CESSION_OFFER_CANCELLED_ON_MODIFY ||
    state === FourEyesState.CESSION_ACCEPT_SECOND ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED ||
    state === FourEyesState.CESSION_ACCEPT_CANCELLED_ON_REJECT
  ) {
    confirmer = zeroAddress;
  }
  return [...cessions].map((cession) => {
    if (cession.cessionId.localeCompare(id) === 0) {
      return {
        ...cession,
        confirmationState: state,
        firstConfirmer: confirmer,
      };
    }
    return cession;
  });
}

function updateCessionFourEyesStatusReducer(state, action) {
  const cessions = updateCessionFourEyesStateInArray(
    state.cessions,
    action.cessionId,
    action.state,
    action.confirmer
  );
  const cessionRequests = updateCessionFourEyesStateInArray(
    state.cessionRequests,
    action.cessionId,
    action.state,
    action.confirmer
  );
  return {
    ...state,
    cessions,
    cessionRequests,
  };
}

function addCessionFromEventForDgReducer(state, action) {
  const cessions = [...state.cessions];
  const cessionToAdd = {
    cessionId: action.cessionId,
    hash: action.cessionHash,
    newSsdId: action.ssdId,
    confirmationState: FourEyesState.NONE,
    firstConfirmer: zeroAddress,
  };

  const hitsInArr = [...state.cessions].filter(
    (cession) => cession.cessionId === cessionToAdd.cessionId
  );

  if (hitsInArr.length === 0 && state.ssdId === toUtf8(action.ssdId)) {
    cessions.push(cessionToAdd);
  }

  return {
    ...state,
    cessions,
  };
}

function addCessionFromEventForNewDGReducer(state, action) {
  const cessionRequests = [...state.cessionRequests];
  const cessionToAdd = {
    cessionId: action.cessionId,
    hash: action.cessionHash,
    newSsdId: action.ssdId,
    confirmationState: FourEyesState.NONE,
    firstConfirmer: zeroAddress,
  };

  const hitsInArr = [...state.cessionRequests].filter(
    (cession) => cession.cessionId === cessionToAdd.cessionId
  );
  if (hitsInArr.length === 0) {
    cessionRequests.push(cessionToAdd);
  }

  return {
    ...state,
    cessionRequests,
  };
}

function cessionProcessNotificationRequestReducer(state) {
  return {
    ...state,
    processNotificationPending: true,
  };
}
function cessionProcessNotificationSuccessReducer(state) {
  return {
    ...state,
    processNotificationPending: false,
  };
}
function cessionProcessNotificationFailureReducer(state) {
  return {
    ...state,
    processNotificationPending: false,
  };
}

function fetchRejectReasonSuccessReducer(state, action) {
  return {
    ...state,
    rejectReason: action.reason,
  };
}

function fetchRejectReasonEmptyReducer(state) {
  return {
    ...state,
    rejectReason: null,
  };
}

export function cessionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CESSION_FOR_SSD_UPDATE_AND_REQUEST:
      return setSsdIdReducer(state, action);
    case FETCH_CESSION_FOR_SSD_REQUEST:
      return setSsdIdReducer(state, action);
    case OPEN_CESSION_REGISTER_MODAL:
      return openCessionRegisterModalReducer(state, action);
    case CLOSE_CESSION_REGISTER_MODAL:
      return closeCessionRegisterModalReducer(state, action);

    case OPEN_CESSION_CONFIRM_MODAL:
      return openCessionConfirmModalReducer(state, action);
    case CLOSE_CESSION_CONFIRM_MODAL:
      return closeCessionConfirmModalReducer(state, action);

    case OPEN_CESSION_PREVIEW_MODAL:
      return openCessionPreviewModalReducer(state, action);
    case CLOSE_CESSION_PREVIEW_MODAL:
      return closeCessionPreviewModalReducer(state, action);

    case SAVE_CESSION_REQUEST:
      return saveCessionRequestReducer(state, action);
    case SAVE_CESSION_SUCCESS:
      return saveCessionSuccessReducer(state, action);
    case SAVE_CESSION_FAILURE:
      return saveCessionFailureReducer(state, action);

    case MODIFY_CESSION_REQUEST:
      return modifyCessionRequestReducer(state, action);
    case MODIFY_CESSION_SUCCESS:
      return modifyCessionSuccessReducer(state, action);
    case MODIFY_CESSION_FAILURE:
      return modifyCessionFailureReducer(state, action);

    case MODIFY_AND_OFFER_CESSION_REQUEST:
      return modifyAndOfferCessionRequestReducer(state, action);
    case MODIFY_AND_OFFER_CESSION_SUCCESS:
      return modifyAndOfferCessionSuccessReducer(state, action);
    case MODIFY_AND_OFFER_CESSION_FAILURE:
      return modifyAndOfferCessionFailureReducer(state, action);

    case SUBMIT_CESSION_REQUEST:
      return submitCessionRequestReducer(state, action);
    case SUBMIT_CESSION_SUCCESS:
      return submitCessionSuccessReducer(state, action);
    case SUBMIT_CESSION_FAILURE:
      return submitCessionFailureReducer(state, action);

    case OFFER_CESSION_REQUEST:
      return offerCessionRequestReducer(state, action);
    case OFFER_CESSION_SUCCESS:
      return offerCessionSuccessReducer(state, action);
    case OFFER_CESSION_FAILURE:
      return offerCessionFailureReducer(state, action);

    case ACCEPT_CESSION_REQUEST:
      return acceptCessionRequestReducer(state, action);
    case ACCEPT_CESSION_SUCCESS:
      return acceptCessionSuccessReducer(state, action);
    case ACCEPT_CESSION_FAILURE:
      return acceptCessionFailureReducer(state, action);

    case NOTIFY_CESSION_REQUEST:
      return notifyCessionRequestReducer(state, action);
    case NOTIFY_CESSION_SUCCESS:
      return notifyCessionSuccessReducer(state, action);
    case NOTIFY_CESSION_FAILURE:
      return notifyCessionFailureReducer(state, action);

    case REJECT_CESSION_REQUEST:
      return rejectCessionRequestReducer(state, action);
    case REJECT_CESSION_SUCCESS:
      return rejectCessionSuccessReducer(state, action);
    case REJECT_CESSION_FAILURE:
      return rejectCessionFailureReducer(state, action);

    case CANCEL_CESSION_REQUEST:
      return cancelCessionRequestReducer(state, action);
    case CANCEL_CESSION_SUCCESS:
      return cancelCessionSuccessReducer(state, action);
    case CANCEL_CESSION_FAILURE:
      return cancelCessionFailureReducer(state, action);

    case FETCH_CESSION_FOR_SSD_SUCCESS:
      return fetchCessionForSsdSuccessReducer(state, action);
    case FETCH_CESSION_DETAILS_FOR_SSD_SUCCESS:
      return fetchCessionDetailsSuccessReducer(state, action);
    case FETCH_CESSION_DETAILS_FOR_SSD_NO_PERMISSION:
      return fetchCessionDetailsNotAllowedReducer(state, action);
    case FETCH_CESSION_REQUESTS_SUCCESS:
      return fetchCessionRequestSuccessReducer(state, action);
    case FETCH_CESSION_REQUEST_DETAILS_SUCCESS:
      return fetchCessionRequestDetailsSuccessReducer(state, action);
    case FETCH_CESSION_REQUEST_DETAILS_NO_PERMISSION:
      return fetchCessionRequestDetailsNoPermissionReducer(state, action);
    case FETCH_CESSION_REQUEST_DETAILS_FAILURE:
      return fetchCessionRequestDetailsFailureReducer(state, action);

    case ADD_CESSION_EVENT_DG:
      return addCessionFromEventForDgReducer(state, action);
    case ADD_CESSION_EVENT_NEW_DG:
      return addCessionFromEventForNewDGReducer(state, action);

    case CESSION_EVENT_UPDATE:
      return cessionEventUpdateReducer(state, action);
    case CESSION_FOUR_EYES_EVENT_UPDATE:
      return updateCessionFourEyesStatusReducer(state, action);

    case CESSION_PROCESS_NOTIFICATION_REQUEST:
      return cessionProcessNotificationRequestReducer(state, action);
    case CESSION_PROCESS_NOTIFICATION_SUCCESS:
      return cessionProcessNotificationSuccessReducer(state, action);
    case CESSION_PROCESS_NOTIFICATION_FAILURE:
      return cessionProcessNotificationFailureReducer(state, action);

    case FETCH_REJECT_REASON_SUCCESS:
      return fetchRejectReasonSuccessReducer(state, action);
    case FETCH_REJECT_REASON_EMPTY:
      return fetchRejectReasonEmptyReducer(state, action);

    default:
      return state;
  }
}
