import { sendSignedHttpRequest } from "util/requestSigning";
//---------------------------------------------------------------------------

export function sendMail({
  template,
  arranger,
  ssdid,
  fingerprint,
  fingerprint_rebuy,
  recipient = "",
  valuta = "",
  waehrung_nominal = "",
  nominal = "",
  kuendigungsvaluta = "",
  rueckzahlung = "",
  kuendigungsrecht = "",
  kuendigungsgrund = "",
  mrel_72b_crr = "",
  kwg_46f = "",
  zins_fix = "",
  subid = "",
  kuendigungFingerprint = "",
  abtretungFingerprint = "",
  firmenname = "",
  digitsKennung = "",
  abtretungId = "",
  parentId = 0,
}) {
  const data = {
    recipient,
    template,
    content: {
      geschaeft: ssdid,
      valuta: valuta,
      waehrung_nominal: waehrung_nominal,
      nominal: nominal,
      kuendigungsvaluta: kuendigungsvaluta,
      rueckzahlung: rueckzahlung,
      kuendigungsrecht: kuendigungsrecht,
      kuendigungsgrund: kuendigungsgrund,
      mrel_72b_crr: mrel_72b_crr,
      kwg_46f: kwg_46f,
      zins_fix: zins_fix,
      subid: subid,
      kuendigungFingerprint,
      abtretungFingerprint,
      firmenname,
      digitsKennung,
      abtretungId,
    },
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdid}/${fingerprint}/${fingerprint_rebuy}/${parentId}/sendconfirm`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export async function sendGenericMail({
  template,
  sender,
  recipient,
  firmenname = "",
  geschaeft = "",
  abtretungId = "",
  abtretungFingerprint = "",
  digitsKennung = "",
  lei = "",
  urkundennummer = "",
}) {
  const data = {
    recipient,
    template,
    content: {
      firmenname,
      digitsKennung,
      geschaeft,
      abtretungId,
      abtretungFingerprint,
      lei,
      urkundennummer,
    },
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/mail/sendmail/${sender}`,
    sender,
    data
  ).catch(function (error) {
    console.error(error);
  });
}
export function sendCssdMail({
  template,
  arranger,
  cssdId,
  fingerprint,
  recipient = "",
  begruendung = "",
  oldHash = "",
  angezeigteGeschaeftsnummern = "",
  ablehnungsgrund = "",
}) {
  const data = {
    recipient,
    template,
    content: { begruendung, oldHash, angezeigteGeschaeftsnummern, ablehnungsgrund },
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/sendmail/${arranger}/${cssdId}/${fingerprint}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendCssdCessionMail({
  template,
  arranger,
  cessionId,
  cessionHash,
  recipient = "",
  subject = "",
  firmenname = "",
  lei = "",
  digitsKennung = "",
  begruendung = "",
}) {
  const data = {
    recipient,
    template,
    subject,
    content: { firmenname, lei, digitsKennung, begruendung },
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/sendmailForAbtretung/${arranger}/${cessionId}/${cessionHash}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendBulkCssdCessionMail({ arranger, mailServiceRequests }) {
  const data = { mailServiceRequests };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abtretung/sendBulkMailForAbtretung/${arranger}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendBulkMailForNewGp({ arranger, cssdId, newGps }) {
  const data = { neuerGeschaeftspartnerRequests: newGps };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/sendSammelMailForNeuerGeschaeftspartner/${arranger}/${cssdId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendBulkMailForNeueZinsmitteilung({ arranger, cssdId }) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/sendBulkMailForNeueZinsmitteilung/${arranger}/${cssdId}`,
    arranger
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendCssdTerminationMail({ arranger, cssdId, kuendigungId }) {
  const data = { template: "cssd_kuendigung_zweitbestaetigung" };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/sendmail/${arranger}/${cssdId}/${kuendigungId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendPartialClaimTerminationMail({
  arranger,
  cssdId,
  teilforderungId,
  kuendigungId,
}) {
  const data = { template: "cssd_kuendigung_zweitbestaetigung" };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/sendmail/${arranger}/${cssdId}/teilforderung/${teilforderungId}/${kuendigungId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendCessionAbortMail(
  arranger,
  cssdId,
  partialClaimId,
  abtretungIds,
  abtretungsIdsRelevantFuerNeuenDG
) {
  const data = {
    template: "cssd_kuendigung_uebertragung_abgebrochen",
    content: { abtretungIds, abtretungsIdsRelevantFuerNeuenDG },
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/abbruchabtretung/sendmail/${arranger}/${cssdId}/${partialClaimId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendTerminationRevokeMail(arranger, cssdId, kuendigungId, templateName) {
  const data = { template: templateName };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/kuendigung/zurueckziehen/sendmail/${arranger}/${cssdId}/${kuendigungId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}

export function sendAbortCessionMail(
  arranger,
  cssdId,
  template,
  zessionareAbtretungIds,
  zedentenAbtretungIds
) {
  const data = { template, zessionareAbtretungIds, zedentenAbtretungIds };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/stornocssd/sendmail/${arranger}/${cssdId}`,
    arranger,
    data
  ).catch(function (error) {
    console.error(error);
  });
}
