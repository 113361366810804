import { sendTransaction, getBSSDControllerContractMethods } from "./commons";
import { contractCache } from "services/smartContracts/smartContracts";
import { toHex } from "services/web3Services/commons";
import { CessionState } from "util/constants";

export async function getCessionMovedEventsFromBcForCompany() {
  const events = await contractCache.ssdManagerContract.getPastEvents("CessionMovedEvent", {
    fromBlock: 0,
    toBlock: "latest",
  });
  const result = [...events].map((event) => {
    return event.returnValues;
  });
  return result;
}

export async function getCessionEventsFromBcForCompany(companyIdProxy) {
  const events = await contractCache.ssdManagerContract.getPastEvents("CessionEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { buyer: companyIdProxy, state: CessionState.NEW },
  });
  const result = [...events].map((event) => {
    return event.returnValues;
  });
  return result;
}

export const getCessionCount = async (arranger, ssdId) =>
  await contractCache.ssdManagerContract.methods
    .getCessionCount(arranger, toHex(`${ssdId}`))
    .call();

export async function getCessionsForSsdId(arranger, ssdId) {
  const cessionCount = await getCessionCount(arranger, ssdId);
  const cessionDetails = [];
  for (let i = 0; i < cessionCount; i++) {
    cessionDetails.push(
      (async () => {
        return contractCache.ssdManagerContract.methods
          .getCessionAtIdx(arranger, toHex(`${ssdId}`), i)
          .call();
      })()
    );
  }
  return await Promise.all(cessionDetails);
}

export async function checkIfCessionWasApproved(cessionId) {
  const events = await contractCache.ssdManagerContract.getPastEvents("CessionEvent", {
    fromBlock: 0,
    toBlock: "latest",
    filter: { cessionId: cessionId },
  });
  const result = [...events].map((event) => {
    return event.returnValues;
  });

  for (let cession of result) {
    if (cession.state === CessionState.APPROVED || cession.state === CessionState.OPEN) {
      return true;
    }
  }
  return false;
}

export async function cessionNew(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  cessionHash,
  cessionValueDate,
  buyer,
  newSsdId,
  newSsdHash,
  externallySettled
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionNew(
      arranger,
      ssdId,
      cessionId,
      cessionHash,
      cessionValueDate,
      buyer,
      newSsdId,
      newSsdHash,
      externallySettled
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionNewAndOffer(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  cessionHash,
  cessionValueDate,
  buyer,
  newSsdId,
  newSsdHash,
  oldSsdHash,
  externallySettled
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionNewAndOffer(
      arranger,
      ssdId,
      cessionId,
      cessionHash,
      cessionValueDate,
      buyer,
      newSsdId,
      newSsdHash,
      oldSsdHash,
      externallySettled
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionModify(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  cessionValueDate,
  cessionHash, // eventually changed this cession hash
  newSsdHash // eventually changed newSsd hash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionModify(arranger, ssdId, cessionId, cessionValueDate, cessionHash, newSsdHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionOffer(
  account,
  arranger,
  firstConfirmer,
  ssdId, //old ssdId
  cessionId,
  ssdHash, //old ssdHash
  cessionHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionOffer(arranger, firstConfirmer, ssdId, cessionId, ssdHash, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionModifyAndOffer(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  cessionValueDate,
  cessionHash, // eventually changed this cession hash
  newSsdHash, // eventually changed newSsd hash
  oldSsdHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionModifyAndOffer(
      arranger,
      ssdId,
      cessionId,
      cessionValueDate,
      cessionHash,
      newSsdHash,
      oldSsdHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionCancel(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  ssdHash, //old ssdHash
  cessionHash,
  reasonHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionCancel(arranger, ssdId, cessionId, ssdHash, cessionHash, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionOfferCancelConfirmation(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionOfferCancelConfirmation(arranger, ssdId, cessionId)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionAccept(
  account,
  arranger,
  firstConfirmer,
  ssdId, //old ssdId
  cessionId,
  ssdHash, //old ssdHash
  cessionHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionAccept(arranger, firstConfirmer, ssdId, cessionId, ssdHash, cessionHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionAcceptCancelConfirmation(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionAcceptCancelConfirmation(arranger, ssdId, cessionId)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionReject(
  account,
  arranger,
  ssdId, //old ssdId
  cessionId,
  ssdHash, //old ssdHash
  cessionHash,
  reasonHash
) {
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionReject(arranger, ssdId, cessionId, ssdHash, cessionHash, reasonHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionNotify(
  account,
  arranger,
  ssdId,
  ssdHash,
  restSsdId,
  restSsdHash,
  cessionIds,
  cessionHashes
) {
  console.error({
    account,
    arranger,
    ssdId,
    ssdHash,
    restSsdId,
    restSsdHash,
    cessionIds,
    cessionHashes,
  });
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionNotify(arranger, ssdId, ssdHash, restSsdId, restSsdHash, cessionIds, cessionHashes)
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export async function cessionProcessNotification(
  account,
  arranger,
  ssdId,
  restSsdId,
  expectedRestSsdHash,
  restSsdValueDate,
  cessionIds,
  cessionExpectedHashes,
  newSsdIds,
  newSsdHashes
) {
  console.error({
    arranger,
    ssdId,
    restSsdId,
    expectedRestSsdHash,
    restSsdValueDate,
    cessionIds,
    cessionExpectedHashes,
    newSsdIds,
    newSsdHashes,
  });
  const contractMethods = getBSSDControllerContractMethods();
  const data = contractMethods
    .cessionProcessNotification(
      arranger,
      ssdId,
      restSsdId,
      expectedRestSsdHash,
      restSsdValueDate,
      cessionIds,
      cessionExpectedHashes,
      newSsdIds,
      newSsdHashes
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.bssdController._address, data);
}

export const getSSDCessionData = async (arranger, id) =>
  await contractCache.ssdManagerContract.methods.getSSDCessionData(arranger, toHex(`${id}`)).call();
