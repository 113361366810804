import {
  CLOSE_CSSD_TERMINATION_DIALOG,
  SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST,
  SAVE_AND_CONFIRM_CSSD_TERMINATION_SUCCESS,
  SAVE_AND_CONFIRM_CSSD_TERMINATION_FAILURE,
  CONFIRM_CSSD_TERMINATION_REQUEST,
  CONFIRM_CSSD_TERMINATION_SUCCESS,
  CONFIRM_CSSD_TERMINATION_FAILURE,
  CANCEL_CSSD_TERMINATION_REQUEST,
  CANCEL_CSSD_TERMINATION_SUCCESS,
  CANCEL_CSSD_TERMINATION_FAILURE,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS,
  SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE,
  CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST,
  CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS,
  CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE,
  CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST,
  CANCEL_PARTIAL_CLAIM_TERMINATION_SUCCESS,
  CANCEL_PARTIAL_CLAIM_TERMINATION_FAILURE,
  OPEN_CONFIRM_REVOKE_DIALOG,
  CLOSE_CONFIRM_REVOKE_DIALOG,
  OFFER_REVOKE_REQUEST,
  OFFER_REVOKE_SUCCESS,
  OFFER_REVOKE_FAILURE,
  CANCEL_REVOKE_REQUEST,
  CANCEL_REVOKE_SUCCESS,
  CANCEL_REVOKE_FAILURE,
  ACCEPT_REVOKE_REQUEST,
  ACCEPT_REVOKE_SUCCESS,
  ACCEPT_REVOKE_FAILURE,
  OPEN_CSSD_TERMINATION_DIALOG_SUCCESS,
} from "./actions";

const initialState = {
  cssdRef: null,
  terminationRef: {},
  partialClaimRef: null,
  saveAndConfirmCssdTerminationPending: false,
  confirmCssdTerminationPending: false,
  cancelCssdTerminationPending: false,
  saveAndConfirmPartialClaimTerminationPending: false,
  confirmPartialClaimTerminationPending: false,
  cancelPartialClaimTerminationPending: false,
  confirmRevokeDialog: {
    partialClaimRef: null,
    terminationRef: null,
  },
  offerRevokePending: false,
  cancelRevokePending: false,
  acceptRevokePending: false,
  isAnyParticipantEnglish: false,
};

const openCssdTerminationDialogReducer = (state, action) => {
  return {
    ...state,
    cssdRef: action.cssd,
    terminationRef: action.termination,
    partialClaimRef: action.partialClaim,
    isAnyParticipantEnglish:
      (action.cssd?.darlehensnehmer.sprache ||
        action.partialClaimDetail?.darlehensnehmer.sprache) === "en" ||
      (action.cssd?.darlehensgeber.sprache || action.partialClaimDetail?.darlehensgeber.sprache) ===
        "en",
  };
};

const closeCssdTerminationDialogReducer = (state) => {
  return {
    ...state,
    cssdRef: null,
    terminationRef: {},
    partialClaimRef: null,
  };
};

const saveAndConfirmCssdTerminationRequestReducer = (state) => {
  return {
    ...state,
    saveAndConfirmCssdTerminationPending: true,
  };
};

const saveAndConfirmCssdTerminationSuccessReducer = (state) => {
  return {
    ...state,
    saveAndConfirmCssdTerminationPending: false,
  };
};

const saveAndConfirmCssdTerminationFailureReducer = (state) => {
  return {
    ...state,
    saveAndConfirmCssdTerminationPending: false,
  };
};

const confirmCssdTerminationRequestReducer = (state) => {
  return {
    ...state,
    confirmCssdTerminationPending: true,
  };
};

const confirmCssdTerminationSuccessReducer = (state) => {
  return {
    ...state,
    confirmCssdTerminationPending: false,
  };
};

const confirmCssdTerminationFailureReducer = (state) => {
  return {
    ...state,
    confirmCssdTerminationPending: false,
  };
};

const cancelCssdTerminationRequestReducer = (state) => {
  return {
    ...state,
    cancelCssdTerminationPending: true,
  };
};

const cancelCssdTerminationSuccessReducer = (state) => {
  return {
    ...state,
    cancelCssdTerminationPending: false,
  };
};

const cancelCssdTerminationFailureReducer = (state) => {
  return {
    ...state,
    cancelCssdTerminationPending: false,
  };
};

const saveAndConfirmPartialClaimTerminationRequestReducer = (state) => {
  return {
    ...state,
    saveAndConfirmPartialClaimTerminationPending: true,
  };
};

const saveAndConfirmPartialClaimTerminationSuccessReducer = (state) => {
  return {
    ...state,
    saveAndConfirmPartialClaimTerminationPending: false,
  };
};

const saveAndConfirmPartialClaimTerminationFailureReducer = (state) => {
  return {
    ...state,
    saveAndConfirmPartialClaimTerminationPending: false,
  };
};

const confirmPartialClaimTerminationRequestReducer = (state) => {
  return {
    ...state,
    confirmPartialClaimTerminationPending: true,
  };
};

const confirmPartialClaimTerminationSuccessReducer = (state) => {
  return {
    ...state,
    confirmPartialClaimTerminationPending: false,
  };
};

const confirmPartialClaimTerminationFailureReducer = (state) => {
  return {
    ...state,
    confirmPartialClaimTerminationPending: false,
  };
};

const cancelPartialClaimTerminationRequestReducer = (state) => {
  return {
    ...state,
    cancelPartialClaimTerminationPending: true,
  };
};

const cancelPartialClaimTerminationSuccessReducer = (state) => {
  return {
    ...state,
    cancelPartialClaimTerminationPending: false,
  };
};

const cancelPartialClaimTerminationFailureReducer = (state) => {
  return {
    ...state,
    cancelPartialClaimTerminationPending: false,
  };
};

const openConfirmRevokeDialogReducer = (state, action) => {
  return {
    ...state,
    confirmRevokeDialog: {
      partialClaimRef: action.partialClaim,
      terminationRef: action.termination,
    },
  };
};

const closeConfirmRevokeDialogReducer = (state) => {
  return {
    ...state,
    confirmRevokeDialog: {
      partialClaimRef: null,
      terminationRef: null,
    },
  };
};

const offerRevokeRequestReducer = (state) => {
  return {
    ...state,
    offerRevokePending: true,
  };
};

const offerRevokeSuccessReducer = (state) => {
  return {
    ...state,
    offerRevokePending: false,
  };
};

const offerRevokeFailureReducer = (state) => {
  return {
    ...state,
    offerRevokePending: false,
  };
};

const cancelRevokeRequestReducer = (state) => {
  return {
    ...state,
    cancelRevokePending: true,
  };
};

const cancelRevokeSuccessReducer = (state) => {
  return {
    ...state,
    cancelRevokePending: false,
  };
};

const cancelRevokeFailureReducer = (state) => {
  return {
    ...state,
    cancelRevokePending: false,
  };
};

const acceptRevokeRequestReducer = (state) => {
  return {
    ...state,
    acceptRevokePending: true,
  };
};

const acceptRevokeSuccessReducer = (state) => {
  return {
    ...state,
    acceptRevokePending: false,
  };
};

const acceptRevokeFailureReducer = (state) => {
  return {
    ...state,
    acceptRevokePending: false,
  };
};

export function cssdTerminationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CSSD_TERMINATION_DIALOG_SUCCESS:
      return openCssdTerminationDialogReducer(state, action);
    case CLOSE_CSSD_TERMINATION_DIALOG:
      return closeCssdTerminationDialogReducer(state);

    case SAVE_AND_CONFIRM_CSSD_TERMINATION_REQUEST:
      return saveAndConfirmCssdTerminationRequestReducer(state, action);
    case SAVE_AND_CONFIRM_CSSD_TERMINATION_SUCCESS:
      return saveAndConfirmCssdTerminationSuccessReducer(state);
    case SAVE_AND_CONFIRM_CSSD_TERMINATION_FAILURE:
      return saveAndConfirmCssdTerminationFailureReducer(state);

    case CONFIRM_CSSD_TERMINATION_REQUEST:
      return confirmCssdTerminationRequestReducer(state);
    case CONFIRM_CSSD_TERMINATION_SUCCESS:
      return confirmCssdTerminationSuccessReducer(state);
    case CONFIRM_CSSD_TERMINATION_FAILURE:
      return confirmCssdTerminationFailureReducer(state);

    case CANCEL_CSSD_TERMINATION_REQUEST:
      return cancelCssdTerminationRequestReducer(state);
    case CANCEL_CSSD_TERMINATION_SUCCESS:
      return cancelCssdTerminationSuccessReducer(state);
    case CANCEL_CSSD_TERMINATION_FAILURE:
      return cancelCssdTerminationFailureReducer(state);

    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST:
      return saveAndConfirmPartialClaimTerminationRequestReducer(state, action);
    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS:
      return saveAndConfirmPartialClaimTerminationSuccessReducer(state);
    case SAVE_AND_CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE:
      return saveAndConfirmPartialClaimTerminationFailureReducer(state);

    case CONFIRM_PARTIAL_CLAIM_TERMINATION_REQUEST:
      return confirmPartialClaimTerminationRequestReducer(state);
    case CONFIRM_PARTIAL_CLAIM_TERMINATION_SUCCESS:
      return confirmPartialClaimTerminationSuccessReducer(state);
    case CONFIRM_PARTIAL_CLAIM_TERMINATION_FAILURE:
      return confirmPartialClaimTerminationFailureReducer(state);

    case CANCEL_PARTIAL_CLAIM_TERMINATION_REQUEST:
      return cancelPartialClaimTerminationRequestReducer(state);
    case CANCEL_PARTIAL_CLAIM_TERMINATION_SUCCESS:
      return cancelPartialClaimTerminationSuccessReducer(state);
    case CANCEL_PARTIAL_CLAIM_TERMINATION_FAILURE:
      return cancelPartialClaimTerminationFailureReducer(state);

    case OPEN_CONFIRM_REVOKE_DIALOG:
      return openConfirmRevokeDialogReducer(state, action);
    case CLOSE_CONFIRM_REVOKE_DIALOG:
      return closeConfirmRevokeDialogReducer(state);

    case OFFER_REVOKE_REQUEST:
      return offerRevokeRequestReducer(state);
    case OFFER_REVOKE_SUCCESS:
      return offerRevokeSuccessReducer(state);
    case OFFER_REVOKE_FAILURE:
      return offerRevokeFailureReducer(state);

    case CANCEL_REVOKE_REQUEST:
      return cancelRevokeRequestReducer(state);
    case CANCEL_REVOKE_SUCCESS:
      return cancelRevokeSuccessReducer(state);
    case CANCEL_REVOKE_FAILURE:
      return cancelRevokeFailureReducer(state);

    case ACCEPT_REVOKE_REQUEST:
      return acceptRevokeRequestReducer(state);
    case ACCEPT_REVOKE_SUCCESS:
      return acceptRevokeSuccessReducer(state);
    case ACCEPT_REVOKE_FAILURE:
      return acceptRevokeFailureReducer(state);

    default:
      return state;
  }
}
