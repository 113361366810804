/* eslint-disable no-console */
import { take, takeLatest, call, fork, put, all, cancel, select } from "redux-saga/effects";
import {
  checkIfIsAllowedToSeeTransaction,
  getSsdEventsFromBc,
  toUtf8,
  toHex,
  getSsdState,
  getFirstZessionarId,
  getTerminationState,
  getFourEyesState,
  getBlockNumber,
  getIdProxyAddr,
  ssdAuditEvents,
} from "services/web3Services/commons";
import { getCessionCount, getSSDCessionData } from "services/web3Services/cession";
import {
  listenForSSDEvents,
  listenForSSDPaymentEvents,
  listenForFourEyesEvents,
  listenForProviderDisconnect,
  listenForSSDRebuyEvents,
  listenForTerminationEvents,
} from "services/web3Services/eventListener";
import {
  exportBssdActions,
  FETCH_SSD_EVENTS_REQUEST,
  ADD_SSD_FROM_EVENT,
  FETCH_SSD_PREVIEW,
  FETCH_PAYMENT_PREVIEW,
  PROVIDER_CONNECTED,
  PDF_DOWNLOAD,
  TRADE_CONFIRMATION_DOWNLOAD,
  REBUY_CONFIRMATION_DOWNLOAD,
  TERMINATION_CONFIRMATION_DOWNLOAD,
  STOP_SSD_LISTENER,
  FETCH_SSD_EVENT_INFO_BULK_RETRY,
  EXPORT_BSSD_CSV,
  ON_START_EVENT_LISTENER,
  ON_LOAD_BSSDS,
  ON_START_MIGRATION,
} from "./actions";
import {
  ssdEventActions,
  updateIdProxyAddrActions,
  updatePreviewActions,
  bssdOverviewActions,
} from "./actions";
import {
  fetchSSDDetails,
  fetchSSDDetailsBulk,
  fetchRejectReason,
  fetchRebuyRejectReason,
  handleFileDownload,
  handleTradeConfirmationDownload,
  handleRebuyConfirmationDownload,
  handleTerminationConfirmationDownload,
  getBssds,
  getMigration,
  postBssds,
} from "services/ssdService";
import { SSDActions, SSDStateType } from "util/constants";
import { addDotsComma } from "util/convertor";
import { downloadByteArrayAsCSV, generateBssdCSV } from "util/export";
import { snackbarActions } from "redux/shared/actions";
import { appIntl } from "components/i18n/intl";
import { exportMessages } from "components/Shared/Export/messages";
import { getNameForAddr } from "services/partnerService";
import { getCompanyInfo, getCompanyAddress } from "redux/selectors";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const ssdEventSagas = [
  takeLatest(FETCH_SSD_EVENTS_REQUEST, fetchSsdEvents),
  takeLatest(FETCH_SSD_EVENT_INFO_BULK_RETRY, retryFetchSsdBulkSaga),
  takeLatest(ADD_SSD_FROM_EVENT, addSsdFromEvent),
  takeLatest(FETCH_SSD_PREVIEW, fetchSsdPreviewData),
  takeLatest(FETCH_PAYMENT_PREVIEW, fetchPaymentPreviewData),
  takeLatest(PDF_DOWNLOAD, getPdf),
  takeLatest(TRADE_CONFIRMATION_DOWNLOAD, downloadTradeConfirmation),
  takeLatest(REBUY_CONFIRMATION_DOWNLOAD, downloadRebuyConfirmation),
  takeLatest(TERMINATION_CONFIRMATION_DOWNLOAD, downloadTerminationConfirmation),
  takeLatest(EXPORT_BSSD_CSV, exportCSV),
  takeLatest(ON_START_EVENT_LISTENER, onStartEventListener),
  takeLatest(ON_LOAD_BSSDS, onLoadBssds),
  takeLatest(ON_START_MIGRATION, onStartMigration),
];

export function* downloadTradeConfirmation({ id, fingerprint, fingerprint_rebuy, arranger }) {
  try {
    yield call(handleTradeConfirmationDownload, id, fingerprint, fingerprint_rebuy, arranger);
  } catch (error) {
    console.error(error);
  }
}

export function* downloadRebuyConfirmation({ id, fingerprint, fingerprint_rebuy, arranger }) {
  try {
    yield call(handleRebuyConfirmationDownload, id, fingerprint, fingerprint_rebuy, arranger);
  } catch (error) {
    console.error(error);
  }
}

export function* downloadTerminationConfirmation({ ssdInfo }) {
  try {
    yield call(handleTerminationConfirmationDownload, ssdInfo);
  } catch (error) {
    console.error(error);
  }
}

export function* getPdf({
  id,
  fingerprint,
  fingerprint_rebuy,
  arranger,
  withWatermark,
  created_by_cession,
  pdfName,
  companyIdProxyAddress,
  externUrkunde,
}) {
  let downloadForZessionar = false;
  const currentSsdId = id;
  const currentSsdCessionInfo = yield call(getSSDCessionData, arranger, id);
  const currentSsd = yield call(getSsdState, arranger, id);
  const rootSsd = yield call(getSsdState, arranger, toUtf8(currentSsdCessionInfo.rootId));
  const parentSsd = yield call(getSsdState, arranger, toUtf8(currentSsdCessionInfo.parentId));
  const parentSsdCessionInfo = yield call(
    getSSDCessionData,
    arranger,
    toUtf8(currentSsdCessionInfo.parentId)
  );
  const zessionarId = yield call(
    getFirstZessionarId,
    arranger,
    toUtf8(currentSsdCessionInfo.parentId)
  );
  const zessionarSsd = yield call(getSsdState, arranger, toUtf8(zessionarId));
  const restSsd = yield call(getSsdState, arranger, toUtf8(parentSsdCessionInfo.restId));

  if (created_by_cession) {
    id = toUtf8(currentSsdCessionInfo.rootId);
    fingerprint = rootSsd.hash;
    fingerprint_rebuy = rootSsd.rebuyHash;
    withWatermark = false;
  }

  if (
    created_by_cession &&
    parentSsd.seller === currentSsd.seller &&
    companyIdProxyAddress !== parentSsd.buyer &&
    companyIdProxyAddress === currentSsd.buyer
  ) {
    downloadForZessionar = true;
  }

  yield call(
    handleFileDownload,
    id,
    fingerprint,
    fingerprint_rebuy,
    Number(toUtf8(currentSsdCessionInfo.rootId)),
    rootSsd.hash,
    rootSsd.rebuyHash,
    Number(toUtf8(currentSsdCessionInfo.parentId)),
    parentSsd.hash,
    parentSsd.rebuyHash,
    Number(toUtf8(parentSsdCessionInfo.restId)),
    restSsd.hash,
    restSsd.rebuyHash,
    Number(toUtf8(zessionarId)),
    zessionarSsd.hash,
    zessionarSsd.rebuyHash,
    arranger,
    withWatermark,
    pdfName,
    downloadForZessionar,
    currentSsdId,
    externUrkunde
  );
}

export function* startListener(companyAddr) {
  const currentBlock = yield call(getBlockNumber);
  const listener = [];
  listener.push(yield fork(providerConnectionListener, companyAddr));
  listener.push(yield fork(ssdEventListener, currentBlock));
  listener.push(yield fork(ssdPaymentEventListener, currentBlock));
  listener.push(yield fork(fourEyesEventListener, currentBlock));
  listener.push(yield fork(ssdRebuyEventListener, currentBlock));
  listener.push(yield fork(ssdTerminationEventListener, currentBlock));
  yield take(STOP_SSD_LISTENER);
  yield all(listener.map((channel) => cancel(channel)));
}

function* fetchPaymentPreviewData({ ssdStatus, modalActions, rebuyFingerprint }) {
  try {
    const ssdDetails = yield call(
      fetchSSDDetails,
      ssdStatus.ssdid,
      ssdStatus.fingerprint,
      ssdStatus.rebuyHash,
      ssdStatus.arranger
    );

    const rejectReason = yield call(
      fetchRebuyRejectReason,
      ssdStatus.ssdid,
      ssdStatus.fingerprint,
      rebuyFingerprint,
      ssdStatus.arranger
    );

    yield put(
      updatePreviewActions.updatePreviewInStore(ssdDetails.data, rejectReason.data, ssdStatus)
    );
    //show passed modal
    yield put(modalActions.open());
  } catch (error) {
    console.error(error);
  }
}

function* fetchSsdPreviewData({ ssdStatus, modalActions }) {
  try {
    const ssdDetails = yield call(
      fetchSSDDetails,
      ssdStatus.ssdid,
      ssdStatus.fingerprint,
      ssdStatus.rebuyHash,
      ssdStatus.arranger
    );

    const rejectReason = yield call(
      fetchRejectReason,
      ssdStatus.ssdid,
      ssdStatus.fingerprint,
      ssdStatus.arranger
    );

    yield put(
      updatePreviewActions.updatePreviewInStore(ssdDetails.data, rejectReason.data, ssdStatus)
    );
    //show passed modal
    yield put(modalActions.open());
  } catch (error) {
    console.error(error);
  }
}

function* addSsdFromEvent({ arranger, id }) {
  try {
    yield call(updateSsdDetails, arranger, toUtf8(id));
  } catch (error) {
    console.error(error);
  }
}

export function* ssdEventListener(currentBlock) {
  try {
    const chan = yield call(listenForSSDEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield fork(updateSsdDetails, result.arranger, toUtf8(result.id));
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* ssdRebuyEventListener(currentBlock) {
  try {
    const chan = yield call(listenForSSDRebuyEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield fork(updateSsdDetails, result.arranger, toUtf8(result.id));
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* ssdPaymentEventListener(currentBlock) {
  try {
    const chan = yield call(listenForSSDPaymentEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* ssdTerminationEventListener(currentBlock) {
  try {
    const chan = yield call(listenForTerminationEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fourEyesEventListener(currentBlock) {
  try {
    const chan = yield call(listenForFourEyesEvents, currentBlock);
    while (true) {
      let result = yield take(chan);
      yield fork(updateSsdDetails, result.arranger, toUtf8(result.id));
      yield put(result);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* providerConnectionListener(companyAddr) {
  try {
    const chan = yield call(listenForProviderDisconnect);
    while (true) {
      let result = yield take(chan);
      if (result.type === PROVIDER_CONNECTED) {
        yield call(fetchSsdEvents, { companyAddr });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* retryFetchSsdBulkSaga({ loadErrors, arranger }) {
  const processedErrors = yield call(processSsdErrorsForRetry, loadErrors, arranger);
  yield call(ssdBulkRequest, processedErrors);
}

export function* fetchBundleDetails(bundle) {
  yield put(bssdOverviewActions.setIsLoading(true));
  const arranger = bundle[0].arranger;
  //---------------------------------call BE--------------------------------------------
  //get all bssds from 1 BE (old endpoint)
  //const t0 = performance.now();
  const response = yield call(fetchSSDDetailsBulk, arranger, bundle);
  if (!(response.status >= 200 && response.status < 300)) {
    const ids = bundle.map(({ ssdId }) => ssdId);
    yield put(bssdOverviewActions.setIsLoading(false));
    return yield put(ssdEventActions.infoBulkFailure(ids, arranger));
  }
  //const t1 = performance.now();
  //---------------------------------format all bssds numbers--------------------------------------------
  const ssdDataFormated = response.data.ssdResponses.map((ssdData) => ({
    data: {
      ...ssdData,
      nominal: addDotsComma(ssdData.nominal),
      kurs: addDotsComma(ssdData.kurs),
      zinssatz: addDotsComma(ssdData.zinssatz),
      kaufpreis: addDotsComma(ssdData.kaufpreis),
      mindestbetrag_bei_abtretung: addDotsComma(ssdData.mindestbetrag_bei_abtretung),
      abschlag: addDotsComma(ssdData.abschlag),
      aufschlag: addDotsComma(ssdData.aufschlag),
      maximumzinssatz:
        ssdData.maximumzinssatz === null ? "" : addDotsComma(ssdData.maximumzinssatz),
      minimumzinssatz:
        ssdData.minimumzinssatz === null ? "" : addDotsComma(ssdData.minimumzinssatz),
      faktor: ssdData.faktor === null ? "" : addDotsComma(ssdData.faktor),
      kuendigungTermine: ssdData.kuendigungTermine.map((entry) => ({
        ...entry,
        kuendigungskurs: addDotsComma(entry.kuendigungskurs),
      })),
      ruecknahmekurs: addDotsComma(ssdData.ruecknahmekurs),
      ruecknahmebetrag: addDotsComma(ssdData.ruecknahmebetrag),
      aufgelaufene_stueckzinsen: addDotsComma(ssdData.aufgelaufene_stueckzinsen),
    },
  }));
  //---------------------------------REMOVE---------------------------------------------------------------
  for (const ssdInfo of ssdDataFormated)
    yield call(updateSsdDetails, ssdInfo.data.darlehensnehmer, `${ssdInfo.data.ssdid}`, ssdInfo);
  //---------------------------------REMOVE---------------------------------------------------------------
  const requestedIds = bundle.map(({ ssdId }) => ssdId);
  const fetchedIds = ssdDataFormated.map(({ data }) => `${data.ssdid}`);
  const missingIds = requestedIds.filter((id) => !fetchedIds.includes(id));
  yield put(bssdOverviewActions.setIsLoading(false));
  if (missingIds.length > 0) yield put(ssdEventActions.infoBulkFailure(missingIds, arranger));
}

export function* onStartEventListener({ companyAddr }) {
  try {
    const idProxyAddr = yield call(getIdProxyAddr);
    yield put(updateIdProxyAddrActions.update(idProxyAddr));
    yield call(startListener, companyAddr);
  } catch (e) {
    yield put(snackbarActions.openError(e.message));
  }
}

// function to load BSSD overview
export function* onLoadBssds() {
  try {
    // 1) load bssds list from current BE
    const t0 = performance.now();
    const companyAddress = yield select(getCompanyAddress);
    const {
      data: { ssdUebersichtResponses: bssdsResponse },
    } = yield call(getBssds, companyAddress);
    const t1 = performance.now();
    console.log(
      `performance: received ${bssdsResponse.length} BSSDs from BE in: ${Math.round(t1 - t0)}ms`
    );

    // 2) show bssds from current BE
    const t2 = performance.now();
    const bssdsGroupBySeller = bssdsResponse.reduce((obj, bssd) => {
      (obj[bssd.darlehensnehmer] = obj[bssd.darlehensnehmer] ?? []).push({
        ...bssd,
        terminationState: `${bssd.terminationState}`,
        state: `${bssd.state}`,
        ssdCessionState: `${bssd.ssdCessionState}`,
        rebuyState: `${bssd.rebuyState}`,
        paymentState: `${bssd.paymentState}`,
        confirmationState: `${bssd.confirmationState}`,
        terminationType: `${bssd.terminationType}`,
        ssdid: `${bssd.ssdid}`,
        ssdId: bssd.ssdid,
        cessionCount: `${bssd.cessionCount}`,
        cessionActiveCount: `${bssd.cessionActiveCount}`,
        rebuyMaturityDate: `${bssd.rebuyMaturityDate}`,
        maturityDate: `${bssd.maturityDate}`,
        terminationDate: `${bssd.terminationDate}`,
        valueDate: `${bssd.valueDate}`,
        arranger: bssd.darlehensnehmer,
        fingerprint_rebuy: bssd.rebuyHash,
        nominal: addDotsComma(bssd.nominal),
        zinssatz: addDotsComma(bssd.zinssatz),
      });
      return obj;
    }, {});
    console.log(bssdsGroupBySeller);
    if (bssdsGroupBySeller[companyAddress]) {
      console.log(`info: setBssds from company ${companyAddress}`);
      yield put(bssdOverviewActions.setBssds(bssdsGroupBySeller[companyAddress]));
    }
    const t3 = performance.now();
    console.log(`performance: bssdsGroupBySeller + setBssds: ${Math.round(t3 - t2)}ms`);

    // 3) call other BEs
    const t4 = performance.now();
    yield all(
      Object.entries(bssdsGroupBySeller).map(([seller, bssds]) =>
        call(postBssdsParallel, seller, bssds, companyAddress)
      )
    );
    const t5 = performance.now();
    console.log(
      `performance: loading BSSDs from ${
        Object.entries(bssdsGroupBySeller).length - (bssdsGroupBySeller[companyAddress] ? 1 : 0)
      } BEs parallel: ${Math.round(t5 - t4)}ms`
    );
    console.log(`performance: total time: ${Math.round(t5 - t0)}ms`);
  } catch (e) {
    yield put(snackbarActions.openError(e.message));
  }
}

// function to load bssds from different BE parallel
export function* postBssdsParallel(seller, bssds, companyAddress) {
  if (seller === companyAddress) return;
  const requestBody = bssds.map(({ arranger, fingerprint, fingerprint_rebuy, ssdId }) => ({
    arranger,
    fingerprint,
    fingerprint_rebuy,
    ssdId,
  }));
  const {
    data: { ssdUebersichtResponses: bssdsResponse },
  } = yield call(postBssds, seller, requestBody);
  const mergedBssds = bssds.map((bssd) => {
    const bssdResponse = bssdsResponse.find(({ ssdid }) => ssdid === bssd.ssdId);
    return {
      ...bssd,
      urkunden_registernummer: bssdResponse.urkunden_registernummer,
      interne_kennung_darlehensgeber: bssdResponse.interne_kennung_darlehensgeber,
      interne_kennung_darlehensnehmer: bssdResponse.interne_kennung_darlehensnehmer,
      vertragsreferenz_nr_darlehensgeber: bssdResponse.vertragsreferenz_nr_darlehensgeber,
      vertragsreferenz_nr_darlehensnehmer: bssdResponse.vertragsreferenz_nr_darlehensnehmer,
      darlehensgeber_name: bssdResponse.darlehensgeber_name,
      darlehensnehmer_name: bssdResponse.darlehensnehmer_name,
      handelstag: bssdResponse.handelstag,
      nominal: addDotsComma(bssdResponse.nominal),
      waehrung_nominal: bssdResponse.waehrung_nominal,
      zinssatz: addDotsComma(bssdResponse.zinssatz),
      valuta: bssdResponse.valuta,
      endfaelligkeit: bssdResponse.endfaelligkeit,
      finledger_urkunde_verwenden: bssdResponse.finledger_urkunde_verwenden,
      created_by_cession: bssdResponse.created_by_cession,
      abtretbarkeit: bssdResponse.abtretbarkeit,
      zins_fix: bssdResponse.zins_fix,
    };
  });
  console.log(`info: setBssds from seller ${seller}`);
  yield put(bssdOverviewActions.setBssds(mergedBssds));
}

// function to trigger migration script in BE
export function* onStartMigration() {
  try {
    const companyAddress = yield select(getCompanyAddress);
    yield call(getMigration, companyAddress);
  } catch (e) {
    yield put(snackbarActions.openError(e.message));
  }
}
//---------------------------------------------------------------------------------------------------------------
export function* updateSsdDetails(arranger, ssdid, detailsBE = null, isAllowed = false) {
  try {
    //const t0 = performance.now();
    const detailsBC = yield call(getSsdState, arranger, ssdid);

    if (!checkIfIsAllowedToSeeTransaction(arranger, detailsBC.buyer, detailsBC.state) && !isAllowed)
      return yield put(ssdEventActions.infoNoPermission(ssdid));

    const auditEvents = yield call(ssdAuditEvents, toHex(ssdid));
    const fourEyesState = yield call(getFourEyesState, arranger, ssdid, 0);
    const cessionCount = yield call(getCessionCount, arranger, ssdid);
    const cessionData = yield call(getSSDCessionData, arranger, ssdid);
    const terminationState = yield call(getTerminationState, arranger, ssdid);

    //format ssdData
    let ssdRejected = false;
    let rebuyRejected = false;

    if (auditEvents.length > 0) {
      const lastAuditEvent = auditEvents.pop().returnValues;
      ssdRejected =
        lastAuditEvent.stateType === SSDStateType.SSD &&
        lastAuditEvent.action === SSDActions.REJECT_OFFER;
      rebuyRejected =
        lastAuditEvent.stateType === SSDStateType.REBUY &&
        lastAuditEvent.action === SSDActions.REJECT_OFFER;
    }

    if (detailsBE === null)
      detailsBE = yield call(fetchSSDDetails, ssdid, detailsBC.hash, detailsBC.rebuyHash, arranger);

    const ssdDetails = { ...detailsBE.data };
    delete ssdDetails.fingerprint;

    const ssdData = {
      ssdDetails,
      arranger,
      ssdRejected,
      rebuyRejected,
      cessionCount,
      ssdid,

      fingerprint: detailsBC.hash,
      state: detailsBC.state,
      paymentState: detailsBC.paymentState,
      buyer: detailsBC.buyer,
      maturityDate: detailsBC.maturityDate,
      rebuyMaturityDate: detailsBC.rebuyMaturityDate,
      rebuyState: detailsBC.rebuyState,
      rebuyHash: detailsBC.rebuyHash,
      valueDate: detailsBC.valueDate,

      ssdCessionState: cessionData.cessionState,
      cessionActiveCount: cessionData.activeCessionCounter,
      restId: cessionData.restId,

      confirmationState: fourEyesState.state,
      firstConfirmer: fourEyesState.firstConfirmer,

      terminationState: terminationState.state,
      terminationType: terminationState.terminationType,
      terminationInitiator: terminationState.terminationInitiator,
      terminationHash: terminationState.hash,
      terminationDate: terminationState.terminationDate,
    };
    //const t1 = performance.now();
    yield put(ssdEventActions.infoSuccess(ssdData));
    return ssdData;
  } catch (error) {
    console.error(error);
    yield put(ssdEventActions.infoFailure(ssdid, arranger));
  }
}

export function* fetchSsdBulkInfo(ssdRequestBundle) {
  const numTotal = ssdRequestBundle.map((bundle) => bundle.length).reduce((a, b) => a + b, 0);
  yield put(ssdEventActions.setTotal(numTotal));
  yield all(ssdRequestBundle.map((bundle) => fork(fetchBundleDetails, bundle)));
}

const processSsdErrorsForRetry = (ssdErrorsForRetry, arrangerFilter) => {
  const processedErrors = [];

  Object.keys(ssdErrorsForRetry).forEach((arranger) => {
    ssdErrorsForRetry[arranger].forEach((ssdId) => {
      processedErrors.push({ id: ssdId, arranger });
    });
  });

  return arrangerFilter
    ? processedErrors.filter((item) => item.arranger === arrangerFilter)
    : processedErrors;
};

export function* ssdBulkRequest(bssdsEventData) {
  //const t0 = performance.now();
  let ssdRequestBundle = [];
  for (let item of bssdsEventData) {
    const state = yield call(getSsdState, item.arranger, item.id);
    if (ssdRequestBundle.length === 0) {
      ssdRequestBundle.push([
        {
          ssdId: item.id,
          fingerprint: state.hash,
          fingerprint_rebuy: state.rebuyHash,
          arranger: item.arranger,
        },
      ]);
    } else {
      let hit = false;
      for (let bundle of ssdRequestBundle) {
        if (item.arranger === bundle[0].arranger) {
          hit = true;
          bundle.push({
            ssdId: item.id,
            fingerprint: state.hash,
            fingerprint_rebuy: state.rebuyHash,
            arranger: item.arranger,
          });
        }
      }
      if (!hit) {
        ssdRequestBundle.push([
          {
            ssdId: item.id,
            fingerprint: state.hash,
            fingerprint_rebuy: state.rebuyHash,
            arranger: item.arranger,
          },
        ]);
      }
    }
  }
  //const t1 = performance.now();
  yield call(fetchSsdBulkInfo, ssdRequestBundle);
}

export function* fetchSsdEvents({ companyAddr }) {
  try {
    const ssdEvents = yield call(getSsdEventsFromBc, companyAddr);
    const ssdEventsAscii = [...ssdEvents].map((event) => {
      return {
        ...event,
        returnValues: {
          ...event.returnValues,
          id: toUtf8(event.returnValues.id),
        },
      };
    });
    const bssdsEventData = ssdEventsAscii.map(({ returnValues }) => ({
      id: returnValues.id,
      arranger: returnValues.arranger,
    }));
    yield call(ssdBulkRequest, bssdsEventData);

    //update idProxyAddr in Store so components can check if SSD arranger == idProxyAddr for logged in wallet
    const idProxyAddr = yield call(getIdProxyAddr);
    yield put(updateIdProxyAddrActions.update(idProxyAddr));

    //start eventlistener
    yield call(startListener, companyAddr);
  } catch (error) {
    console.error(error);
  }
}

export function* exportCSV({ bssds }) {
  try {
    const companyInfo = yield select(getCompanyInfo);
    const bssdsCompatibility = bssds.map((bssd) => ({
      ...bssd,
      darlehensnehmer_name:
        bssd.darlehensnehmer_name || getNameForAddr(bssd.darlehensnehmer, companyInfo),
      darlehensgeber_name:
        bssd.darlehensgeber_name || getNameForAddr(bssd.darlehensgeber, companyInfo),
    }));
    const bytes = yield generateBssdCSV(bssdsCompatibility);
    yield put(exportBssdActions.success());
    downloadByteArrayAsCSV("Export.csv", bytes);
  } catch (error) {
    yield put(exportBssdActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(exportMessages.export_failure)));
  }
}
