import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dashboard_main_list_items_home: {
    id: "dashboard.main.list.items.home",
    defaultMessage: "Home",
  },
  dashboard_main_list_items_bssd: {
    id: "dashboard.main.list.items.bssd",
    defaultMessage: "BSSD",
  },
  dashboard_main_list_items_uebersicht: {
    id: "dashboard.main.list.items.uebersicht",
    defaultMessage: "Übersicht",
  },
  dashboard_main_list_items_erfassung: {
    id: "dashboard.main.list.items.erfassung",
    defaultMessage: "Erfassung",
  },
  dashboard_main_list_items_abtretungsanfragen: {
    id: "dashboard.main.list.items.abtretungsanfragen",
    defaultMessage: "Abtretungsanfragen",
  },
  dashboard_main_list_items_uebertragungsanfragen: {
    id: "dashboard.main.list.items.uebertragungsanfragen",
    defaultMessage: "Übertragungsanfragen",
  },
  dashboard_main_list_items_cssd: {
    id: "dashboard.main.list.items.cssd",
    defaultMessage: "CSSD",
  },
  dashboard_main_list_items_teilforderungen: {
    id: "dashboard.main.list.items.teilforderungen",
    defaultMessage: "Teilforderungen",
  },
  dashboard_main_list_items_mitarbeiter: {
    id: "dashboard.main.list.items.mitarbeiter",
    defaultMessage: "Mitarbeiter",
  },
  dashboard_main_list_items_plattformmitglieder: {
    id: "dashboard.main.list.items.plattformmitglieder",
    defaultMessage: "Plattformmitglieder",
  },
  dashboard_main_list_items_geschaeftspartner: {
    id: "dashboard.main.list.items.geschaeftspartner",
    defaultMessage: "Geschäftspartner",
  },
  dashboard_main_list_items_recovery: {
    id: "dashboard.main.list.items.recovery",
    defaultMessage: "Recovery",
  },
  dashboard_main_list_items_mein_profil: {
    id: "dashboard.main.list.items.mein.profil",
    defaultMessage: "Mein Profil",
  },
  dashboard_main_list_items_nutzerinformationen: {
    id: "dashboard.main.list.items.nutzerinformationen",
    defaultMessage: "Nutzerinformationen",
  },
  dashboard_main_list_items_abmelden: {
    id: "dashboard.main.list.items.abmelden",
    defaultMessage: "Abmelden",
  },
  dashboard_main_list_items_tooltip_title_sprache_aendern: {
    id: "dashboard.dropdown.menu.tooltip.title.sprache.aendern",
    defaultMessage: "Sprache ändern",
  },
  dashboard_main_list_items_tooltip_aria_label_sprache_aendern: {
    id: "dashboard.dropdown.menu.tooltip.aria.label.sprache.aendern",
    defaultMessage: "Sprache ändern",
  },
});
