import { call, put, takeLatest } from "redux-saga/effects";
import {
  CONFIRM_TERMINATION_REQUEST,
  confirmTerminationActions,
  CREATE_TERMINATION_REQUEST,
  createTerminationActions,
  FETCH_KUENDIGUNG_REQUEST,
  fetchKuendigungActions,
  REJECT_TERMINIATION_REQUEST,
  OPEN_TERMINATAION_DIALOG_UPDATE,
  rejectTerminationActions,
  secondConfirmDialogActions,
  terminateSsdDialogActions,
} from "./actions";
import { snackbarActions } from "redux/shared/actions";
import { getKuendigung, postKuendigung } from "services/terminationService";
import {
  terminationApprove,
  terminationDelete,
  terminationModifyAndApprove,
} from "services/web3Services/terminate";
import { toHex, getAbortedCessionsIds, toUtf8 } from "services/web3Services/commons";
import { dateToTimestamp } from "util/convertor";
import { sendMail, sendGenericMail } from "services/mailService";
import { Kuendigungsrecht, mailTemplates, ZERO_HASH } from "util/constants";
import { checkIfCessionWasApproved } from "services/web3Services/cession";
import { handleTerminationConfirmation } from "services/ssdService";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generateSsdTerminationFingerprint } from "util/fingerprint";
import { updateSsdDetails } from "redux/ssdEvents/sagas";
import { removeDots } from "util/convertor";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const terminationSagas = [
  takeLatest(CREATE_TERMINATION_REQUEST, createTermination),
  takeLatest(FETCH_KUENDIGUNG_REQUEST, fetchKuendigung),
  takeLatest(CONFIRM_TERMINATION_REQUEST, confirmTermination),
  takeLatest(REJECT_TERMINIATION_REQUEST, rejectTermination),
  takeLatest(OPEN_TERMINATAION_DIALOG_UPDATE, updateBssd),
];

export function* updateBssd({ ssdInfo: { arranger, ssdid } }) {
  try {
    const ssdData = yield call(updateSsdDetails, arranger, ssdid, null, true);
    const ssdInfoNew = {
      ...ssdData,
      ...ssdData.ssdDetails,
      zinssatz: removeDots(ssdData.ssdDetails.zinssatz),
      nominal: removeDots(ssdData.ssdDetails.nominal),
      ssdid,
    };
    delete ssdInfoNew.ssdDetails;
    yield put(terminateSsdDialogActions.open(ssdInfoNew));
  } catch (error) {
    console.error(error);
  }
}

function* confirmTermination({
  account,
  arranger,
  ssdId,
  ssdHash,
  kuendigung,
  ssdToTerminate,
  recipient,
}) {
  try {
    yield call(
      terminationApprove,
      account,
      arranger,
      toHex(ssdId),
      ssdHash,
      kuendigung.fingerprint
    );

    yield call(sendMail, {
      template: mailTemplates.kuendigung,
      arranger: arranger,
      ssdid: ssdId,
      fingerprint: ssdHash,
      fingerprint_rebuy: ZERO_HASH,
      recipient: recipient,
      kuendigungFingerprint: kuendigung.fingerprint,
    });

    yield call(handleTerminationConfirmation, ssdToTerminate);

    const abortedCessions = yield call(getAbortedCessionsIds, arranger, ssdId);
    if (abortedCessions.length > 0) {
      for (const entry of abortedCessions) {
        if (entry.hash !== ZERO_HASH) {
          const result = yield call(checkIfCessionWasApproved, entry.id);

          yield call(sendGenericMail, {
            template: result
              ? mailTemplates.abtretung_abgebrochen_zessionar
              : mailTemplates.abtretung_abgebrochen_zedent,
            sender: ssdToTerminate.darlehensgeber,
            geschaeft: ssdId,
            abtretungFingerprint: entry.hash,
            abtretungId: toUtf8(entry.id),
            urkundennummer: ssdToTerminate.urkunden_registernummer,
          });
        }
      }
    }

    yield put(confirmTerminationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_termination_kuendigung_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(confirmTerminationActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_termination_kuendigung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

function* rejectTermination({ account, arranger, ssdId, ssdHash, kuendigung }) {
  try {
    yield call(terminationDelete, account, arranger, toHex(ssdId), ssdHash, kuendigung.fingerprint);
    yield put(rejectTerminationActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_termination_kuendigung_wurde_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectTerminationActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_termination_kuendigung_konnte_nicht_abgelehnt_werden)
      )
    );
  }
}

function* fetchKuendigung({ ssdInfo }) {
  try {
    const kuendigung = yield call(
      getKuendigung,
      ssdInfo.arranger,
      ssdInfo.ssdid,
      ssdInfo.terminationHash
    );
    yield put(secondConfirmDialogActions.open(ssdInfo, kuendigung));
  } catch (error) {
    console.error(error);
    yield put(fetchKuendigungActions.failure());
  }
}

function terminationTypeStringToNumber(string) {
  let typ = 0;
  if (string === Kuendigungsrecht.ORDENTLICH) {
    typ = 0;
  }
  if (string === Kuendigungsrecht.AUSSERORDENTLICH) {
    typ = 1;
  }
  return typ;
}

function compareMaturityDate(kuendigungsvaluta, maturityDate) {
  const terminationDate = new Date(
    kuendigungsvaluta.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")
  ).getTime();
  const ssdMaturityDate = new Date(Number(maturityDate) * 1000).getTime();
  return terminationDate < ssdMaturityDate;
}

function* createTermination({
  account,
  arranger,
  ssdid,
  ssdHash,
  kuendigung,
  ssdToTerminate,
  recipient,
  fourEyes,
}) {
  try {
    if (compareMaturityDate(kuendigung.kuendigungsvaluta, ssdToTerminate.maturityDate)) {
      const data = yield generateSsdTerminationFingerprint(kuendigung);
      const { fingerprint } = data;

      yield call(postKuendigung, arranger, ssdid, data);

      yield call(
        terminationModifyAndApprove,
        account,
        arranger,
        toHex(ssdid),
        ssdHash,
        fingerprint,
        terminationTypeStringToNumber(kuendigung.kuendigungsrecht),
        dateToTimestamp(kuendigung.kuendigungsvaluta)
      );

      if (!fourEyes) {
        yield call(sendMail, {
          template: mailTemplates.kuendigung,
          arranger: arranger,
          ssdid: ssdid,
          fingerprint: ssdHash,
          fingerprint_rebuy: ZERO_HASH,
          recipient: recipient,
          kuendigungFingerprint: fingerprint,
        });

        ssdToTerminate.terminationHash = fingerprint;
        ssdToTerminate.terminationInitiator = account.address;
        yield call(handleTerminationConfirmation, ssdToTerminate);

        const abortedCessions = yield call(getAbortedCessionsIds, arranger, ssdid);
        if (abortedCessions.length > 0) {
          for (const entry of abortedCessions) {
            if (entry.hash !== ZERO_HASH) {
              yield call(sendGenericMail, {
                template: checkIfCessionWasApproved(entry.id)
                  ? mailTemplates.abtretung_abgebrochen_zessionar
                  : mailTemplates.abtretung_abgebrochen_zendent,
                sender: ssdToTerminate.darlehensgeber,
                geschaeft: ssdid,
                abtretungFingerprint: entry.hash,
                abtretungId: toUtf8(entry.id),
                urkundennummer: ssdToTerminate.urkunden_registernummer,
              });
            }
          }
        }
      }

      if (!fourEyes) {
        yield put(
          snackbarActions.openSuccess(
            appIntl().formatMessage(messages.redux_termination_kuendigung_wurde_bestaetigt)
          )
        );
      } else {
        yield put(
          snackbarActions.openSuccess(
            appIntl().formatMessage(messages.redux_termination_kuendigung_wurde_erfasst)
          )
        );
      }

      yield put(createTerminationActions.success());
    } else {
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(
            messages.redux_termination_kuendigungsvaluta_muss_kleiner_als_die_valuta_des_ssds_sein
          )
        )
      );
      yield put(createTerminationActions.failure());
    }
  } catch (error) {
    console.error(error);
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_termination_kuendigung_konnte_nicht_erfasst_werden)
      )
    );
    yield put(createTerminationActions.failure(error));
  }
}
