import React from "react";
import { connect } from "react-redux";
import history from "util/history";
import { formatDate } from "util/convertor";
import { Grid, Typography, useTheme, withStyles } from "@material-ui/core";
import { verificationHeader, verificationText } from "./verificationText";

import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import BackButton from "components/Shared/Button/BackButton";
import { getVerificationColor, VerificationType } from "../verificationColors";

export const pdf_verification_styles = (theme) => ({
  panel: {
    padding: theme.spacing(2),
    paddingTop: 15,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "50%",
    marginTop: 50,
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },
});

const PdfVerification = ({ pdfStatus, classes }) => {
  const theme = useTheme();

  const handleBack = () => {
    history.push("/login");
  };

  const getMessage = (
    isSigned,
    isMatured,
    isRootSsd,
    isCeded,
    isRebought,
    isRepaid,
    rebuyDate,
    isTerminated,
    terminationDate
  ) => {
    const terminationDateFormatted = formatDate(new Date(terminationDate * 1000));

    if (isSigned) {
      if (isMatured && !isRebought) {
        return {
          title: verificationHeader.valid(),
          body: verificationText.K(),
          verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
        };
      }
      if (isRootSsd) {
        if (isCeded) {
          if (isTerminated) {
            return {
              title: verificationHeader.valid(),
              body: verificationText.H(terminationDateFormatted),
              verificationColor: getVerificationColor(theme, VerificationType.VALID),
            };
          }
          return {
            title: verificationHeader.valid(),
            body: verificationText.A(),
            verificationColor: getVerificationColor(theme, VerificationType.VALID),
          };
        } else {
          if (isRebought) {
            if (isRepaid && new Date(rebuyDate * 1000) < new Date()) {
              return {
                title: verificationHeader.valid(),
                body: verificationText.E(),
                verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
              };
            } else {
              return {
                title: verificationHeader.valid(),
                body: verificationText.G(),
                verificationColor: getVerificationColor(theme, VerificationType.VALID),
              };
            }
          }
          if (isTerminated) {
            if (isRepaid && new Date(terminationDate * 1000) < new Date()) {
              return {
                title: verificationHeader.valid(),
                body: verificationText.E(),
                verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
              };
            } else {
              return {
                title: verificationHeader.valid(),
                body: verificationText.H(terminationDateFormatted),
                verificationColor: getVerificationColor(theme, VerificationType.VALID),
              };
            }
          }
          return {
            title: verificationHeader.valid(),
            body: verificationText.G(),
            verificationColor: getVerificationColor(theme, VerificationType.VALID),
          };
        }
      } else {
        if (isCeded) {
          return {
            title: verificationHeader.valid(),
            body: verificationText.J(),
            verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
          };
        }
        if (isRebought) {
          if (isRepaid && new Date(rebuyDate * 1000) < new Date()) {
            return {
              title: verificationHeader.valid(),
              body: verificationText.C(),
              verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
            };
          } else {
            return {
              title: verificationHeader.valid(),
              body: verificationText.F(),
              verificationColor: getVerificationColor(theme, VerificationType.VALID),
            };
          }
        }
        if (isTerminated) {
          if (isRepaid && new Date(terminationDate * 1000) < new Date()) {
            return {
              title: verificationHeader.valid(),
              body: verificationText.C(),
              verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
            };
          } else {
            return {
              title: verificationHeader.valid(),
              body: verificationText.I(terminationDateFormatted),
              verificationColor: getVerificationColor(theme, VerificationType.VALID),
            };
          }
        }
        return {
          title: verificationHeader.valid(),
          body: verificationText.F(),
          verificationColor: getVerificationColor(theme, VerificationType.VALID),
        };
      }
    } else {
      return {
        title: verificationHeader.notValid(),
        body: verificationText.L(),
        verificationColor: getVerificationColor(theme, VerificationType.NOT_VALID),
      };
    }
  };

  const {
    isSigned,
    isMatured,
    isRootSsd,
    isCeded,
    isRebought,
    isRepaid,
    rebuyDate,
    isTerminated,
    terminationDate,
  } = pdfStatus;

  const message = getMessage(
    isSigned,
    isMatured,
    isRootSsd,
    isCeded,
    isRebought,
    isRepaid,
    rebuyDate,
    isTerminated,
    terminationDate
  );

  return (
    <Grid
      container
      spacing={3}
      className={classes.panel}
      style={{
        backgroundColor: message.verificationColor.bgColor,
        borderColor: message.verificationColor.borderColor,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: message.verificationColor.color }}>
          {message.title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: message.verificationColor.color,
            lineHeight: 1.7,
            marginTop: 15,
          }}
        >
          {message.body}
        </Typography>
        <br />
        <BackButton onClick={handleBack} color={message.verificationColor.buttonColor}>
          <FormattedMessage {...messages.pdf_verification_button_text_zurueck} />
        </BackButton>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    pdfStatus: state.pdfVerificationReducer.pdfStatus,
  };
};

export default withStyles(pdf_verification_styles)(connect(mapStateToProps, {})(PdfVerification));
