export const LOAD_TEILFORDERUNGEN_REQUEST = "LOAD_TEILFORDERUNGEN_REQUEST";
export const LOAD_TEILFORDERUNGEN_INCREASE_TOTAL = "LOAD_TEILFORDERUNGEN_INCREASE_TOTAL";
export const LOAD_TEILFORDERUNGEN_SET_PENDING = "LOAD_TEILFORDERUNGEN_SET_PENDING";
export const LOAD_TEILFORDERUNGEN_SUCCESS = "LOAD_TEILFORDERUNGEN_SUCCESS";
export const LOAD_TEILFORDERUNGEN_FAILURE = "LOAD_TEILFORDERUNGEN_FAILURE";
export const LOAD_TEILFORDERUNGEN_FINISHED = "LOAD_TEILFORDERUNGEN_FINISHED";
export const LOAD_TEILFORDERUNGEN_REFRESH_SORT = "LOAD_TEILFORDERUNGEN_REFRESH_SORT";
export const LOAD_TEILFORDERUNGEN_RETRY = "LOAD_TEILFORDERUNGEN_RETRY";
export const STORE_LAST_BLOCK_NUMBER_PARTIAL_CLAIM = "STORE_LAST_BLOCK_NUMBER_PARTIAL_CLAIM";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const fetchPartialClaimsBulkActions = {
  request: (lastBlockNumber, loadedClaims) =>
    action(LOAD_TEILFORDERUNGEN_REQUEST, { lastBlockNumber, loadedClaims }),
  storeLastBlockNumber: (lastBlockFromChain) =>
    action(STORE_LAST_BLOCK_NUMBER_PARTIAL_CLAIM, { lastBlockFromChain }),
  increaseTotal: (numPartialClaims) =>
    action(LOAD_TEILFORDERUNGEN_INCREASE_TOTAL, { numPartialClaims }),
  setPending: (partialClaimId) => action(LOAD_TEILFORDERUNGEN_SET_PENDING, { partialClaimId }),
  finished: (partialClaimId) => action(LOAD_TEILFORDERUNGEN_FINISHED, { partialClaimId }),
  success: (payingAgent, teilforderungen, missingIds, unauthorizedIds) =>
    action(LOAD_TEILFORDERUNGEN_SUCCESS, {
      payingAgent,
      teilforderungen,
      missingIds,
      unauthorizedIds,
    }),
  failure: (payingAgent, ids) => action(LOAD_TEILFORDERUNGEN_FAILURE, { payingAgent, ids }),
  refreshSort: () => action(LOAD_TEILFORDERUNGEN_REFRESH_SORT),
  retry: (loadErrors, arranger) => action(LOAD_TEILFORDERUNGEN_RETRY, { loadErrors, arranger }),
};

export const OPEN_PARTIAL_CLAIMS_FOR_CSSD = "OPEN_PARTIAL_CLAIMS_FOR_CSSD";

export const openPartialClaimsForCssdAction = (cssdRef) =>
  action(OPEN_PARTIAL_CLAIMS_FOR_CSSD, { cssdRef });

export const FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST = "FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST";
export const FETCH_PARTIAL_CLAIMS_FOR_CSSD_SUCCESS = "FETCH_PARTIAL_CLAIMS_FOR_CSSD_SUCCESS";
export const FETCH_PARTIAL_CLAIMS_FOR_CSSD_FAILURE = "FETCH_PARTIAL_CLAIMS_FOR_CSSD_FAILURE";

export const fetchPartialClaimsForCssdActions = {
  request: (cssdRef) => action(FETCH_PARTIAL_CLAIMS_FOR_CSSD_REQUEST, { cssdRef }),
  success: (data) => action(FETCH_PARTIAL_CLAIMS_FOR_CSSD_SUCCESS, data),
  failure: (error) => action(FETCH_PARTIAL_CLAIMS_FOR_CSSD_FAILURE, { error }),
};

export const LOAD_CSSD_TEILFORDERUNG_DETAIL = "LOAD_CSSD_TEILFORDERUNG_DETAIL";
export const OPEN_CSSD_TEILFORDERUNG_DETAIL = "OPEN_CSSD_TEILFORDERUNG_DETAIL";
export const OPEN_CSSD_TEILFORDERUNG_EDIT = "OPEN_CSSD_TEILFORDERUNG_EDIT";
export const LOAD_CSSD_TEILFORDERUNG_DETAIL_FAILURE = "LOAD_CSSD_TEILFORDERUNG_DETAIL_FAILURE";
export const CLOSE_CSSD_TEILFORDERUNG_DETAIL = "CLOSE_CSSD_TEILFORDERUNG_DETAIL";
export const CLOSE_CSSD_TEILFORDERUNG_EDIT = "CLOSE_CSSD_TEILFORDERUNG_EDIT";
export const detailsTeilforderung = {
  request: (arranger, teilforderung, edit) =>
    action(LOAD_CSSD_TEILFORDERUNG_DETAIL, { arranger, teilforderung, edit }),
  openDeatils: (teilforderungDetail) =>
    action(OPEN_CSSD_TEILFORDERUNG_DETAIL, { teilforderungDetail }),
  openEdit: (teilforderungDetail) => action(OPEN_CSSD_TEILFORDERUNG_EDIT, { teilforderungDetail }),
  failure: (error) => action(LOAD_CSSD_TEILFORDERUNG_DETAIL_FAILURE, { error }),
  closeDetails: () => action(CLOSE_CSSD_TEILFORDERUNG_DETAIL),
  closeEdit: () => action(CLOSE_CSSD_TEILFORDERUNG_EDIT),
};

export const FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE = "FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE";

export const filterPartialClaimForCssdTable = (filters) =>
  action(FILTER_PARTIAL_CLAIMS_FOR_CSSD_TABLE, { filters });

export const SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE = "SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE";

export const sortPartialClaimForCssdTable = (column) =>
  action(SORT_PARTIAL_CLAIMS_FOR_CSSD_TABLE, { column });

export const FETCH_CSSD_PARTIALCLAIM_SUM_REQUEST = "FETCH_CSSD_PARTIALCLAIM_SUM_REQUEST";
export const FETCH_CSSD_PARTIALCLAIM_SUM_SUCCESS = "FETCH_CSSD_PARTIALCLAIM_SUM_SUCCESS";
export const FETCH_CSSD_PARTIALCLAIM_SUM_FAILURE = "FETCH_CSSD_PARTIALCLAIM_SUM_FAILURE";

export const fetchSumOfAllPartialClaimsActions = {
  request: (cssd) => action(FETCH_CSSD_PARTIALCLAIM_SUM_REQUEST, { cssd }),
  success: (data) => action(FETCH_CSSD_PARTIALCLAIM_SUM_SUCCESS, data),
  failure: (error) => action(FETCH_CSSD_PARTIALCLAIM_SUM_FAILURE, { error }),
};

export const UPDATE_PARTIAL_CLAIM_FROM_EVENT = "UPDATE_PARTIAL_CLAIM_FROM_EVENT";

export const updatePartialClaimFromEventActions = {
  update: (update, onlyCached = false, updateKuendigungId) =>
    action(UPDATE_PARTIAL_CLAIM_FROM_EVENT, { update, onlyCached, updateKuendigungId }),
};

export const CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST =
  "CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST";
export const CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_SUCCESS =
  "CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_SUCCESS";
export const CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_FAILURE =
  "CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_FAILURE";

export const cssdPartialClaimRepaymentReceivedActions = {
  request: (partialClaim) =>
    action(CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_REQUEST, { partialClaim }),
  success: () => action(CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_SUCCESS),
  failure: (error) => action(CSSD_PARTIAL_CLAIM_REPAYMENT_RECEIVED_FAILURE, { error }),
};

export const OPEN_SETTLEMENTINFORMATION = "OPEN_SETTLEMENTINFORMATION";
export const CLOSE_SETTLEMENTINFORMAION = "CLOSE_SETTLEMENTINFORMAION";
export const SAVE_SETTLEMENTINFORMATION = "SAVE_SETTLEMENTINFORMATION";
export const SUCCESS_SAVE_SETTLEMENTINFORMATION = "SUCCESS_SAVE_SETTLEMENTINFORMATION";
export const FAILURE_SAVE_SETTLEMENTINFORMATION = "FAILURE_SAVE_SETTLEMENTINFORMATION";

export const editSettlementInformation = {
  openDialog: (partialClaim) => action(OPEN_SETTLEMENTINFORMATION, { partialClaim }),
  closeDialog: () => action(CLOSE_SETTLEMENTINFORMAION),
  save: (cession, partialClaim) => action(SAVE_SETTLEMENTINFORMATION, { cession, partialClaim }),
  success: () => action(SUCCESS_SAVE_SETTLEMENTINFORMATION),
  failure: () => action(FAILURE_SAVE_SETTLEMENTINFORMATION),
};

export const UPDATE_PARTIAL_CLAIM_TERMINATIONS = "UPDATE_PARTIAL_CLAIM_TERMINATIONS";
export const partialClaimTerminationActions = {
  update: (partialClaimId, terminations) =>
    action(UPDATE_PARTIAL_CLAIM_TERMINATIONS, { partialClaimId, terminations }),
};

export const CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING = "CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING";
export const CSSD_EXPORT_PARTIAL_CLAIM_PROCESSED = "CSSD_EXPORT_PARTIAL_CLAIM_PROCESSED";

export const cssdExportPartialClaim = {
  start: (partialClaims) => action(CSSD_EXPORT_PARTIAL_CLAIM_PROCESSING, { partialClaims }),
  end: () => action(CSSD_EXPORT_PARTIAL_CLAIM_PROCESSED),
};

export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST = "SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST";
export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_SUCCESS = "SAVE_AND_CONFIRM_PARTIAL_CLAIM_SUCCESS";
export const SAVE_AND_CONFIRM_PARTIAL_CLAIM_FAILURE = "SAVE_AND_CONFIRM_PARTIAL_CLAIM_FAILURE";
export const CANCEL_PARTIAL_CLAIM_REQUEST = "CANCEL_PARTIAL_CLAIM_REQUEST";
export const CANCEL_PARTIAL_CLAIM_SUCCESS = "CANCEL_PARTIAL_CLAIM_SUCCESS";
export const CANCEL_PARTIAL_CLAIM_FAILURE = "CANCEL_PARTIAL_CLAIM_FAILURE";
export const CONFIRM_PARTIAL_CLAIM_REQUEST = "CONFIRM_PARTIAL_CLAIM_REQUEST";
export const CONFIRM_PARTIAL_CLAIM_SUCCESS = "CONFIRM_PARTIAL_CLAIM_SUCCESS";
export const CONFIRM_PARTIAL_CLAIM_FAILURE = "CONFIRM_PARTIAL_CLAIM_FAILURE";

export const PARTIAL_CLAIM_SET_CSSD = "PARTIAL_CLAIM_SET_CSSD";

export const cssdPartialClaimActions = {
  saveAndConfirmRequest: (account, partialClaim, formData) =>
    action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_REQUEST, { account, partialClaim, formData }),
  saveAndConfirmSuccess: () => action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_SUCCESS),
  saveAndConfirmFailure: (error) => action(SAVE_AND_CONFIRM_PARTIAL_CLAIM_FAILURE, { error }),

  cancelRequest: (account, partialClaim) =>
    action(CANCEL_PARTIAL_CLAIM_REQUEST, { account, partialClaim }),
  cancelSuccess: () => action(CANCEL_PARTIAL_CLAIM_SUCCESS),
  cancelFailure: (error) => action(CANCEL_PARTIAL_CLAIM_FAILURE, { error }),

  confirmRequest: (account, partialClaim) =>
    action(CONFIRM_PARTIAL_CLAIM_REQUEST, { account, partialClaim }),
  confirmSuccess: () => action(CONFIRM_PARTIAL_CLAIM_SUCCESS),
  confirmFailure: (error) => action(CONFIRM_PARTIAL_CLAIM_FAILURE, { error }),

  setCssd: (cssd) => action(PARTIAL_CLAIM_SET_CSSD, { cssd }),
};

export const BULK_OFFER_PARTIAL_CLAIM = "BULK_OFFER_PARTIAL_CLAIM";
export const BULK_CANCEL_PARTIAL_CLAIM = "BULK_CANCEL_PARTIAL_CLAIM";
export const BULK_ACTION_PARTIAL_CLAIM_FAILURE = "BULK_ACTION_PARTIAL_CLAIM_FAILURE";
export const BULK_ACTION_PARTIAL_CLAIM_SUCCESS = "BULK_ACTION_PARTIAL_CLAIM_SUCCESS";

export const bulkPartialClaimActions = {
  bulkOffer: (partialClaims, reset) => action(BULK_OFFER_PARTIAL_CLAIM, { partialClaims, reset }),
  bulkCancel: (partialClaims, reset) => action(BULK_CANCEL_PARTIAL_CLAIM, { partialClaims, reset }),
  failedBulkRequest: () => action(BULK_ACTION_PARTIAL_CLAIM_FAILURE),
  finishedBulkRequest: () => action(BULK_ACTION_PARTIAL_CLAIM_SUCCESS),
};
