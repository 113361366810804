import React from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";

export const verificationHeader = {
  valid: () => <FormattedMessage {...messages.valid} />,
  notValid: () => <FormattedMessage {...messages.notValid} />,
};

export const verificationText = {
  A: () => <FormattedMessage {...messages.a} />,
  B: () => <FormattedMessage {...messages.b} />,
  C: (terminationDate) => (
    <FormattedMessage {...messages.c} values={{ terminationDate: `${terminationDate}` }} />
  ),
  D: () => <FormattedMessage {...messages.d} />,
  E: (terminationDate) => (
    <FormattedMessage {...messages.e} values={{ terminationDate: `${terminationDate}` }} />
  ),
  F: (terminationDate) => (
    <FormattedMessage {...messages.f} values={{ terminationDate: `${terminationDate}` }} />
  ),
  G: () => <FormattedMessage {...messages.g} />,
  H: () => <FormattedMessage {...messages.h} />,
  L: (terminationDate) => (
    <FormattedMessage {...messages.l} values={{ terminationDate: `${terminationDate}` }} />
  ),
};
