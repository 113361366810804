import {
  UPLOAD_USER_INFORMATION_OPEN_DIALOG,
  UPLOAD_USER_INFORMATION_CLOSE_DIALOG,
  UPLOAD_USER_INFORMATION_REQUEST,
  UPLOAD_USER_INFORMATION_SUCCESS,
  UPLOAD_USER_INFORMATION_FAILURE,
  LIST_USER_INFORMATION_REQUEST,
  LIST_USER_INFORMATION_SUCCESS,
  LIST_USER_INFORMATION_FAILURE,
  DOWNLOAD_USER_INFORMATION_REQUEST,
  DOWNLOAD_USER_INFORMATION_SUCCESS,
  DOWNLOAD_USER_INFORMATION_FAILURE,
  DELETE_USER_INFORMATION_REQUEST,
  DELETE_USER_INFORMATION_SUCCESS,
  DELETE_USER_INFORMATION_FAILURE,
  UPDATE_USER_INFORMATION_REQUEST,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_INFORMATION_FAILURE,
} from "./actions";

const initialState = {
  documents: [],
  queryPending: false,
  dialogOpen: false,
  uploadPending: false,
  pendingIds: [],
  documentForUpdate: null,
};

const uploadUserInformationOpenDialogReducer = (state, action) => ({
  ...state,
  dialogOpen: true,
  documentForUpdate: action.documentForUpdate,
});

const uploadUserInformationCloseDialogReducer = (state) => ({
  ...state,
  dialogOpen: false,
  documentForUpdate: null,
});

const uploadUserInformationRequestReducer = (state) => ({ ...state, uploadPending: true });
const uploadUserInformationSuccessReducer = (state) => ({ ...state, uploadPending: false });
const uploadUserInformationFailureReducer = (state) => ({ ...state, uploadPending: false });

const listUserInformationRequestReducer = (state) => ({ ...state, queryPending: true });

const listUserInformationSuccessReducer = (state, action) => ({
  ...state,
  queryPending: false,
  documents: [...action.documents],
});

const listUserInformationFailureReducer = (state) => ({ ...state, queryPending: false });

const addPendingIdReducer = (state, action) => ({
  ...state,
  pendingIds: [...state.pendingIds, action.dateiId],
});

const removePendingIdReducer = (state, action) => ({
  ...state,
  pendingIds: state.pendingIds.filter((id) => id !== action.dateiId),
});

const updateUserInformationRequestReducer = (state) => ({ ...state, uploadPending: true });
const updateUserInformationSuccessReducer = (state) => ({ ...state, uploadPending: false });
const updateUserInformationFailureReducer = (state) => ({ ...state, uploadPending: false });

export function userInformationReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_USER_INFORMATION_OPEN_DIALOG:
      return uploadUserInformationOpenDialogReducer(state, action);
    case UPLOAD_USER_INFORMATION_CLOSE_DIALOG:
      return uploadUserInformationCloseDialogReducer(state);
    case UPLOAD_USER_INFORMATION_REQUEST:
      return uploadUserInformationRequestReducer(state);
    case UPLOAD_USER_INFORMATION_SUCCESS:
      return uploadUserInformationSuccessReducer(state);
    case UPLOAD_USER_INFORMATION_FAILURE:
      return uploadUserInformationFailureReducer(state);
    case LIST_USER_INFORMATION_REQUEST:
      return listUserInformationRequestReducer(state);
    case LIST_USER_INFORMATION_SUCCESS:
      return listUserInformationSuccessReducer(state, action);
    case LIST_USER_INFORMATION_FAILURE:
      return listUserInformationFailureReducer(state);
    case DOWNLOAD_USER_INFORMATION_REQUEST:
      return addPendingIdReducer(state, action);
    case DOWNLOAD_USER_INFORMATION_SUCCESS:
      return removePendingIdReducer(state, action);
    case DOWNLOAD_USER_INFORMATION_FAILURE:
      return removePendingIdReducer(state, action);
    case DELETE_USER_INFORMATION_REQUEST:
      return addPendingIdReducer(state, action);
    case DELETE_USER_INFORMATION_SUCCESS:
      return removePendingIdReducer(state, action);
    case DELETE_USER_INFORMATION_FAILURE:
      return removePendingIdReducer(state, action);
    case UPDATE_USER_INFORMATION_REQUEST:
      return updateUserInformationRequestReducer(state);
    case UPDATE_USER_INFORMATION_SUCCESS:
      return updateUserInformationSuccessReducer(state);
    case UPDATE_USER_INFORMATION_FAILURE:
      return updateUserInformationFailureReducer(state);

    default:
      return state;
  }
}
