import { getCSSDControllerContractMethods, sendTransaction } from "./commons";
import { contractCache } from "services/smartContracts/smartContracts";

export async function modifyCSSDTermination(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  terminationId,
  terminationHash,
  terminationType,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyCSSDTermination(
      payingAgent,
      cssdId,
      cssdHash,
      terminationId,
      terminationHash,
      terminationType,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function approveCSSDTermination(
  account,
  payingAgent,
  cssdId,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .approveCSSDTermination(payingAgent, cssdId, terminationId, terminationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function approveReDocumentedCSSDTermination(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .approveReDocumentedCssdTermination(
      payingAgent,
      cssdId,
      cssdHash,
      terminationId,
      terminationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndApproveCSSDTermination(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  terminationId,
  terminationHash,
  terminationType,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndApproveCSSDTermination(
      payingAgent,
      cssdId,
      cssdHash,
      terminationId,
      terminationHash,
      terminationType,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndApproveReDocumentedCssdTermination(
  account,
  payingAgent,
  cssdId,
  cssdHash,
  terminationId,
  terminationHash,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndApproveReDocumentedCssdTermination(
      payingAgent,
      cssdId,
      cssdHash,
      terminationId,
      terminationHash,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function rejectCSSDTermination(
  account,
  payingAgent,
  cssdId,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .rejectCSSDTermination(payingAgent, cssdId, terminationId, terminationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function rejectReDocumentedCssdTermination(
  account,
  payingAgent,
  cssdId,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .rejectReDocumentedCssdTermination(payingAgent, cssdId, terminationId, terminationHash)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function approvePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  partialClaimHash,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .approvePartialClaimTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      partialClaimHash,
      terminationId,
      terminationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndApprovePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  partialClaimHash,
  terminationId,
  terminationHash,
  terminationType,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndApprovePartialClaimTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      partialClaimHash,
      terminationId,
      terminationHash,
      terminationType,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndApproveReDocumentedPartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  partialClaimHash,
  terminationId,
  terminationHash,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndApproveReDocumentedPartialClaimTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      partialClaimHash,
      terminationId,
      terminationHash,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function approveReDocumentedPartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  partialClaimHash,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .approveReDocumentedPartialClaimTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      partialClaimHash,
      terminationId,
      terminationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function rejectPartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId,
  terminationHash
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .rejectPartialClaimTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      terminationId,
      terminationHash
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function modifyAndOfferRevokePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .modifyAndOfferRevokePartialClaimTermination(payingAgent, cssdId, partialClaimId, terminationId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function cancelRevokePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId,
  isDN
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .cancelRevokePartialClaimTermination(payingAgent, cssdId, partialClaimId, terminationId, isDN)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function offerRevokePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerRevokePartialClaimTermination(payingAgent, cssdId, partialClaimId, terminationId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function acceptRevokePartialClaimTermination(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .acceptRevokePartialClaimTermination(payingAgent, cssdId, partialClaimId, terminationId)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function hasActiveCessions(payingAgent, cssdId, partialClaimId) {
  const cession = await contractCache.cssdManagerContract.methods
    .hasActiveCessions(payingAgent, cssdId, partialClaimId)
    .call();
  return cession;
}

export async function abortCessionsOnTermination({
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationTimestamp,
}) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .abortCessions(payingAgent, cssdId, partialClaimId, terminationTimestamp)
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}

export async function getAbortedIds({
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationTimestamp,
}) {
  const ids = await contractCache.cssdManagerContract.methods
    .getAbortedIds(payingAgent, cssdId, partialClaimId)
    .call();
  return ids;
}

export async function migrateTerminatedPartialClaim(
  account,
  payingAgent,
  cssdId,
  partialClaimId,
  terminationId,
  terminationHash,
  terminationDate
) {
  const contractMethods = getCSSDControllerContractMethods();
  const data = contractMethods
    .offerMigratedTermination(
      payingAgent,
      cssdId,
      partialClaimId,
      terminationId,
      terminationHash,
      terminationDate
    )
    .encodeABI();
  return await sendTransaction(account, contractCache.cssdController._address, data);
}
