import axios from "axios";
import { formatSafeUrl } from "util/convertor";

//---------------------------------------------------------------------------

export async function verifyDocument(pdfHash, ssdType) {
  const data = { pdfHash };
  return axios.post(
    formatSafeUrl(
      process.env.REACT_APP_SERVER_URL_PREFIX +
        process.env.REACT_APP_SERVER_URL +
        `/digits-server/verify/${ssdType}`
    ),
    data
  );
}

//---------------------------------------------------------------------------
