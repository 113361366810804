import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_business_partner_einladung_bestaetigt: {
    id: "redux.business.partner.einladung.bestaetigt",
    defaultMessage: "Einladung bestätigt.",
  },
  assignGPSuccess: {
    id: "redux.business.partner.assign.success",
    defaultMessage: "Geschäftspartner zugeordnet.",
  },
  assignGPFailure: {
    id: "redux.business.partner.assign.failure",
    defaultMessage: "Geschäftspartner konnten nicht zugeordnet werden.",
  },
  disconnectGPSuccess: {
    id: "redux.business.partner.disconnect.success",
    defaultMessage: "Geschäftspartnerzuordnung wurde getrennt.",
  },
  disconnectGPFailure: {
    id: "redux.business.partner.disconnect.failure",
    defaultMessage: "Geschäftspartnerzuordnung konnten nicht getrennt werden.",
  },
  redux_business_partner_einladung_konnte_nicht_bestaetigt_werden: {
    id: "redux.business.partner.einladung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Einladung konnte nicht bestätigt werden.",
  },
  redux_business_partner_einladung_abgelehnt: {
    id: "redux.business.partner.einladung.abgelehnt",
    defaultMessage: "Einladung abgelehnt.",
  },
  redux_business_partner_einladung_konnte_nicht_abgelehnt_werden: {
    id: "redux.business.partner.einladung.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Einladung konnte nicht abgelehnt werden.",
  },
  redux_business_partner_einladung_erfolgreich_versendet: {
    id: "redux.business.partner.einladung.erfolgreich.versendet",
    defaultMessage: "Einladung erfolgreich versendet.",
  },
  redux_business_partner_fehler_beim_senden_der_einladung: {
    id: "redux.business.partner.fehler.beim.senden.der.einladung",
    defaultMessage: "Fehler beim senden der Einladung.",
  },
  savePartnerIdSuccess: {
    id: "sagas.businessPartner.savePartnerIdSuccess",
    defaultMessage: "Partner ID gespeichert",
  },

  savePartnerIdFailure: {
    id: "sagas.businessPartner.savePartnerIdFailure",
    defaultMessage: "Partner ID konnte nicht gespeichert werden",
  },
});
