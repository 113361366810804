import React, { useEffect } from "react";
import { supportedLanguages } from "util/constants";
import { isGermanOnly } from "./intl";

export const LocaleContext = React.createContext();

export const LocaleContextProvider = (props) => {
  const [locale, setLocale] = React.useState();
  const localStorageBrowserLanguageKey = "browserLanguageKey";

  // run only once to initialize 'locale' state
  useEffect(() => {
    initLocale();
  }, []);

  // run everytime 'locale' changes to update localStorage
  useEffect(() => {
    if (locale) {
      localStorage.setItem(localStorageBrowserLanguageKey, locale);
    }
  }, [locale]);

  const initLocale = () => {
    const savedLanguage = localStorage.getItem(localStorageBrowserLanguageKey);
    const browserLanguage = navigator.language.substr(0, 2);

    if (!isGermanOnly() && supportedLanguages.includes(savedLanguage || browserLanguage)) {
      setLocale(savedLanguage || browserLanguage);
    } else {
      setLocale("de");
    }
  };

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>{props.children}</LocaleContext.Provider>
  );
};
