import { sendSignedHttpRequest } from "util/requestSigning";
import { contractCache } from "services/smartContracts/smartContracts";
import { startPdfDownload } from "services/commonService";
import { downloadFileFromBase64 } from "util/fileReader";
import { GeschaeftspartnerTyp } from "util/constants";

export function postCSSD(arranger, data) {
  return sendSignedHttpRequest("post", `/digits-server/cssd/${arranger}`, arranger, data);
}

export function uploadDocumentsCSSD(arranger, cssdId, fingerprint, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/urkundeUndZahlstellenvertrag/${arranger}/${cssdId}/${fingerprint}`,
    arranger,
    data
  );
}

export function queryCSSDs(arranger, data) {
  return sendSignedHttpRequest("post", `/digits-server/cssd/cssds/${arranger}`, arranger, data);
}

export function queryCSSD(arranger, cssdId, fingerprint) {
  const data = {
    zahlstelle: arranger === contractCache.identityProxy._address ? arranger : null,
    darlehensnehmer:
      arranger !== contractCache.identityProxy._address
        ? contractCache.identityProxy._address
        : null,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/${arranger}/${cssdId}/${fingerprint}`,
    arranger,
    data
  );
}

export function queryCSSDNewDg(arranger, cssdId, fingerprint, cessionId) {
  const data = {
    zahlstelle: arranger === contractCache.identityProxy._address ? arranger : null,
    darlehensnehmer:
      arranger !== contractCache.identityProxy._address
        ? contractCache.identityProxy._address
        : null,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/fuerNeuenDG/${arranger}/${cssdId}/${fingerprint}/${cessionId}`,
    arranger,
    data
  );
}

export function queryCSSDsById(arranger, cssdRequests, idProxy) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/cssds_by_id/${arranger}/${idProxy}`,
    arranger,
    { cssdRequests }
  );
}

export function queryInformationsmaterialMetadataForNewDg(
  arranger,
  cssdId,
  cessionId,
  filters,
  sortCriteria,
  pagingInfo
) {
  const data = {
    sortBy: sortCriteria.column,
    descending: sortCriteria.isDescending,
    filters: filters,
    pageNumber: pagingInfo.pageNumber,
    pageSize: pagingInfo.pageSize,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/datenraum_info_FuerNeuenDG/${arranger}/${cssdId}/${cessionId}`,
    arranger,
    data
  );
}

export function queryInformationsmaterialMetadata(
  arranger,
  cssdId,
  filters,
  sortCriteria,
  pagingInfo
) {
  const data = {
    sortBy: sortCriteria.column,
    descending: sortCriteria.isDescending,
    filters: filters,
    pageNumber: pagingInfo.pageNumber,
    pageSize: pagingInfo.pageSize,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/datenraum_info/${arranger}/${cssdId}`,
    arranger,
    data
  );
}

export function uploadInformationsmaterial(arranger, cssdId, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/datenraum/${arranger}/${cssdId}`,
    arranger,
    data
  );
}

export function deleteInformationsmaterialService(arranger, cssdId, dateiId, mitarbeiter) {
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/cssd/datenraum/${arranger}/${cssdId}/${dateiId}`,
    arranger,
    {
      mitarbeiter,
    }
  );
}

export async function fetchInformationsmaterial(arranger, cssdId, dateiId) {
  const response = await sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/datenraum/${arranger}/${cssdId}/${dateiId}`,
    arranger
  );
  try {
    downloadFileFromBase64(response.data.dokument, response.data.dateiName, response.data.dateiTyp);
  } catch (error) {
    console.error(error);
  }
  return response;
}

export async function sendMailForInformationsmaterialUpload(arranger, cssdId, title, text, type) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/sendBulkMailForNeuesDokument/${arranger}/${cssdId}`,
    arranger,
    {
      content: {
        betreff: title,
        bemerkung: text,
        dokumentTyp: type,
      },
    }
  );
}

export function cssdAblehnen(arranger, cssdId, fingerprint, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/ablehnung/${arranger}/${cssdId}/${fingerprint}`,
    arranger,
    data
  );
}

export function getAblehnungsgruende(arranger, cssdId, fingerprint, kaufId, typ) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/ablehnungsgruende/${arranger}/${cssdId}/${fingerprint}/${typ}/${kaufId}`,
    arranger
  );
}

export async function validateId(id_proxy_arranger, cssdId, fingerprint, id, idName) {
  const data = {
    id,
    idName,
  };
  const response = await sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/validateId/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger,
    data
  );
  return response.data.idIsValid;
}

export function queryTeilforderungenById(arranger, teilforderungBulkRequests, idProxy) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/teilforderungen_by_id/${arranger}/${idProxy}`,
    arranger,
    { teilforderungBulkRequests }
  );
}

export function queryTeilforderungDetails(arranger, teilforderungId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/teilforderung/${teilforderungId}/${arranger}`,
    arranger
  );
}

export function bulkQueryTeilforderungDetails(arranger, partialClaimIds) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/teilforderungendetails_by_id/${arranger}`,
    arranger,
    {
      teilforderungBulkRequests: partialClaimIds,
    }
  );
}

export function getRestAmountFromPartialClaimForRebuy(arranger, teilforderungId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretung/teilforderung/abgestimmterRestbestandBeiRueckkauf/${arranger}/${teilforderungId}`,
    arranger
  );
}

export async function fetchPDF(id_proxy_arranger, cssdId, fingerprint, documentType) {
  const response = await sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/urkundeUndZahlstellenvertrag/${id_proxy_arranger}/${cssdId}/${fingerprint}/${GeschaeftspartnerTyp.ZAHLSTELLE}`,
    id_proxy_arranger
  );
  const suitableDocument = response.data.dokumentResponses.find(
    (doc) => doc.dokumentTyp === documentType
  );
  startPdfDownload(suitableDocument.dokument, suitableDocument.dateiName);
  return response;
}

export async function updateUrkundeUndZahlstellenvertragSignatureTimestamp(
  id_proxy_arranger,
  cssdId,
  fingerprint
) {
  return sendSignedHttpRequest(
    "put",
    `/digits-server/cssd/urkundeUndZahlstellenvertrag/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger
  );
}

export function zweitgegenbestaetigung(id_proxy_arranger, cssdId, fingerprint) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/geschaeftsbestaetigung/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger,
    null
  );
}

export async function getGeschaeftsbestaetigung(id_proxy_arranger, cssdId, fingerprint, id) {
  const response = await sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/geschaeftsbestaetigung/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger
  );
  startPdfDownload(response.data.dokument, response.data.dateiName);
  return response;
}

export function createErsteTeilforderung(id_proxy_arranger, cssdId, fingerprint) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/ersteTeilforderung/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger
  );
}

export function getErsteTeilforderung(id_proxy_arranger, cssdId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/ersteTeilforderung/${id_proxy_arranger}/cssdId/${cssdId}/`,
    id_proxy_arranger
  );
}

export function fetchPartialClaimsForCssd(id_proxy_arranger, cssdId, isZS) {
  return sendSignedHttpRequest(
    "post",
    isZS
      ? `/digits-server/cssd/${cssdId}/teilforderungen/${id_proxy_arranger}`
      : `/digits-server/cssd/${cssdId}/teilforderungenFuerDarlehensnehmer/${id_proxy_arranger}`,
    id_proxy_arranger,
    {}
  );
}

export async function getCssdUrkunde(id_proxy_arranger, cssdId) {
  const response = await sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/aktuelleUrkunde/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
  startPdfDownload(response.data.dokument, response.data.dateiName);
  return response;
}

export function fetchSumOfAllPartialClaims(id_proxy_arranger, cssdId, isZS) {
  return sendSignedHttpRequest(
    "get",
    isZS
      ? `/digits-server/cssd/summeAbgestimmteRestbetraege/${id_proxy_arranger}/${cssdId}`
      : `/digits-server/cssd/summeAbgestimmteRestbetraegeFuerDarlehensnehmer/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
}

export function fetchInterestNotifications(id_proxy_arranger, cssdId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/zinsmitteilung/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
}

export function storeInterestNotification(id_proxy_arranger, cssdId, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/zinsmitteilung/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger,
    data
  );
}

export function storeSettlementInformation(id_proxy_arranger, cssdId, fingerprint, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/abwicklungsinformationen/${id_proxy_arranger}/${cssdId}/${fingerprint}`,
    id_proxy_arranger,
    data
  );
}

export function fetchCssdHistory(id_proxy_arranger, cssdId, subId = null, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/cssd/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger,
    data
  );
}

export function fetchCssdKaufHistory(id_proxy_arranger, cssdId, id, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/cssdKauf/${id_proxy_arranger}/${cssdId}/${id}`,
    id_proxy_arranger,
    data
  );
}

export function fetchTerminationHistory(id_proxy_arranger, cssdId, id, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/kuendigung/${id_proxy_arranger}/${cssdId}/${id}`,
    id_proxy_arranger,
    data
  );
}

export function fetchCssdSettlementHistory(id_proxy_arranger, cssdId, id, data) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/audit/abwicklungsInfo/${id_proxy_arranger}/${cssdId}/${id}`,
    id_proxy_arranger,
    data
  );
}

export function fetchRejectReason(id_proxy_arranger, cssdId, reasonHash, typ) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/ablehnungsgrund/${id_proxy_arranger}/${cssdId}/${reasonHash}/${typ}`,
    id_proxy_arranger
  );
}

export function fetchDocumentRejectReason(id_proxy_arranger, cssdId, reasonHash) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/urkundeUndZahlstellenvertrag/ablehnungsgrund/${id_proxy_arranger}/${cssdId}/${reasonHash}`,
    id_proxy_arranger
  );
}

export function createZinsmitteilungPDF(idProxyArranger, cssdId, zinsmitteilungId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/zinsmitteilung/pdf/${idProxyArranger}/${cssdId}/${zinsmitteilungId}`,
    idProxyArranger,
    null
  );
}

export function fetchInterestNotification(id_proxy_arranger, cssdId, interestNotificationId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/zinsmitteilung/${id_proxy_arranger}/${cssdId}/${interestNotificationId}`,
    id_proxy_arranger
  );
}

export function deleteInterestNotification(id_proxy_arranger, cssdId, interestNotificationId) {
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/cssd/zinsmitteilung/${id_proxy_arranger}/${cssdId}/${interestNotificationId}`,
    id_proxy_arranger
  );
}

export function deleteInterestNotificationMail(id_proxy_arranger, cssdId, interestNotificationId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/sendBulkMailForDeletedZinsmitteilung/${id_proxy_arranger}/${cssdId}/${interestNotificationId}`,
    id_proxy_arranger
  );
}

export function getExternPartialClaimsForCssd(id_proxy_arranger, cssdId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/teilforderung/extern/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
}

export function checkIfCancelCessionRequired(id_proxy_arranger, cssdId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/cssd/abtretungStornierungErforderlich/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
}

export function deleteAllCssdDocuments(id_proxy_arranger, cssdId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/deletedokuments/${id_proxy_arranger}/${cssdId}`,
    id_proxy_arranger
  );
}

export async function rejectCssdDocuments(arranger, cssdId, fingerprint, cssdAblehnungDTO) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/cssd/urkundeUndZahlstellenvertrag/ablehnung/${arranger}/${cssdId}/${fingerprint}`,
    arranger,
    cssdAblehnungDTO
  );
}
