import { removeDots, clearSsdNumberFormat, clearCessionNumberFormat } from "util/convertor";
import { sendSignedHttpRequest } from "util/requestSigning";
import { usecase } from "util/constants";
import { startPdfDownload } from "services/commonService";

export function fetchRejectReason(darlehensnehmer, arranger, ssdId, cessionId, cessionHash) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/abtretung/${darlehensnehmer}/${ssdId}/${cessionId}/${cessionHash}/bemerkung`,
    arranger
  ).catch((err) => {
    console.error(err);
    return {};
  });
}

export function prepareSsdForCession(ssdData) {
  const arranger = ssdData.arranger;
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}`,
    arranger,
    clearSsdNumberFormat(ssdData)
  );
}

export function postCession(cessionData, ssdId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${cessionData.idproxy_arranger}/${ssdId}`,
    cessionData.darlehensgeber_alt,
    clearCessionNumberFormat(cessionData)
  );
}

export function updateCession(cessionData, ssdId) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${cessionData.idproxy_arranger}/${ssdId}/${cessionData.abtretung_id}`,
    cessionData.darlehensgeber_alt,
    clearCessionNumberFormat(cessionData)
  );
}

export function fetchCession(arranger, seller, ssdId, cessionId, cessionHash) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/abtretung/${arranger}/${ssdId}/${cessionId}/${cessionHash}`,
    seller
  );
}

export function rejectCessionPostRejectReason(
  darlehensnehmer,
  arranger,
  ssdId,
  cessionId,
  cessionHash,
  rejectReason
) {
  const data = { usecase: usecase.REJECT, kommentar: rejectReason };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${darlehensnehmer}/${ssdId}/${cessionId}/${cessionHash}/reject`,
    arranger,
    data
  );
}

export function cessionAcceptOfferMail(
  darlehensnehmer,
  template,
  arranger,
  ssdId,
  cessionId,
  cessionHash
) {
  const data = { template };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${darlehensnehmer}/${ssdId}/${cessionId}/${cessionHash}/send_accept_offer`,
    arranger,
    data
  ).catch((error) => {
    console.error(error);
  });
}

export function cessionNotifyMail(template, arranger, ssdId, cessionId, cessionHash, dgOld) {
  const data = { template };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${arranger}/${ssdId}/${cessionId}/${cessionHash}/send_notify`,
    dgOld,
    data
  ).catch((error) => {
    console.error(error);
  });
}

export function createCessionAnzeige(cessionInitiator, cessionId, cessionHash, oldSsdInfo) {
  const info = {
    ssdNominal: parseFloat(removeDots(oldSsdInfo.nominal)),
    waehrung_nominal: oldSsdInfo.waehrung_nominal,
    urkunden_registernummer: oldSsdInfo.urkunden_registernummer,
    endfaelligkeit: oldSsdInfo.endfaelligkeit,
    ssdValuta: oldSsdInfo.valuta,
    dnAnschrift: oldSsdInfo.anschrift_darlehensnehmer,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${oldSsdInfo.darlehensnehmer}/${oldSsdInfo.ssdid}/${cessionId}/${cessionHash}/anzeige`,
    cessionInitiator,
    info
  ).catch((error) => {
    console.error(error);
  });
}

export function getCessionAnzeige(darlehensnehmer, cessionInitiator, ssdId, cessionId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/abtretung/${darlehensnehmer}/${ssdId}/${cessionId}/anzeige`,
    cessionInitiator
  ).then(function (response) {
    startPdfDownload(response.data.base64EncodedPdf, response.data.dateiName);
  });
}

export function getCessionConfirmation(
  cessionInitiator,
  ssdId,
  cessionId,
  cessionHash,
  cessionInfo,
  ssdDetails
) {
  const data = {
    darlehensnehmerIdProxy: cessionInfo.idproxy_arranger,
    nominal: cessionInfo.abgetretenes_nominal,
    waehrung_nominal: cessionInfo.waehrung_nominal,
    urkunden_registernummer: cessionInfo.urkunden_registernummer,
    endfaelligkeit: cessionInfo.valuta_abtretung,
    zins_fix: ssdDetails.zins_fix,
    ssdNominal: parseFloat(removeDots(ssdDetails.nominal)),
    ssdEndfaelligkeit: ssdDetails.endfaelligkeit,
  };
  return sendSignedHttpRequest(
    "post",
    `/digits-server/abtretung/${cessionInfo.idproxy_arranger}/${ssdId}/${cessionId}/${cessionHash}/bestaetigung`,
    cessionInitiator,
    data
  ).then(function (response) {
    startPdfDownload(response.data.base64EncodedBestaetigung, response.data.dateiName);
  });
}
