import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cssd_termination_saveAndConfirm_success: {
    id: "cssd.termination.saveAndConfirm.success",
    defaultMessage: "Kündigung wurde erfolgreich erfasst und erstbestätigt.",
  },
  cssd_termination_saveAndConfirm_failure: {
    id: "cssd.termination.saveAndConfirm.failure",
    defaultMessage: "Kündigung konnte nicht erfasst werden.",
  },
  cssd_termination_confirm_success: {
    id: "cssd.termination.confirm.success",
    defaultMessage: "Das CSSD wurde erfolgerich gekündigt.",
  },
  cssd_termination_confirm_failure: {
    id: "cssd.termination.confirm.failure",
    defaultMessage: "Das CSSD konnte nicht gekündigt werden.",
  },
  partialClaim_termination_confirm_success: {
    id: "cssd.partialClaim.termination.confirm.success",
    defaultMessage: "Die Teilforderung wurde erfolgreich gekündigt.",
  },
  partialClaim_termination_confirm_failure: {
    id: "cssd.partialClaim.termination.confirm.failure",
    defaultMessage: "Die Teilforderung konnte nicht gekündigt werden.",
  },
  cssd_termination_cancel_success: {
    id: "cssd.termination.cancel.success",
    defaultMessage: "Die Kündigung wurde erfolgreich storniert.",
  },
  cssd_termination_cancel_failure: {
    id: "cssd.termination.cancel.failure",
    defaultMessage: "Die Kündigung konnte nicht storniert werden",
  },
  titleTerminationStateNotValid: {
    id: "cssd.information.dialog.title.termination.state.not.valid",
    defaultMessage: "Status stimmte nicht überein",
  },
  contentTextTerminationStateNotValid: {
    id: "cssd.information.dialog.content.text.termination.state.not.valid",
    defaultMessage:
      "Inkonsistenter Zustand der Kündigung erkannt. Status korrigiert und von Blockchain übernommen.",
  },
  default: {
    id: "cssd.information.dialog.default.button",
    defaultMessage: "Ok Verstanden",
  },
  offer_revoke_termination_success: {
    id: "cssd.partialClaim.termination.revoke.offer.success",
    defaultMessage: "Das Zurückziehen der Kündigung wurde erfolgreich bestätigt.",
  },
  offer_revoke_termination_failure: {
    id: "cssd.partialClaim.termination.revoke.offer.failure",
    defaultMessage: "Das Zurückziehen der Kündigung konnte nicht bestätigt werden.",
  },
  cancel_revoke_termination_success: {
    id: "cssd.partialClaim.termination.revoke.cancel.success",
    defaultMessage: "Das Zurückziehen der Kündigung wurde abgelehnt.",
  },
  cancel_revoke_termination_failure: {
    id: "cssd.partialClaim.termination.revoke.cancel.failure",
    defaultMessage: "Das Zurückziehen der Kündigung konnte nicht abgelehnt werden",
  },
  download_failed: {
    id: "cssd.termination.download.failed",
    defaultMessage: "Beim Download ist ein Fehler aufgetreten.",
  },
  accept_revoke_termination_success: {
    id: "cssd.partialClaim.termination.revoke.accept.success",
    defaultMessage: "Die Anfrage zum Zurückziehen der Kündigung wurde erfolgreich gegenbestätigt.",
  },
  accept_revoke_termination_failure: {
    id: "cssd.partialClaim.termination.revoke.accept.failure",
    defaultMessage:
      "Die Anfrage zum Zurückziehen der Kündigung konnte nicht gegenbestätigt werden.",
  },
  open_dialog_open_cession_error_title: {
    id: "cssd.partialCalim.termination.create.openCession.title",
    defaultMessage: "Kündigung erfassen nicht möglich",
  },
  open_dialog_open_cession_error_body: {
    id: "cssd.partialCalim.termination.create.openCession.body",
    defaultMessage:
      "Die Kündigung kann aufgrund einer nicht abgestimmten Übertragung nicht erfasst werden. Schließen Sie die Prozesse ab um eine Kündigung erfassen zu können.",
  },
  open_dialog_open_cession_error_close: {
    id: "cssd.partialCalim.termination.create.openCession.close",
    defaultMessage: "Schließen",
  },
});
