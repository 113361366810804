import { sendSignedHttpRequest } from "util/requestSigning";
import { clearTerminationNumberFormat } from "util/convertor";

export async function postKuendigung(arranger, ssdId, kuendigung) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/kuendigung`,
    arranger,
    clearTerminationNumberFormat(kuendigung)
  ).then(function (response) {
    return response.data;
  });
}

export async function postKuendigungZedent(arranger, ssdId, parentId, kuendigung) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/ssd/${arranger}/${ssdId}/${parentId}/kuendigung`,
    arranger,
    clearTerminationNumberFormat(kuendigung)
  ).then(function (response) {
    return response.data;
  });
}

export async function getKuendigung(arranger, ssdId, kuendigungsHash) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/ssd/${arranger}/${ssdId}/kuendigung/${kuendigungsHash}`,
    arranger
  ).then(function (response) {
    return response.data;
  });
}
