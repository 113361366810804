import { hot } from "react-hot-loader/root";
import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import { LocaleContext } from "./i18n/LocaleContext";
import { IntlGlobalProvider } from "./i18n/intl";
import { IntlProvider } from "react-intl";
import de from "components/i18n/translations/de.json";
import en from "components/i18n/translations/en.json";
import MessageDialog from "./Shared/MessageDialog/MessageDialog";
import SnackbarContainer from "./Shared/SnackbarContainer/SnackbarContainer";
import { createMuiTheme, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";
import InvalidDataAlertDialog from "./Shared/InvalidDataAlertDialog/InvalidDataAlertDialog";
import { createImported, goToMigrationOverview, switchTestData } from "util/fields";
import grey from "@material-ui/core/colors/grey";
import AssignGPDialog from "components/Shared/AssignGPDialog/AssignGPDialog";

const messages = {
  de,
  en,
};

const themeLight = createMuiTheme({
  typography: {
    fontFamily: '"Dosis", sans-serif',
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
  palette: createPalette({
    type: "light",
    primary: {
      main: "#005A5B",
    },
    primary2: {
      light: "#E1F4EF",
      main: "#02A676",
      dark: "#017B46",
    },
    secondary: {
      main: "#474747",
    },
    error: {
      light: "#FCE7E7",
      main: "#C62828",
    },
    warning: {
      light: "#FDF3E3",
      main: "#FFA000",
    },
    info: {
      light: "#E3F2FD",
      main: "#90CAF9",
      dark: "#49a8f5",
    },
    success: {
      light: "#ECF9F3",
      main: "#00C853",
    },
    background: {
      main: "#FBFCFD",
      paper: "#FFFFFF",
    },
    hoverColor: grey[100],
    uploadButtonUnderline: "#949494",
    listHeadColor: "#BBB",
    headbgColorLight: "#fff",
    headbgColor: "#fff",
  }),
});

const themeDark = createMuiTheme({
  typography: {
    fontFamily: '"Dosis", sans-serif',
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#02A676",
    },
    primary2: {
      light: "#E1F4EF",
      main: "#02A676",
      dark: "#017B46",
    },
    secondary: {
      main: "#474747",
    },
    error: {
      light: "#FCE7E7",
      main: "#C62828",
    },
    warning: {
      light: "#FDF3E3",
      main: "#FFA000",
    },
    info: {
      light: "#E3F2FD",
      main: "#90CAF9",
      dark: "#49a8f5",
    },
    success: {
      light: "#ECF9F3",
      main: "#00C853",
    },
    background: {
      paper: "#323232",
      default: "#2b2b2b",
    },
    tableHeadColor: "#616161",
    headbgColor: "#424242",
    listHeadColor: "#616161",
    uploadButtonUnderline: "#bdbdbd",
  },
});

function App() {
  const [locale] = React.useContext(LocaleContext);
  const [theme, setTheme] = useState(themeLight);

  useEffect(() => {
    // Switch Theme between dark and light
    window.finledger_changeTheme = () => {
      setTheme(theme.palette.type === "dark" ? themeLight : themeDark);
    };

    // De-/Activate Testdata
    window.finledger_switchTestData = () => switchTestData();

    // Save Cssds as imported
    window.finledger_createImported = () => createImported();

    // Go to migration overview
    window.finledger_migrationOverview = () => goToMigrationOverview();
  }, []);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]} onError={() => {}}>
      <IntlGlobalProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <InvalidDataAlertDialog />
          <Navigation />
          <MessageDialog />
          <AssignGPDialog />
          <SnackbarContainer maxSnackbars={2} />
        </MuiThemeProvider>
      </IntlGlobalProvider>
    </IntlProvider>
  );
}

export default hot(App);
