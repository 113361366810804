import { contractCache } from "services/smartContracts/smartContracts";
//import { getDetailsUrl } from "./commons";

export function fetchMemberOnboardedEvents() {
  return contractCache.platformManagerContract
    .getPastEvents("MemberOnboardedEvent", {
      fromBlock: 0,
      toBlock: "latest",
    })
    .then(async function (events) {
      const data = [];
      for (const event of [...events]) {
        const idProxyAddress = event.returnValues.idProxy;
        //' const url = await getDetailsUrl(idProxy);
        data.push({ idProxyAddress });
      }
      return { data };
    })
    .catch((error) => {
      return { error };
    });
}
