import { defineMessages } from "react-intl";

export const messages = {
  ...{
    label_rows_per_page: {
      id: "cssd.table.label.rows.per.page",
    },
  },
  ...defineMessages({
    choose_gp: {
      id: "cssd.abtretung.geschaeftspartner_waehlen",
      defaultMessage: "Geschäftspartner wählen",
    },
    manual_registration: {
      id: "cssd.abtretung.geschaeftspartner_manuell",
      defaultMessage: "Manuell Daten erfassen",
    },
    imported: {
      id: "cssd.abtretung.status.importiert",
      defaultMessage: "Importiert",
    },
    singular: {
      id: "cssd.abtretung.singular",
      defaultMessage: "Übertragung",
    },
    plural: {
      id: "cssd.abtretung.plural",
      defaultMessage: "Übertragungen",
    },
    lei: {
      id: "cssd.abtretung.lei",
      defaultMessage: "LEI",
    },
    firmenname: {
      id: "cssd.abtretung.firmenname",
      defaultMessage: "Firmenname",
    },
    partnerId: {
      id: "cssd.abtretung.partnerId",
      defaultMessage: "Partner ID",
    },
    edit: {
      id: "cssd.abtretung.edit",
      defaultMessage: "Bearbeiten",
    },
    close: {
      id: "cssd.abtretung.close",
      defaultMessage: "Schließen",
    },
    geschaeftsnummer: {
      id: "cssd.abtretung.geschaeftsnummer",
      defaultMessage: "Geschäftsnummer",
    },
    geschaeftsnummer_placeholder: {
      id: "cssd.abtretung.geschaeftsnummer.placeholder",
      defaultMessage: "wird vom System vergeben",
    },
    date: {
      id: "cssd.abtretung.date",
      defaultMessage: "Datum",
    },
    state: {
      id: "cssd.abtretung.status",
      defaultMessage: "Status",
    },
    actions: {
      id: "cssd.abtretung.aktion",
      defaultMessage: "Aktion",
    },
    title: {
      id: "cssd.abtretung.title",
      defaultMessage: "CSSD Übertragung",
    },
    rebuy: {
      id: "cssd.abtretung.rebuy",
      defaultMessage: "Rückkauf",
    },
    externRebuy: {
      id: "cssd.abtretung.externRebuy",
      defaultMessage: "Rückkauf nachdokumentieren",
    },
    migratedPartialClaim: {
      id: "cssd.migrated.partialClaim",
      defaultMessage: "Migrationsdaten",
    },
    migratedPartialClaimState: {
      id: "cssd.migrated.partialClaim.state",
      defaultMessage: "Status nach Migration",
    },
    migratedPartialClaimBestand: {
      id: "cssd.migrated.partialClaim.bestand",
      defaultMessage: "Aktueller Bestand",
    },
    cession_open_view_failure: {
      id: "cession.cession_open_view_failure",
      defaultMessage: "Ansicht kann nicht geöffnet werden",
    },
    cession_confirmation_download_failure: {
      id: "cession.cession_confirmation_download_failure",
      defaultMessage: "Geschäftsbestätigung konnte nicht heruntergeladen werden",
    },
    cession_confirmation_download: {
      id: "cession.cession_confirmation_download",
      defaultMessage: "Geschäftsbestätigung herunterladen",
    },
    cession_cert_download_failure: {
      id: "cession.cession_cert_download_failure",
      defaultMessage: "Übertragungszertifikat konnte nicht heruntergeladen werden",
    },
    cession_cert_download: {
      id: "cession.cession_cert_download",
      defaultMessage: "Übertragungszertifikat herunterladen",
    },
    newDarlehensgeber: {
      id: "cssd.abtretung.neuer_darlehensgeber",
      defaultMessage: "Neuer Darlehensgeber",
    },
    currentDarlehensgeber: {
      id: "cssd.abtretung.bisheriger_darlehensgeber",
      defaultMessage: "Bisheriger Darlehensgeber",
    },
    valuta: {
      id: "cssd.abtretung.valuta",
      defaultMessage: "Valuta",
    },
    waehrung_nominal: {
      id: "cssd.abtretung.waehrung_nominal",
      defaultMessage: "Währung Nominal",
    },
    nominal: {
      id: "cssd.abtretung.nominal",
      defaultMessage: "Nominal",
    },
    sumNominal: {
      id: "cssd.abtretung.summe_nominal",
      defaultMessage: "Summe der Nominale",
    },
    noData: {
      id: "cssd.abtretung.empty",
      defaultMessage: "Für das Geschäft wurden noch keine Übertragungen angelegt.",
    },
    fetchFailed: {
      id: "cssd.abtretung.fetch.failed",
      defaultMessage: "Daten konnten nicht geladen werden.",
    },
    restTeilforderungen: {
      id: "cssd.abtretung.rest_teilforderungen",
      defaultMessage: "Restbestand Teilforderungen",
    },
    partialClaim: {
      id: "cssd.abtretung.partialClaim",
      defaultMessage: "Teilforderung",
    },
    darlehensnehmer: {
      id: "cssd.abtretung.darlehensnehmer",
      defaultMessage: "Darlehensnehmer",
    },
    zahlstelle: {
      id: "cssd.abtretung.zahlstelle",
      defaultMessage: "Zahlstelle",
    },
    urkundennummer: {
      id: "cssd.abtretung.urkundennummmer",
      defaultMessage: "Urkunden-/ Registernummer",
    },
    darlehensbetrag: {
      id: "cssd.abtretung.darlehensbetrag",
      defaultMessage: "Darlehensbetrag",
    },
    cssd: {
      id: "cssd.abtretung.cssd",
      defaultMessage: "Aktuelles Geschäft - {urkundenRegisternummer}",
    },
    interneKennung: {
      id: "cssd.abtretung.interneKennung",
      defaultMessage: "Interne Kennung",
    },
    waehrungNominal: {
      id: "cssd.abtretung.waehrungNominal",
      defaultMessage: "Währung Nominal",
    },
    konsortialkennung: {
      id: "cssd.abtretung.konsortialkennung",
      defaultMessage: "Konsortialkennung",
    },
    gerichtsstand: {
      id: "cssd.abtretung.gerichtsstand",
      defaultMessage: "Gerichtsstand",
    },
    mindestbetragBeiAbtretung: {
      id: "cssd.abtretung.mindestbetragBeiAbtretung",
      defaultMessage: "Mindestbetrag bei Übertragung",
    },
    sprache: {
      id: "cssd.abtretung.sprache",
      defaultMessage: "Sprache",
    },
    sprache_dn: {
      id: "cssd.abtretung.sprache_dn",
      defaultMessage: "Sprache DN",
    },
    sprache_dg: {
      id: "cssd.abtretung.sprache_dg",
      defaultMessage: "Sprache DG",
    },
    endfaelligkeit: {
      id: "cssd.abtretung.endfaelligkeit",
      defaultMessage: "Endfälligkeit",
    },
    endfaelligkeitDurchKuendigung: {
      id: "cssd.abtretung.endfaelligkeitDurchKuendigung",
      defaultMessage: "Endfälligkeit durch Kündigung",
    },
    laufzeitbeginn: {
      id: "cssd.abtretung.laufzeitbeginn",
      defaultMessage: "Laufzeitbeginn",
    },
    details: {
      id: "cssd.abtretung.details",
      defaultMessage: "Übertragung Detailansicht",
    },
    nachdokumentation: {
      id: "cssd.abtretung.nachdokumentation",
      defaultMessage: "Übertragung Nachdokumentation",
    },
    erste_teilforderung_details: {
      id: "cssd.abtretung.erste_teilforderung_details",
      defaultMessage: "Erstgeschäft Detailansicht",
    },
    verzinsung: {
      id: "cssd.abtretung.verzinsung",
      defaultMessage: "Verzinsung",
    },
    review: {
      id: "cssd.abtretung.review",
      defaultMessage: "Überblick",
    },
    darlehensgeber: {
      id: "cssd.abtretung.darlehensgeber",
      defaultMessage: "Darlehensgeber",
    },
    abort: {
      id: "cssd.abtretung.abbrechen",
      defaultMessage: "Abbrechen",
    },
    next: {
      id: "cssd.abtretung.next",
      defaultMessage: "Weiter",
    },
    back: {
      id: "cssd.abtretung.back",
      defaultMessage: "Zurück",
    },
    save: {
      id: "cssd.abtretung.save",
      defaultMessage: "Speichern",
    },
    save_and_confirm: {
      id: "cssd.abtretung.save_and_confirm",
      defaultMessage: "Speichern und erstbestätigen",
    },
    companyInfo: {
      id: "cssd.abtretung.companyInfo",
      defaultMessage: "Unternehmensdaten",
    },
    hinweis_dateInPast: {
      id: "cssd.abtretung.dateInPast",
      defaultMessage: "Hinweis: Datum liegt in der Vergangenheit",
    },
    datum: {
      id: "cssd.abtretung.datum",
      defaultMessage: "Datum",
    },
    gegenwert: {
      id: "cssd.abtretung.gegenwert",
      defaultMessage: "Gegenwert",
    },
    valutaStueckzinsen: {
      id: "cssd.abtretung.valutaStueckzinsen",
      defaultMessage: "Valuta Stückzinsen",
    },
    kurs: {
      id: "cssd.abtretung.kurs",
      defaultMessage: "Kurs",
    },
    zinstermin: {
      id: "cssd.abtretung.zinstermin",
      defaultMessage: "Nächster Zinstermin",
    },
    digits_kennung: {
      id: "cssd.abtretung.digits",
      defaultMessage: "Finledger Kennung",
    },
    umsatzsteuer_id: {
      id: "cssd.abtretung.ustid",
      defaultMessage: "Umsatzsteuer ID",
    },
    anschrift: {
      id: "cssd.abtretung.anschrift",
      defaultMessage: "Anschrift",
    },
    sitzland: {
      id: "cssd.abtretung.sitzland",
      defaultMessage: "Sitzland",
    },
    ansprechpartner_abwicklung: {
      id: "cssd.abtretung.ansprechpartner_abwicklung",
      defaultMessage: "Ansprechpartner Abwicklung",
    },
    abteilung_abwicklung: {
      id: "cssd.abtretung.abteilung_abwicklung",
      defaultMessage: "Abteilung Abwicklung",
    },
    telefonnummer: {
      id: "cssd.abtretung.telefon",
      defaultMessage: "Telefonnummer",
    },
    email_verteiler: {
      id: "cssd.abtretung.email_verteiler",
      defaultMessage: "Email Verteiler",
    },
    bic: {
      id: "cssd.abtretung.bic",
      defaultMessage: "BIC",
    },
    konto_zins_tilgung: {
      id: "cssd.abtretung.konto_zins_tilgung",
      defaultMessage: "Konto (Zins & Tilgung)",
    },
    konto_zins: {
      id: "cssd.abtretung.konto_zins",
      defaultMessage: "Konto (Zins)",
    },
    bic_zins_tilgung: {
      id: "cssd.abtretung.bic_zins_tilgung",
      defaultMessage: "BIC (Zins & Tilgung)",
    },
    bic_zins: {
      id: "cssd.abtretung.bic_zins",
      defaultMessage: "BIC (Zins)",
    },
    hinweis_zahlung_zins_tilgung: {
      id: "cssd.abtretung.hinweis_zahlung_zins_tilgung",
      defaultMessage: "Hinweis Zahlung (Zins & Tilgung)",
    },
    hinweis_zahlung_zins: {
      id: "cssd.abtretung.hinweis_zahlung_zins",
      defaultMessage: "Hinweis Zahlung (Zins)",
    },
    konto_tilgung: {
      id: "cssd.abtretung.konto_tilgung",
      defaultMessage: "Konto (Tilgung)",
    },
    bic_tilgung: {
      id: "cssd.abtretung.bic_tilgung",
      defaultMessage: "BIC (Tilgung)",
    },
    hinweis_zahlung_tilgung: {
      id: "cssd.abtretung.hinweis_zahlung_tilgung",
      defaultMessage: "Hinweis Zahlung (Tilgung)",
    },
    belastung_internes_konto: {
      id: "cssd.abtretung.belastung_internes_konto",
      defaultMessage: "Belastung internes Konto",
    },
    im_adressbuch_speichern: {
      id: "cssd.abtretung.im_adressbuch_speichern",
      defaultMessage: "Im Adressbuch speichern",
    },
    bicFormat: {
      id: "cssd.abtretung.bicFormat",
      defaultMessage: "Fehler - Beispiel: HELADEFF123",
    },
    ibanFormat: {
      id: "cssd.abtretung.ibanFormat",
      defaultMessage: "Fehler - Beispiel: DE84500105176619333197",
    },
    ja: {
      id: "cssd.abtretung.ja",
      defaultMessage: "Ja",
    },
    nein: {
      id: "cssd.abtretung.nein",
      defaultMessage: "Nein",
    },
    name: {
      id: "cssd.abtretung.name",
      defaultMessage: "Darlehensgeber neu",
    },
    valutaErrorClaimAndToday: {
      id: "cssd.abtretung.valutaVorValutaUndHeuteError",
      defaultMessage: "Die Valuta muss nach heute und nach der Valuta der Teilforderung liegen",
    },
    valutaPast: {
      id: "cssd.abtretung.valutaPast",
      defaultMessage: "Die Valuta liegt in der Vergangenheit",
    },
    valutaError: {
      id: "cssd.abtretung.valutaVorValutaError",
      defaultMessage: "Die Valuta muss nach der Valuta der Teilforderung liegen",
    },
    valutaErrorWithDate: {
      id: "cssd.abtretung.valutaErrorWithDate",
      defaultMessage: "Die Valuta muss nach der Valuta der Teilforderung ({valuta}) liegen",
    },
    valutaEndfaelligkeitError: {
      id: "cssd.abtretung.valutaNachEndfaelligkeitError",
      defaultMessage: "Die Valuta muss vor der Endfälligkeit der Teilforderung liegen",
    },
    nominalSumError: {
      id: "cssd.abtretung.nominalSummeZuGross",
      defaultMessage:
        "Die Summe der Nominale darf nicht größer sein als das Nominal der Teilforderung.",
    },
    mirgationSumError: {
      id: "cssd.abtretung.mirgationSumError",
      defaultMessage:
        "Die Summe der Nominale darf nicht größer sein als der abgestimmte Restbestand durch Migration der Teilforderung. {restbetrag}.",
    },
    nominalError: {
      id: "cssd.abtretung.nominalMindestbetrag",
      defaultMessage:
        "Das Nominal muss ein ganzzahliges Vielfaches des Mindestbetrag bei Übertragung sein",
    },
    kursError: {
      id: "cssd.abtretung.kursError",
      defaultMessage: "Kurs muss > 0 sein",
    },
    umsatzSteuerIdFormatError: {
      id: "cssd.abtretung.umsatzSteuerIdFormatError",
      defaultMessage: "Fehler - Beispiel: DE123456789",
    },
    telefonFormatError: {
      id: "cssd.abtretung.telefonFormatError",
      defaultMessage: "Fehler - Beispiel: +49 30 12345",
    },
    emailFormatError: {
      id: "cssd.abtretung.emailFormatError",
      defaultMessage: "Fehler - Beispiel: example@site.com",
    },
    leiFormatError: {
      id: "cssd.abtretung.leiFormatError",
      defaultMessage: "Fehler - Beispiel: 724500VKKSH9QOLTFR81",
    },
    action_register: {
      id: "cssd.abtretung.register",
      defaultMessage: "Erfassen",
    },
    cession_paid_success: {
      id: "sagas.cession_paid_success",
      defaultMessage: "Übertragung als bezahlt markiert.",
    },
    cession_paid_failure: {
      id: "sagas.cession_paid_failure",
      defaultMessage: "Übertragung konnte nicht als bezahlt markiert werden.",
    },
    rebuy_paid_success: {
      id: "sagas.rebuy_paid_success",
      defaultMessage: "Zahlung wurde erfolgreich veranlasst.",
    },
    rebuy_paid_failure: {
      id: "sagas.rebuy_paid_failure",
      defaultMessage: "Zahlung konnte nicht veranlasst werden.",
    },
    cession_save_success: {
      id: "sagas.cession_save_success",
      defaultMessage: "Übertragung wurde erfolgreich erfasst.",
    },
    cession_offer_success: {
      id: "sagas.cession_offer_success",
      defaultMessage: "Übertragung wurde erfolgreich bestätigt.",
    },
    cession_offer_failure: {
      id: "sagas.cession_offer_failure",
      defaultMessage: "Übertragung konnte nicht bestätigt werden.",
    },
    cession_accept_success: {
      id: "sagas.cession_accept_success",
      defaultMessage: "Übertragung wurde erfolgreich gegenbestätigt.",
    },
    cession_accept_failure: {
      id: "sagas.cession_accept_failure",
      defaultMessage: "Übertragung konnte nicht gegenbestätigt werden.",
    },
    cession_save_failure: {
      id: "sagas.cession_save_failure",
      defaultMessage: "Übertragung konnte nicht erfasst werden.",
    },
    cession_update_success: {
      id: "sagas.cession_update_success",
      defaultMessage: "Übertragung wurde erfolgreich gespeichert.",
    },
    cession_update_failure: {
      id: "sagas.cession_update_failure",
      defaultMessage: "Übertragung konnte nicht gespeichert werden.",
    },
    cession_cancel_failure: {
      id: "sagas.cession_cancel_failure",
      defaultMessage: "Übertragung konnte nicht storniert werden.",
    },
    cession_reject_failure: {
      id: "sagas.cession_reject_failure",
      defaultMessage: "Übertragung konnte nicht abgelehnt werden.",
    },
    cession_reject_success: {
      id: "sagas.cession_reject_success",
      defaultMessage: "Übertragung wurde erfolgreich abgelehnt.",
    },
    cession_save_and_confirm_success: {
      id: "sagas.cession_save_and_confirm_success",
      defaultMessage: "Übertragung wurde erfolgreich gespeichert und bestätigt.",
    },
    cession_save_and_confirm_failure: {
      id: "sagas.cession_save_and_confirm_failure",
      defaultMessage: "Übertragung konnte nicht gespeichert und bestätigt werden.",
    },
    reject_reason: {
      id: "cssd.abtretung.reject_reason",
      defaultMessage: "Ablehnungsgrund",
    },
    reject_reason_warning: {
      id: "cssd.abtretung.reject_reason_warning",
      defaultMessage: "Bitte geben Sie einen Grund an, wenn Sie ablehnen möchten.",
    },
    action_reject: {
      id: "cssd.abtretung.actions.reject",
      defaultMessage: "Ablehnen",
    },
    action_offer_first: {
      id: "cssd.abtretung.actions.offer_first",
      defaultMessage: "Erstbestätigen",
    },
    action_open: {
      id: "cssd.abtretung.actions.open",
      defaultMessage: "Zweitbestätigen",
    },
    action_accept_first: {
      id: "cssd.abtretung.actions.accept_first",
      defaultMessage: "Erstgegenbestätigen",
    },
    action_approve: {
      id: "cssd.abtretung.actions.approve",
      defaultMessage: "Zweitgegenbestätigen",
    },
    description_payment_received: {
      id: "cssd.abtretung.description_payment_received",
      defaultMessage: "Haben Sie die Zahlung erhalten?",
    },
    action_cancel: {
      id: "cssd.abtretung.actions.cancelled",
      defaultMessage: "Stornieren",
    },
    action_cancel_confirm_title: {
      id: "cssd.abtretung.actions.cancel_confirm_title",
      defaultMessage: "Übertragung stornieren",
    },
    action_cancel_confirm_body: {
      id: "cssd.abtretung.actions.cancel_confirm_body",
      defaultMessage: "Wollen Sie diese Übertragung wirklich stornieren?",
    },
    action_payment_received: {
      id: "cssd.abtretung.actions.payment_received",
      defaultMessage: "Zahlung erhalten",
    },
    action_accept_external_cession: {
      id: "cssd.abtretung.actions.accept_external_cession",
      defaultMessage: "Gegenbestätigung / Korrektur",
    },
    action_receive_certificate: {
      id: "cssd.abtretung.actions.receive_certificate",
      defaultMessage: "Übertragungszertifikat erhalten",
    },
    action_cert_offer_first_disabled_hint: {
      id: "cssd.abtretung.actions.cert_offer_first_disabled_hint",
      defaultMessage:
        "Das Übertragungszertifikat der vorherigen Übertragung muss zunächst noch gegenbestätigt werden.",
    },
    action_cert_offer_first: {
      id: "cssd.abtretung.actions.cert_offer_first",
      defaultMessage: "Übertragungszertifikat erstbestätigen",
    },
    action_cert_open: {
      id: "cssd.abtretung.actions.cert_open",
      defaultMessage: "Übertragungszertifikat zweitbestätigen",
    },
    action_cert_accept_first: {
      id: "cssd.abtretung.actions.cert_accept_first",
      defaultMessage: "Übertragungszertifikat erstgegenbestätigen",
    },
    action_cert_approve: {
      id: "cssd.abtretung.actions.cert_approve",
      defaultMessage: "Übertragungszertifikat zweitgegenbestätigen",
    },
    action_cert_offer_first_body: {
      id: "cssd.abtretung.actions.cert_offer_first_body",
      defaultMessage: "Wollen Sie das Übertragungszertifikat erstbestätigen?",
    },
    action_cert_open_body: {
      id: "cssd.abtretung.actions.cert_open_body",
      defaultMessage: "Wollen Sie das Übertragungszertifikat zweitbestätigen?",
    },
    action_cert_accept_first_body: {
      id: "cssd.abtretung.actions.cert_accept_first_body",
      defaultMessage: "Wollen Sie das Übertragungszertifikat erstgegenbestätigen?",
    },
    action_cert_approve_body: {
      id: "cssd.abtretung.actions.cert_approve_body",
      defaultMessage: "Wollen Sie das Übertragungszertifikat zweitgegenbestätigen?",
    },
    extern_flag: {
      id: "cssd.abtretung.status.extern",
      defaultMessage: "Extern",
    },
    status_imported: {
      id: "cssd.abtretung.status.imported",
      defaultMessage: "Importiert",
    },
    status_registered: {
      id: "cssd.abtretung.status.registered",
      defaultMessage: "Erfasst",
    },
    status_rejected: {
      id: "cssd.abtretung.status.rejected",
      defaultMessage: "Abgelehnt",
    },
    status_offer_first: {
      id: "cssd.abtretung.status.offer_first",
      defaultMessage: "Erstbestätigt",
    },
    status_open: {
      id: "cssd.abtretung.status.open",
      defaultMessage: "Bestätigt",
    },
    status_accept_first: {
      id: "cssd.abtretung.status.accept_first",
      defaultMessage: "Erstgegenbestätigt",
    },
    status_approved: {
      id: "cssd.abtretung.status.approved",
      defaultMessage: "Abgestimmt",
    },
    status_cancelled: {
      id: "cssd.abtretung.status.cancelled",
      defaultMessage: "Storniert",
    },
    status_aborted: {
      id: "cssd.abtretung.status.aborted",
      defaultMessage: "Abgebrochen",
    },
    status_payment_received: {
      id: "cssd.abtretung.status.payment_received",
      defaultMessage: "Zahlung erhalten",
    },
    status_cert_offer_first: {
      id: "cssd.abtretung.status.cert_offer_first",
      defaultMessage: "Übertragungszertifikat erstbestätigt",
    },
    status_cert_open: {
      id: "cssd.abtretung.status.cert_open",
      defaultMessage: "Übertragungszertifikat zweitbestätigt",
    },
    status_cert_accept_first: {
      id: "cssd.abtretung.status.cert_accept_first",
      defaultMessage: "Übertragungszertifikat erstgegenbestätigt",
    },
    status_cert_approved: {
      id: "cssd.abtretung.status.cert_approved",
      defaultMessage: "Übertragungszertifikat zweitgegenbestätigt",
    },
    status_notified: {
      id: "cssd.abtretung.status.notified",
      defaultMessage: "Übertragung angezeigt",
    },
    bulk_action_success: {
      id: "sagas.bulk_action_success",
      defaultMessage: "Die Aktion wurde erfolgreich durchgeführt.",
    },
    bulk_action_failure: {
      id: "sagas.bulk_action_failure",
      defaultMessage: "Die Aktion konnte nicht durchgeführt werden.",
    },
    bulk_action_dialog_title: {
      id: "cssd.abtretung.bulk_action.dialog.title",
      defaultMessage: "⚠️ {numErrors} Fehler:",
    },
    bulk_action_dialog_message: {
      id: "cssd.abtretung.bulk_action.dialog.message",
      defaultMessage:
        "Übertragungen mit markierten Auswahlkästchen sind für die ausgewählte Aktion nicht gültig.",
    },
    bulk_action_dialog_status_errors: {
      id: "cssd.abtretung.bulk_action.dialog.status.error",
      defaultMessage: "Übertragung ist nicht im richtigen Status:",
    },
    bulk_action_dialog_user_errors: {
      id: "cssd.abtretung.bulk_action.dialog.user.error",
      defaultMessage: "Aktion für diesen Benutzer nicht zulässig:",
    },
    bulk_action_dialog_bottom_info: {
      id: "cssd.abtretung.bulk_action.dialog.bottom.info",
      defaultMessage: "Die Auswahl wurde automatisch korrigiert. Bitte wiederholen Sie die Aktion.",
    },
    bulk_action_dialog_button_label: {
      id: "cssd.abtretung.bulk_action.dialog.button.label",
      defaultMessage: "Schließen",
    },
    zins_fix: {
      id: "cssd.abtretung.zins_fix",
      defaultMessage: "Verzinsung",
    },
    duplicateAccount: {
      id: "cssd.abtretung.duplicate_account",
      defaultMessage: "Gleiches Konto für Zins und Tilgung nutzen",
    },
    extern: {
      id: "cssd.abtretung.nachExtern",
      defaultMessage: "nach Extern übertragen",
    },
    adressbuch_auswahl: {
      id: "cssd.abtretung.adressbuch_auswahl",
      defaultMessage: "Aus Adressbuch auswählen",
    },
    neuer_adressbucheintrag: {
      id: "cssd.abtretung.neuer_adressbucheintrag",
      defaultMessage: "Neuer Adressbucheintrag",
    },
    cssd_cession_accept_ext_dialog_title: {
      id: "cssd.cession.accept.ext.dialog.title",
      defaultMessage: "Gegenbestätigung / Korrektur",
    },
    cssd_cession_accept_external_dialog_changes_requested: {
      id: "cssd.cession.accept.ext.dialog.changes_requested",
      defaultMessage: "Korrektur angefordert",
    },
    cssd_cession_accept_ext_dialog_body: {
      id: "cssd.cession.accept.ext.dialog.body",
      defaultMessage: "Wurde die Gegenbestätigung erhalten oder eine Korrektur angefordert?",
    },
    cssd_cession_accept_ext_dialog_btn_confirm: {
      id: "cssd.cession.accept.ext.dialog.btn.confirm",
      defaultMessage: "Gegenbestätigung erhalten",
    },
    cssd_cession_accept_ext_snackbar_success: {
      id: "cssd.cession.accept.ext.snackbar.success",
      defaultMessage: "Externe Übertragung wurde gegenbestätigt.",
    },
    cssd_cession_accept_ext_snackbar_failure: {
      id: "cssd.cession.accept.ext.snackbar.failure",
      defaultMessage: "Externe Übertragung konnte nicht gegenbestätigt werden.",
    },
    cssd_cession_request_change_external_snackbar_failure: {
      id: "cssd.cession.accept.ext.cssd_cession_request_change_external_snackbar_failure",
      defaultMessage: "Korrektur konnte nicht angefordert werden.",
    },
    cssd_cession_request_change_external_snackbar_success: {
      id: "cssd.cession.accept.ext.cssd_cession_request_change_external_snackbar_success",
      defaultMessage: "Korrektur angefragt.",
    },
    cssd_cession_upload_cert_snackbar_success: {
      id: "cssd.cession.upload.cert.snackbar.success",
      defaultMessage: "Übertragungszertifikat für Externe Übertragung hochgeladen.",
    },
    cssd_cession_upload_cert_snackbar_failure: {
      id: "cssd.cession.upload.cert.snackbar.failure",
      defaultMessage:
        "Das Hochladen des Übertragungszertifikat für die Externe Übertragung ist fehlgeschlagen",
    },
    cssd_cession_upload_cert_wrong_type_snackbar_failure: {
      id: "cssd.cession.upload.cert.wrong.type.snackbar.failure",
      defaultMessage: "Das hochgeladene Übertragungszertifikat ist kein PDF.",
    },
    upload_dialog_title: {
      id: "cssd.abtretung.uebertragungszertifikat.upload.dialog.title",
      defaultMessage: "Übertragungszertifikat hochladen",
    },
    upload_dialog_text: {
      id: "cssd.abtretung.uebertragungszertifikat.upload.dialog.text",
      defaultMessage: "Bitte laden Sie das Übertragungszertifikat hoch.",
    },
    upload_dialog_upload_button: {
      id: "cssd.abtretung.uebertragungszertifikat.upload.dialog.upload",
      defaultMessage: "Hochladen",
    },
    upload_dialog_browse_button: {
      id: "cssd.abtretung.uebertragungszertifikat.upload.dialog.browse",
      defaultMessage: "Übertragungszertifikat",
    },
    upload_dialog_cancel_button: {
      id: "cssd.abtretung.uebertragungszertifikat.upload.dialog.cancel",
      defaultMessage: "Abbrechen",
    },
    audit: {
      id: "cssd.abtretung_context.audit",
      defaultMessage: "Historie",
    },
    create_cession_disabled: {
      id: "cssd.abtretung.erstellen.disabled",
      defaultMessage:
        "Sie können keine Übertragung erfassen, wenn Sie eine Kündigung in Bearbeitung haben.",
    },
    cessions_for_partial_claim_error: {
      id: "cssd.abtretung.fuer.teilforderung.fehler",
      defaultMessage: "Übertragungen für die Übersicht konnten nicht geladen werden",
    },
    partialClaimError: {
      id: "cssd.migration.teilforderung.bearbeiten.aktuellerBestand.fehler",
      defaultMessage:
        "Der aktuelle Bestand muss kleiner oder gleich als das Nominal der Teilforderung sein ({currency} {nominal})",
    },
    cession_bulk_selected: {
      id: "cssd.abtretung.migration.ausgewaehlt",
      defaultMessage: "ausgewählt",
    },
  }),
};
