// action types
export const PDF_VERIFICATION_REQUEST = "PDF_VERIFICATION_REQUEST";
export const PDF_VERIFICATION_SUCCESS = "PDF_VERIFICATION_SUCCESS";
export const PDF_VERIFICATION_FAILURE = "PDF_VERIFICATION_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const pdfVerificationActions = {
  request: (pdfFile, ssdType) => action(PDF_VERIFICATION_REQUEST, { pdfFile, ssdType }),
  success: (pdfStatus) => action(PDF_VERIFICATION_SUCCESS, { pdfStatus }),
  failure: (error) => action(PDF_VERIFICATION_FAILURE, { error }),
};
