import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { messageDialogActions } from "redux/shared/actions";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

const MessageDialog = (props) => {
  const { icon: Icon, title, body, buttonLabel, open, handleClose } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          disableTypography={true}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {Icon && <Icon style={{ marginRight: "5px" }} />}
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            id="alert-dialog-description"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {buttonLabel || "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    icon: state.sharedReducer.messageDialog.icon,
    title: state.sharedReducer.messageDialog.title,
    body: state.sharedReducer.messageDialog.body,
    buttonLabel: state.sharedReducer.messageDialog.buttonText,
    open: state.sharedReducer.messageDialog.open,
  };
};

const mapDispatchToProps = {
  handleClose: messageDialogActions.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageDialog);
