import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redux_wallet_registration_anmeldung_bestaetigt: {
    id: "redux.wallet.registration.anmeldung.bestaetigt",
    defaultMessage: "Anmeldung bestätigt!",
  },
  redux_wallet_registration_anmeldung_konnte_nicht_bestaetigt_werden: {
    id: "redux.wallet.registration.anmeldung.konnte.nicht.bestaetigt.werden",
    defaultMessage: "Anmeldung konnte nicht bestätigt werden.",
  },
  redux_wallet_registration_anmeldung_abgelehnt: {
    id: "redux.wallet.registration.anmeldung.abgelehnt",
    defaultMessage: "Anmeldung abgelehnt.",
  },
  redux_wallet_registration_anmeldung_konnte_nicht_abgelehnt_werden: {
    id: "redux.wallet.registration.anmeldung.konnte.nicht.abgelehnt.werden",
    defaultMessage: "Anmeldung konnte nicht abgelehnt werden.",
  },
  redux_wallet_registration_anmeldung_wurde_erfolgreich_gesendet: {
    id: "redux.wallet.registration.anmeldung.wurde.erfolgreich.gesendet",
    defaultMessage: "Anmeldung wurde erfolgreich gesendet.",
  },
  redux_wallet_registration_anmeldung_konnte_nicht_gesendet_werden_bitte_ueberpruefen: {
    id: "redux.wallet.registration.anmeldung.konnte.nicht.gesendet.werden.bitte.ueberpruefen",
    defaultMessage:
      "Anmeldung konnte nicht gesendet werden. Bitte überprüfen Sie, ob die eingegebene Backend URL korrekt ist!",
  },
});
