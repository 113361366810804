import { takeLatest, call, put } from "redux-saga/effects";
import { addDotsComma, formatDate, convertTimestampToDate, convertTimestampToTime } from "util/convertor";
import {
  toHex,
  toUtf8,
  getIdProxyAddr,
  getTimestampFromBlock,
  ssdAuditEvents,
  ssdFourEyesEvents,
  getInterestNotificationAuditEventFromBc,
  getCessionAuditEventFromBc,
} from "services/web3Services/commons";
import {
  FETCH_SSD_HISTORY_REQUEST,
  FETCH_INTEREST_HISTORY_REQUEST,
  FETCH_CESSION_HISTORY_REQUEST,
} from "./actions";
import {
  historyActions,
  interestHistoryModalActions,
  interestHistoryActions,
  cesstionHistoryActions,
  cessionHistoryModalActions,
} from "./actions";
import {
  fetchAllRejectReason,
  getHistory,
  getInterestAuditHistory,
  getCessionAuditHistory,
} from "services/ssdService";
import { fetchRejectReason as fetchCessionRejectReason } from "services/cessionService";
import { getAccountInfo } from "services/accountService";
import {
  FourEyesState,
  SSDActions,
  SSDState,
  SSDStateType,
  SSDPaymentState,
  SSDPaymentAction,
  SSDRebuyState,
  SSDRebuyAction,
  SSDTerminationAction,
  InterestNotificationState,
  InterestNotificationAction,
  CessionState,
  CessionAction,
} from "util/constants";
import { isInCompany, getSsdState } from "services/web3Services/commons";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const auditSagas = [
  takeLatest(FETCH_SSD_HISTORY_REQUEST, requestHistory),
  takeLatest(FETCH_INTEREST_HISTORY_REQUEST, requestInterestHistory),
  takeLatest(FETCH_CESSION_HISTORY_REQUEST, requestCessionHistory),
];

function getCessionAuditEventDescripion(action, state) {
  if (state === CessionState.NEW) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_erfasst),
      prio: 1,
    };
  }
  if (action === CessionAction.MODIFY) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_bearbeitet),
      prio: 5,
    };
  }
  if (action === CessionAction.OFFER) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_bestaetigt),
      prio: 10,
    };
  }
  if (action === CessionAction.ACCEPT_OFFER) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_gegenbestaetigt),
      prio: 10,
    };
  }
  if (action === CessionAction.REJECT_OFFER) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_abgelehnt),
      prio: 10,
    };
  }
  if (action === CessionAction.ABORT) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_abgebrochen),
      prio: 10,
    };
  }
  if (action === CessionAction.CANCEL) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_storniert),
      prio: 10,
    };
  }
  if (action === CessionAction.NOTIFY) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_angezeigt),
      prio: 10,
    };
  }
  if (action === CessionAction.PROCESS) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_abgeschlossen),
      prio: 10,
    };
  }
  return `action: ${action}; state: ${state}`;
}

function getCessionsFourEyesDescription(state) {
  if (state === FourEyesState.CESSION_OFFER_FIRST) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_erste_bestaegigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.CESSION_OFFER_SECOND) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_zweite_bestaegigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.CESSION_OFFER_CANCELLED) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_abtretung_bestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }
  if (state === FourEyesState.CESSION_OFFER_CANCELLED_ON_MODIFY) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_abtretung_bestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }

  if (state === FourEyesState.CESSION_ACCEPT_FIRST) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_erste_gegenbestaegigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.CESSION_ACCEPT_SECOND) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_abtretung_zweite_gegenbestaegigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.CESSION_ACCEPT_CANCELLED) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_abtretung_gegenbestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }
  if (state === FourEyesState.CESSION_ACCEPT_CANCELLED_ON_REJECT) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_abtretung_gegenbestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }
  return `state: ${state}`;
}

function checkIfEditIsAfterReject(index, arr, rejectedFlag) {
  const part = arr.slice(0, index);
  for (let entry of part) {
    if (entry.returnValues.action === rejectedFlag) {
      return true;
    }
  }
  return false;
}

function* formatCessionAuditEvents(
  auditEvents,
  fourEyesEvents,
  serverHistory,
  isDgOld,
  dgOld,
  ssdId,
  cessionId
) {
  let result = [];
  let changes;
  let who;

  for (let [index, entry] of auditEvents.entries()) {
    changes = [];
    who = "n.A.";
    if (
      (entry.returnValues.state === CessionState.NEW && !isDgOld) ||
      (entry.returnValues.action === CessionAction.MODIFY &&
        !isDgOld &&
        !checkIfEditIsAfterReject(index, auditEvents, CessionAction.REJECT_OFFER))
    ) {
      continue;
    }
    for (let change of serverHistory.changeList) {
      if (
        entry.returnValues.newHash === change.newFingerprint &&
        entry.returnValues.oldHash === change.oldFingerprint &&
        change.changes.length > 0
      ) {
        changes = [...change.changes];
        who = change.who;
      } else {
        let benutzerkennung = yield getBenutzerkennung(entry, "audit");
        who = benutzerkennung;
      }
    }
    let timestamp = yield call(getTimestampFromBlock, entry.blockNumber);
    let eventAction = yield call(
      getCessionAuditEventDescripion,
      entry.returnValues.action,
      entry.returnValues.state
    );
    let comment = "";
    if (entry.returnValues.action === CessionAction.REJECT_OFFER) {
      const { data } = yield call(
        fetchCessionRejectReason,
        entry.returnValues.arranger,
        dgOld,
        ssdId,
        cessionId,
        entry.returnValues.newHash
      );
      comment = data ? data.kommentar : "";
    }

    if (
      eventAction.description ===
      appIntl().formatMessage(messages.redux_audit_abtretung_abgebrochen)
    ) {
      comment = appIntl().formatMessage(
        messages.redux_audit_abtretung_aufgrund_einer_kuendigung_abgebrochen
      );
    }

    let formatted = {
      timestamp,
      datum: convertTimestampToDate(timestamp),
      uhrzeit: convertTimestampToTime(timestamp),
      benutzerkennung: who,
      ereignis: eventAction.description,
      aenderungen: formatChangeObj(changes),
      ablehnungsgrund: comment,
      isFeEvent: false,
      prio: eventAction.prio,
    };
    result.push(formatted);
  }

  for (let entry of fourEyesEvents) {
    let benutzerkennung = yield getBenutzerkennung(entry, "fourEyes");
    who = benutzerkennung;
    let timestamp = yield call(getTimestampFromBlock, entry.blockNumber);
    let eventAction = yield call(getCessionsFourEyesDescription, entry.returnValues.state);
    let formatted = {
      timestamp,
      datum: convertTimestampToDate(timestamp),
      uhrzeit: convertTimestampToTime(timestamp),
      benutzerkennung: who,
      ereignis: eventAction.description,
      aenderungen: [],
      ablehnungsgrund: "",
      isFeEvent: true,
      prio: eventAction.prio,
    };
    result.push(formatted);
  }

  return result.sort(compareHistoryEvents);
}

function* fetchCessionServerHistory(arranger, dgOld, ssdId, cessionEvents) {
  let changeList = [];
  for (let entry of cessionEvents) {
    changeList.push({
      who: entry.returnValues.who,
      oldFingerprint: entry.returnValues.oldHash,
      newFingerprint: entry.returnValues.newHash,
    });
  }
  const request = {
    ssdId: Number(ssdId),
    cessionId: Number(toUtf8(cessionEvents[0].returnValues.cessionId)),
    changeList,
  };
  const { data } = yield call(getCessionAuditHistory, arranger, dgOld, ssdId, request);
  return data;
}

function* requestCessionHistory({ arranger, dgOld, ssdId, cessionId }) {
  try {
    let isDgOld = yield call(isInCompany, dgOld);
    const cessionAuditEvents = yield call(
      getCessionAuditEventFromBc,
      toHex(`${ssdId}`),
      toHex(`${cessionId}`)
    );
    const fourEyesEvents = yield call(ssdFourEyesEvents, toHex(`${ssdId}`), toHex(`${cessionId}`));
    const serverHistory = yield call(
      fetchCessionServerHistory,
      arranger,
      dgOld,
      ssdId,
      cessionAuditEvents
    );
    const result = yield call(
      formatCessionAuditEvents,
      cessionAuditEvents,
      fourEyesEvents,
      serverHistory,
      isDgOld,
      dgOld,
      ssdId,
      cessionId
    );
    yield put(cesstionHistoryActions.success(result));
    yield put(cessionHistoryModalActions.open());
  } catch (error) {
    console.error(error);
    yield put(cesstionHistoryActions.failure(error));
  }
}

function getAuditEventDescripion(action, state) {
  if (state === InterestNotificationState.NEW) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_erfasst),
      prio: 1,
    };
  }
  if (state === InterestNotificationState.APPROVED) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_bestaetigt),
      prio: 10,
    };
  }
  if (action === InterestNotificationAction.REJECT_OFFER) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_abgelehnt),
      prio: 10,
    };
  }
  if (action === InterestNotificationAction.CANCEL) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_storniert),
      prio: 10,
    };
  }
  if (action === InterestNotificationAction.CLOSE) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_ungueltig_gemacht),
      prio: 10,
    };
  }
  if (action === InterestNotificationAction.MODIFY) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_bearbeitet),
      prio: 5,
    };
  }
  return `action: ${action}; state: ${state}`;
}

function getInterestFourEyesDescription(state) {
  if (state === FourEyesState.INTEREST_NOTIFICATION_OFFER_FIRST) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_erste_bestaetigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.INTEREST_NOTIFICATION_OFFER_SECOND) {
    return {
      description: appIntl().formatMessage(messages.redux_audit_zinsmitteilung_zweite_bestaetigung),
      prio: 10,
    };
  }
  if (state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_zinsmitteilung_bestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }
  if (state === FourEyesState.INTEREST_NOTIFICATION_OFFER_CANCELLED_ON_MODIFY) {
    return {
      description: appIntl().formatMessage(
        messages.redux_audit_zinsmitteilung_bestaetigung_zurueckgezogen
      ),
      prio: 2,
    };
  }
}

function* formatInterestAuditEvents(auditEvents, fourEyesEvents, serverHistory, isDgOld) {
  let result = [];
  let changes;
  let who;

  for (let [index, entry] of auditEvents.entries()) {
    if (
      entry.returnValues.action === InterestNotificationAction.MODIFY &&
      !isDgOld &&
      !checkIfEditIsAfterReject(index, auditEvents, InterestNotificationAction.REJECT_OFFER)
    ) {
      continue;
    }

    changes = [];
    who = "n.A.";
    for (let change of serverHistory.changeList) {
      if (
        entry.returnValues.newHash === change.newFingerprint &&
        entry.returnValues.oldHash === change.oldFingerprint &&
        change.changes.length > 0
      ) {
        changes = [...change.changes];
        who = change.who;
      } else {
        if (who === "n.A.") {
          who = yield getBenutzerkennung(entry, "audit");
        }
      }
    }
    let timestamp = yield call(getTimestampFromBlock, entry.blockNumber);
    let eventAction = yield call(
      getAuditEventDescripion,
      entry.returnValues.action,
      entry.returnValues.state
    );
    let formatted = {
      timestamp,
      datum: convertTimestampToDate(timestamp),
      uhrzeit: convertTimestampToTime(timestamp),
      benutzerkennung: who,
      ereignis: eventAction.description,
      aenderungen: formatChangeObj(changes),
      ablehnungsgrund: "",
      isFeEvent: false,
      prio: eventAction.prio,
    };
    result.push(formatted);
  }

  for (let entry of fourEyesEvents) {
    let benutzerkennung = yield getBenutzerkennung(entry, "fourEyes");
    who = benutzerkennung;
    let timestamp = yield call(getTimestampFromBlock, entry.blockNumber);
    let eventAction = yield call(getInterestFourEyesDescription, entry.returnValues.state);
    let formatted = {
      timestamp,
      datum: convertTimestampToDate(timestamp),
      uhrzeit: convertTimestampToTime(timestamp),
      benutzerkennung: who,
      ereignis: eventAction.description,
      aenderungen: [],
      ablehnungsgrund: "",
      isFeEvent: true,
      prio: eventAction.prio,
    };
    result.push(formatted);
  }

  return result.sort(compareHistoryEvents);
}

function formatChangeObj(changes) {
  let numberFields = [
    "nominal",
    "kurs",
    "kaufpreis",
    "zinssatz",
    "ruecknahmekurs",
    "ruecknahmebetrag",
    "aufgelaufene_stueckzinsen",
    "abschlag",
    "aufschlag",
    "maximumzinssatz",
    "minimumzinssatz",
    "faktor",
    "mindestbetrag_bei_abtretung",
    "referenzZinssatz",
    "zinsSatz",
    "zinsBetrag",
    "abgetretenes_nominal",
    "abtretungskurs",
    "gegenwert_abtretung",
    "stueckzinsen",
  ];

  let dateFields = [
    "handelstag",
    "valuta",
    "endfaelligkeit",
    "feststellungsTag",
    "zinsperiodeVon",
    "zinsperiodeBis",
    "zahltag",
    "naechsteFestsetzung",
    "datum_abtretung",
    "valuta_abtretung",
    "valuta_stueckzinsen",
    "naechster_zinstermin",
  ];

  let timeFields = ["uhrzeit"];

  return changes.map((obj) => {
    if (numberFields.includes(obj.field))
      return {
        field: obj.field,
        oldValue: addDotsComma(obj.oldValue),
        newValue: addDotsComma(obj.newValue),
      };
    if (dateFields.includes(obj.field))
      return {
        field: obj.field,
        oldValue: formatDate(new Date(Number(obj.oldValue))),
        newValue: formatDate(new Date(Number(obj.newValue))),
      };
    if (timeFields.includes(obj.field))
      return {
        field: obj.field,
        oldValue:
          obj.oldValue === "null"
            ? appIntl().formatMessage(messages.redux_audit_nicht_erfasst)
            : obj.oldValue.replace(/"/g, ""),
        newValue:
          obj.newValue === "null"
            ? appIntl().formatMessage(messages.redux_audit_nicht_erfasst)
            : obj.newValue.replace(/"/g, ""),
      };
    return obj;
  });
}

function* fetchInterestServerHistory(arranger, ssdId, auditEvents) {
  let changeList = [];
  for (let entry of auditEvents) {
    changeList.push({
      who: entry.returnValues.who,
      oldFingerprint: entry.returnValues.oldHash,
      newFingerprint: entry.returnValues.newHash,
    });
  }
  const request = {
    ssdId: Number(ssdId),
    zinsmitteilungid: Number(toUtf8(auditEvents[0].returnValues.interestNotificationId)),
    changeList,
  };
  const { data } = yield call(getInterestAuditHistory, arranger, ssdId, request);
  return data;
}

function* requestInterestHistory({ arranger, ssdId, notification }) {
  try {
    const auditEvents = yield call(
      getInterestNotificationAuditEventFromBc,
      toHex(ssdId),
      toHex(`${notification.zinsmitteilungid}`)
    );
    const fourEyesEvents = yield call(
      ssdFourEyesEvents,
      toHex(ssdId),
      toHex(`${notification.zinsmitteilungid}`)
    );
    const serverHistory = yield call(fetchInterestServerHistory, arranger, ssdId, auditEvents);
    let isDgOld = yield call(isInCompany, arranger);

    const result = yield call(
      formatInterestAuditEvents,
      auditEvents,
      fourEyesEvents,
      serverHistory,
      isDgOld
    );
    yield put(interestHistoryActions.success(result));
    yield put(interestHistoryModalActions.open());
  } catch (error) {
    console.error(error);
    yield put(interestHistoryActions.failure(error));
  }
}

//===========================================================================
function getSsdAuditEvent(states, action, stateType, isFirstEvent, isFirstRebuyEvent) {
  let result = "";
  let prio = 10;

  //-----------------------   Termination   -------------------------

  if (stateType === SSDStateType.TERMINATION) {
    if (action === SSDTerminationAction.MODIFY) {
      return {
        description: appIntl().formatMessage(messages.redux_audit_kuendigung_erfasst),
        prio: 1,
      };
    }
    if (action === SSDTerminationAction.DELETE) {
      return {
        description: appIntl().formatMessage(messages.redux_audit_kuendigung_storniert),
        prio: 10,
      };
    }
    if (action === SSDTerminationAction.ABORT) {
      return {
        description: appIntl().formatMessage(messages.redux_audit_kuendigung_abgebrochen),
        prio: 10,
      };
    }
    if (action === SSDTerminationAction.APPROVE) {
      return {
        description: appIntl().formatMessage(messages.redux_audit_kuendigung_bestaetigt),
        prio: 10,
      };
    }
  }

  //-----------------------   SSD   -------------------------

  if (stateType === SSDStateType.SSD) {
    if (states[0] === SSDState.IN_WORK && isFirstEvent) {
      result = appIntl().formatMessage(messages.redux_audit_erfasst);
      prio = 1;
    }
    if (states[0] === SSDState.IN_WORK && action === SSDActions.MODIFY && !isFirstEvent) {
      result = appIntl().formatMessage(messages.redux_audit_bearbeitet);
      prio = 5;
    }
    if (states[0] === SSDState.OPEN && action === SSDActions.OFFER)
      result = appIntl().formatMessage(messages.redux_audit_zweitbestaetigt);

    if (states[0] === SSDState.APPROVED)
      result = appIntl().formatMessage(messages.redux_audit_zweitgegenbestaetigt);

    if (states[0] === SSDState.IN_WORK && action === SSDActions.REJECT_OFFER)
      result = appIntl().formatMessage(messages.redux_audit_abgelehnt);

    if (states[0] === SSDState.CANCELLED)
      result = appIntl().formatMessage(messages.redux_audit_storniert);

    if (states[0] === SSDState.DOCUMENT_SIGNED && action === SSDActions.SIGN_DOCUMENT)
      result = appIntl().formatMessage(messages.redux_audit_urkunde_ausgestellt);

    if (states[0] === SSDState.DOCUMENT_SIGNED && action === SSDActions.SIGN_DOCUMENT_CESSION)
      return {
        description: appIntl().formatMessage(messages.redux_audit_abtretungszertifikat_ausgestellt),
        prio: 10,
      };
  }

  //----------------------   Payment   ------------------------
  if (
    stateType === SSDStateType.SSD &&
    states[1] === SSDPaymentState.PAID &&
    action !== SSDPaymentAction.PAYMENT_CORRECTION
  )
    result += " / " + appIntl().formatMessage(messages.redux_audit_zahlung_erhalten);

  if (
    states[1] === SSDPaymentState.AWAITING_PAYMENT &&
    action !== SSDPaymentAction.PAYMENT_CORRECTION
  )
    result += " / " + appIntl().formatMessage(messages.redux_audit_zahlung_ausstehend);

  if (stateType === SSDStateType.PAYMENT) {
    if (states[1] === SSDPaymentState.PAID && action !== SSDPaymentAction.PAYMENT_CORRECTION)
      result = appIntl().formatMessage(messages.redux_audit_zahlung_erhalten);

    if (
      states[1] === SSDPaymentState.AWAITING_PAYMENT &&
      action === SSDPaymentAction.PAYMENT_CORRECTION
    )
      result = appIntl().formatMessage(messages.redux_audit_zahlung_nicht_erhalten);
  }

  //----------------------   Rebuy   -------------------------

  if (stateType === SSDStateType.REBUY) {
    if (
      states[2] === SSDRebuyState.IN_WORK &&
      action === SSDRebuyAction.MODIFY &&
      isFirstRebuyEvent
    ) {
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_erfasst);
      prio = 1;
    }

    if (
      states[2] === SSDRebuyState.IN_WORK &&
      action === SSDRebuyAction.MODIFY &&
      !isFirstRebuyEvent
    ) {
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_bearbeitet);
      prio = 5;
    }

    if (states[2] === SSDRebuyState.OPEN && action === SSDRebuyAction.OFFER)
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_zweitbestaetigt);

    if (states[2] === SSDRebuyState.APPROVED)
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_zweitgegenbestaetigt);
    if (states[2] === SSDRebuyState.IN_WORK && action === SSDRebuyAction.REJECT_OFFER)
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_abgelehnt);

    if (states[2] === SSDRebuyState.CANCELLED)
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_storniert);
    if (states[2] === SSDRebuyState.ABORTED)
      result = appIntl().formatMessage(messages.redux_audit_rueckkauf_abgebrochen);
  }
  //---------------------- Rebuy Payment   ------------------------

  if (stateType === SSDStateType.PAYMENT)
    if (states[1] === SSDPaymentState.REPAID && action === SSDPaymentAction.REPAYMENT_RECEIVED)
      result = appIntl().formatMessage(messages.redux_audit_rueckzahlung_erhalten);

  //-----------------------   Cession   -------------------------
  if (stateType === SSDStateType.CESSION) {
    if (action === CessionAction.NOTIFY) {
      return { description: appIntl().formatMessage(messages.redux_audit_abgetreten), prio: 10 };
    }
  }

  return { description: result, prio };
}
//===========================================================================
function getSsdFourEyeEvent(state) {
  let result = "";
  let prio = 10;

  if (state === FourEyesState.TERMINATION_APPROVE_FIRST)
    result = appIntl().formatMessage(messages.redux_audit_kuendigung_erste_bestaetigung);

  if (state === FourEyesState.TERMINATION_APPROVE_SECOND)
    result = appIntl().formatMessage(messages.redux_audit_kuendigung_zweite_bestaetigung);

  if (state === FourEyesState.TERMINATION_APPROVE_CANCELLED)
    result = appIntl().formatMessage(messages.redux_audit_kuendigung_storniert);

  if (state === FourEyesState.TERMINATION_APPROVE_CANCELLED_ON_MODIFY)
    result = appIntl().formatMessage(messages.redux_audit_kuendigung_storniert);

  if (state === FourEyesState.TERMINATION_APPROVE_CANCELLED_ON_DELETE)
    result = appIntl().formatMessage(messages.redux_audit_kuendigung_storniert);

  if (state === FourEyesState.OFFER_FIRST)
    result = appIntl().formatMessage(messages.redux_audit_erstbestaetigt);

  // if (state === FourEyesState.OFFER_SECOND) result = "Zweitbestätigt";
  // It is no need. Because it has covered in getSsdEvent function

  if (state === FourEyesState.OFFER_CANCELLED) {
    result = appIntl().formatMessage(messages.redux_audit_erstbestaetigung_zurueckgezogen);
    prio = 2;
  }

  if (state === FourEyesState.OFFER_CANCELLED_ON_MODIFY) {
    result = appIntl().formatMessage(
      messages.redux_audit_erstbestaetigung_automatisch_zurueckgezogen
    );
    prio = 2;
  }

  if (state === FourEyesState.ACCEPT_FIRST)
    result = appIntl().formatMessage(messages.redux_audit_erstgegenbestaetigt);

  // if (state === FourEyesState.ACCEPT_SECOND) result = "Zweitgegenbestätigt";
  // It is no need. Because it has covered in getSsdEvent function

  if (state === FourEyesState.ACCEPT_CANCELLED) {
    result = appIntl().formatMessage(messages.redux_audit_erstgegenbestaetigung_zurueckgezogen);
    prio = 2;
  }

  if (state === FourEyesState.ACCEPT_CANCELLED_ON_REJECT) {
    result = appIntl().formatMessage(
      messages.redux_audit_erstgegenbestaetigung_automatisch_zurueckgezogen
    );
    prio = 2;
  }

  //----------------------   Rebuy   -------------------------

  if (state === FourEyesState.REBUY_OFFER_FIRST)
    result = appIntl().formatMessage(messages.redux_audit_rueckkauf_erstbestaetigt);

  // if (state === FourEyesState.REBUY_OFFER_SECOND) result = "Rückkauf zweitbestätigt";
  // It is no need. Because it has covered in getSsdEvent function

  if (state === FourEyesState.REBUY_OFFER_CANCELLED) {
    result = appIntl().formatMessage(
      messages.redux_audit_rueckkauf_erstbestaetigung_zurueckgezogen
    );
    prio = 2;
  }

  if (state === FourEyesState.REBUY_OFFER_CANCELLED_ON_MODIFY) {
    prio = 2;
    result = appIntl().formatMessage(
      messages.redux_audit_rueckkauf_erstbestaetigung_automatisch_zurueckgezogen
    );
  }

  if (state === FourEyesState.REBUY_ACCEPT_FIRST)
    result = appIntl().formatMessage(messages.redux_audit_rueckkauf_erstgegenbestaetigt);

  // if (state === FourEyesState.REBUY_ACCEPT_SECOND) result = "Rückkauf zweitgegenbestätigt";
  // It is no need. Because it has covered in getSsdEvent function

  if (state === FourEyesState.REBUY_ACCEPT_CANCELLED) {
    result = appIntl().formatMessage(
      messages.redux_audit_rueckkauf_erstgegenbestaetigung_zurueckgezogen
    );
    prio = 2;
  }

  if (state === FourEyesState.REBUY_ACCEPT_CANCELLED_ON_REJECT) {
    result = appIntl().formatMessage(
      messages.redux_audit_rueckkauf_erstgegenbestaetigung_automatisch_zurueckgezogen
    );
    prio = 2;
  }

  return { description: result, prio };
}
//===========================================================================
async function prepareDataToSendToServer(id, auditEvents) {
  let tempArray = [];

  for (let event of auditEvents) {
    const transactionChange = {
      action: event.returnValues.action,
      stateType: event.returnValues.stateType,
      who: event.returnValues.who,

      oldFingerprint: event.returnValues.oldSsdHash,
      newFingerprint: event.returnValues.newSsdHash,

      oldRebuyHash: event.returnValues.oldRebuyHash,
      newRebuyHash: event.returnValues.newRebuyHash,

      oldTerminationHash: event.returnValues.oldTerminationHash,
      newTerminationHash: event.returnValues.newTerminationHash,
    };
    tempArray.push(transactionChange);
  }

  const ssdInfo = { ssdId: id, transactionChanges: tempArray };
  return ssdInfo;
}
//===========================================================================
function compareHistoryEvents(a, b) {
  if (a.timestamp < b.timestamp) return 1;
  if (a.timestamp > b.timestamp) return -1;
  if (a.timestamp === b.timestamp) {
    if (a.prio < b.prio) return 1;
    if (a.prio > b.prio) return -1;
  }
  return 0;
}
//===========================================================================
function* getEvent(event, type, isFirstEvent, isFirstRebuyEvent) {
  if (type === "audit") {
    return yield getSsdAuditEvent(
      event.returnValues.states,
      event.returnValues.action,
      event.returnValues.stateType,
      isFirstEvent,
      isFirstRebuyEvent
    );
  } else return yield getSsdFourEyeEvent(event.returnValues.state);
}
//===========================================================================
const NAME_CACHE = [];
//===========================================================================
function* getBenutzerkennung(event, type) {
  let who, idProxy, result;

  if (type === "audit") {
    who = event.returnValues.who;
    idProxy = event.returnValues.idProxy;
  } else {
    who = event.returnValues.account;
    idProxy = getIdProxyAddr();
  }

  for (const name of NAME_CACHE) {
    if (who === name.who && idProxy === name.idProxy) {
      return name.kennung;
    }
  }

  if (idProxy === getIdProxyAddr()) {
    let response = yield call(getAccountInfo, who, idProxy);
    result = response.data.benutzerkennung;
    NAME_CACHE.push({ who, idProxy, kennung: result });
  } else result = "n.A.";

  return result;
}
//===========================================================================
function formatBemerkungDateString(dateStr) {
  let timestamp;

  let ua = window.navigator.userAgent;
  let msie = ua.indexOf("MSIE ");
  let new_ie = ua.indexOf("Trident/");

  //ie
  if (msie > 0 || new_ie > 0) {
    const TIMEZONE_SUFFIX = "Z";
    const toFormat = dateStr + TIMEZONE_SUFFIX;
    timestamp = new Date(toFormat.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")) / 1000 - 7200;
  }

  //chrome + firefox
  else {
    timestamp = new Date(dateStr) / 1000;
  }
  return timestamp;
}
//===========================================================================
function* getEventDetail(event, serverHistory, type, isFirstEvent, isFirstRebuyEvent) {
  let timestamp = yield getTimestampFromBlock(event.blockNumber);
  let ssdEvent = yield getEvent(event, type, isFirstEvent, isFirstRebuyEvent);
  let benutzerkennung = yield getBenutzerkennung(event, type);
  const ssd = yield call(getSsdState, event.returnValues.arranger, toUtf8(event.returnValues.id));

  let comment = "";
  let minDiff = 100000;
  if (
    ssdEvent.description.startsWith(appIntl().formatMessage(messages.redux_audit_abgelehnt)) ||
    ssdEvent.description.startsWith(
      appIntl().formatMessage(messages.redux_audit_rueckkauf_abgelehnt)
    )
  ) {
    const ablehnungen = yield call(
      fetchAllRejectReason,
      serverHistory.data.ssdId,
      event.returnValues.newSsdHash,
      event.returnValues.arranger,
      ssd.rebuyHash
    );

    if (ablehnungen.data) {
      for (let ablehnungsData of [].concat(ablehnungen.data)) {
        const diff = Math.abs(timestamp - formatBemerkungDateString(ablehnungsData.created));
        if (diff < minDiff) {
          minDiff = diff;
          comment = ablehnungsData.kommentar;
        }
      }
    }
  }

  if (
    ssdEvent.description === appIntl().formatMessage(messages.redux_audit_kuendigung_abgebrochen)
  ) {
    comment = appIntl().formatMessage(
      messages.redux_audit_kuendigung_aufgrund_einer_abtretung_abgebrochen
    );
  }

  let result = {
    datum: convertTimestampToDate(timestamp),
    uhrzeit: convertTimestampToTime(timestamp),
    benutzerkennung: benutzerkennung,
    ereignis: ssdEvent.description,
    aenderungen: [],
    ablehnungsgrund: comment,
    timestamp,
    isFeEvent: type === "fourEyes",
    prio: ssdEvent.prio,
  };

  for (let transChange of serverHistory.data.transactionChanges)
    if (
      transChange.newFingerprint === event.returnValues.newSsdHash &&
      transChange.oldFingerprint === event.returnValues.oldSsdHash &&
      transChange.changes.length > 0
    ) {
      result.aenderungen = formatChangeObj(transChange.changes);
    }

  return result;
}
//===========================================================================
function* mergeResult(serverHistory, auditHistory, fourEyesHistory, type, arranger) {
  let result = [];
  let isFirstEvent = true;
  let isFirstRebuyEvent = true;
  let blockchainHistory = type === "audit" ? auditHistory : fourEyesHistory;
  let isDgOld = yield call(isInCompany, arranger);

  for (let [index, event] of blockchainHistory.entries()) {
    if (
      (type === "audit" &&
        event.returnValues.stateType === SSDStateType.SSD &&
        event.returnValues.action === SSDActions.MODIFY &&
        !isDgOld &&
        !checkIfEditIsAfterReject(index, blockchainHistory, SSDActions.REJECT_OFFER)) ||
      (event.returnValues.stateType === SSDStateType.TERMINATION &&
        event.returnValues.action === SSDTerminationAction.DELETE)
    ) {
      continue;
    }

    let isDuplicatedEvent = false;
    let state = "";
    let duplicateStates = new Set([
      FourEyesState.OFFER_SECOND,
      FourEyesState.ACCEPT_SECOND,
      FourEyesState.REBUY_OFFER_SECOND,
      FourEyesState.REBUY_ACCEPT_SECOND,
    ]);

    if (type === "fourEyes") {
      isDuplicatedEvent = auditHistory.some((x) => x.blockNumber === event.blockNumber);

      state = event.returnValues.state;
    }
    if (
      type === "audit" ||
      (type === "fourEyes" && !(isDuplicatedEvent && duplicateStates.has(state)))
    ) {
      let entry = yield getEventDetail(event, serverHistory, type, isFirstEvent, isFirstRebuyEvent);
      result.push(entry);

      isFirstEvent = false;
      if (entry.ereignis === appIntl().formatMessage(messages.redux_audit_rueckkauf_erfasst))
        isFirstRebuyEvent = false;
      if (entry.ereignis === appIntl().formatMessage(messages.redux_audit_rueckkauf_storniert))
        isFirstRebuyEvent = true;
    }
  }

  return result;
}
//===========================================================================

function* requestHistory({ ssdId, arranger }) {
  try {
    // Get data from Blockchain through two Events
    const bcId = toHex(ssdId);
    const auditHistory = yield call(ssdAuditEvents, bcId);
    const fourEyesHistory = yield call(ssdFourEyesEvents, bcId, "0x0");

    // Get data from Server
    let ssdInfo = yield prepareDataToSendToServer(ssdId, auditHistory);
    const serverHistory = yield call(getHistory, ssdInfo, arranger);

    // Merge results from Blockchain and server
    let finalHistory = [];

    let auditEventsResult = yield mergeResult(
      serverHistory,
      auditHistory,
      fourEyesHistory,
      "audit",
      arranger
    );

    let fourEyesEventsResult = yield mergeResult(
      serverHistory,
      auditHistory,
      fourEyesHistory,
      "fourEyes",
      arranger
    );

    finalHistory = auditEventsResult.concat(fourEyesEventsResult);
    const sortedHistory = finalHistory.sort(compareHistoryEvents);
    yield put(historyActions.success(sortedHistory));
  } catch (error) {
    console.error(error);
    yield put(historyActions.failure(error));
  }
}
