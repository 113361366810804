import { call, takeLatest, put } from "redux-saga/effects";

import {
  WALLET_SAVE_REQUEST,
  FETCH_PENDING_WALLET_REQUEST,
  ACCEPT_PENDING_WALLET_REQUEST,
  REJECT_PENDING_WALLET_REQUEST,
} from "./actions";

import {
  fetchPendingWalletActions,
  acceptPendingWalletActions,
  rejectPendingWalletActions,
  saveWalletActions,
} from "./actions";

import {
  saveWallet,
  getPendingWallets,
  acceptWallet,
  rejectWallet,
  fetchMaInviteData,
  postMaInviteData,
  fetchPlatformMemberData,
  postPlatformMemberData,
} from "services/walletService";
import { getIdProxyAddr } from "services/web3Services/commons";
import { onboardMember } from "services/web3Services/onboarding";
import { snackbarActions } from "redux/shared/actions";
import { registrationConfirmModalActions } from "redux/walletRegistration/actions";
import { evictAllPermissionCache } from "services/permissionCacheService";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const walletEventSagas = [
  takeLatest(WALLET_SAVE_REQUEST, saveWalletService),
  takeLatest(FETCH_PENDING_WALLET_REQUEST, fetchPendingWallets),
  takeLatest(ACCEPT_PENDING_WALLET_REQUEST, acceptPendingWallet),
  takeLatest(REJECT_PENDING_WALLET_REQUEST, rejectPendingWallet),
];

export function* fetchPendingWallets() {
  try {
    const pendingWallets = yield call(getPendingWallets, getIdProxyAddr());
    yield put(fetchPendingWalletActions.success(pendingWallets.data));
  } catch (err) {
    console.error(err);
    yield put(fetchPendingWalletActions.failure(err));
  }
}

export function* acceptPendingWallet({
  account,
  walletAddr,
  backend_url_id_proxy,
  vier_augen_bestaetigung,
  recovererAddr,
}) {
  try {
    const companyProxyAddr = yield call(
      onboardMember,
      account,
      walletAddr,
      backend_url_id_proxy,
      vier_augen_bestaetigung,
      recovererAddr
    );
    let platformMemberData = yield call(fetchPlatformMemberData, walletAddr, getIdProxyAddr());
    platformMemberData = {
      ...platformMemberData.data,
      idproxy_adresse: companyProxyAddr,
    };
    yield call(postPlatformMemberData, platformMemberData, backend_url_id_proxy, companyProxyAddr);
    let maInviteData = yield call(fetchMaInviteData, walletAddr, getIdProxyAddr());
    maInviteData = {
      ...maInviteData.data,
      backendUrl: backend_url_id_proxy,
      walletAdresse: walletAddr,
    };
    yield call(postMaInviteData, maInviteData, backend_url_id_proxy, companyProxyAddr);
    yield call(acceptWallet, walletAddr, getIdProxyAddr());
    yield call(evictAllPermissionCache, getIdProxyAddr());
    yield put(acceptPendingWalletActions.success());
    //update table
    yield call(fetchPendingWallets);
    yield put(registrationConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_wallet_registration_anmeldung_bestaetigt)
      )
    );
  } catch (err) {
    console.error(err);
    yield put(acceptPendingWalletActions.failure(err));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_wallet_registration_anmeldung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* rejectPendingWallet({ walletAddr, rejectReason }) {
  try {
    yield call(rejectWallet, walletAddr, getIdProxyAddr(), rejectReason);
    yield put(rejectPendingWalletActions.success());
    //update table
    yield call(fetchPendingWallets);
    yield put(registrationConfirmModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_wallet_registration_anmeldung_abgelehnt)
      )
    );
  } catch (err) {
    yield put(rejectPendingWalletActions.failure(err));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_wallet_registration_anmeldung_konnte_nicht_abgelehnt_werden
        )
      )
    );
  }
}

export function* saveWalletService({ walletData, backendUrl }) {
  try {
    const response = yield call(saveWallet, walletData, backendUrl);
    if (response.status >= 200 && response.status < 300) {
      yield put(saveWalletActions.success(response.data));
    } else {
      throw response;
    }
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_wallet_registration_anmeldung_wurde_erfolgreich_gesendet
        )
      )
    );
  } catch (err) {
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_wallet_registration_anmeldung_konnte_nicht_gesendet_werden_bitte_ueberpruefen
        )
      )
    );
    yield put(saveWalletActions.failure(err));
  }
}
