import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { dashboard_styles } from "./dashboard_styles";
import MainListItems from "./MainListItems/MainListItems";
import { Router, Route, Switch } from "react-router-dom";
import history from "util/history";
import {
  getAccountActions,
  getCompanyActions,
  memberDataEventListenerActions,
} from "redux/account/actions";
import { getIdProxyAddr } from "services/web3Services/commons";
import { withStyles, CssBaseline, Drawer, List, Divider, Typography } from "@material-ui/core";
import AsyncComponent from "./AsyncComponent";
import Breadcrumb from "./Breadcrumb";
import { cssdEventActions } from "redux/cssdEvents/actions";
import { LocaleContext } from "components/i18n/LocaleContext";
import Logo from "components/Shared/Logo/Logo";

const asyncHome = AsyncComponent(() => import("components/Home/Home"));
const asyncBssdOverview = AsyncComponent(() => import("components/BssdOverview/SsdOverview"));
const asyncBssdRegister = AsyncComponent(() => import("components/BssdRegister/BssdRegister"));
const asyncCssdRegister = AsyncComponent(() => import("components/CssdRegister/CssdRegister"));
const asyncCession = AsyncComponent(() => import("components/Cession/Cession"));
const asyncCessionNotify = AsyncComponent(() => import("components/Cession/CessionNotify"));
const asyncColleague = AsyncComponent(() => import("components/Colleague/Colleague"));
const asyncPlatformMember = AsyncComponent(() =>
  import("components/PlatformMember/PlatformMember")
);
const asyncBusinessPartner = AsyncComponent(() =>
  import("components/BusinessPartner/BusinessPartner")
);
const asyncProfile = AsyncComponent(() => import("components/Profile/Profile"));
const asyncUserInformation = AsyncComponent(() =>
  import("components/UserInformation/UserInformationOverview")
);
const asyncMigrateCssdOverview = AsyncComponent(() =>
  import("components/Migrate/MigrateCssdOverview")
);
const asyncCessionOverview = AsyncComponent(() =>
  import("components/Cession/CessionOverview/CessionOverview")
);
const asyncInterestNotification = AsyncComponent(() =>
  import("components/InterestNotification/InterestNotification")
);
const asyncRecovery = AsyncComponent(() => import("components/Recovery/Recovery"));
const asyncCssdOverview = AsyncComponent(() => import("components/CssdOverview/CssdOverview"));
const asyncCssdTeilforderungenOverview = AsyncComponent(() =>
  import("components/CssdTeilforderungen/Overview/CssdTeilforderungenOverview")
);
const AsyncCssdTeilforderungCssdOverview = AsyncComponent(() =>
  import("components/CssdTeilforderungen/PartialClaimForCssd/PartialClaimForCssdOverview")
);
const AsyncCssdCessionOverview = AsyncComponent(() =>
  import("components/CssdCession/Overview/CssdCessionOverview")
);
const AsyncCssdCessionRegister = AsyncComponent(() =>
  import("components/CssdCession/Register/CssdCessionRegister")
);
const asyncCssdCessionRequestOverview = AsyncComponent(() =>
  import("components/CssdCession/RequestOverview/CssdCessionRequestOverview")
);
const AsyncCssdInfomaterialOverview = AsyncComponent(() =>
  import("components/CssdInformationsmaterial/Overview/CssdInformationsmaterialOverview")
);
const AsyncCssdInfomaterial = AsyncComponent(() =>
  import("components/CssdInformationsmaterial/Upload/CssdInformationsmaterialUpload")
);
const AsyncCssdInterestNotification = AsyncComponent(() =>
  import("components/CssdInterestNotification/InterestNotification")
);
const AsyncCssdAudit = AsyncComponent(() => import("components/CssdAudit/CssdAuditWrapper"));
const AsyncPartialClaimCessionAudit = AsyncComponent(() =>
  import("components/CssdAudit/PartialClaimCessionAuditWrapper")
);
const AsyncInterestnotificationAudit = AsyncComponent(() =>
  import("components/CssdAudit/InterestNotificationAuditWrapper")
);

function Dashboard({
  classes,
  location,
  getCompanyInfo,
  accountAddress,
  getAccountInfo,
  startMemberDataUpdateListener,
  stopMemberDataUpdateListener,
  startListener,
  stopListener,
  accountInfo,
  companyInfo,
}) {
  useEffect(() => {
    const ipa = getIdProxyAddr();
    getAccountInfo(accountAddress, ipa);
    if (ipa) {
      getCompanyInfo(ipa);
      startMemberDataUpdateListener();
    }
    return stopMemberDataUpdateListener;
  }, []);

  useEffect(() => {
    startListener(getIdProxyAddr());
    return stopListener;
  }, []);

  React.useContext(LocaleContext);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open
        >
          <Logo />
          <div className={classes.accountInfoWrapper}>
            <Typography variant="subtitle1">
              {accountInfo.vorname} {accountInfo.nachname}
            </Typography>
            <Typography variant="caption">
              {companyInfo ? <div>{companyInfo.name} </div> : ""}
            </Typography>
          </div>
          <Divider />
          <List>
            <MainListItems location={location} />
          </List>
        </Drawer>
        <Router history={history}>
          <main className={classes.content}>
            <Switch>
              <Route exact path="/Home" component={asyncHome} />
              <Route exact path="/bssdoverview" component={asyncBssdOverview} />
              <Route exact path="/bssd" component={asyncBssdRegister} />
              <Route exact path="/cssd" component={asyncCssdRegister} />
              <Route exact path="/cession/:ssdid" component={asyncCession} />
              <Route exact path="/cessionnotify/:ssdid" component={asyncCessionNotify} />
              <Route exact path="/colleague" component={asyncColleague} />
              <Route exact path="/platformmember" component={asyncPlatformMember} />
              <Route exact path="/businesspartner" component={asyncBusinessPartner} />
              <Route exact path="/recovery" component={asyncRecovery} />
              <Route exact path="/profile" component={asyncProfile} />
              <Route exact path="/userinformation" component={asyncUserInformation} />
              <Route exact path="/migrate" component={asyncMigrateCssdOverview} />
              <Route exact path="/cessionoverview" component={asyncCessionOverview} />
              <Route
                exact
                path="/interest-notification/:ssdid"
                component={asyncInterestNotification}
              />
              <Route exact path="/cssdoverview" component={asyncCssdOverview} />
              <Route
                exact
                path="/cssdpartialclaimoverview"
                component={asyncCssdTeilforderungenOverview}
              />
              <Route
                exact
                path="/cssdoverview/partialclaimforcssdoverview"
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncCssdTeilforderungCssdOverview} />
                )}
              />
              <Route
                exact
                path={[
                  "/cssdpartialclaimoverview/audit",
                  "/cssdoverview/partialclaimforcssdoverview/audit",
                  "/cssdpartialclaimoverview/cessionoverview/audit",
                  "/cssdoverview/partialclaimforcssdoverview/cessionoverview/audit",
                  "/cssdcessionrequest/audit",
                ]}
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncPartialClaimCessionAudit} />
                )}
              />
              <Route
                exact
                path="/cssdoverview/interestnotification"
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncCssdInterestNotification} />
                )}
              />
              <Route
                exact
                path="/cssdoverview/interestnotification/audit"
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncInterestnotificationAudit} />
                )}
              />
              <Route
                exact
                path="/cssdoverview/audit"
                render={(props) => <Breadcrumb {...props} component={AsyncCssdAudit} />}
              />
              <Route
                exact
                path={[
                  "/cssdpartialclaimoverview/cessionoverview",
                  "/cssdoverview/partialclaimforcssdoverview/cessionoverview",
                ]}
                render={(props) => <Breadcrumb {...props} component={AsyncCssdCessionOverview} />}
              />
              <Route exact path="/cssdcessionrequest" component={asyncCssdCessionRequestOverview} />
              <Route
                exact
                path={[
                  "/cssdpartialclaimoverview/cessionoverview/add",
                  "/cssdpartialclaimoverview/cessionoverview/nachdokumentieren",
                  "/cssdoverview/partialclaimforcssdoverview/cessionoverview/add",
                  "/cssdoverview/partialclaimforcssdoverview/cessionoverview/nachdokumentieren",
                ]}
                render={(props) => <Breadcrumb {...props} component={AsyncCssdCessionRegister} />}
              />
              <Route
                exact
                path={[
                  "/cssdpartialclaimoverview/cessionoverview/rebuy",
                  "/cssdoverview/partialclaimforcssdoverview/cessionoverview/rebuy",
                ]}
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncCssdCessionRegister} rebuy={true} />
                )}
              />
              <Route
                exact
                path={[
                  "/cssdoverview/informationmaterial/upload",
                  "/cssdpartialclaimoverview/informationmaterial/upload",
                  "/cssdoverview/partialclaimforcssdoverview/informationsmaterial/upload",
                ]}
                render={(props) => <Breadcrumb {...props} component={AsyncCssdInfomaterial} />}
              />
              <Route
                exact
                path={[
                  "/cssdoverview/informationmaterial",
                  "/cssdpartialclaimoverview/informationmaterial",
                  "/cssdoverview/partialclaimforcssdoverview/informationmaterial",
                ]}
                render={(props) => (
                  <Breadcrumb {...props} component={AsyncCssdInfomaterialOverview} />
                )}
              />
            </Switch>
          </main>
        </Router>
      </div>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    accountAddress: state.signInReducer.account.address,
    idProxyAddress: state.signInReducer.account.address,
    accountInfo: state.accountReducer.accountInfo,
    companyInfo: state.accountReducer.companyInfo,
  };
};

const mapDispatchToProps = {
  getAccountInfo: getAccountActions.request,
  getCompanyInfo: getCompanyActions.request,
  startMemberDataUpdateListener: memberDataEventListenerActions.start,
  stopMemberDataUpdateListener: memberDataEventListenerActions.stop,
  startListener: cssdEventActions.startEventListener,
  stopListener: cssdEventActions.stopEventListener,
};

export default withStyles(dashboard_styles)(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
