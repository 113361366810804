import { all } from "redux-saga/effects";
import { signInSagas } from "./signIn/sagas";
import { ssdEventSagas } from "./ssdEvents/sagas";
import { ssdStatusSagas } from "./ssdStatus/sagas";
import { cessionSagas } from "./cession/sagas";
import { walletEventSagas } from "./walletRegistration/sagas";
import { colleagueSagas } from "./colleague/sagas";
import { accountSagas } from "./account/sagas";
import { pdfSagas } from "./pdfVerification/sagas";
import { platformMemberSagas } from "./platformMember/sagas";
import { businessPartnerSagas } from "./businessPartner/sagas";
import { ssdPaymentStatusSagas } from "./ssdPaymentStatus/sagas";
import { interestNotificationSagas } from "./interestNotification/sagas";
import { terminationSagas } from "./termination/sagas";
import { auditSagas } from "./audit/sagas";
import { recoverySagas } from "./recovery/sagas";
import { cssdEventSagas } from "./cssdEvents/sagas";
import { cssdActionSagas } from "./cssdActions/sagas";
import { cssdTeilforderungenSagas } from "./cssdTeilforderungen/sagas";
import { cssdCessionSagas } from "./cssdCession/sagas";
import { cssdInfomaterialSagas } from "./cssdInformationsmaterial/sagas";
import { cssdAuditSagas } from "./cssdAudit/sagas";
import { cssdTerminationSagas } from "./cssdTermination/sagas";
import { userInformationSagas } from "./userInformation/sagas";

export function* rootSaga() {
  yield all([
    ...signInSagas,
    ...ssdEventSagas,
    ...ssdStatusSagas,
    ...cessionSagas,
    ...walletEventSagas,
    ...colleagueSagas,
    ...accountSagas,
    ...pdfSagas,
    ...platformMemberSagas,
    ...businessPartnerSagas,
    ...ssdPaymentStatusSagas,
    ...interestNotificationSagas,
    ...terminationSagas,
    ...auditSagas,
    ...recoverySagas,
    ...cssdEventSagas,
    ...cssdActionSagas,
    ...cssdTeilforderungenSagas,
    ...cssdCessionSagas,
    ...cssdInfomaterialSagas,
    ...cssdAuditSagas,
    ...cssdTerminationSagas,
    ...userInformationSagas,
  ]);
}
