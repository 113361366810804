import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, withStyles } from "@material-ui/core";
import BackButton from "components/Shared/Button/BackButton";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  assignGpToTradeActions,
  disconnectGPAndTradeActions,
  ASSIGN_GP_TO_TRADE_REQUEST,
  DISCONNECT_GP_AND_TRADE_REQUEST,
} from "redux/businessPartner/actions";
import { partnerCache } from "services/partnerService";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { FourEyesState, zeroAddress } from "util/constants";
import RejectButton from "components/Shared/Button/RejectButton";

const messages = defineMessages({
  title: {
    id: "assin.gp.dialog.title",
    defaultMessage: "Geschäftspartner zuordnen",
  },
  confirm: {
    id: "assin.gp.dialog.confirm",
    defaultMessage: "Erstbestätigen",
  },
  secondConfirm: {
    id: "assin.gp.dialog.secondConfirm",
    defaultMessage: "Zweitbestätigen",
  },
  disconnect: {
    id: "assin.gp.dialog.disconnect",
    defaultMessage: "Trennen",
  },
  cancel: {
    id: "assin.gp.dialog.cancel",
    defaultMessage: "Stornieren",
  },
  abort: {
    id: "assin.gp.dialog.abort",
    defaultMessage: "Abbrechen",
  },
  close: {
    id: "assin.gp.dialog.close",
    defaultMessage: "schließen",
  },
  darlehensnehmer: {
    id: "assin.gp.dialog.darlehensnehmer",
    defaultMessage: "Darlehensnehmer",
  },
  darlehensgeber: {
    id: "assin.gp.dialog.darlehensgeber",
    defaultMessage: "Darlehensgeber",
  },
  lei: {
    id: "assin.gp.dialog.lei",
    defaultMessage: "LEI:",
  },
  umsatzsteuer_id: {
    id: "assin.gp.dialog.umsatzsteuer_id",
    defaultMessage: "Umsatzsteuer ID:",
  },
  name: {
    id: "assin.gp.dialog.name",
    defaultMessage: "Name:",
  },
  kennung: {
    id: "assin.gp.dialog.kennung",
    defaultMessage: "Finledger-Kennung:",
  },
  anschrift: {
    id: "assin.gp.dialog.anschrift",
    defaultMessage: "Anschrift:",
  },
});

const style = (theme) => ({
  wrapper: {
    marginLeft: theme.spacing(4),
    marginTop: "16px",
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textField: {
    width: "100%",
  },
  addressLine: {
    whiteSpace: "pre-line",
  },
});

function AssignGPDialog({
  isOpen,
  isPending,
  classes,
  trade,
  close,
  companyInfo,
  assignGP,
  discconnectGP,
  account,
}) {
  const [open, setOpen] = useState(false);
  const [partners, setPartners] = useState(partnerCache);
  const [dnData, setDnData] = useState("");
  const [isFourEyesPending, setIsFourEyesPending] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const { gpConnectConfirmer } = trade;

  useEffect(() => {
    setOpen(isOpen);
    setPartners(partnerCache);
  }, [isOpen]);

  useEffect(() => {
    initFromTrade(trade);
  }, [trade]);

  const initFromTrade = (trade) => {
    setIsFourEyesPending(false);
    setIsConnected(false);
    if (
      trade.gpConnectFourEyesState === FourEyesState.CONNECT_GP_FIRST &&
      trade.gpConnectConfirmer !== zeroAddress
    ) {
      setIsFourEyesPending(true);
    } else if (trade.connectedGP && trade.connectedGP !== zeroAddress) {
      setIsConnected(true);
    }
    const partner = partnerCache.filter((member) => member.idProxyAddress === trade.connectedGP)[0];
    setDnData(partner ? partner : "");
  };

  const handleConfirm = () => {
    assignGP(dnData, trade);
  };

  const handleDisconnect = () => {
    discconnectGP(trade);
  };

  const handleClose = () => {
    close();
    setOpen(false);
    setIsFourEyesPending(false);
    setIsConnected(false);
    setDnData("");
    setPartners([]);
  };

  const handleChange = (e) => {
    const raw = e.target.value;
    setDnData(raw);
  };

  const disabled =
    isPending === DISCONNECT_GP_AND_TRADE_REQUEST || isPending === ASSIGN_GP_TO_TRADE_REQUEST;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage {...messages.title} />
          {` - ${
            trade.urkundenRegisternummer ? trade.urkundenRegisternummer : trade.geschaeftsnummer
          }`}
        </DialogTitle>
        <DialogContent>
          <>
            <InputLabel required>
              {trade.id ? (
                <FormattedMessage {...messages.darlehensgeber} />
              ) : (
                <FormattedMessage {...messages.darlehensnehmer} />
              )}
            </InputLabel>
            <Select
              className={classes.textField}
              onChange={handleChange}
              value={dnData}
              disabled={isConnected || isFourEyesPending}
            >
              <MenuItem value="">---</MenuItem>
              {partners
                .filter((member) => member.name !== companyInfo.name)
                .map((member, index) => (
                  <MenuItem key={index} value={member} id={`darlehensnehmer-select-item-${index}`}>
                    {member.name}
                  </MenuItem>
                ))}
            </Select>
          </>
          <Box m={2} />
          {dnData !== "" && (
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormattedMessage {...messages.kennung} />
                  </TableCell>
                  <TableCell>{dnData.digitsKennung}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormattedMessage {...messages.name} />
                  </TableCell>
                  <TableCell>{dnData.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormattedMessage {...messages.lei} />
                  </TableCell>
                  <TableCell>{dnData.lei}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormattedMessage {...messages.umsatzsteuer_id} />
                  </TableCell>
                  <TableCell>{dnData.umsatzsteuer_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormattedMessage {...messages.anschrift} />
                  </TableCell>
                  <TableCell className={classes.addressLine}>{dnData.anschrift}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <BackButton onClick={handleClose} disabled={disabled}>
              <FormattedMessage {...messages.abort} />
            </BackButton>
          </div>

          {(isConnected || isFourEyesPending) && (
            <div className={classes.wrapper}>
              <RejectButton
                onClick={handleDisconnect}
                type="submit"
                variant="contained"
                disabled={disabled}
                autoFocus
              >
                {isConnected ? (
                  <FormattedMessage {...messages.disconnect} />
                ) : (
                  <FormattedMessage {...messages.cancel} />
                )}
              </RejectButton>
              {isPending === DISCONNECT_GP_AND_TRADE_REQUEST && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          )}

          {!isConnected && gpConnectConfirmer !== account.address && (
            <div className={classes.wrapper}>
              <Button
                onClick={handleConfirm}
                variant="contained"
                type="submit"
                color="primary"
                disabled={disabled || dnData === ""}
                autoFocus
              >
                {isFourEyesPending ? (
                  <FormattedMessage {...messages.secondConfirm} />
                ) : (
                  <FormattedMessage {...messages.confirm} />
                )}
              </Button>
              {isPending === ASSIGN_GP_TO_TRADE_REQUEST && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.signInReducer.account,
    isPending: state.businessPartnerReducer.isAssignGPToTradePending,
    isOpen: state.businessPartnerReducer.assignGPDialogOpen,
    trade: state.businessPartnerReducer.assignGPTrade,
    companyInfo: state.accountReducer.companyInfo,
  };
};

const mapDispatchToProps = {
  close: assignGpToTradeActions.close,
  assignGP: assignGpToTradeActions.request,
  discconnectGP: disconnectGPAndTradeActions.request,
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(AssignGPDialog));
