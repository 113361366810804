import { takeLatest, call, put } from "redux-saga/effects";
import {
  MODIFY_SSD_REBUY_REQUEST,
  MODIYY_AND_OFFER_SSD_REBUY_REQUEST,
  CANCEL_SSD_REBUY_REQUEST,
  OFFER_SSD_REBUY_REQUEST,
  CANCEL_OFFER_SSD_REBUY_REQUEST,
  ACCEPT_SSD_REBUY_REQUEST,
  CANCEL_ACCEPT_SSD_REBUY_REQUEST,
  REJECT_SSD_REBUY_REQUEST,
  PAYMENT_RECEIVED_REQUEST,
  PAYMENT_CORRECTION_REQUEST,
  REPAYMENT_RECEIVED_REQUEST,
} from "./actions";
import {
  modifySsdRebuyActions,
  modifyAndOfferSsdRebuyActions,
  cancelSsdRebuyActions,
  offerSsdRebuyActions,
  cancelOfferSsdRebuyActions,
  acceptSsdRebuyActions,
  cancelAcceptSsdRebuyActions,
  rejectSsdRebuyActions,
  repaymentReceivedActions,
} from "./actions";
import { postRebuy, postRebuyRejectReason, createRebuyConfirmationPDF } from "services/ssdService";
import { FourEyesState, usecase } from "util/constants";
import { rebuyModalActions } from "redux/ssdEvents/actions";
import { rebuyPreviewModalActions } from "redux/ssdEvents/actions";
import { snackbarActions } from "redux/shared/actions";
import { paymentCorrectionActions, paymentReceivedActions } from "./actions";
import { toHex, getHashFromData, toSha3, getFourEyesState } from "services/web3Services/commons";
import {
  sendModifySsdRebuy,
  sendModifyAndOfferSsdRebuy,
  sendOfferSsdRebuy,
  sendCancelOfferSsdRebuy,
  sendCancelSsdRebuy,
  sendAcceptSsdRebuy,
  sendCancelAcceptSsdRebuy,
  sendRejectSsdRebuy,
  sendPaymentCorrection,
  sendPaymentReceived,
  sendRepaymentReceived,
} from "services/web3Services/paymentState";
import { sendMail } from "services/mailService";
import { mailTemplates } from "util/constants";
import { dateToTimestamp } from "util/convertor";
import { getSSDCessionData } from "services/web3Services/cession";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generateSsdRebuyFingerprint } from "util/fingerprint";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const ssdPaymentStatusSagas = [
  takeLatest(MODIFY_SSD_REBUY_REQUEST, modifySsdRequestHandler),
  takeLatest(MODIYY_AND_OFFER_SSD_REBUY_REQUEST, modifyAndOfferSsdRequestHandler),
  takeLatest(CANCEL_SSD_REBUY_REQUEST, cancelSsdRebuyRequestHandler),
  takeLatest(OFFER_SSD_REBUY_REQUEST, offerSsdRebuyRequestHandler),
  takeLatest(CANCEL_OFFER_SSD_REBUY_REQUEST, cancelOfferSsdRebuyRequestHandler),
  takeLatest(ACCEPT_SSD_REBUY_REQUEST, acceptSsdRebuyRequestHandler),
  takeLatest(CANCEL_ACCEPT_SSD_REBUY_REQUEST, cancelAcceptSsdRebuyRequestHandler),
  takeLatest(REJECT_SSD_REBUY_REQUEST, rejectSsdRebuyRequestHandler),
  takeLatest(PAYMENT_RECEIVED_REQUEST, sendPaymentRecceivedHandler),
  takeLatest(PAYMENT_CORRECTION_REQUEST, requestPaymentCorrectionHandler),
  takeLatest(REPAYMENT_RECEIVED_REQUEST, confirmRepayment),
];

// watcher saga: watches for actions dispatched to the store, starts worker saga

export function* checkActiveCessions(arranger, ssdId) {
  try {
    const ssdData = yield call(getSSDCessionData, arranger, ssdId);
    const activeCessionCount = ssdData.activeCessionCounter;
    return activeCessionCount === "0";
  } catch (error) {
    return false;
  }
}

export function* modifySsdRequestHandler({ account, ssdData, old_fingerprint_rebuy, rebuyData }) {
  try {
    const noActiveCessions = yield call(checkActiveCessions, ssdData.arranger, ssdData.ssdid);
    if (noActiveCessions) {
      const rebuyDataWithFingerprint = yield generateSsdRebuyFingerprint(rebuyData);

      yield call(
        postRebuy,
        ssdData.ssdid,
        ssdData.fingerprint,
        old_fingerprint_rebuy,
        rebuyDataWithFingerprint,
        ssdData.arranger
      );

      yield call(
        sendModifySsdRebuy,
        account,
        toHex(ssdData.ssdid),
        rebuyDataWithFingerprint.fingerprint,
        dateToTimestamp(rebuyData.valuta_ruecknahmebetrag)
      );
      yield put(modifySsdRebuyActions.success());
      yield put(rebuyModalActions.close());
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(messages.redux_ssd_payment_status_rueckkauf_gespeichert)
        )
      );
    } else {
      yield put(modifySsdRebuyActions.failure());
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(
            messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_gespeichert_werden
          )
        )
      );
    }
  } catch (error) {
    console.error(error);
    yield put(modifySsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_gespeichert_werden
        )
      )
    );
  }
}

export function* modifyAndOfferSsdRequestHandler({
  account,
  ssdData,
  old_fingerprint_rebuy,
  rebuyData,
}) {
  try {
    const noActiveCessions = yield call(checkActiveCessions, ssdData.arranger, ssdData.ssdid);
    if (noActiveCessions) {
      const rebuyDataWithFingerprint = yield generateSsdRebuyFingerprint(rebuyData);

      yield call(
        postRebuy,
        ssdData.ssdid,
        ssdData.fingerprint,
        old_fingerprint_rebuy,
        rebuyDataWithFingerprint,
        ssdData.arranger
      );
      yield call(
        sendModifyAndOfferSsdRebuy,
        account,
        toHex(ssdData.ssdid),
        rebuyDataWithFingerprint.fingerprint,
        dateToTimestamp(rebuyData.valuta_ruecknahmebetrag)
      );
      yield put(modifyAndOfferSsdRebuyActions.success());
      yield put(rebuyModalActions.close());
      yield put(
        snackbarActions.openSuccess(
          appIntl().formatMessage(
            messages.redux_ssd_payment_status_rueckkauf_wurde_gespeichert_und_bestaetigt
          )
        )
      );
    } else {
      yield put(modifyAndOfferSsdRebuyActions.failure());
      yield put(
        snackbarActions.openError(
          appIntl().formatMessage(
            messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_gespeichert_werden
          )
        )
      );
    }
  } catch (error) {
    yield put(modifyAndOfferSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_gespeichert_werden
        )
      )
    );
  }
}

export function* cancelSsdRebuyRequestHandler({ account, ssdData, reason }) {
  try {
    const reasonHash = toSha3(reason);
    yield call(sendCancelSsdRebuy, account, toHex(ssdData.ssdid), ssdData.rebuyHash, reasonHash);
    yield put(cancelSsdRebuyActions.success());
    yield put(rebuyModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_rueckkauf_wurde_abgebrochen)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cancelSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_abgebrochen_werden
        )
      )
    );
  }
}

export function* cancelOfferSsdRebuyRequestHandler({ account, ssdData }) {
  try {
    yield call(sendCancelOfferSsdRebuy, account, toHex(ssdData.ssdid));
    yield put(cancelOfferSsdRebuyActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_bestaetigung_wurde_zurueckgenommen
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cancelOfferSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_bestaetigung_konnte_nicht_zurueck_genommen_werden
        )
      )
    );
  }
}

export function* cancelAcceptSsdRebuyRequestHandler({ account, ssdData }) {
  try {
    yield call(sendCancelAcceptSsdRebuy, account, ssdData.arranger, toHex(ssdData.ssdid));
    yield put(cancelAcceptSsdRebuyActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_bestaetigung_wurde_zurueckgenommen
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(cancelAcceptSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_bestaetigung_konnte_nicht_zurueck_genommen_werden
        )
      )
    );
  }
}

export function* offerSsdRebuyRequestHandler({ account, ssdData, isSecondConfirm }) {
  try {
    yield call(sendOfferSsdRebuy, account, ssdData.firstConfirmer, toHex(ssdData.ssdid), ssdData.rebuyHash);
    yield put(offerSsdRebuyActions.success());
    yield put(rebuyModalActions.close());
    if (isSecondConfirm) {
      yield call(sendMail, {
        template: mailTemplates.rueckkauf_gegenbestaetigung,
        arranger: ssdData.arranger,
        ssdid: ssdData.ssdid,
        fingerprint: ssdData.fingerprint,
        fingerprint_rebuy: ssdData.rebuyHash,
      });
    }
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_rueckkauf_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(offerSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* acceptSsdRebuyRequestHandler({ account, ssdData, fourEyesFlag }) {
  try {
    const fourEyesState = yield call(getFourEyesState, ssdData.arranger, ssdData.ssdid, 0);

    if (fourEyesState.state === FourEyesState.REBUY_ACCEPT_FIRST || !fourEyesFlag) {
      yield call(
        createRebuyConfirmationPDF,
        ssdData.ssdid,
        ssdData.fingerprint,
        ssdData.rebuyHash,
        ssdData.arranger
      );
    }
    yield call(
      sendAcceptSsdRebuy,
      account,
      ssdData.arranger,
      ssdData.firstConfirmer,
      toHex(ssdData.ssdid),
      ssdData.rebuyHash
    );
    yield put(acceptSsdRebuyActions.success());
    yield put(rebuyPreviewModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_rueckkauf_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(acceptSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* rejectSsdRebuyRequestHandler({ account, ssdData, reason, rebuyFingerprint }) {
  try {
    yield call(
      postRebuyRejectReason,
      ssdData.ssdid,
      ssdData.fingerprint,
      reason,
      usecase.REJECT,
      rebuyFingerprint,
      ssdData.arranger
    );
    const reasonHash = toSha3(reason);
    yield call(
      sendRejectSsdRebuy,
      account,
      ssdData.arranger,
      toHex(ssdData.ssdid),
      ssdData.rebuyHash,
      reasonHash
    );
    yield put(rejectSsdRebuyActions.success());
    yield put(rebuyPreviewModalActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_rueckkauf_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectSsdRebuyActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckkauf_konnte_nicht_abgelehnt_werden
        )
      )
    );
  }
}

export function* requestPaymentCorrectionHandler({ account, ssdData, reason }) {
  try {
    yield call(sendPaymentCorrection, account, toHex(ssdData.ssdid), getHashFromData(reason));
    yield put(paymentCorrectionActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_zahlungskorrektur_wurde_angefordert
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(paymentCorrectionActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_fehler_beim_anfordern_der_zahlungskorrektur
        )
      )
    );
  }
}

export function* sendPaymentRecceivedHandler({ account, ssdData }) {
  try {
    yield call(sendPaymentReceived, account, toHex(ssdData.ssdid));
    yield put(paymentReceivedActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_zahlung_wurde_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(paymentReceivedActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_zahlung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* confirmRepayment({ account, ssdData }) {
  try {
    yield call(sendRepaymentReceived, account, ssdData.arranger, toHex(ssdData.ssdid));
    yield put(repaymentReceivedActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_ssd_payment_status_rueckzahlung_wurde_bestaetigt)
      )
    );
  } catch (error) {
    yield put(repaymentReceivedActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_ssd_payment_status_rueckzahlung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}
