import { takeLatest, call, put } from "redux-saga/effects";
import { messages } from "components/Signin/messages";
import { web3SignIn, generateWallet, generateWalletInIE } from "services/web3Services/login";
import { checkToken, validateToken } from "services/colleagueService";
import {
  SIGN_IN_REQUEST,
  CREATE_WALLET_REQUEST,
  VALIDATE_ONBOARDING_TOKEN_REQUEST,
} from "./actions";
import {
  signInActions,
  createWalletAction,
  userOnboardActions,
  onboardingTokenActions,
} from "./actions";
import history from "util/history";
import { isIE } from "util/isIEHelper.js";
import { requestEtherForWallet } from "services/web3Services/faucet";
import { listenForProviderDisconnect } from "services/web3Services/eventListener";
import { getIdProxyAddr } from "services/web3Services/commons";
import { messageDialogActions, snackbarActions } from "../shared/actions";
import { appIntl } from "components/i18n/intl";
import { downloadFileFromBinary, FileTypes } from "util/fileReader";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const signInSagas = [
  takeLatest(SIGN_IN_REQUEST, handleSignIn),
  takeLatest(CREATE_WALLET_REQUEST, createWallet),
  takeLatest(VALIDATE_ONBOARDING_TOKEN_REQUEST, handleValidateToken),
];

export function* handleValidateToken({ token, backendUrl }) {
  try {
    const { response, error } = yield call(validateToken, token, backendUrl);
    if (error) {
      let r = error.response;
      if (r.status === 417) {
        yield put(
          messageDialogActions.open({
            title: appIntl().formatMessage(messages.linkAlreadyUsedDialogTitle),
            body: appIntl().formatMessage(messages.linkAlreadyUsedDialogMessage),
            buttonText: appIntl().formatMessage(messages.closeDialogButton),
          })
        );
      } else {
        yield put(
          messageDialogActions.open({
            title: appIntl().formatMessage(messages.invitationExpiredDialogTitle),
            body: appIntl().formatMessage(messages.invitationExpiredDialogMessage),
            buttonText: appIntl().formatMessage(messages.closeDialogButton),
          })
        );
      }
    } else {
      yield put(
        onboardingTokenActions.success(
          token,
          backendUrl,
          response.data.invitationType === "UK",
          response.data.email
          // DIGITS-260 für Erfassender Mitarbeiter müssen vorname und nachname im Backend ergänzt werden
          // response.data.vorname + " " + response.data.nachname
        )
      );
    }
  } catch (error) {
    yield put(onboardingTokenActions.failure(error));
  }
}

export function* acceptInvite(token, walletAddress) {
  const response = yield call(checkToken, token, walletAddress);
  if (response.status === 409) {
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.walletAlreadyLinkedMessage))
    );
  } else if (response.status >= 400) {
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.registrationAlreadySucceededMessage)
      )
    );
  } else {
    yield put(
      messageDialogActions.open({
        title: appIntl().formatMessage(messages.permissionsCanBeAssignedDialogTitle),
        body: appIntl().formatMessage(messages.permissionsCanBeAssignedDialogBody),
        buttonText: appIntl().formatMessage(messages.closeDialogButton),
      })
    );
  }
}

export function* handleSignIn({ instance, onBoardingToken, isPlatformMemberInvited }) {
  try {
    const signInResult = yield call(web3SignIn, instance);
    signInResult.token = onBoardingToken;
    yield put(signInActions.success(signInResult));
    yield call(listenForProviderDisconnect);
    if (onBoardingToken) {
      if (!isPlatformMemberInvited) {
        yield call(acceptInvite, onBoardingToken, signInResult.account.address);
      } else {
        history.push("/wallet");
      }
    } else {
      yield put(userOnboardActions.userIsOnboarded());
      const hasAnyPrivilege = Object.values(signInResult.privileges).reduce((a, b) => a || b);
      if (hasAnyPrivilege) {
        try {
          yield call(
            requestEtherForWallet,
            signInResult.account,
            signInResult.account.address,
            getIdProxyAddr()
          );
        } catch (error) {
          console.error("could not refuel wallet!");
        }
      } else {
        yield put(
          messageDialogActions.open({
            title: appIntl().formatMessage(messages.noPrivilegesDialogTitle),
            body: appIntl().formatMessage(messages.noPrivilegesDialogBody),
            buttonText: appIntl().formatMessage(messages.closeDialogButton),
          })
        );
      }
    }
  } catch (error) {
    yield put(signInActions.failure(error.message));
  }
}

export function startWalletDownload(wallet) {
  try {
    const timestamp = new Date().toISOString().slice(0, 10);
    const fileName = `${timestamp}--${wallet.address}.wallet`;
    downloadFileFromBinary([JSON.stringify(wallet)], fileName, FileTypes.JSON);
  } catch (error) {
    console.error(error);
  }
}

export function* createWallet({ password }) {
  let wallet;
  try {
    if (isIE()) {
      wallet = yield call(generateWalletInIE, password);
    } else {
      wallet = yield call(generateWallet, password);
    }
    yield call(startWalletDownload, wallet);
    yield put(createWalletAction.success(wallet));
  } catch (error) {
    yield put(createWalletAction.failure(error));
  }
}
