export const OPEN_HISTORY_MODAL = "OPEN_HISTORY_MODAL";
export const CLOSE_HISTORY_MODAL = "CLOSE_HISTORY_MODAL";

export const FETCH_SSD_HISTORY_REQUEST = "FETCH_SSD_HISTORY_REQUEST";
export const FETCH_SSD_HISTORY_SUCCESS = "FETCH_SSD_HISTORY_SUCCESS";
export const FETCH_SSD_HISTORY_FAILURE = "FETCH_SSD_HISTORY_FAILURE";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const historyActions = {
  request: (ssdId, arranger) => action(FETCH_SSD_HISTORY_REQUEST, { ssdId, arranger }),
  success: (ssdHistory) => action(FETCH_SSD_HISTORY_SUCCESS, { ssdHistory }),
  failure: (error) => action(FETCH_SSD_HISTORY_FAILURE, { error }),
};

export const historyModalActions = {
  open: () => action(OPEN_HISTORY_MODAL, {}),
  close: () => action(CLOSE_HISTORY_MODAL, {}),
};

export const OPEN_INTEREST_HITORY_MODAL = "OPEN_INTEREST_HITORY_MODAL";
export const CLOSE_INTEREST_HITORY_MODAL = "CLOSE_INTEREST_HITORY_MODAL";

export const FETCH_INTEREST_HISTORY_REQUEST = "FETCH_INTEREST_HISTORY_REQUEST";
export const FETCH_INTEREST_HISTORY_SUCCESS = "FETCH_INTEREST_HISTORY_SUCCESS";
export const FETCH_INTEREST_HISTORY_FAILURE = "FETCH_INTEREST_HISTORY_FAILURE";

export const interestHistoryModalActions = {
  open: () => action(OPEN_INTEREST_HITORY_MODAL, {}),
  close: () => action(CLOSE_INTEREST_HITORY_MODAL, {}),
};

export const interestHistoryActions = {
  request: (arranger, ssdId, notification) =>
    action(FETCH_INTEREST_HISTORY_REQUEST, { arranger, ssdId, notification }),
  success: (interestHistory) => action(FETCH_INTEREST_HISTORY_SUCCESS, { interestHistory }),
  failure: () => action(FETCH_INTEREST_HISTORY_FAILURE, {}),
};

export const OPEN_CESSION_HISTORY_MODAL = "OPEN_CESSION_HISTORY_MODAL";
export const CLOSE_CESSION_HISTORY_MODAL = "CLOSE_CESSION_HISTORY_MODAL";

export const cessionHistoryModalActions = {
  open: () => action(OPEN_CESSION_HISTORY_MODAL),
  close: () => action(CLOSE_CESSION_HISTORY_MODAL),
};

export const FETCH_CESSION_HISTORY_REQUEST = "FETCH_CESSION_HISTORY_REQUEST";
export const FETCH_CESSION_HISTORY_SUCCESS = "FETCH_CESSION_HISTORY_SUCCESS";
export const FETCH_CESSION_HISTORY_FAILURE = "FETCH_CESSION_HISTORY_FAILURE";

export const cesstionHistoryActions = {
  request: (arranger, dgOld, ssdId, cessionId) =>
    action(FETCH_CESSION_HISTORY_REQUEST, {
      arranger,
      dgOld,
      ssdId,
      cessionId,
    }),
  success: (cessionHistory) => action(FETCH_CESSION_HISTORY_SUCCESS, { cessionHistory }),
  failure: () => action(FETCH_CESSION_HISTORY_FAILURE),
};
