import { take, takeLatest, call, fork, put, all, cancel } from "redux-saga/effects";
import { getBlockNumber, getIdProxyAddr } from "services/web3Services/commons";
import {
  GET_ACCOUNT_REQUEST,
  GET_COMPANY_REQUEST,
  START_MEMBER_DATA_LISTENER,
  STOP_MEMBER_DATA_LISTENER,
  MEMBER_DATA_MODIFY_EVENT,
  UPDATE_PLATFORMMEMBER_INFO_REQUEST,
  ACCEPT_PLATFORMMEMBER_INFO_REQUEST,
  REJECT_PLATFORMMEMBER_INFO_REQUEST,
  MEMBER_DATA_ACCEPT_EVENT,
  GET_ADDRESS_BOOK_ENTRY_REQUEST,
  GET_ADDRESS_BOOK_ENTRIES_REQUEST,
  getAddressBookEntriesActions,
  deleteAddressBookEntryActions,
  DELETE_ADDRESS_BOOK_ENTRY_REQUEST,
  deleteAddressBookEntryDialogActions,
  saveAddressBookEntryActions,
  SAVE_ADDRESS_BOOK_ENTRY_REQUEST,
  SAVE_ADDRESS_BOOK_ENTRY_SUCCESS,
} from "./actions";
import { messageDialogActions, snackbarActions } from "redux/shared/actions";
import {
  companyDataModifyAndApproveUpdate,
  companyDataApproveUpdate,
  companyDataRejectUpdate,
} from "services/web3Services/commons";
import {
  listenForMemberDataModifiedEvent,
  listenForMemberDataUpdatedEvent,
  listenForMemberDataRejectedEvent,
} from "services/web3Services/eventListener";
import {
  getAccountActions,
  getCompanyActions,
  updatePlatformMemberInfoActions,
  acceptPlatformMemberInfoActions,
  fetchPlatformMemberInfoDraftActions,
  rejectPlatformMemberInfoActions,
  editAddressBookDialogActions,
  getAddressBookEntryActions,
} from "./actions";
import {
  getAccountInfo,
  getCompanyInfo,
  updateCompanyInfo,
  getCompanyInfoDraft,
} from "services/accountService";
import {
  getAdressbuchEintrag,
  getAdressbuchEintraege,
  deleteAdressbuchEintrag,
  storeAdressbucheintrag,
} from "services/addressBookService";
import { getFourEyesFlag, getMemberData } from "services/web3Services/commons";
import { FourEyesState, MemberInfoStatus } from "util/constants";

import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { generatePlatformmemberFingerprint } from "util/fingerprint";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const accountSagas = [
  takeLatest(GET_ACCOUNT_REQUEST, fetchAccount),
  takeLatest(GET_COMPANY_REQUEST, fetchCompany),
  takeLatest(MEMBER_DATA_ACCEPT_EVENT, fetchCompany),
  takeLatest(START_MEMBER_DATA_LISTENER, startMemberUpdateListener),
  takeLatest(UPDATE_PLATFORMMEMBER_INFO_REQUEST, updatePlatformMemberInfo),
  takeLatest(ACCEPT_PLATFORMMEMBER_INFO_REQUEST, acceptPlatformMemberInfo),
  takeLatest(REJECT_PLATFORMMEMBER_INFO_REQUEST, rejectPLatformMemberInfo),
  takeLatest(MEMBER_DATA_MODIFY_EVENT, fetchCompanyDraft),
  takeLatest(GET_ADDRESS_BOOK_ENTRY_REQUEST, getAddressBookEntry),
  takeLatest(GET_ADDRESS_BOOK_ENTRIES_REQUEST, getAddressBookEntries),
  takeLatest(DELETE_ADDRESS_BOOK_ENTRY_REQUEST, deleteAddressBookEntry),
  takeLatest(SAVE_ADDRESS_BOOK_ENTRY_REQUEST, storeAddressBookEntry),
  takeLatest(SAVE_ADDRESS_BOOK_ENTRY_SUCCESS, getAddressBookEntries),
];

export function* memberDataEventListenerLoop(chan) {
  while (true) {
    let result = yield take(chan);
    if (result.idProxyAddress === getIdProxyAddr()) {
      yield put(result);
    }
  }
}

export function* memberDataModifiedEventListener(currentBlock) {
  try {
    const chan = yield call(listenForMemberDataModifiedEvent, currentBlock);
    yield fork(memberDataEventListenerLoop, chan);
  } catch (error) {
    console.error(error);
  }
}

export function* MemberDataUpdatedEventListener(currentBlock) {
  try {
    const chan = yield call(listenForMemberDataUpdatedEvent, currentBlock);
    yield fork(memberDataEventListenerLoop, chan);
  } catch (error) {
    console.error(error);
  }
}

export function* MemberDataRejectedEventListener(currentBlock) {
  try {
    const chan = yield call(listenForMemberDataRejectedEvent, currentBlock);
    yield fork(memberDataEventListenerLoop, chan);
  } catch (error) {
    console.error(error);
  }
}

export function* startMemberUpdateListener() {
  const currentBlock = yield call(getBlockNumber);
  const listener = [];
  listener.push(yield fork(memberDataModifiedEventListener, currentBlock));
  listener.push(yield fork(MemberDataUpdatedEventListener, currentBlock));
  listener.push(yield fork(MemberDataRejectedEventListener, currentBlock));
  yield take(STOP_MEMBER_DATA_LISTENER);
  yield all(listener.map((channel) => cancel(channel)));
}

export function* updatePlatformMemberInfo({ account, platformmeberInfo }) {
  try {
    const newInfo = {
      fingerprint: platformmeberInfo.fingerprint,
      name: platformmeberInfo.name,
      umsatzsteuer_id: platformmeberInfo.umsatzsteuer_id,
      lei: platformmeberInfo.lei,
      anschrift: platformmeberInfo.anschrift,
      telefonnummer: platformmeberInfo.telefonnummer,
      email_verteiler: platformmeberInfo.email_verteiler,
      iban: platformmeberInfo.iban,
      bic: platformmeberInfo.bic,
      iban_tilgung: platformmeberInfo.iban_tilgung,
      bic_tilgung: platformmeberInfo.bic_tilgung,
      bic_allgemein: platformmeberInfo.bic_allgemein,
      status: MemberInfoStatus.DRAFT,
      info_creation_date: new Date().getTime(),
      sitzLand: platformmeberInfo.sitzLand,
      sprache: platformmeberInfo.sprache,
      gerichtsstand: platformmeberInfo.gerichtsstand,
    };
    const { idProxyAddress } = platformmeberInfo;

    const newInfoWithFP = yield generatePlatformmemberFingerprint(newInfo);

    yield call(updateCompanyInfo, newInfoWithFP, idProxyAddress);
    yield call(companyDataModifyAndApproveUpdate, account, newInfoWithFP.fingerprint);

    yield put(updatePlatformMemberInfoActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_account_informationen_gespeichert)
      )
    );
  } catch (error) {
    yield put(updatePlatformMemberInfoActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_account_informationen_konnten_nicht_gespeichert_werden
        )
      )
    );
  }
}

export function* acceptPlatformMemberInfo({ account, platformmeberInfo }) {
  try {
    const newInfo = {
      ...platformmeberInfo,
      status: MemberInfoStatus.ACCEPTED,
    };
    const { idProxyAddress } = platformmeberInfo;

    yield call(updateCompanyInfo, newInfo, idProxyAddress);
    yield call(companyDataApproveUpdate, account, newInfo.fingerprint);
    yield put(acceptPlatformMemberInfoActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_account_informationen_akzeptiert)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(acceptPlatformMemberInfoActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_account_informationen_konnten_nicht_akzeptiert_werden
        )
      )
    );
  }
}

export function* rejectPLatformMemberInfo({ account, platformmeberInfo }) {
  try {
    const newInfo = {
      ...platformmeberInfo,
      status: MemberInfoStatus.REJECTED,
    };
    const { idProxyAddress } = platformmeberInfo;

    yield call(updateCompanyInfo, newInfo, idProxyAddress);
    yield call(companyDataRejectUpdate, account, platformmeberInfo.fingerprint);
    yield put(rejectPlatformMemberInfoActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_account_informationen_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectPlatformMemberInfoActions.failure());
    snackbarActions.openError(
      appIntl().formatMessage(messages.redux_account_informationen_konnten_nicht_abgelehnt_werden)
    );
  }
}

export function* fetchAccount({ accountAddress, idProxyAddr }) {
  try {
    const response = yield call(getAccountInfo, accountAddress, idProxyAddr);
    if (response.status >= 200 && response.status < 300) {
      yield put(getAccountActions.success(response.data));
    } else {
      yield logoutWithMessage();
    }
  } catch (err) {
    yield put(getAccountActions.failure(err));
    yield logoutWithMessage();
  }
}

function* logoutWithMessage(){
  yield put(
    messageDialogActions.open({
      title: appIntl().formatMessage(messages.redux_account_ein_fehler_ist_aufgetreten),
      body: appIntl().formatMessage(messages.redux_account_mitarbeiter_info_fehler),
    }));
  setTimeout(()=> {
    window.location.reload(true);
  }, 5000);
}

export function* fetchCompanyDraft({ idProxyAddress, firstConfirmer }) {
  try {
    const { data } = yield call(getCompanyInfoDraft, idProxyAddress);
    yield put(fetchPlatformMemberInfoDraftActions.success(data, firstConfirmer));
  } catch (error) {
    yield put(fetchPlatformMemberInfoDraftActions.failure(error));
  }
}

export function* fetchCompany({ idProxyAddress }) {
  try {
    const response = yield call(getCompanyInfo, idProxyAddress);
    const fourEyesFlag = yield call(getFourEyesFlag);
    const companyInfo = {
      ...response.data,
      fourEyesFlag,
    };

    const memberDataStatus = yield call(getMemberData);
    if (memberDataStatus.confirmationState === FourEyesState.MEMBER_DATA_UPDATE_APPROVE_FIRST) {
      yield fork(fetchCompanyDraft, {
        idProxyAddress,
        firstConfirmer: memberDataStatus.firstConfirmer,
      });
    }

    if (response.status >= 200 && response.status < 300)
      yield put(getCompanyActions.success(companyInfo));
  } catch (err) {
    console.error(err);
    yield put(getCompanyActions.failure(err));
  }
}

export function* getAddressBookEntry({ entryId }) {
  try {
    if (entryId) {
      yield put(editAddressBookDialogActions.open("PENDING"));
      const { data } = yield call(getAdressbuchEintrag, entryId);
      yield put(editAddressBookDialogActions.open({ ...data, sprache: data.sprache || "" }));
    } else {
      yield put(editAddressBookDialogActions.open({ sprache: "" }));
    }
    yield put(getAddressBookEntryActions.success());
  } catch (error) {
    yield put(editAddressBookDialogActions.close());
    yield put(getAddressBookEntryActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_account_ein_fehler_ist_aufgetreten)
      )
    );
  }
}

export function* getAddressBookEntries() {
  try {
    const { data } = yield call(getAdressbuchEintraege);
    yield put(getAddressBookEntriesActions.success(data.adressbuch));
  } catch (error) {
    yield put(getAddressBookEntriesActions.failure());
  }
}

export function* storeAddressBookEntry({ payload }) {
  try {
    yield call(storeAdressbucheintrag, payload);
    yield put(saveAddressBookEntryActions.success());
    yield put(editAddressBookDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_account_adressbucheintrag_wurde_erfolgreich_gespeichert
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(saveAddressBookEntryActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          error?.response?.status === 409
            ? messages.redux_account_adressbucheintrag_konnte_nicht_gespeichert_werden_weil_bereits_vorhanden
            : messages.redux_account_adressbucheintrag_konnte_nicht_gespeichert_werden
        )
      )
    );
  }
}

export function* deleteAddressBookEntry({ entryId }) {
  try {
    yield call(deleteAdressbuchEintrag, entryId);
    yield put(deleteAddressBookEntryActions.success(entryId));
    yield put(deleteAddressBookEntryDialogActions.close());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(
          messages.redux_account_adressbucheintrag_wurde_erfolgreich_geloescht
        )
      )
    );
  } catch (error) {
    console.error(error);
    yield put(deleteAddressBookEntryActions.failure());
    yield put(deleteAddressBookEntryDialogActions.close());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_account_adressbucheintrag_konnte_nicht_geloescht_werden
        )
      )
    );
  }
}
