import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Link,
} from "@material-ui/core";
import {
  ArrowBackIos as ArrowBackIosIcon,
  LockOpenOutlined as LockOpenIcon,
  LockOutlined as LockIcon,
  VerifiedUser as VerifiedUserIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import queryString from "query-string";
import { connect } from "react-redux";
import { signin_styles } from "./signin_styles";
import { onboardingTokenActions, signInActions } from "redux/signIn/actions";
import { setNameFromUrlActions } from "redux/walletRegistration/actions";
import { readSingleFile } from "util/fileReader";
import { loginErrorCodes } from "util/errorCodes";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import AuthenticityCheckButton from "./AuthenticityCheck/AuthenticityCheckButton";
import { pdfVerificationActions } from "redux/pdfVerification/actions";
import { appIntl, isGermanOnly } from "../i18n/intl";
import WalletRegistration from "./WalletRegistration/WalletRegistration";
import Logo from "components/Shared/Logo/Logo";
import ToggleLanguageButton from "components/Shared/ToggleLanguageButton/ToggleLanguageButton";
import Footer from "components/Shared/Footer/Footer";

class SignIn extends React.Component {
  state = {
    password: "",
    wallet: "",
    showGenerateWallet: false,
    noWallet: false,
    certificate: {
      contentBuffer: null,
      selectedDocumentType: null,
    },
    showPassword: false,
  };

  componentDidMount() {
    this.checkOnboardingToken();
  }

  checkOnboardingToken = () => {
    const values = queryString.parse(this.props.location.search);
    if (values.token && values.backendUrl) {
      this.props.onSetOnboardingTokenRequest(values.token, values.backendUrl);
    }
    if (values.mitarbeiter) {
      this.props.setName(values.mitarbeiter);
    }
  };

  componentDidUpdate;

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  updateWallet = (walletJson) => {
    this.setState({
      wallet: JSON.parse(walletJson),
    });
  };

  onWalletChange = (event) => {
    readSingleFile(event, this.updateWallet);
    this.setState({
      wallet: event.target.value,
      noWallet: false,
    });
  };

  switchView = (event) => {
    event.preventDefault();
    this.setState({
      showGenerateWallet: !this.state.showGenerateWallet,
    });
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.wallet === "") {
      this.setState({
        noWallet: true,
      });
    }
    if (this.state.wallet !== "" && this.state.password !== "") {
      this.props.onSignInRequest(
        this.state,
        this.props.onBoardingToken,
        this.props.isPlatformMemberInvited
      );
    }
  };

  handleLoginReset = () => {
    this.setState({ password: "", wallet: "", showGenerateWallet: false });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  onCertificateUpload = (contentBuffer, selectedDocumentType) => {
    this.setState({
      certificate: {
        contentBuffer,
        selectedDocumentType,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const showGenerateWallet =
      this.state.showGenerateWallet && Object.keys(this.props.account).length === 0;

    const activeTab = this.props.onBoardingToken ? 0 : 1;
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar variant={"outlined"} color={"inherit"}>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs={4}>
                <Logo />
              </Grid>
              <Grid item xs={4} className={classes.toolbarLinks}>
                <Button
                  size="large"
                  color="inherit"
                  className={classes.toolbarButton}
                  component={Link}
                  href={"https://www.finledger.de/"}
                >
                  Finledger.de
                </Button>
                <Button
                  size="large"
                  color="inherit"
                  className={classes.toolbarButton}
                  component={Link}
                  href={"https://www.finledger.de/faq"}
                >
                  FAQ
                </Button>
              </Grid>
              <Grid item xs={4} className={classes.launguageButtonWrapper}>
                {!isGermanOnly() && <ToggleLanguageButton isTextVisible={true} />}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Grid container>
            <Grid item xs={12} className={classes.welcomeMessage}>
              <Typography variant={"h4"}>
                <FormattedMessage {...messages.welcomeMessageTop} />
              </Typography>
              <Typography variant={"h4"}>
                <FormattedMessage {...messages.welcomeMessageBottom} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={1} sm={2} md={4} />
              <Grid item xs={10} sm={9} md={7}>
                <Typography variant={"h5"} className={classes.subTitle}>
                  {activeTab === 0 ? (
                    <FormattedMessage {...messages.registerTitle} />
                  ) : (
                    <FormattedMessage {...messages.loginTitle} />
                  )}
                </Typography>

                <Paper>
                  <Tabs
                    indicatorColor={"primary"}
                    variant="fullWidth"
                    value={activeTab}
                    className={classes.loginTabs}
                  >
                    <Tab
                      className={classes.registerTab}
                      style={{ pointerEvents: "auto" }}
                      label={
                        activeTab === 0 ? (
                          appIntl().formatMessage(messages.registerTabTitle)
                        ) : (
                          <Tooltip
                            enterTouchDelay={50}
                            title={
                              <>
                                <div>
                                  {appIntl().formatMessage(messages.registerTooltipNotOnboarded)}
                                </div>
                                <br />
                                <div>
                                  {appIntl().formatMessage(messages.registerTooltipOnboarded)}
                                </div>
                              </>
                            }
                          >
                            <span>{appIntl().formatMessage(messages.registerTabTitle)}</span>
                          </Tooltip>
                        )
                      }
                      onClick={this.handleLoginReset}
                      disabled={!this.props.onBoardingToken || this.props.showWalletInformation}
                    />
                    <Tab
                      className={classes.loginTab}
                      label={appIntl().formatMessage(messages.loginTabTitle)}
                      onClick={this.handleLoginReset}
                      disabled={!!this.props.onBoardingToken}
                    />
                  </Tabs>
                  <div className={classes.paper}>
                    <Grid container>
                      <Grid item xs={4}>
                        {this.state.showGenerateWallet && !this.props.showWalletInformation && (
                          <Button
                            onClick={this.switchView}
                            disabled={this.props.createWalletPending}
                          >
                            <ArrowBackIosIcon
                              color={this.props.createWalletPending ? "disabled" : "primary"}
                              fontSize={"small"}
                            />
                          </Button>
                        )}
                        {!!this.state.wallet && (
                          <Button
                            onClick={this.handleLoginReset}
                            disabled={this.props.signInPending}
                          >
                            <ArrowBackIosIcon
                              color={this.props.signInPending ? "disabled" : "primary"}
                              fontSize={"small"}
                            />
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={4} className={classes.loginIconWrapper}>
                        {this.state.password ? (
                          <LockOpenIcon className={classes.avatar} fontSize={"large"} />
                        ) : (
                          <LockIcon className={classes.avatar} fontSize={"large"} />
                        )}
                      </Grid>
                      <Grid item xs={4} />
                    </Grid>

                    <Typography variant="h5">
                      <FormattedMessage {...messages.finledgerLogin} />
                    </Typography>
                    {!showGenerateWallet && (
                      <form className={this.state.wallet ? classes.formWithPassword : classes.form}>
                        {!this.state.wallet && (
                          <FormControl margin="normal" required fullWidth>
                            <input
                              accept="file/*"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={this.onWalletChange}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                disabled={this.props.signInPending}
                                variant="contained"
                                component="span"
                                color="primary"
                                className={classes.button}
                              >
                                <FormattedMessage {...messages.openWalletButton} />
                              </Button>
                            </label>
                          </FormControl>
                        )}
                        {this.state.wallet && (
                          <FormControl
                            margin="normal"
                            required
                            fullWidth
                            error={
                              !this.props.signInPending &&
                              (this.props.signInErrorCode !== "" || this.state.noWallet)
                            }
                          >
                            <InputLabel htmlFor="password">
                              <FormattedMessage {...messages.passwordInput} />
                            </InputLabel>
                            <Input
                              id="password"
                              name="password"
                              autoComplete="current-password"
                              type={this.state.showPassword ? "text" : "password"}
                              error={this.props.signInError}
                              classes={{
                                input: classes.passwordInput,
                              }}
                              onChange={this.onPasswordChange}
                              aria-describedby={
                                !this.props.signInPending
                                  ? this.props.signInErrorCode
                                  : this.state.noWallet
                                  ? "no-wallet"
                                  : ""
                              }
                              endAdornment={
                                <InputAdornment position="end" className={classes.showPassword}>
                                  <Button
                                    type={"button"}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </Button>
                                </InputAdornment>
                              }
                            />

                            {!this.props.signInPending && this.state.noWallet && (
                              <FormHelperText id="no-wallet">
                                <FormattedMessage {...messages.chooseWalletMessage} />
                              </FormHelperText>
                            )}
                            {!this.props.signInPending &&
                              this.props.signInErrorCode ===
                                loginErrorCodes.WRONG_PASSWORD_ERROR && (
                                <FormHelperText id={loginErrorCodes.WRONG_PASSWORD_ERROR}>
                                  <FormattedMessage {...messages.wrongPasswordMessage} />
                                </FormHelperText>
                              )}
                            {!this.props.signInPending &&
                              this.props.signInErrorCode ===
                                loginErrorCodes.NO_PERMISSIONS_ERROR && (
                                <FormHelperText id={loginErrorCodes.NO_PERMISSIONS_ERROR}>
                                  <FormattedMessage {...messages.noPermissionMessage} />
                                </FormHelperText>
                              )}
                            {!this.props.signInPending &&
                              this.props.signInErrorCode ===
                                loginErrorCodes.SOCKET_HANDSHAKE_ERROR && (
                                <FormHelperText id={loginErrorCodes.SOCKET_HANDSHAKE_ERROR}>
                                  <FormattedMessage {...messages.handshakeErrorMessage} />
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                        {this.props.onBoardingToken && !this.state.wallet && (
                          <div className={classes.wrapperSmallMargin}>
                            <Button
                              disabled={this.props.signInPending}
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="default"
                              onClick={this.switchView}
                            >
                              <FormattedMessage {...messages.noWalletButton} />
                            </Button>
                          </div>
                        )}
                        <div className={classes.wrapper}>
                          <Button
                            type="submit"
                            color="primary"
                            fullWidth
                            disabled={this.props.signInPending || !this.state.password}
                            onClick={this.handleSubmit}
                          >
                            <FormattedMessage {...messages.nextButton} />
                          </Button>

                          {this.props.signInPending && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          )}
                        </div>
                        <div className={classes.wrapper}>
                          {this.state.wallet ? (
                            <Tooltip
                              enterTouchDelay={50}
                              title={appIntl().formatMessage(messages.forgottenPasswordTooltip)}
                            >
                              <Button
                                component={"div"}
                                disableRipple
                                className={classes.infoButton}
                              >
                                <Typography variant={"overline"}>
                                  <FormattedMessage {...messages.forgottenPasswordButtonText} />
                                </Typography>
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              enterTouchDelay={50}
                              title={appIntl().formatMessage(messages.lostWalletButtonTooltip)}
                            >
                              <Button
                                component={"div"}
                                disableRipple
                                className={classes.infoButton}
                              >
                                <Typography variant={"overline"}>
                                  <FormattedMessage {...messages.lostWalletButtonText} />
                                </Typography>
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </form>
                    )}
                    {showGenerateWallet && <WalletRegistration switchView={this.switchView} />}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={1} sm={1} md={1} />
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={1} sm={1} md={1} />
              <Grid item xs={10} sm={9} md={7}>
                <Typography variant={"h5"} className={classes.subTitle}>
                  <FormattedMessage {...messages.validateCertificateTitle} />
                </Typography>
                <Paper className={classes.paper}>
                  <div className={classes.loginTabSpacer} />
                  <Grid container>
                    <Grid item xs={4} />
                    <Grid item xs={4} className={classes.loginIconWrapper}>
                      <VerifiedUserIcon className={classes.avatar} fontSize={"large"} />
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>
                  <Typography variant="h5">
                    <FormattedMessage {...messages.finledgerCheck} />
                  </Typography>
                  <form className={classes.form}>
                    <FormControl margin="normal" required fullWidth>
                      <AuthenticityCheckButton setDocument={this.onCertificateUpload} />
                    </FormControl>
                    {((this.props.onBoardingToken &&
                      !this.state.wallet &&
                      !this.state.showGenerateWallet) ||
                      (!this.state.wallet && this.props.showWalletInformation)) && (
                      <div className={classes.onBoardingSpacer} />
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        type="button"
                        color="primary"
                        fullWidth
                        disabled={!this.state.certificate.contentBuffer}
                        onClick={() =>
                          this.props.onVerify(
                            this.state.certificate.contentBuffer,
                            this.state.certificate.selectedDocumentType
                          )
                        }
                      >
                        <FormattedMessage {...messages.checkButton} />
                      </Button>
                    </div>
                    <div className={classes.wrapper}>
                      <Tooltip
                        enterTouchDelay={50}
                        title={appIntl().formatMessage(messages.usageButtonTooltip)}
                      >
                        <Button component={"div"} disableRipple className={classes.infoButton}>
                          <Typography variant={"overline"}>
                            <FormattedMessage {...messages.usageButtonText} />
                          </Typography>
                        </Button>
                      </Tooltip>
                    </div>
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={1} sm={2} md={4} />
            </Grid>
          </Grid>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    signInPending: state.signInReducer.signInPending,
    signInErrorCode: state.signInReducer.signInErrorCode,
    signInError: state.signInReducer.signInError,
    account: state.signInReducer.account,
    onBoardingToken: state.signInReducer.onBoardingToken,
    isPlatformMemberInvited: state.signInReducer.isPlatformMemberInvited,
    showWalletInformation: state.signInReducer.showWalletInformation,
    createWalletPending: state.signInReducer.createWalletPending,
  };
};

const mapDispatchToProps = {
  onSignInRequest: signInActions.request,
  onSetOnboardingTokenRequest: onboardingTokenActions.request,
  setName: setNameFromUrlActions.setName,
  onVerify: pdfVerificationActions.request,
};

export default withStyles(signin_styles)(connect(mapStateToProps, mapDispatchToProps)(SignIn));
