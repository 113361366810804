import web3 from "util/web3";
import LoginWorker from "services/worker/web3Login.worker";
import WalletWorker from "services/worker/web3Wallet.worker";

import { NUMBER_OF_WALLETS_TO_CREATE } from "util/constants";

import { updateContracts, getPrivilegesForAccount } from "services/smartContracts/smartContracts";
import { getAllPartnerInfo } from "../partnerService";
import { getIdProxyAddr } from "./commons";
import { contractCache } from "../smartContracts/smartContracts";
import { getSignatureString } from "util/requestSigning";
import { loginErrorCodes } from "util/errorCodes";
import { sendPing } from "util/wsPingHelper";

export let account = {};

//
// LOGIN
//

export async function connectProvider() {
  const urlParam = await getSignatureString(
    "get",
    process.env.REACT_APP_NODE_URL,
    process.env.REACT_APP_SERVER_URL_PREFIX + process.env.REACT_APP_SERVER_URL
  );
  return new Promise((resolve, reject) => {
    try {
      const provider = new web3.providers.WebsocketProvider(
        process.env.REACT_APP_NODE_URL + "?token=" + urlParam, {
          clientConfig: {
            maxReceivedFrameSize: 100000000,
            maxReceivedMessageSize: 100000000,
          }
        }
      );
      if (provider.connection.readyState === 1 || provider.connection.readyState === 0) {
        provider.on("connect", function () {
          web3.setProvider(provider);
          resolve();
        });
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function web3SignIn(instance) {
  const worker = new LoginWorker();
  worker.postMessage(instance);
  return new Promise(function (resolve, reject) {
    worker.onmessage = async (event) => {
      try {
        if (event.data !== null) {
          account = web3.eth.accounts.privateKeyToAccount(event.data);
          try {
            await connectProvider();
          } catch (err) {
            return reject(new Error(loginErrorCodes.SOCKET_HANDSHAKE_ERROR));
          }
          await updateContracts(account);
          const privileges = await getPrivilegesForAccount(account);
          sendPing();
          //eslint-disable-next-line no-console
          console.log(contractCache);
          if (contractCache.identityProxy && contractCache.identityProxy._address) {
            getAllPartnerInfo(getIdProxyAddr());
            resolve({ account, privileges });
            //log env
            //console.error(process.env);
          } else {
            //no permissions
            //console.error("no Permissions");
            resolve({ account, privileges });
          }
        } else {
          //invalid pw
          //console.error("invalid Password");
          reject(new Error(loginErrorCodes.WRONG_PASSWORD_ERROR));
        }
      } catch (error) {
        console.error(error);
        reject(event.data);
      }
    };
  });
}

export async function generateWalletInIE(password) {
  try {
    const wallets = web3.eth.accounts.wallet.create(NUMBER_OF_WALLETS_TO_CREATE);
    const wallet = wallets[wallets.length - 1];
    const walletEnc = await wallet.encrypt(password);
    return walletEnc;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function generateWallet(password) {
  const worker = new WalletWorker();
  worker.postMessage(password);
  return new Promise(function (resolve, reject) {
    worker.onmessage = async (event) => {
      try {
        if (event.data !== null) {
          resolve(event.data);
        } else {
          reject(event.data);
        }
      } catch (error) {
        reject(event.data);
      }
    };
  });
}
