import {
  LOAD_CSSD_FOR_TEILFORDERUNG,
  FETCH_INFORMATIONSMATERIAL_METADATA,
  SAVE_INFORMATIONSMATERIAL_REQUEST,
  DOWNLOAD_INFORMATIONSMATERIAL,
  DELETE_INFORMATIONSMATERIAL,
  CSSD_INTEREST_NOTIFICATION_OPEN,
  CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER,
  CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER,
  CSSD_INTEREST_NOTIFICATION_CONFIRM,
  CSSD_INTEREST_NOTIFICATION_CANCEL,
  CSSD_INTEREST_NOTIFICATION_DELETE,
  INTEREST_NOTIFICATION_EVENT,
} from "./actions";
import {
  cssdInformationsmaterial,
  downloadCssdInformationsmaterial,
  saveCssdInformationsmaterial,
  deleteCssdInformationsmaterial,
  InterestNotificationActions,
  interestNotificationEventActions,
  deleteInterestNotificationDialogActions,
} from "./actions";
import { takeLatest, put, call, select, all, spawn } from "redux-saga/effects";
import {
  queryInformationsmaterialMetadata,
  queryInformationsmaterialMetadataForNewDg,
  uploadInformationsmaterial,
  deleteInformationsmaterialService,
  fetchInformationsmaterial,
  fetchInterestNotifications as fetchInterestNotificationBackend,
  storeInterestNotification,
  createZinsmitteilungPDF,
  fetchInterestNotification,
  deleteInterestNotification as deleteInterestNotificationFromBe,
  deleteInterestNotificationMail,
  sendMailForInformationsmaterialUpload,
} from "services/cssdService";
import history from "util/history";
import { snackbarActions } from "../shared/actions";
import { appIntl } from "components/i18n/intl";
import { messages } from "components/CssdInformationsmaterial/CssdInformationsmaterialMessages";
import { zeroAddress } from "util/constants";
import { messages as notificationMessages } from "components/CssdInterestNotification/messages";
import { getIdProxyAddr, toHex, toUtf8 } from "services/web3Services/commons";
import { removeDots } from "util/convertor";
import { generateCssdInterestNotificationFingerprint } from "util/fingerprint";
import {
  modifyInterestNotification,
  modifyAndOfferInterestNotification,
  offerInterestNotification,
  cancelInterestNotification as cancelInterestNotificationOnBc,
  closeInterestNotification as closeInterestNotificationOnBc,
} from "services/web3Services/cssd";
import { getCssdRefId, getKennung, getAccount } from "redux/selectors";
import { sendBulkMailForNeueZinsmitteilung } from "services/mailService";

export const cssdInfomaterialSagas = [
  takeLatest(LOAD_CSSD_FOR_TEILFORDERUNG, fetchCssd),
  takeLatest(FETCH_INFORMATIONSMATERIAL_METADATA, fetchInformationsMaterialMetadata),
  takeLatest(SAVE_INFORMATIONSMATERIAL_REQUEST, saveInformationsmaterial),
  takeLatest(DOWNLOAD_INFORMATIONSMATERIAL, downloadInformationsmaterial),
  takeLatest(DELETE_INFORMATIONSMATERIAL, deleteInformationsmaterial),
  takeLatest(CSSD_INTEREST_NOTIFICATION_OPEN, fetchInterestNotifications),
  takeLatest(CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER, saveInterestNotification),
  takeLatest(
    CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER,
    saveAndConfirmInterestNotification
  ),
  takeLatest(CSSD_INTEREST_NOTIFICATION_CONFIRM, confirmInterestNotification),
  takeLatest(CSSD_INTEREST_NOTIFICATION_CANCEL, cancelInterestNotification),
  takeLatest(CSSD_INTEREST_NOTIFICATION_DELETE, deleteInterestNotification),
  takeLatest(INTEREST_NOTIFICATION_EVENT, updateIntrestNotification),
];

export function* fetchInformationsMaterialMetadata({ queryData }) {
  try {
    let response;
    if (queryData.cessionId) {
      response = yield queryInformationsmaterialMetadataForNewDg(
        queryData.idProxyAddress,
        queryData.cssdId,
        queryData.cessionId,
        queryData.filters,
        queryData.sortCriteria,
        queryData.pagingData
      );
    } else {
      response = yield queryInformationsmaterialMetadata(
        queryData.idProxyAddress,
        queryData.cssdId,
        queryData.filters,
        queryData.sortCriteria,
        queryData.pagingData
      );
    }
    yield put(cssdInformationsmaterial.fetchInformationsMaterialMetadataSuccess(response.data));
  } catch (e) {
    yield put(cssdInformationsmaterial.fetchInformationsMaterialMetadataFailure());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(messages.error_metadata_fetch_failed))
    );
  }
}

export function* downloadInformationsmaterial({ arranger, cssdId, dateiId }) {
  try {
    yield put(downloadCssdInformationsmaterial.downloading(dateiId));
    yield call(fetchInformationsmaterial, arranger, cssdId, dateiId);
    yield put(downloadCssdInformationsmaterial.success(dateiId));
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.downloadSuccess)));
  } catch (error) {
    yield put(downloadCssdInformationsmaterial.failure(dateiId));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.downloadFail)));
  }
}

export function* deleteInformationsmaterial({ arranger, cssdId, dateiId, mitarbeiter }) {
  try {
    yield put(deleteCssdInformationsmaterial.deleting(dateiId));
    yield call(deleteInformationsmaterialService, arranger, cssdId, dateiId, mitarbeiter);
    yield put(deleteCssdInformationsmaterial.success(dateiId));
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.deleteSuccess)));
  } catch (error) {
    yield put(deleteCssdInformationsmaterial.failure(dateiId));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.deleteFail)));
  }
}

export function* fetchCssd({ teilforderung }) {
  try {
    yield put(
      cssdInformationsmaterial.openInfomaterialOverviewWithCSSD({
        cssdId: teilforderung.cssdId,
        zahlstelle: { idProxyAdresse: teilforderung.idProxyAdresseZahlstelle },
        entstandenAusAbtretungId: teilforderung.entstandenAusAbtretungId,
      })
    );
    history.push(history.location.pathname + "/informationmaterial");
  } catch (e) {
    console.error(e);
  }
}

export function* saveInformationsmaterial({ arranger, cssdId, informationsmaterial }) {
  try {
    yield put(saveCssdInformationsmaterial.uploading());
    yield call(uploadInformationsmaterial, arranger, cssdId, informationsmaterial);
    yield spawn(
      sendMailForInformationsmaterialUpload,
      arranger,
      cssdId,
      informationsmaterial.betreff,
      informationsmaterial.text,
      informationsmaterial.documents.map((document) => document.dokumentTyp)
    );
    yield put(saveCssdInformationsmaterial.success());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.uploadSuccess)));
    history.goBack();
  } catch (e) {
    console.error(e);
    yield put(saveCssdInformationsmaterial.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.uploadFail)));
  }
}

function* getInterestNotificationCssdId() {
  return yield select(getCssdRefId);
}
function* getAccountFromStore() {
  return yield select(getAccount);
}

function buildPostInterestNotification(notification) {
  const { status, firstConfirmer, fourEyesStatus, ...rest } = notification;
  return {
    ...rest,
    zinssatz: Number(removeDots(notification.zinssatz)),
    festgestellterReferenzzinssatz: notification.festgestellterReferenzzinssatz
      ? Number(removeDots(notification.festgestellterReferenzzinssatz))
      : null,
    margenanpassung: notification.margenanpassung
      ? Number(removeDots(notification.margenanpassung))
      : null,
    anzahlTage: Number(removeDots(notification.anzahlTage)),
  };
}

export function* fetchInterestNotifications(action) {
  try {
    let cssdId;
    if (action && action.cssd) {
      cssdId = action.cssd.cssdId;
    } else {
      cssdId = yield call(getInterestNotificationCssdId);
    }
    const { data } = yield call(fetchInterestNotificationBackend, getIdProxyAddr(), cssdId);
    yield put(InterestNotificationActions.storeNotifications(data.cssdZinsmitteilungResponses));
  } catch (error) {
    console.error(error);
  }
}

export function* saveInterestNotification({ notification }) {
  try {
    const notificationWithFP = yield generateCssdInterestNotificationFingerprint(
      buildPostInterestNotification(notification)
    );
    const cssdId = yield call(getInterestNotificationCssdId);
    const account = yield call(getAccountFromStore);

    const { data } = yield call(
      storeInterestNotification,
      getIdProxyAddr(),
      cssdId,
      notificationWithFP
    );
    yield call(
      modifyInterestNotification,
      account,
      getIdProxyAddr(),
      toHex(`${cssdId}`),
      toHex(`${data.zinsmitteilungId}`),
      notificationWithFP.fingerprint
    );
    yield put(InterestNotificationActions.closeRegister());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(notificationMessages.success)));
  } catch (error) {
    console.error(error);
    yield put(snackbarActions.openError(appIntl().formatMessage(notificationMessages.error)));
    yield put(InterestNotificationActions.failure());
  }
}

export function* saveAndConfirmInterestNotification({ account, notification }) {
  try {
    const notificationWithFP = yield generateCssdInterestNotificationFingerprint(
      buildPostInterestNotification(notification)
    );
    const cssdId = yield call(getInterestNotificationCssdId);

    const { data } = yield call(
      storeInterestNotification,
      getIdProxyAddr(),
      cssdId,
      notificationWithFP
    );
    yield call(
      modifyAndOfferInterestNotification,
      account,
      getIdProxyAddr(),
      toHex(`${cssdId}`),
      toHex(`${data.zinsmitteilungId}`),
      notificationWithFP.fingerprint
    );
    yield put(InterestNotificationActions.closeRegister());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(notificationMessages.success)));
  } catch (error) {
    console.error(error);
    yield put(InterestNotificationActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(notificationMessages.error)));
  }
}

export function* confirmInterestNotification({ account, notification }) {
  try {
    const cssdId = yield call(getInterestNotificationCssdId);
    const idProxyArranger = getIdProxyAddr();

    if (notification.firstConfirmer !== zeroAddress) {
      yield call(
        createZinsmitteilungPDF,
        idProxyArranger,
        notification.cssdId,
        notification.zinsmitteilungId
      );

      yield call(sendBulkMailForNeueZinsmitteilung, {
        arranger: idProxyArranger,
        cssdId: cssdId,
      });
    }

    yield call(
      offerInterestNotification,
      account,
      idProxyArranger,
      toHex(`${cssdId}`),
      toHex(`${notification.zinsmitteilungId}`),
      notification.fingerprint
    );
    yield put(InterestNotificationActions.closeRegister());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(notificationMessages.success)));
  } catch (error) {
    console.error(error);
    yield put(InterestNotificationActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(notificationMessages.error)));
  }
}

export function* cancelInterestNotification({ account, notification }) {
  try {
    const cssdId = yield call(getInterestNotificationCssdId);

    yield call(
      cancelInterestNotificationOnBc,
      account,
      getIdProxyAddr(),
      toHex(`${cssdId}`),
      toHex(`${notification.zinsmitteilungId}`),
      notification.fingerprint
    );

    yield put(InterestNotificationActions.closeRegister());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(notificationMessages.success)));
  } catch (error) {
    console.error(error);
    yield put(InterestNotificationActions.failure());
    yield put(snackbarActions.openError(appIntl().formatMessage(notificationMessages.error)));
  }
}

export function* deleteInterestNotification({ account, notification }) {
  try {
    const { cssdId, dateiId, zinsmitteilungId, fingerprint } = notification;
    yield put(deleteCssdInformationsmaterial.deleting(dateiId));

    const idProxyArranger = getIdProxyAddr();
    let cssdIdForInterestNotification = cssdId;
    if (!cssdIdForInterestNotification) {
      cssdIdForInterestNotification = yield call(getInterestNotificationCssdId);
    }

    const user = yield select(getKennung);

    yield all([
      call(
        closeInterestNotificationOnBc,
        account,
        idProxyArranger,
        toHex(`${cssdIdForInterestNotification}`),
        toHex(`${zinsmitteilungId}`),
        fingerprint
      ),
      call(
        deleteInformationsmaterialService,
        idProxyArranger,
        cssdIdForInterestNotification,
        dateiId,
        user
      ),
      call(
        deleteInterestNotificationFromBe,
        idProxyArranger,
        cssdIdForInterestNotification,
        zinsmitteilungId
      ),
      spawn(
        deleteInterestNotificationMail,
        idProxyArranger,
        cssdIdForInterestNotification,
        zinsmitteilungId
      ),
    ]);

    yield put(deleteCssdInformationsmaterial.success(dateiId));
    yield put(deleteInterestNotificationDialogActions.close());
    yield put(
      snackbarActions.openSuccess(appIntl().formatMessage(notificationMessages.success_delete))
    );
  } catch (error) {
    console.error(error);
    yield put(InterestNotificationActions.failure());
    yield put(
      snackbarActions.openError(appIntl().formatMessage(notificationMessages.error_delete))
    );
  }
}

export function* updateIntrestNotification({ event }) {
  try {
    const { payingAgent, cssdId, interestNotificationId } = event;
    const { data } = yield call(
      fetchInterestNotification,
      payingAgent,
      Number(toUtf8(cssdId)),
      Number(toUtf8(interestNotificationId))
    );
    yield put(interestNotificationEventActions.update(data));
  } catch (error) {
    console.error(error);
  }
}
