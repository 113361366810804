import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_SUCCESS,
  CREATE_WALLET_FAILURE,
  USER_IS_ONBOARDED,
  VALIDATE_ONBOARDING_TOKEN_SUCCESS,
} from "./actions";

// reducer with initial state
const initialState = {
  account: {},
  privileges: {
    isSuperUser: false,
    companyIsPlaformCoordinator: false,
    privilegeBssdOperations: false,
    privilegeCssdOperations: false,
    privilegeSsdOffer: false,
    interestNotification: false,
    zahlstellenMa: false,
    readOnly: false,
  },
  isPlatformMemberInvited: false,
  isLoggedIn: false,
  signInPending: false,
  signInError: false,
  signInErrorCode: "",
  createWalletPending: false,
  showWalletInformation: false,
  userIsOnboarded: false,
};

function signInRequestReducer(state) {
  return {
    ...state,
    isLoggedIn: false,
    signInPending: true,
    signInError: false,
  };
}

function signInFailureReducer(state, action) {
  return {
    ...state,
    signInPending: false,
    signInError: true,
    signInErrorCode: action.error,
  };
}

function signInSuccessReducer(state, action) {
  return {
    ...state,
    isLoggedIn: true,
    signInPending: false,
    signInError: false,
    onBoardingToken: null,
    token: action.token,
    account: action.account,
    privileges: action.privileges,
    showWalletInformation: false,
  };
}

function createWalletRequestReducer(state) {
  return { ...state, createWalletPending: true };
}

function createWalletFailureReducer(state) {
  return { ...state, createWalletPending: false };
}

function createWalletSuccessReducer(state, action) {
  return {
    ...state,
    createWalletPending: false,
    showWalletInformation: true,
    generatedWalletEncrypted: action.wallet,
  };
}

function userIsOnboardedReducer(state) {
  return { ...state, userIsOnboarded: true };
}

function validateOnboardingTokenSuccessReducer(state, action) {
  return {
    ...state,
    onBoardingToken: action.token,
    onBoardingUrl: action.backendUrl,
    isPlatformMemberInvited: action.isPlatformMemberInvited,
    platformMemberEmail: action.platformMemberEmail,
  };
}

export function signInReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return signInRequestReducer(state, action);
    case SIGN_IN_FAILURE:
      return signInFailureReducer(state, action);
    case SIGN_IN_SUCCESS:
      return signInSuccessReducer(state, action);
    case CREATE_WALLET_REQUEST:
      return createWalletRequestReducer(state, action);
    case CREATE_WALLET_FAILURE:
      return createWalletFailureReducer(state, action);
    case CREATE_WALLET_SUCCESS:
      return createWalletSuccessReducer(state, action);

    case USER_IS_ONBOARDED:
      return userIsOnboardedReducer(state, action);

    case VALIDATE_ONBOARDING_TOKEN_SUCCESS:
      return validateOnboardingTokenSuccessReducer(state, action);
    default:
      return state;
  }
}
