import { defineMessages } from "react-intl";

export const messages = defineMessages({
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Rueckzahlung_ausstehend: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.PaymentStatusChips.Rueckzahlung_ausstehend",
    defaultMessage: "Rückzahlung ausstehend",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Initial: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.PaymentStatusChips.Initial",
    defaultMessage: "Initial",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Rueckzahlung_erhalten: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.PaymentStatusChips.Rueckzahlung_erhalten",
    defaultMessage: "Rückzahlung erhalten",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Zahlung_erhalten: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.PaymentStatusChips.Zahlung_erhalten",
    defaultMessage: "Zahlung erhalten",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_PaymentStatusChips_Zahlung_ausstehend: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.PaymentStatusChips.Zahlung_ausstehend",
    defaultMessage: "Zahlung ausstehend",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdCessionStatusChip_abgetreten: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdCessionStatusChip.abgetreten",
    defaultMessage: "Abgetreten",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdCessionStatusChip_offene_abtretung: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdCessionStatusChip.offene.abtretung",
    defaultMessage: "offene Abtretung",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdCessionStatusChip_offene_abtretungen: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdCessionStatusChip.offene.abtretungen",
    defaultMessage: "offene Abtretungen",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Kuendigung_erfasst: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Kuendigung_erfasst",
    defaultMessage: "Kündigung erfasst",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_gekuendigt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.gekuendigt",
    defaultMessage: "Gekündigt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Initial: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Initial",
    defaultMessage: "Initial",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Neu: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Neu",
    defaultMessage: "Neu",
  },

  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Abgelehnt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Abgelehnt",
    defaultMessage: "Abgelehnt",
  },

  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Erfasst: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Erfasst",
    defaultMessage: "Erfasst",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_In_Pruefung: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.In_Pruefung",
    defaultMessage: "In Prüfung",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_bestaetigt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.bestaetigt",
    defaultMessage: "Bestätigt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Storniert: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Storniert",
    defaultMessage: "Storniert",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Zurueckgezahlt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Zurueckgezahlt",
    defaultMessage: "Zurückgezahlt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Abtretungszertifikat_ausgestellt: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Abtretungszertifikat_ausgestellt",
    defaultMessage: "Abtretungszertifikat ausgestellt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Urkunde_ausgestellt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Urkunde_ausgestellt",
    defaultMessage: "Urkunde ausgestellt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Endfaellig: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Endfaellig",
    defaultMessage: "Endfällig",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Rueckkauf_storniert: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Rueckkauf_storniert",
    defaultMessage: "Rückkauf storniert",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Angezeigt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Angezeigt",
    defaultMessage: "Angezeigt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Externes_Abtretungszertifikat_ausgestellt: {
    id:
      "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Externes_Abtretungszertifikat_ausgestellt",
    defaultMessage: "Externes Abtretungszertifikat ausgestellt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Rueckkauf_abgelehnt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Rueckkauf_abgelehnt",
    defaultMessage: "Rückkauf abgelehnt",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Rueckkauf_erfasst: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Rueckkauf_erfasst",
    defaultMessage: "Rückkauf erfasst",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Rueckkauf_in_Pruefung: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Rueckkauf_in_Pruefung",
    defaultMessage: "Rückkauf in Prüfung",
  },
  BssdOverview_ssdContentwrapper_ssdContent_StatusChips_ssdStatusChip_Rueckkauf_bestaetigt: {
    id: "BssdOverview.ssdContentwrapper.ssdContent.StatusChips.ssdStatusChip.Rueckkauf_bestaetigt",
    defaultMessage: "Rückkauf bestätigt",
  },
});
