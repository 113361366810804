import { sendSignedHttpRequest } from "util/requestSigning";

export async function evictWalletPermissionCache(idProxyAddress, walletadress) {
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/evictcache/${walletadress}`,
    idProxyAddress
  ).catch(function (error) {
    console.error(error);
  });
}

export async function evictAllPermissionCache(idProxyAddress) {
  return sendSignedHttpRequest("delete", `/digits-server/evictcache/all`, idProxyAddress).catch(
    function (error) {
      console.error(error);
    }
  );
}
