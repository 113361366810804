import { defineMessages } from "react-intl";

export const messages = defineMessages({
  registrationAlreadySucceededMessage: {
    id: "shared.signin.registration.already.succeded.message",
    defaultMessage: "Eine Registrierung wurde bereits erfolgreich durchgeführt!",
  },
  walletAlreadyLinkedMessage: {
    id: "shared.signin.wallet.already.linked.message",
    defaultMessage: "Ein Nutzer ist bereits mit diesem Wallet registriert.",
  },
  noPrivilegesDialogTitle: {
    id: "shared.signin.noprivileges.dialog.title",
    defaultMessage: "Anmeldung fehlgeschlagen",
  },
  noPrivilegesDialogBody: {
    id: "shared.signin.noprivileges.dialog.body",
    defaultMessage:
      "Anmeldung fehlgeschlagen, da Sie keine Berechtigungen haben oder Ihr Konto gesperrt wurde. Bitte wenden Sie sich an Ihren Unternehmenskoordinator!",
  },
  permissionsCanBeAssignedDialogTitle: {
    id: "signin.permissions.assignable.dialog.title",
    defaultMessage: "Registrierung erfolgreich",
  },
  permissionsCanBeAssignedDialogBody: {
    id: "signin.permissions.assignable.dialog.body",
    defaultMessage: "Ihnen können nun Berechtigungen zugewiesen werden.",
  },
  invitationExpiredDialogTitle: {
    id: "shared.signin.invitation.expired.dialog.title",
    defaultMessage: "Einladung abgelaufen",
  },
  invitationExpiredDialogMessage: {
    id: "shared.signin.invitation.expired.dialog.message",
    defaultMessage:
      "Ihre Einladung für diese Anwendung ist nicht (mehr) gültig. " +
      "Bitte lassen Sie sich erneut eine Einladung zusenden!",
  },
  linkAlreadyUsedDialogTitle: {
    id: "shared.signin.link.already.used.dialog.title",
    defaultMessage: "Link bereits verwendet",
  },
  linkAlreadyUsedDialogMessage: {
    id: "shared.signin.link.already.used.dialog.message",
    defaultMessage:
      "Der Link für diese Einladung wurde bereits verwendet." +
      "Melden Sie sich mit Ihrem Wallet an oder lassen Sie sich erneut eine Einladung zusenden!",
  },
  closeDialogButton: {
    id: "shared.signin.close.dialog.button",
    defaultMessage: "Schließen",
  },
  openWalletButton: {
    id: "shared.signin.open.wallet.button",
    defaultMessage: "wallet auswählen",
  },
  chooseWalletMessage: {
    id: "shared.signin.choose.wallet.message",
    defaultMessage: "Bitte wählen Sie ein Wallet aus.",
  },
  passwordInput: {
    id: "shared.signin.password.input",
    defaultMessage: "Passwort",
  },
  wrongPasswordMessage: {
    id: "shared.signin.wrong.password.message",
    defaultMessage: "Wallet oder Passwort stimmen nicht.",
  },
  noPermissionMessage: {
    id: "shared.signin.no.permission.message",
    defaultMessage: "Es konnten keine Berechtigungen gefunden werden.",
  },
  handshakeErrorMessage: {
    id: "shared.signin.handshake.error.message",
    defaultMessage: "Es konnte keine Verbindung hergestellt werden.",
  },
  signInButton: {
    id: "shared.signin.shared.signin.button",
    defaultMessage: "weiter",
  },
  checkButton: {
    id: "shared.signin.shared.signin.check",
    defaultMessage: "prüfen",
  },
  noWalletButton: {
    id: "shared.signin.no.wallet.button",
    defaultMessage: "kein Wallet ?",
  },
  nextButton: {
    id: "shared.signin.next.button",
    defaultMessage: "Weiter",
  },
  passwordLabel: {
    id: "shared.signin.password.label",
    defaultMessage: "Passwort",
  },
  repeatPasswordLabel: {
    id: "shared.signin.repeat.password.label",
    defaultMessage: "Passwort wiederholen",
  },
  passwordHelper: {
    id: "shared.signin.password.complexity.helper",
    defaultMessage:
      "Mindestpasswortlänge: 10 Zeichen, davon mind. 1\n" +
      "Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer und 1\n" +
      "Sonderzeichen. Erlaubte Sonderzeichen sind (!@#$%^&)_-*",
  },
  walletInfoMessage: {
    id: "shared.signin.wallet.info.message",
    defaultMessage: "Ihr Wallet und Ihr Passwort ermöglichen Ihnen den Zugang zum System!",
  },
  secureYourWalletMessage: {
    id: "shared.signin.secure.your.wallet.message",
    defaultMessage: "Sichern Sie Ihre Wallet-Datei und Ihr Passwort!",
  },
  lostAccessMessageBeginning: {
    id: "shared.signin.lost.access.message.beginning",
    defaultMessage: "Beim Verlust des Wallets oder des Passworts kann Ihr Zugang zum System ",
  },
  lostAccessMessageUnderlined: {
    id: "shared.signin.lost.access.message.underlined",
    defaultMessage: "nicht",
  },
  lostAccessMessageEnd: {
    id: "shared.signin.lost.access.message.end",
    defaultMessage: " wiederhergestellt werden!",
  },
  createWalletButton: {
    id: "shared.signin.create.wallet.button",
    defaultMessage: "Wallet erstellen",
  },
  backButton: {
    id: "shared.signin.back.button",
    defaultMessage: "zurück",
  },
  finledgerLogin: {
    id: "shared.signin.finledger.login",
    defaultMessage: "Finledger Login",
  },
  finledgerCheck: {
    id: "shared.signin.finledger.check",
    defaultMessage: "Finledger Check",
  },
  loginTitle: {
    id: "shared.signin.login.title",
    defaultMessage: "Mich einloggen",
  },
  registerTitle: {
    id: "shared.signin.register.title",
    defaultMessage: "Mich registrieren",
  },
  validateCertificateTitle: {
    id: "shared.signin.validate.certificate.title",
    defaultMessage: "Eine Urkunde prüfen",
  },
  welcomeMessageTop: {
    id: "shared.signin.welcome.message.top",
    defaultMessage: "Willkommen bei Finledger!",
  },
  welcomeMessageBottom: {
    id: "shared.signin.welcome.message.bottom",
    defaultMessage: "Was möchten Sie tun?",
  },
  loginTabTitle: {
    id: "shared.signin.login.tab.title",
    defaultMessage: "einloggen",
  },
  registerTabTitle: {
    id: "shared.signin.register.tab.title",
    defaultMessage: "registrieren",
  },
  registerTooltipNotOnboarded: {
    id: "shared.signin.register.tooltip.onboarded",
    defaultMessage:
      "Falls Ihr Unternehmen noch nicht auf Finledger ongeboardet ist, wenden Sie sich bitte an info@dwpbank.de.",
  },
  registerTooltipOnboarded: {
    id: "shared.signin.register.tooltip.not.onboarded",
    defaultMessage:
      "Falls Ihr Unternehmen bereits Finledger-Teilnehmer sein sollte, wenden Sie Sich bitte an Ihren Unternehmenskoordinator.",
  },
  usageButtonText: {
    id: "shared.signin.usage.button.text",
    defaultMessage: "Wie funktioniert das?",
  },
  usageButtonTooltip: {
    id: "shared.signin.usage.button.tooltip",
    defaultMessage:
      'Wählen Sie zunächst bitte aus, ob Sie ein Dokument aus dem Corporate- oder aus dem Banken-Schuldscheinbereich prüfen wollen. Klicken Sie dann auf "Urkunde hochladen" und wählen Sie im anschließend geöffneten Dialog das Dokument aus, das geprüft werden soll. Im Anschluss sehen Sie das Prüfergebnis.',
  },
  lostWalletButtonText: {
    id: "shared.signin.lost.wallet.button.text",
    defaultMessage: "Wallet verloren?",
  },
  lostWalletButtonTooltip: {
    id: "shared.signin.lost.wallet.button.tooltip",
    defaultMessage: "Bitte wenden Sie sich an den Unternehmenskoordinator Ihres Unternehmens.",
  },
  forgottenPasswordButtonText: {
    id: "shared.signin.forgotten.password.button.text",
    defaultMessage: "Passwort vergessen",
  },
  forgottenPasswordTooltip: {
    id: "shared.signin.forgotten.password.button.tooltip",
    defaultMessage: "Bitte wenden Sie sich an den Unternehmenskoordinator Ihres Unternehmens.",
  },
});
