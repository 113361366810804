import { call, takeLatest, put, fork, all, select } from "redux-saga/effects";
import { getAccount } from "redux/selectors";
import {
  INVITE_BUSINESS_PARTNER_SEND,
  GET_BUSINESS_PARTNER_INVITES_REQUEST,
  GET_BP_PLATFORM_MEMBERS_REQUEST,
  ACCEPT_BUSINESS_PARTNER_REQUEST,
  REJECT_BUSINESS_PARTNER_REQUEST,
  getBusinessPartnerInvitesActions,
  getBusinessPartnersActions,
  getPlatformMembersActions,
  getPlatformMemberDetailsActions,
  inviteBusinessPartnerActions,
  acceptBusinessPartnerActions,
  rejectBusinessPartnerActions,
  SAVE_BUSINESSPARTNER_PARTNER_ID,
  editBusinesspartnerPartnerId,
  ASSIGN_GP_TO_TRADE_REQUEST,
  assignGpToTradeActions,
  ASSIGN_GP_TO_TRADE_SHOW,
  disconnectGPAndTradeActions,
  DISCONNECT_GP_AND_TRADE_REQUEST,
  GET_BP_PLATFORM_MEMBER_DETAILS_RETRY,
} from "./actions";
import {
  getBusinessPartners,
  getPendingInvites,
  addPartner,
  getPublicPlatformMemberInfo,
  getIdentity,
  sendPartnerInvite,
  acceptInvite,
  getCompletePartnerInfo,
  finalizeAccept,
  rejectInvite,
  cleanUpRejectedInvites,
  updatePartnerId,
} from "services/businessPartnerService";
import { getPlatformMemberDetails } from "services/platformMemberService";
import { fetchMemberOnboardedEvents } from "services/web3Services/platformMembers";
import { snackbarActions } from "redux/shared/actions";
import { businessPartnerState, FourEyesState, zeroAddress, ZERO_HASH } from "util/constants";
import { appIntl } from "components/i18n/intl";
import { messages } from "./messages";
import { getAllPartnerInfo } from "../../services/partnerService";
import {
  connectGP,
  cancelConnectGP,
  getConnectGPEvents,
  getConnectGPFourEyes,
} from "services/web3Services/cssd";
import { toHex } from "services/web3Services/commons";
import { updateExternalCssdData } from "redux/cssdActions/sagas";
import { updateExternalPartialClaimDG } from "redux/cssdCession/sagas";
import { queryCSSD } from "services/cssdService";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export const businessPartnerSagas = [
  takeLatest(INVITE_BUSINESS_PARTNER_SEND, inviteBusinessPartner),
  takeLatest(GET_BUSINESS_PARTNER_INVITES_REQUEST, fetchBusinessPartnerInvites),
  takeLatest(GET_BP_PLATFORM_MEMBERS_REQUEST, fetchPlatformMembers),
  takeLatest(GET_BP_PLATFORM_MEMBER_DETAILS_RETRY, retryFetch),
  takeLatest(ACCEPT_BUSINESS_PARTNER_REQUEST, acceptBusinessPartnerInvite),
  takeLatest(REJECT_BUSINESS_PARTNER_REQUEST, rejectBusinessPartnerInvite),
  takeLatest(SAVE_BUSINESSPARTNER_PARTNER_ID, savePartnerId),
  takeLatest(ASSIGN_GP_TO_TRADE_SHOW, checkAssignedGPStatus),
  takeLatest(ASSIGN_GP_TO_TRADE_REQUEST, assignGPToTradeSagas),
  takeLatest(DISCONNECT_GP_AND_TRADE_REQUEST, disconnectGPAndTradeSagas),
];

export function* checkAssignedGPStatus({ trade }) {
  try {
    const { cssdId, id: patrialClaimId, idProxyAdresseZahlstelle } = trade;

    const connectGpEvents = yield call(
      getConnectGPEvents,
      toHex(`${cssdId}`),
      patrialClaimId ? toHex(`${patrialClaimId}`) : ZERO_HASH
    );
    if (connectGpEvents.length >= 1) {
      const { gpHash, gpAddress } = connectGpEvents[connectGpEvents.length - 1]?.returnValues;
      const fourEyesState = yield call(
        getConnectGPFourEyes,
        idProxyAdresseZahlstelle ? idProxyAdresseZahlstelle : trade.zahlstelle.idProxyAdresse,
        patrialClaimId ? toHex(`${patrialClaimId}`) : toHex(`${trade.cssdId}`),
        gpHash
      );
      const updatedTrade = {
        ...trade,
        gpConnectFourEyesState: fourEyesState.state,
        gpConnectConfirmer: fourEyesState.firstConfirmer,
        connectedGP: gpAddress,
      };
      yield put(assignGpToTradeActions.open(updatedTrade));
    } else {
      const updatedTrade = {
        ...trade,
        gpConnectFourEyesState: FourEyesState.NONE,
        gpConnectConfirmer: zeroAddress,
        connectedGP: zeroAddress,
      };
      yield put(assignGpToTradeActions.open(updatedTrade));
    }
  } catch (error) {
    console.error(error);
  }
}

const CONNECT_GP_KEYS = {
  DN: "darlehensnehmer",
  DG: "darlehensgeber",
};

export function* assignGPToTradeSagas({ gp, trade }) {
  try {
    const { cssdId, cssdFingerprint, id: patrialClaimId, idProxyAdresseZahlstelle } = trade;
    const account = yield select(getAccount);

    if (trade.gpConnectConfirmer !== zeroAddress) {
      if (patrialClaimId) {
        if (trade.entstandenAusAbtretungId) {
          yield call(updateExternalPartialClaimDG, trade, gp.idProxyAddress, false);
        } else {
          const { data: cssdTrade } = yield queryCSSD(
            idProxyAdresseZahlstelle,
            cssdId,
            cssdFingerprint
          );
          yield call(
            updateExternalCssdData,
            cssdTrade,
            gp.idProxyAddress,
            CONNECT_GP_KEYS.DG,
            false
          );
        }
      } else {
        yield call(updateExternalCssdData, trade, gp.idProxyAddress, CONNECT_GP_KEYS.DN, false);
      }
    }

    yield call(
      connectGP,
      account,
      idProxyAdresseZahlstelle ? idProxyAdresseZahlstelle : trade.zahlstelle.idProxyAdresse,
      trade.gpConnectConfirmer,
      gp.idProxyAddress,
      gp.idProxyAddress,
      toHex(`${cssdId}`),
      patrialClaimId ? toHex(`${patrialClaimId}`) : ZERO_HASH
    );

    yield put(assignGpToTradeActions.success());
    yield put(assignGpToTradeActions.close());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.assignGPSuccess)));
  } catch (error) {
    console.error(error);
    yield put(assignGpToTradeActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.assignGPFailure)));
  }
}

export function* disconnectGPAndTradeSagas({ trade }) {
  try {
    const { cssdId, cssdFingerprint, id: patrialClaimId, idProxyAdresseZahlstelle } = trade;
    const account = yield select(getAccount);

    if (trade.connectedGP !== zeroAddress) {
      if (patrialClaimId) {
        if (trade.entstandenAusAbtretungId) {
          yield call(updateExternalPartialClaimDG, trade, zeroAddress, false);
        } else {
          const { data: cssdTrade } = yield queryCSSD(
            idProxyAdresseZahlstelle,
            cssdId,
            cssdFingerprint
          );
          yield call(updateExternalCssdData, cssdTrade, zeroAddress, CONNECT_GP_KEYS.DG, false);
        }
      } else {
        yield call(updateExternalCssdData, trade, zeroAddress, CONNECT_GP_KEYS.DN, false);
      }
    }

    yield call(
      cancelConnectGP,
      account,
      idProxyAdresseZahlstelle ? idProxyAdresseZahlstelle : trade.zahlstelle.idProxyAdresse,
      trade.connectedGP,
      toHex(`${cssdId}`),
      patrialClaimId ? toHex(`${patrialClaimId}`) : ZERO_HASH
    );

    yield put(disconnectGPAndTradeActions.success());
    yield put(assignGpToTradeActions.close());
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.disconnectGPSuccess)));
  } catch (error) {
    console.error(error);
    yield put(disconnectGPAndTradeActions.failure(error));
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.disconnectGPFailure)));
  }
}

export function* acceptBusinessPartnerInvite({ bpInfo, idProxyAddr }) {
  try {
    const myInfo = yield call(getIdentity, idProxyAddr);
    yield call(acceptInvite, bpInfo.idproxy_adresse, myInfo.data);
    const partnerInfo = yield call(getCompletePartnerInfo, idProxyAddr, bpInfo.idproxy_adresse);
    yield call(finalizeAccept, idProxyAddr, partnerInfo.data);
    yield call(fetchBusinessPartnerInvites, { idProxyAddr });
    yield put(acceptBusinessPartnerActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_business_partner_einladung_bestaetigt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(acceptBusinessPartnerActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_business_partner_einladung_konnte_nicht_bestaetigt_werden
        )
      )
    );
  }
}

export function* rejectBusinessPartnerInvite({ bpInfo, idProxyAddr, rejector, rejectReason }) {
  try {
    yield call(rejectInvite, idProxyAddr, bpInfo.idproxy_adresse);
    yield call(cleanUpRejectedInvites, idProxyAddr, bpInfo.idproxy_adresse, rejector, rejectReason);
    yield call(fetchBusinessPartnerInvites, { idProxyAddr });
    yield put(rejectBusinessPartnerActions.success());
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_business_partner_einladung_abgelehnt)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(rejectBusinessPartnerActions.failure(error));
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(
          messages.redux_business_partner_einladung_konnte_nicht_abgelehnt_werden
        )
      )
    );
  }
}

export function* inviteBusinessPartner({ invitationData, idProxyAddr }) {
  try {
    //send partner invite
    const myInfo = yield call(getIdentity, idProxyAddr);
    yield call(sendPartnerInvite, invitationData.idProxyAddress, myInfo.data);
    //add partner
    const partnerPublicInfo = yield call(
      getPublicPlatformMemberInfo,
      invitationData.idProxyAddress
    );
    yield call(addPartner, idProxyAddr, partnerPublicInfo.data);
    yield put(inviteBusinessPartnerActions.success());
    yield call(fetchPlatformMembers, { idProxyAddr });
    yield put(
      snackbarActions.openSuccess(
        appIntl().formatMessage(messages.redux_business_partner_einladung_erfolgreich_versendet)
      )
    );
  } catch (error) {
    console.error(error);
    yield put(inviteBusinessPartnerActions.failure());
    yield put(
      snackbarActions.openError(
        appIntl().formatMessage(messages.redux_business_partner_fehler_beim_senden_der_einladung)
      )
    );
  }
}

export function* fetchPlatformMemberDetails(idProxyAddr, businessPartners) {
  try {
    const { response, error } = yield call(getPlatformMemberDetails, idProxyAddr);
    if (response) {
      const details = {
        ...response.data,
        active: businessPartners.some(
          (bp) =>
            bp.idProxyAddress === response.data.idProxyAddress &&
            bp.status === businessPartnerState.ACTIVE
        ),
        pending: businessPartners.some(
          (bp) =>
            bp.idProxyAddress === response.data.idProxyAddress &&
            bp.status === businessPartnerState.PENDING
        ),
      };
      yield put(getPlatformMemberDetailsActions.success(details));
    } else {
      throw error;
    }
  } catch (error) {
    console.error(error);
    yield put(getPlatformMemberDetailsActions.failure(idProxyAddr));
  }
}

export function* retryFetch({ loadErrors, idProxyAddr }) {
  if (idProxyAddr) {
    yield fetchPlatformMemberDetails(idProxyAddr);
  } else {
    yield all(
      Object.keys(loadErrors).map((idProxyAddress) =>
        fork(fetchPlatformMemberDetails, idProxyAddress)
      )
    );
  }
}

export function* fetchPlatformMembers({ idProxyAddr }) {
  try {
    let { data, error } = yield call(fetchMemberOnboardedEvents);
    if (data) {
      const businessPartners = yield call(getBusinessPartners, idProxyAddr);
      data = data.filter((event) => event.idProxyAddress !== idProxyAddr);
      yield all(
        data.map((event) =>
          fork(fetchPlatformMemberDetails, event.idProxyAddress, businessPartners.data)
        )
      );

      yield put(getPlatformMembersActions.success(data));
    } else {
      throw error;
    }
  } catch (error) {
    console.error(error);
    yield put(getPlatformMembersActions.failure(error));
  }
}

export function* fetchBusinessPartners({ idProxyAddr }) {
  try {
    const data = yield call(getBusinessPartners, idProxyAddr);
    yield put(getBusinessPartnersActions.success(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchBusinessPartnerInvites({ idProxyAddr }) {
  try {
    const invites = yield call(getPendingInvites, idProxyAddr);
    yield put(getBusinessPartnerInvitesActions.success(invites.data));
  } catch (err) {
    yield put(getBusinessPartnerInvitesActions.failure(err));
  }
}

export function* savePartnerId({ pmIdProxyAddr, gpIdProxyAddr, partnerId }) {
  try {
    yield call(updatePartnerId, pmIdProxyAddr, gpIdProxyAddr, partnerId);
    yield call(getAllPartnerInfo, pmIdProxyAddr);
    yield put(snackbarActions.openSuccess(appIntl().formatMessage(messages.savePartnerIdSuccess)));
    yield put(editBusinesspartnerPartnerId.success());
  } catch (err) {
    yield put(snackbarActions.openError(appIntl().formatMessage(messages.savePartnerIdFailure)));
    yield put(editBusinesspartnerPartnerId.failure());
  }
  yield put(editBusinesspartnerPartnerId.closeModal());
}
