import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {
  SupervisedUserCircle as SupervisedUserCircleIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  AccountCircle as AccountCircleIcon,
  FolderShared as FolderSharedIcon,
  SettingsBackupRestore,
  Dashboard as DashboardIcon,
  Assessment as AssessmentIcon,
  Group as GroupIcon,
} from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { appIntl, isGermanOnly } from "components/i18n/intl";
import { messages } from "./messages";
import { PartialClaimIcon, CessionRequestIcon } from "components/Shared/Icons";
import LanguageIcon from "@material-ui/icons/Language";
import { LocaleContext } from "../../i18n/LocaleContext";

function MainListItems(props) {
  const { location, privileges, account, draftFirstConfirmer } = props;
  const [expanded, setExpanded] = useState(false);
  const [locale, setLocale] = React.useContext(LocaleContext);

  const handleChange = (panel) => (event) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const toggleLanguage = () => {
    if (locale === "de") {
      setLocale("en");
    } else {
      setLocale("de");
    }
  };

  const logout = () => {
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      <ListItem
        id="home-nav"
        selected={location.pathname === "/home"}
        button
        component={Link}
        to="/home"
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={appIntl().formatMessage(messages.dashboard_main_list_items_home)} />
      </ListItem>
      <Divider />
      {(privileges.privilegeBssdOperations ||
        privileges.readOnly ||
        privileges.interestNotification ||
        (privileges.privilegeBssdOperations && privileges.privilegeSsdOffer)) && (
        <>
          <ListItem button onClick={handleChange("bssd")}>
            <ListItemIcon>
              {expanded === "bssd" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary={appIntl().formatMessage(messages.dashboard_main_list_items_bssd)}
            />
          </ListItem>
          <Divider />
        </>
      )}
      <Collapse in={expanded === "bssd"} timeout="auto">
        <List component="div" disablePadding>
          {(privileges.privilegeBssdOperations ||
            privileges.readOnly ||
            privileges.interestNotification) && (
            <ListItem
              id="bssdoverview-nav"
              selected={location.pathname === "/bssdoverview"}
              button
              component={Link}
              to="/bssdoverview"
            >
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={appIntl().formatMessage(messages.dashboard_main_list_items_uebersicht)}
              />
            </ListItem>
          )}
          {(privileges.privilegeBssdOperations || privileges.readOnly) && (
            <>
              <ListItem
                id="cessionoverview-nav"
                selected={location.pathname === "/cessionoverview"}
                button
                component={Link}
                to="/cessionoverview"
              >
                <ListItemIcon>
                  <CessionRequestIcon />
                </ListItemIcon>
                <ListItemText
                  primary={appIntl().formatMessage(
                    messages.dashboard_main_list_items_abtretungsanfragen
                  )}
                />
              </ListItem>
              <Divider />
            </>
          )}
        </List>
      </Collapse>
      {((privileges.zahlstellenMa && privileges.privilegeCssdOffer) ||
        privileges.privilegeCssdOperations ||
        privileges.readOnly) && (
        <ListItem button onClick={handleChange("cssd")}>
          <ListItemIcon>
            {expanded === "cssd" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemIcon>
          <ListItemText
            primary={appIntl().formatMessage(messages.dashboard_main_list_items_cssd)}
          />
        </ListItem>
      )}
      <Divider />
      <Collapse in={expanded === "cssd"} timeout="auto">
        <List component="div" disablePadding>
          {((privileges.zahlstellenMa && privileges.privilegeCssdOffer) ||
            privileges.privilegeCssdOperations ||
            privileges.readOnly) && (
            <ListItem
              id="cssdoverview-nav"
              selected={location.pathname === "/cssdoverview"}
              button
              component={Link}
              to="/cssdoverview"
            >
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={appIntl().formatMessage(messages.dashboard_main_list_items_uebersicht)}
              />
            </ListItem>
          )}
          {(privileges.privilegeCssdOperations ||
            privileges.zahlstellenMa ||
            privileges.readOnly) && (
            <ListItem
              id="cssd-teilforderungen-nav"
              selected={location.pathname === "/cssdpartialclaimoverview"}
              button
              component={Link}
              to="/cssdpartialclaimoverview"
            >
              <ListItemIcon>
                <PartialClaimIcon />
              </ListItemIcon>
              <ListItemText
                primary={appIntl().formatMessage(
                  messages.dashboard_main_list_items_teilforderungen
                )}
              />
            </ListItem>
          )}
          {(privileges.privilegeCssdOperations || privileges.readOnly) &&
            !privileges.privilegePlatformReadOnly && (
              <ListItem
                id="cessionoverview-nav"
                selected={location.pathname === "/cssdcessionrequest"}
                button
                component={Link}
                to="/cssdcessionrequest"
              >
                <ListItemIcon>
                  <CessionRequestIcon />
                </ListItemIcon>
                <ListItemText
                  primary={appIntl().formatMessage(
                    messages.dashboard_main_list_items_uebertragungsanfragen
                  )}
                />
              </ListItem>
            )}
          <Divider />
        </List>
      </Collapse>
      {privileges.isSuperUser && (
        <ListItem
          id="colleague-nav"
          selected={location.pathname === "/colleague"}
          button
          component={Link}
          to="/colleague"
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={appIntl().formatMessage(messages.dashboard_main_list_items_mitarbeiter)}
          />
        </ListItem>
      )}
      {privileges.isSuperUser && privileges.companyIsPlaformCoordinator && (
        <ListItem
          id="platformmember-nav"
          selected={location.pathname === "/platformmember"}
          button
          component={Link}
          to="/platformmember"
        >
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={appIntl().formatMessage(
              messages.dashboard_main_list_items_plattformmitglieder
            )}
          />
        </ListItem>
      )}
      {privileges.isSuperUser && (
        <ListItem
          id="businesspartner-nav"
          selected={location.pathname === "/businesspartner"}
          button
          component={Link}
          to="/businesspartner"
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText
            primary={appIntl().formatMessage(messages.dashboard_main_list_items_geschaeftspartner)}
          />
        </ListItem>
      )}
      {privileges.isSuperUser && privileges.companyIsPlaformCoordinator && (
        <ListItem
          id="recovery-nav"
          selected={location.pathname === "/recovery"}
          button
          component={Link}
          to="/recovery"
        >
          <ListItemIcon>
            <SettingsBackupRestore />
          </ListItemIcon>
          <ListItemText
            primary={appIntl().formatMessage(messages.dashboard_main_list_items_recovery)}
          />
        </ListItem>
      )}
      {privileges.isSuperUser && <Divider />}
      <ListItem
        id="profile-nav"
        selected={location.pathname === "/profile"}
        button
        component={Link}
        to="/profile"
      >
        <ListItemIcon>
          {draftFirstConfirmer && draftFirstConfirmer !== account.address ? (
            <Badge badgeContent={"!"} color="error">
              <AccountCircleIcon />
            </Badge>
          ) : (
            <AccountCircleIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={appIntl().formatMessage(messages.dashboard_main_list_items_mein_profil)}
        />
      </ListItem>
      <ListItem
        id="user-information-nav"
        selected={location.pathname === "/userinformation"}
        button
        component={Link}
        to="/userinformation"
      >
        <ListItemIcon>
          <FolderSharedIcon />
        </ListItemIcon>
        <ListItemText
          primary={appIntl().formatMessage(messages.dashboard_main_list_items_nutzerinformationen)}
        />
      </ListItem>
      {!isGermanOnly() && (
        <Tooltip
          title={appIntl().formatMessage(
            messages.dashboard_main_list_items_tooltip_title_sprache_aendern
          )}
          aria-label={appIntl().formatMessage(
            messages.dashboard_main_list_items_tooltip_aria_label_sprache_aendern
          )}
        >
          <MenuItem onClick={toggleLanguage}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            {locale === "de" ? "English" : "Deutsch"}
          </MenuItem>
        </Tooltip>
      )}
      <ListItem id="logout-btn" button onClick={logout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText
          primary={appIntl().formatMessage(messages.dashboard_main_list_items_abmelden)}
        />
      </ListItem>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.signInReducer.account,
    draftFirstConfirmer: state.accountReducer.draftFirstConfirmer,
    privileges: state.signInReducer.privileges,
  };
};

export default connect(mapStateToProps, {})(MainListItems);
