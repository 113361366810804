export function checkifMaturityDateInPast(dateStr) {
  if (dateStr === "0") {
    return false;
  }
  try {
    const radix = 10;
    const maturityDate = new Date(parseInt(dateStr, radix) * 1000);
    const now = new Date();
    maturityDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    return now >= maturityDate;
  } catch (error) {
    console.error(error);
    return false;
  }
}
