import {
  CLEAR_FILTER_AND_SORTING,
  FETCH_INFORMATIONSMATERIAL_METADATA_SUCCESS,
  FILTER_METADATA_TABLE,
  OPEN_CSSD_INFORMATIONSMATERIAL_OVERVIEW,
  SET_PAGE_METADATA_TABLE,
  SET_PAGE_SIZE_METADATA_TABLE,
  SORT_METADATA_TABLE,
  DOWNLOAD_INFORMATIONSMATERIAL_STARTED,
  DOWNLOAD_INFORMATIONSMATERIAL_SUCCESS,
  DOWNLOAD_INFORMATIONSMATERIAL_FAILURE,
  UPLOAD_INFORMATIONSMATERIAL_STARTED,
  UPLOAD_INFORMATIONSMATERIAL_SUCCESS,
  UPLOAD_INFORMATIONSMATERIAL_FAILURE,
  DELETE_INFORMATIONSMATERIAL_STARTED,
  DELETE_INFORMATIONSMATERIAL_SUCCESS,
  DELETE_INFORMATIONSMATERIAL_FAILURE,
  EXPAND_INFORMATIONSMATERIALMETADATA,
  SHRINK_INFORMATIONSMATERIALMETADATA,
  FETCH_INFORMATIONSMATERIAL_METADATA,
  FETCH_INFORMATIONSMATERIAL_METADATA_FAILURE,
  CSSD_INTEREST_NOTIFICATION_OPEN,
  CSSD_INTEREST_NOTIFICATION_OPEN_REGISTER,
  CSSD_INTEREST_NOTIFICATION_CLOSE_REGISTER,
  CSSD_INTEREST_NOTIFICATION_STORE,
  CSSD_INTEREST_NOTIFICATION_EDIT,
  CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER,
  CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER,
  CSSD_INTEREST_NOTIFICATION_CONFIRM,
  CSSD_INTEREST_NOTIFICATION_CANCEL,
  CSSD_INTEREST_NOTIFICATION_FAILURE,
  CSSD_INTEREST_NOTIFICATION_DELETE,
  INTEREST_NOTIFICATION_FOUR_EYES_EVENT,
  INTEREST_NOTIFICATION_UPDATE,
  OPEN_DELETE_INTEREST_NOTIFICATION_DIALOG,
  CLOSE_DELETE_INTEREST_NOTIFICATION_DIALOG,
} from "./actions";
import { toUtf8 } from "services/web3Services/commons";

const initialState = {
  cssdRef: null,
  documentInfoList: null,
  sortCriteria: {
    column: null,
    isDescending: false,
  },
  filters: {},
  pagingData: {
    pageSize: 10,
    pageNumber: 0,
    amount: 0,
  },
  uploadPending: false,
  pendingIds: [],
  loadDocumentInfoListPending: false,
  registerNotificationOpen: false,
  notifications: [],
  editNotification: null,
  interestNotificationPending: false,
  interestNotificationPendingType: "",
  toDeleteNotification: null,
};

function openOverview(state, action) {
  return {
    ...state,
    cssdRef: action.cssd,
    idProxyAddress: action.cssd.zahlstelle.idProxyAdresse,
    documentInfoList: null,
    pagingData: { ...initialState.pagingData },
    sortCriteria: { ...initialState.sortCriteria },
    filters: {},
  };
}

const fetchInformationsMaterialMetaData = (state) => ({
  ...state,
  loadDocumentInfoListPending: true,
});

const fetchInformationsMaterialMetaDataSuccess = (state, action) => ({
  ...state,
  documentInfoList: action.dokumentInfoResponses,
  pagingData: {
    ...state.pagingData,
    amount: action.amount,
  },
  cssdRef: {
    ...state.cssdRef,
    urkundenRegisternummer: action.urkundenRegisternummer,
  },
  loadDocumentInfoListPending: false,
});

const fetchInformationsMaterialMetaDataFailure = (state) => ({
  ...state,
  loadDocumentInfoListPending: false,
});
const sortMetaDataTable = (state, action) => ({
  ...state,
  sortCriteria: {
    column: action.sortBy,
    isDescending:
      state.sortCriteria.column === action.sortBy
        ? !state.sortCriteria.isDescending
        : state.sortCriteria.isDescending,
  },
});

const filterMetaDataTable = (state, action) => ({
  ...state,
  filters: action.filters,
  pagingData: { ...initialState.pagingData },
});

const setPageMetaDataTable = (state, action) => ({
  ...state,
  pagingData: {
    ...state.pagingData,
    pageNumber: action.pageNumber,
  },
});

const setPageSizeMetaDataTable = (state, action) => ({
  ...state,
  pagingData: {
    ...state.pagingData,
    pageSize: action.pageSize,
  },
});

const clearFilterAndSorting = (state) => ({
  ...state,
  filters: {},
  sortCriteria: { ...initialState.sortCriteria },
});

const addPendingId = (state, action) => ({
  ...state,
  pendingIds: [...state.pendingIds].concat(action.dateiId),
});

const clearPendingId = (state, action) => ({
  ...state,
  pendingIds: [...state.pendingIds].filter((id) => id !== action.dateiId),
});

const setUploadPending = (state, pending) => ({
  ...state,
  uploadPending: pending,
});

const expandMetadata = (state, action) => ({
  ...state,
  documentInfoList: state.documentInfoList.map((documentInfo) => {
    if (documentInfo.dateiId === action.dateiId) {
      documentInfo.expanded = true;
    }
    return documentInfo;
  }),
});

const shrinkMetadata = (state, action) => ({
  ...state,
  documentInfoList: state.documentInfoList.map((documentInfo) => {
    if (documentInfo.dateiId === action.dateiId) {
      documentInfo.expanded = false;
    }
    return documentInfo;
  }),
});

const openInterestNotification = (state, action) => ({
  ...state,
  cssdRef: action.cssd,
});

const openRegisterInterestNotification = (state) => ({
  ...state,
  registerNotificationOpen: true,
});

const closeRegisterInterestNotification = (state) => ({
  ...state,
  registerNotificationOpen: false,
  interestNotificationPending: false,
  editNotification: null,
  interestNotificationPendingType: "",
});

const storeInterestNotifications = (state, action) => ({
  ...state,
  notifications: action.notifications,
});

const editNotification = (state, action) => ({
  ...state,
  editNotification: action.notification,
  registerNotificationOpen: true,
});

const setInterestNotificationPending = (state, action) => ({
  ...state,
  interestNotificationPending: true,
  interestNotificationPendingType: action.type,
});

const resetInterestNotificationPending = (state) => ({
  ...state,
  interestNotificationPending: false,
  interestNotificationPendingType: "",
});

const updateNotificationFourEyesStateInArr = (notifications, fourEyesState, firstConfirmer, id) =>
  [...notifications].map((notification) => {
    if (notification.zinsmitteilungId === id) {
      return { ...notification, fourEyesState, firstConfirmer };
    }
    return notification;
  });

const updateInterestNotificationFourEyesStateReducer = (state, action) => {
  const { state: fourEyesState, subid, account: firstConfirmer } = action.event;
  const { notifications } = state;
  const updatedNotifications = updateNotificationFourEyesStateInArr(
    notifications,
    fourEyesState,
    firstConfirmer,
    Number(toUtf8(subid))
  );
  return {
    ...state,
    notifications: updatedNotifications,
  };
};

const updateNotificationStateInArr = (notifications, interestNotification) => {
  let hit = false;
  const update = [...notifications].map((notification) => {
    if (notification.zinsmitteilungId === interestNotification.zinsmitteilungId) {
      hit = true;
      return { ...notification, ...interestNotification };
    }
    return notification;
  });
  if (!hit) {
    update.push(interestNotification);
  }
  return update;
};

const updateInterestNotificationStateReducer = (state, action) => {
  const { notifications, cssdRef } = state;
  const { interestNotification } = action;

  if (interestNotification.cssdId === cssdRef.cssdId) {
    const updatedNotifications = updateNotificationStateInArr(notifications, interestNotification);
    return {
      ...state,
      notifications: updatedNotifications,
    };
  }
  return { ...state };
};

const openDeleteInterestNotificationReducer = (state, action) => ({
  ...state,
  toDeleteNotification: action.notification,
});
const closeDeleteInterestNotificationReducer = (state) => ({
  ...state,
  toDeleteNotification: null,
  interestNotificationPending: false,
  interestNotificationPendingType: "",
});

export function cssdInformationsmaterialReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CSSD_INFORMATIONSMATERIAL_OVERVIEW:
      return openOverview(state, action);
    case SORT_METADATA_TABLE:
      return sortMetaDataTable(state, action);
    case FILTER_METADATA_TABLE:
      return filterMetaDataTable(state, action);
    case SET_PAGE_METADATA_TABLE:
      return setPageMetaDataTable(state, action);
    case SET_PAGE_SIZE_METADATA_TABLE:
      return setPageSizeMetaDataTable(state, action);
    case CLEAR_FILTER_AND_SORTING:
      return clearFilterAndSorting(state);
    case FETCH_INFORMATIONSMATERIAL_METADATA:
      return fetchInformationsMaterialMetaData(state, action);
    case FETCH_INFORMATIONSMATERIAL_METADATA_SUCCESS:
      return fetchInformationsMaterialMetaDataSuccess(state, action);
    case FETCH_INFORMATIONSMATERIAL_METADATA_FAILURE:
      return fetchInformationsMaterialMetaDataFailure(state, action);
    case UPLOAD_INFORMATIONSMATERIAL_STARTED:
      return setUploadPending(state, true);
    case UPLOAD_INFORMATIONSMATERIAL_SUCCESS:
    case UPLOAD_INFORMATIONSMATERIAL_FAILURE:
      return setUploadPending(state, false);
    case DOWNLOAD_INFORMATIONSMATERIAL_STARTED:
    case DELETE_INFORMATIONSMATERIAL_STARTED:
      return addPendingId(state, action);
    case DOWNLOAD_INFORMATIONSMATERIAL_SUCCESS:
    case DOWNLOAD_INFORMATIONSMATERIAL_FAILURE:
    case DELETE_INFORMATIONSMATERIAL_SUCCESS:
    case DELETE_INFORMATIONSMATERIAL_FAILURE:
      return clearPendingId(state, action);
    case EXPAND_INFORMATIONSMATERIALMETADATA:
      return expandMetadata(state, action);
    case SHRINK_INFORMATIONSMATERIALMETADATA:
      return shrinkMetadata(state, action);
    case CSSD_INTEREST_NOTIFICATION_OPEN:
      return openInterestNotification(state, action);
    case CSSD_INTEREST_NOTIFICATION_STORE:
      return storeInterestNotifications(state, action);
    case CSSD_INTEREST_NOTIFICATION_OPEN_REGISTER:
      return openRegisterInterestNotification(state, action);
    case CSSD_INTEREST_NOTIFICATION_CLOSE_REGISTER:
      return closeRegisterInterestNotification(state, action);
    case CSSD_INTEREST_NOTIFICATION_EDIT:
      return editNotification(state, action);
    case CSSD_INTEREST_NOTIFICATION_SAVE_REGISTER:
    case CSSD_INTEREST_NOTIFICATION_SAVE_AND_CONFIRM_REGISTER:
    case CSSD_INTEREST_NOTIFICATION_CONFIRM:
    case CSSD_INTEREST_NOTIFICATION_CANCEL:
    case CSSD_INTEREST_NOTIFICATION_DELETE:
      return setInterestNotificationPending(state, action);
    case CSSD_INTEREST_NOTIFICATION_FAILURE:
      return resetInterestNotificationPending(state, action);
    case INTEREST_NOTIFICATION_FOUR_EYES_EVENT:
      return updateInterestNotificationFourEyesStateReducer(state, action);
    case INTEREST_NOTIFICATION_UPDATE:
      return updateInterestNotificationStateReducer(state, action);
    case OPEN_DELETE_INTEREST_NOTIFICATION_DIALOG:
      return openDeleteInterestNotificationReducer(state, action);
    case CLOSE_DELETE_INTEREST_NOTIFICATION_DIALOG:
      return closeDeleteInterestNotificationReducer(state, action);
    default:
      return state;
  }
}
