// action types
export const INVITE_COLLEAGUE_SEND = "INVITE_COLLEAGUE_SEND";
export const INVITE_COLLEAGUE_SUCCESS = "INVITE_COLLEAGUE_SUCCESS";
export const INVITE_COLLEAGUE_FAILURE = "INVITE_COLLEAGUE_FAILURE";

export const REVOKE_INVITE_COLLEAGUE_REQUEST = "REVOKE_INVITE_COLLEAGUE_REQUEST";
export const REVOKE_INVITE_COLLEAGUE_SUCCESS = "REVOKE_INVITE_COLLEAGUE_SUCCESS";
export const REVOKE_INVITE_COLLEAGUE_FAILURE = "REVOKE_INVITE_COLLEAGUE_FAILURE";

export const GET_COLLEAGUES_REQUEST = "GET_COLLEAGUES_REQUEST";
export const GET_COLLEAGUES_SUCCESS = "GET_COLLEAGUES_SUCCESS";
export const GET_COLLEAGUES_FAILURE = "GET_COLLEAGUES_FAILURE";

export const GET_COLLEAGUES_PERMISSIONS_REQUEST = "GET_COLLEAGUES_PERMISSIONS_REQUEST";
export const GET_COLLEAGUES_PERMISSIONS_SUCCESS = "GET_COLLEAGUES_PERMISSIONS_SUCCESS";
export const GET_COLLEAGUES_PERMISSIONS_FAILURE = "GET_COLLEAGUES_PERMISSIONS_FAILURE";

export const GRANT_REVOKE_PRIVILEGES_REQUEST = "GRANT_REVOKE_PRIVILEGES_REQUEST";
export const GRANT_REVOKE_PRIVILEGES_SUCCESS = "GRANT_REVOKE_PRIVILEGES_SUCCESS";
export const GRANT_REVOKE_PRIVILEGES_FAILURE = "GRANT_REVOKE_PRIVILEGES_FAILURE";

export const OPEN_ROLE_MODAL = "OPEN_ROLE_MODAL";
export const CLOSE_ROLE_MODAL = "CLOSE_ROLE_MODAL";

export const OPEN_DEACTIVATE_USER_DIALOG = "OPEN_DEACTIVATE_USER_DIALOG";
export const CLOSE_DEACTIVATE_USER_DIALOG = "CLOSE_DEACTIVATE_USER_DIALOG";
export const DEACTIVATE_USER_REQUEST = "DEACTIVATE_USER_REQUEST";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_ERROR = "DEACTIVATE_USER_ERROR";

export const CREATE_TECHNICALUSER = "CREATE_TECHNICALUSER";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const inviteColleagueActions = {
  send: (invitationData) => action(INVITE_COLLEAGUE_SEND, { invitationData }),
  success: () => action(INVITE_COLLEAGUE_SUCCESS, {}),
  failure: (error) => action(INVITE_COLLEAGUE_FAILURE, { error }),
};

export const revokeColleagueInviteActions = {
  request: (email, idProxyAddr) => action(REVOKE_INVITE_COLLEAGUE_REQUEST, { email, idProxyAddr }),
  success: () => action(REVOKE_INVITE_COLLEAGUE_SUCCESS, {}),
  failure: (error) => action(REVOKE_INVITE_COLLEAGUE_FAILURE, { error }),
};

export const getColleaguesActions = {
  request: (idProxyAddr) => action(GET_COLLEAGUES_REQUEST, { idProxyAddr }),
  success: (colleagues) => action(GET_COLLEAGUES_SUCCESS, { colleagues }),
  failure: (error) => action(GET_COLLEAGUES_FAILURE, { error }),
};

export const roleModalActions = {
  open: (colleagueData) => action(OPEN_ROLE_MODAL, { colleagueData }),
  close: () => action(CLOSE_ROLE_MODAL, {}),
};

export const colleaguePermissionActions = {
  request: (walletAddress) => action(GET_COLLEAGUES_PERMISSIONS_REQUEST, { walletAddress }),
  success: (walletAddress, permissions) =>
    action(GET_COLLEAGUES_PERMISSIONS_SUCCESS, { walletAddress, permissions }),
  failure: (error) => action(GET_COLLEAGUES_PERMISSIONS_FAILURE, { error }),
};

export const grantRevokePrivilegeActions = {
  request: (account, walletAddress, newPermissions, currentPermissions) =>
    action(GRANT_REVOKE_PRIVILEGES_REQUEST, {
      account,
      walletAddress,
      newPermissions,
      currentPermissions,
    }),
  success: () => action(GRANT_REVOKE_PRIVILEGES_SUCCESS, {}),
  failure: (error) => action(GRANT_REVOKE_PRIVILEGES_FAILURE, { error }),
};

export const deactivateUserActions = {
  openDialog: (colleagueData) => action(OPEN_DEACTIVATE_USER_DIALOG, { colleagueData }),
  closeDialog: () => action(CLOSE_DEACTIVATE_USER_DIALOG, {}),
  deactivateUser: (account, walletAddress) =>
    action(DEACTIVATE_USER_REQUEST, { account, walletAddress }),
  deactivateUserSuccess: () => action(DEACTIVATE_USER_SUCCESS, {}),
  deactivateUserFailure: (error) => action(DEACTIVATE_USER_ERROR, { error }),
};

export const createTechnicalUser = {
  request: (password, account) => action(CREATE_TECHNICALUSER, { password, account }),
};
