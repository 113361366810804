import iso7064 from "iso-7064";
import { removeDots, dateToTimestamp, formatDate } from "util/convertor";
import { IBAN_CODE_LENGTHS } from "./constants";
import { validateId } from "services/cssdService";

export function checkTelResultAsBool(value) {
  if (!value) return true;
  const regex = /^([+][0-9\- ]{7,})?$/;
  return regex.test(value);
}
export function checkTel(value) {
  return checkTelResultAsBool(value).toString();
}
//---------------------------------------------------------------------------
export function checkIban(value) {
  if (!value) return "true";
  let result = isValidIBANNumber(value);
  return result === "iban" ? "false" : "true";
}
//---------------------------------------------------------------------------
export function checkBic(value) {
  if (!value) return "true";
  const regex = /^([a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?)?$/;
  let result = regex.test(value);
  return result.toString();
}
//---------------------------------------------------------------------------
export function checkSecondIban(firstIban, secondIban) {
  if (!firstIban || !secondIban) return "true";
  let result =
    checkIban(secondIban) === "true" &&
    (firstIban.replace(/\s/g, "") !== secondIban.replace(/\s/g, "") || secondIban === ""); //remove white space
  return result.toString();
}
//---------------------------------------------------------------------------
export function ibansNotMatching(firstIban, secondIban) {
  if (firstIban && secondIban && firstIban.replace(/\s/g, "") !== secondIban.replace(/\s/g, "")) {
    return true;
  }
  return false;
}
//---------------------------------------------------------------------------
export function checkEmailResultAsBool(value) {
  const regex = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))?$/;
  let result = regex.test(value);
  return result;
}
export function checkEmail(value) {
  return checkEmailResultAsBool(value).toString();
}
//---------------------------------------------------------------------------
export function checkUrl(value) {
  const result = value === "" || value.startsWith("http://") || value.startsWith("https://");
  return result.toString();
}
//---------------------------------------------------------------------------
export function checkLEIResultAsBool(value) {
  if (!value) return true;
  let result = false;
  const string = value.toString();
  const regex = /^([0-9A-Z]{20})?$/;
  if (!regex.test(string)) {
    return false;
  }
  result = string === "" || iso7064.computeWithoutCheck(string) === 1;
  return result;
}
export function checkLEI(value) {
  return checkLEIResultAsBool(value).toString();
}
//---------------------------------------------------------------------------
export function checkUmsatzsteuerIdResultAsBool(value) {
  return true;
  //const regex = /^DE[[0-9]{9}?$/;
  //return value === "" || regex.test(value);
}

export function checkUmsatzsteuerId(value) {
  return checkUmsatzsteuerIdResultAsBool(value).toString();
}
//---------------------------------------------------------------------------
export function checkNominalOnEdit(abgetretenes_nominal, old_nominal, ssdInfo, nominalSum) {
  let result = false;
  const a = Number(removeDots(abgetretenes_nominal));
  const b = Number(removeDots(ssdInfo.mindestbetrag_bei_abtretung));
  const c = Number(removeDots(ssdInfo.nominal));
  const d = Number(nominalSum);
  const old_nom = Number(removeDots(old_nominal));

  // Abgetretenes Nominal muss mindestens so hoch wie SSD Abtretung Mindestbetrag sein.
  const condition1 = a >= b;

  // Außerdem darf die Summe der Nominale (aller Abtretungen zu diesem SSD) nicht größer als das Nominal des SSDs sein. (Stornierte Abtretungen nicht mitgerechnet.)
  const condition2 = Number((a + d - old_nom).toFixed(2)) <= c;

  if (condition1 && condition2) result = true;
  return result.toString();
}
//---------------------------------------------------------------------------
export function checkNominal(abgetretenes_nominal, ssdInfo, nominalSum) {
  let result = false;
  const a = Number(removeDots(abgetretenes_nominal));
  const b = Number(removeDots(ssdInfo.mindestbetrag_bei_abtretung));
  const c = Number(removeDots(ssdInfo.nominal));
  const d = Number(nominalSum);

  // Abgetretenes Nominal muss mindestens so hoch wie SSD Abtretung Mindestbetrag sein.
  const condition1 = a >= b;

  // Außerdem darf die Summe der Nominale (aller Abtretungen zu diesem SSD) nicht größer als das Nominal des SSDs sein. (Stornierte Abtretungen nicht mitgerechnet.)
  const condition2 = Number((a + d).toFixed(2)) <= c;

  if (condition1 && condition2) result = true;
  return result.toString();
}
//---------------------------------------------------------------------------
export function checkValuta(CessionValuta, ssdValuta) {
  let result = false;

  if (CessionValuta && ssdValuta) {
    const a = dateToTimestamp(CessionValuta);
    const b = dateToTimestamp(ssdValuta);

    // Valuta muss gleich oder höher als die Valuta des ursprünglichen SSDs sein.
    const condition = a >= b;
    if (condition) result = true;
  }
  return result.toString();
}
//---------------------------------------------------------------------------
export function is3DayBeforeEndfaelligkeit(ssd_endfaelligkeit) {
  let result = false;

  if (ssd_endfaelligkeit) {
    const today = dateToTimestamp(formatDate(new Date()));
    const endfaelligkeit = dateToTimestamp(ssd_endfaelligkeit);
    const days = (endfaelligkeit - today) / 86400;

    // Eine Abtretung Erfassung ist nur bis 3 Tage vor Ablaufdatum (Endfälligkeit) des SSDs möglich.
    const condition = days >= 3;
    if (condition) result = true;
  }
  return result;
}

//---------------------------------------------------------------------------
export function isDateInFuture(date) {
  if (!date) return true;
  let now = new Date();
  now.setHours(0, 0, 0, 0);

  let dateParts = date.split(".", 3);
  let selected = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

  return selected >= now;
}

//---------------------------------------------------------------------------
export const isDateBefore = (firstDate) => (secondDate) => {
  return dateBefore(firstDate, secondDate);
};

const dateBefore = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    let firstSelected = dateToTimestamp(firstDate);
    let secondSelected = dateToTimestamp(secondDate);

    return firstSelected < secondSelected;
  } else {
    return false;
  }
};

export const isDateAfter = (firstDate) => (secondDate) => {
  return dateBefore(secondDate, firstDate);
};

//---------------------------------------------------------------------------
export const isDateAfterOrEqual = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    let firstSelected = dateToTimestamp(firstDate);
    let secondSelected = dateToTimestamp(secondDate);

    return firstSelected >= secondSelected;
  } else {
    return true;
  }
};

export const isDateBeforeOrEqual = (firstDate) => (secondDate) => {
  if (firstDate && secondDate) {
    let firstSelected = dateToTimestamp(firstDate);
    let secondSelected = dateToTimestamp(secondDate);

    return firstSelected <= secondSelected;
  } else {
    return false;
  }
};

//---------------------------------------------------------------------------
export const validateBic = (bic) => {
  return bic ? (bic.length === 8 || bic.length === 11 ? null : "bic") : null;
};

export const smallerOrEqualAs = (large) => (small) => {
  if (!large || !small) return true;
  return Number(removeDots(small)) <= Number(removeDots(large));
};

export const validateIban = (waehrung) => (iban) => {
  return !iban || waehrung === "USD" ? null : isValidIBANNumber(iban);
};

//---------------------------------------------------------------------------

function isValidIBANNumber(input) {
  const iban = String(input).toUpperCase().replace(/\s/g, ""); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits;
  // check syntax and length
  if (!code) {
    return "iban";
  }
  if (!IBAN_CODE_LENGTHS[code[1]]) {
    return true;
  }
  if (iban.length !== IBAN_CODE_LENGTHS[code[1]]) {
    return "iban";
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1 ? true : "iban";
}
function mod97(string) {
  let checksum = string.slice(0, 2),
    fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

//---------------------------------------------------------------------------

export const isPositive = (value) => {
  return value ? Number(removeDots(value)) > 0 : false;
};

export const isDateInFutureAndBefore = (firstDate) => (secondDate) => {
  const isFuture = isDateInFuture(secondDate);
  if (isFuture) {
    return !firstDate || dateBefore(secondDate, firstDate);
  }

  return false;
};

export const isDateInFutureAndBetween = (startDate1, startDate2) => (valueDate) => {
  const isFuture = isDateInFuture(valueDate);
  if (isFuture) {
    return dateBefore(startDate1, valueDate) && (!startDate2 || dateBefore(startDate2, valueDate));
  }

  return false;
};

export async function isIdValid(
  idProxyAddress,
  cssdId = 0,
  fingerprint = 0,
  value,
  name,
  callback
) {
  if (String(value).length > 0) {
    const res = await validateId(idProxyAddress, cssdId, fingerprint, value, name);
    callback(!res);
  } else {
    callback(false);
  }
}

export function isGreaterThan(first, second) {
  first = Number(removeDots(first));
  second = Number(removeDots(second));
  return second < first;
}

export const isMultipleOf = (base) => (multiple) => {
  if (typeof multiple === "string") {
    multiple = Number(removeDots(multiple));
  }
  return multiple % base === 0;
};

export const maxRows = (max) => (text) => {
  const current = text.split("\n").length;
  return current <= max;
};

export const validateFile = (file, allowedFileTypes, maxUploadSizeInMb) => {
  const res = {
    isTypeValid: allowedFileTypes.some((expectedType) => file.type === expectedType),
    maxLength: file.size <= maxUploadSizeInMb * 1000000,
  };
  return res;
};

export const findItemByState = (list = [], state) => {
  return list.find((item) => item.status === state);
};

export const filterItemsByInitiator = (list = [], initiator) => {
  return list.filter((item) => item.initiator === initiator);
};

export const isAccountInfoEqual = (abwicklungsinformationen) => {
  if (!abwicklungsinformationen) return false;
  const {
    ibanTilgungEur,
    ibanZinsEur,
    bicTilgungEur,
    bicZinsEur,
    hinweisZahlungZins,
    hinweisZahlungTilgung,
  } = abwicklungsinformationen;
  if (!ibanTilgungEur) return false;
  return (
    ibanTilgungEur === ibanZinsEur &&
    bicTilgungEur === bicZinsEur &&
    hinweisZahlungTilgung === hinweisZahlungZins
  );
};
