import { sendSignedHttpRequest } from "util/requestSigning";
import { downloadFileFromBase64 } from "util/fileReader";

const idProxyAddress = process.env.REACT_APP_NUTZERINFORMATION_ADDRESS;

export async function uploadUserInformation(request) {
  return sendSignedHttpRequest(
    "post",
    `/digits-server/nutzerinformation/${idProxyAddress}`,
    idProxyAddress,
    request
  );
}

export async function getUserInformationMetadataList() {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/nutzerinformation/${idProxyAddress}`,
    idProxyAddress
  );
}

export async function getUserInformation(dateiId) {
  return sendSignedHttpRequest(
    "get",
    `/digits-server/nutzerinformation/${idProxyAddress}/${dateiId}`,
    idProxyAddress
  ).then((response) => {
    downloadFileFromBase64(response.data.dokument, response.data.dateiName, response.data.dateiTyp);
  });
}

export async function deleteUserInformation(dateiId) {
  return sendSignedHttpRequest(
    "delete",
    `/digits-server/nutzerinformation/${idProxyAddress}/${dateiId}`,
    idProxyAddress
  );
}

export async function updateUserInformation(dateiId, request) {
  return sendSignedHttpRequest(
    "put",
    `/digits-server/nutzerinformation/${idProxyAddress}/${dateiId}`,
    idProxyAddress,
    request
  );
}
